import { Component, OnInit, HostListener } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { environment } from '../../../../environments/environment';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { GlobalService } from '../../../global.service';
declare const $;
@Component({
  selector: 'app-salestax',
  templateUrl: './salestax.component.html',
  styleUrls: ['./salestax.component.css']
})

export class SalestaxComponent implements OnInit {
  baseUrl = environment.baseUrl;
  //ProductDetailsUrl = this.baseUrl + "/Product/GetProductDetailById";
  
  companyName = sessionStorage.getItem("companyName");
  IdProduct = sessionStorage.getItem("referproductid");

  posts: any;
  lst_SalesTax: any;
  pageno: number = 1;
  before: number;
  TotalCount: any = 1;

  AlertText: string;
  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public apiList: ApilistService) { 
      var rowno = (window.innerHeight - 388) / 54;
      if(rowno>=0){
        this.before = parseInt(rowno.toFixed(2).toString().split(".")[0], 10);
      //  console.log( this.before);
      }else{
        this.before=1;
      }
    }

  ngOnInit() {
    $('#dots_spinner').hide();
    this.RefreshTax();
  }

  RefreshTax() {
    $('#dots_spinner').show();
    let url1 = `${this.apiList.ProductSalesTaxDetailsUrl}`;
    this.http.post<any>(url1, { IdProduct: this.IdProduct, PageNumber: this.pageno, NumberOfRows: this.before  }, this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
        this.posts = response;
      //console.log(response);
      this.lst_SalesTax = this.posts.lst_SalesTax;
      this.TotalCount =  this.posts.TotalCount;
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }


  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
  goback() {
    this.router.navigate([this.companyName + '/dashboard/product']);
  }
/* function for hit the api when click on pagination*/
pageChange(event) {
  //console.log(event);
  this.pageno = event;
    //this.RefreshTax();
}
@HostListener('window:resize', ['$event'])
onResize(event) {
  event.target.innerHeight;
//  console.log(event.target.innerHeight)
  var rowno = (window.innerHeight - 388) / 54;
 // console.log(rowno)
  this.before = parseInt(rowno.toFixed(2).toString().split(".")[0], 10);//before
 // console.log(this.before)
  if (this.before >= 0) {
    if (this.before == 0) {
      this.before = 1
    }
    //this.RefreshTax();
  }

}
}
