import { Injectable } from '@angular/core';
import { CanActivate,CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { PlaceloopComponent } from './dashboard/digitalsignage/placeloop/placeloop.component';
import { AdloopComponent } from './dashboard/digitalsignage/adloop/adloop.component';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
  } 

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    canDeactivate(component: CanComponentDeactivate, 
        route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot) {

  let url: string = state.url;

  return component.canDeactivate ? component.canDeactivate() : true;
}
}
