import { Component, OnInit, HostListener } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
import { Subject } from 'rxjs';
declare const $;
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  BaseFileUrl = environment.BaseFileUrl;
  ImageUrl = this.BaseFileUrl + '/ClientDocument/';
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
 // list: any;
  reverse: any;
  p: any;
  key: any;
  Search: any;
  posts: any;
  IdMainCategory: string;
  MainCategoryName = '';
  AlertText: string;
  pageno = 1;
  before: number;
  TotalCount: any = 1;
  screenHeight: number;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate
  
  constructor(private router: Router, private http: HttpClient, private globalservice: GlobalService, public apiList: ApilistService) {
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
    this.refreshlistcategories();
  }

  ngOnInit() {
    $('#alert_div').hide();
    $('#dots_spinner').hide();
  }

  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
//console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  refreshlistcategories() {
    $('#dots_spinner').show();
    const headers = new Headers();
    headers.append('cache-control', 'no-cache');
    this.http.post<any>(this.apiList.CategoryListUrl,
      {
        IdMerchant: this.IdMerchant,
        PageNumber: this.pageno,
        NumberOfRows: this.before
      }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          this.posts = response.lst_mc;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide();
      if (this.Resizing) {
        // need to give time to render behind the scenes before size/resize rows
        this.SettleAndAutosize();
      }
    });
  }

  Maincategories() {
    this.router.navigate([this.companyName + '/dashboard/categories/categoriesinformation']);
  }

  Subcategories() {
    this.router.navigate([this.companyName + '/dashboard/categories/subcategorylist']);
  }

  Subcategorylist(id: string, name: string) {
    this.IdMainCategory = id;
    this.MainCategoryName = name;
    sessionStorage.setItem('referidmancategory', this.IdMainCategory);
    sessionStorage.setItem('referidmancategoryName', this.MainCategoryName);
    this.router.navigate([this.companyName + '/dashboard/categories/SubCategoryListPage', {
      id: id,
      name: name
    }]);
  }

  getID(id: string) {
    this.IdMainCategory = id;
  }

  edit(id: any, name: string, image: any, desc: any) {
    this.IdMainCategory = id;
    this.router.navigate([this.companyName + '/dashboard/categories/Updatecategory', {
      id: id
    }]);
  }

  delete() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteCategoryUrl,
      { IdMainCategory: this.IdMainCategory, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
       // this.list = response;
       this.FlashError('Successfully deleted');
        this.refreshlistcategories();

      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide();
      if (this.Resizing) {
        // need to give time to render behind the scenes before size/resize rows	        // need to give time to render behind the scenes before size/resize rows
         this.SettleAndAutosize(500);
      }	      
    });
      
  }
  // -----------sorting----------//
  sortData(sort: Sort) {
    const data = this.posts.slice();
    if (!sort.active || sort.direction === '') {
      this.posts = data;
      return;
    }
    this.posts = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'catergory_name': return this.compare(a.MainCategoryName.toLowerCase(), b.MainCategoryName.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  // -----------sorting----------//

  clearSearch() {
    this.Search = '';
    this.refreshlistcategories();
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  /* function for hit the api when click on pagination*/
  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }
}
