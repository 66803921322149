import { Component, OnInit, HostListener } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Sort } from '@angular/material';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
import { Subject } from 'rxjs';
declare const $;
@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
  p = 1;
  posts: any;
  IdLocation: string;
  Search: any;
  AlertText = '';
  post_info: any;
  pageno = 1;
  before: number;
  //TotalCount: any = 1;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate


  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public apiList: ApilistService
  ) {
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
  }

  ngOnInit() {
    this.hidepops();
    this.refreshlocationlist();
  }

  hidepops() {
    $('#LocationDetails').modal('hide');
    $('#alert_div').hide();
    $('#dots_spinner').hide();
  }

  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
//console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  locationInfo() {
    this.router.navigate([this.companyName + '/dashboard/locations/locationinformation2']);
  }

  getID(id: string) {
    this.IdLocation = id;
  }

  refreshlocationlist() {
    $('#dots_spinner').show();
    let data = {
      IdMerchant: this.IdMerchant,
      PageNumber: this.pageno,
      NumberOfRows: this.before
    };
    this.http.post<any>(this.apiList.LocationListUrl, data, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(resp => {
        if (resp == null || resp.Response == null) { throw { Response: 0, ErrorMessage: 'Null Response' }; }
        resp.Response = +resp.Response;
        if (resp.Response !== 1) { throw resp; }
        return resp;
      })
      .subscribe((response) => {
        this.posts = response.lst_Location;
        console.log(this.posts);
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide();
      if (this.Resizing) {
        // need to give time to render behind the scenes before size/resize rows
        this.SettleAndAutosize();
      }
    });
  }

  delete() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteLocationUrl, { IdLocation: this.IdLocation }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe(success => {
        const res = success;
        if (res.Response === 1) {
          this.FlashError('Successfully deleted');
          this.refreshlocationlist();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  edit(id: string) {
    this.IdLocation = id;
    this.router.navigate([this.companyName + '/dashboard/Updatelocation', {
      id: this.IdLocation
    }]);
  }

  // -----------sorting----------//
  sortData(sort: Sort) {
    const data = this.posts.slice();
    if (!sort.active || sort.direction === '') {
      this.posts = data;
      return;
    }
    this.posts = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'location': return this.compare(a.LocationName.toLowerCase(), b.LocationName.toLowerCase(), isAsc);
        case 'commission': return this.compare(a.CommisiionSplit, b.CommisiionSplit, isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  LocationInfo(data) {
    $('#LocationDetails').modal('show');
    this.post_info = data;
  }

  locdetails(data){
    $("#btn_close_popup").trigger("click");
    this.post_info = data;
  }

  clearSearch() {
    this.Search = '';
    this.refreshlocationlist();
  }
  /* function for hit the api when click on pagination*/
  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }
}
