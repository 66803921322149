import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { GlobalService } from '../../../global.service';
declare const $;
@Component({
  selector: 'app-edit-subscription',
  templateUrl: './edit-subscription.component.html',
  styleUrls: ['./edit-subscription.component.css']
})
//
export class EditSubscriptionComponent implements OnInit {

  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
  companyName = sessionStorage.getItem('companyName');
  AlertText: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private globalservice: GlobalService,
    public apiList: ApilistService) {
    this.activatedRoute.params.subscribe((get) => {
      this.IdUser = get.id;
    });
  }

  Update;
  edit_Id;
  edit_details;
  UserList: any[];
  AlertTypeArray = ['Daily', 'Live', 'Both'];
  AlertType;
  EventTypeId;
  IdUser;
  EventTypesArray: any[];
  EventTypeArray: any[] = [];
  ScreenOrientation; SpecificationName; ScreenResolution; ScreenSize; ScreenOrientationorg; SpecificationNameorg; ScreenResolutionorg; ScreenSizeorg;
  DailySlider = {
    IsAlertEnabled: false,
    IsAlertStatus: 0
  };

  LiveSlider = {
    IsAlertEnabled: false,
    IsAlertStatus: 0
  };
  ngOnInit() {
    //console.log("companyname" + this.companyName);
    $('#dots_spinner').hide();
    $('#alert_div').hide();
    this.getUsers();
    this.GetEventList(this.IdUser);
    // this.editDataById();

  }
  getUsers() {
    $('#dots_spinner').show();
    this.http.get<any>(this.apiList.GetChildMerchants, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.UserList = response.Merchants;
        // this.EventTypeArray = response.EventTypes;

      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {

        $('#dots_spinner').hide();
      });
  }
  checkSliderDaily(value) {
    this.DailySlider.IsAlertEnabled = value.checked;
    if (value.checked == true) {
      this.DailySlider.IsAlertStatus = 1;
    }
    else {
      this.DailySlider.IsAlertStatus = 0;
    }
  }

  checkSliderLive(value) {
    this.LiveSlider.IsAlertEnabled = value.checked;
    if (value.checked == true) {
      this.LiveSlider.IsAlertStatus = 1;
    }
    else {
      this.LiveSlider.IsAlertStatus = 0;
    }
  }


  GetEventList(iduser) {
    this.EventTypeArray = [];
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.GetSingleSubscribedAlertList, { IdMerchant: iduser }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.EventTypeArray = response.merchantSubscribes.Subscriptions;
        //  this.cd.detectChanges();

      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }
  changeUser(val) {
    this.IdUser = val;
    if (this.IdUser == undefined || this.IdUser == null) {
      // this.EventSection=false;
      this.EventTypeArray = [];
    }
    else {
      // this.EventSection=true;
      this.GetEventList(this.IdUser);
    }
  }

  SaveAs() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.SubscribeAlerts,
      {
        IdMerchant: this.IdUser,
        Subscriptions: this.EventTypeArray


      }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.Update = response;
          this.FlashSuccess('Successfully Updated');
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  onDailyStatusChanged(event, eventid) {
    var indexEvent = this.EventTypeArray.filter(ij => ij.EventTypeId == eventid)[0];
    if (indexEvent !== undefined) {
      var index22 = this.EventTypeArray.indexOf(indexEvent);
      this.EventTypeArray[index22].IsDaily = event.checked;
    }
  }

  onLiveStatusChanged(event, eventid) {
    var indexEvent = this.EventTypeArray.filter(ij => ij.EventTypeId == eventid)[0];
    if (indexEvent !== undefined) {
      var index22 = this.EventTypeArray.indexOf(indexEvent);
      this.EventTypeArray[index22].IsLive = event.checked;
    }

  }

  sortData(event) { //$$ BUG: Stub added for compile. Html refers, but no implementation.

  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }


  FlashSuccess(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
      this.router.navigate([this.companyName + '/dashboard/eventsubscription']);
    }, 3000);
  }
  changeAlert(val) {
    this.AlertType = val;
  }
  changeEvent(val) {
    this.EventTypeId = val;
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/eventsubscription']);
  }

}
