import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facial-recognization',
  templateUrl: './facial-recognization.component.html',
  styleUrls: ['./facial-recognization.component.css']
})
export class FacialRecognizationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
