import { Component, OnInit } from '@angular/core';
import { ApilistService } from '../../../../service/Api/apilist.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../../global.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EditorModule } from '@tinymce/tinymce-angular';

// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
declare const $;
import { UserIdleService } from 'angular-user-idle';
declare const tinymce;

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.css'],
  providers: [DatePipe]
})
export class TermsConditionComponent implements OnInit {

  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));

  AlertText: string;
  TC:any = ''
  RevisionDate: any;
  RevisionNumber: any;
  Cancel_btn: boolean = false;
  reserveTC: any;
  CurrentDate: any;
  myDate = new Date();
  editor: any;

  // public Editor = ClassicEditor;
  constructor(public userIdle: UserIdleService,private datePipe: DatePipe,private router: Router, public apiList: ApilistService, private http: HttpClient, private globalservice: GlobalService,
    public EditorModule: EditorModule) { 
 
  }

  ngOnInit() {
    var that = this
    this.userIdle.startWatching();

    tinymce.init({
      selector: '#mytextareaTC',
      resize: true,
      width: '100%',
      min_height: 300,
      //menubar: 'tools',
      plugins: 'lists advlist code',
      toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | code',
      setup:(ed) => {
        ed.on('keyup', function(e) {
            that.userIdle.resetTimer();
        });
      }
    });
    
    $('#CancelModal').modal('hide');
    $('#SaveModal').modal('hide');
    $('#alert_div').hide();
    $('#dots_spinner').hide();
    this.GetTermsConditions()
  }

  smtp() { this.router.navigate([this.companyName + '/dashboard/setting']) }
  email() { this.router.navigate([this.companyName + '/dashboard/setting/EmailTemplate']) }
  terms() { this.router.navigate([this.companyName + '/dashboard/setting/term']) }
  listSpecial() { this.router.navigate([this.companyName + '/dashboard/setting/specialMessage']) }
  firebaseRoute(){this.router.navigate([this.companyName + '/dashboard/setting/FirebaseDetails'])}


  GetTermsConditions() {
   // console.log(this.IdMerchant)
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.GetTermsandConditions, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())
    .subscribe((res: any) => {
      $('#dots_spinner').hide();
      if (res.Response == 1) {
        this.TC = res.objTerms.TermsandCondition;
        tinymce.get('mytextareaTC').setContent(this.TC); 
        this.reserveTC = res.objTerms.TermsandCondition
        this.RevisionDate = res.objTerms.RevisionDate;
        this.RevisionNumber = res.objTerms.RevisionNumber
      }
      else if (res.Response === 35) {
        this.AlertText = "";
        this.AlertText = res.ErrorMessage
        if(this.AlertText) {
          this.FlashError();
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
      }
      else {
        if(res.ErrorMessage!=''){
          this.AlertText = "";
          this.AlertText = res.ErrorMessage
          this.FlashError();
        }
       
      }
    });
  }
  changeTC(TC) {
    this.Cancel_btn = true
   // console.log('Changed');
  }
  Save(){
    $('#SaveModal').modal('show');
  }
  Cancel() {
    $('#CancelModal').modal('show');
  }

  Popup_Cancel(){
    this.TC = this.reserveTC;
    this.Cancel_btn = false;
  }

  UpdateTC() {
    this.TC = tinymce.activeEditor.getContent({format : 'raw'});
    let myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd HH:mm:ss');
    // console.log(myDate);
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.UpdateTermsAndCondition, {  IdMerchant: this.IdMerchant,ReVDate: myDate ,TermsAndCondition: this.TC }, this.globalservice.Headers()).subscribe((res: any) => {
      $('#dots_spinner').hide();
      if (res.Response == 1) {
        this.RevisionDate = res.RevisionDate;
        this.RevisionNumber = res.RevisionNumber;
        this.Cancel_btn = false;
        this.AlertText = "";
        this.AlertText = 'Successfully saved!'
        this.FlashError();
      }
      else if (res.Response === 35) {
        this.AlertText = "";
        this.AlertText = res.ErrorMessage
        if(this.AlertText) {
          this.FlashError();
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
      }
      else {
        this.AlertText = "";
        this.AlertText = res.ErrorMessage
        this.FlashError();
      }
    });
  }

  FlashError() {
    $("#custom").blur();
    $('#alert_div').show();
    setTimeout(() => {
      $("#alert_div").hide();
    }, 3000);
  }

  ngOnDestroy() {
    tinymce.remove('#mytextareaTC');
   }

}
