import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm } from "@angular/forms";
import { Observable } from 'rxjs/Rx';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service'
declare const $;


@Component({
  selector: 'app-addplacemachine',
  templateUrl: './addplacemachine.component.html',
  styleUrls: ['./addplacemachine.component.css']
})

export class AddplacemachineComponent implements OnInit {
  @ViewChild('place', { static: false }) public placeForm: NgForm
  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));


  getvalues: any;
  posts: any;
  names: any;
  Mdetails: any;
  ldetails: any;
  IdLocation: any;
  LocationNumber: string;
  LocationName: string = "";
  LocationAddress: string = "";
  Country: string = "";
  City: string = "";
  State: string = "";
  Zip: number;
  LocationPhoneNumber: number;
  ContactPerson: string = "";
  ContactPersonEmailId: string = "";
  ContactPersonPhoneNumber: number;
  CommisiionSplitType: string = "";
  CommisiionSplit: string = "";
  IdMachineType: number;
  IdMachine=[];
  Machine = '';
  LinkCode = '';
  MachineSerial = '';
  MachineType = '';
  MachineModel = '';
  ActivationDate = '';
  Activated = '';
  NoOfMotorMaxNo = "";
  NoOfMotorsPerTray = "";
  NoOfColumns = "";
  Cannisters = "";
  WholeBean = "";
  Tea = "";
  MachineInfo = "";
  selectedValue: number;
  AlertText
  ldetail: any;
  selectedMachine: any;
  constructor(public apiList: ApilistService, private router: Router,
    private http: HttpClient, private globalservice: GlobalService) { }

  ngOnInit() {
    this.HidePopups()
    this.LocationLstUrl();
    this.UnplaceMachineLst();
  }

  HidePopups(){
    $('#dots_spinner').hide();
    $("#left").hide();
    $("#right").hide();
    $("#location").hide();
    $("#tray_moter").hide();
    $("#vertical_columns").hide();
    $("#Coffee_show").hide();
    $("#Cube").hide();
    $("#alert_div").hide();
  }

  LocationLstUrl() {
    this.http.post<any>(this.apiList.LocationListUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if( response.Response==1){
        // this.posts = response;
      this.posts = response.lst_Location;
      if (this.posts.length == 0) {
        this.FlashError("Please Add a Location")
      }
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
     });
  }

  UnplaceMachineLst() {
    this.http.post<any>(this.apiList.UnplaceMachineListUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      
      if (response.Response == 1) {
        this.names = response;
      this.names = this.names.lst_MachineList
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      });
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/placeaMachine']);
  }

  onChangeMachine(event, id: any) {
    $('#dots_spinner').show();
    $("#tray_moter").empty();

    this.IdMachine=id;
    console.log(this.IdMachine)
    this.http.post<any>(this.apiList.MachineDetailUrl, {  IdMachines: id }, this.globalservice.Headers())
 .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.Mdetails = response;
        this.Mdetails = this.Mdetails.lst_MachineMaster;
        $("#vertical_columns").show();
        $("#tray_moter").show();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      $('#dots_spinner').hide(); });

  }

  onChangelocation(idl: number) {
    $('#dots_spinner').show();
    this.IdLocation = this.selectedValue;
    this.http.post<any>(this.apiList.LocationDetailUrl, {  IdLocation: this.IdLocation }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.ldetail = response.Obj_MachineList[0];
        // console.log(this.ldetail);
        $("#location").show();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      $('#dots_spinner').hide(); });
  }

  Submit_placemachine() {
    if (!this.IdLocation || !this.IdMachine) {
      this.FlashError("Select Location name & Machine")
    } else {
      $('#dots_spinner').show();
      this.http.post<any>(this.apiList.PlaceMachineUrl, {  IdLocation: this.IdLocation, IdMachines: this.IdMachine, CreatedBy: this.IdMerchant }, this.globalservice.Headers())
         .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
        this.posts = response;
        if (this.posts.Response == 1) {
          this.router.navigate([this.companyName + '/dashboard/placeaMachine']);
        } 
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      $('#dots_spinner').hide(); });
    }
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

}
