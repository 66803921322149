import { Component, OnInit, HostListener } from '@angular/core';
import { ApilistService } from '../../../../service/Api/apilist.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../../global.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
declare const $;

@Component({
  selector: 'app-emailtemplate',
  templateUrl: './emailtemplate.component.html',
  styleUrls: ['./emailtemplate.component.css']
})
export class EmailtemplateComponent implements OnInit {
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");

  status: any;
  upload_file: any;
  IdEmailTemplateMaster = 0;
  Url: string = '';
  URLMD5Sum: any;
  ZipUrl: string;
  ZipFileMD5Sum: any;
  ZIpFileURL: any;
  fileName: any;
  AlertText: string;
  screenHeight: number;
  frameHeight: number;
  image
  constructor(private sanitizer: DomSanitizer, private router: Router, public apiList: ApilistService, private http: HttpClient, private globalservice: GlobalService) {
    this.screenHeight = window.innerHeight;
    this.frameHeight = this.screenHeight - 360;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerHeight;
    this.screenHeight = window.innerHeight;
    this.frameHeight = this.screenHeight - 360;
  }

  ngOnInit() {
    this.HidePopups()
    this.getTemplateDetail()
  }

  HidePopups(){
    $("#alert_div").hide();
    $('#dots_spinner').hide();
  }

  smtp() { this.router.navigate([this.companyName + '/dashboard/setting']) }
  email() { this.router.navigate([this.companyName + '/dashboard/setting/EmailTemplate']) }
  terms() { this.router.navigate([this.companyName + '/dashboard/setting/term']) }
  listSpecial() { this.router.navigate([this.companyName + '/dashboard/setting/specialMessage']) }
  firebaseRoute(){this.router.navigate([this.companyName + '/dashboard/setting/FirebaseDetails'])}


  getTemplateDetail() {
    var data = {
      IdMerchant: this.IdMerchant,
    }
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.getEmailTempalteApi, data, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      this.status = response.Response;
      // console.log('this.status', response);
      if (response.Response == 1) {
        //this.Template = response.objDetails;
        this.upload_file = response.lst_Uploads;
        this.IdEmailTemplateMaster = response.IdEmailTemplateMaster
        this.Url = response.URL;
        this.URLMD5Sum = response.URLMD5Sum;
        this.Url = this.Url + "?nocache=" + this.URLMD5Sum;
        this.fileName = response.FileName;
        this.ZIpFileURL = response.ZIpFileURL;
        this.ZipFileMD5Sum = response.ZipFileMD5Sum;
        this.ZipUrl = this.ZIpFileURL + "?nocache=" + this.ZipFileMD5Sum;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  saveFile() {
    $('#dots_spinner').show();
    var data = {
      IdMerchant: this.IdMerchant,
      lst_Uploads: this.upload_file,
      IdEmailTemplateMaster: this.IdEmailTemplateMaster,
      
    }
    this.http.post<any>(this.apiList.saveUploadSMTPApi, data, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.FlashError('Successfully saved');
        this.getTemplateDetail()
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }


  fileChange(event) {
    // console.log(event.target.files)
    var filename = event.target.files;
    var abc = filename[0]
    this.fileName = abc.name;
    var _validFileExtensions = [".zip", ".Zip"];
    var blnValid = false;
    for (var j = 0; j < _validFileExtensions.length; j++) {
      var sCurExtension = _validFileExtensions[j];
      if (this.fileName.substr(this.fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        this.uploadFile(abc)
        break;
      }
    }
    if (!blnValid) {
      alert("Sorry, " + this.fileName + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "));
      return false;
    }

  }
  // //Reviewed
  uploadFile(value) {
    $('#dots_spinner').show();
    let formData: FormData = new FormData();
    formData.append('file', value, value.name);
    // console.log('browse', formData)
    this.http.post<any>(this.apiList.uploadSMTPApi + "?IdMerchant=" + this.IdMerchant, formData, this.globalservice.MediaHeaders())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
        if (response.Response == 1) {
          this.upload_file = response.lst_Uploads;
          this.Url = response.URL
          this.URLMD5Sum = response.URLMD5Sum;
          this.Url = this.Url + "?nocache=" + this.URLMD5Sum;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  download() {
    window.location.href = this.ZipUrl;
  }

  preview() {
    // console.log('in');
    window.open(this.Url, "about:blank", "width=400,height=400")
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

}
