import { Component, OnInit, HostListener } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { Sort } from '@angular/material';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service'
import { Subject } from 'rxjs';
declare const $;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  LoginId = + sessionStorage.getItem("LoginId");
  posts: any;
  lists: any;
  IdMachineUser: any;
  Search: any;
  key: any;
  reverse: any;
  p: any;
  Id_Merchant
  ll: any;
  screenHeight: number;
  before: number;
  pageno: any;
  LoginId_orig = sessionStorage.getItem("Login");
  AlertText: string;

  checkedBit:number=0;
  checkedShopper:boolean=false;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate

  constructor(private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,public apiList: ApilistService) {     this.screenHeight = window.innerHeight;
      this.resize$.debounceTime(100).subscribe(innerWidth => {
        if (this.Resizing) { return; }
        this.Repaginate();
        this.SettleAndAutosize(80);
      });
      this.Repaginate();
      this.refreshuserlist();

      $('#alert_div').hide();
    }

  ngOnInit() {
    this.checkedShopper=false;
    this.checkedBit=0;
    $('#dots_spinner').hide();
  }
  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
//console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }
//
  refreshuserlist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.UserListUrl, { LoginId:this.LoginId_orig,IdMerchant: this.IdMerchant,IncludeShoppers:this.checkedBit },this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
        if (response.Response == 1) {
          this.ll = response
        this.lists = this.ll.lst_UserRole;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {  $('#dots_spinner').hide();
      if (this.Resizing) {
        // need to give time to render behind the scenes before size/resize rows
        this.SettleAndAutosize();
      }
       });
  }


  onChangeCheckbox(value){
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
    this.checkedBit =  value.checked == true? 1 : 0
    this.refreshuserlist();
  }

  getID(id) {
    this.Id_Merchant = id;
  }

  user() {
    this.router.navigate([this.companyName + '/dashboard/users/addusers']);
  }

  edit(id) {
    //this.IdMachineUser = id;
   // sessionStorage.setItem("referUser", this.IdMachineUser);
    this.router.navigate([this.companyName + '/dashboard/updateuser',{
      id:id
    }]);
  }

  delete() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteUserUrl, { LoginId:this.LoginId_orig,IdMachineUser: this.Id_Merchant },this.globalservice.Headers())
         .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
        if (response.Response == 1) {
          this.FlashError('Successfully deleted');
      this.refreshuserlist();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
   //-----------sorting----------//
   sortData(sort: Sort) {
    const data = this.lists.slice();
    if (!sort.active || sort.direction === '') {
      this.lists = data;
      return;
    }
    this.lists = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username': return this.compare(a.Name.toLowerCase(), b.Name.toLowerCase(), isAsc);
        // case 'datesetup': return this.compare(a.DateSetup, b.DateSetup, isAsc);
        // case 'datecancelled': return this.compare(a.DateCancelled, b.DateCancelled, isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  //----------------------//

  clearSearch() {
    this.Search = '';
    this.refreshuserlist();
  }

  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }
  
}
