import { Component, OnInit, ViewChild, NgZone, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { TextMaskModule } from 'angular2-text-mask';
import { NgForm, FormControl } from "@angular/forms";
import { Observable } from 'rxjs/Rx';
import { GlobalService } from '../../../global.service';
import { NgxMaskModule } from 'ngx-mask'
import { ApilistService } from '../../../../service/Api/apilist.service'
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';
import { DropdownSearchService } from '../../../dropdown-search.service';
declare const $;
declare var google

@Component({
  selector: 'app-location-information2',
  templateUrl: './location-information2.component.html',
  styleUrls: ['./location-information2.component.css']
})

export class LocationInformation2Component implements OnInit, OnDestroy {
  dstOffset: any;
  rawOffset: any;
  @ViewChild('personForm', { static: false }) public locationForm: NgForm
  // @ViewChild('address') public addressForm: NgForm
  @ViewChild('map', { static: false }) public mapElement: ElementRef;
  companyName = sessionStorage.getItem("companyName");
  IdMerchant = + parseInt(sessionStorage.getItem("referMerchantId"));
  IdMasterMerchant = + sessionStorage.getItem("referMasterMerchantId");


  public mask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  optionsModel = [];
  myOptions = [
    { id: 0, name: 'Sunday' },
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
  ];
  posts: any;
  LocationNumber: number;
  LocationName: string = "";
  LocationBio;
  LocationWeblink;
  LocationAddress: string = "";
  City: string = "";
  State: string = "";
  Zip: any = '';
  Country: string = "";
  LocationPhoneNumber: any;
  ContactPerson: string = "";
  ContactPersonEmailId: string = "";
  ContactPersonPhoneNumber: string;
  CommisiionSplitType: string = "";
  CommisiionSplit: string = "";
  CommisiionSplit1: string = "";
  CommisiionSplit2: string = "";
  CommisiionSplit3: string = "";
  WorkingDaysName: string = "";
  WorkingDays: any;
  StartTime: string = "12:00 AM";
  EndTime: string = "12:00 AM";
  currencyType = "";
  countrylists: any;
  selctedcountry: any;
  citys: any;
  selectedcity: any;
  states: any;
  selectedstate: any;
  IdCountry: any;
  IdState: any;
  IdCity:any;
  AlertText
  isAdding = false
  geometry_address: any;
  countryname: any = '';
  statename: any = '';
  cityname: any = '';
  timezone_address: any;
  timezoneid: string;
  lat: any;
  long: any;
  country_code: any;
  c_code = 1;
  lat_value = true;
  long_value = true;
  pos: { lat: number; lng: number; };
  public searchCountryControl: FormControl = new FormControl();
  public searchStateSearchCtrl: FormControl = new FormControl();
  public searchCityControl: FormControl = new FormControl();
  LocationAddressmanully
  latitude: any;
  longitude: any;
  completeaddress: string;
  LocationAddressmap
  lat_map: any;
  long_map: any;
  cityname_auto: any = '';
  statename_auto: any = '';
  postal_code_auto: any = '';
  Zipcodeerror: number;
  street_number_auto: any = '';
  route_auto: any = '';
  data: any;
  map_error: number = 0;        //map validation
  //abc = new google.maps.places.AutocompleteService();
  check_redirect = 'current';
  lat_current;
  long_current
  save_auto: number;    //firstly save cordinates then save_auto = 1;
  autocheck2: number;
  partial_match: boolean = false;
  map_error_text: string;
  auto_enter: boolean;
  userdata: any;
  completeaddress2: string;
  bit: any = false;
  checkCountry: any;   // check if accoeding to zipcode getting multiple countries then check lookup address country
  chainList:any[]=[];
  optionsModelChain="None";
  chainName: string;
  blob: Blob;
  upload_image: any;
  image: any;
  locNamee: string;
  Name: any;
  zipValidationAddress:string;
  Latitude = '';
  Longitude = '';
  AddressValidated :boolean=false;

  constructor(private router: Router,
    private http: HttpClient,
    public dialog: MatDialog,
    public dropdownService: DropdownSearchService,
    private globalservice: GlobalService, public zone: NgZone, public apiList: ApilistService) {
    this.http.get<any>('./assets/country.txt')
      .subscribe((response) => {
        this.country_code = response;
      });
  }

  ngOnInit() {
    this.AddressValidated=false;
    this.refreshChainlist();
    this.globalservice.setDiscard_check();

    this.globalservice.getCountry().then((res: any) => {
      this.countrylists = res;
      this.dropdownService.countryObject.next(this.countrylists);
      this.dropdownService.CountryArrayObj.next(this.countrylists.slice());
      //this.productForm.setControl('', this.dropdownService.countryControl);
      this.searchCountryControl = this.dropdownService.searchCountryControl;
      this.searchCountryControl.valueChanges
        .subscribe(() => {
          this.dropdownService.countryFilter();
        });
      //console.log(res)
    });
    this.HidePopups();
    this.dateTimeFunction()
    this.admindetails();
    var that = this
    var input = document.getElementById('pac-input');
    var autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.addListener('place_changed', function () {
      $('#dots_spinner').show();
      var place = autocomplete.getPlace();
      //console.log(place);
      if (place.address_components) {
        that.fetch_key(place);
        //console.log(that.check_redirect)
        if (that.check_redirect == 'current') {
          that.check_redirect = 'auto'
          that.map_error = 0;
        }
        if (that.check_redirect == 'auto' || that.check_redirect == 'type') {
          that.check_redirect = 'auto'
          that.map_error = 0;
          that.get_lat_long();
        }
        if (that.check_redirect == 'current2') {
          that.autocheck2 = 1;
          that.map_error = 0;
          that.get_lat_long();
        }
      } else {
        $('#dots_spinner').hide();
        //  console.log(place.name)
        that.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + place.name + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
          // console.log(response);
          that.auto_enter = true
          that.geometry_address = response;
          if (that.geometry_address.status == "OK") {
            var results_count = that.geometry_address.results.length
            if (results_count > 1) {
              $('#dots_spinner').hide();
              that.map_error = 2;
              that.map_error_text = "No match found!"
              that.Addressfild_clear();
            }
            if (results_count == 1) {
              // var codee = that.geometry_address.results[0];
              that.fetch_key(that.geometry_address.results[0]);
              that.get_lat_long();
            }
          } else {
            $('#dots_spinner').hide();
            that.FlashError("Invalid Address");
          }
        })
      }
    })



    // this.myOptions = [
    //   { id: 0, name: 'Sunday' },
    //   { id: 1, name: 'Monday' },
    //   { id: 2, name: 'Tuesday' },
    //   { id: 3, name: 'Wednesday' },
    //   { id: 4, name: 'Thursday' },
    //   { id: 5, name: 'Friday' },
    //   { id: 6, name: 'Saturday' },
    // ];

    $(function () {
      $("#C0").click(function () {
        $("#txt_sale").attr('disabled', 'disabled');
        $("#txt_profit").attr('disabled', 'disabled');
        $("#sel_currency").attr('disabled', 'disabled');
        $("#txt_MonthlyFee").attr('disabled', 'disabled');
        that.CommisiionSplit1 = '';
        that.CommisiionSplit2 = '';
        that.CommisiionSplit3 = '';
        that.CommisiionSplit = '';
        that.CommisiionSplitType = ''
      });
    });

    $(function () {
      $("#C1").click(function () {
        $("#txt_sale").removeAttr('disabled');
        $("#txt_profit").attr('disabled', 'disabled');
        $("#sel_currency").attr('disabled', 'disabled');
        $("#txt_MonthlyFee").attr('disabled', 'disabled');
      });
    });

    $("#C2").click(function () {
      $("#txt_profit").removeAttr('disabled');
      $("#txt_sale").attr('disabled', 'disabled');
      $("#sel_currency").attr('disabled', 'disabled');
      $("#txt_MonthlyFee").attr('disabled', 'disabled');
    });

    $("#C3").click(function () {
      $("#txt_MonthlyFee").removeAttr('disabled');
      $("#txt_sale").attr('disabled', 'disabled');
      $("#sel_currency").removeAttr('disabled');
      $("#txt_profit").attr('disabled', 'disabled');
    });
  }


  HidePopups() {
    $("#alert_div").hide();
    $('#dots_spinner').hide();
    $('#address_modal').modal('hide');
    $('#map_modal').modal('hide');
    $('#addChain').modal('hide');
    $('#myImg').hide();
    $('#main_img_close').hide();
    $('#uploadSuccess').modal('hide');
  }

  dateTimeFunction() {
    $(document).ready(function () {
      $('#timepicker1').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12"
      });
      $('#timepicker2').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12"
      });
    });
    $('body').on('change', '#timepicker1', function () {
      $('#timepicker1').trigger('click');
    });
    $('body').on('change', '#timepicker2', function () {
      $('#timepicker2').trigger('click');
    });
  }

  GetTimezoneID(lat, long) {
    var time = new Date().getTime();
    var date_timestamp = Math.round(time / 1000);
    this.http.get<any>("https://maps.googleapis.com/maps/api/timezone/json?location=" + lat + "," + long + "&timestamp=" + date_timestamp + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.timezone_address = response;
      // console.log(this.timezone_address)
      if (this.timezone_address.status == "OK") {
        this.timezoneid = this.timezone_address.timeZoneId;
        this.dstOffset = this.timezone_address.dstOffset;
        this.rawOffset = this.timezone_address.rawOffset;
      } else {
        this.lat = '';
        this.long = '';
        this.FlashError("Please fill valid latitude and longitude value");
      }
    })
  }
  fetch_key(place) {
    this.Addressfild_clear();
    $('#dots_spinner').hide();
    for (let i = 0; i < place.address_components.length; i++) {
      for (let j = 0; j < place.address_components[i].types.length; j++) {
        let result: string = place.address_components[i].types[j]
        switch (result) {
          case "postal_code":
            this.postal_code_auto = place.address_components[i].long_name;
            break
          case "street_number":
            this.street_number_auto = place.address_components[i].long_name;
            break
          case "route":
            this.route_auto = place.address_components[i].long_name;
            break
          case "locality":
            this.cityname_auto = place.address_components[i].long_name;
            break
          case "administrative_area_level_1":
            this.statename_auto = place.address_components[i].long_name;
            break
          case "country":
            this.countryname = place.address_components[i].long_name;
            this.get_dailingcode(place.address_components[i].long_name);
            break
        }
      }
    }
    this.completeaddress = this.street_number_auto + " " + this.route_auto + ", " + this.cityname_auto + ", " + this.statename_auto + " " + this.postal_code_auto + " " + this.countryname;
    this.completeaddress = this.completeaddress.replace(/  +/g, ' ');
  }

  // canDeactivate(): Observable<boolean> | boolean {
  //   if (!this.isAdding && this.locationForm.dirty) {
  //     return confirm('Are you sure you want to discard your changes?');
  //   } else if (this.locationForm.dirty) {
  //     return confirm('Are you sure you want to discard your changes?');
  //   }
  //   return true;
  // }
  canDeactivate(): any {
    if (!this.isAdding && this.locationForm.dirty) {
      if (this.bit == false && this.globalservice.GetDiscard_Check()) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });
        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response == "ok")) {
            this.dialog.closeAll();
            this.bit = true;
            this.router.navigate([this.companyName + '/dashboard/locations']);
          }
        });
      }
    } else if (this.locationForm.dirty) {
      if (this.bit == false) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });
        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response == "ok")) {
            this.dialog.closeAll();
            this.bit = true;
            this.router.navigate([this.companyName + '/dashboard/locations']);
          }
        });
      }
    }
    return true;
  }
  onChange(value) {
    this.currencyType = value;
  }

  sale() {
    this.CommisiionSplit = this.CommisiionSplit1;
    this.CommisiionSplitType = "% of sale";
  }

  gross() {
    this.CommisiionSplit = this.CommisiionSplit2;
    this.CommisiionSplitType = "% of Gross Profit";
  }

  fees() {
    this.CommisiionSplit = this.CommisiionSplit3;
    this.CommisiionSplitType = "Monthly Fees";
  }

  startTime(time: string) {
    this.StartTime = time;
  }

  ondrop(event) {
  }

  endTimelocation(tim: string) {
    this.EndTime = tim;
  }

  changeday() {
    $('#uncheckall').removeClass("btn_active");
    $('#checkall').removeClass("btn_active");
  }

  selectAll() {
    this.optionsModel = [0, 1, 2, 3, 4, 5, 6]
    $('#checkall').addClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
  }

  autocomplete_length(value) {
    if (this.check_redirect == 'current2') {
      if (value.target.value) {
        if (this.auto_enter != true) {
          this.autocheck2 = 0
          this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + value.target.value + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
            this.geometry_address = response;
            if (this.geometry_address.status == "OK") {
              var results_count = this.geometry_address.results.length
              if (results_count > 1) {
                $('#dots_spinner').hide();
                this.map_error = 2;
                this.map_error_text = "No match found!"
                this.Addressfild_clear();
              }
              if (results_count == 1) {
                var codee = this.geometry_address.results[0];
                this.fetch_key(codee);
                this.addAddressData()
                var lat_ab = this.geometry_address.results[0].geometry.location.lat;
                var long_ab = this.geometry_address.results[0].geometry.location.lng;
                var distance = this.getDistanceFromLatLonInKm(lat_ab, long_ab, this.lat, this.long)
                if (distance > 16) {
                  this.map_error = 1;
                  this.map_error_text = "Address and coordinates are too far apart!";
                } else {
                  this.map_error = 0;
                }
              }
            } else {
              this.FlashError("Invalid Address");
            }
          })
        } else {

        }
      } else {
        this.Addressfild_clear()
      }
    }
    else {
      if (value.target.value) {
        if (this.auto_enter != true) {
          this.check_redirect = 'type';
          // this.check_address_valid();
          $('#dots_spinner').hide();
          this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + value.target.value + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
            // console.log(response);
            this.geometry_address = response;
            if (this.geometry_address.status == "OK") {
              var results_count = this.geometry_address.results.length
              if (results_count > 1) {
                $('#dots_spinner').hide();
                this.map_error = 2;
                this.map_error_text = "No match found!";
                this.Addressfild_clear();
              }
              if (results_count == 1) {
                var codee = this.geometry_address.results[0];
                this.fetch_key(codee);
                this.get_lat_long();

              }
            } else {
              $('#dots_spinner').hide();
              this.FlashError("Invalid Address");
            }
          })
        }
      } else {
        this.Addressfild_clear();
        this.check_redirect = 'current';
      }
    }
  }


  addAddressData() {
    let data = {
      ZipCode: this.postal_code_auto,
      City: this.cityname_auto,
      State: this.statename_auto,
      Country: this.countryname,
      Latitude: this.lat,
      Longitude: this.long,
      PhoneCode: this.c_code
    }
    this.http.post<any>(this.apiList.AddZipCodeUrl, data, this.globalservice.Headers()).subscribe((response) => {
    }, (err: any) => {
      const Error = this.globalservice.ProcessError(err);
      if (Error.Response === 35) {
        if (Error.ErrorMessage) {
          this.FlashError(Error.ErrorMessage);
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
        return;
      } else {
        this.FlashError(Error.ErrorMessage);
        return;
      }
    }).add(() => { $('#dots_spinner').hide(); });
  }

  deselectAll() {
    this.optionsModel = [];
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').addClass("btn_active");
  }

  async ZipDetail() {
    if (this.Zip) {
    $('#dots_spinner').show();
    var Zipdetails: any = await this.globalservice.GetZIPDetails(this.Zip)
    //console.log(Zipdetails);
    $('#dots_spinner').hide();

    if (Zipdetails.Response == 1) {
      // this.selctedcountry = Zipdetails.CountryName;

      // this.IdCountry = Zipdetails.CountryId;
      if (Zipdetails.lst_Countries.length == 1) {
        this.countrylists = Zipdetails.lst_Countries;
        this.dropdownService.countryObject.next(this.countrylists);
        this.dropdownService.CountryArrayObj.next(this.countrylists.slice());
        this.searchCountryControl = this.dropdownService.searchCountryControl;
        this.searchCountryControl.valueChanges
          .subscribe(() => {
            this.dropdownService.countryFilter();
          });
        this.selctedcountry = Zipdetails.lst_Countries[0].CountryName;
        this.countryname = Zipdetails.lst_Countries[0].CountryName;
        //console.log(this.selctedcountry)
        //console.log(this.countryname)
        this.IdCountry = Zipdetails.lst_Countries[0].IdCountry;


        if (this.IdCountry) {
          this.getstates();
          if (Zipdetails.lst_States.length == 1) {
            this.selectedstate = Zipdetails.lst_States[0].StateName;
            this.statename = Zipdetails.lst_States[0].StateName;
            this.IdState = Zipdetails.lst_States[0].IdState;
            if (this.IdState) {
              this.getcity();
              if (Zipdetails.lst_Cities.length == 1) {
                console.log("zipdetails city"+JSON.stringify(Zipdetails.lst_Cities));
                this.selectedcity = Zipdetails.lst_Cities[0].CityName;
                this.cityname = Zipdetails.lst_Cities[0].CityName;
                this.IdCity = Zipdetails.lst_Cities[0].CityId;
                console.log("id city"+this.IdCity);
              }
              if (Zipdetails.lst_Cities.length > 1) {
                this.citys = Zipdetails.lst_Cities;
              }
            }
          }
          if (Zipdetails.lst_States.length > 0) {
            this.states = Zipdetails.lst_States
          }
        }
      }
      if (Zipdetails.lst_Countries.length == 0) {   // when no country
        // this.globalservice.getCountry().then((res:any)=>{
        //   this.countrylists = res;
        //  // console.log(res);
        // });
        this.globalservice.getCountry().then((res:any)=>{
          this.countrylists = res;
          this.dropdownService.countryObject.next(this.countrylists);
          this.dropdownService.CountryArrayObj.next(this.countrylists.slice());
          this.searchCountryControl = this.dropdownService.searchCountryControl;
          this.searchCountryControl.valueChanges
            .subscribe(() => {
              this.dropdownService.countryFilter();
            });
        });
        this.selctedcountry = '';
        this.selectedstate = ''
        this.selectedcity = '';
      }
      if (Zipdetails.lst_Countries.length > 1) {
        Zipdetails.lst_Countries.forEach(element => {
          //  console.log(this.checkCountry)
          if (element.CountryName == this.checkCountry) {
            this.selctedcountry = element.CountryName;
            this.countryname = element.CountryName;
            this.IdCountry = element.IdCountry;
            if (this.IdCountry) {
              //    this.getstates();
              if (Zipdetails.lst_States.length == 1) {
                this.selectedstate = Zipdetails.lst_States[0].StateName;
                this.statename = Zipdetails.lst_States[0].StateName;
                this.IdState = Zipdetails.lst_States[0].IdState;
                if (this.IdState) {
                  //  this.getcity();
                  if (Zipdetails.lst_Cities.length == 1) {
                    console.log("zipdetails city"+JSON.stringify(Zipdetails.lst_Cities));
                    this.selectedcity = Zipdetails.lst_Cities[0].CityName;
                    this.cityname = Zipdetails.lst_Cities[0].CityName;
                    this.IdCity = Zipdetails.lst_Cities[0].CityId;
                    console.log("id city"+this.IdCity);
                  }
                  if (Zipdetails.lst_Cities.length > 1) {
                    this.citys = Zipdetails.lst_Cities;
                  }
                }
              }
              if (Zipdetails.lst_States.length > 1) {
                this.states = Zipdetails.lst_States
              } else {
                this.getstates()
              }
            }
          } else {
           // this.globalservice.getCountry().then((res: any) => {
              this.countrylists = Zipdetails.lst_Countries;
              this.dropdownService.countryObject.next(this.countrylists);
              this.dropdownService.CountryArrayObj.next(this.countrylists.slice());
              //this.productForm.setControl('', this.dropdownService.countryControl);
              this.searchCountryControl = this.dropdownService.searchCountryControl;
              this.searchCountryControl.valueChanges
                .subscribe(() => {
                  this.dropdownService.countryFilter();
                });
              //console.log(res)
           // });
           // this.countrylists = Zipdetails.lst_Countries;
          }
        });
        // 
        this.selectedstate = ''
        this.selectedcity = '';
      }

    } else if (Zipdetails.Response === 35) {
      this.AlertText = "";
      if (Zipdetails.ErrorMessage) {
        this.FlashError(Zipdetails.ErrorMessage);
        this.globalservice.Logout('Yes');
      }
      this.globalservice.Logout('No');
    }
    else {
      this.selctedcountry = '';
      this.selectedstate = ''
      this.selectedcity = '';
      this.FlashError(Zipdetails.ErrorMessage);
    }
  }
  else{
    this.clearSearch();
  }
 
  }

  clearSearch() {
    this.Zip = '';
    this.selctedcountry = '';
    this.selectedcity = '';
    this.selectedstate = '';
    var country=[];
    this.dropdownService.countryObject.next(country);
    this.dropdownService.CountryArrayObj.next(country.slice());

var state=[];
    this.dropdownService.stateObject.next(state);
            this.dropdownService.StateArrayObj.next(state.slice());
            var city=[];
            this.dropdownService.cityObject.next(city);
            this.dropdownService.CityArrayObj.next(city.slice());

  }

  get_dailingcode(name) {
    var code = this.country_code.filter((item: any) => {
      return (item.name.toLowerCase().indexOf(this.countryname.toLowerCase()) > -1);
    })
    for (let k = 0; k < code.length; k++) {
      if (name === code[k].name) {
        this.c_code = code[k].dial_code;
      }
    }
  }

  onChangeCountry(a, b) {
    this.IdCountry = a;
    // console.log(this.IdCountry);
    this.countryname = b,
      this.selectedstate = '';
    this.selectedcity = ''
    // console.log(this.countryname);
    this.getstates();
  }

  getstates() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.StateUrl, { IdCountry: this.IdCountry }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(resp => {
        if (resp == null || resp.Response == null) { throw { Response: 0, ErrorMessage: 'Null Response' }; }
        resp.Response = +resp.Response;
        if (resp.Response !== 1) { throw resp; }
        return resp;
      })
      .subscribe((response) => {
        this.states = response.lst_State;
      //  console.log("states returned are"+JSON.stringify(this.states));
        this.dropdownService.stateObject.next(this.states);
            this.dropdownService.StateArrayObj.next(this.states.slice());

          //  console.log("state object displayed in front end"+JSON.stringify(this.dropdownService.StateArrayObj));
            this.searchStateSearchCtrl = this.dropdownService.searchStateControl;
            this.searchStateSearchCtrl.valueChanges
                .subscribe(() => {
                    this.dropdownService.stateFilter();
                });
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  onChangeState(c, d) {
    this.IdState = c;
    this.statename = d;
    this.selectedcity = ''
    this.getcity();
  }

  getcity() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CityUrl, { IdState: this.IdState }, this.globalservice.Headers())
      .subscribe((response) => {
        this.citys = response.lst_City;
        this.dropdownService.cityObject.next(this.citys);
            this.dropdownService.CityArrayObj.next(this.citys.slice());
            this.searchCityControl = this.dropdownService.searchCityControl;
            this.searchCityControl.valueChanges
                .subscribe(() => {
                    this.dropdownService.cityFilter();
                });
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  onchangecity(e,id) {
    this.cityname = e;
    this.IdCity=id;
    console.log("idcity"+id);
  }

  confirm_address() {
    // this.completeaddress = this.LocationAddressmanully + " " + this.cityname + ", " + this.statename + " " + this.Zip + " " + this.countryname;
    if (this.LocationAddressmanully && this.selctedcountry && this.selectedstate) {
      if (this.check_redirect == 'auto' || this.check_redirect == 'current') {
        this.APICAll();
      }
      else {
        this.APICAll();
      }
    }
  }

  APICAll() {
    this.map_error = 0;
    $('#dots_spinner').show();
    this.completeaddress = this.LocationAddressmanully + ", " + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
    // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
    //console.log(this.completeaddress);
    this.completeaddress = this.completeaddress.replace(/  +/g, ' ');
    this.route_auto = '';
    this.street_number_auto = this.LocationAddressmanully;
    this.cityname_auto = this.selectedcity;
    // this.selectedcity = this.cityname_auto
    this.statename_auto = this.selectedstate;
    // this.selectedstate = this.statename_auto
    this.countryname = this.selctedcountry;
    //this.selctedcountry = this.countryname;
    this.postal_code_auto = this.Zip;
    this.LocationAddress = this.completeaddress;
    this.get_dailingcode(this.countryname);
    this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.completeaddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.geometry_address = response;
      // console.log(this.geometry_address);
      if (this.geometry_address.status == "OK") {
        //  console.log(this.geometry_address.results.length);
        $('#dots_spinner').hide();
        $('#manual_address_modal').modal('hide');
        this.check_redirect = 'edit';
        var results_count = this.geometry_address.results.length
        if (results_count > 1) {
          for (let k = 0; k < this.geometry_address.results.length; k++) {
            for (let i = 0; i < this.geometry_address.results[k].address_components.length; i++) {
              for (let j = 0; j < this.geometry_address.results[k].address_components[i].types.length; j++) {
                if (this.geometry_address.results[k].address_components[i].types[j] == "postal_code") {
                  if (this.Zip == this.geometry_address.results[k].address_components[i].long_name) {
                    if (this.save_auto != 1) {
                      this.lat = this.geometry_address.results[k].geometry.location.lat;
                      this.long = this.geometry_address.results[k].geometry.location.lng;
                      //console.log(this.lat, this.long)
                    } else {
                      var latt = this.geometry_address.results[k].geometry.location.lat;
                      var longg = this.geometry_address.results[k].geometry.location.lng;
                      var distance = this.getDistanceFromLatLonInKm(latt, longg, this.lat, this.long)
                      //console.log(distance);
                      if (distance > 16) {
                        this.map_error = 1;
                        this.map_error_text = "Address and coordinates are too far apart!";
                      } else {
                        this.map_error = 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        else {
          if (this.save_auto != 1) {
            this.lat = this.geometry_address.results[0].geometry.location.lat;
            this.long = this.geometry_address.results[0].geometry.location.lng;
            //console.log(this.lat, this.long)
          } else {
            var latt = this.geometry_address.results[0].geometry.location.lat;
            var longg = this.geometry_address.results[0].geometry.location.lng;
            var distance = this.getDistanceFromLatLonInKm(latt, longg, this.lat, this.long)
            // console.log(distance);
            if (distance > 16) {
              this.map_error = 1;
              this.map_error_text = "Address and coordinates are too far apart!";
            } else {
              this.map_error = 0;
            }
          }
        }
      } else {
        $('#dots_spinner').hide();
        this.FlashError("Invalid Address");
      }
    })
  }

  map() {
    let result: string = this.check_redirect
    switch (result) {
      case "type":
        this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.LocationAddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
          this.geometry_address = response;
          //console.log(this.geometry_address);
          if (this.geometry_address.status == "OK") {
            $('#map_modal').modal('show');
            var results_count = this.geometry_address.results.length
            if (results_count > 1) {
              this.getCurrent_location();
            }
            if (results_count == 1) {
              if (this.save_auto != 1) {
                this.lat = this.geometry_address.results[0].geometry.location.lat;
                this.long = this.geometry_address.results[0].geometry.location.lng;
              }
              var codee = this.geometry_address.results[0];
              this.fetch_key(codee);
              this.show_map_address(this.lat, this.long)
            }
          } else {
            this.FlashError("Invalid Address");
          }
        })
        break;
      case "auto":
        $('#map_modal').modal('show');
        this.show_map_address(this.lat, this.long)
        break;
      case "edit":
        $('#map_modal').modal('show');
        this.LocationAddressmap = this.completeaddress;
        this.show_map_address(this.lat, this.long)
        break;
      case "current2":
        $('#map_modal').modal('show');
        this.show_map_address(this.lat, this.long)
        break;
      default:
        $('#map_modal').modal('show');
        this.getCurrent_location();
    }
  }

  getCurrent_location() {
    var that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        that.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        that.show_map_address(that.pos.lat, that.pos.lng);
      },
        function (error) {
          //console.log(error);
          that.admindetails();
        });
    }
  }

  Ok(lat, long) {
    var lat_range = this.isBetween(lat, 90, -90)
    var long_range = this.isBetween(long, 180, -180)
    if (lat_range == true && long_range == true) {
      this.show_map_address(lat, long)
    }
    else if (lat_range == false && long_range == false) {
      this.FlashError("The latitude and longitude value is invalid");
    }
    else if (lat_range == false && long_range == true) {
      this.FlashError("The latitude  value is invalid");
    }
    else if (lat_range == true && long_range == false) {
      this.FlashError("The longitude value is invalid");
    }
  }

  isBetween(n, a, b) {
    return (n - a) * (n - b) <= 0
  }


  map_address_serach(value) {
    this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + value + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.geometry_address = response;
      if (this.geometry_address.status == "OK") {
        var lat = this.geometry_address.results[0].geometry.location.lat;
        var long = this.geometry_address.results[0].geometry.location.lng;
        this.show_map_address(lat, long)
      } else {
        this.FlashError("Invalid Address");
      }
    })
  }

  show_map_address(lat, long) {
    this.lat_map = lat;
    this.long_map = long;
    var that = this;
    var map
    map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: this.lat_map, lng: this.long_map },
      zoom: 8,
    })
    var marker = new google.maps.Marker({
      position: { lat: this.lat_map, lng: this.long_map },
      map: map,
      draggable: true,
    });
    marker.setMap(map);
    google.maps.event.addListener(marker, 'drag', function (event) {
      that.lat_map = event.latLng.lat();
      that.long_map = event.latLng.lng();
      that.get_address()
    });
    that.get_address()
  }

  save_map_position(lat, long, name) {
    if (this.check_redirect == 'current' || this.check_redirect == 'type' || this.check_redirect == 'current2') {
      this.save_map_current(lat, long, name);
      this.GetTimezoneID(lat, long);
    } else {
      this.save_map(lat, long, name);
      this.GetTimezoneID(lat, long);
    }
  }

  save_map_current(lat, long, name) {
    this.map_error = 0;
    this.lat_map = lat
    this.long_map = long
    this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + this.lat_map + "," + this.long_map + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.geometry_address = response;
      // console.log(this.geometry_address);
      if (this.geometry_address.status == "OK") {
        $('#map_modal').modal('hide')
        this.check_switch_case();
        this.save_auto = 1;
      } else {
        this.FlashError("Invalid Address");
      }
    })
  }

  check_switch_case() {
    let result = this.check_redirect
    switch (result) {
      case "current":
        var codee = this.geometry_address.results[0];
        this.fetch_key(codee);
        this.LocationAddress = this.LocationAddressmap;
        this.check_redirect = 'current2'
        this.lat = this.lat_map;
        this.long = this.long_map;
        break;
      case "current2":
        if (this.save_auto != 1) {
          var codee = this.geometry_address.results[0];
          this.fetch_key(codee);
          this.LocationAddress = this.LocationAddressmap;
          this.lat = this.lat_map;
          this.long = this.long_map;
        } else {
          this.calculate_distance_address(this.completeaddress);
          this.lat = this.lat_map;
          this.long = this.long_map;
        }
        break;
      case "type":
        var codee = this.geometry_address.results[0];
        this.fetch_key(codee);
        this.LocationAddress = this.LocationAddressmap;
        this.check_redirect = 'auto';
        break;
      default:
        var codee = this.geometry_address.results[0];
        this.fetch_key(codee);
        this.lat = this.lat_map;
        this.long = this.long_map;
    }
  }

  save_map(lat, long, name) {
    this.map_error = 0;
    this.lat_map = lat
    this.long_map = long
    this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + this.lat_map + "," + this.long_map + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.geometry_address = response;
      //console.log(this.geometry_address);
      if (this.geometry_address.status == "OK") {
        $('#map_modal').modal('hide');
        if (this.save_auto != 1) {
          this.lat = this.lat_map;
          this.long = this.long_map;
          this.calculate_distance_address(this.completeaddress);
        } else {
          this.lat = this.lat_map;
          this.long = this.long_map;
          this.calculate_distance_address(this.completeaddress);
        }
      } else {
        this.FlashError("Invalid Address");
      }
      this.save_auto = 1;
    })
  }

  calculate_distance_address(name) {
    //console.log(name);
    this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + name + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.geometry_address = response
      // console.log(this.geometry_address);
      if (this.geometry_address.status == "OK") {
        var results_count = this.geometry_address.results.length
        if (results_count > 1) {
          for (let k = 0; k < this.geometry_address.results.length; k++) {
            for (let i = 0; i < this.geometry_address.results[k].address_components.length; i++) {
              for (let j = 0; j < this.geometry_address.results[k].address_components[i].types.length; j++) {
                if (this.geometry_address.results[k].address_components[i].types[j] == "postal_code") {
                  if (this.postal_code_auto == this.geometry_address.results[k].address_components[i].long_name) {
                    var a = this.geometry_address.results[k].geometry.location.lat;
                    var b = this.geometry_address.results[k].geometry.location.lng;
                    // console.log(a, b, this.lat_map, this.long_map)
                    var distance = this.getDistanceFromLatLonInKm(a, b, this.lat_map, this.long_map)
                    //console.log(distance);
                    if (distance > 16) {
                      this.map_error = 1;
                      this.map_error_text = "Address and coordinates are too far apart!";
                    } else {
                      this.map_error = 0;
                    }
                  }
                }
              }
            }
          }
        } else {
          var a = this.geometry_address.results[0].geometry.location.lat;
          var b = this.geometry_address.results[0].geometry.location.lng;
          // console.log(a, b, this.lat_map, this.long_map)
          var distance = this.getDistanceFromLatLonInKm(a, b, this.lat_map, this.long_map)
          // console.log(distance);
          if (distance > 16) {
            this.map_error = 1;
            this.map_error_text = "Address and coordinates are too far apart!";
          } else {
            this.map_error = 0;
          }
        }
      }
    })
  }

  get_address() {
    this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + this.lat_map + "," + this.long_map + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.geometry_address = response
      if (this.geometry_address.status == "OK") {
        // console.log(this.geometry_address);
        this.LocationAddressmap = this.geometry_address.results[0].formatted_address;
      }
    });
  }

  get_lat_long() {

    this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.completeaddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      // console.log(this.geometry_address);
      this.geometry_address = response
      if (this.geometry_address.status == "OK") {
        $('#dots_spinner').hide();
        // this.complete_address = this.geometry_address.results[0].formatted_address
        if (this.save_auto != 1) {
          //  console.log('hello')
          var codee = this.geometry_address.results[0];
          this.fetch_key(codee);
          this.lat = this.geometry_address.results[0].geometry.location.lat;
          this.long = this.geometry_address.results[0].geometry.location.lng;
          this.GetTimezoneID(this.lat, this.long);
          this.addAddressData();
        } else {
          var ab = this.geometry_address.results[0].geometry.location.lat;
          var cd = this.geometry_address.results[0].geometry.location.lng;
          this.GetTimezoneID(ab, cd);
          if (this.check_redirect == 'current2') {
            var distance = this.getDistanceFromLatLonInKm(ab, cd, this.lat, this.long)
          } else {
            var distance = this.getDistanceFromLatLonInKm(ab, cd, this.lat, this.long)
          }
          if (distance > 16) {
            this.map_error = 1;
            this.map_error_text = "Address and coordinates are too far apart!";
          } else {
            this.map_error = 0;
          }
        }
      } else {
        this.FlashError("Invalid Address");
      }
    })
  }

  Edit_address() {
    $('#manual_address_modal').modal('show');
    // console.log(this.check_redirect, this.autocheck2);
    if (this.check_redirect == 'current' || this.check_redirect == 'edit') {
      // console.log('dhfhdhfbhjfbh')
      this.check_redirect = 'edit';
    } else if (this.check_redirect == 'auto') {
      // console.log('auto')
      if (this.street_number_auto) {
        this.LocationAddressmanully = this.street_number_auto + " " + this.route_auto;
      } else {
        this.LocationAddressmanully = this.route_auto;
      }
      this.Zip = this.postal_code_auto;
      //  this.selctedcountry = this.countryname
      this.ZipDetail();
    } else if (this.check_redirect == 'current2' && this.autocheck2 == 1) {
      // console.log('dhfhd')
      if (this.street_number_auto) {
        this.LocationAddressmanully = this.street_number_auto + " " + this.route_auto;
      } else {
        this.LocationAddressmanully = this.route_auto;
      }
      this.Zip = this.postal_code_auto;
      // this.selctedcountry = this.countryname
      this.ZipDetail();
    }
    else {
      this.check_address_valid()
    }
  }

  check_address_valid() {
    if (this.LocationAddress) {
      this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.LocationAddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
        this.geometry_address = response;
        //  console.log(this.geometry_address);
        if (this.geometry_address.status == "OK") {
          var results_count = this.geometry_address.results.length
          if (results_count > 1) {
            this.map_error = 2;
            this.map_error_text = "No match found!"
          }
          if (results_count == 1) {
            if (this.geometry_address.status == "OK") {
              // this.partial_match = this.geometry_address.results[0].partial_match;
              for (let i = 0; i < this.geometry_address.results[0].address_components.length; i++) {
                for (let j = 0; j < this.geometry_address.results[0].address_components[i].types.length; j++) {
                  if (this.geometry_address.results[0].address_components[i].types[j] == "country") {
                    this.checkCountry = this.geometry_address.results[0].address_components[i].long_name;
                  }
                  if (this.geometry_address.results[0].address_components[i].types[j] == "postal_code") {
                    this.Zip = this.geometry_address.results[0].address_components[i].long_name;
                    this.ZipDetail();
                  }
                  if (this.geometry_address.results[0].address_components[i].types[j] == "street_number") {
                    this.street_number_auto = this.geometry_address.results[0].address_components[i].long_name;
                  }
                  if (this.geometry_address.results[0].address_components[i].types[j] == "route") {
                    this.route_auto = this.geometry_address.results[0].address_components[i].long_name;
                  }

                }
              }
              if (this.street_number_auto) {
                this.LocationAddressmanully = this.street_number_auto + " " + this.route_auto;
              } else {
                this.LocationAddressmanully = this.route_auto;
              }
            }
          }
        } else {
          this.FlashError("Invalid Address");
        }
      })
    }
  }

  admindetails() {
    if (this.IdMasterMerchant == 0) {
      var data = {
        IdMerchant: this.IdMerchant
      }
    } else {
      var data = {
        IdMerchant: this.IdMasterMerchant
      }
    }
    this.http.post<any>(this.apiList.MerchantDetailUrl, data, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(resp => {
        if (resp == null || resp.Response == null) { throw { Response: 0, ErrorMessage: 'Null Response' }; }
        resp.Response = +resp.Response;
        if (resp.Response !== 1) { throw resp; }
        return resp;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.userdata = response;
          // console.log(this.userdata)
          var Zipcode_merchant = '' + this.userdata.obj_MerchantRecord.Zip;
          this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + Zipcode_merchant + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
            this.geometry_address = response
            if (this.geometry_address.status == "OK") {
              var lat = this.geometry_address.results[0].geometry.location.lat;
              var long = this.geometry_address.results[0].geometry.location.lng;
              this.show_map_address(lat, long);
            }
          });
        }

      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { });
  }

  Address() {
    var time = new Date().getTime();
    var date_timestamp = Math.round(time / 1000);
    if (this.lat && this.long) {
      this.http.get<any>("https://maps.googleapis.com/maps/api/timezone/json?location=" + this.lat + "," + this.long + "&timestamp=" + date_timestamp + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
        this.timezone_address = response;
        //  console.log(this.timezone_address)
        if (this.timezone_address.status == "OK") {
          this.timezoneid = this.timezone_address.timeZoneId;
          this.dstOffset = this.timezone_address.dstOffset;
          this.rawOffset = this.timezone_address.rawOffset;
          this.save();
        } else {
          this.lat = '';
          this.long = '';
          this.FlashError("Please fill valid latitude and longitude value");
        }
      })
    } else {
      this.FlashError("Your address is invalid. Please enter valid address");
    }
  }

  save() {
    this.ZipcodeExist();

    setTimeout(()=>{
      console.log("this.addressvalidated"+this.AddressValidated);
      if(this.AddressValidated){
        if(this.optionsModelChain=="None"){
          this.optionsModelChain=null;
        }
        this.LocationPhoneNumber = this.c_code + this.LocationPhoneNumber;
        this.ContactPersonPhoneNumber = this.c_code + this.ContactPersonPhoneNumber;
        if (this.street_number_auto) {
          var street_address: any = this.street_number_auto + " " + this.route_auto;
        } else {
          var street_address: any = this.route_auto;
        }
        if (this.upload_image === 1) {
        this.isAdding = true;
        $('#dots_spinner').show();
        var a = this.optionsModel;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        this.http.post<any>(this.apiList.AddLocationUrl, { LocationNumber: this.LocationNumber, LocationName: this.LocationName, LocationAddress: this.completeaddress, LocationPhoneNumber: this.LocationPhoneNumber, ContactPerson: this.ContactPerson, ContactPersonEmailId: this.ContactPersonEmailId, ContactPersonPhoneNumber: this.ContactPersonPhoneNumber, CommisiionSplitType: this.CommisiionSplitType, CurrencyType: this.currencyType, CommisiionSplit: this.CommisiionSplit, CreatedBy: this.IdMerchant, WorkingDays: newstr, WorkingDaysName: this.WorkingDaysName, StartTime: this.StartTime, EndTime: this.EndTime, Longitude: this.long, Latitude: this.lat, TimeZoneId: this.timezoneid, dstOffset: this.dstOffset, rawOffset: this.rawOffset, StreetAddress: street_address, City: this.cityname_auto, State: this.statename_auto, Country: this.countryname, Zipcode: this.postal_code_auto,IdChain:this.optionsModelChain,LocationImageName:this.Name,Bio:this.LocationBio,Weblink:this.LocationWeblink,AddressValidated :this.AddressValidated,IdCountry :this.IdCountry,IdState :this.IdState,IdCity :this.IdCity }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(resp => {
            if (resp == null || resp.Response == null) { throw { Response: 0, ErrorMessage: 'Null Response' }; }
            resp.Response = +resp.Response;
            if (resp.Response !== 1) { throw resp; }
            return resp;
          })
          .subscribe((response) => {
            this.posts = response;
            if (this.posts.Response == 1) {
              this.locationForm.reset();
              this.router.navigate([this.companyName + '/dashboard/locations']);
            }
          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => { $('#dots_spinner').hide(); });
    } else {
          this.FlashError('Firstly upload location Image');
        }
      }
  
      else{
        this.FlashError('Address is not validated');
      }
    },2000);
    
    
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {    //calculate distance
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }
  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/locations']);
  }

  Addressfild_clear() {
    this.completeaddress = '';
    this.street_number_auto = '';
    this.route_auto = '';
    this.cityname_auto = '';
    this.statename_auto = '';
    this.countryname = '';
    this.postal_code_auto = "";
  }

  ngOnDestroy() {
    this.dialog.ngOnDestroy();
  }

  // get chains
  refreshChainlist() {
    $('#dots_spinner').show();
   // console.log(this.globalservice.Headers())
    
    this.http.post<any>(this.apiList.MerchantChains,{} , this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          console.log("merchant chain list" + JSON.stringify(response));
          this.chainList = response.Chains;
          $('#dots_spinner').hide();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
            $('#dots_spinner').hide();
          }
          this.globalservice.Logout('No');
          $('#dots_spinner').hide();
          return;
        } else {
          $('#dots_spinner').hide();
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
        
      });
  }
  onChangechain(id: string) {
  this.optionsModelChain=id;
  }

  addNewChain(){
    this.chainName = "";
    console.log("inside modal open");
    $('#addChain').modal('show');
  }

  
  AddChainData() {
    // this.productForm.value.SalesTaxAmount
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.AddChain,
      { Name: this.chainName }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        // this.list = response;
        this.FlashError('Successfully Added');
        this.refreshChainlist();

      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
        $('#addChain').modal('hide');
        this.chainName = "";
        // if (this.Resizing) {
        //   // need to give time to render behind the scenes before size/resize rows	        // need to give time to render behind the scenes before size/resize rows
        //   this.SettleAndAutosize(500);
        // }
      });


  }
  close_addchain() {
    $('#addChain').modal('hide');
    this.chainName = "";

  }
  MainImageUpload(file: File) {
    this.blob = new Blob([file[0]], { type: file[0].type });
    console.log(file);
    this.uploadFile()
  }
  uploadFile() {
    $('#dots_spinner').show();
    this.globalservice.uploadFiles(this.blob).then((res: any) => {
      if (res.Response == 1) {
        $('#dots_spinner').hide();
        this.upload_image = 1;
        this.locNamee = res.PreviewUrl;
        this.Name = res.FileName
        $('#myImg').show(2000, function () {
          $('#main_img_close').show();
          $('#uploadSuccess').modal('show');
        });
        $('#upload-btn-wrapper').hide();
      }
    }, error => {
      const Error = this.globalservice.ProcessError(error);
      if (Error.Response === 35) {
        if (Error.ErrorMessage) {
          this.FlashError(Error.ErrorMessage);
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
        return;
      } else {
        console.log(Error.ErrorMessage)
        // event.target.value = null;
        this.FlashError(Error.ErrorMessage);
        return;
      }
    })
  }
  remove() {
    this.locNamee = ''
    this.upload_image = '';
   // this.myInputVariable.nativeElement.value = '';
    $('#myImg').hide();
    $('#main_img_close').hide();
    $('#upload-btn-wrapper').show();
  }


  ZipcodeExist() {
    console.log("iinside zip exists");
    if (this.selectedcity) {
      this.http.post<any>(this.apiList.CheckZipCodeExist,
        { ZipCode: this.Zip, IdCity: this.selectedcity }, this.globalservice.headerWithoutauth())
        .subscribe((response) => {
          if (response) {
            if (response.Response === 1) {
              this.Latitude = response.Latitude;
              this.Longitude = response.Longitude;
              this.AddressValidated=true;
              console.log("iinside zip exists valuid");
            } else if (response.Response === 4) {
              // this.AlertText = "The zip code "+  this.Zip  +" is not recognized, are you sure?";
              // $('#myModal').modal('show');
              this.checkZipGoogleApi();
            } else {
              this.FlashError("There was an internal error processing this zip code");
            }
          }
        }, (err) => {
          console.log("iinside zip error");
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        });
    } else {
      this.Latitude = '';
        this.Longitude = '';
        this.AddressValidated=false;
      this.timezoneid='';
      this.dstOffset='';
      this.rawOffset='';
      this.FlashError('Please select city first');
    }
  }

  checkZipGoogleApi(){
    console.log("iinside zip exists check google");
    if(this.Zip && this.Zip!=="" ){
      console.log("inside zip entered");
      this.zipValidationAddress = this.selectedcity + " " + this.Zip ;
      // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
      // //console.log(this.completeaddress);
       this.zipValidationAddress = this.zipValidationAddress.replace(/  +/g, ' ');
      // this.completeaddress = this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selectedcountry;
      // // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
      // //console.log(this.completeaddress);
      // this.completeaddress = this.completeaddress.replace(/  +/g, ' ');
      // this.completeaddress = this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selectedcountry;
      // // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
      // //console.log(this.completeaddress);
      // this.completeaddress = this.completeaddress.replace(/  +/g, ' ');
      this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.zipValidationAddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
        this.geometry_address = response;
        if (this.geometry_address.status == "OK") {
console.log("inside status ok");
this.AddressValidated=true;
          var lat = this.geometry_address.results[0].geometry.location.lat;
          var long = this.geometry_address.results[0].geometry.location.lng;
          this.GetTimezoneID(lat, long);
  
         
        } else {
          console.log("inside status not ok");
          this.Latitude = '';
          this.Longitude = '';
          
          this.timezoneid='';
          this.dstOffset='';
          this.rawOffset='';
          this.AddressValidated=false;
          this.FlashError("Invalid Zip Code");
        }
      })
    }
    else{
      console.log("inside zip not entered");
      this.Latitude = '';
      this.Longitude = '';
      
      this.timezoneid='';
      this.dstOffset='';
      this.rawOffset='';
      this.AddressValidated=false;
      this.FlashError("Invalid Zip Code");  
    }
    
  }


}








