import { Component, OnInit, HostListener } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../global.service';
import { Sort } from '@angular/material'
import { DomSanitizer } from '@angular/platform-browser';
import { ApilistService } from '../../../service/Api/apilist.service';
import { Subject } from 'rxjs';
declare const $;

@Component({
  selector: 'app-routelist',
  templateUrl: './routelist.component.html',
  styleUrls: ['./routelist.component.css']
})
export class RoutelistComponent implements OnInit {
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");

  Search: string = '';
  AlertText: string = '';
  lists: any;
  Posts: any;
  RouteId: number;
  pageno: number = 1;
  before: number;
  TotalCount: any = 1;
  screenHeight: number;
  fileUrl: any;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate


  constructor(private router: Router, 
    private http: HttpClient, 
    private globalservice: GlobalService, 
    private route: ActivatedRoute, 
    private sanitizer: DomSanitizer,
    public apiList: ApilistService) {
      this.resize$.debounceTime(100).subscribe(innerWidth => {
        if (this.Resizing) { return; }
        this.Repaginate();
        this.SettleAndAutosize(80);
      });
      this.Repaginate();
      this.List_Route();
  }

  ngOnInit() {
  this.HidePopups()   
  }

  HidePopups(){
    $('#Norecord').hide();
    $("#alert_div").hide();
    $('#dots_spinner').hide();
    $("#paginate").hide();
  }

  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
//console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  create_rote() {
    this.router.navigate([this.companyName + '/dashboard/Routelist/Createroute']);
  }

  clearSearch() {
    this.Search = '';
  }

  EditRoute(id) {
    this.router.navigate([this.companyName + '/dashboard/Routelist/Updateroute', { id: id }]);
  }

  Delete() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.RouteDeleteUrl, { RouteId: this.RouteId,IdMerchant:this.IdMerchant }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      this.Posts = response;
      if (this.Posts.Response == 1) {
        this.FlashError('Successfully Deleted');
        this.List_Route();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }


  List_Route() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.GetRouteListUrl, { CreatedBy: this.IdMerchant }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      this.lists = response;
      if (this.lists.Response == 1) {
        this.lists = this.lists.lst_Routes;
//code for sroute list print,edit,delete not displaying
        this.lists.forEach(element => {
          
        element.Machines=element.Machines.replace(/,(?=[^\s])/g, ", ");
        });
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide();
       if (this.Resizing) {
        // need to give time to render behind the scenes before size/resize rows
        this.SettleAndAutosize();
      } });
  }

  Printroute(id) {
    this.http.post<any>(this.apiList.PrintrouteUrl, { CreatedBy: this.IdMerchant, RouteId: id },this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
    //  console.log(response)
      var data = response;
      if(data.Response == 1){
      data = data.Url
      var popupWinindow = window.open('', '_blank', 'width=1366, height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
      popupWinindow.document.open();
      popupWinindow.document.write('<html><head></head><body onload="window.print()" onafterprint="window.close()">' + data + '</html>');
      popupWinindow.document.close();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  GetRouteId(id) {
    this.RouteId = id;
  }

  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }

  sortData(sort: Sort) {
    const data = this.lists.slice();
    if (!sort.active || sort.direction === '') {
      this.lists = data;
      return;
    }
    this.lists = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Identifier': return this.compare(a.RouteIdentifier.toLowerCase(), b.RouteIdentifier.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}
