import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from '../../../../environments/environment';
import { NgForm } from "@angular/forms";
import { Observable } from 'rxjs/Rx';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';
import { switchMap } from 'rxjs/operators';
declare const $;
@Component({
  selector: 'app-add-coupons',
  templateUrl: './add-coupons.component.html',
  styleUrls: ['./add-coupons.component.css']
})
export class AddCouponsComponent implements OnInit, OnDestroy {
  @ViewChild('couponForm', { static: false }) public CouponForm: NgForm
  baseUrl = environment.baseUrl;

  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");

  bit: any = false;
  CouponCode: any;
  DiscountType
  CouponType: any;
  CouponUse: any;
  DiscountAmount: any;
  StartDate
  EndDate: any;
  StartTime: string = "12:00 AM";
  EndTime: string = "12:00 AM";
  CreatedBy: any;
  CreatedDate: any;
  posts: any; MultipleUse: any;
  a: any;
  AlertText
  isAdding = false;
  selectedMachineproduct; selectedProduct2; IdMachines; productlenght
  mlists = [];
  Mlists; IdProducts
  Mlistsss = [];
  array
  nextLibAvailable = false;
  Dis_amount = false;
  partiall = false;
  discount_show = 1;
  Date; time
  to24Hour
  error: number;
    cacheProducts = [];
  
  constructor(private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public dialog: MatDialog,
    public apiList: ApilistService) { }

  ngOnInit() {
    this.globalservice.setDiscard_check();
    this.Date = new Date();
    this.HidePopups();
    this.dateTimeFunction()
    this.listofmachine()

  }

  HidePopups(){
 $("#alert_div").hide();
 $('#dots_spinner').hide();
  }

  dateTimeFunction(){
    $(document).ready(function () {
      $('#timepicker1').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12"
      });
      $('#timepicker2').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12"
      });
    });
    $('body').on('change', '#timepicker1', function () {
      $('#timepicker1').trigger('click');
    });
    $('body').on('change', '#timepicker2', function () {
      $('#timepicker2').trigger('click');
    });
  }

  randomNumber() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (var i = 0; i < 6; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    this.CouponCode = text;
    this.CouponCodeUnique();
    return text;
  }

  async CouponCodeUnique() {
    this.http.post<any>(this.apiList.IsExistCouponCode, { CouponCode: this.CouponCode, CreatedBy: this.IdMerchant, CouponId: 0 }, this.globalservice.Headers())
    .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
     
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        }
        else if (Error.Response == 2) {
        this.error = 1;
        } else {
        this.error = 0;
        this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {});
  }

  // canDeactivate(): Observable<boolean> | boolean {
  //   if (!this.isAdding && this.CouponForm.dirty) {
  //     return confirm('Are you sure you want to discard your changes?');
  //   } else if (this.CouponForm.dirty) {
  //     return confirm('Are you sure you want to discard your changes?');
  //   }
  //   return true;
  // }


  canDeactivate():any {
    if (!this.isAdding && this.CouponForm.dirty) {
        if(this.bit == false && this.globalservice.GetDiscard_Check()){
            const dialogRef = this.dialog.open(DialogComponent, {
                width: '300px',
                data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
            });
          return dialogRef.afterClosed().subscribe(result => {
                if (result && (result.response == "ok")) {
                    this.dialog.closeAll();
                    this.bit = true;
                    this.router.navigate([this.companyName + '/dashboard/coupons']);
                }
            });
        }
      } else if (this.CouponForm.dirty) {
        if(this.bit == false){
            const dialogRef = this.dialog.open(DialogComponent, {
                width: '300px',
                data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
            });
          return dialogRef.afterClosed().subscribe(result => {
                if (result && (result.response == "ok")) {
                    this.dialog.closeAll();
                    this.bit = true;
                    this.router.navigate([this.companyName + '/dashboard/coupons']);
                }
            });
        }
    }
    return true; 
}
  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDate = $('#SDate').val();
    //  console.log(this.StartDate);
  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    this.EndDate = $('#EDate').val();
    // console.log(this.EndDate);
  }

  startTime(time: string) {
    this.StartTime = time
    // console.log(this.StartTime);
  }
  endTime(time: string) {
    this.EndTime = time;
    //  console.log(this.EndTime);
  }



 FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  addcoupon() {
    this.time = this.Date.getHours() + ":" + this.Date.getMinutes() + ":00"
    var date = (this.Date.getMonth() + 1) + '/' + this.Date.getDate() + '/' + this.Date.getFullYear();
    this.isAdding = true;
    if (this.CouponCode == undefined || this.CouponCode == '') {
      $('#dots_spinner').hide();
      this.FlashError("Firstly Generate Coupon Code");
    } else {
      if (!this.StartDate || !this.EndDate) {
        $('#dots_spinner').hide();
        this.FlashError("Please complete Date Information");
      }
      else {
        if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {
          if (this.timeconversion(this.StartTime) == this.timeconversion("12:00 AM") && this.timeconversion(this.EndTime) == this.timeconversion("12:00 AM")) {
            //console.log(this.CouponCode, this.DiscountType, this.CouponType, this.DiscountAmount, this.StartDate + " " + this.StartTime, this.EndDate + " " + this.EndTime, this.IdMachines, this.IdProducts);
            this.AddCouponApi();
          }
          else if (this.toTimestamp(this.StartDate) >= this.toTimestamp(date) && this.toTimestamp(this.EndDate) != this.toTimestamp(date)) {
            if (this.timeconversion(this.StartTime) < this.timeconversion(this.EndTime)) {
              this.AddCouponApi();

            }
            else if ((this.timeconversion(this.StartTime) >= this.timeconversion(this.EndTime))) {
              $('#dots_spinner').hide();
              this.FlashError("Start Time Should be less than End Time");
            }
          }
          else if (this.toTimestamp(this.StartDate) == this.toTimestamp(date) && this.toTimestamp(this.EndDate) == this.toTimestamp(date)) {
            if (this.timeconversion(this.StartTime) >= this.time) {
              if (this.timeconversion(this.StartTime) < this.timeconversion(this.EndTime)) {
                // console.log( this.CouponCode, this.DiscountType, this.CouponType, this.DiscountAmount, this.StartDate + " " + this.StartTime, this.EndDate + " " + this.EndTime, this.IdMachines, this.IdProducts);
                this.AddCouponApi();
              } else if ((this.timeconversion(this.StartTime) >= this.timeconversion(this.EndTime))) {
                $('#dots_spinner').hide();
                this.FlashError("Start Time Should be less than End Time");
              }
            } else {
              $('#dots_spinner').hide();
              this.FlashError("Start Time Should be greater than Current Time");
            }
          }
        }
        else if (this.toTimestamp(this.StartDate) >= this.toTimestamp(this.EndDate)) {
          $('#dots_spinner').hide();
          this.FlashError("Start Date should be less than End Date");
        }
      }
    }
  }

  async AddCouponApi() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.AddCouponUrl, { CouponCode: this.CouponCode, CreatedDate: "19-10-2018", DiscountType: this.DiscountType, CouponType: "this.CouponType", CouponUse: this.CouponUse, DiscountAmount: this.DiscountAmount, StartDate: this.StartDate + " " + this.StartTime, EndDate: this.EndDate + " " + this.EndTime, StartTime: this.StartTime, EndTime: this.EndTime, CreatedBy: this.IdMerchant, IdMachines: this.IdMachines, IdProducts: this.IdProducts }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.CouponForm.reset();
        this.router.navigate([this.companyName + '/dashboard/coupons']);
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
        this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide() });
  }

  listofmachine() {
    if (this.IdMerchant) {
      $('#dots_spinner').show();
      let url1 = `${this.apiList.GetPlaceMachinesForSpecialPrice}`;
      this.http.post<any>(url1, { IdMerchant: this.IdMerchant }, this.globalservice.Headers()).pipe(
        switchMap(((response) => {      
        if (response.Response == 1) {
          this.mlists = response.lst_sp_GetPlacedMachinesWithLocation;
          let array = [];
          for (let i = 0; i < this.mlists.length; i++) {
            array.push(this.mlists[i].IdMachine);
          }
          this.IdMachines = array.toString();
          let url2 = `${this.apiList.ProductLstMachineUrl}`;
          return this.http.post<any>(url2, { IdMachines: this.IdMachines }, this.globalservice.Headers());
        }
      })))  .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
          if (response.Response == 1) {
            this.cacheProducts = response.lst_Product;
          }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        }
        else if(Error.Response === 4){
return;
        }
        
        else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
    }
  }
  onChangeMachine(a) {
    if (a) {
      this.nextLibAvailable = true;
      $('#uncheckall').removeClass("btn_active");
      $('#checkall').removeClass("btn_active");
      a = a.map(function (e) { return JSON.stringify(e); });
      a = a.join(",");
      this.IdMachines = a;
      //console.log(this.IdMachines);
      this.refreshlistproductMachine();
    }
  }

  refreshlistproductMachine() {
    if (this.IdMachines) {
      let machines = this.IdMachines.split(',');
      let products = [];
      machines.forEach(el => {
        for(let i=0; i<this.cacheProducts.length; i++) {
          if(+el === this.cacheProducts[i].IdMachine) {
            products.push(this.cacheProducts[i]);
          }
        }
      });
      //remove duplicates from products array
      let newArr = [];
      products.forEach((item, index) => {
          if (newArr.findIndex(i => i.IdProduct == item.IdProduct) === -1) {
            newArr.push(item)
          }
      });
      this.Mlists = newArr;
      console.log( this.Mlists);
      this.productlenght = this.Mlists.length;
    } else {
      this.nextLibAvailable = false;
      this.Mlists = [];
      this.selectedProduct2 = '';
    }
  }

//   refreshlistproductMachine() {
//     if (this.IdMachines) {
//       //  $('#dots_spinner').show(this.IdMachines);
//       this.http.post<any>(this.apiList.ProductLstMachineUrl, { IdMachines: this.IdMachines }, this.globalservice.Headers())
//     .timeout(25000)
//       .take(1)
//       .map(resp => {
//         if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
//         if (+resp.Response !== 1) { throw resp; }
//         return resp;
//       }).subscribe((response) => {
//          this.Mlists = response;
//           this.Mlistsss = this.Mlists.lst_Product;
//           this.Mlists = this.Mlists.lst_Product;
//           console.log(this.Mlists);
//           this.productlenght = this.Mlistsss.length;
//       }, (err: any) => {
//         const Error = this.globalservice.ProcessError(err);
//         if (Error.Response === 35) {
//           if (Error.ErrorMessage) {
//             this.FlashError(Error.ErrorMessage);
//             this.globalservice.Logout('Yes');
//           }
//           this.globalservice.Logout('No');
//           return;
//         } else {
//        this.nextLibAvailable = false;
//       this.Mlists = [];
//       this.selectedProduct2 = '';
//           return;
//         }
//       }).add(() => { $('#dots_spinner').hide() });
//   }
// }


  onChangeProduct2(id2) {
    if (id2) {
      $('#uncheckall').removeClass("btn_active");
      $('#checkall').removeClass("btn_active");
      id2 = id2.map(function (e) { return JSON.stringify(e); });
      id2 = id2.join(",");
      this.IdProducts = id2
    }


  }

  selectAll(id) {
    $('#checkall').addClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
  if (id == 'machine') {
    this.array = [];
    for (let i = 0; i < this.mlists.length; i++) {
      this.array.push(this.mlists[i].IdMachine);
    }
    this.selectedMachineproduct = this.array;
    this.IdMachines = this.selectedMachineproduct.toString();
    // console.log(this.IdMachines);
    this.nextLibAvailable = true;
    this.refreshlistproductMachine();
  } else if (id == 'multipleproduct') {
    this.array = [];
    for (let i = 0; i < this.Mlists.length; i++) {
      this.array.push(this.Mlists[i].IdProduct);
    }
    this.selectedProduct2 = this.array;
    this.IdProducts = this.selectedProduct2.toString();
  }
}

  deselectAll(id) {
    if (id == 'machine') {
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').addClass("btn_active");
      this.selectedMachineproduct = "";
      this.array = [];
      this.IdMachines = "";
      this.refreshlistproductMachine();
    } else if (id == 'multipleproduct') {
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').addClass("btn_active");
      this.selectedProduct2 = "";
      this.array = [];
      this.IdProducts = "";
    }
  }
  timeconversion(Time) {
    var time = Time;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes + ":" + "00"
  }

  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  submit_click() {
    this.addcoupon();
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/coupons']);
  }

  partial() {
    this.partiall = true;
    this.DiscountType = "$"
    this.Dis_amount = true;
    this.discount_show = 2;
    this.DiscountAmount = ""

  }

  onetime() {
    this.partiall = false;
    this.DiscountAmount = ""
  }

  type(value) {
    //  console.log(value);
    this.discount_show = value;
    this.Dis_amount = true;
    this.DiscountAmount = "";
  }

  ngOnDestroy() {
    this.dialog.ngOnDestroy();
  }
}
