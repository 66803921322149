import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { GlobalService } from '../../../global.service';
declare const $;
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);

  public mask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  ClientName: string;
  ClientAddress: string;
  ClientPhoneNo: string;
  ContactName: string;
  ContactPhoneNo: string;
  ContactEmailId: string;
  countrylists;
  selctedcountry;
  selectedstate;
  states;
  citys;
  IdCountry;
  IdState;
  IdCity;
  ZipCode;
  selectedcity: any;
  AlertText: string;
  //zipPattern = /^[a-zA-Z0-9\s]{5,8}$/;

  constructor(
    private router: Router,
    private http: HttpClient,
    public apiList: ApilistService,
    private globalservice: GlobalService) { }

  ngOnInit() {
    $('#dots_spinner').hide();
    $('#alert_div').hide();
    this.globalservice.getCountry().then((res: any) => {
      this.countrylists = res;
    });
  }

  numberOnly(event): boolean {
    console.log("event.length"+event.length);
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    else if(event.length==8) {
      return false;
    }
    return true;

  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
  onChangeCountry(a) {
    this.IdCountry = a;
    this.getstates();
  }

  getstates() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.StateUrl, { IdCountry: this.IdCountry })
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.states = response.lst_State;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  onChangeState(b) {
    this.IdState = b;
    this.getcity();
  }

  getcity() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CityUrl, { IdState: this.IdState })
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.citys = response.lst_City;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/Clientlist']);
  }

  save() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.AddClientApi,
      {
        IdMerchant: this.IdMerchant,
        ClientName: this.ClientName,
        ClientAddress: this.ClientAddress,
        IdCountry: this.IdCountry,
        IdState: this.IdState,
        IdCity: this.selectedcity,
        ZipCode: this.ZipCode,
        ClientPhoneNo: this.ClientPhoneNo,
        ContactName: this.ContactName,
        ContactPhoneNo: this.ContactPhoneNo,
        ContactEmailId: this.ContactEmailId
      }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.router.navigate([this.companyName + '/dashboard/Clientlist']);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }
}


