import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { GlobalService } from '../global.service';
declare const $;
@Component({
  selector: 'app-super-adminlogin',
  templateUrl: './super-adminlogin.component.html',
  styleUrls: ['./super-adminlogin.component.css']
})
export class SuperAdminloginComponent implements OnInit {
  baseUrl = environment.baseUrl;
  SuperAdminLoginUrl = this.baseUrl + '/Login/SuperAdminLogin';
  ForgotPwsUrl = this.baseUrl + '/Login/ForgotPasswordFun';
  LoginName;
  Password;
  AlertText;
  Emailid;
  IdSuperAdmin;
  constructor(private router: Router, private http: HttpClient, private globalservice: GlobalService) { }

  ngOnInit() {
    $('#alert_div').hide();
  }

  login() {
    this.http.post<any>(this.SuperAdminLoginUrl, { LoginName: this.LoginName, Password: this.Password },
      this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (+result.Response !== 1) { throw result; }
        return result;
      }).subscribe((response) => {
        if (response) {
          this.IdSuperAdmin = response.Obj_SuperAdminMaster.IdSuperAdmin;
          sessionStorage.setItem('referIdSuperAdmin', this.IdSuperAdmin);
          if (response.Response === 1) {
            this.router.navigate(['Superadmindashboard/Signups']);
          } else if (response.Response === 2) {
            this.FlashError('Incorrect Credentials');
          } else if (response.Response === 0) {
            this.FlashError('!Error Occured');
          }
        }
      }, (err) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });

  }
  forgot() {
    this.http.post<any>(this.ForgotPwsUrl, { LoginName: this.Emailid }, this.globalservice.Headers())
      .subscribe((response) => {
        if (response.Response === 1) {
          this.FlashError('Successfully Email sent');
        } else if (response.Response === 2) {
          this.FlashError('Not Existing EmailId');
        } else if (response.Response === 0) {
          this.FlashError('Problem in sending Email');
        }
      }, (err) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
  }
  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}
