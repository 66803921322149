import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { GlobalService } from '../../../../global.service';
import { ApilistService } from '../../../../../service/Api/apilist.service';
import { MatDialog } from '../../../../../../node_modules/@angular/material';
import { NgForm } from '../../../../../../node_modules/@angular/forms';
import { DialogComponent } from '../../../../dialog/dialog.component';
declare const $;
@Component({
  selector: 'app-updatesubcategory',
  templateUrl: './updatesubcategory.component.html',
  styleUrls: ['./updatesubcategory.component.css']
})
export class UpdatesubcategoryComponent implements OnInit, OnDestroy {
  @ViewChild('myInput', { static: false }) public myInputVariable: ElementRef;
  @ViewChild('updatesubcategory', { static: false }) public updatesubcategory: NgForm;
  baseUrl = environment.baseUrl;
  BaseFileUrl = environment.BaseFileUrl;
  ImageUrl = this.BaseFileUrl + '/ClientDocument/';
  CheckTransparency = this.baseUrl + '/DocumentUpload/CheckTransparency';
  GetMainCategoryDetailUrl: string = this.baseUrl + '/Category/GetSubCategoryDetailById';
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
  IdM = sessionStorage.getItem('referidmancategory');
  posts: any;
  ImageName: any;
  IdMainCategory: any;
  names: any;
  IdSubCategory: any;
  upload_image;
  AlertText;
  image;
  fileList: any;
  Mainfile: any;
  IdS;
  SubCategoryName;
  Description;
  MainImage;
  FileMD5Sum: any;
  isAdding = false;
  bit: any = false;
  blob: any;
  FileName: any;

  constructor(
    public apiList: ApilistService,
    public dialog: MatDialog,
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    private route: ActivatedRoute) {
    this.route.params.subscribe((get) => {
      this.IdS = get.id;
    });
  }

  ngOnInit() {
    this.globalservice.setDiscard_check();
    this.upload_image = 1;
    this.get_detail();
    $('#add').trigger('click');
    this.refreshlistmaincategory();
    this.hidepops();
    $(function () {
      $('#myImg').show();
      $('#main_img_close').show();
      $('#upload-btn-wrapper').hide();
      $('#file').change(function () {
        if (this.files && this.files[0]) {
          const reader = new FileReader();
          reader.onload = imageIsLoaded;
          reader.readAsDataURL(this.files[0]);
        }
      });
    });
    function imageIsLoaded(e) {
      $('#myImg').attr('src', e.target.result);
    }
  }
  hidepops() {
    $('#upload-btn-wrapper').hide();
    $('#alert_div').hide();
    $('#dots_spinner').hide();
    $('#myModal').modal('hide');
  }
  get_detail() {
    $('#dots_spinner').show();
    this.http.post<any>(this.GetMainCategoryDetailUrl,
      { IdSubcategory: this.IdS, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      })
      .subscribe((response) => {
        if (response.Response === 1) {
          response = response.SubCategoryDetail;
          this.SubCategoryName = response.SubCategoryName;
          this.MainImage = response.ImageURL;
          this.Description = response.Description;
          this.FileMD5Sum = response.FileMD5Sum;
          this.FileName = response.ImageName
        } else {
          this.FlashError(response.ErrorMessage);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
  getID(id: string) {
    this.IdMainCategory = id;
  }

  closebutton() {
    this.FileMD5Sum = '';
    this.upload_image = '';
    this.MainImage= ''
    $('#myImg').hide();
    $('#main_img_close').hide();
    $('#upload-btn-wrapper').show();
  }
  // fileChange(event) {
  //   this.fileList = event.target.files;
  //   if (this.fileList.length > 0) {
  //     $('#dots_spinner').show();
  //     this.Mainfile = this.fileList[0];
  //     this.ImageName = this.Mainfile.name.replace(/\s/g, '');
  //     const formData: FormData = new FormData();
  //     formData.append('ClientDocs', this.Mainfile, this.Mainfile.name.replace(/\s/g, ''));
  //     this.http.post<any>(`${this.CheckTransparency}`, formData, this.globalservice.MediaHeaders())
  //       .timeout(25000)
  //       .take(1)
  //       .map(resp => {
  //         if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
  //         if (+resp.Response !== 1) { throw resp; }
  //         return resp;
  //       })
  //       .subscribe((response) => {
  //         if (response.Response === 1) {
  //           this.ImageUpload(formData, this.Mainfile);
  //         } else if (response.Response === 5) {
  //           this.AlertText = 'Image is not of Size 500 *500';
  //           this.FlashError('Image is not of Size 500 *500');
  //         } else if (response.Response === 4) {
  //           $('#myModal').modal('show');

  //         } else if (response.Response === 0) {
  //           this.FlashError('Something went wrong');
  //         } else {
  //           this.FlashError(response.ErrorMessage);
  //         }
  //       }, (err: any) => {
  //         const Error = this.globalservice.ProcessError(err);
  //         if (Error.Response === 35) {
  //           if (Error.ErrorMessage) {
  //             this.FlashError(Error.ErrorMessage);
  //             this.globalservice.Logout('Yes');
  //           }
  //           this.globalservice.Logout('No');
  //           return;
  //         } else {
  //           this.FlashError(Error.ErrorMessage);
  //           return;
  //         }
  //       }).add(() => { $('#dots_spinner').hide(); });
  //   }
  // }

  // Yes() {
  //   if (this.fileList.length > 0) {
  //     const file = this.fileList[0];
  //     const formData: FormData = new FormData();
  //     formData.append('ClientDocs', file, file.name.replace(/\s/g, ''));
  //     this.ImageUpload(formData, file);
  //   }
  // }
  canDeactivate(): any {
    if (!this.isAdding && this.updatesubcategory.dirty) {
      if (this.bit === false && this.globalservice.GetDiscard_Check()) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });

        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response === 'ok')) {
            this.dialog.closeAll();
            this.bit = true;
            this.router.navigate([this.companyName + '/dashboard/categories/SubCategoryListPage']);
          }
        });
      }
    } else if (!this.isAdding && this.updatesubcategory.dirty) {
      if (this.bit === false) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });

        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response === 'ok')) {
            this.dialog.closeAll();
            this.bit = true;
            this.router.navigate([this.companyName + '/dashboard/categories/SubCategoryListPage']);
          }
        });
      }
    }
    return true;
  }

  // ImageUpload(formData, file) {
  //   $('#dots_spinner').show();
  //   this.http.post<any>(this.apiList.MediaUpload, formData, this.globalservice.MediaHeaders())
  //     .timeout(25000)
  //     .take(1)
  //     .map(resp => {
  //       if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
  //       if (+resp.Response !== 1) { throw resp; }
  //       return resp;
  //     })
  //     .subscribe((response) => {
  //       if (response.Response === 1) {
  //         this.MainImage = response.FileName;
  //         this.FileMD5Sum = 1;
  //         this.upload_image = 1;
  //         $('#pro-img').show(2000, function () {
  //           $('#main_img_close').show();
  //           $('#uploadSuccess').modal('show');
  //         });
  //         $('#upload-btn-wrapper').hide();
  //       }
  //     }, (err: any) => {
  //       const Error = this.globalservice.ProcessError(err);
  //       if (Error.Response === 35) {
  //         if (Error.ErrorMessage) {
  //           this.FlashError(Error.ErrorMessage);
  //           this.globalservice.Logout('Yes');
  //         }
  //         this.globalservice.Logout('No');
  //         return;
  //       } else {
  //         this.FlashError(Error.ErrorMessage);
  //         return;
  //       }
  //     }).add(() => { $('#dots_spinner').hide(); });
  // }
  refreshlistmaincategory() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CategoryListUrl, { IdMerchant: this.IdMerchant },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.posts = response;
          this.posts = this.posts.lst_mc;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }
  subcategory_submit() {
    this.isAdding = true;
    if (this.upload_image === 1) {
      $('#dots_spinner').show();
      this.http.post<any>(this.apiList.updateSubCatUrl,
        {
          IdSubCategory: this.IdS,
          SubCategoryName: this.SubCategoryName,
          Description: this.Description,
          IdMerchant: this.IdMerchant,
          IdMainCategory: this.IdM,
          ImageName: this.FileName
        },
        this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(resp => {
          if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
          if (+resp.Response !== 1) { throw resp; }
          return resp;
        }).subscribe((response) => {
          if (response.Response === 1) {
            this.names = response;
            this.router.navigate([this.companyName + '/dashboard/categories/SubCategoryListPage']);
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#dots_spinner').hide(); });
    } else {
      this.FlashError('Firstly upload your Subcategory Image');
    }
  }
  goback() {
    this.router.navigate([this.companyName + '/dashboard/categories/SubCategoryListPage']);
  }

  ngOnDestroy() {
    this.dialog.ngOnDestroy();
  }

  MainImageUpload(file: File) {
    // if (this.upload_image == 1) {
     $('#dots_spinner').show();
   
     this.blob = new Blob([file[0]], { type: file[0].type });
     console.log(file)
     this.globalservice.CheckTransparency(file).then((res:any) => {
         $('#dots_spinner').hide();
         console.log(res)
         if(res.Response == 1){
             this.uploadFile()
         }
     },error=>{
         $('#dots_spinner').hide();
         console.log(error)
         const Error = this.globalservice.ProcessError(error);
             if (Error.Response === 35) {
                 if (Error.ErrorMessage) {
                     this.FlashError(Error.ErrorMessage);
                     this.globalservice.Logout('Yes');
                 }
                 this.globalservice.Logout('No');
                 return;
             } else if (Error.Response == 4) {
                 $('#myModal').modal('show');
             }
             else {
                 console.log(Error.ErrorMessage)
                // event.target.value = null;
                 this.FlashError(Error.ErrorMessage);
                 return;
             }
     })
 }
 // else{
 //         this.FlashError("Firstly upload your Category image");
 //     }
 // }
 uploadFile(){
     $('#dots_spinner').show();
     this.globalservice.uploadFiles(this.blob).then((res:any)=>{
         if(res.Response==1){
             $('#dots_spinner').hide();
             this.upload_image = 1;
             this.MainImage = res.PreviewUrl;
             this.FileName=res.FileName
             $('#myImg').show(2000, function () {
              $('#main_img_close').show();
              $('#uploadSuccess').modal('show');
          });
          $('#upload-btn-wrapper').hide();
         }
     },error=>{
         const Error = this.globalservice.ProcessError(error);
         if (Error.Response === 35) {
             if (Error.ErrorMessage) {
                 this.FlashError(Error.ErrorMessage);
                 this.globalservice.Logout('Yes');
             }
             this.globalservice.Logout('No');
             return;
         } 
         // else if (Error.Response == 4) {
         //     $('#myModal').modal('show');
         // }
         else {
             console.log(Error.ErrorMessage)
            // event.target.value = null;
             this.FlashError(Error.ErrorMessage);
             return;
         }
     })
 }

   Yes() {
     // console.log(this.section);
 this.uploadFile()
    
 }
}
