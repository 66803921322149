import { Component, OnInit, ViewChild, Renderer2, ElementRef, HostListener, Inject, ChangeDetectorRef } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { DOCUMENT } from '@angular/common';
import { WindowRefService } from './window-ref.service';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { async } from '@angular/core/testing';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { interval, Observable, timer, from } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';
import { MatInput, MatTabChangeEvent, MatTabGroup } from '@angular/material';
import * as moment from "moment-timezone";

declare const $;

@Component({
  selector: 'app-planogramtraymotor',
  templateUrl: './planogramtraymotor.component.html',
  styleUrls: ['./planogramtraymotor.component.css']
})

export class PlanogramtraymotorComponent implements OnInit {
  BaseFileUrl = environment.BaseFileUrl;
  ImageUrl = this.BaseFileUrl + "/ClientDocument/ThumbnailImages/";
  companyName = sessionStorage.getItem("companyName");
  IdMasterMachine = sessionStorage.getItem("refrenceMasterID");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  MachineType = sessionStorage.getItem("referMachinetype");
  refrencemachineid = sessionStorage.getItem("refrencemachineid");
  Sessionid_Admin = sessionStorage.getItem("Sessionid_Admin")
  timezoneid_planogram: string;
  timezone_address_planogram: any;
  dstOffset: any;
  rawOffset: any;
  lat: any;
  long: any;
  Idlocation: number;
  cubemachinenumber: number;
  linkcode: any
  posts: any;
  remove: any;
  machineid: any;
  j: any;
  private window: Window;
  lists: any;
  data: any;
  trays: any;
  AvailableQuantity: number;
  ProductName: string;
  ProductImage: string;
  IdProduct: number;
  IdSlotMaster: number;
  IdMainCategory: number;
  IdSubCategory: number;
  Name = "";
  Price = "";
  MainImage = "";
  NutritionalFacts = "";
  AlternateImage1 = "";
  AlternateImage2 = "";
  AlternateImage3 = "";
  NoOfMotorsPerTray = "";
  Max: number;
  results: any;
  IdMachineProduct: string;
  IdMachine: number;
  SlotId: number;
  TotalQuantity: number[];
  Par: number[];
  slaveSerialNumbers: any[];
  TrayNo: number;
  imagedrop = "";
  dragprodname = "";
  dragprodprice: any;
  dragprodid: string = '';
  dropslotid = "";
  droppar = "";
  dropquant = "";
  slotid = "";
  Parslot: number;
  MachineProdtype: any;
  productType: any;
  CurrencyType
  Search
  AlertText;
  check = true;
  count = 0
  machine_detail: any = {};
  ConFiglists: any;
  AddtrayCount: number = 0;
  AddMotorCount: number = 0;
  MaxMotors: number;
  MaxTrays: number;
  Traylength: any;
  TrayNumber: any;
  MasterProductType: any;
  Setposition = false
  initheight: number
  scroll_pos: number;
  bottomtouch: boolean = false;
  pickerheight: number;
  lastScrollTop = 0
  pickerInitHeight: number;
  resetheight: number;

  SalesTax: any;
  KioskTime: any;
  StartTime: any;
  TimeZoneId: any;
  KioskDate: any;
  SelectedDate: any = null;
  SelectedTime: any = '';
  DiscountAmount: any;
  DiscountType: any = '';
  SpecialPricingRule: any;
  Date: Date;
  AfterDiscountAmt: any;
  CurrentTime: any;
  CurrentDate: any;
  TimeCheck: Boolean;
  interval$ = timer(0, 1000);
  intervalDateTime$: Observable<string>;
  staticTime = false;
  public selectedIndex: number = 0;
  tabs4: any;

  placeMachineId;

  productPar:number;

  //@ViewChild(MatTabGroup,{static: false}) tabGroup: MatTabGroup;

  @ViewChild('input1', {
    read: MatInput,
    static: false
  }) input1: MatInput;
  subscription: any;
  state: void;
  EventUrl: any;
  public VendEvents: EventSource;

  constructor(private change: ChangeDetectorRef, public apiList: ApilistService, private router: Router, private rd: Renderer2, @Inject(DOCUMENT) private document: Document,
    private http: HttpClient, windowRef: WindowRefService, private globalservice: GlobalService, private route: ActivatedRoute) {
    $('[data-toggle="tooltip"]').tooltip();


    this.route.params.subscribe((get) => {
      this.linkcode = get.l_c;
      this.cubemachinenumber = get.c_m;
      this.Idlocation = get.I_l;
      this.MasterProductType = get.P_Type;
      this.IdMachine = get.id;
      this.placeMachineId=get.idplace;
      console.log("idplace is "+this.placeMachineId);
    })
    this.pickerheight = window.innerHeight - 67;
    this.resetheight = window.innerHeight - 200;
    this.initheight = this.resetheight;
  }

  ngOnInit() {
    this.selectedIndex = 0;
    this.count = 0;
    this.tabs4 = $('#tabSet').scrollTabs();
    // this.tabGroup.selectedIndex = 1;
    $("#tabSet span").click(function () {
      $("span").removeAttr("id")
      $(this).attr('id', 'active');
    });

    this.HidePopups();
    this.dateTimeFunction();
    this.get_machineinfo();
    this.getMachineSerial();
    $('[data-toggle="tooltip"]').tooltip();
    window.addEventListener('scroll', this.scrollEvent, true);
  }
  //function to set new values for idmachine,codelink,locationid and machine i.e cubenumber


  setMachine(idmac, cubeno, locid, linkcode) {
    console.log("id mac" + idmac);
    console.log("cubeno" + cubeno);
    console.log("locid" + locid);
    console.log("linkcode" + linkcode);

    this.linkcode = linkcode;
    this.cubemachinenumber = cubeno;
    this.Idlocation = locid;
    // this.MasterProductType = get.P_Type;
    this.IdMachine = idmac;
    this.ngOnInit();
  }

  onTabChanged(tabChangeEvent: MatTabChangeEvent) {
    console.log('tabChangeEvent => ', tabChangeEvent);
    //var object
    console.log('tab label' + tabChangeEvent.tab.textLabel);
    console.log('tab label' + tabChangeEvent.tab.position);
    console.log('index => ', tabChangeEvent.index);
  }
  //new function for getting serial numbers
  getMachineSerial() {
    // this.TimeCheck = false;
    this.http.post<any>(this.apiList.getMachineSerial, { IdMachine: this.IdMachine }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
         // console.log("response of serialapi" + JSON.stringify(response));
          this.slaveSerialNumbers = response.SlavesMachines;

          window.setTimeout(() => {
            if (this.slaveSerialNumbers !== undefined && this.slaveSerialNumbers !== null && this.slaveSerialNumbers.length > 0) {
              for (var i = 0; i < this.slaveSerialNumbers.length; i++) {

                if (this.slaveSerialNumbers[i].IdMachine == this.IdMachine) {
                  this.selectedIndex = i;
                  this.change.markForCheck();
                }


              }

            }
            else {
              this.selectedIndex = 0;
              this.change.markForCheck();
            }

          });
         // console.log("this.slaveSerialNumbers" + JSON.stringify(this.slaveSerialNumbers));
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      });
  }

  HidePopups() {
    $('#TM').modal('hide');
    $('#dots_spinner').hide();
    $("#alert_div").hide();
  }

  dateTimeFunction() {
    $(document).ready(function () {
      $('#timepicker1').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12"
      });
    });
    $('body').on('change', '#timepicker1', function () {
      $('#timepicker1').trigger('click');
    });
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.KioskDate = $('#KioskDate').val();
  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    //this.EndDate = $('#MSPDate').val();
    // alert(this.EndDate);
  }

  startTime(time: string) {
    this.KioskTime = time;
  }

  Calendar_popup() {
    this.input1.value = this.SelectedDate;
    $('#KioskDate').val(this.SelectedDate);
    this.KioskTime = this.SelectedTime;
    $('#timepicker1').timepicker('setTime', this.SelectedTime);
    $('#timesetting').modal('show');
  }

  SubmitSettings() {
    this.SelectedDate = $('#KioskDate').val();
    this.SelectedTime = this.KioskTime;
    //console.log(this.SelectedTime);
    //console.log(this.timeconversion(this.SelectedTime))
    if (this.SelectedDate == this.CurrentDate && this.SelectedTime == this.CurrentTime) {
      this.TimeCheck = false;
    }
    else {
      this.TimeCheck = true;
    }
    this.refreshlistproduct();
    this.trayarrange();
    $('#timesetting').modal('hide');
    this.staticTime = true;
  }

  Clear() {
    this.get_machineinfo();
    this.staticTime = false;
  }
  Cancel_settings() {
    $('#timesetting').modal('hide');
  }

  GoToSpecialPrice(rule) {
    this.SpecialPricingRule = rule;
    //console.log(this.SpecialPricingRule);
    this.router.navigate([this.companyName + '/dashboard/Specialpricing', {
      rule: this.SpecialPricingRule,
    }]);
  }

  refreshlistproduct() {
    var settings = null;
    if (this.SelectedDate && this.SelectedTime) {
      settings = this.SelectedDate + ' ' + this.SelectedTime
    }
    // console.log(settings)
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ProductlstPlanoUrl, { IdMerchant: this.IdMerchant, Idlocation: this.Idlocation, IdMachine: this.IdMachine, SelectedDate: settings, IdPlaceMachine : this.placeMachineId }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.posts = response.lst_ProductaForPlanogram;
          //console.log("JSON list post"+JSON.stringify(response.lst_ProductaForPlanogram));
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  scrollEvent = (event: any): void => {
    $('html, body').animate({ scrollTop: 0 }, 100);
    //console.log(this.checkheight());
    let box1_height: number = $('#dash').height();
    if (event.target.id == 'dash' || event.target.id == '') {
      let Direction = this.scrollDirection();
      this.scroll_pos = event.srcElement.scrollTop;
      let scroll_height: number = event.srcElement.scrollHeight;
      this.resetpickersize();

      if ((this.bottomtouch == true) && (box1_height + this.scroll_pos < scroll_height - 20)) {
        this.initheight = this.pickerheight + 25
      }
      if ((this.scroll_pos >= 170)) {
        $(".product_list").addClass("div_fixed");
        $(".product_search_input").addClass("input_fixed");
      }
      if (this.initheight < this.pickerheight && (this.bottomtouch == false)) {
        this.initheight = (this.scroll_pos + this.resetheight)
      }
      else if ((this.scroll_pos < 170)) {
        this.initheight = this.resetheight
        if (Direction == 'up') {
          $(".product_list").removeClass("div_fixed");
          $(".product_search_input").removeClass("input_fixed");
          this.initheight = (this.resetheight + this.scroll_pos)
        }
        else if (Direction == 'down') {
          this.bottomtouch = false;
          $(".product_list").removeClass("div_fixed");
          $(".product_search_input").removeClass("input_fixed");
          this.initheight = (this.resetheight + this.scroll_pos)
        }
      }
      if (box1_height + this.scroll_pos == scroll_height) {
        this.bottomtouch = true;
        this.initheight = this.pickerheight - 25;
      }
    }
  }

  checkheight() {
    let Traymotor_height = $('.main_All_plano').height();
    if (Traymotor_height > this.initheight) {
      return true
    }
    else {
      return false
    }
  }
  resetpickersize() {
    if (this.scroll_pos == 0) {
      this.initheight = this.resetheight
    }
  }

  scrollDirection() {
    if (this.scroll_pos < this.lastScrollTop) {
      this.lastScrollTop = this.scroll_pos;
      return 'up';
    }
    else if (this.scroll_pos > this.lastScrollTop) {
      this.lastScrollTop = this.scroll_pos;
      return 'down';
    }
  }


  MissingMotors(numArray, Max) {
    var MaxArray = [];
    for (let i = 1; i <= Max; i++) {
      MaxArray.push(i);
    }
    var sorted = numArray.slice().sort(function (a, b) {
      return a - b;
    });

    var doNotMatch = MaxArray.filter(function (obj) { return numArray.indexOf(obj) == -1; });
    return doNotMatch;
  }

  TrayMotorConfig() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.TrayMotorConfUrl, { LinkCode: this.linkcode, CubeNo: this.cubemachinenumber }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        $('#TM').modal('show');
        if (response.Response == 1) {
          this.ConFiglists = response;
          //console.log(this.ConFiglists)
          this.MaxMotors = this.ConFiglists.MaxMotors;
          this.MaxTrays = this.ConFiglists.MaxTrays;
          this.ConFiglists = this.ConFiglists.lst_Trays;
          this.Traylength = this.ConFiglists.length
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  RemoveMotor(i, j) {
    //console.log("Tray number=" + i, "Motor Number=" + j);
    this.http.post<any>(this.apiList.RemoveTrayOrMotorUrl, { TrayNumber: i, MotorNumber: j, LinkCode: this.linkcode, CubeNo: this.cubemachinenumber }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.TrayMotorConfig();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  AddTray(SlotDetails, i) {
    let TrayArray = [];
    //console.log(this.ConFiglists);
    for (let i = 0; i < this.ConFiglists.length; i++) {
      TrayArray.push(this.ConFiglists[i].TrayNumber);
    }
    //console.log(TrayArray);

    var DonotMatchArray = this.MissingMotors(TrayArray, this.MaxTrays);
    //console.log(DonotMatchArray);
    var TrayNumber = DonotMatchArray.shift();
    this.http.post<any>(this.apiList.AddTrayOrMotorUrl, { TrayNumber: TrayNumber, MotorNumber: "0", NoofMaxMotors: this.MaxMotors, LinkCode: this.linkcode, CubeNo: this.cubemachinenumber }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.TrayMotorConfig();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  AddMotor(SlotDetails, i) {
    let MotorArray = [];
    //console.log(SlotDetails);
    this.TrayNumber = SlotDetails.TrayNumber;
    for (let i = 0; i < SlotDetails.lst_Slots.length; i++) {
      MotorArray.push(SlotDetails.lst_Slots[i].MotorNumber);
    }
    //console.log(MotorArray);
    var DonotMatchArray = this.MissingMotors(MotorArray, this.MaxMotors);
    //console.log(DonotMatchArray);
    var MotorNumber = DonotMatchArray.shift();
    this.http.post<any>(this.apiList.AddTrayOrMotorUrl, { TrayNumber: i, MotorNumber: MotorNumber, LinkCode: this.linkcode, CubeNo: this.cubemachinenumber }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.TrayMotorConfig();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      });

  }

  @HostListener("scroll", ['$event'])
  onElementScroll($event) {
    var elmnt = document.getElementById("Planodash");
    if (elmnt.scrollTop > 80) {
      $(".product_list").addClass("div_fixed");
      $(".product_search_input").addClass("input_fixed");
    }
    else {
      $(".product_list").removeClass("div_fixed");
      $(".product_search_input").removeClass("input_fixed");
    }
  }

  get_machineinfo() {
    this.TimeCheck = false;
    this.http.post<any>(this.apiList.GetMachineLocationDetailUrl, { IdMerchant: this.IdMerchant, LinkCode: this.linkcode, CubeNo: this.cubemachinenumber, IdLocation: this.Idlocation }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe(async (response) => {
        if (response.Response == 1) {
          this.machine_detail = response;
          console.log("this.machine_detail"+JSON.stringify(this.machine_detail));
        //  console.log("this.machine_detail"+JSON.stringify(this.machine_detail));
          this.TimeZoneId = this.machine_detail.TimeZone;
           this.setTimeMoment(this.TimeZoneId);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      });
  }

  setTimeMoment(timezone) {

    this.intervalDateTime$ = this.interval$.pipe(
      map(() => this.getDate(timezone))
    );
    this.refreshlistproduct();
    this.trayarrange();

  }

  getDate(timezone): string {

    let remoteDateTime = new Date(moment.tz(timezone).format('YYYY-MM-DD HH:mm:ss'));

    let month = remoteDateTime.getMonth() + 1;
    let m_str: string;
    let d_str: string;
    if (month < 10) {
      m_str = '0' + month;
    }
    else {
      m_str = month.toString();
    }

    let day = remoteDateTime.getDate();
    if (day < 10) {
      d_str = '0' + day;
    }
    else {
      d_str = day.toString();
    }
    let year = remoteDateTime.getFullYear();
    this.SelectedDate = m_str + '/' + d_str + '/' + year;
    this.SelectedTime = this.convert24HrtoAMPM(remoteDateTime.toTimeString().substr(0, 8));
    this.CurrentDate = this.SelectedDate;
    this.CurrentTime = this.SelectedTime;
    return this.SelectedDate + ' ' + this.SelectedTime;
  }

  convert24HrtoAMPM(timeStr: string): string {
    var H = +timeStr.substr(0, 2);
    var h = H % 12 || 12;
    var ampm = (H < 12 || H === 24) ? " AM" : " PM";
    return h + timeStr.substr(2, 6) + ampm;
  }

  async trayarrange() {
    var settings = null;
    if (this.SelectedDate && this.SelectedTime) {
      settings = this.SelectedDate + ' ' + this.SelectedTime
    }
    // console.log(settings)
    // $('#dots_spinner').show();
    await this.http.post<any>(this.apiList.TrayMotorlstUrl, {
      IdMachine: this.IdMachine,
      LinkCode: this.linkcode,
      CubeNo: this.cubemachinenumber,
      IdLocation: this.Idlocation,
      SelectedDate: settings,
      SessionId: this.Sessionid_Admin,
      IdPlaceMachine:this.placeMachineId
    }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        //  $('#dots_spinner').hide();
        if (response.Response == 1) {
          // console.log(response)
          this.lists = response.list_tray;
          setTimeout(() => {
            let Traymotor_height = $('.main_All_plano').height();
            Traymotor_height = Traymotor_height - 50;
            //console.log(Traymotor_height)
            //console.log(this.initheight);
            if (this.initheight > Traymotor_height) {
              this.initheight = window.innerHeight - 268;
            }
          }, 100);
          for (let i = 0; i < this.lists.length; i++) {
            for (let j = 0; j < this.lists[i].lst_Slots.length; j++) {
              if (this.lists[i].lst_Slots[j].IdProduct > 0) {
                this.count++;
              }
              else if (this.lists[i].lst_Slots[j].IdProduct == 0) {
                $("#" + this.lists[i].lst_Slots[j].IdSlotMaster + "Planoheading").addClass('fill');
              }
            }
          }
          this.MachineProdtype = this.lists[0].ProductType;
          this.EventUrl = response.EventURL;
          // console.log(this.EventUrl)
          this.VendEvents = new EventSource(this.EventUrl);
          this.VendEvents.onmessage = (e) => {
            var Responsee = e.data;
            var Response = JSON.parse(Responsee)
            //  console.log(Response)
            for (let i = 0; i < Response.length; i++) {
              for (let j = 0; j < Response[i].objChange.list_tray.length; j++) {
                for (let l = 0; l < this.lists.length; l++) {
                  for (let m = 0; m < this.lists[l].lst_Slots.length; m++) {
                    if (this.lists[l].lst_Slots[m].IdSlotMaster == Response[i].objChange.list_tray[j].IdSlotMaster) {
                      this.lists[l].lst_Slots[m].Quarantine = Response[i].objChange.list_tray[j].Quarantine
                      this.lists[l].lst_Slots[m].TotalQuantity = Response[i].objChange.list_tray[j].TotalQuantity

                    }
                  }
                }
              }
            }
            // })
          }

        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      });
  }

  Remove(event, j, id) {
    var target = event.currentTarget;
    var pElement = target.parentElement.parentElement;
    var pclassAttr = pElement.attributes.id;
    this.slotid = pclassAttr.value;
    let Stock = $("#" + this.slotid + "stock").val();
    //console.log(Stock)
    if (id != 0 && Stock == 0) {
      this.check = true;
      $('#1par').trigger('change');
      $('#' + this.slotid + 'max').trigger('change');
      this.Max = parseInt($('#' + this.slotid + 'max').val());
      this.Parslot = parseInt($('#' + this.slotid + 'par').val());
      $('#dots_spinner').show();
      this.http.post<any>(this.apiList.RemoveProductUrl, { SlotId: pclassAttr.value, IdMerchant: this.IdMerchant, LinkCode: this.linkcode, CubeNo: this.cubemachinenumber ,IdPlaceMachine : this.placeMachineId}, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (result.Response !== 1) throw result;
          return result;
        })
        .subscribe((response) => {
          if (response.Response == 1) {
            $("#" + this.slotid + "planoModal").modal('hide');
            $('#dots_spinner').hide();
            // this.trayarrange();
            this.Max = parseInt($('#' + this.slotid + 'max').val(0));
            this.Parslot = parseInt($('#' + this.slotid + 'par').val(0));
            for (let i = 0; i < this.lists.length; i++) {
              for (let j = 0; j < this.lists[i].lst_Slots.length; j++) {
                if (this.lists[i].lst_Slots[j].IdSlotMaster == this.slotid) {
                  this.lists[i].lst_Slots[j].IdProduct = 0;
                  this.lists[i].lst_Slots[j].SalesTax = 0;
                  this.lists[i].lst_Slots[j].AvailableQuantity = 0;
                  this.lists[i].lst_Slots[j].TotalQuantity = 0;
                  console.log("this.lists[i].lst_Slots[j].PostDetectSpinTime"+this.lists[i].lst_Slots[j].PostDetectSpinTime);
                  this.lists[i].lst_Slots[j].PostDetectSpinTime=0;
                  this.lists[i].lst_Slots[j].ProductPar=0;
                  this.count--;
                  console.log("remove count" + this.count);
                  if (this.count == 0) {
                    console.log("inside no product");
                    this.lists[i].ProductType = "NoProducts";
                    this.MachineProdtype = "NoProducts";
                   // this.refreshlistproduct();
                    $("#" + pclassAttr.value + "img").empty();
                    $("#" + pclassAttr.value + "img").append("<img src='./assets/images/place-item.jpg';  alt='#' class='img_pic_DivBoxBg'>");
                    $("#" + pclassAttr.value + "Modalimg").empty();
                    $("#" + pclassAttr.value + "Modalimg").append("<img src='./assets/images/place-item.jpg';  alt='#' height='55'>");
                    $("#" + pclassAttr.value + "name").empty();
                    $("#" + pclassAttr.value + "Modalname").empty();
                    $("#" + pclassAttr.value + "Modalname").append("<p style='text-align: center' class='p_text_DivBoxBg'>No Product</p>");
                    $("#" + pclassAttr.value + "price").empty();
                    $("#" + pclassAttr.value + "ModalPrice").empty();
                    $("#" + pclassAttr.value+ 'PostSpin').empty();

                    this.refreshlistproduct();
                    this.trayarrange();
                  }
                }
              }
            }
            if (this.count == 0 || this.count == 1) {
              this.refreshlistproduct();
              this.trayarrange();
              $("#" + pclassAttr.value + "img").empty();
              $("#" + pclassAttr.value + "img").append("<img src='./assets/images/place-item.jpg';  alt='#' class='img_pic_DivBoxBg'>");
              $("#" + pclassAttr.value + "Modalimg").empty();
              $("#" + pclassAttr.value + "Modalimg").append("<img src='./assets/images/place-item.jpg';  alt='#' height='55'>");
              $("#" + pclassAttr.value + "name").empty();
              $("#" + pclassAttr.value + "Modalname").empty();
              $("#" + pclassAttr.value + "Modalname").append("<p style='text-align: center' class='p_text_DivBoxBg'>No Product</p>");
              $("#" + pclassAttr.value + "price").empty();
              $("#" + pclassAttr.value + "ModalPrice").empty();
              $("#" + pclassAttr.value+ 'PostSpin').empty();
              $("#" + pclassAttr.value+ 'productpar').empty();
            }
            this.refreshlistproduct();
            this.trayarrange();
            $("#" + pclassAttr.value + "img").empty();
            $("#" + pclassAttr.value + "img").append("<img src='./assets/images/place-item.jpg';  alt='#' class='img_pic_DivBoxBg'>");
            $("#" + pclassAttr.value + "Modalimg").empty();
            $("#" + pclassAttr.value + "Modalimg").append("<img src='./assets/images/place-item.jpg';  alt='#' height='55'>");
            $("#" + pclassAttr.value + "name").empty();
            $("#" + pclassAttr.value + "Modalname").empty();
            $("#" + pclassAttr.value + "Modalname").append("<p style='text-align: center' class='p_text_DivBoxBg'>No Product</p>");
            $("#" + pclassAttr.value + "price").empty();
            $("#" + pclassAttr.value + "ModalPrice").empty();

            $("#" + pclassAttr.value+ 'PostSpin').empty();
            $("#" + pclassAttr.value+ 'productpar').empty();
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            $("#" + this.slotid + "planoModal").modal('hide');
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
        });
    }
    else {
      this.FlashError("You should remove inventory at the machine before reassigning the channel. ");
    }
  }

  dragEnter(event) {
    console.log("dragEnter");
  }

  dragLeave(event) {
    console.log("dragLeave");
  }

  dragoverMouse(event) {
  }

  releaseDrop(event) {
    console.log("releaseDrop");
  }

  startDrag(Name: string, Price: string, c: string, IdProduct: string, idMainC: number, isSubC: number, k: number, currency, FileMD5Sum, SalesTax, DiscountAmount, DiscountType, rule,ProductPar) {

    console.log("START DRAGGING: " + Name);

    this.DiscountAmount = DiscountAmount;
    this.DiscountType = DiscountType;
    this.SpecialPricingRule = rule;
    if (FileMD5Sum) {
      this.imagedrop = c;
    } else {
      this.imagedrop = '../../../assets/images/place-item.jpg';
    }
    this.dragprodname = Name;
    this.dragprodid = IdProduct;
    this.productType = k;
    this.CurrencyType = currency;
    this.productPar=ProductPar;
    console.log("product par"+this.productPar);
    this.SalesTax = SalesTax
    this.dragprodprice = parseFloat(Price).toFixed(2);
    if (this.DiscountType == '$') {
      this.AfterDiscountAmt = this.dragprodprice - this.DiscountAmount
      this.AfterDiscountAmt = parseFloat(this.AfterDiscountAmt).toFixed(2);
    }
    else if (this.DiscountType == '%') {
      this.AfterDiscountAmt = (this.dragprodprice - ((this.dragprodprice * this.DiscountAmount) / 100));
      this.AfterDiscountAmt = parseFloat(this.AfterDiscountAmt).toFixed(2);
    }

  }

  OpenModal(id) {
    if ($('#' + id + 'img1').hasClass('placed') || $('#' + id + 'Planoheading').hasClass('plano_heading fill')) {
      $("#" + id + "planoModal").modal('show');
    }

  }

  addDropItem(id, productId) {

    console.log(`END DRAG addDropItem(${id}, ${productId})`);

    if (productId != 0) {
      this.FlashError("First remove the product which is already placed in Tray")
    } else {
      // console.log(this.DiscountAmount);
      this.check = false;
      let Stock = $("#" + id + "stock").val();
      console.log("this.MachineProdtype " + this.MachineProdtype);
      console.log("this.productType " + this.productType);
      console.log(`Stock=${Stock}`);
      //  if (this.MachineType == "Master") {
      if ((this.MachineProdtype == this.productType || this.MachineProdtype == "NoProducts") && (Stock == 0)) {
        $("#" + id + "planoModal").modal('show');
        this.MachineProdtype = this.productType;
        $("#" + id).attr('role', this.dragprodid);
        $("#" + id + "img").empty();
        $("#" + id + "img").append("<img src=" + this.imagedrop + " alt='#' id=" + id + "img1  class='img_pic_DivBoxBg placed'>");
        $("#" + id + "Modalimg").empty();
        $("#" + id + "Modalimg").append("<img src=" + this.imagedrop + " alt='#'height='55'>");
        $("#" + id + "name").empty();
        $("#" + id + "name").append(" <p style='text-align: center' class='p_text_DivBoxBg'>" + this.dragprodname + "</p>");
        $("#" + id + "Modalname").empty();
        $("#" + id + "Modalname").append(" <p style='text-align: center' class='p_text_DivBoxBg'>" + this.dragprodname + "</p>");
        $("#" + id + "price").empty();
        $("#" + id + "ModalPrice").empty();
        $("#" + id + 'PostSpin').empty();
        $("#" + id + 'productpar').val(this.productPar);

        

        if (this.DiscountAmount == 0) {
          $("#" + id + "price").append("<div class='pricePlano'>" + this.CurrencyType + this.dragprodprice + "</div>");
          $("#" + id + "ModalPrice").append("<h4  style='margin:0;  text-align: right'>" + this.CurrencyType + this.dragprodprice + "</h4>");
        }
        else {
          $("#" + id + "price").append("<div class='pricePlano'><p class='special_price tooltip2' style='text-align: center'>" + this.CurrencyType + this.AfterDiscountAmt + "<span class='tooltiptextBlack'><span class='with_special_pricing'>" + this.CurrencyType + this.dragprodprice + "</span>" + this.CurrencyType + this.AfterDiscountAmt + '(' + this.SpecialPricingRule + ")</span></p></div>");
          $("#" + id + "ModalPrice").append("<h4 class='with_special_pricing' style='margin:0;  text-align: right'>" + this.CurrencyType + this.dragprodprice + "<h4 style='margin:0;  text-align: right'>" + this.CurrencyType + this.AfterDiscountAmt + '(' + this.SpecialPricingRule + ")</h4></h4>");
        }
        $("#" + id + "SalesTax").empty();
        $("#" + id + "SalesTax").append("<div class='form-control'>" + this.SalesTax + "</div>");

      }
      else {
        this.check = true;
        this.AlertText = "";
        if (Stock == 0) {
          this.FlashError("Product Type Not Match");
        }
        else {
          this.FlashError("You should remove inventory at the machine before reassigning the channel.");
        }
      }
    }
  }

  dropEventMouse(event, idSlotMaster, idProduct) {
    // if drag the product image, I don't get the dropEvent... some directive bug
    // Workaround: instead I rely on drop dropEventMouse which we do get in all cases
    this.addDropItem(idSlotMaster, idProduct);
  }

  refreshplanogram(minSpinTime: number, maxSpinTime: number, minPostDetect: number, maxPostDetect: number) {

    this.Max = parseInt($('#' + this.slotid + 'max').val());
    this.Parslot = parseInt($('#' + this.slotid + 'par').val());
    var maxSpinTimeValue = parseInt($('#' + this.slotid + 'Spin').val());
    var postDetectSpinTimeValue = parseInt($('#' + this.slotid + 'PostSpin').val());
    this.productPar = parseInt($('#' + this.slotid + 'productpar').val());
    var spinTimeValid = this.isBetween(maxSpinTimeValue, minSpinTime, maxSpinTime);
    var extraSpinTimeValid = this.isBetween(postDetectSpinTimeValue, minPostDetect, maxPostDetect);
    if ((!this.dragprodid) || (this.dragprodid == "0")) {
      this.FlashError("Please drag and drop product first");
    }
    else {
      if ((!this.Max) || (this.Max == 0)) {
        this.FlashError("Invalid Max value");
      } else {
        if ((!this.Parslot) || (this.Parslot == 0)) {
          this.FlashError("Invalid Par value");
        }
        else if (!spinTimeValid) {
          this.FlashError(`Maximum spin time must be between ${minSpinTime} and ${maxSpinTime} deciseconds`);
        }
        else if (!extraSpinTimeValid) {
          this.FlashError(`Post-detect spin time must be between ${minPostDetect} and ${maxPostDetect} deciseconds`);
        }
        else {
          this.AvailableQuantity = parseInt($('#' + this.slotid + 'stock').val());
          if (this.Max < this.Parslot) {
            this.FlashError("Par must be less than max");
            $('#' + this.slotid + 'par').val(0);
          }
          else if ((this.Max >= this.Parslot)) {
            $('#dots_spinner').show();
            this.http.post<any>(this.apiList.AddProdToMachineUrl, {
              IdMerchant: this.IdMerchant,
              IdLocation: this.Idlocation,
              LinkCode: this.linkcode,
              CubeNo: this.cubemachinenumber,
              ProductId: this.dragprodid,
              SlotId: this.slotid,
              TotalQuantity: this.Max,
              AvailableQuantity: this.AvailableQuantity,
              Par: this.Parslot,
              MaxSpinTime: maxSpinTimeValue,
              PostDetectSpinTime: postDetectSpinTimeValue,
              ProductPar:this.productPar,
              IdPlaceMachine:this.placeMachineId
            }, this.globalservice.Headers())
              .take(1)
              .timeout(25000)
              .map(result => {
                if (result.Response !== 1) throw result;
                return result;
              })
              .subscribe((response) => {
                // this.data = response;
                // console.log(this.data);
                if (response.Response == 1) {
                   //console.log("response.PostDetectSpinTime"+response.PostDetectSpinTime);
                  for (let i = 0; i < this.lists.length; i++) {
                    if (this.lists[i].TrayNo == response.TrayNo) {
                      for (let j = 0; j < this.lists[i].lst_Slots.length; j++) {
                        if (this.lists[i].lst_Slots[j].IdSlotMaster == this.slotid) {
                          this.lists[i].lst_Slots[j].IdProduct = response.IdProduct;
                          this.lists[i].lst_Slots[j].SalesTax = response.SaleTax;
                         // this.lists[i].lst_Slots[j].PostDetectSpinTime=response.PostDetectSpinTime;
                          //this.lists[i].lst_Slots[j].Price = this.data.Price;
                          this.count++;
                          console.log(this.count)

                          this.refreshlistproduct();
                          this.trayarrange();
                          // if (this.count == 1) {
                          //   this.refreshlistproduct()
                          // }
                        }
                      }
                    }
                  }
                  // console.log(this.lists)
                  $("#" + this.slotid + "Planoheading").addClass('fill');

                  $("#" + this.slotid + "planoModal").modal('hide');
                  this.FlashError("Successfully Applied");
                }
              }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                  if (Error.ErrorMessage) {
                    this.FlashError(Error.ErrorMessage);
                    this.globalservice.Logout('Yes');
                  }
                  this.globalservice.Logout('No');
                  return;
                } else {
                  $("#" + this.slotid + "Planoheading").addClass('fill');
                  $("#" + this.slotid + "planoModal").modal('hide');
                  this.FlashError(Error.ErrorMessage);
                  return;
                }
              }).add(() => {
                $('#dots_spinner').hide();
              });
          }
        }
      }
    }
  }

  isBetween(n, a, b) {
    return (n - a) * (n - b) <= 0
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  submitSlot(event, j, minSpin: number, maxSpin: number, minPostDetect: number, maxPostDetect: number) {
    this.check = true;
    var target = event.currentTarget;
    var pElement = target.parentElement.parentElement;
    var pclassAttr = pElement.attributes.id;
    var pclassAttr12 = pElement.attributes.role;
    this.dragprodid = pclassAttr12.value;
    this.slotid = pclassAttr.value;
    //console.log(this.slotid)
    $('#1par').trigger('change');
    $('#' + this.slotid + 'max').trigger('change');
    this.refreshplanogram(minSpin, maxSpin, minPostDetect, maxPostDetect);
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/placeaMachine']);
  }

  clearSearch() {
    this.Search = '';
    this.refreshlistproduct();
  }


  Cancel(event, j) {
    if (this.count == 0) {
      this.MachineProdtype = "NoProducts";
    }
    var target = event.currentTarget;
    var pElement = target.parentElement.parentElement;
    var pclassAttr = pElement.attributes.id;
    var a = pclassAttr.value.split('-');
    this.slotid = a[0];
    this.Max = parseInt($('#' + this.slotid + 'max').val(0));
    this.Parslot = parseInt($('#' + this.slotid + 'par').val(0));
    this.productPar = parseInt($('#' + this.slotid + 'productpar').val(0));
    $("#" + this.slotid + "planoModal").modal('hide');
    $("#" + this.slotid + "img").empty();
    $("#" + this.slotid + "img").append("<img src='./assets/images/place-item.jpg';  alt='#' class='img_pic_DivBoxBg'>");
    $("#" + this.slotid + "Modalimg").empty();
    $("#" + this.slotid + "Modalimg").append("<img src='./assets/images/place-item.jpg';  alt='#' height='55'>");
    $("#" + this.slotid + "name").empty();
    $("#" + this.slotid + "Modalname").empty();
    $("#" + this.slotid + "Modalname").append("<p style='text-align: center' class='p_text_DivBoxBg'>No Product</p>");
    $("#" + this.slotid + "price").empty();
    $("#" + this.slotid + "ModalPrice").empty();
    $('#' + this.slotid + 'PostSpin').empty();
    $('#' + this.slotid + 'productpar').empty();


  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerHeight;
    //console.log(window.innerHeight);
    this.pickerheight = window.innerHeight - 67;
    this.resetheight = window.innerHeight - 200;
    this.initheight = this.resetheight
  }
  ngOnDestroy() {
    // this.subscription.unsubscribe();
    if (this.VendEvents) {
      this.VendEvents.close();
    }
  }
  
}
