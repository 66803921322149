import { Component, OnInit, HostListener } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material/sort';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
import { Subject } from 'rxjs';

declare const $;
@Component({
  selector: 'app-superadminlist',
  templateUrl: './superadminlist.component.html',
  styleUrls: ['./superadminlist.component.css']
})
export class SuperadminlistComponent implements OnInit {

  screenHeight: number;
  before: number;
  pageno: any;
  lists;
  posts;
  Search;
  Cdetails;
  CompanyName;
  CompanyAddress;
  CompanyPhoneNumber;
  City;
  State;
  Zip;
  ContactPerson;
  ContactPersonNumber;
  ContactPersonEmailId;
  RequestedPassword;
  RequestedUserName;
  IdMerchant;
  p = 1;
  AlertText;
  Accept;
  Reject;
  key = 'name'; // set default
  reverse = false;
  user_id;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate

  constructor(
    public apiList: ApilistService,
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService) {
      this.resize$.debounceTime(100).subscribe(innerWidth => {
        if (this.Resizing) { return; }
        this.Repaginate();
        this.SettleAndAutosize(80);
      });
      this.Repaginate();
      this.Superadminlist();
  }


  ngOnInit() {
    $('#alert_div').hide();
    $('#dots_spinner').hide();
    
  }

  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
//console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  Superadminlist() {
    $('#dots_spinner').show();
    this.http.get<any>(this.apiList.SuperadminlistUrl, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe(response => {
        this.posts = response.lst_Customer;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
        if (this.Resizing) {
          // need to give time to render behind the scenes before size/resize rows
          this.SettleAndAutosize();
        }
      });
  }

  createsuper() {
    this.router.navigate(['./Superadmindashboard/Superadminlist/Createsuperadmin']);
  }

  getId(id) {
    this.user_id = id;
  }

  delete() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteSuperAdmin, { IdMerchant: this.user_id }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe(response => {
        this.FlashError('Successfully deleted');
          this.Superadminlist();
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }
  
  edit(id) {
    this.router.navigate(['./Superadmindashboard/Superadminlist/Updatesuperadmin',
      { id: id }
    ]);
  }
  //////// sorting////////////////
  sortData(sort: Sort) {
    const data = this.posts.slice();
    if (!sort.active || sort.direction === '') {
      this.posts = data;
      return;
    }
    this.posts = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.Name.toLowerCase(), b.Name.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  /////////////////////////////
  clearSearch() {
    this.Search = '';
    this.Superadminlist();
  }
  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }
}
