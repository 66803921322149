import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';


declare const $;
@Component({
  selector: 'app-updatesuperadmin',
  templateUrl: './updatesuperadmin.component.html',
  styleUrls: ['./updatesuperadmin.component.css']
})
export class UpdatesuperadminComponent implements OnInit {
  merchant_id;
  posts;
  RequestedUserName;
  Name;
  AlertText;
  Search;
  Country;
  State;
  City;
  Zip;
  countrylists;
  selectedState;
  citys;
  states;
  IdCountry;
  IdState;
  selectedcountry;
  selectedcity;
  IdCity;
  RequestedPassword;
  confirmPassword;
  iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass = false;
  type = 'password';
  iconname2 = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass2 = false;
  type2 = 'password';
  compare = true;
  nextLibAvailable = false;
  show_message;
  CompanyName: any;
  CompanyAddress: any;
  CompanyPhoneNumber: any;
  ContactPersonEmailId: any;
  ContactPerson: any;
  ContactPersonNumber: any;


  constructor
    (public apiList: ApilistService,
      private route: ActivatedRoute,
      private http: HttpClient,
      private router: Router,
      private globalservice: GlobalService) {
    this.route.params.subscribe((get) => {
      this.merchant_id = get.id;
    });
  }

  ngOnInit() {
    $('#alert_div').hide();
    this.admindetails();
    this.globalservice.getCountry().then((res: any) => {
      this.countrylists = res;
    });
  }

  admindetails() {
    this.http.post<any>(this.apiList.MerchantDetailUrl,
      { IdMerchant: this.merchant_id }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.posts = response;
        if (this.posts.Response === 1) {
          this.Name = this.posts.obj_MerchantRecord.Name;
          this.RequestedUserName = this.posts.obj_MerchantRecord.EmailId;
          this.selectedcountry = this.posts.obj_MerchantRecord.IdCountry;
          this.IdCountry = this.selectedcountry;
          this.CompanyName = this.posts.obj_MerchantRecord.CompanyName;
          this.CompanyAddress = this.posts.obj_MerchantRecord.CompanyAddress;
          this.CompanyPhoneNumber = this.posts.obj_MerchantRecord.CompanyPhoneNumber;
          this.ContactPerson = this.posts.obj_MerchantRecord.ContactPerson;
          this.ContactPersonEmailId = this.posts.obj_MerchantRecord.ContactPersonEmailId;
          this.ContactPersonNumber = this.posts.obj_MerchantRecord.ContactPersonNumber;
          if (typeof this.IdCountry === 'undefined') {
          } else {
            this.getStates();
            this.selectedState = this.posts.obj_MerchantRecord.IdState;
            this.IdState = this.selectedState;

          }
          if (typeof this.IdState === 'undefined') {
            alert('value undefined');
          } else {
            this.getcity();
          }
          this.selectedcity = this.posts.obj_MerchantRecord.IdCity;
          this.IdCity = this.selectedcity;
          this.Zip = this.posts.obj_MerchantRecord.Zip;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });

  }

  editsuperadmin() {
    if (this.RequestedPassword === this.confirmPassword) {
      const url = `${this.apiList.UpdatesuperadminUrl}`;
      const data = {
        Name: this.Name,
        EmailId: this.RequestedUserName,
        IdMerchant: this.merchant_id,
        Password: this.RequestedPassword,
        IdCountry: this.IdCountry,
        IdState: this.IdState,
        IdCity: this.selectedcity,
        Zip: this.Zip,
        UserRole: 'SuperAdmin',
        CompanyName: this.CompanyName,
        CompanyAddress: this.CompanyAddress,
        CompanyPhoneNumber: this.CompanyPhoneNumber,
        ContactPerson: this.ContactPerson,
        ContactPersonEmailId: this.ContactPersonEmailId,
        ContactPersonNumber: this.ContactPersonNumber
      };
      this.http.post<any>(url, data, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(resp => {
          if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
          if (+resp.Response !== 1) { throw resp; }
          return resp;
        }).subscribe((response) => {
         // this.posts = response;
          // if (response.Response === 1) {
            this.router.navigate(['./Superadmindashboard/Superadminlist']);
          // }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        });
    } else {
      this.FlashError('Password and Confirm Password does not match');
    }
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  onChangeCountry(a) {
    this.IdCountry = a;
    if (typeof this.IdCountry === 'undefined') {
      alert('value undefined');
    } else {
      this.getStates();
    }
  }

  getStates() {
    $('#dots_spinner').show();
    const url = `${this.apiList.StateUrl}`;
    this.http.post<any>(url, { IdCountry: this.IdCountry }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.states = response.lst_State;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  onChangeState(b) {
    this.IdState = b;
    if (typeof this.IdState === 'undefined') {
      alert('value undefined');
    } else {
      this.getcity();
    }

  }
  getcity() {
    $('#dots_spinner').show();
    const url = `${this.apiList.CityUrl}`;
    this.http.post<any>(url, { IdState: this.IdState }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.citys = response.lst_City;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }
  goback() {
    this.router.navigate(['./Superadmindashboard/Superadminlist']);
  }
  showPassword() {
    this.showPass = !this.showPass;
    if (this.showPass) {
      this.type = 'text';
      this.iconname = 'fa fa-fw fa-eye field-icon toggle-password';
    } else {
      this.type = 'password';
      this.iconname = 'fa fa-fw fa-eye-slash  field-icon toggle-password';
    }
  }

  confirm_pass() {
    this.showPass2 = !this.showPass2;
    if (this.showPass2) {
      this.type2 = 'text';
      this.iconname2 = 'fa fa-fw fa-eye field-icon toggle-password';
    } else {
      this.type2 = 'password';
      this.iconname2 = 'fa fa-fw fa-eye-slash  field-icon toggle-password';
    }
  }

  onKey(event: any) { // without type info
    if (this.RequestedPassword === this.confirmPassword) {
      this.compare = true;
    } else {
      this.show_message = 'Password and Confirm Password does not match';
      this.compare = false;
    }
  }
  onKey1(event) {
    if (this.RequestedPassword) {
      this.show_message = '';
      this.nextLibAvailable = true;
    } else {
      this.show_message = '';
      this.nextLibAvailable = false;
      this.compare = false;
    }
  }
}
