import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service'
declare const $;
@Component({
  selector: 'app-updatesuppliers',
  templateUrl: './updatesuppliers.component.html',
  styleUrls: ['./updatesuppliers.component.css']
})
export class UpdatesuppliersComponent implements OnInit {
  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  public mask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  posts: any;
  IdProductSupplier: number;
  CompanyAddress: any;
  CompanyName: any;
  CompanyEmailId: any;
  CompanyPhoneNo: any;
  IdCity: any;
  ContactEmailId: any;
  ContactName: any;
  ContactPhoneNo: any;
  IdCountry: any;
  IdState: any;
  ZipCode: any;
  selctedcountry: any;
  countrylists: any;
  states: any;
  citys: any;
  selectedcity: any;
  selectedstate: any;
  CityName: any;
  result: any;
  AlertText: any;

  constructor(
    public apiList: ApilistService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient, private globalservice: GlobalService) {
    this.route.params.subscribe((get) => {
      this.IdProductSupplier = get.id;
    })
  }


  ngOnInit() {
this.HidePopups()
    this.globalservice.getCountry().then((res:any)=>{
      this.countrylists = res;
      this.GetSuplierDetails();
    });
    //this.GetSuplierDetails();
  }
  HidePopups(){
        $("#alert_div").hide();
    $('#dots_spinner').hide();
  }

  GetSuplierDetails() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.SupplierDetailsUrl, { IdProductSupplier: this.IdProductSupplier }, this.globalservice.Headers()).take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      $('#dots_spinner').hide();
      if (response.Response == 1) {
      this.posts = response;
      this.CompanyName = this.posts.obj_ProductSupplier.CompanyName;
      this.CompanyAddress = this.posts.obj_ProductSupplier.CompanyAddress;
      this.CompanyEmailId = this.posts.obj_ProductSupplier.CompanyEmailId;
      this.CompanyPhoneNo = this.posts.obj_ProductSupplier.CompanyPhoneNo;
      this.IdCity = this.posts.obj_ProductSupplier.IdCity;
      this.ContactEmailId = this.posts.obj_ProductSupplier.ContactEmailId;
      this.ContactName = this.posts.obj_ProductSupplier.ContactName;
      this.ContactPhoneNo = this.posts.obj_ProductSupplier.ContactPhoneNo;

      this.selctedcountry = this.posts.obj_ProductSupplier.IdCountry;;
      this.IdCountry = this.selctedcountry;
      this.IdState = this.posts.obj_ProductSupplier.IdState;
      this.selectedstate = this.posts.obj_ProductSupplier.IdState;
      this.IdState = this.selectedstate;

      if (typeof this.IdCountry === 'undefined') {
        alert("value undefined");
      }
      else {
        this.getstates();
        this.selectedcity = this.posts.obj_ProductSupplier.IdCity;
        this.IdCity = this.selectedcity;
      }
      if (typeof this.IdState === 'undefined') {
        alert("value undefined");
      }
      else {
        this.getcity();
      }
      this.CityName = this.posts.obj_ProductSupplier.CityName;
      this.ZipCode = this.posts.obj_ProductSupplier.ZipCode;
    }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  onChangeCountry(a) {
    this.IdCountry = a;
  //  console.log(this.selectedstate);
    this.selectedstate=null
  //  console.log(this.selectedstate);
    if (typeof this.IdCountry === 'undefined') {
      alert("value undefined");
    }
    else {
      this.getstates();
    }
  }

  getstates() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.StateUrl, { IdCountry: this.IdCountry }, this.globalservice.Headers()).take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if(response.Response==1){
        this.states = response.lst_State;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  onChangeState(b) {
    this.IdState = b;
    this.selectedcity=null
    if (typeof this.IdState === 'undefined') {
      alert("value undefined");
    }
    else {
      this.getcity();
    }
  }

  getcity() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CityUrl, { IdState: this.IdState }, this.globalservice.Headers()).take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if(response.Response==1){
        this.citys = response.lst_City;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  Update() {
    this.http.post<any>(this.apiList.UpdateSupplierUrl, { IdProductSupplier: this.IdProductSupplier, IdMerchant: this.IdMerchant, CreatedBy: this.IdMerchant, CompanyName: this.CompanyName, CompanyAddress: this.CompanyAddress, CompanyPhoneNo: this.CompanyPhoneNo, CompanyEmailId: this.CompanyEmailId, ContactName: this.ContactName, ContactPhoneNo: this.ContactPhoneNo, ContactEmailId: this.ContactEmailId, IdCountry: this.IdCountry, IdState: this.IdState, IdCity: this.selectedcity, ZipCode: this.ZipCode }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.router.navigate([this.companyName + '/dashboard/ProductSuppliers']);
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       });
  }

 FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
  
  goback() {
    this.router.navigate([this.companyName + '/dashboard/ProductSuppliers']);
  }
}