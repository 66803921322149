import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
declare const $;
import * as moment from 'moment';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-updatecoupons',
  templateUrl: './updatecoupons.component.html',
  styleUrls: ['./updatecoupons.component.css']
})
export class UpdatecouponsComponent implements OnInit {
  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));

  posts
  IdCoupon
  CouponCode
  DiscountType
  CouponType
  CouponUse
  MultipleUse
  DiscountAmount
  StartDate
  EndDate
  StartTime
  EndTime
  CreatedDate
  AlertText = ""
  selectedMachineproduct
  selectedProduct2
  mlists = [];
  Mlists; IdProducts
  Mlistsss = [];
  array
  nextLibAvailable = false;
  IdMachines; productlenght;
  partiall;
  Date; time;
  discount_show = 1;
  Dis_amount = false;
  Changecoupon_type
  coupon_use
  error: number;
  cacheProducts = [];
  Glists = [];

  constructor(
    private router: Router,
    private http: HttpClient, private route: ActivatedRoute, private globalservice: GlobalService,
    public apiList: ApilistService
  ) {
    this.route.params.subscribe((get) => {
      this.IdCoupon = get.id;
    })
  }


  ngOnInit() {
    this.Date = new Date();
    this.listofmachine();
     this.HidePopups()
    this.dateTimeFunction()
   
    
  }


  HidePopups(){
 $("#alert_div").hide();
    $('#dots_spinner').hide();
    $('#myModal').modal('hide');
  }

   dateTimeFunction(){
     $('body').on('change', '#timepicker1', function () {
      $('#timepicker1').trigger('click');
    });
    $('#timepicker1').timepicker({
      timeFormat: 'HH:mm:ss',
    });
    $('body').on('change', '#timepicker2', function () {
      $('#timepicker2').trigger('click');
    });
    $('#timepicker1').timepicker();
    $('#timepicker2').timepicker();
   }


  randomNumber() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (var i = 0; i < 6; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    this.CouponCode = text;
    this.CouponCodeUnique();
    return text;
  }
  CouponCodeUnique() {
    this.http.post<any>(this.apiList.IsExistCouponCode, {CouponCode: this.CouponCode, CreatedBy: this.IdMerchant, CouponId: this.IdCoupon }, this.globalservice.Headers()).subscribe((response) => {
      var rr = response
      if (rr.Response == 2) {
        this.error = 1;
      }
      else {
        this.error = 0;
      }
    });
  }

  GetCouponDetails() {
    this.array = [];
    var array2 = [];
    this.http.post<any>(this.apiList.GetCouponDetailUrl, { IdCoupon: this.IdCoupon, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
     .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
      this.posts = response;
      if (this.posts.Response == 1) {
        // console.log(this.posts);
        this.CouponCode = this.posts.Obj_Coupon.CouponCode
        this.CouponUse = this.posts.Obj_Coupon.CouponUse;
        if (this.CouponUse == "PartialUse") {
          this.partiall = true
        }
        this.CouponType = this.posts.Obj_Coupon.CouponType;
        this.Changecoupon_type = this.posts.Obj_Coupon.CouponUse;
        this.DiscountAmount = this.posts.Obj_Coupon.DiscountAmount
        this.DiscountType = this.posts.Obj_Coupon.DiscountType;
        if (this.DiscountType == '%') {
          this.discount_show = 1
        } else {
          this.discount_show = 2
        }
        this.EndDate = this.posts.Obj_Coupon.EdateString
        this.StartDate = this.posts.Obj_Coupon.SdateString;
        //  console.log(this.StartDate);
        this.StartDate =  moment(this.Date).format('MM/DD/YYYY')
        $('#SDate').val(this.StartDate);
        $('#EDate').val(this.EndDate);
        console.log( this.StartDate)
        this.EndTime = this.posts.Obj_Coupon.EndTime
        this.StartTime = this.posts.Obj_Coupon.StartTime
        $('#timepicker1').timepicker('setTime', this.posts.Obj_Coupon.StartTime);
        $('#timepicker2').timepicker('setTime', this.posts.Obj_Coupon.EndTime);
        for (let i = 0; i < this.posts.lst_Machines.length; i++) {
          this.array.push(this.posts.lst_Machines[i].IdMachine);
        }
        this.selectedMachineproduct = this.array;
        //console.log(this.selectedMachineproduct)
        this.IdMachines = this.selectedMachineproduct.toString();
        for (let i = 0; i < this.posts.lst_Products.length; i++) {
          array2.push(this.posts.lst_Products[i].IdProduct);
        }
        this.selectedProduct2 = array2;
        console.log(this.selectedProduct2)
        this.IdProducts = this.selectedProduct2.toString();
        this.refreshlistproductMachine();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
        this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide() });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  addcoupon() {
    this.time = this.Date.getHours() + ":" + this.Date.getMinutes() + ":00"
    var date = (this.Date.getMonth() + 1) + '/' + this.Date.getDate() + '/' + this.Date.getFullYear();
    if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {
      if (this.timeconversion(this.StartTime) == this.timeconversion("12:00 AM") && this.timeconversion(this.EndTime) == this.timeconversion("12:00 AM")) {
        //  console.log(this.CouponCode, this.DiscountType, this.CouponType, this.DiscountAmount, this.StartDate + " " + this.StartTime, this.EndDate + " " + this.EndTime, this.IdMachines, this.IdProducts);
        this.UpdateCoupon();
      }
      else if (this.toTimestamp(this.StartDate) >= this.toTimestamp(date) && this.toTimestamp(this.EndDate) != this.toTimestamp(date)) {
        if (this.timeconversion(this.StartTime) < this.timeconversion(this.EndTime)) {
          this.UpdateCoupon();
        }
        else if ((this.timeconversion(this.StartTime) >= this.timeconversion(this.EndTime))) {
          $('#dots_spinner').hide();
          this.FlashError("Start Time Should be less than End Time");
        }
        else {
          $('#dots_spinner').hide();
          this.FlashError("Start Time Should be greater than Current Time");
        }
      }
      else if (this.toTimestamp(this.StartDate) == this.toTimestamp(date) && this.toTimestamp(this.EndDate) == this.toTimestamp(date)) {
        //  console.log(this.timeconversion(this.StartTime) + ">=" + this.time)
        if (this.timeconversion(this.StartTime) >= this.time) {
          if (this.timeconversion(this.StartTime) < this.timeconversion(this.EndTime)) {
            this.UpdateCoupon();
          }
          else if ((this.timeconversion(this.StartTime) >= this.timeconversion(this.EndTime))) {
            $('#dots_spinner').hide();
            this.FlashError("Start Time Should be less than End Time");
          }
        } else {
          $('#dots_spinner').hide();
          this.FlashError("Start Time Should be greater than Current Time");
        }
      }
      else {
        $('#dots_spinner').hide();
        this.FlashError("Something went wrong");

      }
    }
    else if (this.toTimestamp(this.StartDate) >= this.toTimestamp(this.EndDate)) {
      $('#dots_spinner').hide();
      this.FlashError("Start Date should be less than End Date");
    } else {
      $('#dots_spinner').hide();
      this.FlashError("Something went wrong");

    }
  }

  UpdateCoupon() {
    $('#dots_spinner').show();

    let req =  {
      IdCoupon: this.IdCoupon,
      CouponCode: this.CouponCode,
      DiscountType: this.DiscountType,
      CouponType: this.CouponType,
      CouponUse: this.CouponUse,
      DiscountAmount: this.DiscountAmount,
      StartDate: this.StartDate + " " + this.StartTime,
      EndDate: this.EndDate + " " + this.EndTime,
      StartTime: this.StartTime,
      EndTime: this.EndTime,
      CreatedBy: this.IdMerchant,
      IdMachines: this.IdMachines,
      IdProducts: this.IdProducts
    };

    this.http.post<any>(this.apiList.UpdateCouponUrl, req, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(resp => {
        if (resp == null) resp = { Response: 0, ErrorMessage: "Null response"};
        resp.Response = +resp.Response;
        if (resp.Response != 1) throw resp;
        return resp;
      })
      .subscribe((response) => {
        this.FlashError(response.ErrorMessage);
        this.router.navigate([this.companyName + '/dashboard/coupons']);

      }, err => {

        if (err.Response === 35) {
          if (this.AlertText) {
            this.FlashError(err.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
        }
        else {
           this.FlashError(err.ErrorMessage);
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  timeconversion(Time) {
    var time = Time;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes + ":" + "00"
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDate = $('#SDate').val();
  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    this.EndDate = $('#EDate').val();
  }

  startTime(time: string) {
    this.StartTime = time;
  }
  endTime(tim: string) {
    this.EndTime = tim;
  }

  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  submit_click() {
    this.addcoupon();
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/coupons']);
  }
  
  onChangeMachine(a) {
    this.nextLibAvailable = true;
    $('#uncheckall').removeClass("btn_active");
    $('#checkall').removeClass("btn_active");
    a = a.map(function (e) { return JSON.stringify(e); });
    a = a.join(",");
    this.IdMachines = a;
    this.refreshlistproductMachine();
  }

  listofmachine() {
    // $('#dots_spinner').show();
    let url1 = `${this.apiList.GetPlaceMachinesForSpecialPrice}`;
    this.http.post<any>(url1, { IdMerchant: this.IdMerchant }, this.globalservice.Headers()).pipe(
      switchMap((response) => {      
      if (response.Response == 1) {
        this.mlists = response.lst_sp_GetPlacedMachinesWithLocation;
        let array = [];
        for (let i = 0; i < this.mlists.length; i++) {
          array.push(this.mlists[i].IdMachine);
        }
        this.IdMachines = array.toString();
        let url2 = `${this.apiList.ProductLstMachineUrl}`;
        return this.http.post<any>(url2, { IdMachines: this.IdMachines }, this.globalservice.Headers());
      }
    })).take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      // $('#dots_spinner').hide();
        if (response.Response == 1) {
          this.cacheProducts = response.lst_Product;
          this.GetCouponDetails();
        }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       });
  }

  refreshlistproductGlobal() {
    // $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ProductLstGlobalUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      //  $('#dots_spinner').hide();
      if (response.Response == 1) {
        this.Glists = response
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        });
  }

  refreshlistproductMachine() {

    if (this.IdMachines) {

      // O(1) lookup hash for set of machine id's
      // { machineID1: true, machineID2: true, ... }
      let machines = this.IdMachines.split(',').reduce((acc, cur) => {
        acc[+cur] = true;
        return acc;
      }, {});

      let seenProduct = {};
      let products = [];

      // build a list of unique products... O(1) efficiency
      this.cacheProducts.forEach(p => {

        // filter unique list of product IDs, Name by selected machines
        if (machines[p.IdMachine] && !seenProduct[p.IdProduct]) {
          seenProduct[p.IdProduct] = true;
          products.push({
            IdProduct: p.IdProduct,
            Name: p.Name
          });
        }

      });

      this.Mlists = products;
      console.log( this.Mlists);
      this.productlenght = this.Mlists.length;
    } else {
      this.nextLibAvailable = false;
      this.Mlists = [];
      this.productlenght = 0;
      this.selectedProduct2 = '';
    }
  }


  onChangeProduct2(id2) {
    $('#uncheckall').removeClass("btn_active");
    $('#checkall').removeClass("btn_active");
    id2 = id2.map(function (e) { return JSON.stringify(e); });
    id2 = id2.join(",");
    this.IdProducts = id2;
  }

  selectAll(id) {
    $('#checkall').addClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
  if (id == 'machine') {
    this.array = [];
    for (let i = 0; i < this.mlists.length; i++) {
      this.array.push(this.mlists[i].IdMachine);
    }
    this.selectedMachineproduct = this.array;
    this.IdMachines = this.selectedMachineproduct.toString();
    // console.log(this.IdMachines);
    this.nextLibAvailable = true;
    this.refreshlistproductMachine();
  } else if (id == 'multipleproduct') {
    this.array = [];
    for (let i = 0; i < this.Mlists.length; i++) {
      this.array.push(this.Mlists[i].IdProduct);
    }
    this.selectedProduct2 = this.array;
    this.IdProducts = this.selectedProduct2.toString();
  }
}

  deselectAll(id) {
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').addClass("btn_active");
    if (id == 'machine') {
      this.selectedMachineproduct = "";
      this.array = [];
      this.IdMachines = "";
      this.refreshlistproductMachine();
    } else if (id == 'multipleproduct') {
      // $('#checkall').removeClass("btn_active");
      // $('#uncheckall').addClass("btn_active");
      this.selectedProduct2 = "";
      this.array = [];
      this.IdProducts = "";
    }
  }

  partial() {
    this.partiall = true;
    this.DiscountType = "$"
    this.DiscountAmount = ""
    this.discount_show = 2;
    this.Dis_amount = true;

  }

  onetime() {
    this.partiall = false;
    this.DiscountAmount = ""
  }

  type(value) {
    // console.log(value);
    this.discount_show = value;
    this.Dis_amount = true;
    this.DiscountAmount = "";
    // console.log(value)

  }

  change_couponuse(use) {
    $('#myModal').modal('show');
    this.coupon_use = use;
  }

  yes() {
    $('#myModal').modal('hide');
    switch (this.coupon_use) {
      case 'OneTime':
        this.onetime()
        break;
      case 'PartialUse':
        this.partial();
        break;
      case 'MultipleUse':
        this.onetime()
        break;
      default:
      // code to be executed if n doesn't match any constant
    }

  }
  No() {
    $('#myModal').modal('hide');
    this.CouponUse = this.Changecoupon_type;
  }

}
