import { Component, OnInit, Inject, HostListener, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router, NavigationStart } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { WindowRefService } from './place-machine/planogramtraymotor/window-ref.service';
import { DOCUMENT } from '@angular/common';
import { UserService } from "../user.service";
import { environment } from '../../environments/environment';
import { FormControl, NgForm } from "@angular/forms";
import { GlobalService } from '../global.service';
import { CropperComponent } from 'angular-cropperjs';
import { ApilistService } from '../../service/Api/apilist.service'
import { filter } from 'rxjs/operators';
declare const $;
//declare var Demo: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  /*
  these variable are used for image crop
  */
  base64: any;
  cropperRes: string;
  showCropper: boolean;
  cropperConfig: object = {
    movable: true,
    scalable: true,
    zoomable: true,
    rounded: true,
    background: true,
    aspectRatio: 1 / 1,
    viewMode: 1,
    checkCrossOrigin: true
  };

  @ViewChild('angularCropper', { static: false }) public angularCropper: CropperComponent;
  /*************** End */
  @ViewChild('changepassword', { static: false }) public ChangePassword: NgForm
  baseUrl = environment.baseUrl;
  BaseFileUrl = environment.BaseFileUrl;
  ImageUrl = this.BaseFileUrl + "/ProfilePics/"
  //ContactPerson = sessionStorage.getItem("ContactPersonName");
  IdMerchant = + parseInt(sessionStorage.getItem("referMerchantId"));
  IdMasterMerchant = + sessionStorage.getItem("referMasterMerchantId");
  SuperMerchantId = + sessionStorage.getItem("referSuperMerchantId")
  UserRole = sessionStorage.getItem("referUserRole");
  InventoryUserRole = sessionStorage.getItem("Inventoryuserole");
  companyName = sessionStorage.getItem("companyName");
  logoCompanyName = sessionStorage.getItem("logocompanyName");

  Date
  ToDate
  posts
  Name
  CompanyName
  CompanyAddress
  CompanyPhoneNo
  City
  State
  Country
  data
  ContactPersonNumber
  Zip
  ProfileImg
  base64Profilepic
  ImagePath = "";
  private window: Window;
  OldPassword: any
  NewPassword: any
  NewPassword1: any
  AlertText
  details
  MachineVisible = false
  CategoryVisible = false
  ProductVisible = false
  LocationVisible = false
  PlaceMachineVisible = false
  UsersVisible = false
  UIVisible = false
  ReportVisible = false

  DigitalSinageVisible = false
  SpecialPricingVisible = false
  CouponVisible = false
  hoverBox: boolean = false;
  CompanyLogo = "";
  showbutton = 1;
  keylock
  compare = true
  nextLibAvailable = false;
  show_message;
  FacialVisible = false;
  AgeverfiactionVisible = false;
  FileMD5Sum: any;
  ThemeVisible = false;
  LogoVisible = false;
  InventoryManagementVisible = false;
  image_upload: any;
  Sessionid: string;
  Server = {
    idSMTPServer: '0',
    hostName: '',
    portNumber: '',
    userName: '',
    userEmail: '',
    userPassword: '',
  }
  Session_id: any;
  dashboardVisible: any = false;
  GlobalSettingVisible: any = false;
  EventSubscription: any = false;
  BrandUserVisible: any = false;
  IsBrandVisible: boolean = false; //$$ BUG had to add since front end looks for this, but no one ever updates this. Please fix.
  Alerts: any = false;
  LookupFeature :any;
  blobImageObject: Blob;
  FileName: any;
  UserRolee;
  IsSamplingActive:boolean=false;
  Samplingvisible:boolean=false;

  constructor(public apiList: ApilistService, private user: UserService, private http: HttpClient, private router: Router, @Inject(DOCUMENT) private document: Document, @Inject(WindowRefService) private windowRef: WindowRefService, public globalService: GlobalService) {
    this.window = windowRef.nativeWindow;
  }

  ngOnInit() {
    this.IsBrandVisible=false;
    this.IsSamplingActive=false;
    this.admindetails();
    this.keylock = 0
    $('#server').modal('hide');
    $('#ChangePassword').modal('hide');
    $("#alert_div").hide();


    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });

    $(".sidenav_overlay").hide();
    setInterval(() => {
      this.Date = new Date();
    }, 1000);

    setInterval(() => {
      this.Date = new Date();
    }, 1000);

    $('.dropdown').on('show.bs.dropdown', function (e) {
      $(this).find('.dropdown-menu').first().stop(true, true).slideDown(300);
    });

    $('.dropdown').on('hide.bs.dropdown', function (e) {
      $(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
    });
    $(function () {
      $(".dropdown").on("show.bs.dropdown hide.bs.dropdown", function () {
        $(this).find(".fa-caret-down").toggleClass("fa-caret-up");
      });
    });
    //............for getting name of pic...............
    // Demo.init();

    $(document).on('click.bs.dropdown.data-api', '.dropdown', function (e) {
      e.stopPropagation();
    });
  }

  mouseEnter() {
    $(".long-text").addClass("show-long-text");
  }
  mouseLeave() {
    $(".long-text").removeClass("show-long-text");
  }

  upload() {
    if (this.blobImageObject !== null && this.blobImageObject !== undefined) {
      $('#dots_spinner').show();
      this.globalService.uploadFiles(this.blobImageObject).then(async (res: any) => {
        if (res.Response === 1) {
          $('#dots_spinner').hide();
          // this.ImagePath = res.PreviewUrl;
          this.FileName = res.FileName;
          this.showbutton = 0;
          await this.saveData();
        }
      }, error => {
        $('#dots_spinner').hide();
        this.FileMD5Sum = null;
        const Error = this.globalService.ProcessError(error);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalService.Logout('Yes');
          }
          this.globalService.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
    } else {
      this.keylock = 1;
      this.FlashError('Firstly choose your image & then click on result button');
    }
  }

  saveData() {
    $('#dots_spinner').show();
    if (this.showbutton === 0) {
      this.showbutton = 1;
      let data = {};
      if (this.UserRole === 'SuperAdmin') {
        data = {
          IdMerchant: this.SuperMerchantId,
          FileName: this.FileName
        };
      } else {
        if (this.IdMasterMerchant === 0) {
          data = {
            IdMerchant: this.IdMerchant,
            FileName: this.FileName

          };
        } else {
          data = {
            IdMerchant: this.IdMerchant,
            FileName: this.FileName
          };
        }
      }
      this.http.post<any>(this.apiList.UploadImageUrl, data, this.globalService.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (result.Response !== 1) throw result;
          return result;
        })
        .subscribe((response) => {
          this.image_upload = response;
          if (this.image_upload.Response === 1) {
            $('#EditPicture').modal('hide');
            this.ImagePath = this.image_upload.ImageURL;
            this.FileMD5Sum = this.image_upload.FileMD5Sum
            // this.admindetails();
          }
          sessionStorage.removeItem('profilepic');
        }, (err: any) => {
          const Error = this.globalService.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalService.Logout('Yes');
            }
            this.globalService.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
        });
    } else {
      this.keylock = 1
      this.FlashError('Firstly choose your image & then click on result button')
    }
  }

  Logo_click() {
    this.router.navigate([this.companyName + '/dashboard/machine']);
  }

  async GoToSuperAdmin(idSuperMerchant) {
    const result: any = await this.globalService.BackToSuperAdmin(idSuperMerchant);
    if (result.Response === 0) {
      this.FlashError(`Failed to transition back to Super Admin: ${result.ErrorMessage}`);
    }
    if (result.Response === 1) {
      setTimeout(() => {
        this.image_upload = result;
        this.globalService.SaveIdentity(result);
      }, 3000);
      this.router.navigate(['Superadmindashboard/Machineslist']);
      const sessionId = sessionStorage.getItem('Sessionid_Admin');
      this.globalService.Signout(sessionId);
    }
    return;
  }


  settings() {
    $(".sidenav_overlay").fadeIn("slow");
    document.getElementById("mySidenav").style.width = "250px";
  }

  closeNav() {
    $(".sidenav_overlay").fadeOut("slow");
    document.getElementById("mySidenav").style.width = "0";
  }

  admindetails() {
    console.log("inside admindetails");
    if (this.UserRole == "SuperAdmin") {
      var data = {
        SuperMerchantId: this.SuperMerchantId,
        IdMerchant: this.SuperMerchantId,
        IsFromSuperAdmin: true
      }
    } else {
      if (this.IdMasterMerchant == 0) {
        var data = {
          SuperMerchantId: this.IdMerchant,
          IdMerchant: this.IdMerchant,

          IsFromSuperAdmin: false
        }
      } else {
        var data = {
          SuperMerchantId: this.IdMasterMerchant,
          IdMerchant: this.IdMerchant,
          IsFromSuperAdmin: false
        }
      }
    }
    // console.log(data)
    this.http.post<any>(this.apiList.MerchantDetailUrl, data, this.globalService.Headers())
      .map(response => {
        if (!response) {
          throw { Response: 0, ErrorMessage: "Empty or null response" };
        }
        if (response.Response !== 1) {
          throw response;
        }
        return response;
      })
      .subscribe((response) => {

        this.posts = response;
        console.log("response admin details"+JSON.stringify(this.posts));

        this.Session_id = response.SessionId;
        this.ImagePath = response.obj_MerchantRecord.ImageURL;
        this.Name = response.obj_MerchantRecord.ContactPerson;
        this.UserRolee=response.obj_MerchantRecord.UserRole;
        this.CompanyName = response.obj_MerchantRecord.CompanyName;
        this.CompanyAddress = response.obj_MerchantRecord.CompanyAddress;
        this.CompanyPhoneNo = response.obj_MerchantRecord.CompanyPhoneNumber;
        this.Country = response.obj_MerchantRecord.Country;
        this.State = response.obj_MerchantRecord.State
        this.City = response.obj_MerchantRecord.City
        this.ContactPersonNumber = response.obj_MerchantRecord.ContactPersonNumber
        this.Zip = response.obj_MerchantRecord.Zip;
        this.FileMD5Sum = response.obj_MerchantRecord.FileMD5Sum;
        this.Permissiondetail();
        this.Featuredetail()

      }, (err: any) => {
        const Error = this.globalService.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalService.Logout('Yes');
          }
          this.globalService.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      });
  }

  Permissiondetail() {

    this.http.post<any>(this.apiList.AllPermissionsWithMerchant, { IdMerchant: this.IdMerchant }, this.globalService.Headers())
      .map(response => {
        if (!response) {
          throw { Response: 0, ErrorMessage: "Empty or null response" };
        }
        if (response.Response !== 1) {
          throw response;
        }
        return response;
      })
      .timeout(30000)
      .retry(2)
      .take(1)
      .subscribe(response => {

        this.details = response;

        // merge permissions into variables for display
        let perms = this.details.Permissions;

        for (let i = 0; i < perms.length; i++) {

          if (perms[i].name == "Machine Section") {
            this.MachineVisible = perms[i].State;
          }
          if (perms[i].name == "Product Category Section") {
            this.CategoryVisible = perms[i].State;
          }

          if (perms[i].name == "Product Section") {
            this.ProductVisible = perms[i].State;
          }

          if (perms[i].name == "Location Section") {
            this.LocationVisible = perms[i].State;
          }

          if (perms[i].name == "Place Machine Section") {
            this.PlaceMachineVisible = perms[i].State;
          }

          if (perms[i].name == "Users Section") {
            this.UsersVisible = perms[i].State;
          }

          if (perms[i].name == "Coupon Section") {
            this.CouponVisible = perms[i].State;
          }

          if (perms[i].name == "Special Pricing Section") {
            this.SpecialPricingVisible = perms[i].State;
          }

          if (perms[i].name == "Digital Signage Section") {
            this.DigitalSinageVisible = perms[i].State;
          }
          if (perms[i].name == "Report Section") {
            this.ReportVisible = perms[i].State;
          }

          if (perms[i].name == "UI Section") {
            this.UIVisible = perms[i].State;
          }

          if (perms[i].name == "Facial Recognition") {
            this.FacialVisible = perms[i].State;
          }

          if (perms[i].name == "Age Verification") {
            this.AgeverfiactionVisible = perms[i].State;
          }

          if (perms[i].name == "Background Theme") {
            this.ThemeVisible = perms[i].State;
          }

          if (perms[i].name == "Company Logo") {
            this.LogoVisible = perms[i].State;
          }
          if (perms[i].name == "Inventory Management") {
            this.InventoryManagementVisible = perms[i].State;
          }

          if (perms[i].name == "Dashboard") {
            this.dashboardVisible = perms[i].State;
          }
          if (perms[i].name == "Global Settings") {
            this.GlobalSettingVisible = perms[i].State;
          }

          if (perms[i].name == "Brand Management") {
            this.BrandUserVisible = perms[i].State;
          }

          if (perms[i].name == "Alerts") {
            this.Alerts = perms[i].State;
          }

          if (perms[i].name == "Sampling Report") {
            this.Samplingvisible = perms[i].State;
          }



          //  this[this.LookupPermissions[perms[i].IdPermission]] = perms[i].State;
        }
      }, (err: any) => {
        const Error = this.globalService.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalService.Logout('Yes');
          }
          this.globalService.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      });
  }


  Featuredetail() {

    this.http.post<any>(this.apiList.GetMerchantFeatures, { IdMerchant: this.IdMerchant }, this.globalService.Headers())
      .timeout(30000)
      .retry(2)
      .take(1)
      .map(response => {
        if (!response) {
          throw { Response: 0, ErrorMessage: "Empty or null response" };
        }
        if (response.Response !== 1) {
          throw response;
        }
        return response;
      })
      .subscribe(response => {

        // this creates a hash of FeatureTypes to true/false (feature enabled/disable)
        // not used?
        this.LookupFeature = response.Feature.reduce((acc, cv) => {
            acc[cv.FeatureType] = cv.FeatureValue;
            return acc;
          }, {});
          //console.log("this.lookupfeature"+JSON.stringify(this.LookupFeature));
//IsSamplingActive
          if(this.LookupFeature){
           // this.LookupFeature=JSON.stringify(this.LookupFeature);
          this.IsBrandVisible=this.LookupFeature.IsBrandActive;
          //console.log("this.IsBrandVisible"+this.IsBrandVisible);
          }

      }, (err: any) => {
        const Error = this.globalService.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalService.Logout('Yes');
          }
          this.globalService.Logout('No');
          return;
        }
        //  else {
        //   this.FlashError(Error.ErrorMessage);
        //   return;
        // }
      }).add(() => {
      });
  }

  changepsw() {
    let change_data: { IdMerchant: number, OldPassword: string, NewPassword: string };

    if (this.UserRole == "SuperAdmin") {
      change_data = {
        IdMerchant: this.SuperMerchantId,
        OldPassword: this.OldPassword,
        NewPassword: this.NewPassword,

      }
    } else {
      if (this.IdMasterMerchant == 0) {
        change_data = {
          IdMerchant: this.IdMerchant,
          OldPassword: this.OldPassword,
          NewPassword: this.NewPassword,

        }
      } else {
        change_data = {
          IdMerchant: this.IdMerchant,
          OldPassword: this.OldPassword,
          NewPassword: this.NewPassword,

        }
      }
    }

    if (this.NewPassword == this.NewPassword1) {
      this.http.post<any>(this.apiList.ChangePswUrl, change_data, this.globalService.Headers())
        .take(1)
        .map(response => {
          if (!response) throw { Response: 0, ErrorMessage: "Empty reply to change password" };
          if (response.Response != 1) throw response;
          return response;
        })
        .subscribe(response => {
          this.data = response;
          this.ChangePassword.reset()
          this.keylock = 1
          $('#ChangePassword').modal('hide');
          this.FlashError("Password Successfully changed")

        }, (err: any) => {
          const Error = this.globalService.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalService.Logout('Yes');
            }
            this.globalService.Logout('No');
            return;
          } else {
            this.keylock = 1
            this.ChangePassword.reset()
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
        });
    } else {
      this.keylock = 1
      this.FlashError("New password or Confirm password is not matched")
    }
  }

  logout() {
    this.globalService.Logout('No');
  }


  onKey(event: any) { // without type info
    if (this.NewPassword == this.NewPassword1) {
      this.compare = true
    } else {
      this.show_message = "Password and Confirm Password does not match"
      this.compare = false;
    }
  }
  onKey1() {
    if (this.NewPassword) {
      this.show_message = "";
      this.nextLibAvailable = true;
    } else {
      this.show_message = "";
      // this.show_message = " First fill User Password"
      this.nextLibAvailable = false;
      this.compare = false;
    }
  }


  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      this.keylock = 0
      $('#alert_div').hide();
    }, 3000);
  }

  onFileSelected(event) {
    const that = this;
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      that.showCropper = false;
      reader.onload = (eventCurr: ProgressEvent) => {
        that.base64 = (<FileReader>eventCurr.target).result;
        setTimeout(function () {
          that.refreshCrop(that.base64);
        }, 2000);
      };
      this.ProfileImg = event.target.files[0].name;
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  refreshCrop(img) {
    this.base64 = img;
    this.showCropper = true;
  }

  editpicture() {
    $("#EditPicture").modal("show");
    //this.showCropper = false;
  }

  cropendImage() {
    var that = this
    that.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    that.base64Profilepic = this.cropperRes.split(',')
    // this.base64Profilepic=this.cropperRes;
    $('#EditPicture').append('<div id="croppieModal" class="modal fade" role="dialog"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><h3 class="modal-title">Preview</h3><button type="button" class="close" id="imgResultClose">&times;</button></div><div class="modal-body"><div class="product-image-overlay"><img class="img-responsive" src="" /></div></div><div class="modal-footer" style="text-align:center"><button type="button" class="btn btn-success" id="imgResultSave">Done</button></div></div></div></div>');
    var productImage = $('img');
    var productOverlay = $('.product-image-overlay');
    var productOverlayImage = $('.product-image-overlay img');
    //var productImageSource = $(this).attr('src');

    productOverlayImage.attr('src', this.cropperRes);
    productOverlay.fadeIn(100);
    $('#EditPicture').css('overflow', 'hidden');

    $('.product-image-overlay-close').click(function () {
      productOverlay.fadeOut(100);
      $('#EditPicture').css('overflow', 'auto');
    });

    $("#imgResultClose").click(function () {
      $("#croppieModal").modal("hide")
    });
    $("#imgResultSave").click(function () {
      $("#croppieModal").modal("hide");
      that.showbutton = 0;
      // var showmessage = sessionStorage.getItem("profilepic");
      if (!that.ProfileImg) {
        that.keylock = 1
        that.FlashError("Firstly upload the picture")
      }
    });
  }

  // imgResultClose(){
  //   $("#croppieModal").modal("hide")
  // }

  readyImage(event) {
    if (this.angularCropper !== undefined) {
      this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      this.b64toBlob(this.cropperRes);
    }
  }

  rotate(turn) {
    if (this.angularCropper !== undefined) {
      turn = turn === 'left' ? -45 : 45;
      this.angularCropper.cropper.rotate(turn);
      this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      this.b64toBlob(this.cropperRes);
    }
  }

  destroy() {
    this.angularCropper.cropper.destroy();
  }

  zoomManual() {
    if (this.angularCropper !== undefined) {
      this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      this.b64toBlob(this.cropperRes);
    }
  }

  zoom(status) {
    if (this.angularCropper !== undefined) {
      status = status === 'positive' ? 0.1 : -0.1;
      this.angularCropper.cropper.zoom(status);
      this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      this.b64toBlob(this.cropperRes);
    }
  }
  serverPopUp() {
    $('#server').modal('show');
  }

  b64toBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    this.blobImageObject = new Blob([ab], { type: 'image/jpeg' });
  }

}
