import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { NgForm } from '@angular/forms';
import { Observable, Subject } from 'rxjs/Rx';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
import { ApiCacheStorageService } from '../../api-cache-storage.service';
//const FixedRecordCount = 100
declare const $;
@Component({
  selector: 'app-active-customers',
  templateUrl: './active-customers.component.html',
  styleUrls: ['./active-customers.component.css']
})
export class ActiveCustomersComponent implements OnInit {

  @ViewChild('permissions', { static: false }) public permissionsForm: NgForm;
  public mask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  Search;
  lists;
  posts;
  CompanyName;
  CompanyAddress;
  CompanyPhoneNumber;
  City;
  State;
  Zip;
  ContactPerson;
  ContactPersonNumber;
  ContactPersonEmailId;
  RequestedUserName;
  IdMerchant;
  IdCity;
  IdCountry;
  IdState;
  Name;
  LastName;
  countrylists;
  selectedState;
  citys;
  States;
  result;
  Country;
  selectedcountry;
  selectedcity;
  StateName;
  CountryName;
  CityName;
  mlists;
  lst;
  details;
  selectedpermission = [];
  selected_value = [];
  taskTypeAreas: {
    PermissionName: string;
    IdPermission: number;
    State: boolean;
  }[];
  merhantpermissions;
  Permissions: {
    name: string;
    id: number;
    State: boolean;
  }[];
  isAdding = false;
  post_info;
  before: number;
  screenHeight: number;
  // pageno: any;
  ll: any;
  userrole: any;
  search_role: true;
  myyy = true;
  myy = true;
  my;
  viewmerchant = true;
  viewDistributor = true;
  Session_id: any;
  AlertText: string;
  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate
  feature: any;
  selectedFeature: any;
  // public Records = [];
  //new inclusion for color-theme

  FQDN;
  skinStyle = null;
  skinStylesArray: any[] = [];

  responseUpdate: string = "";
  responseZip: string = "";
  Latitude = '';
  Longitude = '';

  geometry_address: any;
  timezone_address: any;
  timezoneid: string;
  dstOffset: any;
  rawOffset: any;
  zipValidationAddress: string;

  constructor(public apiList: ApilistService,
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    private apiCacheStorage: ApiCacheStorageService
  ) {
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
    this.refreshActiveCustomrelist();
  }

  ngOnInit() {
    this.hidepops();
    this.getAllSkins();
    this.globalservice.getCountry().then((res: any) => {
      this.countrylists = res;
    });

  }

  //Drop down Select Skin Type in merchant details

  getAllSkins() {
    $('#dots_spinner').show();
    this.http.get<any>(this.apiList.GetAllSkin, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Null response' }; }
        if (resp.Response !== 1) { throw resp; }
        return resp;
      })
      .subscribe(response => {
        this.skinStylesArray = response.Skins;
        // console.log("this.skinStylesArray"+JSON.stringify(this.skinStylesArray));
      }, err => {
        // console.log("error");
      }).add(() => {
        $('#dots_spinner').hide();

      });
  }


  hidepops() {
    $('#uploadSuccess').modal('hide');
    $('#colorTheme').modal('hide');
    $('#errorZip').modal('hide');
    $('#dots_spinner').hide();
    $('#alert_div').hide();
    $('#permission_detail').modal('hide');
    $('#LocationDetails').modal('hide');
  }

  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      //console.log('Can\'t find');
      //console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    // console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    //console.log(`RowHeight: ${this.RowHeight}`);
  }

  ModifyPermissions(id) {
    this.isAdding = true;
    this.merhantpermissions.Permissions = this.taskTypeAreas;

    this.http.post<any>(this.apiList.SetPermissionUrl,
      this.merhantpermissions, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Null response' }; }
        if (resp.Response !== 1) { throw resp; }
        return resp;
      })
      .subscribe(response => {
        this.lst = response;
        this.permissionsForm.reset();
      }, err => {
        switch (this.lst.Response) {
          case 3:
            if (err.ErrorMessage) {
              this.FlashError(err.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            break;
          default:
            this.FlashError(err.ErrorMessage);
            break;
        }
      }).add(() => {
        $('#dots_spinner').hide();
        $('#permission_detail').modal('hide');
      });
  }



  getUpdatePermission(id) {
    $('#dots_spinner').show();

    this.IdMerchant = id;
    // this.taskTypeAreas=[];
    this.http.post<any>(this.apiList.GetPermissionByMerchantId, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .retry(2)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        this.merhantpermissions = response.merchantPermissions;
        this.taskTypeAreas = this.merhantpermissions.Permissions;
        $('#permission_detail').modal('show');
      }, err => {
        switch (+err.Response) {
          case 3:
            if (err.ErrorMessage) {
              this.FlashError(err.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            break;
          default:
            this.FlashError(err.ErrorMessage);
            break;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }


  refreshActiveCustomrelist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ActiveCustomerListUrl,
      { ActiveDeactive: 1 }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      })
      .subscribe(response => {
        this.ll = response;
        this.posts = this.ll.lst_Customer;
        // console.log(this.posts)
      }, err => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
        if (this.Resizing) {
          // need to give time to render behind the scenes before size/resize rows
          this.SettleAndAutosize();
        }
      });
  }

  FlashError(message: string) {
    //console.log("inside alert div");
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  async GoToAdmin(id, userrole, Sid) {
    sessionStorage.setItem('Login', id);
    const result: any = await this.globalservice.ImpersonateApi(id, true);
    if (result.Response === 0) {
      this.FlashError(result.ErrorMessage);
      return;
    }
    if (userrole === 'Distributor') {
      this.router.navigate([this.CompanyName + '/dashboard/InventoryManagement']);
    } else {
      this.router.navigate([this.CompanyName + '/dashboard/machine']);
    }
  }

  DeActivate(id) {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ActiveDeactiveListUrl,
      { IdMerchant: id, ActivateDeactivateAction: 0 }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      })
      .subscribe((response) => {
        if (response.Response === 1) {
          this.router.navigate(['/Superadmindashboard/DeActiveCustomers']);
          this.refreshActiveCustomrelist();
        }
      }, (err) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  MerchantDetails(id) {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MerchantDetailUrl,
      { IdMerchant: id, IsFromSuperAdmin: true, }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        //console.log("merchant details response"+JSON.stringify(response));
        this.mlists = response;
        if (this.mlists.Response === 1) {
          this.Session_id = this.mlists.SessionId,
            this.Name = this.mlists.obj_MerchantRecord.Name;
          this.skinStyle = this.mlists.obj_MerchantRecord.V4USkinId;
          this.FQDN = this.mlists.obj_MerchantRecord.DomainName;
          this.CompanyName = this.mlists.obj_MerchantRecord.CompanyName.replace(/\s/g, '');
          this.CompanyAddress = this.mlists.obj_MerchantRecord.CompanyAddress;
          this.CompanyPhoneNumber = this.mlists.obj_MerchantRecord.CompanyPhoneNumber;
          this.selectedcountry = this.mlists.obj_MerchantRecord.IdCountry;
          this.IdCountry = this.selectedcountry;
          this.selectedState = this.mlists.obj_MerchantRecord.IdState;
          this.IdState = this.selectedState;
          this.selectedcity = this.mlists.obj_MerchantRecord.IdCity;
          this.Zip = this.mlists.obj_MerchantRecord.Zip;
          this.StateName = this.mlists.obj_MerchantRecord.State;
          this.CountryName = this.mlists.obj_MerchantRecord.Country;
          this.CityName = this.mlists.obj_MerchantRecord.City;
          this.ContactPerson = this.mlists.obj_MerchantRecord.ContactPerson;
          this.ContactPersonNumber = this.mlists.obj_MerchantRecord.ContactPersonNumber;
          this.ContactPersonEmailId = this.mlists.obj_MerchantRecord.ContactPersonEmailId;
          this.RequestedUserName = this.mlists.obj_MerchantRecord.RequestedUserName;
          this.IdMerchant = this.mlists.obj_MerchantRecord.IdMerchant;
          this.userrole = this.mlists.obj_MerchantRecord.UserRole;
          this.timezoneid = this.mlists.obj_MerchantRecord.TimeZoneId;
          // console.log("this.timezoneid"+this.timezoneid);
          // console.log("idstate is--->"+this.IdState);
          if (typeof this.IdCountry === 'undefined') {
            alert('value undefined');
          } else {
            this.getStates();
            this.selectedcity = this.mlists.obj_MerchantRecord.IdCity;
            this.IdCity = this.selectedcity;
          }
          if (typeof this.IdState === 'undefined') {
            alert('value undefined');
          } else {
            this.getcity();
          }
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.isAdding && this.permissionsForm.dirty) {
      return confirm('Are you sure you want to discard your changes?');
    } else if (this.permissionsForm.dirty) {
      return confirm('Are you sure you want to discard your changes?');
    }
    return true;
  }

  update() {
    this.responseUpdate = "";
    var statusFQDN: boolean;

    if (this.FQDN && this.FQDN.trim() !== '') {
      statusFQDN = this.checkPattern(this.FQDN);
      //console.log("statusFQDN"+statusFQDN);
      if (statusFQDN) {
        this.updateDetails();
      }
      else {

        //console.log("inside not match");
        this.responseUpdate = "Please Enter valid Tenant Skin FQDN";
        $('#colorTheme').modal('show');
        return;
      }
    }

    else {
      this.updateDetails();
    }
  }

  updateDetails() {
    const a = this.ContactPerson.split(' ');
    this.Name = a[0];
    this.LastName = a[1];
    this.responseUpdate = "";

    if (this.FQDN && (this.skinStyle == null || this.skinStyle == undefined)) {
      this.responseUpdate = "Please Select Skin Style as well";

      $('#colorTheme').modal('show');

    }
    else {
      this.http.post<any>(this.apiList.UpdateMerchantUrl,
        {
          Latitude: this.Latitude,
          Longitude: this.Longitude,
          IdMerchant: this.IdMerchant,
          LastName: this.LastName,
          CompanyName: this.CompanyName,
          EmailId: this.ContactPersonEmailId,
          CompanyAddress: this.CompanyAddress,
          CompanyPhoneNumber: this.CompanyPhoneNumber,
          City: this.CityName,
          State: this.StateName,
          Zip: this.Zip,
          Name: this.Name,
          V4USkinId: this.skinStyle,
          DomainName: this.FQDN,
          ContactPerson: this.ContactPerson,
          ContactPersonNumber: this.ContactPersonNumber,
          ContactPersonEmailId: this.ContactPersonEmailId,
          RequestedUserName: this.RequestedUserName,
          IdCity: this.selectedcity,
          IdCountry: this.IdCountry,
          IdState: this.IdState,
          UserRole: this.userrole,
          TimeZoneId: this.timezoneid
        },
        this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(response => {
          if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
          if (+response.Response !== 1) { throw response; }
          return response;
        }).subscribe((response) => {
          if (response.Response === 1) {
            this.apiCacheStorage.InvalidateById('GetMerchantDetailsById', this.IdMerchant);
            this.result = response;
            this.refreshActiveCustomrelist();
            $('#uploadSuccess').modal('show');

          }
        }, (err) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.responseUpdate = Error.ErrorMessage;
            $('#colorTheme').modal('show');
            this.globalservice.Logout('No');
            return;
          } else {
            this.responseUpdate = Error.ErrorMessage;
            $('#colorTheme').modal('show');
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#dots_spinner').hide(); });
    }

  }


  checkPattern(FQDNval) {
    var regexPatternVVal = /^(?!.* .*)(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
    return regexPatternVVal.test(FQDNval);
  }

  onChangeSkin(skinSelected) {
    this.skinStyle = skinSelected;
  }

  onChangeCountry(a) {
    this.IdCountry = a;
    if (typeof this.IdCountry === 'undefined') {
      alert('value undefined');
    } else {
      this.getStates();
    }
  }

  getStates() {
    this.http.post<any>(this.apiList.StateUrl,
      { IdCountry: this.IdCountry }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response.Response === 1) {
          this.States = response.lst_State;
        }
      }, (err) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  onChangeState(b) {
    this.IdState = b;
    if (typeof this.IdState === 'undefined') {
      alert('value undefined');
    } else {
      this.getcity();
    }
  }

  getcity() {
    //  console.log("inside city call");
    this.http.post<any>(this.apiList.CityUrl,
      { IdState: this.IdState }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response.Response === 1) {
          this.citys = response.lst_City;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  sortData(sort: Sort) {
    const data = this.posts.slice();
    if (!sort.active || sort.direction === '') {
      this.posts = data;
      return;
    }
    this.posts = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Company_name': return this.compare(a.CompanyName.toLowerCase(), b.CompanyName.toLowerCase(), isAsc);
        case 'date': return this.compare(a.ActivationDate, b.ActivationDate, isAsc);
        case 'user_role': return this.compare(a.UserRole.toLowerCase(), b.UserRole.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  location_info(data) {
    $('#LocationDetails').modal('show');
    this.post_info = data;
  }

  clearSearch() {
    this.Search = '';
    this.refreshActiveCustomrelist();
  }

  UserRole(id, name) {
    this.router.navigate(['/Superadmindashboard/Distributor', {
      id: id,
      name: name
    }]);
  }

  radio_selection1(value) {
    this.viewmerchant = value;
    if (this.viewmerchant === true && this.viewDistributor === false) {
      this.http.post<any>(this.apiList.SearchRadioURL,
        { ActiveDeactive: 1, Searchtext: 'Merchant' }, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(response => {
          if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
          if (+response.Response !== 1) { throw response; }
          return response;
        })
        .subscribe((response) => {
          if (response.Response === 1) {
            this.ll = response;
            this.posts = this.ll.lst_Customer;
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#dots_spinner').hide(); });
    } else if (this.viewmerchant === false && this.viewDistributor === true) {
      this.http.post<any>(this.apiList.SearchRadioURL,
        { ActiveDeactive: 1, Searchtext: 'Distributor' }, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(response => {
          if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
          if (+response.Response !== 1) { throw response; }
          return response;
        })
        .subscribe((response) => {
          if (response.Response === 1) {
            this.ll = response;
            this.posts = this.ll.lst_Customer;
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#dots_spinner').hide(); });
    } else {
      this.refreshActiveCustomrelist();
    }
  }

  radio_selection2(value) {
    this.viewDistributor = value;
    if (this.viewmerchant === true && this.viewDistributor === false) {
      this.http.post<any>(this.apiList.SearchRadioURL,
        { ActiveDeactive: 1, Searchtext: 'Merchant' }, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(response => {
          if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
          if (+response.Response !== 1) { throw response; }
          return response;
        })
        .subscribe((response) => {
          if (response.Response === 1) {
            this.ll = response;
            this.posts = this.ll.lst_Customer;
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#dots_spinner').hide(); });
    } else if (this.viewmerchant === false && this.viewDistributor === true) {
      this.http.post<any>(this.apiList.SearchRadioURL,
        { ActiveDeactive: 1, Searchtext: 'Distributor' }, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(response => {
          if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
          if (+response.Response !== 1) { throw response; }
          return response;
        })
        .subscribe((response) => {
          if (response.Response === 1) {
            this.ll = response;
            this.posts = this.ll.lst_Customer;
          } else if (response.Response === 35) {
            if (response.ErrorMessage) {
              this.FlashError(response.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
          } else {
            this.FlashError(response.ErrorMessage);
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#dots_spinner').hide(); });
    } else {
      this.refreshActiveCustomrelist();
    }


  }
  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }


  FeaturesList(id) {
    this.IdMerchant = id;
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.GetMerchantFeatures,
      { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response.Response === 1) {
          this.feature = response.Feature;
          console.log(this.feature)
          $('#feature_detail').modal('show');
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  Modifyfeature(id) {
    this.IdMerchant = id; // why pass this or do this? This variable is already set when pop-up opens.

    var selected = this.selectedFeature.reduce((acc, cv) => { acc[cv] = true; return acc; }, {});
    for (var i = 0; i < this.feature.length; i++) {
      this.feature[i].FeatureValue = selected[this.feature[i].FeatureType] === true;
    }


    //console.log(this.IdMerchant);
    this.http.post<any>(this.apiList.UpdateMerchantFeatures,

      { IdMerchant: this.IdMerchant, Features: this.feature }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Null response' }; }
        if (resp.Response !== 1) { throw resp; }
        return resp;
      })
      .subscribe(response => {
        this.lst = response;
        this.permissionsForm.reset(); //$$ BUG? What does permissions have to do with features?
      }, err => {
        switch (this.lst.Response) {
          case 3:
            if (err.ErrorMessage) {
              this.FlashError(err.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            break;
          default:
            this.FlashError(err.ErrorMessage);
            break;
        }
      }).add(() => {
        $('#dots_spinner').hide();
        $('#feature_detail').modal('hide');
      })
  }

  ZipcodeExist() {
    this.responseZip = "";
    this.responseUpdate = "";
    if (this.selectedcity) {
      this.http.post<any>(this.apiList.CheckZipCodeExist,
        { ZipCode: this.Zip, IdCity: this.selectedcity }, this.globalservice.headerWithoutauth())
        .subscribe((response) => {
          if (response) {
            if (response.Response === 1) {
              this.Latitude = response.Latitude;
              this.Longitude = response.Longitude;
            } else if (response.Response === 4) {
              // this.FlashError("We don\'t recognize this zip code");
              this.responseZip = "The zip code " + this.Zip + " is not recognized, are you sure?";
              $('#errorZip').modal('show');

              // this.AlertText = 'We don\'t recognize this zip code, are you sure?';
              // $('#myModal').modal('show');
            } else {
              $('#errorZip').modal('hide');
              this.responseUpdate = "There was an internal error processing this zip code";
              $('#colorTheme').modal('show');
              //this.FlashError("There was an internal error processing this zip code");
            }
          }
        }, (err) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        });
    } else {
      this.Latitude = '';
      this.Longitude = '';
      this.Zip = '';
      this.timezoneid = '';
      this.dstOffset = '';
      this.rawOffset = '';
      $('#errorZip').modal('hide');
      this.responseUpdate = "Please select city first";
      $('#colorTheme').modal('show');
      //this.FlashError('Please select city first');
    }
  }

  checkZipGoogleApi() {
    this.responseUpdate = '';
    this.zipValidationAddress = this.selectedcity + " " + this.Zip;
    // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
    // //console.log(this.completeaddress);
    this.zipValidationAddress = this.zipValidationAddress.replace(/  +/g, ' ');
    this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.zipValidationAddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.geometry_address = response;
      if (this.geometry_address.status == "OK") {
        var lat = this.geometry_address.results[0].geometry.location.lat;
        var long = this.geometry_address.results[0].geometry.location.lng;
        this.GetTimezoneID(lat, long);
      } else {
        this.Latitude = '';
        this.Longitude = '';
        this.Zip = '';
        this.timezoneid = '';
        this.dstOffset = '';
        this.rawOffset = '';
        $('#errorZip').modal('hide');
        this.responseUpdate = "Invalid Zip Code";
        $('#colorTheme').modal('show');
      }
    })
  }


  GetTimezoneID(lat, long) {
    var time = new Date().getTime();
    var date_timestamp = Math.round(time / 1000);
    this.http.get<any>("https://maps.googleapis.com/maps/api/timezone/json?location=" + lat + "," + long + "&timestamp=" + date_timestamp + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.timezone_address = response;
      // console.log(this.timezone_address)
      if (this.timezone_address.status == "OK") {
        this.timezoneid = this.timezone_address.timeZoneId;
        this.dstOffset = this.timezone_address.dstOffset;
        this.rawOffset = this.timezone_address.rawOffset;
        this.Latitude = lat;
        this.Longitude = long;
        // console.log("this.Latitude"+this.Latitude);
        // console.log("this.Longitude"+this.Longitude);
      } else {
        this.Latitude = '';
        this.Longitude = '';
        this.Zip = '';
        this.timezoneid = '';
        this.dstOffset = '';
        this.rawOffset = '';
        $('#errorZip').modal('hide');
        this.responseUpdate = "Please Fill a valid Zip Code";
        $('#colorTheme').modal('show');
        // this.FlashError("Please fill valid Zip Code");
      }
    })
  }

  No() {
    this.Zip = '';
  }


  onPermissionCheckChanged(event, eventid) {
    var indexEvent = this.taskTypeAreas.filter(ij => ij.IdPermission == eventid)[0];
    if (indexEvent !== undefined) {
      var index22 = this.taskTypeAreas.indexOf(indexEvent);
      this.taskTypeAreas[index22].State = event.checked;
    }

  }

}

