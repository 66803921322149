import { Component, OnInit, HostListener } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormsModule, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material/sort';
import { GlobalService } from '../../global.service';
import 'rxjs/Rx';
import { ApilistService } from '../../../service/Api/apilist.service';
import { Subject } from 'rxjs/Rx';
import { ApiCacheStorageService } from '../../api-cache-storage.service';
declare const $;
@Component({
  selector: 'app-signups',
  templateUrl: './signups.component.html',
  styleUrls: ['./signups.component.css']
})
export class SignupsComponent implements OnInit {
  public mask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  Search;
  posts;
  lists;
  Cdetails;
  CompanyName;
  CompanyAddress;
  CompanyPhoneNumber;
  City;
  State;
  Zip;
  ContactPerson;
  ContactPersonNumber;
  ContactPersonEmailId;
  RequestedUserName;
  AlertText;
  Name;
  LastName;
  IdCity;
  IdCountry;
  IdState;
  countrylists;
  selectedState;
  citys;
  states;
  Country;
  selectedcountry;
  selectedcity;
  StateName;
  CountryName;
  CityName;
  mlists;
  nameshort;
  screenHeight: number;
  before: number;
  pageno: any;
  ll: any;
  userrole: any;
  UserRole;
  IdMerchant;
  zipValidationAddress:string;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate
  responseUpdate:string ="";
  responseZip:string="";
  Latitude = '';
  Longitude = '';

  geometry_address: any;
  timezone_address: any;
  timezoneid: string;
  dstOffset: any;
  rawOffset: any;
  //

  FQDN;
  skinStyle=null;
  skinStylesArray:any[]=[];
  
  constructor(public apiList: ApilistService, private router: Router, private http: HttpClient, private globalservice: GlobalService,private apiCacheStorage: ApiCacheStorageService) {
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
    this.refreshNewCustomrelist();
  }

  ngOnInit() {
    this.hidepops();
    this.getAllSkins();
    this.globalservice.getCountry().then((res: any) => {
      this.countrylists = res;
    });
  }


  //Drop down Select Skin Type in merchant details

  getAllSkins(){
    $('#dots_spinner').show();
    this.http.get<any>(this.apiList.GetAllSkin, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Null response' }; }
        if (resp.Response !== 1) { throw resp; }
        return resp;
      })
      .subscribe(response => {
        this.skinStylesArray = response.Skins;
       // console.log("this.skinStylesArray"+JSON.stringify(this.skinStylesArray));
      }, err => {
       // console.log("error");
      }).add(() => {
        $('#dots_spinner').hide();
        
      });
  }

  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
//console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  hidepops() {
    $('#dots_spinner').hide();
    $('#alert_div').hide();
    $('#user_detail').modal('hide');
    $('#colorTheme').modal('hide');
    $('#errorZip').modal('hide');
  }

  refreshNewCustomrelist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.NewCustomerUrl, {}, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (+result.Response !== 1) { throw result; }
        return result;
      })
      .subscribe((response) => {
        this.ll = response;
        this.posts = this.ll.lst_Customer;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide();
      if (this.Resizing) {
        // need to give time to render behind the scenes before size/resize rows
        this.SettleAndAutosize();
      } });
  }

  Accept() {
    const data = {
      IdMerchant: this.IdMerchant,
      ActivateDeactivateAction: 1
    };
    this.http.post<any>(this.apiList.ActiveDeactiveListUrl, data, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (+result.Response !== 1) { throw result; }
        return result;
      })
      .subscribe((response) => {
        if (response) {
          this.router.navigate(['/Superadmindashboard/ActiveCustomers']);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#user_detail').hide(); });
  }

  Reject() {
    const data = {
      IdMerchant: this.IdMerchant,
      ActivateDeactivateAction: 0
    };
    this.http.post<any>(this.apiList.ActiveDeactiveListUrl, data, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (+result.Response !== 1) { throw result; }
        return result;
      })
      .subscribe((response) => {
        if (response) {
          this.refreshNewCustomrelist();
          this.FlashError('Successfully Rejected Customer');
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#user_detail').hide(); });
  }

  MerchantDetails(id) {
    this.http.post<any>(this.apiList.MerchantDetailUrl, { IdMerchant: id }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (+result.Response !== 1) { throw result; }
        return result;
      }).subscribe((response) => {
        $('#user_detail').modal('show');
        this.mlists = response;
        this.Name = this.mlists.obj_MerchantRecord.Name;
        this.skinStyle=this.mlists.obj_MerchantRecord.V4USkinId;
        this.FQDN=this.mlists.obj_MerchantRecord.DomainName;
        this.CompanyName = this.mlists.obj_MerchantRecord.CompanyName;
        this.CompanyAddress = this.mlists.obj_MerchantRecord.CompanyAddress;
        this.CompanyPhoneNumber = this.mlists.obj_MerchantRecord.CompanyPhoneNumber;
        this.selectedcountry = this.mlists.obj_MerchantRecord.IdCountry;
        this.IdCountry = this.selectedcountry;
        console.log("country id"+this.IdCountry);
        this.selectedState = this.mlists.obj_MerchantRecord.IdState;
        this.IdState = this.selectedState;
        this.selectedcity = this.mlists.obj_MerchantRecord.IdCity;
        this.Zip = this.mlists.obj_MerchantRecord.Zip;
        this.StateName = this.mlists.obj_MerchantRecord.State;
        this.CountryName = this.mlists.obj_MerchantRecord.Country;
        this.CityName = this.mlists.obj_MerchantRecord.City;
        this.ContactPerson = this.mlists.obj_MerchantRecord.ContactPerson;
        this.ContactPersonNumber = this.mlists.obj_MerchantRecord.ContactPersonNumber;
        this.ContactPersonEmailId = this.mlists.obj_MerchantRecord.ContactPersonEmailId;
        this.RequestedUserName = this.mlists.obj_MerchantRecord.RequestedUserName;
        this.IdMerchant = this.mlists.obj_MerchantRecord.IdMerchant;
        this.userrole = this.mlists.obj_MerchantRecord.UserRole;
        this.timezoneid=this.mlists.obj_MerchantRecord.TimeZoneId;
        console.log(this.userrole)
        if (typeof this.IdCountry === 'undefined') {
          alert('value undefined');
        } else {
          this.getStates();
          this.selectedcity = this.mlists.obj_MerchantRecord.IdCity;
          this.IdCity = this.selectedcity;
        }
        if (typeof this.IdState === 'undefined') {
          alert('value undefined');
        } else {
          this.getcity();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }


  update() {
    this.responseUpdate="";
    var statusFQDN:boolean; 

    if(this.FQDN && this.FQDN.trim()!==''){
      statusFQDN=this.checkPattern(this.FQDN);
      //console.log("statusFQDN"+statusFQDN);
      if(statusFQDN){
        this.updateDetails();
      }
      else{
         
         //console.log("inside not match");
         this.responseUpdate="Please Enter valid Tenant Skin FQDN";
         $('#colorTheme').modal('show');
         return;
      }
    }

    else{
     this.updateDetails();
    }  
  }


  updateDetails(){
    const a = this.ContactPerson.split(' ');
    this.Name = a[0];
    this.LastName = a[1];
    this.responseUpdate="";
    
    if(this.FQDN && (this.skinStyle==null || this.skinStyle==undefined)){
      this.responseUpdate="Please Select Skin Style as well";

      $('#colorTheme').modal('show');
     
    }
    else{
      const data = {
        Latitude: this.Latitude,
            Longitude: this.Longitude,
        IdMerchant: this.IdMerchant,
        LastName: this.LastName,
        CompanyName: this.CompanyName,
        EmailId: this.ContactPersonEmailId,
        CompanyAddress: this.CompanyAddress,
        CompanyPhoneNumber: this.CompanyPhoneNumber,
        City: this.CityName,
        State: this.StateName,
        Zip: this.Zip,
        Name: this.Name,
        ContactPerson: this.ContactPerson,
        ContactPersonNumber: this.ContactPersonNumber,
        ContactPersonEmailId: this.ContactPersonEmailId,
        RequestedUserName: this.RequestedUserName,
        IdCity: this.selectedcity,
        IdCountry: this.IdCountry,
        IdState: this.IdState,
        UserRole: this.userrole,
        TimeZoneId:this.timezoneid,
        V4USkinId :this.skinStyle,
        DomainName:this.FQDN, 
      };
      this.http.post<any>(this.apiList.UpdateMerchantUrl, data, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (+result.Response !== 1) { throw result; }
          return result;
        })
        .subscribe((response) => {
          this.apiCacheStorage.InvalidateById('GetMerchantDetailsById', this.IdMerchant);
          this.refreshNewCustomrelist();
          this.FlashError('Updated Succesfully');
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#dots_spinner').hide(); });
    //   this.http.post<any>(this.apiList.UpdateMerchantUrl,
    //   {
    //     Latitude: this.Latitude,
    //     Longitude: this.Longitude,
    //   IdMerchant: this.IdMerchant,
    //   LastName: this.LastName,
    //   CompanyName: this.CompanyName,
    //   EmailId: this.ContactPersonEmailId,
    //   CompanyAddress: this.CompanyAddress,
    //   CompanyPhoneNumber: this.CompanyPhoneNumber,
    //   City: this.CityName,
    //   State: this.StateName,
    //   Zip: this.Zip,
    //   Name: this.Name,
    //   V4USkinId :this.skinStyle,
    //   DomainName:this.FQDN, 
    //   ContactPerson: this.ContactPerson,
    //   ContactPersonNumber: this.ContactPersonNumber,
    //   ContactPersonEmailId: this.ContactPersonEmailId,
    //   RequestedUserName: this.RequestedUserName,
    //   IdCity: this.selectedcity,
    //   IdCountry: this.IdCountry,
    //   IdState: this.IdState,
    //   UserRole: this.userrole,
    //   TimeZoneId:this.timezoneid
    // },
    // this.globalservice.Headers())
    // .timeout(25000)
    // .take(1)
    // .map(response => {
    //   if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
    //   if (+response.Response !== 1) { throw response; }
    //   return response;
    // }).subscribe((response) => {
    //   if (response.Response === 1) {
    //     this.apiCacheStorage.InvalidateById('GetMerchantDetailsById', this.IdMerchant);
    //     this.result = response;
    //     this.refreshActiveCustomrelist();
    //     $('#uploadSuccess').modal('show');
        
    //   }
    // }, (err) => {
    //   const Error = this.globalservice.ProcessError(err);
    //   if (Error.Response === 35) {
    //     if (Error.ErrorMessage) {
    //       this.FlashError(Error.ErrorMessage);
    //       this.globalservice.Logout('Yes');
    //     }
    //     this.responseUpdate=Error.ErrorMessage;
    //     $('#colorTheme').modal('show');
    //     this.globalservice.Logout('No');
    //     return;
    //   } else {
    //     this.responseUpdate=Error.ErrorMessage;
    //     $('#colorTheme').modal('show');
    //     this.FlashError(Error.ErrorMessage);
    //     return;
    //   }
    // }).add(() => { $('#dots_spinner').hide(); });
  }
    
  }


  checkPattern(FQDNval) {
    var regexPatternVVal =/^(?!.* .*)(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/ ;
    return regexPatternVVal.test(FQDNval);
  }

  onChangeSkin(skinSelected) {
    this.skinStyle=skinSelected;
  }

  // update() {
  //   const a = this.ContactPerson.split(' ');
  //   this.Name = a[0];
  //   this.LastName = a[1];
  //   const data = {
  //     IdMerchant: this.IdMerchant,
  //     LastName: this.LastName,
  //     CompanyName: this.CompanyName,
  //     EmailId: this.ContactPersonEmailId,
  //     CompanyAddress: this.CompanyAddress,
  //     CompanyPhoneNumber: this.CompanyPhoneNumber,
  //     City: this.CityName,
  //     State: this.StateName,
  //     Zip: this.Zip,
  //     Name: this.Name,
  //     ContactPerson: this.ContactPerson,
  //     ContactPersonNumber: this.ContactPersonNumber,
  //     ContactPersonEmailId: this.ContactPersonEmailId,
  //     RequestedUserName: this.RequestedUserName,
  //     IdCity: this.selectedcity,
  //     IdCountry: this.IdCountry,
  //     IdState: this.IdState,
  //     UserRole: this.userrole
  //   };
  //   this.http.post<any>(this.apiList.UpdateMerchantUrl, data, this.globalservice.Headers())
  //     .take(1)
  //     .timeout(25000)
  //     .map(result => {
  //       if (+result.Response !== 1) { throw result; }
  //       return result;
  //     })
  //     .subscribe((response) => {
  //       this.apiCacheStorage.InvalidateById('GetMerchantDetailsById', this.IdMerchant);
  //       this.refreshNewCustomrelist();
  //       this.FlashError('Updated Succesfully');
  //     }, (err: any) => {
  //       const Error = this.globalservice.ProcessError(err);
  //       if (Error.Response === 35) {
  //         if (Error.ErrorMessage) {
  //           this.FlashError(Error.ErrorMessage);
  //           this.globalservice.Logout('Yes');
  //         }
  //         this.globalservice.Logout('No');
  //         return;
  //       } else {
  //         this.FlashError(Error.ErrorMessage);
  //         return;
  //       }
  //     });
  // }

  onChangeCountry(a) {
    this.IdCountry = a;
    if (typeof this.IdCountry === 'undefined') {
      alert('value undefined');
    } else {
      this.getStates();
    }
  }

  getStates() {
    const url = `${this.apiList.StateUrl}`;
    this.http.post<any>(url, { IdCountry: this.IdCountry }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.states = response.lst_State;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
  }

  onChangeState(b) {
    this.IdState = b;
    if (typeof this.IdState === 'undefined') {
      alert('value undefined');
    } else {
      this.getcity();
    }

  }
  getcity() {
    const url = `${this.apiList.CityUrl}`;
    this.http.post<any>(url, { IdState: this.IdState }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.citys = response.lst_City;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
  }

  //////// sorting////////////////
  sortData(sort: Sort) {
    const data = this.posts.slice();
    if (!sort.active || sort.direction === '') {
      this.posts = data;
      return;
    }
    this.posts = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Company_name': return this.compare(a.CompanyName.toLowerCase(), b.CompanyName.toLowerCase(), isAsc);
        case 'City': return this.compare(a.City.toLowerCase(), b.City.toLowerCase(), isAsc);
        case 'State': return this.compare(a.State.toLowerCase(), b.State.toLowerCase(), isAsc);
        case 'user_role': return this.compare(a.UserRole.toLowerCase(), b.UserRole.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  /////////////////////////////

  clearSearch() {
    this.Search = '';
    this.refreshNewCustomrelist();
  }

  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }

  ZipcodeExist() {
    this.responseZip="";
    this.responseUpdate="";
    if (this.selectedcity) {
      this.http.post<any>(this.apiList.CheckZipCodeExist,
        { ZipCode: this.Zip, IdCity: this.selectedcity }, this.globalservice.headerWithoutauth())
        .subscribe((response) => {
          if (response) {
            if (response.Response === 1) {
              this.Latitude = response.Latitude;
              this.Longitude = response.Longitude;
            } else if (response.Response === 4) {
              // this.FlashError("We don\'t recognize this zip code");
              this.responseZip="The zip code "+this.Zip+" is not recognized, are you sure?";
              $('#errorZip').modal('show');
             
              // this.AlertText = 'We don\'t recognize this zip code, are you sure?';
              // $('#myModal').modal('show');
            } else {
              $('#errorZip').modal('hide');
              this.responseUpdate="There was an internal error processing this zip code";
              $('#colorTheme').modal('show');
              //this.FlashError("There was an internal error processing this zip code");
            }
          }
        }, (err) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        });
    } else {
      this.Latitude = '';
        this.Longitude = '';
      this.Zip = '';
      this.timezoneid='';
      this.dstOffset='';
      this.rawOffset='';
      $('#errorZip').modal('hide');
      this.responseUpdate="Please select city first";
      $('#colorTheme').modal('show');
      //this.FlashError('Please select city first');
    }
  }

  checkZipGoogleApi(){
    this.responseUpdate='';
    this.zipValidationAddress = this.selectedcity + " " + this.Zip ;
    // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
    // //console.log(this.completeaddress);
     this.zipValidationAddress = this.zipValidationAddress.replace(/  +/g, ' ');
    // this.completeaddress = this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selectedcountry;
    // // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
    // //console.log(this.completeaddress);
    // this.completeaddress = this.completeaddress.replace(/  +/g, ' ');
    this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.zipValidationAddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.geometry_address = response;
      if (this.geometry_address.status == "OK") {
        var lat = this.geometry_address.results[0].geometry.location.lat;
        var long = this.geometry_address.results[0].geometry.location.lng;
        this.GetTimezoneID(lat, long);
      } else {
        this.Latitude = '';
        this.Longitude = '';
        this.Zip='';
        this.timezoneid='';
        this.dstOffset='';
        this.rawOffset='';
        $('#errorZip').modal('hide');
        this.responseUpdate="Invalid Zip Code";
        $('#colorTheme').modal('show');
      }
    })
  }

  
  GetTimezoneID(lat, long) {
    var time = new Date().getTime();
    var date_timestamp = Math.round(time / 1000);
    this.http.get<any>("https://maps.googleapis.com/maps/api/timezone/json?location=" + lat + "," + long + "&timestamp=" + date_timestamp + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.timezone_address = response;
      // console.log(this.timezone_address)
      if (this.timezone_address.status == "OK") {
        this.timezoneid = this.timezone_address.timeZoneId;
        this.dstOffset = this.timezone_address.dstOffset;
        this.rawOffset = this.timezone_address.rawOffset;
        this.Latitude=lat;
        this.Longitude=long;
        // console.log("this.Latitude"+this.Latitude);
        // console.log("this.Longitude"+this.Longitude);
      } else {
        this.Latitude = '';
        this.Longitude = '';
        this.Zip='';
        this.timezoneid='';
        this.dstOffset='';
        this.rawOffset='';
        $('#errorZip').modal('hide');
        this.responseUpdate="Please Fill a valid Zip Code";
        $('#colorTheme').modal('show');
       // this.FlashError("Please fill valid Zip Code");
      }
    })
  }

  No() {
    this.Zip = '';
  }

}
