import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { GlobalService } from '../../../global.service';
declare const $;
@Component({
  selector: 'app-update-client',
  templateUrl: './update-client.component.html',
  styleUrls: ['./update-client.component.css']
})
export class UpdateClientComponent implements OnInit {
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'));
  IdClient = sessionStorage.getItem('referclientid');

  public mask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  Clientdetails;
  ClientName;
  ClientAddress;
  ClientPhoneNo;
  CountryName;
  StateName;
  CityName;
  ContactName;
  ContactPhoneNo;
  ContactEmailId;
  ZipCode;
  selectedcity;
  selctedcountry;
  selectedstate;
  countrylists;
  IdCountry;
  states;
  IdState;
  citys;
  IdCity;
  AlertText: string;
  constructor(
    private router: Router,
    private http: HttpClient,
    public apiList: ApilistService,
    private globalservice: GlobalService) { }

  ngOnInit() {
    $('#alert_div').hide();
    $('#dots_spinner').hide();
    this.globalservice.getCountry().then((res: any) => {
      this.countrylists = res;
      this.clientdetails();
    });
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/Clientlist']);
  }

  onChangeCountry(a) {
    this.IdCountry = a;
    if (typeof this.IdCountry === 'undefined') {
      alert('value undefined');
    } else {
      this.getstates();
    }
  }
  numberOnly(event): boolean {
    console.log("event.length"+event.length);
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    else if(event.length==8) {
      return false;
    }
    return true;

  }
  save() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.UpdateClientUrl,
      {
        IdClient: this.IdClient,
        IdMerchant: this.IdMerchant,
        ClientName: this.ClientName,
        ClientAddress: this.ClientAddress,
        IdCountry: this.IdCountry,
        IdState: this.IdState,
        IdCity: this.selectedcity,
        ZipCode: this.ZipCode,
        ClientPhoneNo: this.ClientPhoneNo,
        ContactName: this.ContactName,
        ContactPhoneNo: this.ContactPhoneNo,
        ContactEmailId: this.ContactEmailId
      }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        
        if (response.Response === 1) {
          this.router.navigate([this.companyName + '/dashboard/Clientlist']);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });

  }
  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
  getstates() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.StateUrl, { IdCountry: this.IdCountry })
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.states = response.lst_State;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  onChangeState(b) {
    this.IdState = b;
    if (typeof this.IdState === 'undefined') {
      alert('value undefined');
    } else {
      this.getcity();
    }
  }

  getcity() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CityUrl, { IdState: this.IdState })
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.citys = response.lst_City;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  clientdetails() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ClientDetailsUrl,
      { IdClient: this.IdClient, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.Clientdetails = response;
          this.ClientName = this.Clientdetails.Obj_Client.ClientName;
          this.ClientAddress = this.Clientdetails.Obj_Client.ClientAddress;
          this.ClientPhoneNo = this.Clientdetails.Obj_Client.ClientPhoneNo;
          this.selctedcountry = this.Clientdetails.Obj_Client.IdCountry;
          this.IdCountry = this.selctedcountry;
          this.selectedstate = this.Clientdetails.Obj_Client.IdState;
          this.IdState = this.selectedstate;
          this.ContactName = this.Clientdetails.Obj_Client.ContactName;
          this.ContactPhoneNo = this.Clientdetails.Obj_Client.ContactPhoneNo;
          this.ContactEmailId = this.Clientdetails.Obj_Client.ContactEmailId;
          this.ZipCode = this.Clientdetails.Obj_Client.ZipCode;
          if (typeof this.IdCountry === 'undefined') {
            alert('value undefined');
          } else {
            this.getstates();
            this.selectedcity = this.Clientdetails.Obj_Client.IdCity;
            this.IdCity = this.selectedcity;
          }
          if (typeof this.IdState === 'undefined') {
            alert('value undefined');
          } else {
            this.getcity();
          }
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }
}
