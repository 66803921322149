import { Component, OnInit, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DragDropDirectiveModule } from 'angular4-drag-drop';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from '../../../../environments/environment';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
declare const $;

@Component({
  selector: 'app-cubelayout',
  templateUrl: './cubelayout.component.html',
  styleUrls: ['./cubelayout.component.css']
})

export class CubelayoutComponent implements OnInit {
  @ViewChild('cube', { static: false }) public cubeForm: NgForm;
  @ViewChild('slave', { static: false }) public slaveForm: NgForm;

  IdMerchant = sessionStorage.getItem('referSuperMerchantId');
  companyName = sessionStorage.getItem('companyName');
  events: string[] = [];
  IdGrid: any;
  MachineType: string;
  item;
  ActivationDate;
  Activated;
  MasterNoOfMotorsPerTray;
  MasterNoOfTrays;
  MasterMachineModel;
  MasterMachineModel1;
  MasterMachineModel2;
  MasterMachineSerial;
  MasterMachine = '0';
  Machine;
  LinkCode = '';
  Snumbers;
  added;
  SlaveNoOfTrays;
  SlaveNoOfMotorsPerTray;
  SlaveMachineModel;
  SlaveMachineModel1;
  SlaveMachineModel2;
  SlaveMachine;
  SlaveMachineSerial;
  IdSlot;
  IdMachine;
  MasterglobalFlag = true;
  SlaveglobalFlag = true;
  AlertText = '';
  Machine_Id = 0;
  isAdding = false;
  isAddingslave = false;
  IdManufacturer: number;
  IdMachineModel: number;
  MachineModelName = '';
  ManufacturerName: any;
  Date: any;
  startbuilddate: any;
  completebuilddate: any;
  CanHaveSlaveScreen: boolean;
  maxTray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
    , 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
  count = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
    , 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
  // --edit
  lists: any;
  IdMaster: any;
  BuildStartDate: any;
  BuildCompleteDate: any;
  CanBeMaster: any;
  CanBeslave: any;
  Cannisters: any;
  Tea: any;
  WholeBean: any;
  IdSlave: any;
  update = false; // Create master machine. True for machine already exist;
  updateslave = false;   // Create new slave machine True for machine already exist;
  Tdetails: any;
  MaxTrayAllowed: any;
  MinTrayAllowed: any;
  MaxMotorsAllowed: any;
  MinMotorsAllowed: any;

  constructor(
    public apiList: ApilistService,
    private router: Router,
    private elRef: ElementRef,
    private route: ActivatedRoute,
    private http: HttpClient,
    private globalservice: GlobalService) {
    this.route.params.subscribe((get) => {
      this.CanHaveSlaveScreen = JSON.parse(get.CanHaveSlaveScreen),
        this.IdManufacturer = get.IdManufacturer;
      this.ManufacturerName = get.ManufacturerName;
      this.IdMachineModel = get.IdMachineModel;
      this.MachineModelName = get.MachineModelName;
    });
  }

  ngOnInit() {
    this.Date = new Date();
    this.hidepops();
  }
  hidepops() {
    $('#alert_div').hide();
    $('#dots_spinner').hide();
  }
  ngAfterViewInit() {
    // assume dynamic HTML was added before
  }
  dragEnter(event) {// console.log("dragEnter");
  }
  dragLeave(event) {// console.log("dragLeave");
  }
  dragoverMouse(event) {// console.log("dragoverMouse");
  }
  releaseDrop(event) { // console.log("releaseDrop");
  }
  startDrag(a) { this.MachineType = a; }

  addDropItem(id: number) {
    this.SlaveMachine = '';
    if (this.MasterglobalFlag === false) {
      this.FlashError(' Please fill form first');
      return false;
    }
    if (this.SlaveglobalFlag === false) {
      this.FlashError(' Please fill form first');
      return false;
    }
    this.IdGrid = id;
    if (this.MachineType === 'Master') {
      const plus8 = (parseInt(this.IdGrid, 0) + 8);
      const minus8 = (parseInt(this.IdGrid, 0) - 8);
      const plus1 = (parseInt(this.IdGrid, 0) + 1);
      const minus1 = (parseInt(this.IdGrid, 0) - 1);
      if ($('.Machine_dragDiv').find('.Master_Div').length === 1) {

        $('#' + this.IdGrid).empty();
        $('#' + this.IdGrid).append('<div class="Machine_dragDiv" data-toggle="modal" data-target="#Master_modal"><div class="Master_Div" id=M_' + this.IdGrid + '>' + this.MasterMachine + '</div></div>');
        $('#' + this.IdGrid).addClass('placed');
        this.MasterglobalFlag = false;
        this.SlaveglobalFlag = false;
        if (!$('#' + plus8).hasClass('placed')) {
          $('#' + plus8).addClass('dashedDiv');
        }
        if (!$('#' + minus8).hasClass('placed')) {
          $('#' + minus8).addClass('dashedDiv');
        }
        if (!$('#' + plus1).hasClass('placed')) {
          if ((this.IdGrid !== 8) && (this.IdGrid !== 16) && (this.IdGrid !== 24) && (this.IdGrid !== 32) && (this.IdGrid !== 40)) {
            $('#' + plus1).addClass('dashedDiv');
          }
        }
        if (!$('#' + minus1).hasClass('placed')) {
          if ((this.IdGrid !== 9) && (this.IdGrid !== 17) && (this.IdGrid !== 25) && (this.IdGrid !== 33)) {
            $('#' + minus1).addClass('dashedDiv');
          }
        }
      } else {
        this.FlashError('Not Allowed');
      }
    }
    if (this.MachineType === 'Slave') {
      const plus8 = (parseInt(this.IdGrid, 0) + 8);
      const minus8 = (parseInt(this.IdGrid, 0) - 8);
      const plus1 = (parseInt(this.IdGrid, 0) + 1);
      const minus1 = (parseInt(this.IdGrid, 0) - 1);
      if ($('#' + this.IdGrid).hasClass('dashedDiv')) {
        $('#' + this.IdGrid).empty();
        $('#' + this.IdGrid).append('<div class="Machine_dragDiv" data-toggle="modal" data-target="#Slave_modal"><div class="Slave_Div" style="margin:0px" id=S_' + this.IdGrid + '>' + this.SlaveMachine + '</div></div>');
        $('#' + this.IdGrid).addClass('placed');
        this.MasterglobalFlag = false;
        this.SlaveglobalFlag = false;
        if (!$('#' + plus8).hasClass('placed')) {
          $('#' + plus8).addClass('dashedDiv');
        }
        if (!$('#' + minus8).hasClass('placed')) {
          $('#' + minus8).addClass('dashedDiv');
        }
        if (!$('#' + plus1).hasClass('placed')) {
          if ((this.IdGrid !== 8) && (this.IdGrid !== 16) && (this.IdGrid !== 24) && (this.IdGrid !== 32) && (this.IdGrid !== 40)) {
            $('#' + plus1).addClass('dashedDiv');
          }
        }
        if (!$('#' + minus1).hasClass('placed')) {
          if ((this.IdGrid !== 9) && (this.IdGrid !== 17) && (this.IdGrid !== 25) && (this.IdGrid !== 33)) {
            $('#' + minus1).addClass('dashedDiv');
          }
        }
      }
      if ((!$('#' + this.IdGrid).hasClass('dashedDiv'))) {
        if ($('.Machine_dragDiv').find('.Master_Div').length === 1) {
          this.FlashError('Place Master first');
        } else {
          this.FlashError('Not Allowed');
        }
      }
      if ($('#' + this.IdGrid).hasClass('dashedDiv') && $('#' + this.IdGrid).addClass('placed')) {
        $('#' + this.IdGrid).removeClass('dashedDiv');
      }
    }
  }

  // canDeactivate(): Observable<boolean> | boolean {
  //   if ((!this.isAdding && this.cubeForm.dirty) || (!this.isAddingslave && this.slaveForm.dirty)) {
  //     return confirm('Are you sure you want to discard your changes?');
  //   } else if (this.cubeForm.dirty || this.slaveForm.dirty) {
  //     return confirm('Are you sure you want to discard your changes?');
  //   }
  //   return true;
  // }

  dropEventMouse(event) {
    // console.log("dropEventMouse");
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.ActivationDate = $('#date1').val();
  }

  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    this.ActivationDate = $('#date2').val();
  }
  addEvent4(value) {
    this.startbuilddate = value;
    this.BuildStartDate=value;
  }
  addEvent3(value) {
    this.BuildCompleteDate = value;
  }

  randomNumber() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (let i = 0; i < 8; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    this.LinkCode = text;
    return text;
  }

  async OpenModal(id) {
    this.IdSlot = id;
    //console.log("idslot is"+id);
    this.updateslave = false;
    this.update = false;
    if (this.IdMachine) {  // Existing machine get the machine detail
      await this.http.post<any>(this.apiList.MachineByIdUrl,
        { IdMachine: this.IdMachine }, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(resp => {
          if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
          if (+resp.Response !== 1) { throw resp; }
          return resp;
        }).subscribe((response) => {
          if (response.Response === 1) {
            this.lists = response;
            this.MaxTrayAllowed = this.lists.Obj_Machines.Obj_Machine.NoOfTrays;
            this.MinTrayAllowed = this.lists.Obj_Machines.Obj_Machine.MinTrays;
            this.MaxMotorsAllowed = this.lists.Obj_Machines.Obj_Machine.NoOfMotorsPerTray;
            this.MinMotorsAllowed = this.lists.Obj_Machines.Obj_Machine.MinMotors;
            this.IdMaster = this.lists.Obj_Machines.Obj_Machine.IdMachine;
            this.IdSlot = id;
            if ($('#M_' + id).hasClass('fill_done')) {  // Get master machine detail
              this.update = true;
              this.IdMaster = this.lists.Obj_Machines.Obj_Machine.IdMachine;
              this.Machine_Id = this.lists.Obj_Machines.Obj_Machine.IdMachine;
              this.MasterMachine = this.lists.Obj_Machines.Obj_Machine.Machine;
              this.Activated = this.lists.Obj_Machines.Obj_Machine.Activated;
              this.LinkCode = this.lists.Obj_Machines.Obj_Machine.LinkCode;
              this.MasterMachineSerial = this.lists.Obj_Machines.Obj_Machine.MachineSerial;
              this.MachineType = this.lists.Obj_Machines.Obj_Machine.MachineType;
              this.MasterMachineModel = this.lists.Obj_Machines.Obj_Machine.MachineModel;
              this.MasterMachineModel = this.MasterMachineModel.split(' ');
              this.MasterMachineModel1 = this.MasterMachineModel[0];
              this.MasterMachineModel2 = this.lists.Obj_Machines.Obj_Machine.MachineModelNumber;
              this.MasterNoOfTrays = this.lists.Obj_Machines.Obj_Machine.NoOfTrays;
              this.MasterNoOfMotorsPerTray = this.lists.Obj_Machines.Obj_Machine.NoOfMotorsPerTray;
              this.ActivationDate = this.lists.Obj_Machines.Obj_Machine.ActivationDate.split('T');
              this.ActivationDate = this.ActivationDate[0];
              this.startbuilddate=this.lists.Obj_Machines.Obj_Machine.BuildStartDate.split('T');
              this.startbuilddate=this.startbuilddate[0];
              this.BuildStartDate = this.lists.Obj_Machines.Obj_Machine.BuildStartDate.split('T');
              this.BuildStartDate = this.BuildStartDate[0];
              $('#sdate').val(this.BuildStartDate);
              this.BuildCompleteDate = this.lists.Obj_Machines.Obj_Machine.BuildCompleteDate.split('T');
              this.BuildCompleteDate = this.BuildCompleteDate[0];
              $('#cdate').val(this.BuildCompleteDate);
              if ((!this.CanBeMaster) && (!this.CanBeslave)) {
                this.Cannisters = this.lists.Obj_Machines.Obj_Machine.Cannisters;
                this.Tea = this.lists.Obj_Machines.Obj_Machine.Tea;
                this.WholeBean = this.lists.Obj_Machines.Obj_Machine.WholeBean;
              }
              $('#date1').val(this.ActivationDate);
              $('#date3').val(this.ActivationDate);
            } else if ($('#S_' + id).hasClass('fill_done')) { 
              //console.log("inside slave");   // Get Slave machine detail
              for (let i = 0; i < this.lists.Obj_Machines.lst_Slaves.length; i++) {
                if (this.IdSlot == this.lists.Obj_Machines.lst_Slaves[i].PositionInGrid) {
                  this.updateslave = true;
                  this.MachineType = 'Slave-Cube';
                  this.IdSlave = this.lists.Obj_Machines.lst_Slaves[i].IdSlave;
                  this.Machine_Id = this.lists.Obj_Machines.lst_Slaves[i].IdSlave;
                  this.SlaveMachine = this.lists.Obj_Machines.lst_Slaves[i].Machine;
                  this.SlaveMachineSerial = this.lists.Obj_Machines.lst_Slaves[i].MachineSerial;
                  this.SlaveNoOfTrays = this.lists.Obj_Machines.lst_Slaves[i].NoOfTrays;
                  this.SlaveNoOfMotorsPerTray = this.lists.Obj_Machines.lst_Slaves[i].NoOfMotorsPerTray;
                  this.SlaveMachineModel = this.lists.Obj_Machines.lst_Slaves[i].MachineModel.replace(/  +/g, ' ');
                  this.SlaveMachineModel = this.SlaveMachineModel.split(' ');
                  this.SlaveMachineModel1 = this.SlaveMachineModel[0];
                  this.SlaveMachineModel2 = this.SlaveMachineModel[1];
                  this.Activated = this.lists.Obj_Machines.lst_Slaves[i].Activated;
                  this.ActivationDate = this.lists.Obj_Machines.lst_Slaves[i].ActivationDate.split('T');
                  this.ActivationDate = this.ActivationDate[0];
                  $('#date2').val(this.ActivationDate);
                }
              }
            } else {  // New Slave machine
              this.slaveForm.reset();
              this.Machine_Id = 0;
            }
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        });
    }
  }

  submitMasterCubes() {
    this.isAdding = true;
    $('#dots_spinner').show();
    if (!this.LinkCode) {
      $('#dots_spinner').hide();
      this.FlashError('Firstly generate linkcode');
    } else {
      if (!this.ActivationDate) {
        $('#dots_spinner').hide();
        this.FlashError('Select Activation date');
      }
      else if(!this.startbuilddate){
        $('#dots_spinner').hide();
        this.FlashError('Select Build start date');
      }
      else if(!this.BuildCompleteDate){
        $('#dots_spinner').hide();
        this.FlashError('Select Build complete date');
      }
      else {
        this.http.post<any>(this.apiList.MachineSNCheckUrl,
          { MachineSerial: this.MasterMachineSerial, IdMachine: this.Machine_Id }, this.globalservice.Headers())
          .timeout(25000)
          .take(1)
          .map(resp => {
            if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
            if (+resp.Response !== 1) { throw resp; }
            return resp;
          }).subscribe((response) => {
            this.Snumbers = response;
            if (this.Snumbers.Response === 0) {
              this.FlashError('Machine Serial number is already in Use');
            } else if (this.Snumbers.Response === 1) {
              this.MasterMachineModel = this.MasterMachineModel1 + ' ' + this.MasterMachineModel2;
              this.http.post<any>(this.apiList.AddMachineUrl, {
                IdManufacturer: this.IdManufacturer,
                IdMachineModel: this.IdMachineModel,
                MachineModelNumber: this.MasterMachineModel2,
                PositionInGrid: this.IdSlot,
                MachineName: '', IdMasterMachine: 0,
                IsMaster: true
                , LinkCode: this.LinkCode,
                Machine: this.MasterMachine,
                MachineSerial: this.MasterMachineSerial,
                MachineType: 'Master-Cube',
                IdMachineType: 2,
                MachineModel: this.MasterMachineModel,
                NoOfTrays: this.MasterNoOfTrays,
                NoOfMotorsPerTray: this.MasterNoOfMotorsPerTray,
                ActivationDate: this.ActivationDate,
                Activated: this.Activated,
                CreatedBy: this.IdMerchant,
                AssignTo: this.IdMerchant,
                MaxCartSize: 4,
                BuildStartDate: this.startbuilddate,
                BuildCompleteDate: this.BuildCompleteDate
              }, this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(resp => {
                  if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                  if (+resp.Response !== 1) { throw resp; }
                  return resp;
                }).subscribe((response) => {
                  this.added = response;
                  this.update = false;
                  if (this.added.Response === 1) {
                    $('#M_' + this.IdSlot).addClass('fill_done');
                    this.MasterglobalFlag = true;
                    this.SlaveglobalFlag = true;
                    this.IdMachine = this.added.IdMachine;
                    this.cubeForm.reset();
                  }
                }, (err: any) => {
                  const Error = this.globalservice.ProcessError(err);
                  if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                      this.FlashError(Error.ErrorMessage);
                      this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                  } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                  }
                }).add(() => {
                  $('#dots_spinner').hide();
                  $('#Master_modal').modal('hide');
                  $('#Slave_modal').modal('hide');
                });
            }
          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
            $('#Slave_modal').modal('hide');
          });
      }
    }
  }

  submitSlaveCubes() {
    this.isAddingslave = true;
    $('#dots_spinner').show();
    if (this.SlaveMachine === 0) {
      $('#dots_spinner').hide();
      this.FlashError('Slave machine number is Invalid');
    } else {
      if (!this.ActivationDate) {
        $('#dots_spinner').hide();
        this.FlashError('Select Activation date');
      } else {
        this.http.post<any>(this.apiList.MachineSNCheckUrl,
          {
            MachineSerial: this.SlaveMachineSerial,
            IdMachine: this.Machine_Id
          }, this.globalservice.Headers())
          .timeout(25000)
          .take(1)
          .map(resp => {
            if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
            if (+resp.Response !== 1) { throw resp; }
            return resp;
          }).subscribe((response) => {
            this.Snumbers = response;
            if (this.Snumbers.Response === 0) {
              this.FlashError('Machine Serial number is already in Use');
            } else if (this.Snumbers.Response === 1) {
              this.SlaveMachineModel = this.SlaveMachineModel1 + ' ' + this.SlaveMachineModel2;
              this.http.post<any>(this.apiList.AddMachineUrl, {
                IdManufacturer: this.IdManufacturer, IdMachineModel: this.IdMachineModel,
                MachineModelNumber: this.SlaveMachineModel2,
                PositionInGrid: this.IdSlot, MachineName: '', IdMasterMachine: this.IdMachine, IsMaster: false
                , LinkCode: '', Machine: this.SlaveMachine
                , MachineSerial: this.SlaveMachineSerial, MachineType: 'Slave-Cube',
                IdMachineType: 2, MachineModel: this.SlaveMachineModel, NoOfTrays: this.SlaveNoOfTrays,
                NoOfMotorsPerTray: this.SlaveNoOfMotorsPerTray, ActivationDate: this.ActivationDate,
                Activated: this.Activated, CreatedBy: this.IdMerchant, AssignTo: this.IdMerchant
              }, this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(resp => {
                  if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                  if (+resp.Response !== 1) { throw resp; }
                  return resp;
                }).subscribe((response) => {
                  this.added = response;
                  this.update = false;
                  if (this.added.Response === 1) {
                    $('#S_' + this.IdSlot).addClass('fill_done');
                    this.SlaveglobalFlag = true;
                    this.MasterglobalFlag = true;
                    $('#S_' + this.IdSlot).text(this.SlaveMachine);
                    this.slaveForm.reset();
                  }
                }, (err: any) => {
                  const Error = this.globalservice.ProcessError(err);
                  if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                      this.FlashError(Error.ErrorMessage);
                      this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                  } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                  }
                }).add(() => {
                  $('#dots_spinner').hide();
                  $('#Slave_modal').modal('hide');
                });
            }
          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => { $('#dots_spinner').hide(); });
      }
    }
  }

  updateMasterCubes() {
    this.ActivationDate = $('#date1').val();
    this.http.post<any>(this.apiList.MachineSNCheckUrl,
      {
        MachineSerial: this.MasterMachineSerial,
        IdMachine: this.Machine_Id
      }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.Snumbers = response;
        if (this.Snumbers.Response === 0) {
          this.FlashError('Machine Serial number is already in Use');
        } else if (this.Snumbers.Response === 1) {
          this.MasterMachineModel = this.MasterMachineModel1 + ' ' + this.MasterMachineModel2;
          this.http.post<any>(this.apiList.UpdateMachineUrl,
            {
              IdManufacturer: this.IdManufacturer,
              IdMachineModel: this.IdMachineModel,
              MachineModelNumber: this.MasterMachineModel2,
              PositionInGrid: this.IdSlot,
              IdMachine: this.IdMaster,
              LinkCode: this.LinkCode,
              Machine: this.MasterMachine,
              MachineSerial: this.MasterMachineSerial,
              MachineModel: this.MasterMachineModel,
              NoOfTrays: this.MasterNoOfTrays,
              NoOfMotorsPerTray: this.MasterNoOfMotorsPerTray,
              ActivationDate: this.ActivationDate,
              Activated: this.Activated,
              CreatedBy: this.IdMerchant,
              AssignTo: this.IdMerchant,
              BuildStartDate: this.BuildStartDate,
              BuildCompleteDate: this.BuildCompleteDate
            }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(resp => {
              if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
              if (+resp.Response !== 1) { throw resp; }
              return resp;
            }).subscribe((response) => {
              this.Tdetails = response;
              this.update = false;
              if (this.Tdetails.Response === 2) {
                this.FlashError('Please Remove Products From Machines');
              } else if (this.Tdetails.Response === 1) {
                this.MasterglobalFlag = true;
                this.SlaveglobalFlag === true;
                $('#Master_modal').modal('hide');
                this.FlashError('Updated Successfully');
                this.http.post<any>(this.apiList.MachineByIdUrl,
                  { IdMachine: this.IdMachine }, this.globalservice.Headers())
                  .timeout(25000)
                  .take(1)
                  .map(resp => {
                    if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                    if (+resp.Response !== 1) { throw resp; }
                    return resp;
                  }).subscribe((response) => {
                    if (response.Response === 1) {
                      this.lists = response;
                    }
                  }, (err: any) => {
                    const Error = this.globalservice.ProcessError(err);
                    if (Error.Response === 35) {
                      if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                      }
                      this.globalservice.Logout('No');
                      return;
                    } else {
                      this.FlashError(Error.ErrorMessage);
                      return;
                    }
                  }).add(() => {
                    $('#dots_spinner').hide();
                    $('#Master_modal').modal('hide');
                  });
                if (this.CanBeMaster && (!this.CanBeslave) || !this.CanBeMaster && (!this.CanBeslave)) {
                }
              }
            }, (err: any) => {
              const Error = this.globalservice.ProcessError(err);
              if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                  this.FlashError(Error.ErrorMessage);
                  this.globalservice.Logout('Yes');
                }
                this.globalservice.Logout('No');
                return;
              } else {
                this.FlashError(Error.ErrorMessage);
                return;
              }
            }).add(() => {
              $('#dots_spinner').hide();
              $('#Master_modal').modal('hide');
            });
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
        $('#Master_modal').modal('hide');
      });
  }

  Completedate(value) {
    this.BuildCompleteDate = value;
  }

  UpdateSlaveCubes() {
    this.ActivationDate = $('#date2').val();
    if (this.SlaveMachine === 0) {
      $('#dots_spinner').hide();
      this.FlashError('Slave machine number is Invalid');
    } else {
      this.http.post<any>(this.apiList.MachineSNCheckUrl,
        {
          MachineSerial: this.SlaveMachineSerial,
          IdMachine: this.Machine_Id
        }, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(resp => {
          if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
          if (+resp.Response !== 1) { throw resp; }
          return resp;
        }).subscribe((response) => {
          this.Snumbers = response;
          if (this.Snumbers.Response === 0) {
            this.FlashError('Machine Serial number is already in Use');
          } else if (this.Snumbers.Response === 1) {
            this.SlaveMachineModel = this.SlaveMachineModel1 + ' ' + this.SlaveMachineModel2;
            this.http.post<any>(this.apiList.UpdateMachineUrl,
              {
                IdManufacturer: this.IdManufacturer,
                IdMachineModel: this.IdMachineModel,
                MachineModelNumber: this.SlaveMachineModel2,
                PositionInGrid: this.IdSlot,
                IdMachine: this.IdSlave,
                LinkCode: this.LinkCode,
                Machine: this.SlaveMachine,
                MachineSerial: this.SlaveMachineSerial,
                MachineType: this.MachineType,
                MachineModel: this.SlaveMachineModel,
                NoOfTrays: this.SlaveNoOfTrays,
                NoOfMotorsPerTray: this.SlaveNoOfMotorsPerTray,
                ActivationDate: this.ActivationDate,
                Activated: this.Activated,
                CreatedBy: this.IdMerchant,
                AssignTo: this.IdMerchant
              },
              this.globalservice.Headers())
              .timeout(25000)
              .take(1)
              .map(resp => {
                if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                if (+resp.Response !== 1) { throw resp; }
                return resp;
              }).subscribe((response) => {
                this.Tdetails = response;
                this.updateslave = false;
                if (this.Tdetails.Response === 1) {
                  this.AlertText = '';
                  this.AlertText = 'Updated Successfully';
                  $('#Slave_modal').modal('hide');
                  this.http.post<any>(this.apiList.MachineByIdUrl,
                    { IdMachine: this.IdMachine }, this.globalservice.Headers())
                    .timeout(25000)
                    .take(1)
                    .map(resp => {
                      if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                      if (+resp.Response !== 1) { throw resp; }
                      return resp;
                    }).subscribe((response) => {
                      if (response.Response === 1) {
                        this.lists = response;
                      }
                    });
                  $('#' + this.IdSlot).empty();
                  $('#' + this.IdSlot).append('<div class="Machine_dragDiv" data-toggle="modal" data-target="#Slave_modal"><div class="Slave_Div" style="margin:0px" id=S_' + this.IdSlot + '>' + this.SlaveMachine + '</div></div>');
                  $('#' + this.IdSlot).addClass('placed');
                  $('#S_' + this.IdSlot).addClass('fill_done');
                }
              }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                  if (Error.ErrorMessage) {
                    this.FlashError(Error.ErrorMessage);
                    this.globalservice.Logout('Yes');
                  }
                  this.globalservice.Logout('No');
                  return;
                } else {
                  this.FlashError(Error.ErrorMessage);
                  return;
                }
              }).add(() => { $('#dots_spinner').hide(); });
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#dots_spinner').hide(); });
    }
  }

  back() {
    this.router.navigate(['./Superadmindashboard/Inventory']);
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}
