import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
declare const $;

@Component({
  selector: 'app-campaignreports',
  templateUrl: './campaignreports.component.html',
  styleUrls: ['./campaignreports.component.css']
})

export class CampaignreportsComponent implements OnInit {
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'));

  details;
  CampaignDuration;
  CampaignStartDate;
  CampaignEndDate;
  Campaignlists: any;
  selectedCampaign;
  ScreenOrientation;
  p;
  lst_Machines;
  lstContents;
  orientation;
  AlertText: string;


  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public apiList: ApilistService) { }

  ngOnInit() {
    $('#dots_spinner').hide();
    $('#alert_div').hide();
    this.Campaignlist();
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    // this.StartDate =$('#SDate').val();
  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    // this.EndDate =$('#EDate').val();
  }

  Campaignlist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CampaignlistApi, { IdMerchant: this.IdMerchant },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.Campaignlists = response;
        this.Campaignlists = this.Campaignlists.lst_GetListOfCampaign;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  changeCampaign(id) {
    this.selectedCampaign = id;
    $('#dots_spinner').show();
    $('#machinelist').show();
    this.http.post<any>(this.apiList.CampaignDetailsApi, { IdCampaign: this.selectedCampaign },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.details = response;
        this.CampaignDuration = this.details.Obj_CampaignClass.CampaignDuration;
        this.CampaignStartDate = this.details.Obj_CampaignClass.StringCampaignStartDate;
        this.CampaignEndDate = this.details.Obj_CampaignClass.StringCampaignEndDate;
        $('#SDate').val(this.CampaignStartDate);
        $('#EDate').val(this.CampaignEndDate);
        this.lstContents = this.details.lst_Content;
        this.lst_Machines = this.details.lst_Machines;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  print(): void {
    // window.print();
    // let printContents, popupWin;
    // printContents = document.getElementById('print-section').innerHTML;
    // popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    // popupWin.document.open();
    // popupWin.document.write(`
    //   <html>
    //     <head>
    //       <title>Print tab</title>
    //       <style>

    //       </style>
    //     </head>
    // <body onload="window.print();window.close()">${printContents}</body>
    //   </html>`
    // );
    // popupWin.document.close();
  }

  AddScreenbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage']); }
  AddContentbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Addcontent']); }
  Adloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Adloop']); }
  placeloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/placeloop']); }
  preview() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Previewcampaign']); }
  Calendar() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaigncalendar']); }
  report() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaignreports']); }
  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}

