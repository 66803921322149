import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { Sort } from '@angular/material';
import { ApilistService } from '../../../../service/Api/apilist.service'
import * as moment from 'moment';
declare const $;

@Component({
  selector: 'app-productsalereport',
  templateUrl: './productsalereport.component.html',
  styleUrls: ['./productsalereport.component.css']
})

export class ProductsalereportComponent implements OnInit {
  CSVFileUrl = environment.CSVFileUrl;

  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));

  posts
  Mposts
  IdMachines = null
  Mlists
  StartDate
  EndDate
  selectedProduct
  Plistss
  Tlists
  MPlists
  Trans
  p: number = 1;
  IdProducts = null
  OnlyselectedMachine
  selectedMachine1
  selectedlocation = [];
  S1Date
  E2Date
  S3Date
  E4Date
  S5Date
  E6Date
  lists
  LocationName
  Machine
  tab: boolean = false;
  array; array1; array2; arraymachine; arrayproduct
  Idlocation; IdMachine;
  timezone: any;
  selectedtimezone = ''
  AlertText: string;

  Date: Date;

  constructor(public apiList: ApilistService,
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService) { }

  ngOnInit() {
    this.Date = new Date();
    this.StartDate =  moment(this.Date).format('MM/DD/YYYY')
    this.EndDate = moment(this.Date).format('MM/DD/YYYY')
    this.locationlist()
    this.listofmachine();
    this.listofproduct();
    this.timezonelist();
    this.HidePopups()
  }

  HidePopups(){
    $('#S5Date').val(this.StartDate);
    $('#E6Date').val(this.EndDate)
    $('#alert_div').hide()
  }

  locationlist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.LocationListUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.posts = response;
        this.posts = this.posts.lst_Location;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  timezonelist() {
    $('#dots_spinner').show();
    this.http.get<any>(this.apiList.TimeZoneListFUrl, this.globalservice.Headers())   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.timezone = response;
        this.timezone = this.timezone.lst_TimeZone;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  onChangeMachine(id: any) {
    id = id.map(function (e) {
      return JSON.stringify(e);
    });
    id = id.join(",");
    this.IdMachines = id;
    this.listofproductbymachine();
  }

  onChangelocation(idl: number) {
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
    this.Idlocation = idl.toString();
    this.machinelistist();
  }

  listofmachine() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.PlaceMachineLstUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.Mposts = response.lst_sp_GetPlacedMachinesWithLocation;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  listofproduct() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.PlacedProductlstUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())
    .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.Plistss = response;
        this.Plistss = this.Plistss.lst_Product
        // console.log(this.Plistss);
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  listofproductbymachine() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ProductLstMachinesUrl, {  IdMachines: this.IdMachines }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      // console.log(this.MPlists);
      if (response.Response == 1) {
        this.MPlists = response;
        this.MPlists = this.MPlists.lst_Product
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  searchproduct() {
    // console.log(this.IdProducts, this.IdProducts, this.StartDate, this.EndDate);
    if (this.StartDate && this.EndDate) {
      if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.ReportsLstURl, {  IdProducts: this.IdProducts, StartDate: this.StartDate, EndDate: this.EndDate, TimeZoneId: this.selectedtimezone }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
          if (response.Response == 1) {
            this.lists = response.lst_Product;
          }
        }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }
  }

  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  MachineTabsalesrepot() {
    // console.log(this.IdMachines, this.StartDate, this.EndDate, this.selectedlocation);
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.SaleReportUrl, {  IdMachine: this.IdMachines, StartDate: this.StartDate, EndDate: this.EndDate, IdLocation: this.selectedlocation }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      //console.log(response);
      if (response.Response == 1) {
        this.lists = response;
        this.lists = this.lists.lst_TransactionsProducts
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }
  onChangeproduct(id) {
    id = id.map(function (e) {
      return JSON.stringify(e);
    });
    id = id.join(",");
    this.IdProducts = id;
  }

  Searchlocation() {
    this.tab = false
    $("#salestable tr:gt(0)").remove();
    this.lists = null
    this.IdProducts = null;
    this.IdMachines = null;
    this.S1Date = ""
    this.E2Date = ""
    this.S3Date = ""
    this.E4Date = ""
    this.S5Date = ""
    this.E6Date = ""
    this.selectedProduct = "";
    this.OnlyselectedMachine = ""
    this.selectedMachine1 = ""
    this.selectedlocation = [];
    // this.Plistss = null;
    this.MPlists = null;
    this.Mlists = null;
  }

  SearchMachine() {
    this.tab = true
    $("#salestable tr:gt(0)").detach();
    this.lists = null
    this.IdProducts = null;
    this.IdMachines = null;
    this.S1Date = ""
    this.E2Date = ""
    this.S3Date = ""
    this.E4Date = ""
    this.S5Date = ""
    this.E6Date = ""
    this.selectedProduct = "";
    this.OnlyselectedMachine = ""
    this.selectedMachine1 = ""
    this.selectedlocation = [];
    // this.Plistss = null;
    this.MPlists = null;
    this.Mlists = null;
  }

  SearchProduct() {
    this.tab = false;
    $("#salestable tr:gt(0)").detach();
    this.lists = null
    this.IdProducts = null;
    this.IdMachines = null;
    this.S1Date = ""
    this.E2Date = ""
    this.S3Date = ""
    this.E4Date = ""
    this.S5Date = ""
    this.E6Date = ""
    this.selectedProduct = "";
    this.OnlyselectedMachine = ""
    this.selectedMachine1 = ""
    this.selectedlocation = [];
    // this.Plistss = null;
    this.MPlists = null;
    this.Mlists = null;
  }
  selectAll(id) {
       $('#checkall').addClass("btn_active");
      $('#uncheckall').removeClass("btn_active");
    if (id == 'location') {
      this.array = [];
      for (let i = 0; i < this.posts.length; i++) {
        this.array.push(this.posts[i].IdLocation);
      }
      this.selectedlocation = this.array;
      this.Idlocation = this.selectedlocation.toString();
      this.machinelistist()
    } else if (id == 'machine') {
      this.array1 = [];
      for (let i = 0; i < this.Mlists.length; i++) {
        this.array1.push(this.Mlists[i].IdMachine);
      }
      this.selectedMachine1 = this.array1;
      this.IdMachines = this.selectedMachine1.toString();
      this.listofproductbymachine();
    } else if (id == 'product') {
      this.array2 = [];
      for (let i = 0; i < this.MPlists.length; i++) {
        this.array1.push(this.MPlists[i].IdProduct);
      }
      this.selectedProduct = this.array1;
      this.IdProducts = this.selectedProduct.toString();
    }

  }


  deselectAll(id) {
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').addClass("btn_active");
    if (id == 'location') {
      this.selectedlocation = [];
      this.Idlocation = '';
      this.machinelistist()
    }
    else if (id == 'machine') {
      this.selectedMachine1 = '';
      this.IdMachines = '';
    } else if (id == 'product') {
      this.selectedProduct = '';
      this.IdProducts = '';
    }
  }

  selectAllmachine(id) {
      $('#checkall').addClass("btn_active");
      $('#uncheckall').removeClass("btn_active");
    if (id == 'machine') {
      this.arraymachine = [];
      for (let i = 0; i < this.Mposts.length; i++) {
        this.arraymachine.push(this.Mposts[i].IdMachine);
      }
      this.OnlyselectedMachine = this.arraymachine;
      this.IdMachines = this.OnlyselectedMachine.toString();
    } else if (id == 'product') {
      this.arrayproduct = [];
      for (let i = 0; i < this.Plistss.length; i++) {
        this.arrayproduct.push(this.Plistss[i].IdProduct);
      }
      this.selectedProduct = this.arrayproduct;
      this.IdProducts = this.selectedProduct.toString();

    }

  }

  deselectAllmachine(id) {
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').addClass("btn_active");
    if (id == 'machine') {
      this.OnlyselectedMachine = '';
      this.IdMachines = '';
    } else if (id == 'product') {
      this.selectedProduct = '';
      this.IdProducts = '';
    }
  }

  machinelistist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachineLstUrl, {  IdLocations: this.Idlocation }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.Mlists = response;
        this.Mlists = this.Mlists.lst_Machine;
      }
      //console.log(response);
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }
  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) { this.StartDate = $('#S1Date').val(); }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDate = $('#E2Date').val(); }
  addEvent3(type: string, event: MatDatepickerInputEvent<Date>) { this.StartDate = $('#S3Date').val(); }
  addEvent4(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDate = $('#E4Date').val(); }
  addEvent5(type: string, event: MatDatepickerInputEvent<Date>) { this.StartDate = $('#S5Date').val(); }
  addEvent6(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDate = $('#E6Date').val(); }


  salesbtn() { this.router.navigate([this.companyName + '/dashboard/reporting']); }
  pickreport() { this.router.navigate([this.companyName + '/dashboard/reporting/pickup']); }
  productbtn() { this.router.navigate([this.companyName + '/dashboard/reporting/productsale']); }
  exception() { this.router.navigate([this.companyName + '/dashboard/reporting/exception']); }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  sortData(sort: Sort) {
    const data = this.lists.slice();
    if (!sort.active || sort.direction === '') {
      this.lists = data;
      return;
    }
    this.lists = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'sku': return this.compare(a.SKU.toLowerCase(), b.SKU.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  export() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ExportproductreporttUrl, {  IdProducts: this.IdProducts, StartDate: this.StartDate, EndDate: this.EndDate, TimeZoneId: this.selectedtimezone }, this.globalservice.Headers())
    .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      // console.log(response);
      if (response.Response == 1) {
        let FileName = response.FileName
        window.location.href = this.CSVFileUrl + '/' + FileName;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  Print() {
    this.http.post<any>(this.apiList.PrintProductsUrl, {  IdProducts: this.IdProducts, StartDate: this.StartDate, EndDate: this.EndDate, TimeZoneId: this.selectedtimezone }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      // console.log(response)
      var data = response;
      if (data.Response == 1) {
        data = data.htmltext
        var popupWinindow = window.open('', '_blank', 'width=1366, height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        popupWinindow.document.write('<html><head></head><body onload="window.print()" onafterprint="window.close()">' + data + '</html>');
        popupWinindow.document.close();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

}
