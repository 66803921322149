import { Component, HostListener, OnInit } from '@angular/core';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Sort } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare const $;

@Component({
  selector: 'app-chains',
  templateUrl: './chains.component.html',
  styleUrls: ['./chains.component.css']
})
export class ChainsComponent implements OnInit {

  companyName = sessionStorage.getItem("companyName");
  Search
  list: any;
  AlertText: string;
  IdBrand: any;
  posts: any; //$$ BUG, added for compile since referenced in HTML, but not used.

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43;
  pageno = 1;
  before: number;
  chainName: string = "";
  // productForm: FormGroup;
  ChainEditName: string = "";
  IdChain;

  constructor(private globalservice: GlobalService, public apiList: ApilistService, private router: Router,
    private http: HttpClient, private formBuilder: FormBuilder,) {
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();

  }

  ngOnInit() {

    this.HidePopups();
    this.refreshlist();
  }

  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
      //console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  HidePopups() {
    $("#alert_div").hide();
    $('#dots_spinner').hide();
    $('#myModal').modal('hide');
    $('#addChain').modal('hide');
    $('#EditChain').modal('hide');

  }



  AddChainData() {
    // this.productForm.value.SalesTaxAmount
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.AddChain,
      { Name: this.chainName }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        // this.list = response;
        this.FlashError('Successfully Added');
        this.refreshlist();

      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
        $('#addChain').modal('hide');
        this.chainName = "";
        // if (this.Resizing) {
        //   // need to give time to render behind the scenes before size/resize rows	        // need to give time to render behind the scenes before size/resize rows
        //   this.SettleAndAutosize(500);
        // }
      });


  }

  EditChainData() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.UpdateChain,
      { IdChain: this.IdChain, Name: this.ChainEditName }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        // this.list = response;
        this.FlashError('Successfully Updated');
        this.refreshlist();

      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
        $('#EditChain').modal('hide');
        this.ChainEditName = "";
        // if (this.Resizing) {
        //   // need to give time to render behind the scenes before size/resize rows	        // need to give time to render behind the scenes before size/resize rows
        //   this.SettleAndAutosize(500);
        // }
      });
  }

  editChainSelected(id, name) {
    this.IdChain = id;
    this.ChainEditName = name;

  }

  refreshlist() {
    $('#dots_spinner').show();
    console.log(this.globalservice.Headers())
    let data = {
      PageNumber: this.pageno,
      NumberOfRows: this.before
    }
    this.http.post<any>(this.apiList.MerchantChains, data, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          console.log("merchant chain list" + JSON.stringify(response));
          this.list = response.Chains;
          $('#dots_spinner').hide();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
            $('#dots_spinner').hide();
          }
          this.globalservice.Logout('No');
          $('#dots_spinner').hide();
          return;
        } else {
          $('#dots_spinner').hide();
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
        if (this.Resizing) {
          // need to give time to render behind the scenes before size/resize rows
          this.SettleAndAutosize();
        }
      });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }


  redirect() {
    this.chainName = "";
    console.log("inside modal open");
    $('#addChain').modal('show');

    // this.router.navigate([this.companyName + '/dashboard/brandusers/brand', {
    // }]);
  }

  deleteChain(id) {
    $('#myModal').modal('show');
    this.IdChain = id
  }


  delete() {
    //console.log(this.IdBrand )
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteChain,
      { IdChain: this.IdChain }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        // this.list = response;
        this.FlashError('Successfully deleted');
        this.refreshlist();

      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
        // if (this.Resizing) {
        //   // need to give time to render behind the scenes before size/resize rows	        // need to give time to render behind the scenes before size/resize rows
        //   this.SettleAndAutosize(500);
        // }
      });

  }

  clearSearch() {
    this.Search = '';
    this.refreshlist()
  }

  close_addchain() {
    $('#addChain').modal('hide');
    this.chainName = "";

  }
  close_editchain() {
    $('#EditChain').modal('hide');
    this.ChainEditName = "";
  }

  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }

  edit(id, name) {
    this.IdChain = id;
    this.ChainEditName = name;
    $('#EditChain').modal('show');
  }

  // -----------sorting----------//
  sortData(sort: Sort) {
    const data = this.list.slice();
    if (!sort.active || sort.direction === '') {
      this.list = data;
      return;
    }
    this.list = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.Name.toLowerCase(), b.Name.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
