import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { RouterModule, Routes, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
declare const $;
@Component({
  selector: 'app-productsuppliers',
  templateUrl: './productsuppliers.component.html',
  styleUrls: ['./productsuppliers.component.css']
})
export class ProductsuppliersComponent implements OnInit {
  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  lists
  Search
  IdProductSupplier
  company_data: any;
  p: any;
  AlertText


  constructor(
    private router: Router,
    private http: HttpClient, 
    private globalservice: GlobalService,
    public apiList: ApilistService) { }

  ngOnInit() {
    this.supplierlist();
    this.HidePopups()
  }

  HidePopups(){
       $("#alert_div").hide();
    $('#LocationDetails').modal('hide');
    $('#dots_spinner').hide();
  }

  supplierlist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.SupplierlstUrl, {IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
       this.lists = response;
        this.lists = this.lists.lst_ProductSupplier
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }
  addsupplier() {
    this.router.navigate([this.companyName + '/dashboard/ProductSuppliers/AddSuppliers']);
  }

  delete() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteSupplierUrl, { IdProductSupplier: this.IdProductSupplier }, this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
        if (response.Response == 1) {
          this.FlashError('Successfully deleted');
        this.supplierlist();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }
  edit(id) {
    this.router.navigate([this.companyName + '/dashboard/ProductSuppliers/Updatesuppliers',
    { id: id }]);
  }
  
  getID(id) {
    this.IdProductSupplier = id;
  }

  //-----------sorting----------//
  sortData(sort: Sort) {
    const data = this.lists.slice();
    if (!sort.active || sort.direction === '') {
      this.lists = data;
      return;
    }
    this.lists = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'company_name': return this.compare(a.CompanyName.toLowerCase(), b.CompanyName.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  //----------------------//
  clearSearch() {
    this.Search = '';
    this.supplierlist();
  }

  company_info(data) {
    $('#LocationDetails').modal('show');
    this.company_data = data;
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}
