import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ɵclearResolutionOfComponentResourcesQueue } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { of, Subject } from 'rxjs';
import { delay, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { GlobalService } from '../../../global.service';

declare const $;


@Component({
  selector: 'app-shopperreport',
  templateUrl: './shopperreport.component.html',
  styleUrls: ['./shopperreport.component.css']
})
export class ShopperreportComponent implements OnInit {
  @ViewChild('reportinglocation', { static: false }) public reportinglocationForm: NgForm
  @ViewChild('reportingmachine', { static: false }) public reportingmachineForm: NgForm
  @ViewChild('reportingmachineCategory', { static: false }) public reportingmachineCategoryForm: NgForm
  reportingmachineCategory
  CSVFileUrl = environment.CSVFileUrl;
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");

  IdMachine
  StartDate
  EndDate
  posts
  selectedlocation = null
  OnlyselectedMachine = null
  Mlists
  ldetails
  MLStartDate
  MLEndDate
  MStartDate
  MEndDate
  Tlists
  Trans: any;
  Mposts
  selectedMachine1
  p: number = 1;
  S1Date
  E2Date
  S3Date
  E4Date
  array = [];
  Idlocation;
  machinelistlength;
  selectedtimezone = ''
  AlertText
  nextLibAvailable = false;
  coupon_code; coupon_amount; coupon_used; coupon_startdate; coupon_enddate; coupon_startdatetime; coupon_enddatetime; coupon_discounttype;
  timezone: any;

  EmailId: any;
  IdTransaction: any;
  TC: any;
  RevNumber: any;
  RevisionNumber: any;
  Date: Date;
  S1Datee;
  resendMailTimeZone: any = "";

  idB;
  Brandlist: any;
  IdBrand: any;

  chainList: any[] = [];
  chainval;
  Locationval;
  ProductVal;
  postsprod: any;
  optionsModel = [];
  arraymachine: any[];
  IdMachines: any;
  arrayproduct;
  selectedProduct;
  Plistss: any;
  IdProducts = null;
  myOptions = [
    { id: 0, name: 'Sunday' },
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
  ];
  StartTime: string = "12:00 AM";
  EndTime: string = "12:00 AM";

  // tab2

  IdMachinetab2
  StartDatetab2
  EndDatetab2
  poststab2
  selectedlocationtab2 = null
  OnlyselectedMachinetab2 = null
  Mliststab2
  ldetailstab2
  MLStartDatetab2
  MLEndDatetab2
  MStartDatetab2
  MEndDatetab2
  Tliststab2
  Transtab2: any;
  Mpoststab2
  selectedMachine1tab2

  S1Datetab2
  E2Datetab2
  S3Datetab2
  E4Datetab2
  arraytab2 = [];
  Idlocationtab2;
  machinelistlengthtab2;
  selectedtimezonetab2 = ''
  AlertTexttab2
  nextLibAvailabletab2 = false;
  coupon_codetab2; coupon_amounttab2; coupon_usedtab2; coupon_startdatetab2; coupon_enddatetab2; coupon_startdatetimetab2; coupon_enddatetimetab2; coupon_discounttypetab2;
  timezonetab2: any;

  EmailIdtab2: any;
  IdTransactiontab2: any;
  TCtab2: any;
  RevNumbertab2: any;
  RevisionNumbertab2: any;
  Datetab2: Date;
  S1Dateetab2;
  resendMailTimeZonetab2: any = "";

  idBtab2;
  Brandlisttab2: any;
  IdBrandtab2: any;

  chainListtab2: any[] = [];
  chainvaltab2;
  Locationvaltab2;
  ProductValtab2;
  postsprodtab2: any;
  optionsModeltab2 = [];
  arraymachinetab2: any[];
  IdMachinestab2: any;
  arrayproducttab2;
  selectedProducttab2;
  Plistsstab2: any;
  IdProductstab2 = null;

  myOptionstab2 = [
    { id: 0, name: 'Sunday' },
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
  ];
  StartTimetab2: string = "12:00 AM";
  EndTimetab2: string = "12:00 AM";
  reportBrand: boolean = true;


  //tab3


  // tab2

  IdMachinetab3
  StartDatetab3
  EndDatetab3
  poststab3
  selectedlocationtab3 = null
  OnlyselectedMachinetab3 = null
  Mliststab3
  ldetailstab3
  MLStartDatetab3
  MLEndDatetab3
  MStartDatetab3
  MEndDatetab3
  Tliststab3
  Transtab3: any;
  Mpoststab3
  selectedMachine1tab3

  S1Datetab3
  E2Datetab3
  S3Datetab3
  E4Datetab3
  arraytab3 = [];
  Idlocationtab3;
  machinelistlengthtab3;
  selectedtimezonetab3 = ''
  AlertTexttab3
  nextLibAvailabletab3 = false;
  coupon_codetab3; coupon_amounttab3; coupon_usedtab3; coupon_startdatetab3; coupon_enddatetab3; coupon_startdatetimetab3; coupon_enddatetimetab3; coupon_discounttypetab3;
  timezonetab3: any;

  EmailIdtab3: any;
  IdTransactiontab3: any;
  TCtab3: any;
  RevNumbertab3: any;
  RevisionNumbertab3: any;
  Datetab3: Date;
  S1Dateetab3;
  resendMailTimeZonetab3: any = "";

  idBtab3;
  Brandlisttab3: any;
  IdBrandtab3: any;

  chainListtab3: any[] = [];
  chainvaltab3;
  Locationvaltab3;
  ProductValtab3;
  postsprodtab3: any;
  optionsModeltab3 = [];
  arraymachinetab3: any[];
  IdMachinestab3: any;
  arrayproducttab3;
  selectedProducttab3;
  Plistsstab3: any;
  IdProductstab3 = null;

  myOptionstab3 = [
    { id: 0, name: 'Sunday' },
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
  ];
  StartTimetab3: string = "12:00 AM";
  EndTimetab3: string = "12:00 AM";

  zipLists: any[] = [];
  zipListsFiltered: any[] = [];
  shopperlistdata: any;
  shopperlistdataFiltered: Object;
  searchTerm$ = new Subject<string>();
  selectedShopper;
  arrayshopper;
  IdShoppers = null;
  shopperVal;
  reportShopper: boolean = true;
  reportZip: boolean = false;
  reportCategory: boolean = false;
  zipVal;


  //category
  idMCat;
  IdMainCategory: any;
  IdSubCategory: any;
  names: any;
  postscat: any;
  subcat_length: any;
  idS: string;
  UserRole = sessionStorage.getItem("referUserRole");
  brandStatus:boolean=false;


  constructor(public apiList: ApilistService, private router: Router, private http: HttpClient, private globalservice: GlobalService) {

    this.globalservice.searchShopper(this.searchTerm$)
      .subscribe(results => {
        this.shopperVal = null;
        this.shopperlistdataFiltered = results.ShoppersList;
      });
  }

  ngOnInit() {
    if(this.UserRole=="BrandUser"){
      this.brandStatus=true;
          }
          else{
      this.brandStatus=false;
          }
    this.reportShopper = true;
    this.reportCategory = false;
    this.reportZip = false;
    this.resendMailTimeZone = "";
    this.ShopperList();
    this.zipList();
    this.categorylist();
    this.HidePopups();
    this.dateTimeFunction();

    this.Date = new Date();
    this.StartDate = moment(this.Date).format('MM/DD/YYYY')
    $('#S1Date').val(this.StartDate);
    this.EndDate = moment(this.Date).format('MM/DD/YYYY')
    $('#E2Date').val(this.EndDate)
    $('#S3Date').val(this.StartDate);
    $('#E4Date').val(this.EndDate);


    // tab2
    this.resendMailTimeZone = "";
    this.dateTimeFunctiontab2();
    this.onChangechaintab2(this.chainval);

    //    this.refreshlistproduct();
    this.Datetab2 = new Date();
    this.StartDatetab2 = moment(this.Datetab2).format('MM/DD/YYYY')
    $('#S1Datetab2').val(this.StartDatetab2);
    this.EndDatetab2 = moment(this.Datetab2).format('MM/DD/YYYY')
    $('#E2Datetab2').val(this.EndDatetab2)
    $('#S3Datetab2').val(this.StartDatetab2);
    $('#E4Datetab2').val(this.EndDatetab2);

    //tab3
    this.dateTimeFunctiontab3();
    this.onChangechaintab3(this.chainval);
    this.Datetab3 = new Date();
    this.StartDatetab3 = moment(this.Datetab3).format('MM/DD/YYYY')
    $('#S1Datetab3').val(this.StartDatetab3);
    this.EndDatetab3 = moment(this.Datetab3).format('MM/DD/YYYY')
    $('#E2Datetab3').val(this.EndDatetab3)
    $('#S3Datetab3').val(this.StartDatetab3);
    $('#E4Datetab3').val(this.EndDatetab3);

  }

  zipList() {

    $('#dots_spinner').show();
    this.http.get<any>(this.apiList.MerchantZipCodeList, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          this.zipLists = response.zipLists;
          this.zipListsFiltered = response.zipLists;
          $('#dots_spinner').hide();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
            $('#dots_spinner').hide();
          }
          this.globalservice.Logout('No');
          $('#dots_spinner').hide();
          return;
        } else {
          $('#dots_spinner').hide();
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();

      });
  }
  ShopperList() {
    $('#dots_spinner').show();
    this.http.get<any>(this.apiList.AllShopperResult, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          this.shopperlistdata = response.ShoppersList;
          this.shopperlistdataFiltered = response.ShoppersList;
          $('#dots_spinner').hide();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
            $('#dots_spinner').hide();
          }
          this.globalservice.Logout('No');
          $('#dots_spinner').hide();
          return;
        } else {
          $('#dots_spinner').hide();
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();

      });
  }

  onChangeproduct(id) {
    id = id.map(function (e) {
      return JSON.stringify(e);
    });
    id = id.join(",");
    this.IdProducts = id;
  }

  onChangeproducttab2(id) {
    id = id.map(function (e) {
      return JSON.stringify(e);
    });
    id = id.join(",");
    this.IdProductstab2 = id;
  }


  onChangeproducttab3(id) {
    id = id.map(function (e) {
      return JSON.stringify(e);
    });
    id = id.join(",");
    this.IdProductstab3 = id;
  }

  onChangeShopper(id) {
    id = id.map(function (e) {
      return JSON.stringify(e);
    });
    id = id.join(",");
    this.IdShoppers = id;
  }


  changeday() {
    $('#uncheckalldays').removeClass("btn_active");
    $('#checkalldays').removeClass("btn_active");
  }
  changedaytab2() {
    $('#uncheckalldaystab2').removeClass("btn_active");
    $('#checkalldaystab2').removeClass("btn_active");
  }

  changedaytab3() {
    $('#uncheckalldaystab3').removeClass("btn_active");
    $('#checkalldaystab3').removeClass("btn_active");
  }
  selectAlldays() {
    this.optionsModel = [0, 1, 2, 3, 4, 5, 6]
    $('#checkalldays').addClass("btn_active");
    $('#uncheckalldays').removeClass("btn_active");
  }
  selectAlldaystab2() {
    this.optionsModeltab2 = [0, 1, 2, 3, 4, 5, 6]
    $('#checkalldaystab2').addClass("btn_active");
    $('#uncheckalldaystab2').removeClass("btn_active");
  }

  selectAlldaystab3() {
    this.optionsModeltab3 = [0, 1, 2, 3, 4, 5, 6]
    $('#checkalldaystab3').addClass("btn_active");
    $('#uncheckalldaystab3').removeClass("btn_active");
  }

  deselectAlldays() {
    this.optionsModel = [];
    $('#checkalldays').removeClass("btn_active");
    $('#uncheckalldays').addClass("btn_active");
  }
  deselectAlldaystab2() {
    this.optionsModeltab2 = [];
    $('#checkalldaystab2').removeClass("btn_active");
    $('#uncheckalldaystab2').addClass("btn_active");
  }
  deselectAlldaystab3() {
    this.optionsModeltab3 = [];
    $('#checkalldaystab3').removeClass("btn_active");
    $('#uncheckalldaystab3').addClass("btn_active");
  }
  dateTimeFunction() {
    $(document).ready(function () {
      $('#timepicker1').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12"
      });
      $('#timepicker2').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12:00 AM"
      });
    });
    $('body').on('change', '#timepicker1', function () {
      $('#timepicker1').trigger('click');
    });
    $('body').on('change', '#timepicker2', function () {
      $('#timepicker2').trigger('click');
    });
  }

  dateTimeFunctiontab2() {
    $(document).ready(function () {
      $('#timepicker1tab2').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12"
      });
      $('#timepicker2tab2').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12:00 AM"
      });
    });
    $('body').on('change', '#timepicker1tab2', function () {
      $('#timepicker1tab2').trigger('click');
    });
    $('body').on('change', '#timepicker2tab2', function () {
      $('#timepicker2tab2').trigger('click');
    });
  }

  dateTimeFunctiontab3() {
    $(document).ready(function () {
      $('#timepicker1tab3').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12"
      });
      $('#timepicker2tab3').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12:00 AM"
      });
    });
    $('body').on('change', '#timepicker1tab3', function () {
      $('#timepicker1tab3').trigger('click');
    });
    $('body').on('change', '#timepicker2tab3', function () {
      $('#timepicker2tab3').trigger('click');
    });
  }
  endTimelocation(tim: string) {
    this.EndTime = tim;
  }
  endTimelocationtab2(tim: string) {
    this.EndTimetab2 = tim;
  }
  endTimelocationtab3(tim: string) {
    this.EndTimetab3 = tim;
  }
  startTime(time: string) {
    this.StartTime = time;
  }
  startTimetab2(time: string) {
    this.StartTimetab2 = time;
  }

  startTimetab3(time: string) {
    this.StartTimetab3 = time;
  }

  listofproduct() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.GetProductListByBrandId, { IdBrand: this.IdBrand }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Plistss = response;
          this.Plistss = this.Plistss.lst_Product
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }
  listofproducttab2() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.GetProductListByBrandId, { IdBrand: this.IdBrandtab2 }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Plistsstab2 = response;
          this.Plistsstab2 = this.Plistsstab2.lst_Product
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  listofproducttab3() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.GetProductListByBrandId, { IdBrand: this.IdBrandtab3 }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Plistsstab3 = response;
          this.Plistsstab3 = this.Plistsstab3.lst_Product
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  onChangechain(id: string) {
    if (id != 'None') {
      $('#dots_spinner').show();
      this.chainval = id;
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').removeClass("btn_active");
      this.http.post<any>(this.apiList.GetLocationListByChain, { IdChain: this.chainval }, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (result.Response !== 1) throw result;
          return result;
        }).subscribe((response) => {
          $('#dots_spinner').hide();
          if (response.Response == 1) {
            this.posts = response.lst_Location;
            this.nextLibAvailable = true;
            $('#checkall').removeClass("btn_active");
            $('#uncheckall').removeClass("btn_active");
            this.selectedlocation = "";
            this.selectedMachine1 = "";
            this.Idlocation = "";
            this.IdMachine = "";
            this.Mlists = [];
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
        });
    }
    else {
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').removeClass("btn_active");
      this.chainval = null;
      this.selectedlocation = null;
    }
  }

  onChangechaintab2(id: string) {
    if (id != 'None') {
      $('#dots_spinner').show();
      this.chainvaltab2 = id;
      $('#checkalltab2').removeClass("btn_active");
      $('#uncheckalltab2').removeClass("btn_active");
      this.http.post<any>(this.apiList.GetLocationListByChain, { IdChain: this.chainvaltab2 }, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (result.Response !== 1) throw result;
          return result;
        }).subscribe((response) => {
          $('#dots_spinner').hide();
          if (response.Response == 1) {
            this.poststab2 = response.lst_Location;
            this.nextLibAvailabletab2 = true;
            $('#checkalltab2').removeClass("btn_active");
            $('#uncheckalltab2').removeClass("btn_active");
            this.selectedlocationtab2 = "";
            this.selectedMachine1tab2 = "";
            this.Idlocationtab2 = "";
            this.Mliststab2 = [];
            this.IdMachinetab2 = "";
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
        });
    }
    else {
      $('#checkalltab2').removeClass("btn_active");
      $('#uncheckalltab2').removeClass("btn_active");
      this.chainvaltab2 = null;
      this.selectedlocationtab2 = null;
      this.selectedMachine1tab2 = null;
      this.Idlocationtab2 = "";
      this.Mliststab2 = [];
      this.IdMachinetab2 = "";
    }
  }

  onChangechaintab3(id: string) {
    if (id != 'None') {
      $('#dots_spinner').show();
      this.chainvaltab3 = id;
      $('#checkalltab3').removeClass("btn_active");
      $('#uncheckalltab3').removeClass("btn_active");
      this.http.post<any>(this.apiList.GetLocationListByChain, { IdChain: this.chainvaltab3 }, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (result.Response !== 1) throw result;
          return result;
        }).subscribe((response) => {
          $('#dots_spinner').hide();
          if (response.Response == 1) {
            this.poststab3 = response.lst_Location;
            this.nextLibAvailabletab3 = true;
            $('#checkalltab3').removeClass("btn_active");
            $('#uncheckalltab3').removeClass("btn_active");
            this.selectedlocationtab3 = "";
            this.selectedMachine1tab3 = "";
            this.Idlocationtab3 = "";
            this.Mliststab3 = [];
            this.IdMachinetab3 = "";
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
        });
    }
    else {
      $('#checkalltab3').removeClass("btn_active");
      $('#uncheckalltab3').removeClass("btn_active");
      this.chainvaltab3 = null;
      this.selectedlocationtab3 = null;
      this.selectedMachine1tab3 = null;
      this.Idlocationtab3 = "";
      this.Mliststab3 = [];
      this.IdMachinetab3 = "";
    }
  }



  refreshChainlist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MerchantChains, {}, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          this.chainList = response.Chains;
          $('#dots_spinner').hide();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
            $('#dots_spinner').hide();
          }
          this.globalservice.Logout('No');
          $('#dots_spinner').hide();
          return;
        } else {
          $('#dots_spinner').hide();
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();

      });
  }

  refreshChainlisttab2() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MerchantChains, {}, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          this.chainListtab2 = response.Chains;
          $('#dots_spinner').hide();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
            $('#dots_spinner').hide();
          }
          this.globalservice.Logout('No');
          $('#dots_spinner').hide();
          return;
        } else {
          $('#dots_spinner').hide();
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();

      });
  }

  refreshChainlisttab3() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MerchantChains, {}, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          this.chainListtab3 = response.Chains;
          $('#dots_spinner').hide();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
            $('#dots_spinner').hide();
          }
          this.globalservice.Logout('No');
          $('#dots_spinner').hide();
          return;
        } else {
          $('#dots_spinner').hide();
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();

      });
  }

  brand() {
    this.http.post<any>(this.apiList.MerchantBrandResult, '', this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          this.Brandlist = response.MerchantBrandsList;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {

      });
  }

  brandtab2() {
    this.http.post<any>(this.apiList.MerchantBrandResult, '', this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          this.Brandlisttab2 = response.MerchantBrandsList;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {

      });
  }

  brandtab3() {
    this.http.post<any>(this.apiList.MerchantBrandResult, '', this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          this.Brandlisttab3 = response.MerchantBrandsList;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {

      });
  }


  HidePopups() {
    $('#Norecord').hide();
    $('#dots_spinner').hide();
    $("#alert_div").hide();
    $('#coupon_info').modal('hide');
  }

  locationList() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.LocationListUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.posts = response;
          this.posts = this.posts.lst_Location;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  locationListtab2() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.LocationListUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.poststab2 = response;
          this.poststab2 = this.poststab2.lst_Location;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }



  locationListtab3() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.LocationListUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.poststab3 = response;
          this.poststab3 = this.poststab3.lst_Location;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  timezonelist() {
    $('#dots_spinner').show();
    this.http.get<any>(this.apiList.TimeZoneListFUrl, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.timezone = response;
          this.timezone = this.timezone.lst_TimeZone;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  timezonelisttab2() {
    $('#dots_spinner').show();
    this.http.get<any>(this.apiList.TimeZoneListFUrl, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.timezonetab2 = response;
          this.timezonetab2 = this.timezonetab2.lst_TimeZone;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  timezonelisttab3() {
    $('#dots_spinner').show();
    this.http.get<any>(this.apiList.TimeZoneListFUrl, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.timezonetab3 = response;
          this.timezonetab3 = this.timezonetab3.lst_TimeZone;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  machinelistbylocation() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachinelstUrl, { IdLocation: this.selectedlocation }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mlists = response;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }
  machinelistbylocationtab2() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachinelstUrl, { IdLocation: this.selectedlocationtab2 }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mliststab2 = response;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  machinelistbylocationtab3() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachinelstUrl, { IdLocation: this.selectedlocationtab3 }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mliststab3 = response;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }


  onChangelocation(idl: number) {
    this.nextLibAvailable = true;
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
    this.Idlocation = idl.toString();
    this.IdMachine = "";
    this.selectedMachine1 = "";
    this.machinelistist()
  }

  onChangelocationtab2(idl: number) {
    this.nextLibAvailabletab2 = true;
    $('#checkalltab2').removeClass("btn_active");
    $('#uncheckalltab2').removeClass("btn_active");
    this.Idlocationtab2 = idl.toString();
    this.IdMachinetab2 = "";
    this.selectedMachine1tab2 = "";
    this.machinelististtab2()
  }

  onChangelocationtab3(idl: number) {
    this.nextLibAvailabletab3 = true;
    $('#checkalltab3').removeClass("btn_active");
    $('#uncheckalltab3').removeClass("btn_active");
    this.Idlocationtab3 = idl.toString();
    this.IdMachinetab3 = "";
    this.selectedMachine1tab3 = "";
    this.machinelististtab3()
  }

  machinelistist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachinelstUrl, { IdLocations: this.Idlocation }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mlists = response;
          this.Mlists = this.Mlists.lst_Machine;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  machinelististtab2() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachinelstUrl, { IdLocations: this.Idlocationtab2 }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mliststab2 = response;
          this.Mliststab2 = this.Mliststab2.lst_Machine;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  machinelististtab3() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachinelstUrl, { IdLocations: this.Idlocationtab3 }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mliststab3 = response;
          this.Mliststab3 = this.Mliststab3.lst_Machine;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  listofshopperreport() {
    this.reportShopper = true;
    this.reportCategory = false;
    this.reportZip = false;
    if (this.StartDate && this.EndDate) {
      if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {
        $('#dots_spinner').show();
        var a = this.optionsModel;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        this.http.post<any>(this.apiList.UserSpecificReport, { IdUser: this.shopperVal, StartDate: this.StartDate, EndDate: this.EndDate, DaysOfWeek: newstr, StartTime: this.StartTime, EndTime: this.EndTime, IdZipCode: 0, IdMainCategory: 0, IdSubCategory: 0 }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {
            this.Tlists = response;
            if (this.Tlists.Response == 1) {
              this.Trans = this.Tlists.ReportProducts;

              console.log("console shopper"+JSON.stringify(this.Trans));

              // {"StoreName":"USA","StoreState":"California","BrandName":"Duo Sampling","ProductName":"Indian Herbal Valley Tulsi Powder","SKU":null,"DateRange":null,"TimePeriod":null,"MainCategory":null,"SubCategory":null,"TotalofSampleTaken":1,"Likes":0,"Dislikes":0,"DislikeComments":"","NumberofTimesSentToFriend":0,"SavedToFavorite":"No","ReadyToPurchase":"No","ChainName":"OmiGi","LocationName":"USA","StoreSampled":"OmiGi: USA"}
              
            }
          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }
  }
  listofzipCodeShopper() {
    this.reportShopper = false;
    this.reportCategory = false;
    this.reportZip = true;
    if (this.StartDatetab2 && this.EndDatetab2) {
      if (this.toTimestamp(this.StartDatetab2) <= this.toTimestamp(this.EndDatetab2)) {
        $('#dots_spinner').show();
        var a = this.optionsModeltab2;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        this.http.post<any>(this.apiList.UserSpecificReport, { IdUser: 0, StartDate: this.StartDatetab2, EndDate: this.EndDatetab2, DaysOfWeek: newstr, StartTime: this.StartTimetab2, EndTime: this.EndTimetab2, IdZipCode: this.zipVal, IdMainCategory: 0, IdSubCategory: 0 }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {
            this.Tlists = response;
            if (this.Tlists.Response == 1) {
              this.Trans = this.Tlists.ReportProducts;

            }
          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }
  }



  listofcategoryShopper() {
    this.reportShopper = false;
    this.reportCategory = true;
    this.reportZip = false;



    if (this.StartDatetab3 && this.EndDatetab3) {
      if (this.toTimestamp(this.StartDatetab3) <= this.toTimestamp(this.EndDatetab3)) {
        $('#dots_spinner').show();
        var a = this.optionsModeltab3;
        var newstr = a.toString().replace(/\[.*\]/g, '');

        if (this.IdSubCategory == "None" || this.IdSubCategory == null) {
          this.IdSubCategory = 0;
        }

        this.http.post<any>(this.apiList.UserSpecificReport, { IdUser: 0, StartDate: this.StartDatetab3, EndDate: this.EndDatetab3, DaysOfWeek: newstr, StartTime: this.StartTimetab3, EndTime: this.EndTimetab3, IdZipCode: 0, IdMainCategory: this.IdMainCategory, IdSubCategory: this.IdSubCategory }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {
            this.Tlists = response;
            if (this.Tlists.Response == 1) {
              this.Trans = this.Tlists.ReportProducts;
            }
          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }
  }

  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  listofmachine() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.PlaceMachineLstUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mposts = response.lst_sp_GetPlacedMachinesWithLocation;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  listofmachinetab2() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.PlaceMachineLstUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mpoststab2 = response.lst_sp_GetPlacedMachinesWithLocation;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  listofmachinetab3() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.PlaceMachineLstUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mpoststab3 = response.lst_sp_GetPlacedMachinesWithLocation;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }



  onChangeMachine(id: any) {
    id = id.map(function (e) { return JSON.stringify(e); });
    id = id.join(",");
    this.IdMachine = id;
  }

  onChangeMachinetab2(id: any) {
    id = id.map(function (e) { return JSON.stringify(e); });
    id = id.join(",");
    this.IdMachinetab2 = id;
  }

  onChangeMachinetab3(id: any) {
    id = id.map(function (e) { return JSON.stringify(e); });
    id = id.join(",");
    this.IdMachinetab3 = id;
  }
  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDate = $('#S1Date').val();
  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDate = $('#E2Date').val(); }

  addEvent1tab2(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDatetab2 = $('#S1Datetab2').val();
  }

  addEvent1tab3(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDatetab3 = $('#S1Datetab3').val();
  }
  addEvent2tab2(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDatetab2 = $('#E2Datetab2').val(); }

  addEvent2tab3(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDatetab3 = $('#E2Datetab3').val(); }
  addEvent3(type: string, event: MatDatepickerInputEvent<Date>) { this.StartDate = $('#S3Date').val(); }
  addEvent4(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDate = $('#E4Date').val(); }

  Searchshopper() {
    $("#salestable tr:gt(0)").remove();
    this.reportShopper = true;
    this.reportCategory = false;
    this.reportZip = false;
    this.Tlists = [];
    this.Trans = [];
  }

  SearchZip() {
    $("#salestable tr:gt(0)").remove();
    this.reportShopper = false;
    this.reportCategory = false;
    this.reportZip = true;
    this.Tlists = [];
    this.Trans = [];
  }
  SearchCategory() {
    $("#salestable tr:gt(0)").remove();
    this.reportShopper = false;
    this.reportCategory = true;
    this.reportZip = false;
    this.Tlists = [];
    this.Trans = [];
  }




  onChangebrand(value) {
    this.IdBrand = value;
    this.IdProducts = "";
    this.selectedProduct = "";
    this.listofproduct();

  }

  onChangebrandtab2(value) {
    this.IdBrandtab2 = value;
    this.IdProductstab2 = "";
    this.selectedProducttab2 = null;
    this.listofproducttab2();

  }

  onChangebrandtab3(value) {
    this.IdBrandtab3 = value;
    this.IdProductstab3 = "";
    this.selectedProducttab3 = null;
    this.listofproducttab3();

  }


  Search() {
    $("#salestable tr:gt(0)").detach();
    this.reportBrand = false;
    this.Tlists = [];
    this.Trans = [];
  }

  selectAllmachine(id) {
    $('#checkallmac').addClass("btn_active");
    $('#uncheckallmac').removeClass("btn_active");
    if (id == 'machine') {
      this.arraymachine = [];
      for (let i = 0; i < this.Mposts.length; i++) {
        this.arraymachine.push(this.Mposts[i].IdMachine);
      }
      this.OnlyselectedMachine = this.arraymachine;
      this.IdMachines = this.OnlyselectedMachine.toString();
    } else if (id == 'product') {
      this.arrayproduct = [];
      for (let i = 0; i < this.Plistss.length; i++) {
        this.arrayproduct.push(this.Plistss[i].IdProduct);
      }
      this.selectedProduct = this.arrayproduct;
      this.IdProducts = this.selectedProduct.toString();

    }

    else if (id == 'shopper') {
      this.arrayshopper = [];
      for (let i = 0; i < this.shopperlistdata.length; i++) {
        this.arrayshopper.push(this.shopperlistdata[i].IdShopper);
      }
      this.selectedShopper = this.arrayshopper;
      this.IdShoppers = this.selectedShopper.toString();
    }

  }

  selectAllmachinetab2(id) {
    $('#checkallmactab2').addClass("btn_active");
    $('#uncheckallmactab2').removeClass("btn_active");
    if (id == 'machine') {
      this.arraymachinetab2 = [];
      for (let i = 0; i < this.Mpoststab2.length; i++) {
        this.arraymachinetab2.push(this.Mpoststab2[i].IdMachine);
      }
      this.OnlyselectedMachinetab2 = this.arraymachinetab2;
      this.IdMachinestab2 = this.OnlyselectedMachinetab2.toString();
    } else if (id == 'product') {
      this.arrayproducttab2 = [];
      for (let i = 0; i < this.Plistsstab2.length; i++) {
        this.arrayproducttab2.push(this.Plistsstab2[i].IdProduct);
      }
      this.selectedProducttab2 = this.arrayproducttab2;
      this.IdProductstab2 = this.selectedProducttab2.toString();

    }

  }

  selectAllmachinetab3(id) {
    $('#checkallmactab3').addClass("btn_active");
    $('#uncheckallmactab3').removeClass("btn_active");
    if (id == 'machine') {
      this.arraymachinetab3 = [];
      for (let i = 0; i < this.Mpoststab3.length; i++) {
        this.arraymachinetab3.push(this.Mpoststab3[i].IdMachine);
      }
      this.OnlyselectedMachinetab3 = this.arraymachinetab3;
      this.IdMachinestab3 = this.OnlyselectedMachinetab3.toString();
    } else if (id == 'product') {
      this.arrayproducttab3 = [];
      for (let i = 0; i < this.Plistsstab3.length; i++) {
        this.arrayproducttab3.push(this.Plistsstab3[i].IdProduct);
      }
      this.selectedProducttab3 = this.arrayproducttab3;
      this.IdProductstab3 = this.selectedProducttab3.toString();

    }

  }



  deselectAllmachine(id) {
    $('#checkallmac').removeClass("btn_active");
    $('#uncheckallmac').addClass("btn_active");
    if (id == 'machine') {
      this.OnlyselectedMachine = '';
      this.IdMachines = '';
    } else if (id == 'product') {
      this.selectedProduct = '';
      this.IdProducts = '';
    }
    else if (id == 'shopper') {
      this.selectedShopper = '';
      this.IdShoppers = '';
    }
  }

  deselectAllmachinetab2(id) {
    $('#checkallmactab2').removeClass("btn_active");
    $('#uncheckallmactab2').addClass("btn_active");
    if (id == 'machine') {
      this.OnlyselectedMachinetab2 = '';
      this.IdMachinestab2 = '';
    } else if (id == 'product') {
      this.selectedProducttab2 = '';
      this.IdProductstab2 = '';
    }
  }

  deselectAllmachinetab3(id) {
    $('#checkallmactab3').removeClass("btn_active");
    $('#uncheckallmactab3').addClass("btn_active");
    if (id == 'machine') {
      this.OnlyselectedMachinetab3 = '';
      this.IdMachinestab3 = '';
    } else if (id == 'product') {
      this.selectedProducttab3 = '';
      this.IdProductstab3 = '';
    }
  }


  brandbtn() { this.router.navigate([this.companyName + '/dashboard/samplingreport']); }
  shopperreport() { this.router.navigate([this.companyName + '/dashboard/samplingreport/shopper']); }
  storebtn() { this.router.navigate([this.companyName + '/dashboard/samplingreport/store']); }

  selectAll(id) {
    $('#checkall').addClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
    if (id == 'location') {
      this.nextLibAvailable = true;

      this.array = [];
      for (let i = 0; i < this.posts.length; i++) {
        this.array.push(this.posts[i].IdLocation);
      }
      this.selectedlocation = this.array;
      this.Idlocation = this.selectedlocation.toString();
      this.machinelistist()
    } else if (id == 'machine') {
      this.array = [];
      for (let i = 0; i < this.Mlists.length; i++) {
        this.array.push(this.Mlists[i].IdMachine);
      }
      this.selectedMachine1 = this.array;
      this.IdMachine = this.selectedMachine1.toString();
    } else if (id == 'machine2') {
      this.array = [];
      for (let i = 0; i < this.Mposts.length; i++) {
        this.array.push(this.Mposts[i].IdMachine);
      }
      this.OnlyselectedMachine = this.array;
      this.IdMachine = this.OnlyselectedMachine.toString();
    }
  }

  selectAlltab2(id) {
    $('#checkalltab2').addClass("btn_active");
    $('#uncheckalltab2').removeClass("btn_active");
    if (id == 'location') {
      this.nextLibAvailabletab2 = true;

      this.arraytab2 = [];
      for (let i = 0; i < this.poststab2.length; i++) {
        this.arraytab2.push(this.poststab2[i].IdLocation);
      }
      this.selectedlocationtab2 = this.arraytab2;
      this.Idlocationtab2 = this.selectedlocationtab2.toString();
      this.machinelististtab2()
    } else if (id == 'machine') {
      this.arraytab2 = [];
      for (let i = 0; i < this.Mliststab2.length; i++) {
        this.arraytab2.push(this.Mliststab2[i].IdMachine);
      }
      this.selectedMachine1tab2 = this.arraytab2;
      this.IdMachinetab2 = this.selectedMachine1tab2.toString();
    } else if (id == 'machine2') {
      this.arraytab2 = [];
      for (let i = 0; i < this.Mpoststab2.length; i++) {
        this.arraytab2.push(this.Mpoststab2[i].IdMachine);
      }
      this.OnlyselectedMachinetab2 = this.array;
      this.IdMachinetab2 = this.OnlyselectedMachinetab2.toString();
    }
  }

  selectAlltab3(id) {
    $('#checkalltab3').addClass("btn_active");
    $('#uncheckalltab3').removeClass("btn_active");
    if (id == 'location') {
      this.nextLibAvailabletab3 = true;

      this.arraytab3 = [];
      for (let i = 0; i < this.poststab3.length; i++) {
        this.arraytab3.push(this.poststab3[i].IdLocation);
      }
      this.selectedlocationtab3 = this.arraytab3;
      this.Idlocationtab3 = this.selectedlocationtab3.toString();
      this.machinelististtab3()
    } else if (id == 'machine') {
      this.arraytab3 = [];
      for (let i = 0; i < this.Mliststab3.length; i++) {
        this.arraytab3.push(this.Mliststab3[i].IdMachine);
      }
      this.selectedMachine1tab3 = this.arraytab3;
      this.IdMachinetab3 = this.selectedMachine1tab3.toString();
    } else if (id == 'machine2') {
      this.arraytab3 = [];
      for (let i = 0; i < this.Mpoststab3.length; i++) {
        this.arraytab3.push(this.Mpoststab3[i].IdMachine);
      }
      this.OnlyselectedMachinetab3 = this.array;
      this.IdMachinetab3 = this.OnlyselectedMachinetab3.toString();
    }
  }

  deselectAll(id) {
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').addClass("btn_active");
    if (id == 'location') {
      this.selectedlocation = '';
      this.Idlocation = '';
      this.selectedMachine1 = "";
      this.IdMachine = "";
      this.machinelistist()
    }
    else if (id == 'machine') {
      this.selectedMachine1 = '';
      this.IdMachine = '';
    }
    else if (id == 'machine2') {
      this.OnlyselectedMachine = '';
      this.IdMachine = '';
    }
  }
  deselectAlltab2(id) {
    $('#checkalltab2').removeClass("btn_active");
    $('#uncheckalltab2').addClass("btn_active");
    if (id == 'location') {
      this.selectedlocationtab2 = '';
      this.Idlocationtab2 = '';
      this.selectedMachine1tab2 = "";
      this.IdMachinetab2 = "";
      this.machinelististtab2()
    }
    else if (id == 'machine') {
      this.selectedMachine1tab2 = '';
      this.IdMachinetab2 = '';
    }
    else if (id == 'machine2') {
      this.OnlyselectedMachinetab2 = '';
      this.IdMachinetab2 = '';
    }
  }

  deselectAlltab3(id) {
    $('#checkalltab3').removeClass("btn_active");
    $('#uncheckalltab3').addClass("btn_active");
    if (id == 'location') {
      this.selectedlocationtab3 = '';
      this.Idlocationtab3 = '';
      this.selectedMachine1tab3 = "";
      this.IdMachinetab3 = "";
      this.machinelististtab3()
    }
    else if (id == 'machine') {
      this.selectedMachine1tab3 = '';
      this.IdMachinetab3 = '';
    }
    else if (id == 'machine2') {
      this.OnlyselectedMachinetab3 = '';
      this.IdMachinetab3 = '';
    }
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }


  exportShopper() {


    $('#dots_spinner').show();
    this.reportBrand = true;
    if (this.StartDate && this.EndDate) {
      if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {
        $('#dots_spinner').show();
        var a = this.optionsModel;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        this.http.post<any>(this.apiList.ExportUserReport, { IdUser: this.shopperVal, StartDate: this.StartDate, EndDate: this.EndDate, DaysOfWeek: newstr, StartTime: this.StartTime, EndTime: this.EndTime, IdZipCode: 0, IdMainCategory: 0, IdSubCategory: 0 }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {
            let result = response;
            if (result.Response == 1) {
              let FileName = result.FileName
              window.location.href = this.CSVFileUrl + '/' + FileName;
            }

          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }


  }


  exportZipShopper() {


    $('#dots_spinner').show();
    this.reportBrand = false;
    if (this.StartDatetab2 && this.EndDatetab2) {
      if (this.toTimestamp(this.StartDatetab2) <= this.toTimestamp(this.EndDatetab2)) {
        $('#dots_spinner').show();
        var a = this.optionsModeltab2;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        this.http.post<any>(this.apiList.ExportZipCodeReport, { IdUser: 0, StartDate: this.StartDatetab2, EndDate: this.EndDatetab2, DaysOfWeek: newstr, StartTime: this.StartTimetab2, EndTime: this.EndTimetab2, IdZipCode: this.zipVal, IdMainCategory: 0, IdSubCategory: 0 }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {
            let result = response;
            if (result.Response == 1) {
              let FileName = result.FileName
              window.location.href = this.CSVFileUrl + '/' + FileName;
            }

          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }


  }


  exportCategory() {


    $('#dots_spinner').show();
    this.reportBrand = false;
    if (this.StartDatetab3 && this.EndDatetab3) {
      if (this.toTimestamp(this.StartDatetab3) <= this.toTimestamp(this.EndDatetab3)) {
        $('#dots_spinner').show();
        var a = this.optionsModeltab3;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        if (this.IdSubCategory == "None" || this.IdSubCategory == null) {
          this.IdSubCategory = 0;
        }
        this.http.post<any>(this.apiList.ExportZipCodeReport, { IdUser: 0, StartDate: this.StartDatetab3, EndDate: this.EndDatetab3, DaysOfWeek: newstr, StartTime: this.StartTimetab3, EndTime: this.EndTimetab3, IdZipCode: 0, IdMainCategory: this.IdMainCategory, IdSubCategory: this.IdSubCategory }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {
            let result = response;
            if (result.Response == 1) {
              let FileName = result.FileName
              window.location.href = this.CSVFileUrl + '/' + FileName;
            }

          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }


  }

  PrintrouteShooper() {
    $('#dots_spinner').show();
    this.reportBrand = true;
    if (this.StartDate && this.EndDate) {
      if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {
        $('#dots_spinner').show();
        var a = this.optionsModel;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        this.http.post<any>(this.apiList.PrintUserReport, { IdUser: this.shopperVal, StartDate: this.StartDate, EndDate: this.EndDate, DaysOfWeek: newstr, StartTime: this.StartTime, EndTime: this.EndTime, IdZipCode: 0, IdMainCategory: 0, IdSubCategory: 0 }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {

            var data = response;
            if (data.Response == 1) {
              data = data.HtmlText
              var popupWinindow = window.open('', '_blank', 'width=1366, height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
              popupWinindow.document.open();
              popupWinindow.document.write('<html><head></head><body onload="window.print()" onafterprint="window.close()">' + data + '</html>');
              popupWinindow.document.close();
            }

          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }



  }


  PrintrouteZip() {
    this.reportCategory = false;
    this.reportShopper = false;
    this.reportZip = true;
    if (this.StartDatetab2 && this.EndDatetab2) {
      if (this.toTimestamp(this.StartDatetab2) <= this.toTimestamp(this.EndDatetab2)) {
        $('#dots_spinner').show();
        var a = this.optionsModeltab2;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        this.http.post<any>(this.apiList.PrintZipCodeSpecificReport, { IdUser: 0, StartDate: this.StartDatetab2, EndDate: this.EndDatetab2, DaysOfWeek: newstr, StartTime: this.StartTimetab2, EndTime: this.EndTimetab2, IdZipCode: this.zipVal, IdMainCategory: 0, IdSubCategory: 0 }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {

            var data = response;

            if (data.Response == 1) {
              data = data.HtmlText
              var popupWinindow = window.open('', '_blank', 'width=1366, height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
              popupWinindow.document.open();
              popupWinindow.document.write('<html><head></head><body onload="window.print()" onafterprint="window.close()">' + data + '</html>');
              popupWinindow.document.close();
            }

          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }



  }
  PrintrouteCategory() {
    this.reportCategory = true;
    this.reportShopper = false;
    this.reportZip = false;
    if (this.StartDatetab3 && this.EndDatetab3) {
      if (this.toTimestamp(this.StartDatetab3) <= this.toTimestamp(this.EndDatetab3)) {
        $('#dots_spinner').show();
        var a = this.optionsModeltab3;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        if (this.IdSubCategory == "None" || this.IdSubCategory == null) {
          this.IdSubCategory = 0;
        }
        this.http.post<any>(this.apiList.PrintZipCodeSpecificReport, { IdUser: 0, StartDate: this.StartDatetab3, EndDate: this.EndDatetab3, DaysOfWeek: newstr, StartTime: this.StartTimetab3, EndTime: this.EndTimetab3, IdZipCode: 0, IdMainCategory: this.IdMainCategory, IdSubCategory: this.IdSubCategory }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {

            var data = response;
            if (data.Response == 1) {
              data = data.HtmlText
              var popupWinindow = window.open('', '_blank', 'width=1366, height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
              popupWinindow.document.open();
              popupWinindow.document.write('<html><head></head><body onload="window.print()" onafterprint="window.close()">' + data + '</html>');
              popupWinindow.document.close();
            }

          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }



  }

  onShopperChange(shoppervalue) {
    if (shoppervalue) {
      this.shopperVal = shoppervalue.value;
    }
  }

  onZipCodeChange(zipValue) {
    if (zipValue) {
      this.zipVal = zipValue.value;
    }
  }




  getShopper(search) {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.SearchShopper, { Name: search }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {

        var data = response;
        if (data.Response == 1) {
          return response;
        }

      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }


  onKeypressedZip(value) {
    this.zipVal = null;
    this.zipListsFiltered = this.searchValueZip(value);
  }

  searchValueZip(value: string) {
    let filter = value.toLowerCase();
    return this.zipLists.filter(option => option.ZipCode.toLowerCase().includes(filter));
  }


  //category

  onChangemain(id: string) {
    if (id != 'None') {
      $('#dots_spinner').show();
      this.IdMainCategory = id;
      this.http.post<any>(this.apiList.SubCategoryListUrl, { IdMainCategory: this.IdMainCategory, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (result.Response !== 1) throw result;
          return result;
        }).subscribe((response) => {
          $('#dots_spinner').hide();
          if (response.Response == 1) {
            this.names = response;
            // console.log(this.names.length);
            this.names = this.names.lst_mc
            this.subcat_length = this.names.length;
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
        });
    }
    else {
      this.subcat_length = 0;
      this.IdMainCategory = null;
      this.IdSubCategory = null
      this.idS = '';
    }
  }

  categorylist() {
    this.http.post<any>(this.apiList.CategoryListUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.postscat = response;
          this.postscat = this.postscat.lst_mc;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      });
  }
  onChangesub(id1: string) {
    if (id1) {
      this.IdSubCategory = id1;
    } else {
      this.IdSubCategory = null;
    }
  }





}
