import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material/sort';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
import { Subject } from 'rxjs';
declare const $;
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  BaseFileUrl = environment.BaseFileUrl;
  ImageUrl = this.BaseFileUrl + "/ClientDocument/ThumbnailImages/";

  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));

  mainvar: string;
  posts: any;
  Name = "";
  Price: any;
  Search: any;
  p: any;
  SKU = "";
  Supplier = "";
  IdMainCategory = "";
  IdSubCategory = "";
  Cost = "";
  SalesTax = "";
  CreatedBy = "";
  IdProduct: any;
  MainCategoryName: string;
  SubCategoryName: string;
  MainImage = "";
  NutritionalFacts = "";
  AlternateImage1 = "";
  AlternateImage2 = "";
  AlternateImage3 = "";
  details: any;
  main = "";
  nutri = "";
  alt1 = "";
  alt2 = "";
  alt3 = "";
  CurrencyType
  AlertText
  pageno: number = 1;
  before: number;
  TotalCount: any = 1;
  ll: any;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate


  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public apiList: ApilistService) {
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
    this.refreshlistproduct();
  }
  ngOnInit() {
    $('#dots_spinner').hide();
    $("#alert_div").hide();
  }

  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
      //console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  refreshlistproduct() {
    $('#dots_spinner').show();
    let url1 = `${this.apiList.ProductLstUrl}`;
    this.http.post<any>(url1, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        this.posts = response;
        this.posts = this.posts.lst_Product;
        this.TotalCount = this.posts.TotalCount;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 4) {
          this.posts = err;
          this.posts = err.lst_Product === null ? [] : null;
          this.TotalCount = err.TotalCount;
          return;
        }
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }

      }).add(() => {
        $('#dots_spinner').hide();
        if (this.Resizing) {
          // need to give time to render behind the scenes before size/resize rows	          // need to give time to render behind the scenes before size/resize rows
          this.SettleAndAutosize(500);
        }
      });
  }

  productdetails(id: number) {
    $('#dots_spinner').show();
    let url1 = `${this.apiList.ProductDetailsUrl}`;
    this.http.post<any>(url1, { IdProduct: id }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        this.details = response;
      
        this.details = this.details.lst_ProductRecord;
        //console.log("productdetails"+JSON.stringify(this.details));
        //[{"IdProduct":769,"Name":"okkk","Price":"1","SKU":"tesrtsku","Supplier":null,"IdMainCategory":274,"IdSubCategory":199,"CurrencyType":"$","SalesTax":true,"CreatedBy":113,"CreatedDate":"2021-05-11T04:35:08.173","IsActive":null,"MainImage":"Product_MainImage_769.jpg","NutritionalFacts":null,"AlternateImage1":null,"AlternateImage2":null,"AlternateImage3":null,"ProductDescription":"<p>ok</p>","isDeleted":false,"Filelength":113742,"FileMD5Sum":"80BA8F3B1790AE67A0AF9268E1B698B3","NutritionalFilelength":0,"NutritionalFileMD5Sum":null,"Alternate1Filelength":0,"Alternate1FileMD5Sum":null,"Alternate2Filelength":0,"Alternate2FileMD5Sum":null,"Alternate3Filelength":0,"Alternate3FileMD5Sum":null,"MainImageURL":"https://dev.vend4you.com/ClientDocument/Product_MainImage_769.jpg","NutritionalFactsURL":null,"AlternateImage1URL":null,"AlternateImage2URL":null,"AlternateImage3URL":null,"IsSample":true,"IdBrand":442,"CertificationLogo":"Product_CertificationLogo_769.jpg","CertificationLogoURL":"https://dev.vend4you.com/ClientDocument/Product_CertificationLogo_769.jpg","CertificationLogoFilelength":113742,"CertificationLogoFileMD5Sum":"80BA8F3B1790AE67A0AF9268E1B698B3","AllergenInformation":"<p>ok</p>","Ingredients":"<p>ok</p>"}]
        $("#btn_close_popup").trigger("click");
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  Tax(id) {
    sessionStorage.setItem("referproductid", id);
    this.router.navigate([this.companyName + '/dashboard/product/Salestax']);
  }

  getID(id: number) {
    this.IdProduct = id;
  }

  add() {
    this.router.navigate([this.companyName + '/dashboard/product/addproduct']);
  }

  imageclick() {
  }

  edit(id: string) {
    // this.IdProduct = id;
    // sessionStorage.setItem("refrenceid", this.IdProduct);
    this.router.navigate([this.companyName + '/dashboard/product/Updateproduct', {
      id: id
    }]);
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  delete() {
    $('#dots_spinner').show();
    let url = `${this.apiList.DeleteProductUrl}`;
    //console.log(this.IdProduct);
    this.http.post<any>(url, { IdProduct: this.IdProduct, CreatedBy: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        this.refreshlistproduct();
        this.FlashError("Succesfully deleted");
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  sortData(sort: Sort) {
    const data = this.posts.slice();
    if (!sort.active || sort.direction === '') {
      this.posts = data;
      return;
    }
    this.posts = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Product_name': return this.compare(a.Name.toLowerCase(), b.Name.toLowerCase(), isAsc);
        case 'Product_price': return this.compare(a.Price, b.Price, isAsc);
        case 'Product_category': return this.compare(a.MainCategoryName.toLowerCase(), b.MainCategoryName.toLowerCase(), isAsc);
        case 'Product_supplier': return this.compare(a.Supplier.toLowerCase(), b.Supplier.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  clearSearch() {
    this.Search = '';
    this.pageno = 1;
    this.refreshlistproduct();
  }

  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }
}
