import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiCacheStorageService } from './api-cache-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiCacheService implements HttpInterceptor {

  constructor(private Cache: ApiCacheStorageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let parts = req.url.split("/");
    var lastPart = parts[parts.length-1];

    console.log(`Intercept ${req.url} lastPart = ${lastPart}`);

    // reset cache for login/out URLs.
    switch (lastPart)
    {
      case "Login":
      case "Logout":
      // case "Impersonate":
      // case "BackToSuperAdmin":
        this.Cache.Clear();
        return next.handle(req);
    }

    // set cache headers for some messages
    switch (lastPart) {
      // put more cases after TimeZoneList. you can let each case all through to the
      case "TimeZoneList":  
        req = req.clone({
          headers: req.headers.set('cache-control', 'public, max-age-84600')
        });
        break;

      default:
        break;
    }

    // only intercept POSTs unless it's one of our specials.
    if (req.method !== 'POST') return next.handle(req);

    let hash: string;
    let body: any;

    // we only handle json. If parse fails, just pass through.
    try {      
      body = req.body;
    } catch (e) {
      console.log(`Failed to decode body of POSTed ${lastPart}`);
      return next.handle(req);
    }

    switch (lastPart)
    {
      // put special handling here. We need to compute a hash.
      // usually this code that mashes down the URL and critical parameters.

      case 'GetMerchantDetailsById'      : hash = `${lastPart}_${body.IdMerchant}`; break;
      case 'PermissionDetailByMerchantId': hash = `${lastPart}_${body.IdMerchant}`; break;
      case 'GetStatesListByCountryId' : hash = `${lastPart}_${body.IdCountry}`; break;
      case 'GetCityListByStateId' : hash = `${lastPart}_${body.IdState}`; break;
      default:

        // by default, no caching
        return next.handle(req);
    }

    // we have a hash code... handle from cache, if possible
    var o = this.Cache.Lookup(hash);
    if (o !== null) {
      console.log(`Cache hit (success) for ${hash}`);
      return of(o);
    }

    return next.handle(req)  
      .pipe(  
        tap(event => {  

          if (event instanceof HttpResponse) {  

            // don't cache failures
            if (!event.ok) return;

            // check body for Vend4You app-level failures, and don't cache those either.
            try {
              var result = event.body;
              if (result.Response != null && result.Response !== 1) return;
            } catch (e) {
              console.log(`Failed to JSON decode response to ${hash}`);
            }

            console.log(`Adding item to cache: ${hash}`);
            this.Cache.Set(hash, event);
          }  
        })  
      );

  }

}
