import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from '../../../../../environments/environment';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../../../../global.service';
import { ApilistService } from '../../../../../service/Api/apilist.service';
declare const $;

@Component({
  selector: 'app-updateplaceloop',
  templateUrl: './updateplaceloop.component.html',
  styleUrls: ['./updateplaceloop.component.css']
})

export class UpdateplaceloopComponent implements OnInit {
  @ViewChild('updateplace', { static: false }) public updateplaceForm: NgForm;
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
  IdPlaceCampaign: any;
  array: any[];
  PlaceCampaignName;
  selectedMachines: number[] = [];
  selectedCampaign;
  Original;
  StartTime = '00:01 AM';
  EndTime = '11:59 PM';
  Campaignlists;
  Mlists;
  CampaignName;
  CampaignDuration;
  CampaignStartDate;
  STime;
  Etime;
  StartDate;
  EndDate;
  CampaignEndDate;
  Mdetails;
  details;
  posts;
  AlertText;

  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    private route: ActivatedRoute,
    public apiList: ApilistService) {
    this.route.params.subscribe((get) => {
      this.PlaceCampaignName = get.placeCampaignName;
      this.getplaceloopdetail();
    });
  }

  ngOnInit() {
    $('#alert_div').hide();
    $('body').on('change', '#timepicker1', function () {
      $('#timepicker1').trigger('click');
    });
    $('#timepicker1').timepicker({
      timeFormat: 'HH:mm:ss',
    });
    $('body').on('change', '#timepicker2', function () {
      $('#timepicker2').trigger('click');
    });
    $('#timepicker1').timepicker();
    $('#timepicker2').timepicker();

    this.getmachinelist();
    this.Campaignlist();
  }

  Campaignlist() {
    this.http.post<any>(this.apiList.CampaignlistApi, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.Campaignlists = response;
          this.Campaignlists = this.Campaignlists.lst_GetListOfCampaign;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }


  getplaceloopdetail() {
    this.http.post<any>(this.apiList.PlaceCampaignDetailApi,
      { PlaceCampaignName: this.PlaceCampaignName }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.Original = response.obj_PlaceCampaign;
        this.PlaceCampaignName = this.Original.PlaceCampaignName;
        this.selectedMachines = [...this.Original.IdMachines];
        this.selectedCampaign = this.Original.IdCampaign;
        if (this.selectedCampaign) {
          this.changeCampaign(this.selectedCampaign);
        }
        this.StartTime = this.Original.StartTime;
        this.EndTime = this.Original.EndTime;
      });
  }

  timeconversion(Time) {
    const time = Time;
    let hours = Number(time.match(/^(\d+)/)[1]);
    const minutes = Number(time.match(/:(\d+)/)[1]);
    const AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === 'PM' && hours < 12) { hours = hours + 12; }
    if (AMPM === 'AM' && hours === 12) { hours = hours - 12; }
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) { sHours = '0' + sHours; }
    if (minutes < 10) { sMinutes = '0' + sMinutes; }
    return sHours + ':' + sMinutes + ':' + '00';
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDate = $('#SDate').val();
  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    this.EndDate = $('#EDate').val();
  }

  getmachinelist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachinelistApi, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.Mlists = response;
        this.Mlists = this.Mlists.lst_masters;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/digitalsignage/placeloop']);
  }

  changemachine(ids) {
    $('#checkall').removeClass('btn_active');
    ids = ids.map(function (e) {
      return +e;
    });
    this.selectedMachines = [...ids];
  }

  selectAll() {
    $('#checkall').addClass('btn_active');
    $('#uncheckall').removeClass('btn_active');
    this.array = [];
    for (let i = 0; i < this.Mlists.length; i++) {
      this.array.push(this.Mlists[i].IdMachine);
    }
    this.selectedMachines = [...this.array];
  }

  deselectAll() {
    $('#checkall').removeClass('btn_active');
    $('#uncheckall').addClass('btn_active');
    this.selectedMachines = [];
    //  }
  }
  save() {
    const CurrentDate = new Date();
    this.http.post<any>(this.apiList.UpdatePlaceCampaignApi,
      {
        IdPlaceCampaign: this.IdPlaceCampaign,
        IdCampaign: this.selectedCampaign,
        IdMachines: [...this.selectedMachines],
        StartDateTime: this.CampaignStartDate,
        StartTime: this.StartTime,
        EndDateTime: this.CampaignEndDate,
        EndTime: this.EndTime,
        OldCampaignName: this.Original.PlaceCampaignName,
        PlaceCampaignName: this.PlaceCampaignName,
        IdMerchant: this.IdMerchant,
        CurrentDate: CurrentDate
      },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.posts = response;
        if (this.posts.Response === 1) {
          this.router.navigate([this.companyName + '/dashboard/digitalsignage/placeloop']);
        } else {
          this.FlashError(this.posts.ErrorMessage);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  changeCampaign(id) {
    this.selectedCampaign = id;
    this.http.post<any>(this.apiList.CampaignDetailApi, { IdCampaign: this.selectedCampaign }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.details = response;
        if (this.details.Response === 1) {
          this.CampaignDuration = this.details.Obj_CampaignClass.CampaignDuration;
          this.CampaignStartDate = this.details.Obj_CampaignClass.StringCampaignStartDate;
          this.CampaignEndDate = this.details.Obj_CampaignClass.StringCampaignEndDate;
          $('#SDate').val(this.CampaignStartDate);
          $('#EDate').val(this.CampaignEndDate);
        } else if (this.details.Response === 2) {
          this.FlashError(this.details.ErrorMessage);
        } else {
          this.FlashError(this.details.ErrorMessage);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}
