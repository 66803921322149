import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { ApilistService } from '../service/Api/apilist.service';

@Injectable()
export class RouterService {
  baseUrl = environment.baseUrl;

  
  IdMerchant: number = parseInt(sessionStorage.getItem("referMerchantId"));
  details
  public MachineVisible:boolean
  public CategoryVisible:boolean
  public ProductVisible:boolean
  
  permissionlst=[];
  constructor(public apiList: ApilistService,private http: HttpClient,private globalservice: GlobalService) { 
    
  }

  Permissiondetailrouter() {

    return new Promise((resolve, reject) => {
      this.http.post<any>(this.apiList.PermissionDetailUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
        .map(resp => {
          if (resp == null) resp = {Response: 0, ErrorMessage: "No response"};
          if (+resp.Response != 1) {
            throw(resp);
          }
          return resp;
        })
        .subscribe(response => {
          resolve(response);
        }, err => {
          resolve(err);
        }
        );
    });

  }
}
