import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Sort } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { NgForm } from "@angular/forms";
import { MatInput } from '@angular/material';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
declare const $;

@Component({
  selector: 'app-addspecialpricing',
  templateUrl: './addspecialpricing.component.html',
  styleUrls: ['./addspecialpricing.component.css']
})
export class AddspecialpricingComponent implements OnInit {
  @ViewChild('machineproduct',{static: false}) public SpecialPriceForm: NgForm;
  @ViewChild('input1', { read: MatInput, static: false }) input1: MatInput;
  @ViewChild('input2', { read: MatInput, static: false }) input2: MatInput;

  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));

  error = 0;
  SpecialPricingRule = "";
  AlertText;
  time;
  StartTime;
  EndTime;
  Date;
  StartDate='12:00 AM';
  EndDate='12:00 AM';
  postdata;
  DiscountType: any;
  SpecialPricingType = 'MachineProducts';
  DiscountAmount: any;
  IdProducts: any = 0;
  IdMachines: any = 0;
  discount_show = 1;
  Dis_amount;
  mlists = [];
  nextLibAvailable = false;
  Mlists
  selectedProduct2: any;
  productlenght
  array: any;
  selectedMachineproduct: any;

  cacheProducts = [];
  discount_minValueError = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    title: Title,
    private globalservice: GlobalService,
    public apiList: ApilistService
  ) { }

  ngOnInit() {
    this.HidePopups()
    this.dateTimefunction()
    this.Date = new Date();
    this.listofmachine()
  }

  HidePopups(){
        $("#alert_div").hide();
    $('#dots_spinner').hide();
  }

  dateTimefunction(){
      $(document).ready(function () {
      $('#timepicker1').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12"
      });
      $('#timepicker2').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12"
      });
    });
    $('body').on('change', '#timepicker1', function () {
      $('#timepicker1').trigger('click');
    });
    $('body').on('change', '#timepicker2', function () {
      $('#timepicker2').trigger('click');
    });
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDate = $('#MSPDate').val();
  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    //this.EndDate = $('#MSPDate').val();
    // alert(this.EndDate);
  }
  addEvent3(type: string, event: MatDatepickerInputEvent<Date>) {
    this.EndDate = $('#MEPDate').val();
    //alert(this.StartDate);
  }
  addEvent4(type: string, event: MatDatepickerInputEvent<Date>) {
    //this.EndDate = $('#MEPDate').val();
  }

  startTime(time: string) {
    this.StartTime = time;
  }
  endTime(tim: string) {
    this.EndTime = tim;
  }

  listofmachine() {
    if (this.IdMerchant) {
      $('#dots_spinner').show();
      let url1 = `${this.apiList.GetPlaceMachinesForSpecialPrice}`;
      this.http.post<any>(url1, { IdMerchant: this.IdMerchant }, this.globalservice.Headers()).pipe(
        switchMap(((response) => {      
        if (response.Response == 1) {
          this.mlists = response.lst_sp_GetPlacedMachinesWithLocation;
          let array = [];
          for (let i = 0; i < this.mlists.length; i++) {
            array.push(this.mlists[i].IdMachine);
          }
          this.IdMachines = array.toString();
          let url2 = `${this.apiList.ProductLstMachineUrl}`;
          return this.http.post<any>(url2, { IdMachines: this.IdMachines }, this.globalservice.Headers());
        }
      })))  .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
          if (response.Response == 1) {
            this.cacheProducts = response.lst_Product;
          }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        }
        else if(Error.Response === 4){
          return;
                  }
        
        else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
    }
  }

  Special_Pricing_Rule() {
    this.http.post<any>(this.apiList.SpecilPricingUrl, { SpecialPricingRule: this.SpecialPricingRule, IdMerchant: this.IdMerchant, SpecialPricingId :"0" }, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      //console.log(response);
      if (response.Response == 2) {
        this.error = 1;
      }
      //console.log( this.posts);
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
           this.error = 0;
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });

  }

  onChangeMachine(a) {
    if(a){
      this.nextLibAvailable = true;
      $('#uncheckall').removeClass("btn_active");
      $('#checkall').removeClass("btn_active");
      a = a.map(function (e) { return JSON.stringify(e); });
      a = a.join(",");
      this.IdMachines = a;
     // console.log(this.IdMachines);
      this.refreshlistproductMachine();
    }
   
  }

  onChangeProduct2(id2) {
    if(id2){
      $('#uncheckall').removeClass("btn_active");
      $('#checkall').removeClass("btn_active");
      id2 = id2.map(function (e) { return JSON.stringify(e); });
      id2 = id2.join(",");
      this.IdProducts = id2
    //  console.log(this.IdProducts)
    }
   
  }

  refreshlistproductMachine() {
    if (this.IdMachines) {
      let machines = this.IdMachines.split(',');
      let products = [];
      machines.forEach(el => {
        for(let i=0; i<this.cacheProducts.length; i++) {
          if(+el === this.cacheProducts[i].IdMachine) {
            products.push(this.cacheProducts[i]);
          }
        }
      });
      //remove duplicates from products array
      let newArr = [];
      products.forEach((item, index) => {
          if (newArr.findIndex(i => i.IdProduct == item.IdProduct) === -1) {
            newArr.push(item)
          }
      });
      this.Mlists = newArr;
      console.log( this.Mlists);
      this.productlenght = this.Mlists.length;
    } else {
      this.nextLibAvailable = false;
      this.Mlists = [];
      this.selectedProduct2 = '';
    }
  }

  timeconversion(Time) {
    var time = Time;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes + ":" + "00"
  }

  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  selectAll(id) {
      $('#checkall').addClass("btn_active");
      $('#uncheckall').removeClass("btn_active");
    if (id == 'machine') {
      this.array = [];
      for (let i = 0; i < this.mlists.length; i++) {
        this.array.push(this.mlists[i].IdMachine);
      }
      this.selectedMachineproduct = this.array;
      this.IdMachines = this.selectedMachineproduct.toString();
      // console.log(this.IdMachines);
      this.nextLibAvailable = true;
      this.refreshlistproductMachine();
    } else if (id == 'multipleproduct') {
      this.array = [];
      for (let i = 0; i < this.Mlists.length; i++) {
        this.array.push(this.Mlists[i].IdProduct);
      }
      this.selectedProduct2 = this.array;
      this.IdProducts = this.selectedProduct2.toString();
    }
  }

  deselectAll(id) {
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').addClass("btn_active");
    if (id == 'machine') {
      this.selectedMachineproduct = "";
      this.array = [];
      this.IdMachines = "";
      this.refreshlistproductMachine();
    } else if (id == 'multipleproduct') {
      this.selectedProduct2 = "";
      this.array = [];
      this.IdProducts = "";
    }
  }

  discount_reset() {
    this.discount_minValueError = false;
  }

  Apply() {
    if(this.StartTime &&  this.EndTime){
    if (this.error == 1) {
      return this.FlashError("There is already a special price with that name.");
    }
    if(parseFloat(this.DiscountAmount) <= 0) {
      this.discount_minValueError = true;
      return this.FlashError("Discount Amount should be more than 0");
    }
    else {
      this.discount_minValueError = false;
    }

    this.time = this.Date.getHours() + ":" + this.Date.getMinutes() + ":00"
    var date = (this.Date.getMonth() + 1) + '/' + this.Date.getDate() + '/' + this.Date.getFullYear();
    $('#dots_spinner').show();
    if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {
      if (this.timeconversion(this.StartTime) == this.timeconversion("12:00 AM") && this.timeconversion(this.EndTime) == this.timeconversion("12:00 AM")) {
        this.Apply_specialpricing();
      }
      else if (this.toTimestamp(this.StartDate) >= this.toTimestamp(date) && this.toTimestamp(this.EndDate) != this.toTimestamp(date)) {
        if (this.timeconversion(this.StartTime) < this.timeconversion(this.EndTime)) {
          this.Apply_specialpricing();
        }
        else if ((this.timeconversion(this.StartTime) >= this.timeconversion(this.EndTime))) {
          $('#dots_spinner').hide();
          this.FlashError("Start Time Should be less than End Time");
        }
      }
      else if (this.toTimestamp(this.StartDate) == this.toTimestamp(date) && this.toTimestamp(this.EndDate) == this.toTimestamp(date)) {
        if (this.timeconversion(this.StartTime) >= this.time) {
          if (this.timeconversion(this.StartTime) < this.timeconversion(this.EndTime)) {
            // console.log( this.CouponCode, this.DiscountType, this.CouponType, this.DiscountAmount, this.StartDate + " " + this.StartTime, this.EndDate + " " + this.EndTime, this.IdMachines, this.IdProducts);
            this.Apply_specialpricing();
          } else if ((this.timeconversion(this.StartTime) >= this.timeconversion(this.EndTime))) {
            $('#dots_spinner').hide();
            this.FlashError("Start Time Should be less than End Time");
          }
        } else {
          $('#dots_spinner').hide();
          this.FlashError("Start Time Should be greater than Current Time");
        }
      }
    }
    else if (this.toTimestamp(this.StartDate) > this.toTimestamp(this.EndDate)) {
      $('#dots_spinner').hide();
      this.FlashError("Start Date should be less than End Date");
    }
  }else{
      this.FlashError("Please complete time Information");
  }
  }


  Apply_specialpricing() {
    this.postdata = {
      "IdMerchant": this.IdMerchant,
      "DiscountType": this.DiscountType,
      "SpecialPricingType": this.SpecialPricingType,
      "DiscountAmount": this.DiscountAmount,
      "CreatedBy": this.IdMerchant,
      "StartDate": this.StartDate,
      "EndDate": this.EndDate,
      "StartTime": this.StartTime,
      "EndTime": this.EndTime,
      "IdProducts": this.IdProducts,
      "IdMachines": this.IdMachines,
      "SpecialPricingRule": this.SpecialPricingRule,
    }
    let url2 = `${this.apiList.ApplySPUrl}`;
    // var headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    this.http.post<any>(url2, this.postdata, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
    //  console.log(response)
      if (response.Response == 1) {
        $('#dots_spinner').hide();
        this.FlashError("Successfully Applied");
        this.SpecialPriceForm.reset();
        this.router.navigate([this.companyName + '/dashboard/Specialpricing'])
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } 
        else if (Error.Response == 4) {
        var error_rule = Error.ErrorMessage.substr(1);
        this.FlashError("This special price name conflicting with " + error_rule + ". Please select another time duration");
      } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

   FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  type(value) {
    this.discount_show = value;
    this.Dis_amount = true;
    this.DiscountAmount = "";
  //  console.log(value)
  }

  goback(){
    this.router.navigate([this.companyName + '/dashboard/Specialpricing'])
  }


}
