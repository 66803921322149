import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { GlobalService } from './global.service';

@Injectable()
export class SuperAdminGuard implements CanActivate {
  constructor(private user:GlobalService , private router:Router) {
   }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  boolean {
      if(this.user.getUserRole() == 'SuperAdmin'){
        return true;
      }
      this.router.navigate(['login']);
      return false;
    }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
