import { Component, OnInit, HostListener } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../../global.service';
import { environment } from '../../../../environments/environment';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ApilistService } from '../../../../service/Api/apilist.service'
declare const $;
declare var google

@Component({
  selector: 'app-createroute',
  templateUrl: './createroute.component.html',
  styleUrls: ['./createroute.component.css']
})
export class CreaterouteComponent implements OnInit {
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");

  Routeslist: any;
  AlertText: string = '';
  Search: string = '';
  WayPoints: any = [];
  RouteIdentifier: string = '';
  pos: { lat: number; lng: number; };
  lst_Locations = [];
  CompleteRouteslist: any;
  map: any;
  markers: any = [];
  Marker_Lng: string = ''
  Marker_Lat: string = ''
  activeInfoWindow: any;
  searchlength: any;
  bounds: any;
  Route_marker: any;


  constructor(public apiList: ApilistService, private router: Router, private http: HttpClient, private globalservice: GlobalService, private route: ActivatedRoute) {
    this.refreshlocationlist();
  }

  ngOnInit() {
    $("#alert_div").hide();
    $('#dots_spinner').hide();
  }

  Pin_Point() {
    var that = this;
    //console.log('pin point')
    if (this.Routeslist.length > 0) {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: new google.maps.LatLng(this.Routeslist[0].Latitude, this.Routeslist[0].Longitude),
        zoom: 12,
      })
      this.bounds = new google.maps.LatLngBounds();
    }
    else if (this.WayPoints.length > 0) {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: new google.maps.LatLng(this.WayPoints[0].Latitude, this.WayPoints[0].Longitude),
        zoom: 12,
      })
    }
    else {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: this.pos,
        zoom: 12,
      })
    }
    this.setMarkers();
  }

  Focuslocation(a, b) {
    var position = new google.maps.LatLng(a, b);
    this.map.setCenter(position);
  }

  setMarkers() {
    var that = this;
    if (this.Routeslist.length > 0) {
      for (let i = 0; i < this.Routeslist.length; i++) {
       this.Route_marker = new google.maps.Marker({
          map: that.map,
          icon: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
          position: new google.maps.LatLng(this.Routeslist[i].Latitude, this.Routeslist[i].Longitude),
        });
        this.markers.push( this.Route_marker);
        this.bounds.extend(this.Route_marker.position);
        this.Marker_latlng(this.Route_marker, this.Routeslist);
      }
      this.map.fitBounds(this.bounds);
    }
    if (this.WayPoints.length > 0) {
      for (let i = 0; i < this.WayPoints.length; i++) {
        var Way_marker = new google.maps.Marker({
          map: that.map,
          icon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
          position: new google.maps.LatLng(this.WayPoints[i].Latitude, this.WayPoints[i].Longitude),
        });
        this.markers.push(Way_marker);
        this.Marker_latlng(Way_marker, this.WayPoints);
      }
    }
  }

  Marker_latlng(marker, route) {
    var that = this
    marker.addListener('click', function (event) {
      if (that.activeInfoWindow) {
        that.activeInfoWindow.close();
      }
      this.Marker_Lat = event.latLng.lat();
      this.Marker_Lng = event.latLng.lng();
      for (var i = 0; i < route.length; i++) {
        if (this.Marker_Lat == route[i].Latitude) {
          that.Search = route[i].LocationName;
          var streetadress = route[i].StreetAddress;
          var city = route[i].City;
          var state = route[i].State;
          var zip = route[i].Zip;
          break;
        }
      }
      that.search();
      var data = "<div style = 'width:200px;min-height:40px'><span>" + that.Search + "<span></br><span>" + streetadress + "</br></span><span>" + city + "<strong>,</strong>&nbsp</span><span>" + state + "&nbsp</span><span>" + zip + "<span></div>"
      var infowindow = new google.maps.InfoWindow({
        content: data,
        maxWidth: 400
      });
      infowindow.open(that.map, marker);
      that.activeInfoWindow = infowindow;
      google.maps.event.addListener(that.map, 'click', function () {
        infowindow.close();
      });
    });
  }

  reloadMarkers() {
    // Loop through markers and set map to null for each
    for (var i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    // Reset the markers array
    this.markers = [];
    // Call set markers to re-add markers
    this.setMarkers();
  }


  search() {
    if (this.Search.length != 0) {
      $('#dots_spinner').show();
      this.http.post<any>(this.apiList.LocationSearchUrl, { SearchText: this.Search, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
 .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
        if (response.Response == 1) {
          this.Routeslist = response.lst_Locations;
          this.ExcludeRoute();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
    }
    else {
      this.refreshlocationlist();
    }
  }

  // Excluding routes that are in waypoints 
  ExcludeRoute() {
    this.Routeslist = this.Routeslist.filter(item1 =>
      !this.WayPoints.some(item2 => (item2.IdLocation === item1.IdLocation)))
    // console.log(this.Routeslist);
  }

  getCurrent_location() {
    var that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        that.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        that.Pin_Point();
      },
        function (error) {
        //  console.log(error);
        });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    if(this.Routeslist.length == 0){
     // this.refreshlocationlist();
      this.Search = "";
    }
     this.reloadMarkers();
   // this.Search = "";

  }

  async  refreshlocationlist() {
    await this.http.post<any>(this.apiList.LocationListUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
 .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.Routeslist = response;
        this.Routeslist = this.Routeslist.lst_Location;
        this.ExcludeRoute();
        this.getCurrent_location();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  Back() {
    this.router.navigate([this.companyName + '/dashboard/Routelist']);
  }

  clearSearch() {
    this.Search = '';
    this.refreshlocationlist();
  }

  calculateIds() {
    for (let i = 0; i < this.WayPoints.length; i++) {
      this.lst_Locations.push({
        IdLocation: this.WayPoints[i].IdLocation
      });
    }
    return this.lst_Locations
  }

  Save() {
    if (this.RouteIdentifier == '') {
      this.FlashError('Route Identifier is mandatory');
    }
    if (this.WayPoints.length == 0) {
      this.FlashError('Waypoints are mandatory');
    }
    else if (this.RouteIdentifier != '' && (this.WayPoints.length != 0)) {
      this.calculateIds();
      this.http.post<any>(this.apiList.AddRouteUrl, { RouteIdentifier: this.RouteIdentifier, CreatedBy: this.IdMerchant, lst_Locations: this.lst_Locations }, this.globalservice.Headers())
 .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
        if (response.Response == 1) {
          this.FlashError('Route Successfully Added');
          this.router.navigate([this.companyName + '/dashboard/Routelist']);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       });
    }
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

}

