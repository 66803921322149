import { Injectable } from '@angular/core';

@Injectable()
export class UserService {
private isUserloggedIn;
public username; 


  constructor() { this.isUserloggedIn=false;}
  setUserLoggedIn(){
  this.isUserloggedIn=true;
  }
  setUserLoggedOut(){
    this.isUserloggedIn=false;
    }
  getUserLoggedIn(){
    return this.isUserloggedIn;
  }
}

