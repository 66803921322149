import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { RouterModule, Routes, Router } from '@angular/router';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service'
declare const $;
@Component({
  selector: 'app-add-suppliers',
  templateUrl: './add-suppliers.component.html',
  styleUrls: ['./add-suppliers.component.css']
})
export class AddSuppliersComponent implements OnInit {
  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));

  public mask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  countrylists
  IdCountry
  IdState
  states
  citys
  selctedcountry
  selectedstate
  selectedcity
  CompanyName
  CompanyAddress
  CompanyPhoneNo
  CompanyEmailId
  ContactName
  ContactPhoneNo
  ContactEmailId
  ZipCode
  posts
  AlertText

  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,public apiList: ApilistService) { }

  ngOnInit() {
    this.HidePopups()
    this.globalservice.getCountry().then((res:any)=>{
      this.countrylists = res;
    });
  }

  HidePopups(){
    $("#alert_div").hide();
    $('#dots_spinner').hide();
  }

  onChangeCountry(a) {
    this.IdCountry = a;
    this.getstates();
  }

  getstates() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.StateUrl, { IdCountry: this.IdCountry },this.globalservice.Headers()).subscribe((response) => {
      $('#dots_spinner').hide();
        this.states = response.lst_State;
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  onChangeState(b) {
    this.IdState = b;
    this.getcity();
  }

  getcity() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CityUrl, { IdState: this.IdState },this.globalservice.Headers()) .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
        this.citys = response.lst_City;
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

4

  save() {
    $('#dots_spinner').show();
    //console.log(this.CompanyName, this.CompanyAddress, this.CompanyPhoneNo, this.CompanyEmailId, this.ContactName, this.ContactPhoneNo,this.ContactEmailId, this.IdCountry, this.IdState, this.selectedcity, this.ZipCode);
    this.http.post<any>(this.apiList.AddSupplierUrl, { IdMerchant: this.IdMerchant, CreatedBy: this.IdMerchant, CompanyName: this.CompanyName, CompanyAddress: this.CompanyAddress, CompanyPhoneNo: this.CompanyPhoneNo, CompanyEmailId: this.CompanyEmailId, ContactName: this.ContactName, ContactPhoneNo: this.ContactPhoneNo, ContactEmailId: this.ContactEmailId, IdCountry: this.IdCountry, IdState: this.IdState, IdCity: this.selectedcity, ZipCode: this.ZipCode },this.globalservice.Headers()) .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      //this.posts = response;
      //console.log(response);
      if(response.Response==1){
        this.router.navigate([this.companyName + '/dashboard/ProductSuppliers']);
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  
  }
  goback() {
    this.router.navigate([this.companyName + '/dashboard/ProductSuppliers']);
  }
   FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}
