import { Component, OnInit, ViewChild, OnDestroy, } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from '../../../../environments/environment';
import { Sort } from '@angular/material';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Rx';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';

declare const $;

@Component({
  selector: 'app-placeloop',
  templateUrl: './placeloop.component.html',
  styleUrls: ['./placeloop.component.css']
})

export class PlaceloopComponent implements OnInit, OnDestroy {
  @ViewChild('place', { static: false }) public placeForm: NgForm;
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);

  IdPlaceCampaign;
  details;
  StartTime = '00:01 AM';
  EndTime = '11:59 PM';
  selectedCampaign;
  selectedMachines: Array<number> = [];
  CampaignDuration = 0;
  PlaceCampaignName;
  posts;
  placecampaign;
  SelectedScreenLocation;
  Mdetails;
  MachineSerial;
  removeplacecampaign;
  CampaignStartDate;
  CampaignEndDate;
  addclass;
  ToDate;
  addclassselect;
  Date;
  p = 1;
  AlertText;
  Mlists: any;
  Campaignlists: any;
  selectedvalues = [];
  array = [];
  isAdding = false;
  bit: any = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public dialog: MatDialog,
    public apiList: ApilistService) { }


  ngOnInit() {
    this.globalservice.setDiscard_check();
    $('#alert_div').hide();
    $('#dots_spinner').hide();
    this.Date = new Date();
    this.ToDate = (this.Date.getMonth() + 1) + '/' + this.Date.getDate() + '/' + this.Date.getFullYear();
    this.getmachinelist();
    this.Campaignlist();
    this.getlistplaceloop();
    $('body').on('change', '#timepicker1', function () {
      $('#timepicker1').trigger('click');
    });
    $('#timepicker1').timepicker({
      timeFormat: 'HH:mm:ss',
    });
    $('body').on('change', '#timepicker2', function () {
      $('#timepicker2').trigger('click');
    });
    $('#timepicker1').timepicker();
    $('#timepicker2').timepicker();
  }
  canDeactivate(): any {
    if (!this.isAdding && this.placeForm.dirty) {
      if (this.bit === false && this.globalservice.GetDiscard_Check()) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });
        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response === 'ok')) {
            this.dialog.closeAll();
            this.bit = true;
          }
        });
      }
    } else if (this.placeForm.dirty) {
      if (this.bit === false) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });
        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response === 'ok')) {
            this.dialog.closeAll();
            this.bit = true;
          }
        });
      }
    }
    return true;
  }
  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {

  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {

  }

  selectAll() {
    $('#checkall').addClass('btn_active');
    $('#uncheckall').removeClass('btn_active');
      this.array = [];
      for (let i = 0; i < this.Mlists.length; i++) {
        this.array.push(this.Mlists[i].IdMachine);
      }
      this.selectedMachines = [...this.array];
  }

  deselectAll(idd) {
    $('#checkall').removeClass('btn_active');
    $('#uncheckall').addClass('btn_active');
      this.selectedMachines = [];
  }

  changemachine(ids) {
    $('#uncheckall').removeClass('btn_active');
    $('#checkall').removeClass('btn_active');
    ids = ids.map(function (e) {
      return +e;
    });
    this.selectedMachines = [...ids];
  }

  changeCampaign(id) {
    this.selectedCampaign = id;
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CampaignDetailApi,
      { IdCampaign: this.selectedCampaign }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.details = response;
          this.CampaignDuration = this.details.Obj_CampaignClass.CampaignDuration;
          this.CampaignStartDate = this.details.Obj_CampaignClass.StringCampaignStartDate;
          this.CampaignEndDate = this.details.Obj_CampaignClass.StringCampaignEndDate;
          $('#SDate').val(this.CampaignStartDate);
          $('#EDate').val(this.CampaignEndDate);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });

  }
  getmachinelist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachinelistApi,
      { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.Mlists = response;
        this.Mlists = this.Mlists.lst_masters;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }


  Campaignlist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CampaignlistApi, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.Campaignlists = response;
          this.Campaignlists = this.Campaignlists.lst_GetListOfCampaign;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  save() {
    this.isAdding = true;
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.PlaceCampaignApi,
      {
        IdCampaign: this.selectedCampaign,
        IdMachines: [...this.selectedMachines],
        StartDateTime: this.CampaignStartDate,
        StartTime: this.StartTime,
        EndDateTime: this.CampaignEndDate,
        EndTime: this.EndTime,
        PlaceCampaignName: this.PlaceCampaignName,
        IdMerchant: this.IdMerchant,
        CurrentDate: this.ToDate
      },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.posts = response;
        this.placeForm.reset();
        if (this.posts.Response === 1) {
          this.FlashError('Placed the Campaign');
        }
        this.getlistplaceloop();
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
           this.CampaignStartDate = '';
          this.CampaignEndDate = '';
          this.CampaignDuration = 0;
          $('#SDate').val('');
          $('#EDate').val('');
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  timeconversion(Time) {
    const time = Time;
    let hours = Number(time.match(/^(\d+)/)[1]);
    const minutes = Number(time.match(/:(\d+)/)[1]);
    const AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === 'PM' && hours < 12) { hours = hours + 12; }
    if (AMPM === 'AM' && hours === 12) { hours = hours - 12; }
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) { sHours = '0' + sHours; }
    if (minutes < 10) { sMinutes = '0' + sMinutes; }
    return sHours + ':' + sMinutes + ':' + '00';
  }

  getlistplaceloop() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ListOfPlaceCampaignsApi, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.placecampaign = response;
        this.placecampaign = this.placecampaign.lst_PlaceLoops;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  edit(placeCampaignName) {
    this.router.navigate([this.companyName + './dashboard/digitalsignage/Updateplaceloop', {
      placeCampaignName: placeCampaignName
    }]);
  }

  delete(PlaceCampaignName, Idmachine) {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.deletePlaceCampaignApi,
      {
        PlaceCampaignName: PlaceCampaignName,
        IdMerchant: this.IdMerchant,
        IdMachine: Idmachine
      }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.removeplacecampaign = response;
        if (this.removeplacecampaign.Response === 1) {
          this.FlashError('Successfully deleted');
          this.getlistplaceloop();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  sortData(sort: Sort) {
    const data = this.placecampaign.slice();
    if (!sort.active || sort.direction === '') {
      this.placecampaign = data;
      return;
    }
    this.placecampaign = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'machineserial': return this.compare(a.MachineSerial.toLowerCase(), b.MachineSerial.toLowerCase(), isAsc);
        case 'campaignname': return this.compare(a.CampaignName.toLowerCase(), b.CampaignName.toLowerCase(), isAsc);
        case 'campaignduration': return this.compare(a.CampaignDuration, b.CampaignDuration, isAsc);
        case 'startdate': return this.compare(a.CampaignStartDate, b.CampaignStartDate, isAsc);
        case 'enddate': return this.compare(a.CampaignEndDate, b.CampaignEndDate, isAsc);
        case 'placementname': return this.compare(a.PlaceCampaignName.toLowerCase(), b.PlaceCampaignName.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  // ----------------------//

  AddScreenbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage']); }
  AddContentbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Addcontent']); }
  Adloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Adloop']); }
  placeloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/placeloop']); }
  preview() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Previewcampaign']); }
  Calendar() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaigncalendar']); }
  report() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaignreports']); }

  ngOnDestroy() {
    this.dialog.ngOnDestroy();
  }
}
