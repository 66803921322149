import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
declare const $;
@Component({
  selector: 'app-updatescreen',
  templateUrl: './updatescreen.component.html',
  styleUrls: ['./updatescreen.component.css']
})
export class UpdatescreenComponent implements OnInit {
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
  companyName = sessionStorage.getItem('companyName');
  AlertText: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private globalservice: GlobalService,
    public apiList: ApilistService) { }

  Update;
  edit_Id;
  edit_details;
  ScreenOrientation; SpecificationName; ScreenResolution; ScreenSize;ScreenOrientationorg; SpecificationNameorg; ScreenResolutionorg; ScreenSizeorg;

  ngOnInit() {
    this.activatedRoute.params.subscribe((get) => {
      this.edit_Id = get.id;
    });
    this.editScreen();
    $('#dots_spinner').hide();
    $('#alert_div').hide();
  }

  editScreen() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.EditScreenApi, { IdScreen: this.edit_Id }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.edit_details = response;
        this.ScreenOrientation = this.edit_details.obj_ScreenDetail.ScreenOrientation;
        this.SpecificationName = this.edit_details.obj_ScreenDetail.SpecificationName;
        this.ScreenResolution = this.edit_details.obj_ScreenDetail.ScreenResolution;
        this.ScreenSize = this.edit_details.obj_ScreenDetail.ScreenSize;

        this.ScreenOrientationorg = this.edit_details.obj_ScreenDetail.ScreenOrientation;
        this.SpecificationNameorg = this.edit_details.obj_ScreenDetail.SpecificationName;
        this.ScreenResolutionorg = this.edit_details.obj_ScreenDetail.ScreenResolution;
        this.ScreenSizeorg = this.edit_details.obj_ScreenDetail.ScreenSize;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  SaveAs() {




  $('#dots_spinner').show();
  this.http.post<any>(this.apiList.UpdateApi,
    {
      IdScreen: this.edit_Id,
      ScreenResolution: this.ScreenResolution,
      ScreenSize: this.ScreenSize,
      ScreenOrientation: this.ScreenOrientation,
      SpecificationName: this.ScreenSize + '_' + this.ScreenOrientation + '_' + this.ScreenResolution,
      CreatedBy: this.IdMerchant, ScreenName: 'Removed'
    }, this.globalservice.Headers())
    .timeout(25000)
    .take(1)
    .map(resp => {
      if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
      if (+resp.Response !== 1) { throw resp; }
      return resp;
    }).subscribe((response) => {
      if (response.Response === 1) {
        this.Update = response;
        this.FlashSuccess('Successfully Updated');
        
        
      }
    }, (err: any) => {
      const Error = this.globalservice.ProcessError(err);
      if (Error.Response === 35) {
        if (Error.ErrorMessage) {
          this.FlashError(Error.ErrorMessage);
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
        return;
      } else {
        this.FlashError(Error.ErrorMessage);
        return;
      }
    }).add(() => { $('#dots_spinner').hide(); });



    
  }
  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }


  FlashSuccess(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
      this.router.navigate([this.companyName + '/dashboard/digitalsignage']);
    }, 3000);
  }

  orientation() {
    if (this.ScreenOrientation === 'Horizontal') {
      this.ScreenResolution = '1920 x 1080';
    } else if (this.ScreenOrientation === 'Vertical') {
      this.ScreenResolution = '1080 x 1920';
    }
  }


  goback() {
    this.router.navigate([this.companyName + '/dashboard/digitalsignage']);
  }
}
