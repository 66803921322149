import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FormControl, NgForm } from '@angular/forms';
import { GlobalService } from '../global.service';
import { CropperComponent } from 'angular-cropperjs';
import { ApilistService } from '../../service/Api/apilist.service';
declare const $;

@Component({
  selector: 'app-superadmindashboard',
  templateUrl: './superadmindashboard.component.html',
  styleUrls: ['./superadmindashboard.component.css']
})

export class SuperadmindashboardComponent implements OnInit {
  IdMerchant = sessionStorage.getItem('referSuperMerchantId');
  BaseFileUrl = environment.BaseFileUrl;
  ImageUrl = this.BaseFileUrl + '/ProfilePics/';
  base64: any;
  cropperRes: string;
  showCropper: boolean;
  cropperConfig: object = {
    movable: true,
    scalable: true,
    zoomable: true,
    rounded: true,
    background: true,
    aspectRatio: 1 / 1,
    viewMode: 1,
    checkCrossOrigin: true
  };
  @ViewChild('angularCropper', { static: false }) public angularCropper: CropperComponent;
  @ViewChild('changepassword', { static: false }) public ChangePassword: NgForm;
  Date;
  Search;
  password = '';
  confirmPassword;
  ContactPerson;
  base64Profilepic = [];
  ProfileImg;
  OldPassword;
  NewPassword;
  NewPassword1;
  AlertText;
  keylock;
  showbutton = 1;
  show_message;
  compare;
  nextLibAvailable = false;
  Sessionid: string;
  superAdminDetail = null;
  blobImageObject: Blob;
  FileName: any;
  FileMD5Sum: any;
  ImagePath: any;


  ///
  constructor(
    public apiList: ApilistService,
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService) {
     }

  ngOnInit() {
    this.keylock = 0;
    $('.sidenav_overlay').hide();
    $('.toggle-password').click(function () {
      $(this).toggleClass('fa-eye fa-eye-slash');
      const input = $($(this).attr('toggle'));
      if (input.attr('type') === 'text') {
        input.attr('type', 'password');
      } else {
        input.attr('type', 'text');
      }
    });
    this.superadmindetails();
    setInterval(() => {
      this.Date = new Date();
    }, 1000);
  }

  upload() {
    if (this.blobImageObject !== null && this.blobImageObject !== undefined) {
      $('#dots_spinner').show();
      this.globalservice.uploadFiles(this.blobImageObject).then(async (res: any) => {
        if (res.Response === 1) {
          $('#dots_spinner').hide();
          // this.ImagePath = res.PreviewUrl;
          this.FileName = res.FileName;
          this.showbutton = 0;
          await this.saveData();
        }
      }, error => {
        $('#dots_spinner').hide();
        this.FileMD5Sum = null;
        const Error = this.globalservice.ProcessError(error);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
    } else {
      this.keylock = 1;
      this.FlashError('Firstly choose your image & then click on result button');
    }
  }

  saveData() {
    $('#dots_spinner').show();
    if (this.showbutton === 0) {
      this.showbutton = 1;
      const data = {
        FileName: this.FileName,
              IdMerchant: this.IdMerchant
            };

      this.http.post<any>(this.apiList.UploadImageUrl, data, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (result.Response !== 1) throw result;
          return result;
        })
        .subscribe((response:any) => {
         // this.image_upload = response;
          if (response.Response === 1) {
            $('#EditPicture').modal('hide');
            this.ImagePath = response.ImageURL;
            this.FileMD5Sum = response.FileMD5Sum
           // this.admindetails();
          }
          sessionStorage.removeItem('profilepic');
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
        });
    } else {
      this.keylock = 1
      this.FlashError('Firstly choose your image & then click on result button')
    }
  }

  profilePicAvailable() : boolean {
    return this.superAdminDetail != null
            && this.superAdminDetail.ImagePath != null
            && this.superAdminDetail.ImagePath.trim() !== '';
  }


  settings() {
    $('.sidenav_overlay').fadeIn('slow');
    document.getElementById('mySidenav').style.width = '250px';
  }

  Logo_click() {
    this.router.navigate(['Superadmindashboard/Signups']);
  }

  closeNav() {
    $('.sidenav_overlay').fadeOut('slow');
    document.getElementById('mySidenav').style.width = '0';
  }

  superadmindetails() {
    const data = {
      IdMerchant: this.IdMerchant,
      IsFromSuperAdmin: false
    };
    this.http.post<any>(this.apiList.MerchantDetailUrl, data, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (+result.Response !== 1) { throw result; }
        return result;
      })
      .subscribe((response) => {
        this.ImagePath = response.obj_MerchantRecord.ImageURL
        this.FileMD5Sum = response.obj_MerchantRecord.FileMD5Sum;
        this.superAdminDetail = response.obj_MerchantRecord;
        console.log("this.superAdminDetail detail"+JSON.stringify(this.superAdminDetail));
      }, (err) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
  }

  changepsw() {
    if (this.NewPassword === this.NewPassword1) {
      const data = {
        IdMerchant: this.IdMerchant,
        OldPassword: this.OldPassword,
        NewPassword: this.NewPassword
      };
      this.http.post<any>(this.apiList.ChangePswUrl, data, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (+result.Response !== 1) { throw result; }
          return result;
        })
        .subscribe((response) => {
          this.ChangePassword.reset();
          this.keylock = 1;
          this.FlashError('Password Successfully changed');
        }, (err) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.keylock = 1;
            this.FlashError(Error.ErrorMessage);
            return;
          }
        });
    } else {
      this.keylock = 1;
      this.FlashError('New password or Confirm password not matched');
    }
  }

  logout() {
    this.globalservice.Logout('No');
  }

  FlashError(message: string) {
    console.log(message)
    this.AlertText = '';
    this.AlertText = message;
    console.log(this.AlertText)
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  onKey(event: any) { // without type info
    this.compare = this.NewPassword === this.NewPassword1 ? true : false;
    this.show_message = this.NewPassword !== this.NewPassword1 ? 'Password and Confirm Password does not match' : '';
  }

  onKey1(event) {
    if (this.NewPassword) {
      this.show_message = '';
      this.nextLibAvailable = true;
    } else {
      this.show_message = '';
      this.nextLibAvailable = false;
      this.compare = false;
    }
  }



  onFileSelected(event) {
    const that = this;
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      that.showCropper = false;
      reader.onload = (eventCurr: ProgressEvent) => {
        that.base64 = (<FileReader>eventCurr.target).result;
        setTimeout(function () {
          that.refreshCrop(that.base64);
        }, 2000);
      };
      this.ProfileImg = event.target.files[0].name.replace(/\s/g, '');
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  refreshCrop(img) {
    this.base64 = img;
    this.showCropper = true;
  }

  editpicture() {
    $('#EditPicture').modal('show');
  }

  cropendImage() {
    const that = this;
    that.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    that.base64Profilepic = this.cropperRes.split(',');
    $('#EditPicture').append('<div id="croppieModal" class="modal fade" role="dialog"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><h3 class="modal-title">Preview</h3><button type="button" class="close" id="imgResultClose">&times;</button></div><div class="modal-body"><div class="product-image-overlay"><img class="img-responsive" src="" /></div></div><div class="modal-footer" style="text-align:center"><button type="button" class="btn btn-success" id="imgResultSave">Done</button></div></div></div></div>');
    const productImage = $('img');
    const productOverlay = $('.product-image-overlay');
    const productOverlayImage = $('.product-image-overlay img');

    productOverlayImage.attr('src', this.cropperRes);
    productOverlay.fadeIn(100);
    $('#EditPicture').css('overflow', 'hidden');

    $('.product-image-overlay-close').click(function () {
      productOverlay.fadeOut(100);
      $('#EditPicture').css('overflow', 'auto');
    });

    $('#imgResultClose').click(function () {
      $('#croppieModal').modal('hide');
    });
    $('#imgResultSave').click(function () {
      $('#croppieModal').modal('hide');
      that.showbutton = 0;
      if (!that.ProfileImg) {
        that.keylock = 1;
        that.FlashError('Firstly upload the picture');
      }
    });
  }

  readyImage(event) {
    if (this.angularCropper !== undefined) {
      this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      this.b64toBlob(this.cropperRes);
    }
  }

  rotate(turn) {
    if (this.angularCropper !== undefined) {
      turn = turn === 'left' ? -45 : 45;
      this.angularCropper.cropper.rotate(turn);
      this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      this.b64toBlob(this.cropperRes);
    }
  }

  destroy() {
    this.angularCropper.cropper.destroy();
  }

  zoomManual() {
    if (this.angularCropper !== undefined) {
      this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      this.b64toBlob(this.cropperRes);
    }
  }
  zoom(status) {
    if (this.angularCropper !== undefined) {
      status = status === 'positive' ? 0.1 : -0.1;
      this.angularCropper.cropper.zoom(status);
      this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      this.b64toBlob(this.cropperRes);
    }
  }
  b64toBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    this.blobImageObject = new Blob([ab], { type: 'image/jpeg' });
  }
}
