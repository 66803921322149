import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgForm } from "@angular/forms";
import { Observable } from 'rxjs/Rx';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';
declare const $;

@Component({
  selector: 'app-addusers',
  templateUrl: './addusers.component.html',
  styleUrls: ['./addusers.component.css']
})

export class AddusersComponent implements OnInit, OnDestroy {
  @ViewChild('personForm',{static: false}) public UserForm: NgForm
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");
  UserRole = sessionStorage.getItem("referUserRole");
  Name: string;
  Password:any;
  DateSetup: string;
  DateCancelled: string;
  Premissions: string;
  EmailId:any;
  CompanyName: string;
  CompanyAddress: string;
  City: string;
  State: string;
  Zip: string;
  CreatedBy = 1;
  posts: any;
  IsActive: boolean;
  ConfirmPassword:any;;
  AlertText
  isAdding = false;
  values = '';
  compare = true
  nextLibAvailable = false;
  show_message
  iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass = false;
  type = 'password';
  iconname2 = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass2 = false;
  type2 = 'password';
  countrylists: any;
  IdCountry
  IdState
  states: any;
  citys: any;
  Lastname
  bit: any = false;
 
  constructor(private router: Router,
    private http: HttpClient,
    public dialog: MatDialog,  
    private globalservice: GlobalService,public apiList: ApilistService) { }

  ngOnInit() {
    this.globalservice.setDiscard_check();
    this.globalservice.getCountry().then((res:any)=>{
      this.countrylists = res;
    });
    this.HidePopups()
  }

  HidePopups(){
        $('#txtPassword').attr("autocomplete", "off");
    setTimeout('$("#txtPassword").val("");', 50); 
    $("#alert_div").hide();
    $('#dots_spinner').hide();
    $('#psw').hide();
  }

  onValChange(value) {
    this.IsActive = value.checked;
  }
  
  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.DateSetup = $('#DSetup').val();
  }

  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    this.DateCancelled = $('#DCancelled').val();
  }

  canDeactivate():any {
    if (!this.isAdding && this.UserForm.dirty) {
        if(this.bit == false && this.globalservice.GetDiscard_Check()){
            const dialogRef = this.dialog.open(DialogComponent, {
                width: '300px',
                data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
            });
          return dialogRef.afterClosed().subscribe(result => {
                if (result && (result.response == "ok")) {
                    this.dialog.closeAll();
                    this.bit = true;
                    this.router.navigate([this.companyName + '/dashboard/users']);
                }
            });
        }
    } else if (this.UserForm.dirty) {
        if(this.bit == false){
            const dialogRef = this.dialog.open(DialogComponent, {
                width: '300px',
                data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
            });
          return dialogRef.afterClosed().subscribe(result => {
                if (result && (result.response == "ok")) {
                    this.dialog.closeAll();
                    this.bit = true;
                    this.router.navigate([this.companyName + '/dashboard/users']);
                }
            });
        }
    }
    return true; 
}
  submit_click() {
    this.isAdding = true;
    if (this.Password == this.ConfirmPassword) {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.AddUserUrl, {IdMerchant:this.IdMerchant, Name: this.Name, LastName:this.Lastname,Password: this.Password, IsActive: this.IsActive,  UserRole: this.Premissions, EmailId: this.EmailId },this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
          this.posts = response;
          if( this.posts.Response==1){
            this.UserForm.reset();
            this.router.navigate([this.companyName + '/dashboard/users']);
          }
        }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {  $('#dots_spinner').hide();
       });
    } else {
      this.FlashError("Password and Confirm Password does not match");
    }
  }

  onKey(event: any) { // without type info
    this.compare = this.Password == this.ConfirmPassword?true:false;
    this.show_message = this.Password != this.ConfirmPassword?'Password and Confirm Password does not match':''

    // if (this.Password == this.ConfirmPassword) {
    //   this.compare = true
    // } else {
    //   this.show_message = "Password and Confirm Password does not match"
    //   this.compare = false;
    // }
  }

  onKey1() {
    this.show_message=''
    if (this.Password) {
      this.nextLibAvailable = true;
    } else {
      this.nextLibAvailable = false;
      this.compare = false;
    }
  }
  showPassword() {
    this.showPass = this.showPass ? false : true;
    if (this.showPass) {
      this.type = 'text';
      this.iconname = 'fa fa-fw fa-eye field-icon toggle-password';
    } else {
      this.type = 'password';
      this.iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
    }
  }

  confirm_pass() {
   // console.log("dgvfdg")
    this.showPass2 = !this.showPass2;
    if (this.showPass2) {
      this.type2 = 'text';
      this.iconname2 = 'fa fa-fw fa-eye field-icon toggle-password';
    } else {
      this.type2 = 'password';
      this.iconname2 = 'fa fa-fw fa-eye-slash field-icon toggle-password';
    }
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  onChangeCountry(a) {
    this.IdCountry = a;
    this.getstates();
  }

  getstates() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.StateUrl, { IdCountry: this.IdCountry },this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if(response.Response==1){
        this.states = response.lst_State;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {  $('#dots_spinner').hide();
       });
  }
  onChangeState(b) {
    this.IdState = b;
    this.getcity();
  }

  getcity() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CityUrl, { IdState: this.IdState },this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if(response.Response==1){
        this.citys = response.lst_City;
      }
    },(err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {  $('#dots_spinner').hide();
       });;
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/users']);
  }

  // Flasherror() {
  //   $("#alert_div").show();
  //   $("#alert_div").animate({
  //     width: "100%"
  //   }, "slow");
  //   $('#user_detail').hide();
  //   setTimeout(() => {
  //     $("#alert_div").hide();
  //   }, 3000);
  // }

  ngOnDestroy() {
    this.dialog.ngOnDestroy();
  }
}
