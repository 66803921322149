import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
declare const $;

@Component({
  selector: 'app-inventorymanagement',
  templateUrl: './inventorymanagement.component.html',
  styleUrls: ['./inventorymanagement.component.css']
})
export class InventorymanagementComponent implements OnInit {
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);

  Inventory: any;
  AlertText: string;
  Search: string;
  Selectedmachine: any;
  posts: any;
  IdMachine: number;
  Effectivedate: any;
  ToId: any;
  IdManufacturer: number;
  before: number;
  screenHeight: number;
  pageno: any;
  selectedser;


  constructor(private router: Router, private http: HttpClient,
    private globalservice: GlobalService,
    public apiList: ApilistService) {
    this.screenHeight = window.innerHeight;
    const rowno = (window.innerHeight - 300) / 54;
    if (rowno >= 1) {
      this.before = parseInt(rowno.toFixed(2).toString().split('.')[0], 10);
    } else {
      this.before = 1;
    }
  }

  ngOnInit() {
    this.hidepops();
    this.Inventorydata();
  }

  hidepops() {
    $('#alert_div').hide();
    $('#transferform').modal('hide');
  }
  Inventorydata() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DistributorInventoryListUrl,
      { IdDistributor: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.Inventory = response.lst_Inventory;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  Transfer(value, id) {
    this.Selectedmachine = value;
    this.IdManufacturer = id;
    $('#transferform').modal('show');
    this.AssignedMerchantlist();
  }

  getID(id: string) { this.IdMachine = + id; }

  AssignedMerchantlist() {
    this.http.post<any>(this.apiList.UsertypeMerchantURL, { IdDistributor: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.posts = response.lst_assignMerchant;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { });
  }

  addEvent1(value) {
    this.Effectivedate = value;
  }

  selecteduser(id) {
    this.ToId = id;
  }

  Assign() {
    const data = {
      IdMachine: this.IdMachine,
      LinkCode: this.Selectedmachine,
      EffectiveDate: this.Effectivedate,
      FromId: this.IdMerchant,
      ToId: this.ToId,
      IdManufacturer: this.IdManufacturer,

    };
    this.http.post<any>(this.apiList.SaveTransferInventoryURL, data, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          $('#transferform').modal('hide');
          this.Inventorydata();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  clearSearch() {
    this.Search = '';
  }

  pageChange(event) {
    this.pageno = event;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerHeight;
    this.screenHeight = window.innerHeight;
    const rowno = (window.innerHeight - 300) / 54;
    this.before = parseInt(rowno.toFixed(2).toString().split('.')[0], 10); // before
    if (this.before >= 0) {
      if (this.before === 0) {
        this.before = 1;
      }
    }
  }

  sortData(sort: Sort) {
    const data = this.Inventory.slice();
    if (!sort.active || sort.direction === '') {
      this.posts = data;
      return;
    }
    this.Inventory = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Purchase_Date': return this.compare(a.PurchaseDate, b.PurchaseDate, isAsc);
        case 'Machine_Type': return this.compare(a.MachineType.toLowerCase(), b.MachineType.toLowerCase(), isAsc);
        case 'Machine_Serial': return this.compare(a.MachineSerial.toLowerCase(), b.MachineSerial.toLowerCase(), isAsc);
        case 'LinkCode': return this.compare(a.LinkCode.toLowerCase(), b.LinkCode.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}
