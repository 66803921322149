import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ɵclearResolutionOfComponentResourcesQueue } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { GlobalService } from '../../../global.service';
declare const $;

@Component({
  selector: 'app-storereport',
  templateUrl: './storereport.component.html',
  styleUrls: ['./storereport.component.css']
})
export class StorereportComponent implements OnInit {

  @ViewChild('reportinglocation', { static: false }) public reportinglocationForm: NgForm
  @ViewChild('reportingmachine', { static: false }) public reportingmachineForm: NgForm
  CSVFileUrl = environment.CSVFileUrl;
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");

  IdMachine
  StartDate
  EndDate
  posts
  selectedlocation = null
  OnlyselectedMachine = null
  OnlyselectedMachinebrand = null
  selectedchains=null;
  Mlists
  ldetails
  MLStartDate
  MLEndDate
  MStartDate
  MEndDate
  Tlists
  Trans: any;
  Mposts
  selectedMachine1
  p: number = 1;
  S1Date
  E2Date
  S3Date
  E4Date
  array = [];
  arraychain=[];
  Idlocation="";
  machinelistlength;
  selectedtimezone = ''
  AlertText
  nextLibAvailable = false;
  coupon_code; coupon_amount; coupon_used; coupon_startdate; coupon_enddate; coupon_startdatetime; coupon_enddatetime; coupon_discounttype;
  timezone: any;

  EmailId: any;
  IdTransaction: any;
  TC: any;
  RevNumber: any;
  RevisionNumber: any;
  Date: Date;
  S1Datee;
  resendMailTimeZone: any = "";

  idB;
  Brandlist: any;

  statelist:any;
  IdBrand: any="";
  IdState:any="";

  chainList: any[] = [];
  chainval=null;
  Locationval;
  ProductVal;
  postsprod: any;
  optionsModel = [];
  arraymachine: any[];
  arraymachinebrand: any[];
  arraymachinestate:any[];
  IdMachines: any="";
  arrayproduct;
  selectedProduct;
  selectedBrand;
  selectedstate;
  selected
  Plistss: any;
  IdProducts = "";
  myOptions = [
    { id: 0, name: 'Sunday' },
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
  ];
  StartTime: string = "12:00 AM";
  EndTime: string = "12:00 AM";

  // tab2

  IdMachinetab2
  StartDatetab2
  EndDatetab2
  poststab2
  selectedlocationtab2 = null
  OnlyselectedMachinetab2 = null
  Mliststab2
  ldetailstab2
  MLStartDatetab2
  MLEndDatetab2
  MStartDatetab2
  MEndDatetab2
  Tliststab2
  Transtab2: any;
  Mpoststab2
  selectedMachine1tab2

  S1Datetab2
  E2Datetab2
  S3Datetab2
  E4Datetab2
  arraytab2 = [];
  Idlocationtab2;
  machinelistlengthtab2;
  selectedtimezonetab2 = ''
  AlertTexttab2
  nextLibAvailabletab2 = false;
  coupon_codetab2; coupon_amounttab2; coupon_usedtab2; coupon_startdatetab2; coupon_enddatetab2; coupon_startdatetimetab2; coupon_enddatetimetab2; coupon_discounttypetab2;
  timezonetab2: any;

  EmailIdtab2: any;
  IdTransactiontab2: any;
  TCtab2: any;
  RevNumbertab2: any;
  RevisionNumbertab2: any;
  Datetab2: Date;
  S1Dateetab2;
  resendMailTimeZonetab2: any = "";

  idBtab2;
  Brandlisttab2: any;
  IdBrandtab2: any;

  chainListtab2: any[] = [];
  chainvaltab2;
  Locationvaltab2;
  ProductValtab2;
  postsprodtab2: any;
  optionsModeltab2 = [];
  arraymachinetab2: any[];
  IdMachinestab2: any;
  arrayproducttab2;
  selectedProducttab2;
  Plistsstab2: any;
  IdProductstab2 = null;
  myOptionstab2 = [
    { id: 0, name: 'Sunday' },
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
  ];
  StartTimetab2: string = "12:00 AM";
  EndTimetab2: string = "12:00 AM";
  reportBrand: boolean = true;

  UserRole = sessionStorage.getItem("referUserRole");
  brandStatus:boolean=false;

  constructor(public apiList: ApilistService, private router: Router, private http: HttpClient, private globalservice: GlobalService) {
  }

  ngOnInit() {
    if(this.UserRole=="BrandUser"){
      this.brandStatus=true;
          }
          else{
      this.brandStatus=false;
          }
    this.reportBrand = true;
    this.resendMailTimeZone = "";
    // this.locationList();
    this.listofmachine();

    this.listOfStates();
    this.timezonelist();
    this.HidePopups();
    this.brand();
    this.listofproduct();
    this.refreshChainlist();

    this.dateTimeFunction();



    this.onChangechain(this.chainval,this.IdState);


    //    this.refreshlistproduct();
    this.Date = new Date();
    /// console.log(this.Date)
    this.StartDate = moment(this.Date).format('MM/DD/YYYY')
    $('#S1Date').val(this.StartDate);
    this.EndDate = moment(this.Date).format('MM/DD/YYYY')
    $('#E2Date').val(this.EndDate)
    $('#S3Date').val(this.StartDate);
    $('#E4Date').val(this.EndDate);


    // tab2
    this.resendMailTimeZone = "";
    // this.locationList();
    this.listofmachinetab2();
    this.timezonelisttab2();
    //  this.HidePopups();
    this.brandtab2();
    this.listofproducttab2();
    this.refreshChainlisttab2();

    this.dateTimeFunctiontab2();



    this.onChangechaintab2(this.chainval);


    //    this.refreshlistproduct();
    this.Datetab2 = new Date();
    /// console.log(this.Date)
    this.StartDatetab2 = moment(this.Datetab2).format('MM/DD/YYYY')
    $('#S1Datetab2').val(this.StartDatetab2);
    this.EndDatetab2 = moment(this.Datetab2).format('MM/DD/YYYY')
    $('#E2Datetab2').val(this.EndDatetab2)
    $('#S3Datetab2').val(this.StartDatetab2);
    $('#E4Datetab2').val(this.EndDatetab2);

  }

  listOfStates(){
    //MerchantStateList

    this.http.post<any>(this.apiList.MerchantStateList, '', this.globalservice.Headers())
    .timeout(25000)
    .take(1)
    .map(response => {
      if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
      if (response.Response !== 1) { throw response; }
      return response;
    })
    .subscribe((response) => {
      if (response) {
         console.log("statelist"+JSON.stringify(response));
        this.statelist = response.stateLists;
      }
    }, (err: any) => {
      const Error = this.globalservice.ProcessError(err);
      if (Error.Response === 35) {
        if (Error.ErrorMessage) {
          this.FlashError(Error.ErrorMessage);
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
        return;
      } else {
        this.FlashError(Error.ErrorMessage);
        return;
      }
    }).add(() => {

    });
  }

  

  onChangeproduct(id) {
    id = id.map(function (e) {
      return JSON.stringify(e);
    });
    id = id.join(",");
    this.IdProducts = id;
    this.selectedMachine1="";
    this.IdMachine="";
    this.machinelistist();
  }

  onChangeproducttab2(id) {
    id = id.map(function (e) {
      return JSON.stringify(e);
    });
    id = id.join(",");
    this.IdProductstab2 = id;
  }


  changeday() {
    $('#uncheckalldays').removeClass("btn_active");
    $('#checkalldays').removeClass("btn_active");
  }
  changedaytab2() {
    $('#uncheckalldaystab2').removeClass("btn_active");
    $('#checkalldaystab2').removeClass("btn_active");
  }
  selectAlldays() {
    this.optionsModel = [0, 1, 2, 3, 4, 5, 6]
    $('#checkalldays').addClass("btn_active");
    $('#uncheckalldays').removeClass("btn_active");
  }
  selectAlldaystab2() {
    this.optionsModeltab2 = [0, 1, 2, 3, 4, 5, 6]
    $('#checkalldaystab2').addClass("btn_active");
    $('#uncheckalldaystab2').removeClass("btn_active");
  }

  deselectAlldays() {
    this.optionsModel = [];
    $('#checkalldays').removeClass("btn_active");
    $('#uncheckalldays').addClass("btn_active");
  }
  deselectAlldaystab2() {
    this.optionsModeltab2 = [];
    $('#checkalldaystab2').removeClass("btn_active");
    $('#uncheckalldaystab2').addClass("btn_active");
  }
  dateTimeFunction() {
    $(document).ready(function () {
      $('#timepicker1').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12"
      });
      $('#timepicker2').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12:00 AM"
      });
    });
    $('body').on('change', '#timepicker1', function () {
      $('#timepicker1').trigger('click');
    });
    $('body').on('change', '#timepicker2', function () {
      $('#timepicker2').trigger('click');
    });
  }

  dateTimeFunctiontab2() {
    $(document).ready(function () {
      $('#timepicker1tab2').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12"
      });
      $('#timepicker2tab2').timepicker({
        timeFormat: 'HH:mm:ss',
        minTime: "12",
        defaultTime: "12:00 AM"
      });
    });
    $('body').on('change', '#timepicker1tab2', function () {
      $('#timepicker1tab2').trigger('click');
    });
    $('body').on('change', '#timepicker2tab2', function () {
      $('#timepicker2tab2').trigger('click');
    });
  }
  endTimelocation(tim: string) {
    this.EndTime = tim;
  }
  endTimelocationtab2(tim: string) {
    this.EndTimetab2 = tim;
  }
  startTime(time: string) {
    this.StartTime = time;
  }
  startTimetab2(time: string) {
    this.StartTimetab2 = time;
  }

  listofproduct() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.GetProductListByBrandId, { IdBrand: this.IdBrand }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Plistss = response;
          this.Plistss = this.Plistss.lst_Product
          // console.log(this.Plistss);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }
  listofproducttab2() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.GetProductListByBrandId, { IdBrand: this.IdBrandtab2 }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Plistsstab2 = response;
          this.Plistsstab2 = this.Plistsstab2.lst_Product
          // console.log(this.Plistss);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }
onChangestate(id:number){
  this.IdState = id.toString();
  $('#checkall').removeClass("btn_active");
      $('#uncheckall').removeClass("btn_active");
      $('#checkallmacbrand').removeClass("btn_active");
      $('#uncheckallmacbrand').removeClass("btn_active");
      $('#checkallchain').removeClass("btn_active");
      $('#uncheckallchain').removeClass("btn_active");
    
this.chainval="";
this.selectedchains="";
this.IdBrand="";
this.selectedBrand="";
this.selectedProduct="";
this.IdProducts="";
this.selectedMachine1="";
this.IdMachine="";
      this.refreshChainlist();
      this.onChangechain(this.chainval,this.IdState);
      this.brand();
      this.listofproduct();
      this.machinelistist();
      

}
  onChangechain(id:number,state) {
    // this.posts=[];
    // if (id ) {
      $('#dots_spinner').show();
      if(id){
        this.chainval = id.toString();
      }
      else{
        this.chainval = null;
      }
      
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').removeClass("btn_active");
      this.http.post<any>(this.apiList.LocationsByChainandState, { IdChain: this.chainval,IdState :this.IdState }, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (result.Response !== 1) throw result;
          return result;
        }).subscribe((response) => {
          $('#dots_spinner').hide();
          if (response.Response == 1) {
            //console.log("locations are" + JSON.stringify(response));
            //  this.locationList = response.lst_Location;
            this.posts = response.lst_Location;
            this.nextLibAvailable = true;
            $('#checkall').removeClass("btn_active");
            $('#uncheckall').removeClass("btn_active");

            $('#checkallchain').removeClass("btn_active");
            $('#uncheckallchain').removeClass("btn_active");
            $('#checkallmacbrand').removeClass("btn_active");
            $('#uncheckallmacbrand').removeClass("btn_active");
            $('#checkallmac').removeClass("btn_active");
            $('#uncheckallmac').removeClass("btn_active");
            $('#checkallmac').removeClass("btn_active");
            $('#uncheckallmac').removeClass("btn_active");
            this.selectedlocation = "";
            this.selectedMachine1 = "";
            this.Idlocation = "";
            this.IdMachine = "";
            this.selectedBrand="";
            this.IdBrand="";
            this.selectedProduct="";
            this.IdProducts="";
            this.Mlists = [];

            this.brand();
            this.listofproduct();
            this.machinelistist();
            //  this.Idlocation = idl.toString();
            // this.machinelistist()
            //console.log("this.location list" + JSON.stringify(this.locationList));
            // this.names = response;
            // // console.log(this.names.length);
            // this.names = this.names.lst_mc
            // this.subcat_length = this.names.length;
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
        });
    // }
    // else {
    //   $('#checkall').removeClass("btn_active");
    //   $('#uncheckall').removeClass("btn_active");
    //   this.chainval = null;
    //   this.selectedlocation = null;
    // }
  }

  onChangechaintab2(id: string) {
    if (id != 'None') {
      $('#dots_spinner').show();
      this.chainvaltab2 = id;
      $('#checkalltab2').removeClass("btn_active");
      $('#uncheckalltab2').removeClass("btn_active");
      this.http.post<any>(this.apiList.GetLocationListByChain, { IdChain: this.chainvaltab2 }, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (result.Response !== 1) throw result;
          return result;
        }).subscribe((response) => {
          $('#dots_spinner').hide();
          if (response.Response == 1) {
            //console.log("locations are" + JSON.stringify(response));
            //  this.locationList = response.lst_Location;
            this.poststab2 = response.lst_Location;
            this.nextLibAvailabletab2 = true;
            $('#checkalltab2').removeClass("btn_active");
            $('#uncheckalltab2').removeClass("btn_active");
            this.selectedlocationtab2 = "";
            this.selectedMachine1tab2 = "";
            this.Idlocationtab2 = "";
            this.Mliststab2 = [];
            this.IdMachinetab2 = "";

            //console.log("this.location list" + JSON.stringify(this.locationList));
            // this.names = response;
            // // console.log(this.names.length);
            // this.names = this.names.lst_mc
            // this.subcat_length = this.names.length;
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
        });
    }
    else {
      $('#checkalltab2').removeClass("btn_active");
      $('#uncheckalltab2').removeClass("btn_active");
      this.chainvaltab2 = null;
      this.selectedlocationtab2 = null;
      //  this.selectedlocationtab2="";
      this.selectedMachine1tab2 = null;
      this.Idlocationtab2 = "";
      this.Mliststab2 = [];
      this.IdMachinetab2 = "";
    }
  }


  refreshChainlist() {
    $('#dots_spinner').show();
    // console.log(this.globalservice.Headers())

    this.http.post<any>(this.apiList.MerchantChainbyState, {IdState :this.IdState}, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
           console.log("merchant chain list" + JSON.stringify(response));
          this.chainList = response.Chains;
          $('#dots_spinner').hide();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
            $('#dots_spinner').hide();
          }
          this.globalservice.Logout('No');
          $('#dots_spinner').hide();
          return;
        } else {
          $('#dots_spinner').hide();
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();

      });
  }

  refreshChainlisttab2() {
    $('#dots_spinner').show();
    // console.log(this.globalservice.Headers())

    this.http.post<any>(this.apiList.MerchantChains, {}, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          // console.log("merchant chain list" + JSON.stringify(response));
          this.chainListtab2 = response.Chains;
          $('#dots_spinner').hide();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
            $('#dots_spinner').hide();
          }
          this.globalservice.Logout('No');
          $('#dots_spinner').hide();
          return;
        } else {
          $('#dots_spinner').hide();
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();

      });
  }
  brand() {
    this.http.post<any>(this.apiList.BrandListbyLocation, {IdLocations:this.Idlocation}, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
           console.log("JSONs brands"+JSON.stringify(response))
          this.Brandlist = response.MerchantBrandsList;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {

      });
  }

  brandtab2() {
    this.http.post<any>(this.apiList.MerchantBrandResult, '', this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          // console.log(response)
          this.Brandlisttab2 = response.MerchantBrandsList;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {

      });
  }

  HidePopups() {
    $('#Norecord').hide();
    $('#dots_spinner').hide();
    $("#alert_div").hide();
    $('#coupon_info').modal('hide');
  }

  locationList() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.LocationListUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.posts = response;
          this.posts = this.posts.lst_Location;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  locationListtab2() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.LocationListUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.poststab2 = response;
          this.poststab2 = this.poststab2.lst_Location;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  timezonelist() {
    $('#dots_spinner').show();
    this.http.get<any>(this.apiList.TimeZoneListFUrl, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.timezone = response;
          this.timezone = this.timezone.lst_TimeZone;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  timezonelisttab2() {
    $('#dots_spinner').show();
    this.http.get<any>(this.apiList.TimeZoneListFUrl, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.timezonetab2 = response;
          this.timezonetab2 = this.timezonetab2.lst_TimeZone;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  machinelistbylocation() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachinelstUrl, { IdLocation: this.selectedlocation }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mlists = response;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }
  machinelistbylocationtab2() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachinelstUrl, { IdLocation: this.selectedlocationtab2 }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mliststab2 = response;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  onChangelocation(idl: number) {
    this.nextLibAvailable = true;
    $('#checkall').removeClass("btn_active");
            $('#uncheckall').removeClass("btn_active");

           
            $('#checkallmacbrand').removeClass("btn_active");
            $('#uncheckallmacbrand').removeClass("btn_active");
            $('#checkallmac').removeClass("btn_active");
            $('#uncheckallmac').removeClass("btn_active");
           
  

    this.Idlocation = idl.toString();
    this.IdMachine = "";
    this.selectedMachine1 = "";

    this.IdBrand = "";
    this.selectedBrand = "";
    this.IdProducts="";
    this.selectedProduct="";

    this.brand();

    this.listofproduct();

    this.machinelistist();
  }

  onChangelocationtab2(idl: number) {
    this.nextLibAvailabletab2 = true;
    $('#checkalltab2').removeClass("btn_active");
    $('#uncheckalltab2').removeClass("btn_active");
    this.Idlocationtab2 = idl.toString();
    this.IdMachinetab2 = "";
    this.selectedMachine1tab2 = "";
    this.machinelististtab2()
  }

  machinelistist() {
    this.Mlists=[];
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachineListByProduct, { IdProducts: this.IdProducts }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          // console.log("inside reponse ok machinelist");
          this.Mlists = response;
          this.Mlists = this.Mlists.lst_MachineMaster;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  machinelististtab2() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachinelstUrl, { IdLocations: this.Idlocationtab2 }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          //  console.log("inside reponse ok machinelisttab2");
          this.Mliststab2 = response;
          this.Mliststab2 = this.Mliststab2.lst_Machine;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  listofstore() {
    this.reportBrand = true;
    //console.log(this.selectedtimezone)
    if (this.StartDate && this.EndDate) {
      if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {
        //console.log(this.IdMachine, this.StartDate, this.EndDate, this.Idlocation);
        $('#dots_spinner').show();
        var a = this.optionsModel;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        this.http.post<any>(this.apiList.StoreReport, { IdChain: this.chainval, IdBrands: this.IdBrand, IdProducts: this.IdProducts, IdMachines: this.IdMachine, StartDate: this.StartDate, EndDate: this.EndDate, IdState: this.IdState, DaysOfWeek: newstr, StartTime: this.StartTime, EndTime: this.EndTime,IdLocations:this.Idlocation }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {
             console.log("response store list"+JSON.stringify(response));
            this.Tlists = response;
            if (this.Tlists.Response == 1) {
              // console.log("this.tlsts" + JSON.stringify(response));
              this.Trans = this.Tlists.ReportProducts;
              // console.log("this.Trans"+JSON.stringify(this.Trans));
              // this.resendMailTimeZone=this.selectedtimezone;
              //           this.reportinglocationForm.reset();
              //        // this.reportingmachineForm.reset();
              //         $('#checkall').removeClass("btn_active");
              //         $('#uncheckall').removeClass("btn_active");
              //         $('#uncheckallmac').removeClass("btn_active");
              //         $('#checkallmac').removeClass("btn_active");
              //         $('#uncheckalldays').removeClass("btn_active");
              // $('#checkalldays').removeClass("btn_active");

            }
          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }
  }


  listofsubbrandreport() {
    this.reportBrand = false;
    //console.log(this.selectedtimezone)
    if (this.StartDatetab2 && this.EndDatetab2) {
      if (this.toTimestamp(this.StartDatetab2) <= this.toTimestamp(this.EndDatetab2)) {
        //console.log(this.IdMachine, this.StartDate, this.EndDate, this.Idlocation);
        $('#dots_spinner').show();
        var a = this.optionsModeltab2;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        this.http.post<any>(this.apiList.BrandSubReport, { IdBrand: this.IdBrandtab2, IdProducts: this.IdProductstab2, IdMachines: this.IdMachinetab2, StartDate: this.StartDatetab2, EndDate: this.EndDatetab2, IdLocations: this.Idlocationtab2, DaysOfWeek: newstr, StartTime: this.StartTimetab2, EndTime: this.EndTimetab2 }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {
            // console.log("response sales list"+response);
            this.Tlists = response;
            if (this.Tlists.Response == 1) {
              // console.log("this.tlsts" + JSON.stringify(response));
              this.Trans = this.Tlists.ReportProducts;
              // console.log("this.Trans"+JSON.stringify(this.Trans));
              // this.resendMailTimeZone=this.selectedtimezone;
              //           this.reportinglocationForm.reset();
              //        // this.reportingmachineForm.reset();
              //         $('#checkall').removeClass("btn_active");
              //         $('#uncheckall').removeClass("btn_active");
              //         $('#uncheckallmac').removeClass("btn_active");
              //         $('#checkallmac').removeClass("btn_active");
              //         $('#uncheckalldays').removeClass("btn_active");
              // $('#checkalldays').removeClass("btn_active");

            }
          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }
  }

  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  listofmachine() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.PlaceMachineLstUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mposts = response.lst_sp_GetPlacedMachinesWithLocation;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  listofmachinetab2() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.PlaceMachineLstUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mpoststab2 = response.lst_sp_GetPlacedMachinesWithLocation;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }


  onChangeMachine(id: any) {
    id = id.map(function (e) { return JSON.stringify(e); });
    id = id.join(",");
    this.IdMachine = id;
  }

  onChangeMachinetab2(id: any) {
    id = id.map(function (e) { return JSON.stringify(e); });
    id = id.join(",");
    this.IdMachinetab2 = id;
  }
  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDate = $('#S1Date').val();
    //console.log(this.StartDate)
  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDate = $('#E2Date').val(); }

  addEvent1tab2(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDatetab2 = $('#S1Datetab2').val();
    //  console.log(this.StartDate)
  }
  addEvent2tab2(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDatetab2 = $('#E2Datetab2').val(); }
  addEvent3(type: string, event: MatDatepickerInputEvent<Date>) { this.StartDate = $('#S3Date').val(); }
  addEvent4(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDate = $('#E4Date').val(); }

  Searchbrand() {
    $("#salestable tr:gt(0)").remove();
    this.reportBrand = true;
    this.Tlists = [];
    this.Trans = [];
  }
  onChangebrand(value) {
    $('#checkallmacbrand').removeClass("btn_active");
    $('#uncheckallmacbrand').removeClass("btn_active");
 


 
    $('#checkallmac').removeClass("btn_active");
    $('#uncheckallmac').removeClass("btn_active");
   
    this.IdBrand = value.toString();
    this.IdProducts = "";
    this.selectedProduct = "";
    this.selectedMachine1="";
    this.IdMachine="";
    this.listofproduct();
    this.machinelistist();

    //console.log(this.IdBrand)
  }

  onChangebrandtab2(value) {
    this.IdBrandtab2 = value;
    this.IdProductstab2 = "";
    this.selectedProducttab2 = null;
    this.listofproducttab2();

    //console.log(this.IdBrand)
  }

  SearchSub() {
    $("#salestable tr:gt(0)").detach();
    this.reportBrand = false;
    this.Tlists = [];
    this.Trans = [];
  }

  selectAllmachine(id) {
    $('#checkallmac').addClass("btn_active");
    $('#uncheckallmac').removeClass("btn_active");
    if (id == 'machine') {
      this.arraymachine = [];
      for (let i = 0; i < this.Mposts.length; i++) {
        this.arraymachine.push(this.Mposts[i].IdMachine);
      }
      this.OnlyselectedMachine = this.arraymachine;
      this.IdMachines = this.OnlyselectedMachine.toString();
    } else if (id == 'product') {
      this.arrayproduct = [];
      for (let i = 0; i < this.Plistss.length; i++) {
        this.arrayproduct.push(this.Plistss[i].IdProduct);
      }
      this.selectedProduct = this.arrayproduct;
      this.IdProducts = this.selectedProduct.toString();
      this.selectedMachine1="";
      this.IdMachine="";
      this.machinelistist();

    }

  }


  selectAllmachinebrand(id) {
    $('#checkallmacbrand').addClass("btn_active");
    $('#uncheckallmacbrand').removeClass("btn_active");
    $('#checkallmac').removeClass("btn_active");
    $('#uncheckallmac').removeClass("btn_active");
    
    if (id == 'brandselect') {
      this.arraymachinebrand = [];
      for (let i = 0; i < this.Brandlist.length; i++) {
        this.arraymachinebrand.push(this.Brandlist[i].IdBrand);
      }
      this.selectedBrand = this.arraymachinebrand;
      this.IdBrand = this.selectedBrand.toString();
      this.selectedProduct = '';
      this.IdProducts = '';
      this.selectedMachine1='';
      this.IdMachine='';
      this.listofproduct();
      this.machinelistist();
    }

  }

  selectAllmachinestate(id) {
    $('#checkallmacstate').addClass("btn_active");
    $('#uncheckallmacstate').removeClass("btn_active");
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
    $('#checkallmacbrand').removeClass("btn_active");
    $('#uncheckallmacbrand').removeClass("btn_active");
    $('#checkallchain').removeClass("btn_active");
    $('#uncheckallchain').removeClass("btn_active");
    if (id == 'stateselect') {
      this.arraymachinestate = [];
      for (let i = 0; i < this.statelist.length; i++) {
        this.arraymachinestate.push(this.statelist[i].StateId);
      }
      this.selectedstate = this.arraymachinestate;
      this.IdState = this.selectedstate.toString();
      this.chainval="";
      this.selectedchains="";
this.selectedlocation="";
this.Idlocation="";
this.selectedMachine1="";
this.IdMachine="";
this.selectedBrand="";
this.IdBrand="";
this.selectedProduct="";
this.IdProducts="";

      this.refreshChainlist();
      this.onChangechain(this.chainval,this.IdState);
      this.brand();
      this.listofproduct();
      this.machinelistist();
      // this.selectedProduct = '';
      // this.IdProducts = '';
      // this.listofproduct();
    }

  }

  selectAllmachinetab2(id) {
    $('#checkallmactab2').addClass("btn_active");
    $('#uncheckallmactab2').removeClass("btn_active");
    if (id == 'machine') {
      this.arraymachinetab2 = [];
      for (let i = 0; i < this.Mpoststab2.length; i++) {
        this.arraymachinetab2.push(this.Mpoststab2[i].IdMachine);
      }
      this.OnlyselectedMachinetab2 = this.arraymachinetab2;
      this.IdMachinestab2 = this.OnlyselectedMachinetab2.toString();
    } else if (id == 'product') {
      this.arrayproducttab2 = [];
      for (let i = 0; i < this.Plistsstab2.length; i++) {
        this.arrayproducttab2.push(this.Plistsstab2[i].IdProduct);
      }
      this.selectedProducttab2 = this.arrayproducttab2;
      this.IdProductstab2 = this.selectedProducttab2.toString();

    }

  }


  deselectAllmachine(id) {
    $('#checkallmac').removeClass("btn_active");
    $('#uncheckallmac').addClass("btn_active");
    if (id == 'machine') {
      this.OnlyselectedMachine = '';
      this.IdMachines = '';
    } else if (id == 'product') {
      this.selectedProduct = '';
      this.IdProducts = '';
      this.selectedMachine1="";
      this.IdMachine="";
      this.machinelistist();
    }
  }

  deselectAllmachinebrand(id) {
    $('#checkallmacbrand').removeClass("btn_active");
    $('#uncheckallmacbrand').addClass("btn_active");

    $('#checkallmac').removeClass("btn_active");
    $('#uncheckallmac').removeClass("btn_active");


    if (id == 'brandselect') {
      this.selectedBrand = '';
      this.IdBrand = null;
      this.selectedProduct = '';
      this.IdProducts = '';
      this.selectedMachine1="";
      this.IdMachine="";
      this.listofproduct();
      this.machinelistist();
    }
  }


  deselectAllmachinestate(id) {
    $('#checkallmacstate').removeClass("btn_active");
    $('#uncheckallmacstate').addClass("btn_active");
    $('#checkallmacbrand').removeClass("btn_active");
    $('#uncheckallmacbrand').removeClass("btn_active");
    $('#checkallmacbrand').removeClass("btn_active");
    $('#uncheckallmacbrand').removeClass("btn_active");
    $('#checkallchain').removeClass("btn_active");
    $('#uncheckallchain').removeClass("btn_active");
    if (id == 'stateselect') {
      this.selectedstate = '';
      this.IdState = null;
      this.selectedProduct='';
      this.IdProducts='';
      this.chainval='';
      this.selectedchains='';
      this.Idlocation='';
      this.selectedlocation='';
      this.selectedBrand='';
      this.IdBrand='';
      this.selectedMachine1="";
      this.IdMachine="";
      this.refreshChainlist();
      this.onChangechain(this.chainval,this.IdState);
      this.brand();
      this.listofproduct();
      this.machinelistist();
      
      
      // this.selectedProduct = '';
      // this.IdProducts = '';
      // this.listofproduct();
    }
  }

  deselectAllmachinetab2(id) {
    $('#checkallmactab2').removeClass("btn_active");
    $('#uncheckallmactab2').addClass("btn_active");
    if (id == 'machine') {
      this.OnlyselectedMachinetab2 = '';
      this.IdMachinestab2 = '';
    } else if (id == 'product') {
      this.selectedProducttab2 = '';
      this.IdProductstab2 = '';
    }
  }

  brandbtn() { this.router.navigate([this.companyName + '/dashboard/samplingreport']); }
  shopperreport() { this.router.navigate([this.companyName + '/dashboard/samplingreport/shopper']); }
  storebtn() { this.router.navigate([this.companyName + '/dashboard/samplingreport/store']); }

  selectAll(id) {
    $('#checkall').addClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
    if (id == 'location') {
      this.nextLibAvailable = true;

      this.array = [];
      for (let i = 0; i < this.posts.length; i++) {
        this.array.push(this.posts[i].IdLocation);
      }
      this.selectedlocation = this.array;
      this.Idlocation = this.selectedlocation.toString();
      this.selectedMachine1="";
      this.IdMachine="";
      this.selectedBrand="";
      this.IdBrand="";
      this.selectedProduct="";
      this.IdProducts="";
      this.brand();
      this.listofproduct();
      this.machinelistist()
    } else if (id == 'machine') {
      this.array = [];
      for (let i = 0; i < this.Mlists.length; i++) {
        this.array.push(this.Mlists[i].IdMachine);
      }
      this.selectedMachine1 = this.array;
      this.IdMachine = this.selectedMachine1.toString();
    } else if (id == 'machine2') {
      this.array = [];
      for (let i = 0; i < this.Mposts.length; i++) {
        this.array.push(this.Mposts[i].IdMachine);
      }
      this.OnlyselectedMachine = this.array;
      this.IdMachine = this.OnlyselectedMachine.toString();
    }

    else if (id == 'chains') {
      this.arraychain = [];
      for (let i = 0; i < this.chainList.length; i++) {
        this.arraychain.push(this.chainList[i].IdChain);
      }
      this.selectedchains = this.arraychain;
      this.chainval = this.selectedchains.toString();
      this.selectedlocation = '';
      this.Idlocation = '';
      this.selectedMachine1 = "";
      this.IdMachine = "";
      this.selectedBrand='';
      this.IdBrand="";
      this.selectedProduct="";
      this.IdProducts="";
      this.onChangechain(this.chainval,this.IdState);
      
      this.brand();
      this.listofproduct();
      
      this.machinelistist()
    }
  }

  selectAllchain(id) {
    $('#checkallchain').addClass("btn_active");
    $('#uncheckallchain').removeClass("btn_active");

    $('#checkall').removeClass("btn_active");
    $('#uncheckall').removeClass("btn_active");

  
    $('#checkallmacbrand').removeClass("btn_active");
    $('#uncheckallmacbrand').removeClass("btn_active");
    $('#checkallmac').removeClass("btn_active");
    $('#uncheckallmac').removeClass("btn_active");
    $('#checkallmac').removeClass("btn_active");
    $('#uncheckallmac').removeClass("btn_active");
    if  (id == 'chains') {
      this.arraychain = [];
      for (let i = 0; i < this.chainList.length; i++) {
        this.arraychain.push(this.chainList[i].IdChain);
      }
      this.selectedchains = this.arraychain;
      this.chainval = this.selectedchains.toString();
      this.selectedlocation = '';
      this.Idlocation = '';
      this.selectedMachine1 = "";
      this.IdMachine = "";
      this.selectedBrand='';
      this.IdBrand="";
      this.selectedProduct="";
      this.IdProducts="";
      this.onChangechain(this.chainval,this.IdState);
      
      this.brand();
      this.listofproduct();
      
      this.machinelistist()
    }
  }


  selectAlltab2(id) {
    $('#checkalltab2').addClass("btn_active");
    $('#uncheckalltab2').removeClass("btn_active");
    if (id == 'location') {
      this.nextLibAvailabletab2 = true;

      this.arraytab2 = [];
      for (let i = 0; i < this.poststab2.length; i++) {
        this.arraytab2.push(this.poststab2[i].IdLocation);
      }
      this.selectedlocationtab2 = this.arraytab2;
      this.Idlocationtab2 = this.selectedlocationtab2.toString();
      this.machinelististtab2()
    } else if (id == 'machine') {
      this.arraytab2 = [];
      for (let i = 0; i < this.Mliststab2.length; i++) {
        this.arraytab2.push(this.Mliststab2[i].IdMachine);
      }
      this.selectedMachine1tab2 = this.arraytab2;
      this.IdMachinetab2 = this.selectedMachine1tab2.toString();
    } else if (id == 'machine2') {
      this.arraytab2 = [];
      for (let i = 0; i < this.Mpoststab2.length; i++) {
        this.arraytab2.push(this.Mpoststab2[i].IdMachine);
      }
      this.OnlyselectedMachinetab2 = this.array;
      this.IdMachinetab2 = this.OnlyselectedMachinetab2.toString();
    }
  }

  deselectAll(id) {
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').addClass("btn_active");
    if (id == 'location') {
      this.selectedlocation = '';
      this.Idlocation = '';
      this.selectedMachine1 = "";
      this.IdMachine = "";
      this.selectedProduct="";
      this.IdProducts="";
      this.brand();
      this.listofproduct();
      this.machinelistist()
    }
    else if (id == 'machine') {
      this.selectedMachine1 = '';
      this.IdMachine = '';
    }
    else if (id == 'machine2') {
      this.OnlyselectedMachine = '';
      this.IdMachine = '';
    }
    else if (id == 'chains') {
      this.chainval="";
      this.selectedchains="";
      this.selectedlocation = '';
      this.Idlocation = '';
      this.selectedMachine1 = "";
      this.IdMachine = "";
      this.selectedBrand='';
      this.IdBrand="";
      this.selectedProduct="";
      this.IdProducts="";
      this.onChangechain(this.chainval,this.IdState);
      
      this.brand();
      this.listofproduct();
      
      this.machinelistist()
    }

  }

  deselectAllchain(id) {
    $('#checkallchain').removeClass("btn_active");
    $('#uncheckallchain').addClass("btn_active");
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').removeClass("btn_active");


    $('#checkallmacbrand').removeClass("btn_active");
    $('#uncheckallmacbrand').removeClass("btn_active");
    $('#checkallmac').removeClass("btn_active");
    $('#uncheckallmac').removeClass("btn_active");
    $('#checkallmac').removeClass("btn_active");
    $('#uncheckallmac').removeClass("btn_active"); 
   if (id == 'chains') {
      this.chainval="";
      this.selectedchains="";
      this.selectedlocation = '';
      this.Idlocation = '';
      this.selectedMachine1 = "";
      this.IdMachine = "";
      this.selectedBrand='';
      this.IdBrand="";
      this.selectedProduct="";
      this.IdProducts="";
      this.onChangechain(this.chainval,this.IdState);
      
      this.brand();
      this.listofproduct();
      
      this.machinelistist()
    }

  }

  deselectAlltab2(id) {
    $('#checkalltab2').removeClass("btn_active");
    $('#uncheckalltab2').addClass("btn_active");
    if (id == 'location') {
      this.selectedlocationtab2 = '';
      this.Idlocationtab2 = '';
      this.selectedMachine1tab2 = "";
      this.IdMachinetab2 = "";
      this.machinelististtab2()
    }
    else if (id == 'machine') {
      this.selectedMachine1tab2 = '';
      this.IdMachinetab2 = '';
    }
    else if (id == 'machine2') {
      this.OnlyselectedMachinetab2 = '';
      this.IdMachinetab2 = '';
    }
  }


  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  coupon_detail(id) {
    $('#dots_spinner').show();
    $('#coupon_info').modal('show');
    this.http.post<any>(this.apiList.GetCouponDetailUrl, { IdCoupon: id, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.posts = response;
        if (this.posts.Response == 1) {
          //new addition
          //console.log("this.posts"+JSON.stringify(this.posts));
          this.coupon_code = this.posts.Obj_Coupon.CouponCode;
          this.coupon_amount = this.posts.Obj_Coupon.DiscountAmount;
          this.coupon_startdatetime = this.posts.Obj_Coupon.StartTime;
          this.coupon_startdate = this.posts.Obj_Coupon.SdateString;
          // this.coupon_startdate = moment(this.coupon_startdate).format('MM/DD/YYYY');
          this.coupon_enddate = this.posts.Obj_Coupon.EdateString;
          this.coupon_enddatetime = this.posts.Obj_Coupon.EndTime;
          this.coupon_discounttype = this.posts.Obj_Coupon.DiscountType;
          //this.coupon_enddate = moment(this.coupon_enddate).format('MM/DD/YYYY');


          //
          // console.log(this.posts);
          // this.CouponCode = this.posts.Obj_Coupon.CouponCode
          // this.CouponUse = this.posts.Obj_Coupon.CouponUse;
          // if (this.CouponUse == "PartialUse") {
          //   this.partiall = true
          // }
          // this.CouponType = this.posts.Obj_Coupon.CouponType;
          // this.Changecoupon_type = this.posts.Obj_Coupon.CouponUse;
          // this.DiscountAmount = this.posts.Obj_Coupon.DiscountAmount
          // this.DiscountType = this.posts.Obj_Coupon.DiscountType;
          // if (this.DiscountType == '%') {
          //   this.discount_show = 1
          // } else {
          //   this.discount_show = 2
          // }
          // this.EndDate = this.posts.Obj_Coupon.EdateString
          // this.StartDate = this.posts.Obj_Coupon.SdateString;
          // //  console.log(this.StartDate);
          // this.StartDate =  moment(this.Date).format('MM/DD/YYYY')
          // $('#SDate').val(this.StartDate);
          // $('#EDate').val(this.EndDate);
          // console.log( this.StartDate)
          // this.EndTime = this.posts.Obj_Coupon.EndTime
          // this.StartTime = this.posts.Obj_Coupon.StartTime
          // $('#timepicker1').timepicker('setTime', this.posts.Obj_Coupon.StartTime);
          // $('#timepicker2').timepicker('setTime', this.posts.Obj_Coupon.EndTime);
          // for (let i = 0; i < this.posts.lst_Machines.length; i++) {
          //   this.array.push(this.posts.lst_Machines[i].IdMachine);
          // }
          // this.selectedMachineproduct = this.array;
          // //console.log(this.selectedMachineproduct)
          // this.IdMachines = this.selectedMachineproduct.toString();
          // for (let i = 0; i < this.posts.lst_Products.length; i++) {
          //   array2.push(this.posts.lst_Products[i].IdProduct);
          // }
          // this.selectedProduct2 = array2;
          // this.IdProducts = this.selectedProduct2.toString();
          // this.refreshlistproductMachine();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide() });








    // this.coupon_code = code;
    // this.coupon_amount = amoumt;
    // this.coupon_used = used;
    // this.coupon_startdate = startdate;
    // this.coupon_enddate = enddate;
  }

  exportStore() {


    $('#dots_spinner').show();
    this.reportBrand = true;
    //console.log(this.selectedtimezone)
    if (this.StartDate && this.EndDate) {
      if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {
        //console.log(this.IdMachine, this.StartDate, this.EndDate, this.Idlocation);
        $('#dots_spinner').show();
        var a = this.optionsModel;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        this.http.post<any>(this.apiList.ExportStoreReport, { IdChain: this.chainval, IdBrands: this.IdBrand, IdProducts: this.IdProducts, IdMachines: this.IdMachine, StartDate: this.StartDate, EndDate: this.EndDate, IdState: this.IdState, DaysOfWeek: newstr, StartTime: this.StartTime, EndTime: this.EndTime,IdLocations:this.Idlocation }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {
            let result = response;
            if (result.Response == 1) {
              console.log("response Brand export" + JSON.stringify(response));
              let FileName = result.FileName
              window.location.href = this.CSVFileUrl + '/' + FileName;
            }

          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }


  }


  exportBrandSubReport() {


    $('#dots_spinner').show();
    this.reportBrand = false;
    //console.log(this.selectedtimezone)
    if (this.StartDatetab2 && this.EndDatetab2) {
      if (this.toTimestamp(this.StartDatetab2) <= this.toTimestamp(this.EndDatetab2)) {
        //console.log(this.IdMachine, this.StartDate, this.EndDate, this.Idlocation);
        $('#dots_spinner').show();
        var a = this.optionsModeltab2;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        this.http.post<any>(this.apiList.ExportBrandSubReport, { IdBrand: this.IdBrandtab2, IdProducts: this.IdProductstab2, IdMachines: this.IdMachinetab2, StartDate: this.StartDatetab2, EndDate: this.EndDatetab2, IdLocations: this.Idlocationtab2, DaysOfWeek: newstr, StartTime: this.StartTimetab2, EndTime: this.EndTimetab2 }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {
            let result = response;
            if (result.Response == 1) {
             // console.log("response Brand export" + JSON.stringify(response));
              let FileName = result.FileName
              window.location.href = this.CSVFileUrl + '/' + FileName;
            }

          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }


  }

  PrintrouteStore() {
    $('#dots_spinner').show();
    this.reportBrand = true;
    //console.log(this.selectedtimezone)
    if (this.StartDate && this.EndDate) {
      if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {
        //console.log(this.IdMachine, this.StartDate, this.EndDate, this.Idlocation);
        $('#dots_spinner').show();
        var a = this.optionsModel;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        this.http.post<any>(this.apiList.PrintStoreReport, { IdChain: this.chainval, IdBrands: this.IdBrand, IdProducts: this.IdProducts, IdMachines: this.IdMachine, StartDate: this.StartDate, EndDate: this.EndDate, IdState: this.IdState, DaysOfWeek: newstr, StartTime: this.StartTime, EndTime: this.EndTime,IdLocations:this.Idlocation }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {

            var data = response;
            if (data.Response == 1) {
              data = data.HtmlText
              var popupWinindow = window.open('', '_blank', 'width=1366, height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
              popupWinindow.document.open();
              popupWinindow.document.write('<html><head></head><body onload="window.print()" onafterprint="window.close()">' + data + '</html>');
              popupWinindow.document.close();
            }

          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }



  }


  PrintrouteBrandSubReport() {
    //$('#dots_spinner').show();
    this.reportBrand = false;
    //console.log(this.selectedtimezone)
    if (this.StartDatetab2 && this.EndDatetab2) {
      if (this.toTimestamp(this.StartDatetab2) <= this.toTimestamp(this.EndDatetab2)) {
        //console.log(this.IdMachine, this.StartDate, this.EndDate, this.Idlocation);
        $('#dots_spinner').show();
        var a = this.optionsModeltab2;
        var newstr = a.toString().replace(/\[.*\]/g, '');
        this.http.post<any>(this.apiList.PrintBrandSubReport, { IdBrand: this.IdBrandtab2, IdProducts: this.IdProductstab2, IdMachines: this.IdMachinetab2, StartDate: this.StartDatetab2, EndDate: this.EndDatetab2, IdLocations: this.Idlocationtab2, DaysOfWeek: newstr, StartTime: this.StartTimetab2, EndTime: this.EndTimetab2 }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {

            var data = response;
           // console.log("response print" + JSON.stringify(data));

            if (data.Response == 1) {
              data = data.HtmlText
              var popupWinindow = window.open('', '_blank', 'width=1366, height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
              popupWinindow.document.open();
              popupWinindow.document.write('<html><head></head><body onload="window.print()" onafterprint="window.close()">' + data + '</html>');
              popupWinindow.document.close();
            }

          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }



  }


}
