import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Renderer, AfterViewInit, AfterContentChecked } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
declare var google
declare const $

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, OnDestroy {
  public VendEvents: EventSource;
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");
  logoCompanyName = sessionStorage.getItem("logocompanyName");
  Sessionid_Admin = sessionStorage.getItem('Sessionid_Admin')
  @ViewChild('map',{static: false}) public mapElement: ElementRef;
  @ViewChild('myIn11',{static: false}) public myIn11: ElementRef;
  map: any;
  Machinelist = [];
  activeInfoWindow: any;
  CompanyName: any;
  Gotoplanogram: string;
  Gotomachine: string;
  pos: { lat: number; lng: number; };
  result: any;
  EventUrl: any;
  Marker: any;
  Merchantdetail: any;
  bounds: any;
  show_result: any
  boxText: any
  activeInfoWindow2: any;
  MachineStatus: any;
  detail_id: any;
  picker_lat: any;
  picker_long: any;
  AllMarkers = []

  AlertText: string;

  constructor(private router: Router, private http: HttpClient, 
    private globalservice: GlobalService, 
    private elementRef: ElementRef, 
    private renderer: Renderer,
    public apiList: ApilistService) {
  }

  ngOnInit() {
    $("#alert_div").hide();
    $('#dots_spinner').hide();
    this.Listofmachine()
  }

  Listofmachine() {
    $('#dots_spinner').show();
    if (this.Sessionid_Admin) {    //--When login with Merchant
      this.Sessionid_Admin = sessionStorage.getItem('Sessionid_Admin')
    } else {  //--When login with Superadmin
      this.Sessionid_Admin = ''
    }
    this.http.post<any>(this.apiList.MachinelstStatusUrl, { IdMerchant: this.IdMerchant, Idmachines: null, SuperAdminId: '0', SessionId: this.Sessionid_Admin }, this.globalservice.Headers()).subscribe((response) => {
      this.result = response
      //  console.log(this.result)
      $('#dots_spinner').hide();
      if (this.result.Response == 1) {
        $('#dots_spinner').hide();
        this.Merchantdetail = this.result.obj_MerchantLocation
        this.Machinelist = this.result.lst_Machines
        this.EventUrl = this.result.EventUrl;
        var id = this.result.EventUrl.split("=")
        sessionStorage.setItem('Sessionid_Admin', id[1])
        this.VendEvents = new EventSource(this.EventUrl);
        this.VendEvents.onmessage = (e) => {
          var Responsee = e.data;
          var Response = JSON.parse(Responsee)
          for (let i = 0; i < Response.length; i++) {
            for (let j = 0; j < this.Machinelist.length; j++) {
              var check = 1
              for (let l = 0; l < this.Machinelist[j].lst_DashBoardMachines.length; l++) {
                if (Response[i].objChange.IdMachine == this.Machinelist[j].lst_DashBoardMachines[l].IdMachine) {
                  check = 0
                  //-------------remove the machine ------------------
                  if (Response[i].objChange.RemoveStatus == true) {
                    this.Machinelist[j].lst_DashBoardMachines.splice(l, 1)
                    for (let m = 0; m < this.AllMarkers.length; m++) {
                      if (this.AllMarkers[m].lat_m == this.Machinelist[j].Latitude && this.AllMarkers[m].long_m == this.Machinelist[j].Longitude) {
                        this.Marker = this.AllMarkers[m];
                        this.Marker.setMap(null);
                      }
                    }
                  } else {
                    //-------------Update the Uptime and connect disconnect status ------------------
                    if (Response[i].objChange.Status) {
                      this.Machinelist[j].lst_DashBoardMachines[l].Status = Response[i].objChange.Status;
                      this.Machinelist[j].lst_DashBoardMachines[l].UpTime = Response[i].objChange.UpTime;
                      if (this.detail_id == Response[i].objChange.IdMachine) {
                        $("#secondmarker h5 #up").text(Response[i].objChange.UpTime);
                      }
                    }
                    if (Response[i].objChange.MachineStatus) {
                      this.Machinelist[j].lst_DashBoardMachines[l].MachineStatus = Response[i].objChange.MachineStatus;
                      if (this.detail_id == Response[i].objChange.IdMachine) {
                        $("#secondmarker h5 #st").attr("class", Response[i].objChange.MachineStatus);
                      }
                    }
                  }
                }
              }
              if (check == 1) {
                if (Response[i].objChange.RemoveStatus == false) {
                  //----------Add new machine on existing lat long---------
                  if (Response[i].objChange.Latitude == this.Machinelist[j].Latitude && Response[i].objChange.Longitude == this.Machinelist[j].Longitude) {
                  this.Machinelist[j].lst_DashBoardMachines.push(Response[i].objChange);
                  } else {
                     //----------Add new machine and new lat long---------
                    var IslatitudeExist = this.Machinelist.some(function (o) { return o["Latitude"] === Response[i].objChange.Latitude && o["Longitude"] === Response[i].objChange.Longitude });
                    if (!IslatitudeExist) {
                      var dashboardMac = [];
                      dashboardMac.push(Response[i].objChange);
                      var data = {
                        Latitude: Response[i].objChange.Latitude,
                        Longitude: Response[i].objChange.Longitude,
                        lst_DashBoardMachines: dashboardMac
                      }
                      this.Machinelist.push(data);
                      break;
                    }
                  }
                }
              }
            }
          }
          this.UpdateMarker()
          // })
        }
        this.VendEvents.addEventListener('my_event', function (e) {
          // console.log(e);
        })
        this.getCurrent_location();
      }
      
      else if (this.result.Response === 35) {
        this.AlertText = "";
        this.AlertText = this.result.ErrorMessage
        if(this.AlertText) {
          this.FlashError();
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
      }
      else {
        this.AlertText = "";
        this.AlertText = this.result.ErrorMessage
        this.FlashError();
      }
    })
  }
  FlashError() {
    $("#alert_div").show();
    $("#alert_div").animate({
      width: "100%"
    }, "slow");
    setTimeout(() => {
      $("#alert_div").hide();
    }, 3000);
  }
  getCurrent_location() {
    var that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        that.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        that.Pin_Point();
      },
        function (error) {
          //  console.log(error);
        });
    }
  }

  Pin_Point() {
    if (this.Machinelist.length > 0) {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: new google.maps.LatLng(this.Merchantdetail.Latitude, this.Merchantdetail.Longitude),
      })
      this.bounds = new google.maps.LatLngBounds();
    } else {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: this.pos,
        zoom: 5,
      })
    }
    this.setMarkers();
  }

  setMarkers() { // create marker
    var that = this;
    if (this.Machinelist.length > 0) {
      for (let i = 0; i < this.Machinelist.length; i++) {
        var IsDown = false;
        var IsIntermittent = false;
        var IsUp = false;
        for (let j = 0; j < this.Machinelist[i].lst_DashBoardMachines.length; j++) {    //-- Down > Intermittent > Up
          var Lat = '';
          var Long = '';
          var Icon = '';
          if (this.Machinelist[i].lst_DashBoardMachines[j].Status == 'Down') {
            IsDown = true;
          }
          if (this.Machinelist[i].lst_DashBoardMachines[j].Status == 'Intermittent') {
            IsIntermittent = true;
          }
          if (this.Machinelist[i].lst_DashBoardMachines[j].Status == 'Up') {
            IsUp = true;
          }
          Lat = this.Machinelist[i].Latitude;
          Long = this.Machinelist[i].Longitude;
        }
        if (IsDown == true) {
          Icon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'

        }
        else if (IsIntermittent == true) {
          Icon = 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png'
        }
        else if (IsUp == true) {
          Icon = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'

        }
        this.Marker = new google.maps.Marker({
          map: that.map,
          icon: Icon,
          position: new google.maps.LatLng(Lat, Long),
          lat_m: Lat,
          long_m: Long
        });
        this.AllMarkers.push(this.Marker)
        this.bounds.extend(this.Marker.position);
        this.FirstInfoWindow(this.Marker, this.Machinelist)
      }
      this.map.fitBounds(this.bounds);
    } else {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: new google.maps.LatLng(this.Merchantdetail.Latitude, this.Merchantdetail.Longitude),
        zoom: 5,
      })
    }
  }

  UpdateMarker() {   //update marker
    var that = this;
    var promise = new Promise((resolve, reject) => {
      if (this.Machinelist.length > 0) {
        for (let i = 0; i < this.Machinelist.length; i++) {
          var IsDown = false;
          var IsIntermittent = false;
          var IsUp = false;
          for (let j = 0; j < this.Machinelist[i].lst_DashBoardMachines.length; j++) {
            if (this.Machinelist[i].lst_DashBoardMachines.length > 0) {
              var Lat = '';
              var Long = '';
              var Icon = '';
              if (this.Machinelist[i].lst_DashBoardMachines[j].Status == 'Down') {
                IsDown = true;
              }
              if (this.Machinelist[i].lst_DashBoardMachines[j].Status == 'Intermittent') {
                IsIntermittent = true;
              }
              if (this.Machinelist[i].lst_DashBoardMachines[j].Status == 'Up') {
                IsUp = true;
              }
              Lat = this.Machinelist[i].Latitude;
              Long = this.Machinelist[i].Longitude;
            }
          }
          if (IsDown == true) {
            Icon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
          }
          else if (IsIntermittent == true) {
            Icon = 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png'
          }
          else if (IsUp == true) {
            Icon = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
          }
          this.Marker = new google.maps.Marker({
            map: that.map,
            icon: Icon,
            position: new google.maps.LatLng(Lat, Long),
            lat_m: Lat,
            long_m: Long
          });
          this.AllMarkers.push(this.Marker)
          this.FirstInfoWindow(this.Marker, this.Machinelist)
        }
      } else {
        this.map = new google.maps.Map(document.getElementById('map'), {
          center: new google.maps.LatLng(this.Merchantdetail.Latitude, this.Merchantdetail.Longitude),
          zoom: 5,
        })
      }
    })
  }

  FirstInfoWindow(marker, machine) {
    var that = this
    marker.addListener('click', function (event) {
      if (that.activeInfoWindow || that.activeInfoWindow2) {
        if (that.activeInfoWindow && that.activeInfoWindow2) {
          that.activeInfoWindow.close();
          that.activeInfoWindow2.close();
        } else if (that.activeInfoWindow) {
          that.activeInfoWindow.close();
        } else {
          that.activeInfoWindow2.close();
        }
      }
      that.show_result = [];
      for (var i = 0; i < machine.length; i++) {
        if (this.lat_m == machine[i].Latitude && this.long_m == machine[i].Longitude) {
          that.show_result.push(machine[i])
        }
      }
      if (that.show_result[0].lst_DashBoardMachines.length > 1) {   // Exist multiple machine on one marker
        that.picker_lat = that.show_result[0].Latitude
        that.picker_long = that.show_result[0].Longitude
        this.boxText = document.createElement("div");
        this.boxText.setAttribute('id', 'info-header');
        for (var j = 0; j < that.show_result.length; j++) {
          for (var k = 0; k < that.show_result[j].lst_DashBoardMachines.length; k++) {
            this.boxText.innerHTML = this.boxText.innerHTML + '<div id=' + that.show_result[j].lst_DashBoardMachines[k].IdMachine + ' class="pickerMap ' + that.show_result[j].lst_DashBoardMachines[k].Status + '"><h5 id=MS' + that.show_result[j].lst_DashBoardMachines[k].IdMachine + ' class="info_machineserial" title=' + that.show_result[j].lst_DashBoardMachines[k].LocationName.replace(/[ ]/g, "\u00a0") + ' >' + that.show_result[j].lst_DashBoardMachines[k].MachineSerial + '</h5></div>';
          }
        }
        var infowindow = new google.maps.InfoWindow({
          content: this.boxText,
          maxWidth: 400
        });
        infowindow.open(that.map, marker);
        that.activeInfoWindow = infowindow;
        google.maps.event.addListener(that.map, 'click', function () {
          infowindow.close();
        });
        google.maps.event.addListener(infowindow, 'domready', function () {
          $("<div/>", { class: "info-window-header", text: that.logoCompanyName }).appendTo("#info-header");
          $("<div/>", { class: "arrow_down" }).appendTo("#info-header");
          for (let k = 0; k < that.show_result[0].lst_DashBoardMachines.length; k++) {
            that.elementRef.nativeElement.querySelector('#MS' + that.show_result[0].lst_DashBoardMachines[k].IdMachine).addEventListener('click', function (event) {
              $('.selected').removeClass("selected");
              $('#MS' + that.show_result[0].lst_DashBoardMachines[k].IdMachine).addClass('selected');
              if (that.activeInfoWindow2) {
                that.activeInfoWindow2.close();
              }
              var id = event.target.id.substring(2);
              that.Second_infowindow(id, marker, 130, -50)
            });
          }
        });
      }
      else {   // Exist only one machine on one marker
        if (that.activeInfoWindow || that.activeInfoWindow2) {
          if (that.activeInfoWindow && that.activeInfoWindow2) {
            that.activeInfoWindow.close();
            that.activeInfoWindow2.close();
          } else if (that.activeInfoWindow) {
            that.activeInfoWindow.close();
          } else {
            that.activeInfoWindow2.close();
          }
        }
        that.Second_infowindow(that.show_result[0].lst_DashBoardMachines[0].IdMachine, marker, 0, 0)
      }
    })
  }

  Second_infowindow(id, marker, sizex, sizey) {    //--Machine detail popup
    this.detail_id = id
    //console.log("machine id"+id);
    var that = this;
    that.http.post<any>(that.apiList.MachineDetail, { IdMachine: id }, this.globalservice.Headers()).subscribe((response) => {
      let Details = response;
      //console.log("details of popup"+JSON.stringify(Details));
      if (Details.Response == 1) {
        this.MachineStatus = Details.obj_MachineDetail.MachineStatus;
        sessionStorage.setItem("refrencemachineid", that.show_result[0].lst_DashBoardMachines[0].IdMachine);
        sessionStorage.setItem("referMachinetype", "Master");
        sessionStorage.setItem("referProductType", null);
        that.Gotoplanogram =  environment.childUrl+'/#/' + that.companyName + '/dashboard/placeaMachine/Planogramtraymotor;l_c=' + Details.obj_MachineDetail.LinkCode + ';c_m=' + Details.obj_MachineDetail.Machine + ';I_l=' + Details.obj_MachineDetail.IdLocation + ";id="+id+";idplace="+Details.obj_MachineDetail.IdPlaceMachine
        that.Gotomachine =  environment.childUrl+'/#/' + that.companyName + '/dashboard/machine;code=' + Details.obj_MachineDetail.LinkCode;
      }
      else if (Details.Response === 35) {
        this.AlertText = "";
        this.AlertText = Details.ErrorMessage
        if(this.AlertText) {
          this.FlashError();
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
      }
      else {
        this.AlertText = "";
        this.AlertText = Details.ErrorMessage;
        this.FlashError();
      }
      if (sizex == 0 && sizey == 0) {   //-- When open only machine detail popup
        var contentString1 =
          '<div class="info-window" id="secondmarker" closeLocation>' +
          '<div class="info-window-header">' + Details.obj_MachineDetail.LocationName + ' </div>' +
          '<h5 class="single2_machine"><span  id="st" class="' + this.MachineStatus + '" ></span>' + Details.obj_MachineDetail.MachineSerial + '<span id="up" class="badge badge-primary">' + Details.obj_MachineDetail.UpTime + '</span></h5>' +
          '<a class="btn btn-xs" href=' + that.Gotomachine + '>Go to Machine</a>' +
          '<a class="btn btn-xs" href=' + that.Gotoplanogram + '>Go to Planogram</a>' +
          '<div class="arrow_down"></div>' +
          '</div>';
      } else if (sizex == 130 && sizey == -50) {  //-- When we click on machine serial
        var contentString1 =
          '<div class="info-window" id="secondmarker" closeLocation>' +
          '<div class="info-window-header">' + Details.obj_MachineDetail.LocationName + ' </div>' +
          '<h5 class="single2_machine"><span  id="st" class="' + this.MachineStatus + '" ></span>' + Details.obj_MachineDetail.MachineSerial + '<span id="up" class="badge badge-primary">' + Details.obj_MachineDetail.UpTime + '</span></h5>' +
          '<a class="btn btn-xs" href=' + that.Gotomachine + '>Go to Machine</a>' +
          '<a class="btn btn-xs" href=' + that.Gotoplanogram + '>Go to Planogram</a>' +
          '</div>';
      }
      var myoptions = {
        content: contentString1,
        maxWidth: 400
        , pixelOffset: new google.maps.Size(sizex, sizey)
        , zIndex: 99999
      }
      var infowindow2 = new google.maps.InfoWindow(myoptions);
      infowindow2.open(that.map, marker);
      that.activeInfoWindow2 = infowindow2;
      google.maps.event.addListener(that.map, 'click', function () {
        infowindow2.close();
      });
    },(err)=>{
     // $('#dots_spinner').hide();
    })
  }

  ngOnDestroy(): void {
    //console.log("Destroy");
    if(this.VendEvents){
    this.VendEvents.close();
    }
  }
}


