import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { NgForm } from "@angular/forms";
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import { ApilistService } from '../../../../service/Api/apilist.service';
declare const $;
@Component({
  selector: 'app-uploadtheme',
  templateUrl: './uploadtheme.component.html',
  styleUrls: ['./uploadtheme.component.css']
})
export class UploadthemeComponent implements OnInit {
  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;
  @ViewChild('ThemeForm', { static: false }) public ThemeForm: NgForm;
  BaseFileUrl = environment.BaseFileUrl;

  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  Imagelink = this.BaseFileUrl + "/Themes/";

  imageresponse
  themeName
  MainImage
  ResolutionType: number = 0;
  posts
  AlertText = "";
  saved = false
  FileMD5Sum: number;
  themecolour: string;
  color;
  themetype;
  theme_type: any;
  blob: Blob;
  FileName: any;

  constructor(private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    private cpService: ColorPickerService,
    public apiList: ApilistService) { }

  ngOnInit() {
    this.themetype = 'Image';
    this.theme_type = 'Image';
    this.HidePopups()
    $(function () {
      $('#myImg').hide();

      $("#file-7").change(function () {
        if (this.files && this.files[0]) {
          var reader = new FileReader();
          reader.onload = imageIsLoaded1;
          reader.readAsDataURL(this.files[0]);
          $('#myImg').fadeIn("slow", function () {
          });
          $('#upload-btn-wrapper').hide();
        }
      });
    });
    function imageIsLoaded1(e) {
      $('#myImg').attr('src', e.target.result);
    };
    (function (document, window, index) {
      var inputs = document.querySelectorAll('.inputfile');
      Array.prototype.forEach.call(inputs, function (input) {
        var label = input.nextElementSibling,
          labelVal = label.innerHTML;
        input.addEventListener('change', function (e) {
          var fileName = '';
          if (this.files && this.files.length > 1)
            fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
          else
            fileName = e.target.value.split('\\').pop();

          // if (fileName)
          //   //label.querySelector('span').innerHTML = fileName;
          // else
          //   label.innerHTML = labelVal;
        });
        input.addEventListener('focus', function () { input.classList.add('has-focus'); });
        input.addEventListener('blur', function () { input.classList.remove('has-focus'); });
      });
    }(document, window, 0));
  }

  HidePopups() {
    $('#Preview').hide();
    $('#alert_div').hide();
    $('#dots_spinner').hide();
    $('#uploadSuccess').modal('hide');
  }


  // fileChangemain(event) {
  //   let fileList = event.target.files;
  //   if (fileList.length > 0) {
  //     $('#dots_spinner').show();
  //     let file = fileList[0];
  //     let formData: FormData = new FormData();
  //     formData.append('ClientDocs', file, file.name);
  //     this.MainImage = file.name;

  //     this.http.post<any>(`${this.apiList.uploadApi}`, formData, this.globalservice.MediaHeaders())
  //       .timeout(25000)
  //       .take(1)
  //       .map(resp => {
  //         if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
  //         if (+resp.Response !== 1) { throw resp; }
  //         return resp;
  //       })
  //       .subscribe((response) => {
  //         this.imageresponse = response;
  //         if (this.imageresponse.Response == 1) {
  //           $('#Preview').show();
  //           this.MainImage = file.name;
  //           this.themecolour = '';
  //           this.FileMD5Sum = 1;
  //           $('#myImg').show(2000, function () {
  //             $('#uploadSuccess').modal('show');
  //           });
  //           $('#upload-btn-wrapper').hide();
  //           if (this.imageresponse.ErrorMessage == "Vertical Theme Uploaded") {
  //             this.ResolutionType = 1;
  //           }
  //           else if (this.imageresponse.ErrorMessage == "Horizontal Theme Uploaded") {
  //             this.ResolutionType = 0;
  //           }
  //         }
  //       }, (err: any) => {
  //         const Error = this.globalservice.ProcessError(err);
  //         if (Error.Response === 35) {
  //           if (Error.ErrorMessage) {
  //             this.FlashError(Error.ErrorMessage);
  //             this.globalservice.Logout('Yes');
  //           }
  //           this.globalservice.Logout('No');
  //           return;
  //         } else {
  //           this.FlashError(Error.ErrorMessage);
  //           return;
  //         }
  //       }).add(() => { $('#dots_spinner').hide(); });
  //   }
  // }







  type(value) {
    //  console.log(value);
    this.theme_type = value;
    if (this.theme_type == 'Color') {
      $('#Preview').show();
      this.themecolour = '#ffffff';
      this.ResolutionType = null;
    } else if (this.theme_type == 'Image') {
      $('#Preview').hide();
    }

  }

  fileChangemain(file: File) {
    // if (this.upload_image == 1) {
    $('#dots_spinner').show();
    this.blob = new Blob([file[0]], { type: file[0].type });
    console.log(file)
    this.globalservice.ThemeConfiguration(file).then((res: any) => {
      $('#dots_spinner').hide();
      console.log(res)
      if (res.Response == 1) {
        this.uploadFile();
      }
    }, error => {
      $('#dots_spinner').hide();
      console.log(error)
      const Error = this.globalservice.ProcessError(error);
      if (Error.Response === 35) {
        if (Error.ErrorMessage) {
          this.FlashError(Error.ErrorMessage);
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
        return;
      } else if (Error.Response == 4) {
        this.FlashError(Error.ErrorMessage);
      }
      else {
        console.log(Error.ErrorMessage)
        // event.target.value = null;
        this.FlashError(Error.ErrorMessage);
        return;
      }
    })
  }
  uploadFile() {
    $('#dots_spinner').show();
    this.globalservice.uploadFiles(this.blob).then((res: any) => {
      if (res.Response == 1) {
        $('#dots_spinner').hide();
        $('#Preview').show();
        this.MainImage = res.PreviewUrl;
        this.FileName=res.FileName;
        this.themecolour = '';
        this.FileMD5Sum = 1;
        $('#myImg').show(2000, function () {
          $('#uploadSuccess').modal('show');
        });
        $('#upload-btn-wrapper').hide();
        if (res.ErrorMessage == "Vertical Theme Uploaded") {
          this.ResolutionType = 1;
        }
        else if (res.ErrorMessage == "Horizontal Theme Uploaded") {
          this.ResolutionType = 0;
        }

      }
    }, error => {
      const Error = this.globalservice.ProcessError(error);
      if (Error.Response === 35) {
        if (Error.ErrorMessage) {
          this.FlashError(Error.ErrorMessage);
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
        return;
      }
      // else if (Error.Response == 4) {
      //     $('#myModal').modal('show');
      // }
      else {
        console.log(Error.ErrorMessage)
        // event.target.value = null;
        this.FlashError(Error.ErrorMessage);
        return;
      }
    })
  }

  save() {
    if (this.MainImage || this.themecolour) {
      $('#dots_spinner').show();
      //console.log(this.themeName, this.MainImage, this.ResolutionType);
      this.http.post<any>(this.apiList.UploadThemeMerchantUrl, { IdMerchant: this.IdMerchant, ThemeName: this.themeName, ThemeImage:this.FileName, ThemeColor: this.themecolour, ResolutionType: this.ResolutionType }, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(resp => {
          if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
          if (+resp.Response !== 1) { throw resp; }
          return resp;
        })
        .subscribe((response) => {
          this.posts = response;
          //console.log(response);
          if (this.posts.Response == 1) {
            this.saved = true;
            this.router.navigate([this.companyName + '/dashboard/Theme']);
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#dots_spinner').hide(); });
    }
    else {
      this.FlashError("Please Select theme first");
    }

  }


  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  back() {
    this.router.navigate([this.companyName + '/dashboard/Theme']);
  }

  public onChangeColorCmyk(color: string): Cmyk {
    this.themecolour = color;
    this.MainImage = ''
    $('#Preview').show();
    this.ResolutionType = null;
    return new Cmyk(0, 0, 0, 0);
  }


  cpInputChange(value) {
    // console.log(value);
  }
}
