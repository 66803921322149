import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Rx';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';
declare const $;
@Component({
    selector: 'app-subcategorylist',
    templateUrl: './subcategorylist.component.html',
    styleUrls: ['./subcategorylist.component.css']
})
export class SubcategorylistComponent implements OnInit, OnDestroy {
    @ViewChild('myInput', { static: false }) public myInputVariable: ElementRef;
    @ViewChild('subcategory', { static: false }) public subcategoryForm: NgForm;
    IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
    companyName = sessionStorage.getItem('companyName');
   // names: any;
    posts: any;
    IdMainCategory = '';
    MainCategoryName = '';
    CreatedBy = '';
    Description = '';
    SubCategoryName = '';
    ImageName = '';
    imageresponse: any;
    upload_image;
    AlertText;
    option;
    isAdding = false;
    fileList: any;
    Mainfile: any;
    image;
    bit: any = false;
    blob: Blob;
    FileName: any;
    constructor(private router: Router,
        private http: HttpClient,
        public dialog: MatDialog,
        private globalservice: GlobalService, public apiList: ApilistService) { }

    ngOnInit() {
        this.globalservice.setDiscard_check();
        this.refreshlistmaincategory();
        this.hidepops();
        $(function () {
            $('#file').change(function () {
                if (this.files && this.files[0]) {
                    const reader = new FileReader();
                    reader.onload = imageIsLoaded;
                    reader.readAsDataURL(this.files[0]);
                    this.MainImage = this.files[0].name;
                }
            });
        });

        function imageIsLoaded(e) {
            $('#myImg').attr('src', e.target.result);
        }
    }

    hidepops() {
        $('#alert_div').hide();
        $('#dots_spinner').hide();
        $('#myImg').hide();
        $('#main_img_close').hide();
    }
    canDeactivate(): any {
        if (!this.isAdding && this.subcategoryForm.dirty) {
            if (this.bit === false && this.globalservice.GetDiscard_Check()) {
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });
                return dialogRef.afterClosed().subscribe(result => {
                    if (result && (result.response === 'ok')) {
                        this.dialog.closeAll();
                        this.bit = true;
                        this.router.navigate([this.companyName + '/dashboard/categories']);
                    }
                });
            }
        } else if (this.subcategoryForm.dirty) {
            if (this.bit === false) {
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });
                return dialogRef.afterClosed().subscribe(result => {
                    if (result && (result.response === 'ok')) {
                        this.dialog.closeAll();
                        this.bit = true;
                        this.router.navigate([this.companyName + '/dashboard/categories']);
                    }
                });
            }
        }
        return true;
    }
    FlashError(message: string) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(() => {
            $('#alert_div').hide();
        }, 3000);
    }

    refreshlistmaincategory() {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.CategoryListUrl, { IdMerchant: this.IdMerchant },
            this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(resp => {
                if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                if (+resp.Response !== 1) { throw resp; }
                return resp;
            }).subscribe((response) => {
                if (response.Response === 1) {
                    this.posts = response;
                    this.posts = this.posts.lst_mc;
                }
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => { $('#dots_spinner').hide(); });
    }

    closebutton() {
        this.myInputVariable.nativeElement.value = '';
        this.upload_image = '';
        $('#myImg').hide();
        $('#main_img_close').hide();
        $('#upload-btn-wrapper').show();
    }

    // fileChange(event) {

    //     this.fileList = event.target.files;
    //     if (this.fileList.length > 0) {
    //         $('#dots_spinner').show();
    //         this.Mainfile = this.fileList[0];
    //         this.ImageName = this.Mainfile.name.replace(/\s/g, '');
    //         const formData: FormData = new FormData();
    //         formData.append('ClientDocs', this.Mainfile, this.Mainfile.name.replace(/\s/g, ''));
    //         this.http.post<any>(this.apiList.CheckTransparency, formData, this.globalservice.MediaHeaders())
    //             .timeout(25000)
    //             .take(1)
    //             .map(resp => {
    //                 if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
    //                 if (+resp.Response !== 1) { throw resp; }
    //                 return resp;
    //             })
    //             .subscribe((response) => {
    //                 const check1 = response;
    //                 if (check1.Response === 1) {
    //                     this.ImageUpload(formData, this.Mainfile);
    //                 } 
    //                 // else if (check1.Response === 5) {
    //                 //     this.FlashError('Image is not of Size 500 *500');
    //                 // }  else if (check1.Response === 0) {
    //                 //     this.FlashError('Something went wrong');
    //                 // } else {
    //                 //     this.FlashError(check1.ErrorMessage);
    //                 // }
    //             }, (err: any) => {
    //                 const Error = this.globalservice.ProcessError(err);
    //                 if (Error.Response === 35) {
    //                     if (Error.ErrorMessage) {
    //                         this.FlashError(Error.ErrorMessage);
    //                         this.globalservice.Logout('Yes');
    //                     }
    //                     this.globalservice.Logout('No');
    //                     return;
    //                 }else if (Error.Response === 4) {
    //                     $('#myModal').modal('show');
    //                 } else {
    //                     this.FlashError(Error.ErrorMessage);
    //                     return;
    //                 }
    //             }).add(() => { $('#dots_spinner').hide(); });
    //     }
    // }

    // Yes() {
    //     if (this.fileList.length > 0) {
    //         const file = this.fileList[0];
    //         const formData: FormData = new FormData();
    //         formData.append('ClientDocs', file, file.name.replace(/\s/g, ''));
    //         this.ImageUpload(formData, file);
    //     }
    // }


    // ImageUpload(formData, file) {
    //     $('#dots_spinner').show();
    //     this.http.post<any>(this.apiList.MediaUpload, formData, this.globalservice.MediaHeaders())
    //         .timeout(25000)
    //         .take(1)
    //         .map(resp => {
    //             if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
    //             if (+resp.Response !== 1) { throw resp; }
    //             return resp;
    //         })
    //         .subscribe((response) => {
    //             if (response.Response === 1) {
    //                 this.ImageName = response.FileName;
    //                 this.upload_image = 1;
    //                 $('#myImg').show(2000, function () {
    //                     $('#main_img_close').show();
    //                     $('#uploadSuccess').modal('show');
    //                 });
    //                 $('#upload-btn-wrapper').hide();
    //             }
    //         }, (err: any) => {
    //             const Error = this.globalservice.ProcessError(err);
    //             if (Error.Response === 35) {
    //                 if (Error.ErrorMessage) {
    //                     this.FlashError(Error.ErrorMessage);
    //                     this.globalservice.Logout('Yes');
    //                 }
    //                 this.globalservice.Logout('No');
    //                 return;
    //             } else {
    //                 this.FlashError(Error.ErrorMessage);
    //                 return;
    //             }
    //         }).add(() => { $('#dots_spinner').hide(); });
    // }

    getID(id: string) {
        this.IdMainCategory = id;
    }

    subcategory_submit() {
        if (this.upload_image === 1) {
            this.isAdding = true;
            $('#dots_spinner').show();
            this.http.post<any>(this.apiList.AddSubCategoryUrl,
                {
                    SubCategoryName: this.SubCategoryName,
                    Description: this.Description,
                    CreatedBy: this.IdMerchant,
                    IdMainCategory: this.IdMainCategory,
                    ImageName: this.FileName
                },
                this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(resp => {
                    if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                    if (+resp.Response !== 1) { throw resp; }
                    return resp;
                }).subscribe((response) => {
                    if (response.Response === 1) {
                       // this.names = response;
                        this.subcategoryForm.reset();
                        this.router.navigate([this.companyName + '/dashboard/categories']);
                    }
                }, (err: any) => {
                    const Error = this.globalservice.ProcessError(err);
                    if (Error.Response === 35) {
                        if (Error.ErrorMessage) {
                            this.FlashError(Error.ErrorMessage);
                            this.globalservice.Logout('Yes');
                        }
                        this.globalservice.Logout('No');
                        return;
                    } else {
                        this.FlashError(Error.ErrorMessage);
                        return;
                    }
                }).add(() => { $('#dots_spinner').hide(); });
        } else {
            this.FlashError('Firstly upload category Image');
        }
    }

    goback() {
        this.router.navigate([this.companyName + '/dashboard/categories']);
    }

    ngOnDestroy() {
        this.dialog.ngOnDestroy();
    }

    MainImageUpload(file: File) {
        // if (this.upload_image == 1) {
         $('#dots_spinner').show();
       
         this.blob = new Blob([file[0]], { type: file[0].type });
         console.log(file)
         this.globalservice.CheckTransparency(file).then((res:any) => {
             $('#dots_spinner').hide();
             console.log(res)
             if(res.Response == 1){
                 this.uploadFile()
             }
         },error=>{
             $('#dots_spinner').hide();
             console.log(error)
             const Error = this.globalservice.ProcessError(error);
                 if (Error.Response === 35) {
                     if (Error.ErrorMessage) {
                         this.FlashError(Error.ErrorMessage);
                         this.globalservice.Logout('Yes');
                     }
                     this.globalservice.Logout('No');
                     return;
                 } else if (Error.Response == 4) {
                     $('#myModal').modal('show');
                 }
                 else {
                     console.log(Error.ErrorMessage)
                    // event.target.value = null;
                     this.FlashError(Error.ErrorMessage);
                     return;
                 }
         })
     }
     // else{
     //         this.FlashError("Firstly upload your Category image");
     //     }
     // }
     uploadFile(){
         $('#dots_spinner').show();
         this.globalservice.uploadFiles(this.blob).then((res:any)=>{
             if(res.Response==1){
                 $('#dots_spinner').hide();
                 this.upload_image = 1;
                 this.ImageName = res.PreviewUrl;
                 this.FileName=res.FileName
                 $('#myImg').show(2000, function () {
                    $('#main_img_close').show();
                    $('#uploadSuccess').modal('show');
                });
                $('#upload-btn-wrapper').hide();
             }
         },error=>{
             const Error = this.globalservice.ProcessError(error);
             if (Error.Response === 35) {
                 if (Error.ErrorMessage) {
                     this.FlashError(Error.ErrorMessage);
                     this.globalservice.Logout('Yes');
                 }
                 this.globalservice.Logout('No');
                 return;
             } 
             // else if (Error.Response == 4) {
             //     $('#myModal').modal('show');
             // }
             else {
                 console.log(Error.ErrorMessage)
                // event.target.value = null;
                 this.FlashError(Error.ErrorMessage);
                 return;
             }
         })
     }
 
       Yes() {
         // console.log(this.section);
     this.uploadFile()
        
     }
}
