import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
declare const $;
@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.css']
})
export class ThemeComponent implements OnInit {
  BaseFileUrl = environment.BaseFileUrl;

  Imagelink =  this.BaseFileUrl + "/Themes/";
  
  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  
  posts
  message
  lst
  IdTheme
  AlertText: any;


  constructor(private router: Router, 
    private http: HttpClient, 
    private globalservice: GlobalService,
    public apiList: ApilistService) { }

  ngOnInit() {
    $('#alert_div').hide();
    $('#dots_spinner').hide();
    this.listoftheme();
  }

  listoftheme() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ThemeListUrl, { IdMerchant: this.IdMerchant },this.globalservice.Headers())
 .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      this.posts = response;
      if(this.posts.Response==1){
        this.posts = this.posts.lst_Themes;
      }  
    },(err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {  $('#dots_spinner').hide();
       });;
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  getId(id){
    this.IdTheme=id;
  }
  
  DeleteTheme(){
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteThemeUrl, { IdTheme: this.IdTheme,IdMerchant:this.IdMerchant },this.globalservice.Headers())
 .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
    //  console.log(this.lst);
      if(response.Response==1){
        this.FlashError('Successfully deleted');
        this.listoftheme();
      }
    },(err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {  $('#dots_spinner').hide();
       });;
  }

  Addtheme() {
    this.router.navigate([this.companyName + '/dashboard/Theme/Uploadtheme']);
  }

  Settheme(theme, ResolutionType) {
  //  console.log(theme)
    this.router.navigate([this.companyName + '/dashboard/Theme/Settheme',{
      id:theme,
      type: ResolutionType,
    }]);
  }

}
