import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from '../../../../environments/environment';
import { ApilistService } from '../../../../service/Api/apilist.service'
import { GlobalService } from '../../../global.service';
declare const $;
@Component({
  selector: 'app-stockpickreport',
  templateUrl: './stockpickreport.component.html',
  styleUrls: ['./stockpickreport.component.css']
})
export class StockpickreportComponent implements OnInit {
  BaseFileUrl = environment.BaseFileUrl;

  ImageUrl = this.BaseFileUrl + "/ClientDocument/"

  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));

  posts
  Mposts
  IdMachines
  Mlists
  selectedProduct
  Plists
  selectedlocation
  selectedMachine1
  selectedMachine
  OnlyselectedMachine
  IdProducts
  MPlists
  lists
  p: number = 1;
  array; array1; array2; Idlocation; arraymachine; arrayproduct

  AlertText: string;

  constructor(public apiList: ApilistService,
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService) { }

  ngOnInit() {
    $("#alert_div").hide();
  
    this.listofmachine();
    this.listofproduct();
    this.listoflocation();
  }

  listoflocation(){
      this.http.post<any>(this.apiList.LocationListUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())  .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.posts = response;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  onChangeMachine(id: any) {
    id = id.map(function (e) { return JSON.stringify(e); });
    id = id.join(",");
    this.IdMachines = id;
    this.listofproductbymachine();
  }

  onChangelocation(idl: number) {
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
    this.Idlocation = idl.toString();
    this.machinelistist();
    // $('#dots_spinner').show();
    // this.http.post<any>(this.MachineLstUrl, { IdLocation: idl }).subscribe((response) => {
    //   $('#dots_spinner').hide();
    //   this.Mlists = response;
    //   this.Mlists = this.Mlists.lst_Machine
    // });
  }

  listofmachine() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.PlaceMachineLstUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())  .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.Mposts = response.lst_sp_GetPlacedMachinesWithLocation;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  listofproductbymachine() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ProductLstMachinesUrl, {  IdMachines: this.IdMachines }, this.globalservice.Headers())  .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      this.MPlists = response;
      this.MPlists = this.MPlists.lst_Product
      // console.log(this.MPlists);
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  listofproduct() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.PlacedProductLstUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())  .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.Plists = response;
        this.Plists = this.Plists.lst_Product
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  onChangeproduct(id) {
    id = id.map(function (e) { return JSON.stringify(e); });
    id = id.join(",");
    this.IdProducts = id;
  }

  searchBYmachine() {
    //console.log(this.IdProducts,this.IdMachines,this.selectedlocation);
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.StockReportByMachineUrl, {  IdMachines: this.IdMachines }, this.globalservice.Headers()).take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.lists = response;
        this.lists = this.lists.lst_Product;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }
  searchBYproduct() {
    //console.log(this.IdProducts,this.IdMachines,this.selectedlocation);
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.StockRepostByProductUrl, {  IdProducts: this.IdProducts }, this.globalservice.Headers()).take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.lists = response;
        this.lists = this.lists.lst_Product;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  Searchlocation() {
    this.p = 1;
    $("#salestable tr:gt(0)").remove();
    this.lists = null;
    this.Plists = null;
    this.MPlists = null;
    this.Mposts = null;
    this.Mlists = null;
    this.selectedlocation = null;
    this.selectedMachine1 = null;
    this.OnlyselectedMachine = null;
    this.selectedProduct = null;
    this.IdProducts = null;
    this.IdMachines = null;
  }

  SearchMachine() {
    this.p = 1;
    $("#salestable tr:gt(0)").detach();
    this.lists = null;
    //this.Plists = null;
    // this.MPlists = null;
    // this.Mposts = null;
    // this.Mlists = null;
    this.selectedlocation = null;
    this.selectedMachine1 = null;
    this.OnlyselectedMachine = null;
    this.selectedProduct = null;
    this.IdProducts = null;
    this.IdMachines = null;
  }

  SearchProduct() {
    this.p = 1;
    this.lists = null;
    // this.Plists = null;
    // this.MPlists = null;
    // this.Mposts = null;
    // this.Mlists = null;
    this.selectedlocation = null;
    this.selectedMachine1 = null;
    this.OnlyselectedMachine = null;
    this.selectedProduct = null;
    this.IdProducts = null;
    this.IdMachines = null;
    $("#salestable tr:gt(0)").detach();
  }

  selectAll(id) {
      $('#checkall').addClass("btn_active");
      $('#uncheckall').removeClass("btn_active");
    if (id == 'location') {
      this.array = [];
      for (let i = 0; i < this.posts.length; i++) {
        this.array.push(this.posts[i].IdLocation);
      }
      this.selectedlocation = this.array;
      this.Idlocation = this.selectedlocation.toString();
      this.machinelistist()
    } else if (id == 'machine') {
      this.array1 = [];
      for (let i = 0; i < this.Mlists.length; i++) {
        this.array1.push(this.Mlists[i].IdMachine);
      }
      this.selectedMachine1 = this.array1;
      this.IdMachines = this.selectedMachine1.toString();
      this.listofproductbymachine();
    } else if (id == 'product') {
      this.array2 = [];
      for (let i = 0; i < this.MPlists.length; i++) {
        this.array1.push(this.MPlists[i].IdProduct);
      }
      this.selectedProduct = this.array1;
      this.IdProducts = this.selectedProduct.toString();
    }
  }


  deselectAll(id) {
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').addClass("btn_active");
    if (id == 'location') {
      this.selectedlocation = '';
      this.Idlocation = '';
      this.machinelistist()
    }
    else if (id == 'machine') {
      this.selectedMachine1 = '';
      this.IdMachines = '';
    } else if (id == 'product') {
      this.selectedProduct = '';
      this.IdProducts = '';
    }
  }

  machinelistist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachineLstUrl, {  IdLocations: this.Idlocation }, this.globalservice.Headers())  .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.Mlists = response;
        this.Mlists = this.Mlists.lst_Machine
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  selectAllmachine(id) {
      $('#checkall').addClass("btn_active");
      $('#uncheckall').removeClass("btn_active");
    if (id == 'machine') {
      this.arraymachine = [];
      for (let i = 0; i < this.Mposts.length; i++) {
        this.arraymachine.push(this.Mposts[i].IdMachine);
      }
      this.OnlyselectedMachine = this.arraymachine;
      this.IdMachines = this.OnlyselectedMachine.toString();
    } else if (id == 'product') {
      this.arrayproduct = [];
      for (let i = 0; i < this.Plists.length; i++) {
        this.arrayproduct.push(this.Plists[i].IdProduct);
      }
      this.selectedProduct = this.arrayproduct;
      this.IdProducts = this.selectedProduct.toString();
    }

  }

  deselectAllmachine(id) {
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').addClass("btn_active");
    if (id == 'machine') {
      this.OnlyselectedMachine = '';
      this.IdMachines = '';
    } else if (id == 'product') {
      this.selectedProduct = '';
      this.IdProducts = '';
    }
  }
  salesbtn() { this.router.navigate([this.companyName + '/dashboard/reporting']); }
  pickreport() { this.router.navigate([this.companyName + '/dashboard/reporting/pickup']); }
  productbtn() { this.router.navigate([this.companyName + '/dashboard/reporting/productsale']); }
  exception() { this.router.navigate([this.companyName + '/dashboard/reporting/exception']); }
}
