// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
    // development environment
    baseUrlSkin:'https://dev.vend4you.com/',
    baseUrl: 'https://dev.vend4you.com/api',
    BaseFileUrl: 'https://dev.vend4you.com',
    siteKeyCaptcha: '6LeaCZYUAAAAAE_4q4QndtPaCNIzCkRZeBSZ8vlu',
    CSVFileUrl: 'https://dev.vend4you.com/ExeclFiles',
    childUrl: ''
  
};
