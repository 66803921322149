import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from '../../../environments/environment';
import { NgForm } from "@angular/forms";
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
import * as moment from 'moment';
declare const $;
@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css']
})
export class ReportingComponent implements OnInit {
  @ViewChild('reportinglocation', { static: false }) public reportinglocationForm: NgForm
  @ViewChild('reportingmachine', { static: false }) public reportingmachineForm: NgForm
  CSVFileUrl = environment.CSVFileUrl;
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");

  StartDate
  EndDate
  posts
  SelectedLocations: Array<number> = [];
  Mlists
  ldetails
  MLStartDate
  MLEndDate
  MStartDate
  MEndDate
  Tlists
  Trans: any;
  Mposts
  SelectedMachines: Array<number> = [];
  p: number = 1;
  S1Date
  E2Date
  S3Date
  E4Date
  array = [];
  machinelistlength;
  selectedtimezone = ''
  AlertText
  nextLibAvailable = false;
  coupon_code; coupon_amount; coupon_used; coupon_startdate; coupon_enddate; coupon_startdatetime; coupon_enddatetime; coupon_discounttype;
  timezone: any;

  EmailId: any;
  IdTransaction: any;
  TC: any;
  RevNumber: any;
  RevisionNumber: any;
  Date: Date;
  S1Datee;
  resendMailTimeZone: any = "";

  constructor(public apiList: ApilistService, private router: Router, private http: HttpClient, private globalservice: GlobalService) {
  }

  ngOnInit() {
    this.resendMailTimeZone = "";
    this.locationList();
    this.listofmachine();
    this.timezonelist();
    this.HidePopups()
    this.Date = new Date();
    /// console.log(this.Date)
    this.StartDate = moment(this.Date).format('MM/DD/YYYY')
    $('#S1Date').val(this.StartDate);
    this.EndDate = moment(this.Date).format('MM/DD/YYYY')
    $('#E2Date').val(this.EndDate)
    $('#S3Date').val(this.StartDate);
    $('#E4Date').val(this.EndDate)
  }

  HidePopups() {
    $('#Norecord').hide();
    $('#dots_spinner').hide();
    $("#alert_div").hide();
    $('#coupon_info').modal('hide');
  }

  locationList() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.GetLocationsWithXactUrl, null, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.posts = response.lst_Location;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  timezonelist() {
    $('#dots_spinner').show();
    this.http.get<any>(this.apiList.TimeZoneListFUrl, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.timezone = response;
          this.timezone = this.timezone.lst_TimeZone;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  onChangelocation(idl: any) {
    this.nextLibAvailable = true;
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
    this.SelectedLocations = idl;
    this.machinelistist()
  }

  machinelistist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.XactMachineList, { LocationIds: this.SelectedLocations }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mlists = response.lst_Machine;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  listofsalesreport() {
    //console.log(this.selectedtimezone)
    if (this.StartDate && this.EndDate) {
      if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {
        //console.log(this.IdMachine, this.StartDate, this.EndDate, this.Idlocation);
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.SaleReportUrl, { IdMachines: this.SelectedMachines, StartDate: this.StartDate, EndDate: this.EndDate, IdLocations: this.SelectedLocations, TimeZoneId: this.selectedtimezone }, this.globalservice.Headers())
          .take(1)
          .timeout(25000)
          .map(result => {
            if (result.Response !== 1) throw result;
            return result;
          })
          .subscribe((response) => {

            this.Tlists = response;
            if (this.Tlists.Response == 1) {
              this.Trans = this.Tlists.lst_TransactionsProducts;

              $('#checkall').removeClass("btn_active");
              $('#uncheckall').removeClass("btn_active");
            }
          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
          });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    } else {
      this.FlashError("Please complete Date Information");
    }
  }

  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  listofmachine() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.PlaceMachineForXactLstUrl, null, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        if (response.Response == 1) {
          this.Mposts = response.lst_Machine;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  onChangeMachine(selectedMachines: any) {
    this.SelectedMachines = selectedMachines;
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDate = $('#S1Date').val();
    console.log(this.StartDate)
  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDate = $('#E2Date').val(); }
  addEvent3(type: string, event: MatDatepickerInputEvent<Date>) { this.StartDate = $('#S3Date').val(); }
  addEvent4(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDate = $('#E4Date').val(); }

  Searchlocation() {
    $("#salestable tr:gt(0)").remove();
    this.Tlists = [];
    this.Trans = [];
  }

  SearchMachine() {
    $("#salestable tr:gt(0)").detach();
    this.Tlists = [];
    this.Trans = [];
  }

  salesbtn() { this.router.navigate([this.companyName + '/dashboard/reporting']); }
  pickreport() { this.router.navigate([this.companyName + '/dashboard/reporting/pickup']); }
  productbtn() { this.router.navigate([this.companyName + '/dashboard/reporting/productsale']); }
  exception() { this.router.navigate([this.companyName + '/dashboard/reporting/exception']); }

  selectAll(id) {

    $('#checkall').addClass("btn_active");
    $('#uncheckall').removeClass("btn_active");

    if (id == 'location') {
      this.nextLibAvailable = true;
      this.SelectedLocations = this.posts.map(post => post.IdLocation);
      this.machinelistist()
    } else if (id == 'machine') {
      this.SelectedMachines = this.Mlists.map(machine => machine.IdMachine);
    } else if (id == 'machine2') {
      this.SelectedMachines = this.Mposts.map(machine => machine.IdMachine);
    }
  }


  deselectAll(id) {

    $('#checkall').removeClass("btn_active");
    $('#uncheckall').addClass("btn_active");

    if (id == 'location') {
      this.SelectedLocations = [];
      this.machinelistist()
    }
    else if (id == 'machine') {
      this.SelectedMachines = [];
    }
    else if (id == 'machine2') {
      this.SelectedMachines = [];
    }
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  coupon_detail(id) {
    $('#dots_spinner').show();
    $('#coupon_info').modal('show');
    this.http.post<any>(this.apiList.GetCouponDetailUrl, { IdCoupon: id, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.posts = response;
        if (this.posts.Response == 1) {
          //new addition
          //console.log("this.posts"+JSON.stringify(this.posts));
          this.coupon_code = this.posts.Obj_Coupon.CouponCode;
          this.coupon_amount = this.posts.Obj_Coupon.DiscountAmount;
          this.coupon_startdatetime = this.posts.Obj_Coupon.StartTime;
          this.coupon_startdate = this.posts.Obj_Coupon.SdateString;
          // this.coupon_startdate = moment(this.coupon_startdate).format('MM/DD/YYYY');
          this.coupon_enddate = this.posts.Obj_Coupon.EdateString;
          this.coupon_enddatetime = this.posts.Obj_Coupon.EndTime;
          this.coupon_discounttype = this.posts.Obj_Coupon.DiscountType;

        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide() });
  }

  export() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ExportReportUrl, { IdMachines: this.SelectedMachines, StartDate: this.StartDate, EndDate: this.EndDate, IdLocations: this.SelectedLocations, TimeZoneId: this.selectedtimezone, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        let result = response;
        if (result.Response == 1) {
          let FileName = result.FileName
          window.location.href = this.CSVFileUrl + '/' + FileName;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  Printroute() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.PrintsalesUrl, { IdMachines: this.SelectedMachines, StartDate: this.StartDate, EndDate: this.EndDate, IdLocations: this.SelectedLocations, TimeZoneId: this.selectedtimezone, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {      //   console.log(response)
        var data = response;
        if (data.Response == 1) {
          data = data.htmltext
          var popupWinindow = window.open('', '_blank', 'width=1366, height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.open();
          popupWinindow.document.write('<html><head></head><body onload="window.print()" onafterprint="window.close()">' + data + '</html>');
          popupWinindow.document.close();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  EmailreceiptDetails(id, email, timezone) {
    this.IdTransaction = id;
    this.EmailId = email;
    this.resendMailTimeZone = timezone;
    // console.log("timezone is"+timezone);
  }

  RevNumberEvent(RevNumber) {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.GetTermsAndConditionByRevNumber, { IdMerchant: this.IdMerchant, RevNumber: RevNumber }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        // console.log(response)
        this.TC = response.objTerms.Terms;
        this.RevNumber = response.objTerms.RevNumber;
        this.RevisionNumber = response.objTerms;
        // console.log(this.RevNumber)
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  PrintTermsandconditions() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.PrintGetTermsAndConditionByRevNumber, { IdMerchant: this.IdMerchant, RevNumber: this.RevNumber }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        //   console.log(response)
        var data = response;
        if (data.Response == 1) {
          data = data.htmltext
          var popupWinindow = window.open('', '_blank', 'width=1366, height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.open();
          popupWinindow.document.write('<html><head></head><body onload="window.print()" onafterprint="window.close()">' + data + '</html>');
          popupWinindow.document.close();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  sendEmailreceipt() {
    if (this.EmailId && this.IdTransaction) {
      $('#dots_spinner').show();
      this.http.post<any>(this.apiList.sendEmailreceipt, { IdTransaction: this.IdTransaction, TimeZoneId: this.resendMailTimeZone, EmailId: this.EmailId }, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (result.Response !== 1) throw result;
          return result;
        })
        .subscribe((response) => {
          //  console.log(response)
          if (response.Response == 1) {
            this.listofsalesreport();
            $('#resend_email').modal('hide');
            this.FlashError('Successfully Email sent');
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
          $('#resend_email').modal('hide');
        });
    }
    else if (this.EmailId == '') {
      this.FlashError("Please Enter Email Address");
    }
  }

  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.FlashError('GUID copied to clipboard - ' + val);
  }

  coupon_redirect(value) {
    this.router.navigate([this.companyName + '/dashboard/coupons', {
      value: value
    }]);
  }

}
