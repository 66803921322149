import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Sort } from '@angular/material';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';

declare const $;
@Component({
  selector: 'app-adloop',
  templateUrl: './adloop.component.html',
  styleUrls: ['./adloop.component.css']
})
export class AdloopComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public dialog: MatDialog,
    public apiList: ApilistService) { }
  array: any[];
  OriginalFileName: any;
  @ViewChild('addd', { static: false }) public addForm: NgForm;
  @ViewChild('add', { static: false }) public addcontentForm: NgForm;
  @ViewChild('checkall', { static: false }) public selectAllEl: ElementRef;
  @ViewChild('uncheckall', { static: false }) public unSelectAllEl: ElementRef;
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);

  AlertText: string;
  selectedScreen;
  SelectedScreenLocation;
  CampaignName;
  selectedContent;
  lists: any;
  looplists: any;
  ContentLists: any;
  details: any;
  ContentType;
  FilePath;
  Duration;
  ContentName;
  Remove;
  posts;
  CampaignStartDate;
  CampaignEndDate;
  ClientName;
  lst_AddContent = [];
  ItemsCount;
  ScreenName;
  IdContent;
  selectedContent1;
  loopplay;
  ScreenOrientation = 'Vertical';
  LoopDuration = 0;
  p = 1;
  SelectedAdTime: any[] = [];
  StartDate;
  EndDate;
  buttonDisabled = false;
  Date;
  ToDate;
  SDate;
  EDate;
  isAdding = false;
  Timeslot;
  contentmodal = true;
  bit: any = false;
  location_on_screen = [  // Digitalsingage position
    { id: '0', name: 'Top Third', value: 'Top Third' },
    { id: '1', name: 'Middle', value: 'Middle' },
    { id: '2', name: 'Bottom Third', value: 'Bottom Third' },
    { id: '3', name: 'Full Screen Vertical', value: 'Full Screen Vertical' }
  ];
  Aspectmode(arg0: any): any {
    throw new Error('Method not implemented.');
  }


  ngOnInit() {
    this.globalservice.setDiscard_check();
    this.Date = new Date();
    this.ToDate = this.Date.toLocaleDateString();
    this.hidepops();
    this.Timeslot = [
      { id: 'midnight to 6am', name: 'midnight to 6am' },
      { id: '6am to 11am', name: '6am to 11am' },
      { id: '11am to 2pm', name: '11am to 2pm' },
      { id: '2pm to 6pm', name: '2pm to 6pm' },
      { id: '6pm to 10pm', name: '6pm to 10pm' },
      { id: '10pm to midnight', name: '10pm to midnight' },
    ];

    this.ScreenList();
    this.ContentList();
    this.Campaignlist();
  }
  hidepops() {
    $('#dots_spinner').hide();
    $('#alert_div').hide();
    $('#preview').hide();

  }
  canDeactivate(): any {
    if (!this.isAdding && this.addForm.dirty) {
      if (this.bit === false && this.globalservice.GetDiscard_Check()) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });
        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response === 'ok')) {
            this.dialog.closeAll();
            this.bit = true;
          }
        });
      }
    } else if (this.addForm.dirty) {
      if (this.bit === false) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });
        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response === 'ok')) {
            this.dialog.closeAll();
            this.bit = true;
          }
        });
      }
    }
    return true;
  }



  AddScreenbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage']); }
  AddContentbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Addcontent']); }
  Adloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Adloop']); }
  placeloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/placeloop']); }
  preview() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Previewcampaign']); }
  Calendar() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaigncalendar']); }
  report() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaignreports']); }


  ScreenList() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ListApi, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.lists = response;
          this.lists = this.lists.lsy_Content;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }
  ContentList() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ContentApi, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.ContentLists = response;
          this.ContentLists = this.ContentLists.lst_Content;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  Campaignlist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CampaignlistApi, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        $('#dots_spinner').hide();

        if (response.Response === 1) {
          this.looplists = response;
          this.looplists = this.looplists.lst_GetListOfCampaign;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    // console.log(this.SDate)

  }

  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
  }

  selectAll() {
    $('#checkall').addClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
    this.array = [];
    for (let i = 0; i < this.Timeslot.length; i++) {
      this.array.push(this.Timeslot[i].id);
    }
    this.SelectedAdTime = this.array;
  }

  deselectAll() {
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').addClass("btn_active");
    this.SelectedAdTime = [];
  }


  getContentDetails1(i) {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ContentDetailApi, { IdContent: i }, this.globalservice.Headers())
      .timeout(25000)
      .retry(3)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.details = response;
        if (this.details.Response === 1) {
          this.contentmodal = false;
          this.IdContent = i;
          this.details = this.details.Obj_ContentClass;
          this.ContentName = this.details.ContentName;
          this.ContentType = this.details.ContentType;
          this.FilePath = this.details.FilePath;
          this.Duration = this.details.Duration;
          this.ClientName = this.details.ClientName;
          this.buttonDisabled = true;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }
  screentype(type) {
    this.ScreenOrientation = type;
    if (this.ScreenOrientation === 'Horizontal') {
      this.SelectedScreenLocation = 'Full Screen Horizontal';
    } else {
      this.SelectedScreenLocation = '';
    }
  }
  AddContent() {
    this.selectAllEl.nativeElement.classList.remove('btn_active');
    this.unSelectAllEl.nativeElement.classList.remove('btn_active');
    this.buttonDisabled = false;
    var dailyPlayTime = [];
    for (let i = 0; i < this.SelectedAdTime.length; i++) {
      dailyPlayTime.push({"PlayTime":this.SelectedAdTime[i]})
    }
    if (this.lst_AddContent != null) {
      const a = this.selectedContent;
      const IsExist = this.lst_AddContent.some(function (o) { return o['IdContent'] === a; });
      if (IsExist === true) {
        this.FlashError('Already Exist');
      } else if (IsExist === false) {
        this.lst_AddContent.push({
          IdContent: this.selectedContent,
          ContentName: this.ContentName,
          ClientName: this.ClientName,
          ContentType: this.ContentType,
          FilePath: this.FilePath,
          OriginalFileName: this.OriginalFileName,
          DailyPlayTime: dailyPlayTime,
          SelectedAdTime: this.SelectedAdTime,
          Duration: this.Duration
        });
        this.math();
      }
    }
    this.addcontentForm.reset();
  }


  math() {
    this.LoopDuration = 0;
    for (let j = 0; j < this.lst_AddContent.length; j++) {
      this.LoopDuration += parseFloat(this.lst_AddContent[j]['Duration']);
    }
  }

  remove(id) {
    this.ItemsCount = this.lst_AddContent.length;
    for (let i = 0; i < this.ItemsCount; i++) {
      if (this.lst_AddContent[i]['IdContent'] === id) {
        this.LoopDuration = this.LoopDuration - parseFloat(this.lst_AddContent[i]['Duration']);
        this.lst_AddContent.splice(i, 1);
        this.ItemsCount = this.ItemsCount - 1;
      }
    }
  }
  convert(str) {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  Save() {
    this.SDate = this.convert(this.SDate);
    this.EDate = this.convert(this.EDate);
    this.isAdding = true;
    if (this.toTimestamp(this.SDate) <= this.toTimestamp(this.EDate)) {
      if (this.lst_AddContent.length === 0) {
        this.FlashError('Add content and play time');
      } else {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.CreateCampaignApi,
          {
            IdScreen: this.selectedScreen,
            LocationOnScreen: this.SelectedScreenLocation,
            CampaignName: this.CampaignName,
            CampaignStartDate: this.SDate,
            CampaignEndDate: this.EDate,
            lst_CampaignContent: this.lst_AddContent,
            IdMerchant: this.IdMerchant,
            AspectMode: this.Aspectmode
          },
          this.globalservice.Headers())
          .timeout(25000)
          .take(1)
          .map(resp => {
            if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
            if (+resp.Response !== 1) { throw resp; }
            return resp;
          }).subscribe((response) => {
            if (response.Response === 1) {
              this.lst_AddContent = [];
              this.addForm.reset();
              this.posts = response;
              this.Campaignlist();
            }
          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => { $('#dots_spinner').hide(); });
      }
    } else if (this.SDate === undefined && this.EDate === undefined) {
      this.FlashError('Select Start Date and End Date');
    } else {
      this.FlashError('Start Date should be less than End Date');
    }
  }

  toTimestamp(strDate) {
    const datum = Date.parse(strDate);
    return datum / 1000;
  }

  timeconversion(Time) {
    const time = Time;
    let hours = Number(time.match(/^(\d+)/)[1]);
    const minutes = Number(time.match(/:(\d+)/)[1]);
    const AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === 'PM' && hours < 12) { hours = hours + 12; }
    if (AMPM === 'AM' && hours === 12) { hours = hours - 12; }
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) { sHours = '0' + sHours; }
    if (minutes < 10) { sMinutes = '0' + sMinutes; }
    return sHours + ':' + sMinutes + ':' + '00';
  }

  edit(id) {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CampaignDetailApi, { IdCampaign: id }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        $('#dots_spinner').hide();
        if (response.Response === 1) {
          this.details = response;
          if (this.details.IsUsed === false) {
            this.router.navigate([this.companyName + '/dashboard/digitalsignage/Updateadloop', {
              campaignid: id
            }],
            );
          } else if (this.details.IsUsed === true) {
            this.FlashError('Changes are not allowed, Campaign already placed');
          } else {
            this.FlashError('Something went wrong. Please Try again');
          }
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }
  setDailyPlayTime(timeArray: any[]) {
    if (timeArray.length > 0) {
      var finaltext = '';
      timeArray.forEach(item => {
        finaltext += item.PlayTime + ', ';
      });
      return finaltext;
    }
  }
  deleteloop(id) {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.deleteCampaignApi, { IdCampaign: id }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.Remove = response;
        if (this.Remove.Response === 1) {
          this.FlashError('Successfully deleted');
          this.Campaignlist();
        } 
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  getContentDetails(id) {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ContentDetailApi, { IdContent: id }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.details = response;
          this.buttonDisabled = true;
          this.details = this.details.Obj_ContentClass;
          this.ContentName = this.details.ContentName;
          this.ContentType = this.details.ContentType;
          this.FilePath = this.details.FilePath;
          this.Duration = this.details.Duration;
          this.ClientName = this.details.ClientName;
          this.OriginalFileName = this.details.OriginalFileName;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }


  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  sortData(sort: Sort) {
    const data = this.looplists.slice();
    if (!sort.active || sort.direction === '') {
      this.looplists = data;
      return;
    }
    this.looplists = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'campaignname': return this.compare(a.Obj_CampaignClass.CampaignName.toLowerCase(), b.Obj_CampaignClass.CampaignName.toLowerCase(), isAsc);
        case 'locationonscreen': return this.compare(a.Obj_CampaignClass.LocationOnScreen.toLowerCase(), b.Obj_CampaignClass.LocationOnScreen.toLowerCase(), isAsc);
        case 'campaignduration': return this.compare(a.Obj_CampaignClass.CampaignDuration, b.Obj_CampaignClass.CampaignDuration, isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  // ----------------------//

  ngOnDestroy() {
    this.dialog.ngOnDestroy();
  }
}
