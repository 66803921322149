import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Sort, MatInput } from '@angular/material';
import { GlobalService } from '../../global.service';
import { NgForm } from '@angular/forms';
import { ApilistService } from '../../../service/Api/apilist.service';
import { Subject } from 'rxjs';
declare const $;
const FixedRecordCount = 100;

@Component({
  selector: 'app-machine',
  templateUrl: './machine.component.html',
  styleUrls: ['./machine.component.css'],
})



export class MachineComponent implements OnInit {
  baseUrl = environment.baseUrl;
  BaseFileUrl = environment.BaseFileUrl;
  Imagelink = this.BaseFileUrl + "/Themes/"
  @ViewChild('deactivate', { static: false }) public deactivateForm: NgForm
  @ViewChild('t2', {
    read: MatInput,
    static: false
  }) t2: MatInput;
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");
  UserRole = sessionStorage.getItem("referUserRole");


  posts: any = [];
  id: number;
  Search: any;
  IdMachine: string;
  Machine = '';
  LinkCode = '';
  MachineSerial = '';
  MachineType = '';
  MachineModel = '';
  NoOfTrays = "";
  NoOfMotorsPerTray = "";
  ActivationDate = '';
  Activated = '';
  NoOfColumns: any;
  p: number = 1;
  code;
  sucesslinkcode;
  AlertText;
  Id_machine;
  cartsize;
  cart_data
  //screenHeight: any;
  before: number;
  temparray: any = 0;
  pageno: number = 1;
  ll: any;
  current_date: Date;
  length_machine: any;
  ManufacturerName: any;
  ShoppingGridRows: number;
  ShoppingGridCols: number;
  Selectedmachine: any;
  IdManufacturer: any;
  Effectivedate: any;
  destination: any;
  Assignby: any;
  error_message: any;
  show_error: boolean = false;
  MachineSserial: any;
  pageCount: number;
  source: any;
  OldSearch: string


  screenHeight: number;
  resize$ = new Subject<void>(); // for debounce
  public RowHeight: number = 43; // initial estimate

  public Resizing: boolean = false;

  // record cache and pagination
  public Records = [];
  public CurrentPage: number = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage: number = 1;
  RecordCount: number = FixedRecordCount;
  doSearch = false

  constructor(public apiList: ApilistService, private router: Router, private http: HttpClient, private globalservice: GlobalService, private route: ActivatedRoute) {

    // this.screenHeight = window.innerHeight;
    // var rowno = (window.innerHeight - 300) / 60;
    // if (rowno >= 1) {
    //   this.before = parseInt(rowno.toFixed(2).toString().split(".")[0], 10);
    // } else {
    //   this.before = 1;
    // }
  }



  ngOnInit() {
    this.current_date = new Date();       //for current date
    this.Assignby = this.UserRole == 'SuperAdmin' ? true : false     // if userRole superadmin means login Superadmin

    this.route.params.subscribe((get) => {
      this.Search = get.code;
      this.doSearch = this.Search ? true : false
      this.DoQuery(1, 1, true);
      // }, 1500);
    })
    // if (this.UserRole == 'SuperAdmin') {
    //   this.Assignby = false   // true
    // } else {
    //   this.Assignby = false
    // }
    this.HidePopups()
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) return;
      this.Repaginate();
      this.SettleAndAutosize();
    });
    this.Repaginate();
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private HidePopups() {
    $('#transferform').modal('hide');
    $('#Norecord').hide();
    $("#alert_div").hide();
    $('#dots_spinner').hide();
    $("#paginate").hide();
    $('#Property_Settings').modal('hide');

  }

  Repaginate() {
    this.Resizing = true;
    this.ItemsPerPageBeforeResize = this.ItemsPerPage;
    this.CurrentPageBeforeResize = this.CurrentPage;
    this.CurrentPage = 1;
    this.ItemsPerPage = this.RecordCount;
  }

  private AutosizeTable() {

    console.log("Autosize table");

    this.UpdateRowHeight();
    var tableOffset = $("#tableBody").offset();
    var pageControlOffset = $("#paginate").offset();

    if (tableOffset == null || pageControlOffset == null) {
      console.log("Can't find")
      console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;

      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);

      // if ItemsPerPage changes, we could suddenly be on a range of rows that
      // have not been pulled from the server.
      // Also, unless user is on the first page they will be at a whole different set of records ("fly-away")
      // we don't know exactly which item the user was looking at, but we can get them
      // pretty close! Using the middle record
      if (this.ItemsPerPageBeforeResize != this.ItemsPerPage || this.CurrentPageBeforeResize != this.CurrentPage) {
        let rowToShow = Math.min((this.CurrentPageBeforeResize - 1) * this.ItemsPerPageBeforeResize + Math.trunc((this.ItemsPerPageBeforeResize + 1) / 2), Math.max(this.Records.length - 1, 0));
        setTimeout(() => {
          this.pageChange(Math.trunc(rowToShow / this.ItemsPerPage) + 1);
        }, 0);
      }
    }

    this.Resizing = false;
    console.log("Resize complete>");
  }

  private UpdateRowHeight() {

    var rows = (<HTMLTableElement>document.getElementById('recordset')).rows;

    if (rows.length != 0) this.RowHeight = 0;

    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }

    console.log(`RowHeight: ${this.RowHeight}`);
  }

  addmachine() { this.router.navigate([this.companyName + '/dashboard/machine/addmachine']); }


  getID(id: string) { this.IdMachine = id; }


  DoQuery(originPage: number, finalPage: number, reload: boolean) {

    let req: any = {

      PageNumber: originPage,
      RecordsPerPage: this.ItemsPerPage,
      RecordCount: this.RecordCount,
      IdMerchant: this.IdMerchant,
    };

    let searchText = this.Search;
    // this.doSearch = false;
    if (searchText != null && searchText.length != 0) {
      req.SearchText = searchText;
      this.doSearch = true;
    }

    this.Fetch(this.doSearch ? this.apiList.MachinesearchUrl : this.apiList.GetMachineList, req, reload);
  }


  Fetch(url: string, req: any, finalPage, reload: boolean = false) {
    console.log(req);
    $('#dots_spinner').show();
    this.http.post<any>(url, req, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {

        // merge retrieved records into cache
        this.globalservice.MergeRecords({
          cache: this.Records,
          newRecords: response.lst_Machines,
          totalCount: response.TotalCount,
          pageNumber: req.PageNumber,
          itemsPerPage: req.RecordsPerPage,
          reload
        });

        // transition to the requested page
        this.CurrentPage = finalPage;

      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
        if (this.Resizing) {
          // need to give time to render behind the scenes before size/resize rows
          this.SettleAndAutosize();
        }
      });
  }


  searchDebounce($event: KeyboardEvent) {

    if (this.Search == null) return;

    // on ENTER, commit the search, no waiting
    if ($event.keyCode === 13 || !this.Search.length) {
      if (this.source) {
        clearTimeout(this.source);
        this.source = null;
      }
      this.OldSearch = this.Search;
      this.DoQuery(1, 1, true);
      return;
    }

    //... rest is to wait until user stops typing as indication of waiting for search

    // ignore keyup events if string doesn't actually change (like, ENTER)
    if (this.OldSearch == this.Search) {
      return;
    }
    this.OldSearch = this.Search;

    // looks like still typing. Cancel any delay and start waiting all over again

    let oldSource = this.source;
    if (oldSource) {
      clearTimeout(oldSource);
    }

    // delay API call to see if user is still typing.
    this.source = setTimeout(() => {
      this.source = null;
      this.DoQuery(1, 1, true);
    }, 800);

  }

  public pageChange(pageNumber: number) {

    this.globalservice.PagingHandler(
      pageNumber,
      this.Records,
      this.ItemsPerPage,
      FixedRecordCount,
      (originPage, finalPage, reload) => this.DoQuery(originPage, finalPage, reload),
      (pageNumber) => this.CurrentPage = pageNumber);
  }

  reset(linkcode) {
    $('#dots_spinner').show();
    this.code = linkcode;
    let url = `${this.apiList.ResetUrl}`;
    this.http.post<any>(url, { LinkCode: this.code, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      }).subscribe((response) => {
        this.sucesslinkcode = response;
        if (this.sucesslinkcode.Response == 1) {
          this.FlashError("Synced Successfully");
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }


  sortData(sort: Sort) {
    const data = this.posts.slice();
    if (!sort.active || sort.direction === '') {
      this.posts = data;
      return;
    }
    this.posts = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'serial': return this.compare(a.MachineSerial.toLowerCase(), b.MachineSerial.toLowerCase(), isAsc);
        case 'date': return this.compare(a.ActivationDate, b.ActivationDate, isAsc);
        case 'machinemodal': return this.compare(a.MachineModelNumber.toLowerCase(), b.MachineModelNumber.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  cart_update(id, serial) {
    this.Id_machine = id;
    this.MachineSerial = serial;
    $('#Property_Settings').modal('show');
  }

  submit() {
    if (this.ShoppingGridRows > 6 && this.ShoppingGridRows > 6) {
      this.FlashError('Grid rows And coloumns should be less than 6')
    }
    else {
      this.http.post<any>(this.apiList.UpdateMachineSettings, { IdMachine: this.Id_machine, MaxCartSize: this.cartsize, ShoppingGridRows: this.ShoppingGridRows, ShoppingGridCols: this.ShoppingGridCols }, this.globalservice.Headers())
        .subscribe((response) => {
          this.cart_data = response;
          if (this.cart_data.Response == 1) {
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          this.cartsize = '';
          $('#dots_spinner').hide();
          $('#Property_Settings').modal('hide');
        });
    }
  }

  clearSearch() {
    clearInterval(this.source);
    this.Search = '';
    this.doSearch = false
    this.DoQuery(1, 1, true);
  }

  theme(theme, ResolutionType) {

    this.router.navigate([this.companyName + '/dashboard/Theme/Settheme', {
      id: theme,
      type: ResolutionType,
    }]);
  }


  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }
  setting(value) {
    //console.log(value)
    this.router.navigate([this.companyName + '/dashboard/machine/machinesetting', {
      data: JSON.stringify(value)
    }], { skipLocationChange: true });
    sessionStorage.setItem("IsSMTPConfigured", value.IsSMTPConfigured)
  }

  Transfer(value, id, serial) {
    this.Selectedmachine = value
    this.IdManufacturer = id
    this.MachineSserial = serial
    $('#transferform').modal('show');
  }

  // shareData() {
  //   alert('')
  // }

  Assign() {
    var data = {
      IdMachine: this.Selectedmachine,
      EffectiveDate: this.Effectivedate,
      SourceIdMerchant: this.IdMerchant,
      Status: "pending",
      Destination: this.destination,
      IsSuperAdmin: this.Assignby
    }
    this.http.post<any>(this.apiList.SaveMachineTransferInventoryURL, data, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        // console.log(response)
        var Result = response
        // console.log(Result.Response)
        if (Result.Response == 1) {
          this.FlashError('Successfully request transfer');
          this.deactivateForm.reset();
          this.t2.value = '';
          $('#transferform').modal('hide');
          this.DoQuery(this.CurrentPage, this.CurrentPage, true);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        }
        else if (Error.Response == 2) {
          this.show_error = true
          this.error_message = Error.ErrorMessage;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      });
  }

  addEvent1(value) {
    this.Effectivedate = value
  }

  Force_Zero() {
    this.http.post<any>(this.apiList.ForceFullyZeroURL, { IdMachine: this.Selectedmachine }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      }).subscribe((response) => {
        // console.log(response)
        var Result = response
        $('#transferform').modal('hide');
        if (Result.Response == 1) {
          this.Assign()
          this.FlashError("Transfer Requested Successfully");
          //  this.refreshmachinelist();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      });
  }


  ngOnDestroy(): void {
    this.HidePopups();
    this.resize$.complete();
  }
}
