import { Component, OnInit, ElementRef } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
declare var $: any;

@Component({
  selector: 'app-addmachine',
  templateUrl: './addmachine.component.html',
  styleUrls: ['./addmachine.component.css']
})

export class AddmachineComponent implements OnInit {


  IdMerchant: number;
  companyName = sessionStorage.getItem('companyName');
  // ll: any;
  posts: any;
  ManufacturerName = '';
  TheVendersModals = ['Olli - Conveyor Delivery', 'Cube', 'ZenCafe', 'Stan - Vertical Drop Delivery'];
  JofemarModals = ['Multiple Iceplus', 'Vision - With Elevator', 'Vision - Without Elevator', 'Vision - ES Plus'];
  lists: any;
  AlertText: string;
  IdManufacturer: number;
  MachineModelName: any;
  IdMachineModel: any;
  // selectedmanufacturer: any;
  selectedOptions: any;
  CanBeMaster: any;
  CanBeSlave: any;
  CanHaveSlaveScreen: boolean;
  constructor(
    public apiList: ApilistService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private globalservice: GlobalService,
    private elem: ElementRef) {
  }

  ngOnInit() {
    this.IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
    this.GetManufacturerlist();
    this.hidepops();
  }
  hidepops() {
    $('#dots_spinner').hide();
    $('#alert_div').hide();
  }
  ChangeModal(CanBeMaster, CanBeSlave, CanHaveSlaveScreen, id, name) {
    const elements = this.elem.nativeElement.querySelectorAll('.ActiveModel');
    if (elements.length > 0) {
      $('#' + elements[0].id).removeClass('ActiveModel');
    }
    $('#' + id + 'Mo').addClass('ActiveModel');
    //static name stored in database
    this.MachineModelName = name;
//static id stored in database
    this.IdMachineModel = id;
    //static master stored in database
    this.CanBeMaster = CanBeMaster;
    //static slave if aalowed stored in database
    this.CanBeSlave = CanBeSlave;
    //static slavescreen if allowed
    this.CanHaveSlaveScreen = CanHaveSlaveScreen;
  }

  ChangeManufacturer(id, name) {
    this.ManufacturerName = name;
    this.IdManufacturer = id;
    const elements = this.elem.nativeElement.querySelectorAll('.ActiveManufacture');
    if (elements.length > 0) {
      $('#' + elements[0].id).removeClass('ActiveManufacture');
    }
    $('#' + id + 'Mn').addClass('ActiveManufacture');
    $('#dots_spinner').show();

    this.http.post<any>(this.apiList.ModelByManufacturerUrl,
      { IdManufacturer: this.IdManufacturer }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
          this.lists = response.lst_Models;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  Next() {
    if ((this.IdMachineModel) && this.IdManufacturer) {
      
      //only master
      if (this.CanBeMaster && !this.CanBeSlave && !this.CanHaveSlaveScreen) {
        this.router.navigate(['./Superadmindashboard/Inventory/Slimelinelayout', {
          CanHaveSlaveScreen: this.CanHaveSlaveScreen,
          IdManufacturer: this.IdManufacturer,
          ManufacturerName: this.ManufacturerName,
          IdMachineModel: this.IdMachineModel,
          MachineModelName: this.MachineModelName
        }], { skipLocationChange: true });
        return;
      }
      //master and slave without screen
       else if (this.CanBeMaster && this.CanBeSlave && !this.CanHaveSlaveScreen) {
        this.router.navigate(['./Superadmindashboard/Inventory/Cubelayout', {
          CanHaveSlaveScreen: this.CanHaveSlaveScreen,
          IdManufacturer: this.IdManufacturer,
          ManufacturerName: this.ManufacturerName,
          IdMachineModel: this.IdMachineModel,
          MachineModelName: this.MachineModelName
        }], { skipLocationChange: true });
        return;
      }
      //all false
       else if (!this.CanBeMaster && !this.CanBeSlave && !this.CanHaveSlaveScreen) {
        this.router.navigate(['./Superadmindashboard/Inventory/CoffeeLayout', {
          CanHaveSlaveScreen: this.CanHaveSlaveScreen,
          IdManufacturer: this.IdManufacturer,
          ManufacturerName: this.ManufacturerName,
          IdMachineModel: this.IdMachineModel,
          MachineModelName: this.MachineModelName
        }], { skipLocationChange: true });
        return;
      } 
      //master and slave with screen
      else if (this.CanBeMaster && this.CanBeSlave && this.CanHaveSlaveScreen) {
        this.router.navigate(['./Superadmindashboard/Inventory/Cubelayout', {
          CanHaveSlaveScreen: this.CanHaveSlaveScreen,
          IdManufacturer: this.IdManufacturer,
          ManufacturerName: this.ManufacturerName,
          IdMachineModel: this.IdMachineModel,
          MachineModelName: this.MachineModelName
        }], { skipLocationChange: false });
        return;
      }
    } else {
      this.FlashError('Both Manufacturer and Machine Model are Mandatory');
    }
  }

  GetManufacturerlist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ManufacturerListUrl,
      { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        // this.ll = response;
        // if (this.ll.Response === 1) {
          this.posts = response.lst_Manufacturer;
      //  }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  back() {
    this.router.navigate(['./Superadmindashboard/Inventory']);
  }

  
  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}













