import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DragDropDirectiveModule } from 'angular4-drag-drop';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
declare const $;
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Rx';
import { ApilistService } from '../../../../service/Api/apilist.service';

@Component({
  selector: 'app-slimelinelayout',
  templateUrl: './slimelinelayout.component.html',
  styleUrls: ['./slimelinelayout.component.css']
})

export class SlimelinelayoutComponent implements OnInit {
  @ViewChild('slimline', { static: false }) public slimlineForm: NgForm;

  IdMerchant = sessionStorage.getItem('referSuperMerchantId');
  companyName = sessionStorage.getItem('companyName');

  count = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
  IdGrid: any;
  MachineType: string;
  item;
  ActivationDate;
  Activated;
  MasterNoOfMotorsPerTray;
  MasterNoOfTrays;
  MasterMachineModel;
  MasterMachineModel1;
  MasterMachineModel2;
  MasterMachineSerial;
  MasterMachine = '0';
  Machine;
  LinkCode = '';
  Snumbers;
  Mnumbers;
  added;
  IdSlot;
  IdMachine = 0;
  AlertText;
  Machine_Id = 0;
  isAdding = false;
  IdManufacturer: number;
  IdMachineModel: number;
  MachineModelName = '';
  Tdetails: any;
  ManufacturerName: any;
  Date: any;
  startbuilddate: any;
  completebuilddate: any;

  constructor(
    public apiList: ApilistService,
    private router: Router,
    private elRef: ElementRef,
    private route: ActivatedRoute,
    private http: HttpClient,
    private globalservice: GlobalService) {
    this.route.params.subscribe((get) => {
      this.IdManufacturer = get.IdManufacturer;
      this.IdMachineModel = get.IdMachineModel;
      this.MachineModelName = get.MachineModelName;
      this.ManufacturerName = get.ManufacturerName;
    });
  }

  ngOnInit() {
    this.Date = new Date();
    this.hidepops();
    setTimeout(() => {
      this.startDrag('Master');
    }, 500);
  }
  hidepops() {
    $('#alert_div').hide();
    $('#dots_spinner').hide();
  }
  dragEnter(event) {// console.log("dragEnter");
  }
  dragLeave(event) {// console.log("dragLeave");
  }
  dragoverMouse(event) {// console.log("dragoverMouse");
  }
  releaseDrop(event) { // console.log("releaseDrop");
  }
  startDrag(a) {
    this.MachineType = a;
    this.addDropItem(20);
  }

  addDropItem(id: number) {
    this.OpenModal(20);
    $('#Master_modal').modal('show');
    this.IdGrid = id;
    if (this.MachineType === 'Master') {
      const plus8 = (parseInt(this.IdGrid, 0) + 8);
      const minus8 = (parseInt(this.IdGrid, 0) - 8);
      const plus1 = (parseInt(this.IdGrid, 0) + 1);
      const minus1 = (parseInt(this.IdGrid, 0) - 1);
      if ($('.Machine_dragDiv').find('.Master_Div').length === 1) {
        $('#' + this.IdGrid).empty();
        $('#' + this.IdGrid).append('<div class="Machine_dragDiv" data-toggle="modal" data-target="#Master_modal"><div class="Master_Div" id=M_' + this.IdGrid + '>' + this.MasterMachine + '</div></div></div>');
        $('#' + this.IdGrid).addClass('placed');
        if (!$('#' + plus8).hasClass('placed')) {
          // $('#' + plus8).addClass("dashedDiv");
        }
        if (!$('#' + minus8).hasClass('placed')) {
          // $('#' + minus8).addClass("dashedDiv");
        }
        if (!$('#' + plus1).hasClass('placed')) {
          if ((this.IdGrid !== 8) && (this.IdGrid !== 16) && (this.IdGrid !== 24) && (this.IdGrid !== 32) && (this.IdGrid !== 40)) {
            // $('#' + plus1).addClass("dashedDiv");
          }
        }
        if (!$('#' + minus1).hasClass('placed')) {
          if ((this.IdGrid !== 9) && (this.IdGrid !== 17) && (this.IdGrid !== 25) && (this.IdGrid !== 33)) {
            // $('#' + minus1).addClass("dashedDiv");
          }
        }
      } else {
        this.FlashError('Not allowed');
      }
    }
  }

  dropEventMouse(event) {

  }
  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.ActivationDate = $('#date1').val();

  }
  addEvent2(value) {
    this.startbuilddate = value;
  }

  addEvent3(value) {
    this.completebuilddate = value;
  }

  randomNumber() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (let i = 0; i < 8; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    this.LinkCode = text;
    return text;
  }

  OpenModal(id) {
    this.IdSlot = id;
  }

  submitMasterCubes() {
    this.isAdding = true;
    $('#dots_spinner').show();
    if (!this.LinkCode) {
      $('#dots_spinner').hide();
      this.FlashError('Firstly generate linkcode');
    } else {
      $('#dots_spinner').hide();
      if (!this.ActivationDate) {
        this.FlashError('Select Activation date');
      } else {
        this.http.post<any>(this.apiList.MachineSNCheckUrl,
          {
            MachineSerial: this.MasterMachineSerial,
            IdMachine: this.Machine_Id
          },
          this.globalservice.Headers())
          .timeout(25000)
          .take(1)
          .map(resp => {
            if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
            if (+resp.Response !== 1) { throw resp; }
            return resp;
          }).subscribe((response) => {
            this.Snumbers = response;
            if (this.Snumbers.Response === 0) {
              this.FlashError('Machine Serial number is already in Use');
            } else if (this.Snumbers.Response === 1) {
              this.MasterMachineModel = this.MasterMachineModel1 + ' ' + this.MasterMachineModel2;
              this.http.post<any>(this.apiList.AddMachineUrl, {
                IdManufacturer: this.IdManufacturer,
                IdMachineModel: this.IdMachineModel,
                MachineModelNumber: this.MasterMachineModel2,
                PositionInGrid: this.IdSlot,
                MachineName: '',
                IdMasterMachine: 0, IsMaster: true
                , LinkCode: this.LinkCode,
                Machine: this.MasterMachine,
                MachineSerial: this.MasterMachineSerial,
                MachineType: this.MachineModelName,
                IdMachineType: 1,
                MachineModel: this.MasterMachineModel,
                NoOfTrays: this.MasterNoOfTrays,
                NoOfMotorsPerTray: this.MasterNoOfMotorsPerTray,
                ActivationDate: this.ActivationDate,
                Activated: this.Activated,
                CreatedBy: this.IdMerchant,
                AssignTo: this.IdMerchant,
                MaxCartSize: 4,
                BuildStartDate: this.startbuilddate,
                BuildCompleteDate: this.completebuilddate
              }, this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(resp => {
                  if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                  if (+resp.Response !== 1) { throw resp; }
                  return resp;
                }).subscribe((response) => {
                  this.added = response;
                  if (this.added.Response === 1) {
                    this.FlashError('Machine Created');
                    $('#M_' + this.IdSlot).addClass('fill_done');
                    this.IdMachine = this.added.IdMachine;
                    setTimeout(() => {
                      this.router.navigate(['./Superadmindashboard/Inventory']);
                    }, 1000);
                  }
                }, (err: any) => {
                  const Error = this.globalservice.ProcessError(err);
                  if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                      this.FlashError(Error.ErrorMessage);
                      this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                  } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                  }
                });
            }
          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => {
            $('#dots_spinner').hide();
            $('#Master_modal').modal('hide');
          });
      }
    }
  }
  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  back() {
    this.router.navigate(['./Superadmindashboard/Inventory']);
  }
}
