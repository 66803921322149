import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { GlobalService } from '../../../global.service';
import { SMTPTypes } from '../../../smtptypes.enum';

declare const $;

@Component({
  selector: 'app-firebasedetails',
  templateUrl: './firebasedetails.component.html',
  styleUrls: ['./firebasedetails.component.css']
})
export class FirebasedetailsComponent implements OnInit {

  //  @ViewChild('tabset', { static: false }) tabset: TabsetComponent;
  @ViewChild('server', { static: false }) public serverForm: NgForm
  Server = {
    RequestURI:'',
    SenderId:'',
    ServerAPIKey:'',
    IdFirebaseDetail:0
  }
  sendemail: any = { emailId: '' };
  upload_file: any;
//  TC: any
  AlertText: string;
  fileUrl;
  Template;
  IdEmailTemplateMaster = 0
  image
  status
  iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass = false;
  type = 'password';
  public files: NgxFileDropEntry[] = [];
  fileName: string = "No file choosen";
  Url: string = "";
  //merchant id
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");
  screenHeight: number;
  frameHeight: number;
  SMTPTypes:{SMTPTypeId: number; SMTPTypeName: string}[]=[];

  sendtestEmail: any;
  deleteSMTPSettings: any;

  ServerAPIKey;
  SenderId;
  RequestURI; 

  smtpavailable:SMTPTypes;

  smtpSelected=parseInt("1");
  ePropertyType =SMTPTypes;
  IdFirebaseDetail=0;
  //@ViewChild(MatSelect, { static: false }) t4: MatSelect;
  constructor(private router: Router, public apiList: ApilistService, private http: HttpClient, private globalservice: GlobalService, private sanitizer: DomSanitizer) {
  //   this.smtpSelected=parseInt("1");
  //   for(var n in SMTPTypes) {
  //     if (typeof SMTPTypes[n] === 'number') {
  //         this.SMTPTypes.push({SMTPTypeId: <any>SMTPTypes[n], SMTPTypeName: n});
  //     }
  // }
  }

  // ngAfterContentChecked() {
  //   // checks state for selected items i.e. isSelected
  //   // checks the boxes of the selected items in material dropdown by setting its value to an array of the values else an empty array
  //   console.log("inside select"+this.SMTPTypes[0].SMTPTypeId);
  //   this.smtpSelected = 1;
  //   this.t4.value = this.smtpSelected;
  // }

  ngOnInit() {
    this.HidePopups();
   

    this.getServerDetails();
    
  }

  

  HidePopups(){
    $("#alert_div").hide();
    $('#dots_spinner').hide();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerHeight;
    this.screenHeight = window.innerHeight;
    this.frameHeight = this.screenHeight - 360;
  }

  getServerDetails() {
    $('#dots_spinner').show();
   
    this.http.get<any>(this.apiList.GetMerchantFirebaseDetail, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(resp => {
      if (resp == null || resp.Response == null) resp = { Response: 0, ErrorMessage: "Empty response" };
      resp.Response = +resp.Response;
      if (resp.Response != 1) throw resp;
      return resp;
    })
    .subscribe((response: any) => {
      //console.log(response)
      if (response.Response == 1) {
        console.log("serverfirebase details"+JSON.stringify(response));
        if(response.firebaseDetail){
          this.Server = response.firebaseDetail;
          console.log("firbase details"+JSON.stringify(this.Server));
        }
        else {
          this.Server = {
            RequestURI:'',
            SenderId:'',       
            ServerAPIKey:'',
            IdFirebaseDetail:0
           
          }
        }
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.Server = {
            RequestURI:'',
            SenderId:'',       
            ServerAPIKey:'',
            IdFirebaseDetail:0
           
          }
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {$('#dots_spinner').hide(); });
  }

  save() {
    var data;

      $('#dots_spinner').show();
      var url = this.apiList.SaveOrUpdateFirebaseDetails;
      // data = {
      //   RequestURI:this.smtpSelected,
      //   SenderId: this.Server.IdSMTPServer,
      //   ServerAPIKey: this.Server.SMTPServerHost,
      //   PortNumber: this.Server.PortNumber,
      //   IdMerchant: this.IdMerchant,
      //   FromUserName: this.Server.FromUserName,// this.Server.FromUserName,
      //   SMTPEmail: this.Server.SMTPEmail,//this.Server.SMTPEmail,
      //   SMTPPassword: this.Server.SMTPPassword,
      //   IsSSL_enabled: this.Server.IsSSL_enabled,
      //   ToEmailId: '',
        
      // }

      console.log("firebseid"+this.Server.IdFirebaseDetail );
      

      data = {
        RequestURI:this.Server.RequestURI,
        SenderId: this.Server.SenderId,
        ServerAPIKey: this.Server.ServerAPIKey,
        
    IdFirebaseDetail:this.Server.IdFirebaseDetail 
      }
      console.log("data"+JSON.stringify(data));
    
    this.http.post<any>(url, data, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(resp => {
      if (resp == null || resp.Response == null) resp = { Response: 0, ErrorMessage: "Empty response" };
      resp.Response = +resp.Response;
      if (resp.Response != 1) throw resp;
      return resp;
    })
    .subscribe((response: any) => {
      if (response.Response == 1) {
        this.FlashError("Firebase Setting Saved Successfully");
        this.getServerDetails();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {  $('#dots_spinner').hide(); });
  }


  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  showModal() {
    $('#myModal').modal('show');
  }





  smtp(){this.router.navigate([this.companyName + '/dashboard/setting'])}
  email(){this.router.navigate([this.companyName + '/dashboard/setting/EmailTemplate'])}
  terms(){this.router.navigate([this.companyName + '/dashboard/setting/term'])}
  listSpecial(){this.router.navigate([this.companyName + '/dashboard/setting/specialMessage'])}

  firebaseRoute(){this.router.navigate([this.companyName + '/dashboard/setting/FirebaseDetails'])}

}
