import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-updateplaceamachine',
  templateUrl: './updateplaceamachine.component.html',
  styleUrls: ['./updateplaceamachine.component.css']
})
export class UpdateplaceamachineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
