import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material/sort';
import { GlobalService } from '../../global.service';
import { NgForm } from "@angular/forms";
import { ApilistService } from '../../../service/Api/apilist.service';
import { Subject } from 'rxjs';
declare const $;

const FixedRecordCount = 100;

@Component({
  selector: 'app-place-machine',
  templateUrl: './place-machine.component.html',
  styleUrls: ['./place-machine.component.css']
})

export class PlaceMachineComponent implements OnInit,OnDestroy {
  @ViewChild('planogrmcopy', { static: false }) public PlanogrmcopyForm: NgForm

  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");
  Sessionid_Admin = sessionStorage.getItem("Sessionid_Admin")
  QRcodeUrl = environment.BaseFileUrl + "/ClientDocument/"

  public VendEvents: EventSource;
  lists: any
  IdMachine: string;
  IdPlaceMachine: number;
  LocationNumber: string;
  LocationName: string;
  LocationAddress: string;
  City: string;
  State: string;
  Zip: string;
  p: any;
  Search: any;
  MachineSerial
  posts: any;
  lposts: any;
  LocationDescription: any;
  selectedMachine: string;
  CopyToIdMachines: number;
  TimeInSecond
  check
  ProductType
  delete_data;
  AlertText
  machine_info;
  location_info: any;
  type: any;
  //screenHeight: number;
  before: number;
  pageno: number = 1;
  ll: any;
  length_placemachine: number;
  Tposts: any;
  Machine_Serial: any;
  EventUrl: any;
  pageCount: number;


  screenHeight: number;
  resize$ = new Subject<void>(); // for debounce
  public RowHeight: number = 43; // initial estimate

  public Resizing: boolean = false;

  // record cache and pagination
  
  public Records = [];
  public CurrentPage: number = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage: number = 1;
  RecordCount: number = FixedRecordCount;
  source: any;
  OldSearch: string


  doSearch = false;

  constructor(public apiList: ApilistService, private router: Router, private http: HttpClient, private globalservice: GlobalService) {
    // this.screenHeight = window.innerHeight;
    // var rowno = (window.innerHeight - 250) / 70;
    // if (rowno >= 1) {
    //   this.before = parseInt(rowno.toFixed(2).toString().split(".")[0], 10);
    //   //console.log(this.before);
    // } else {
    //   this.before = 1;
    // }
  }

  ngOnInit() {
    this.HidePopups()
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) return;
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
    this.DoQuery(1, 1, true);
   // this.refreshplaceamachinelist();
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private HidePopups() {
    $('#dots_spinner').hide();
    $("#alert_div").hide();
    $('#MachineInformation').modal('hide');
    $('#LocationDetails').modal('hide');
  }
  Repaginate() {
    this.Resizing = true;
    this.ItemsPerPageBeforeResize = this.ItemsPerPage;
    this.CurrentPageBeforeResize = this.CurrentPage;
    this.CurrentPage = 1;
    this.ItemsPerPage = this.RecordCount;
  }

  private UpdateRowHeight() {

    var rows = (<HTMLTableElement> document.getElementById('recordset')).rows;

    if (rows.length > 1) this.RowHeight = 0;

    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }

    console.log(`RowHeight: ${this.RowHeight}`);
  }

  private AutosizeTable() {

    console.log("Autosize table");
    
    this.UpdateRowHeight();
    var tableOffset = $("#tableBody").offset();
    var pageControlOffset = $("#paginate").offset();

    if (tableOffset == null || pageControlOffset == null) {
      console.log("Can't find")
      console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;

      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);

      // if ItemsPerPage changes, we could suddenly be on a range of rows that
      // have not been pulled from the server.
      // Also, unless user is on the first page they will be at a whole different set of records ("fly-away")
      // we don't know exactly which item the user was looking at, but we can get them
      // pretty close! Using the middle record
      if (this.ItemsPerPageBeforeResize != this.ItemsPerPage || this.CurrentPageBeforeResize != this.CurrentPage) {
        let rowToShow = Math.min((this.CurrentPageBeforeResize - 1) * this.ItemsPerPageBeforeResize + Math.trunc((this.ItemsPerPageBeforeResize + 1) / 2), Math.max(this.Records.length - 1, 0));
        setTimeout(() => {
          this.pageChange(Math.trunc(rowToShow / this.ItemsPerPage) + 1);
        }, 0);
      }
    }

    this.Resizing = false;
    console.log("Resize complete>");
  }


  DoQuery(originPage: number, finalPage: number, reload: boolean) {

    let req: any = {
      
      PageNumber: originPage,
      RecordsPerPage: this.ItemsPerPage,
      RecordCount: this.RecordCount,
      IdMerchant: this.IdMerchant, 
      CreatedBy: this.IdMerchant,
      SessionId: this.Sessionid_Admin,
    };

    let searchText = this.Search;
   // this.doSearch = false;
    if (searchText != null && searchText.length != 0) {
      req.SearchText = searchText;
      this.doSearch = true;
    }

    this.Fetch(this.doSearch ? this.apiList.placemachinesearchUrl :this.apiList.PlaceMachinelst, req, finalPage, reload);
  }


  Fetch(url: string, req: any, finalPage, reload: boolean = false) {
    console.log(req);
    $('#dots_spinner').show();
    this.http.post<any>(url, req, this.globalservice.Headers())
    .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      console.log(response)
      if(this.doSearch == false){
        this.Event(response.EventUrl)
      }

      // mergresponsee retrieved records into cache
      this.globalservice.MergeRecords({
        cache: this.Records,
        newRecords: response.lst_PlaceMachines,
        totalCount: response.TotalCount,
        pageNumber: req.PageNumber,
        itemsPerPage: req.RecordsPerPage,
        reload
      });

      // transition to the requested page
      this.CurrentPage = finalPage;;

    }, (error) => {

      if (typeof error == 'object' && error.constructor.name == 'Response') {
        error = {
          ErrorMessage: "Network error",
          Resonse: 7
        };
      } else if (typeof error == "string" || error == null) {
        error = {
          ErrorMessage: "Unexpected error",
          Response: 8
        };
      } else if (error.hasOwnProperty("name") && error.name === "TimeoutError") {
        error = {
          ErrorMessage: "Request timed out",
          Response: 6
        };
      }

      console.log(error);

      this.AlertText = error.ErrorMessage;
      if (this.AlertText.length != 0) {
        this.FlashError();
      }

      if (error.Response === 35) {

        if (error.ErrorMessage.length != 0) {
          this.FlashError();
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
      }
    }, () => {
    }).add(() => {
      $('#dots_spinner').hide();
      if (this.Resizing) {

        // need to give time to render behind the scenes before size/resize rows
        this.SettleAndAutosize();
      }
    });
  }

  Event(value){
    if( this.VendEvents !=null) return;
    this.EventUrl = value;
    var id = value.split("=")
    sessionStorage.setItem('Sessionid_Admin', id[1])
    this.VendEvents = new EventSource(this.EventUrl);
    this.VendEvents.onmessage = (e) => {
      var Responsee = e.data;
      var Response = JSON.parse(Responsee)
      for (let i = 0; i < Response.length; i++) {
        for (let j = 0; j < this.Records.length; j++) {
          if (this.Records[j].IdMachine == Response[i].objChange.IdMachine) {
            this.Records[j].UpTime = Response[i].objChange.UpTime
            this.Records[j].MachineStatus = Response[i].objChange.MachineStatus
          }
        }
      }
    }
  }
  

  searchDebounce($event: KeyboardEvent) {

    if (this.Search == null) return;

    // on ENTER, commit the search, no waiting
    if ($event.keyCode === 13 || !this.Search.length) {
      if (this.source) {
        clearTimeout(this.source);
        this.source = null;
      }
      this.OldSearch = this.Search;
      this.DoQuery(1, 1, true);
      return;
    }

    //... rest is to wait until user stops typing as indication of waiting for search

    // ignore keyup events if string doesn't actually change (like, ENTER)
    if (this.OldSearch == this.Search) {
      return;
    }
    this.OldSearch = this.Search;

    // looks like still typing. Cancel any delay and start waiting all over again

    let oldSource = this.source;
    if (oldSource) {
      clearTimeout(oldSource);
    }

    // delay API call to see if user is still typing.
    this.source = setTimeout(() => {
      this.source = null;
      this.DoQuery(1, 1, true);
    }, 800);

  }

  getID(id: number) {
    this.IdPlaceMachine = id;
  }

  placemachine_btn() {
    this.router.navigate([this.companyName + '/dashboard/placeaMachine/addplacemachine']);
  }

  setup1(country, id: string, location: string, machine: string, locatnName: string, locationAdd: string, Cty: string, Stat: string, Z: string, type, linkcode, cubemachine: number, location_id,IdPlaceMachine) {
    this.IdMachine = id;
    this.LocationNumber = location;
    this.MachineSerial = machine;
    this.LocationName = locatnName;
    this.LocationAddress = locationAdd;
    this.City = Cty;
    this.State = Stat;
    this.Zip = Z;
    sessionStorage.setItem("refrencemachineid", this.IdMachine);
    sessionStorage.setItem("referMachinetype", "Master");
    sessionStorage.setItem("referProductType", null);
    this.router.navigate([this.companyName + '/dashboard/placeaMachine/Planogramtraymotor', {
      l_c: linkcode,
      c_m: cubemachine,
      I_l: location_id,
      id: this.IdMachine,
      idplace:IdPlaceMachine
    }]);


  }

  setup2(id:string,linkcode, cubemachine, location_id,IdPlaceMachine) {
    sessionStorage.setItem("referMachinetype", "Slave");
    this.http.post<any>(this.apiList.ProductPlaceInMasterUrl, {  LinkCode: linkcode, CubeNo: cubemachine }, this.globalservice.Headers()).subscribe((response) => {
      this.check = response
      if (this.check.Response == 1) {
        //console.log(this.check);

        //add machineid as per api changes
        this.ProductType = this.check.ProductType
        sessionStorage.setItem("referProductType", this.ProductType);
        this.router.navigate([this.companyName + '/dashboard/placeaMachine/Planogramtraymotor', {
          l_c: linkcode,
          c_m: cubemachine,
          I_l: location_id,
          P_Type: this.ProductType,
          id:id,
          idplace:IdPlaceMachine
        }]);

//commenting code

        // if (this.check.IsProductPlaced == true) {
        //   this.ProductType = this.check.ProductType
        //   sessionStorage.setItem("referProductType", this.ProductType);
        //   this.router.navigate([this.companyName + '/dashboard/placeaMachine/Planogramtraymotor', {
        //     l_c: linkcode,
        //     c_m: cubemachine,
        //     I_l: location_id,
        //     P_Type: this.ProductType
        //   }]);
        // }
        // if (this.check.IsProductPlaced == false) {
        //   this.ProductType = this.check.ProductType
        //   this.AlertText = ""
        //   this.AlertText = "Please First Setup Master Planogram";
        //   this.FlashError()
        // }
      }
      else if (this.check.Response === 35) {
        this.AlertText = "";
        this.AlertText = this.check.ErrorMessage
        if (this.AlertText) {
          this.FlashError();
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
      }
      else {
        this.AlertText = "";
        this.AlertText = this.check.ErrorMessage
        this.FlashError();
      }
    });
  }

  delete() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteUrl, {  IdPlaceMachine: this.IdPlaceMachine }, this.globalservice.Headers()).subscribe(success => {
      $('#dots_spinner').hide();
      this.delete_data = success;
      if (this.delete_data.Response == 1) {
        this.AlertText = ""
        this.AlertText = 'Successfully deleted';
        this.FlashError()
        this.DoQuery(this.CurrentPage,  this.CurrentPage,  true);
      }
      else if (this.delete_data.Response === 35) {
        this.AlertText = "";
        this.AlertText = this.delete_data.ErrorMessage
        if (this.AlertText) {
          this.FlashError();
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
      }
      else {
        this.AlertText = ""
        this.AlertText = this.delete_data.ErrorMessage;
        this.FlashError()
      }
    });
  }

  copy(id, machine_model, machineSerial) {
    this.IdMachine = id;
    this.Machine_Serial = machineSerial
    this.MachineListAccordingtoTrayAndMotor(machine_model);
  }

  SubmitCopy() {
    if (this.CopyToIdMachines) {
      $('#dots_spinner').show();
      this.http.post<any>(this.apiList.CopyPlanogramUrl, {  CopyToIdMachines: this.CopyToIdMachines, CopyFromIdMachine: this.IdMachine }, this.globalservice.Headers()).subscribe((response) => {
        $('#dots_spinner').hide();
        if (response.Response == 1) {
          let res = response;
          this.AlertText = "";
          this.AlertText = res.ErrorMessage;
          this.FlashError();
          this.PlanogrmcopyForm.reset();
        }
        else if (response.Response === 35) {
          this.AlertText = "";
          this.AlertText = response.ErrorMessage
          if (this.AlertText) {
            this.FlashError();
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
        }
        else {
          this.AlertText = "";
          this.AlertText = response.ErrorMessage
          this.FlashError();
        }
      });
    }
  }

  MachineListAccordingtoTrayAndMotor(value) {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachinelstTMUrl, {  IdMachine: this.IdMachine, IdMerchant: this.IdMerchant, IdMachineModel: value }, this.globalservice.Headers()).subscribe((response) => {
      $('#dots_spinner').hide();
      if (response.Response == 1) {
        this.posts = response;

        //console.log(this.posts);
      }
      else if (response.Response === 35) {
        this.AlertText = "";
        this.AlertText = response.ErrorMessage
        if (this.AlertText) {
          this.FlashError();
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
      }
      else {
        this.AlertText = "";
        this.AlertText = response.ErrorMessage
        this.FlashError();
      }
    });
  }

  onChangeMachine(b: number) {
    this.CopyToIdMachines = b;
    // this.CopyToIdMachines = this.CopyToIdMachines.map(function (e) {
    //   return JSON.stringify(e);
    // });
    // this.CopyToIdMachines = this.CopyToIdMachines.join(",");
  }

  locationDiscription() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.UpdateLocationDesUrl, {  IdPlaceMachine: this.IdPlaceMachine, LocationDescription: this.LocationDescription }, this.globalservice.Headers()).subscribe((response) => {
      $('#dots_spinner').hide();
      this.lposts = response;
  
      if (this.lposts.Response == 1) {
        this.DoQuery(this.CurrentPage,  this.CurrentPage, true);
        this.AlertText=''
        this.AlertText='Location Description Updated Successfully'
        this.FlashError();
       // this.refreshplaceamachinelist();
      }
      else if (this.lposts.Response === 35) {
        this.AlertText = "";
        this.AlertText = this.lposts.ErrorMessage
        if (this.AlertText) {
          this.FlashError();
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
      }
      else {
        this.AlertText = "";
        this.AlertText = this.lposts.ErrorMessage;
        this.FlashError();
      }
    });
  }



  getid(b) {
    this.IdMachine = b;
  }

  sortData(sort: Sort) {
    const data = this.lists.slice();
    if (!sort.active || sort.direction === '') {
      this.lists = data;
      return;
    }
    this.lists = data.sort((a, b) => {
      //console.log(typeof (b.Obj_Machine.MachineSerial));
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'machine_serial': return this.compare((a.MachineModelNumber + '' + a.MachineSerial).replace(/\s+/g, '').toLowerCase(), (b.MachineModelNumber + '' + b.MachineSerial).replace(/\s+/g, '').toLowerCase(), isAsc);
        case 'location_name': return this.compare(a.LocationName.toLowerCase(), b.LocationName.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  MachineInfo(data, type) {
    this.type = type;
    $('#MachineInformation').modal('show');
    this.machine_info = data;
  }

  locationInfo(data) {
    $('#LocationDetails').modal('show');
    this.location_info = data;
    console.log("this.location_info"+JSON.stringify(this.location_info));
  }

  Location_description(data) {
    if (data == null) {
      this.LocationDescription = '';
    } else {
      this.LocationDescription = data;
    }
  }

  clearSearch() {
    clearInterval(this.source);
    this.Search = '';
this.doSearch= false
    this.DoQuery(1, 1, true);
  }

  public pageChange(pageNumber: number) {

    this.globalservice.PagingHandler(
      pageNumber, 
      this.Records,
      this.ItemsPerPage,
      FixedRecordCount,
      (originPage, finalPage, reload) => this.DoQuery(originPage, finalPage, reload),
      (pageNumber) => this.CurrentPage = pageNumber);
  }

  
  // PagingHandler(
  //   pageNumber: number,
  //   records: Array<any>,
  //   itemsPerPage: number,
  //   quantum: number,
  //   pullRecords: (originPage: number, finalPage: number, reload: boolean) => void,
  //   changePage: (pageNumber: number) => void) {

  //     console.log(`Start change to ${pageNumber}`);

  //     let rowNumber = (pageNumber - 1) * itemsPerPage;
  //     let limit = Math.min(rowNumber + itemsPerPage, records.length);
  //     let originPage: number = pageNumber;

  //     // confirm that we have all records defined for the requested page. If we don't, call API to pull records
  //     for (let offset = 0; offset < itemsPerPage; offset++) {
  
  //       let i: number = offset + rowNumber;
  //       if (i >= limit) break;
  
  //       // need to make sure we request records if ANY records in the requested page are undefined
  //       if (records[i] == undefined) {

  //         // shift the limit back as far as necessary to capture the furthest "undefined record"
  //         // while still capturing the demanded page.
  //         let requestedPageLimit = i + (itemsPerPage - offset);
  //         for (limit = Math.min(rowNumber + quantum, records.length); limit > requestedPageLimit; limit--) {
  //           if (records[limit - 1] == undefined) break;
  //         }

  //         // found the "ideal limit". Now our ideal range starts #quantum records back from here.
  //         originPage = Math.ceil(Math.max(limit - quantum, 0) / itemsPerPage) + 1;
  
  //         pullRecords(originPage, pageNumber, false);
  //         return;
  //       }
  //     }
  
  //     changePage(pageNumber);
  // }


  FlashError() {
    $("#alert_div").show();
    $("#alert_div").animate({
      width: "100%"
    }, "slow");
    setTimeout(() => {
      $("#alert_div").hide();
    }, 3000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }

  edit(id) {
    this.router.navigate([this.companyName + '/dashboard/placeaMachine/editplacemachine', {
      id: id
    }])
  }

  ngOnDestroy(): void {
    if (this.VendEvents) {
      this.VendEvents.close();
    }
    this.HidePopups();
    this.resize$.complete();
  }


}


