import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
declare const $;
@Component({
  selector: 'app-campaigncalendar',
  templateUrl: './campaigncalendar.component.html',
  styleUrls: ['./campaigncalendar.component.css']
})
export class CampaigncalendarComponent implements OnInit {
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
  AlertText: string;
  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public apiList: ApilistService) { }
  p = 1;
  lstContents;
  details;
  CampaignDuration;
  CampaignStartDate;
  CampaignEndDate;
  Campaignlists: any;
  DailyPlayTime;
  selectedCampaign;
  lst_Machines;
  Sum = 0;


  ngOnInit() {
    this.hidepops();
    this.Campaignlist();
  }
  hidepops() {
    $('#dots_spinner').hide();
    $('#alert_div').hide();
    $('#machinelist').hide();
  }
  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    // this.StartDate =$('#SDate').val();
  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    // this.EndDate =$('#EDate').val();
  }

  sumup() {
    for (let i = 0; i < this.lstContents.length; i++) {
      this.Sum += parseInt(this.lstContents[i].Duration, 0);
    }
  }

  Campaignlist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CampaignlistApi,
      { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.Campaignlists = response;
        this.Campaignlists = this.Campaignlists.lst_GetListOfCampaign;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  changeCampaign(id) {
    this.selectedCampaign = id;
    $('#dots_spinner').show();
    this.Sum = 0;
    $('#machinelist').show();
    this.http.post<any>(this.apiList.CampaignDetailsApi, { IdCampaign: this.selectedCampaign },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.details = response;
        this.CampaignDuration = this.details.Obj_CampaignClass.CampaignDuration;
        this.CampaignStartDate = this.details.Obj_CampaignClass.StringCampaignStartDate;
        this.CampaignEndDate = this.details.Obj_CampaignClass.StringCampaignEndDate;
        $('#SDate').val(this.CampaignStartDate);
        $('#EDate').val(this.CampaignEndDate);
        this.lstContents = this.details.lst_Content;
        this.lst_Machines = this.details.lst_Machines;
        this.sumup();
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  AddScreenbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage']); }
  AddContentbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Addcontent']); }
  Adloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Adloop']); }
  placeloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/placeloop']); }
  preview() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Previewcampaign']); }
  Calendar() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaigncalendar']); }
  report() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaignreports']); }
  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}
