import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material/sort';
import { NgForm, FormControl, FormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs/Rx';
import { GlobalService } from '../../global.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

declare const $;
// import { Bank, BANKS } from '../distributorlisting/demodata';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material';
import { ApilistService } from '../../../service/Api/apilist.service';
export interface Bank {
  IdMerchant: string;
  Merchant: string;
}

@Component({
  selector: 'app-distributorlisting',
  templateUrl: './distributorlisting.component.html',
  styleUrls: ['./distributorlisting.component.css']
})

export class DistributorlistingComponent implements OnInit, OnDestroy {
  baseUrl = environment.baseUrl;
  IdMerchant = sessionStorage.getItem('referSuperMerchantId');

  DistributorId: any;
  IdMerchants: any = [];
  // merchant: any;
  Merchant: any;
  Distributor: any;
  Distributorname;
  assign_merchant: any;
  Selected_DistributorId: any;
  Assignmerchantlist: any;
  Unassignmerchantlist: any;
  Assign;
  Unassign;
  selectedmerchant: any;
  Search: any;
  somethingchange = false;
  Bank = [];
  protected banks: Bank[];
  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  profileForm: FormGroup;
  AlertText: string;
  countrylists: any;

  constructor(
    public apiList: ApilistService,
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    private route: ActivatedRoute) {
    this.route.params.subscribe((get) => {
      this.selectedmerchant = get.id;
      if (this.selectedmerchant) {
        this.DistributorId = get.id;
        this.Distributorlist2();
        this.Distribute_selection(get.id, get.name);
      } else {
        this.Distributorlist();
      }
    });
  }
  ngOnDestroy(): void {
    this.hidepops();
  }

  ngOnInit() {
    this.hidepops();
    this.profileForm = new FormGroup({
      selectedmerchant: new FormControl(''),
    });
  }

  hidepops() {
    $('#alert_div').hide();
    $('#dots_spinner').hide();
    $('#Assign_Merchant').modal('hide');
    $('#Merchantlisting').modal('hide');
    $('#myModal').modal('hide');
  }

  Distributorlist() {
    this.http.post<any>(this.apiList.DistributorlistURL,
      {}, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      }).subscribe((response) => {
        this.Distributor = response.lst_Distributors;
        this.Bank = response.lst_Distributors;
        const BANKS = this.Bank;
        this.banks = BANKS;
        this.bankCtrl.setValue(this.banks);
        this.filteredBanks.next(this.banks.slice());
        this.bankFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterBanks();
          });
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { });
  }


  Distributorlist2() {
    this.http.post<any>(this.apiList.DistributorlistURL,
      {}, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      }).subscribe((response) => {
        this.Distributor = response.lst_Distributors;
        this.Bank = response.lst_Distributors;
        const BANKS = this.Bank;
        this.banks = BANKS;
        this.bankCtrl.setValue(this.banks);
        this.filteredBanks.next(this.banks.slice());
        if (this.selectedmerchant) {
          this.selectedmerchant = JSON.parse(this.DistributorId); // $$ BUG why isn't this already in right format? Fix on server side?
        }
        this.bankFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterBanks();
          });
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { });
  }

  Yes() {
    $('#myModal').modal('hide');
    this.somethingchange = false;
  }

  No() {
    $('#myModal').modal('hide');
    this.somethingchange = true;
  }

  Distribute_selection(id, name) {
    this.somethingchange = false;
    this.DistributorId = id;
    this.http.post<any>(this.apiList.MerchantListingURL,
      { IdDistributor: this.DistributorId },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      }).subscribe((response) => {
        $('#merchant').show();
        this.Assignmerchantlist = response.lst_assignMerchant;
        this.Unassignmerchantlist = response.lst_UnassignMerchant;
        this.Filter();
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { });
  }


  drop(event: CdkDragDrop<string[]>) {
    this.somethingchange = true;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }


  Assign_merchant() {
    this.somethingchange = false;
    for (let i = 0; i < this.Assignmerchantlist.length; i++) {
      this.IdMerchants.push(
        this.Assignmerchantlist[i].IdMerchant
      );
    }
    this.http.post<any>(this.apiList.AssignMerchantURL,
      { IdMerchant: this.IdMerchants, IdDistributor: this.DistributorId },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      }).subscribe((response) => {
        this.FlashError('successfully assigned');
        this.selectedmerchant = '';
        $('#merchant').hide();
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { });
  }

  clearSearch() {
    this.Search = '';
    this.Distribute_sel(this.DistributorId, this.selectedmerchant);
  }

  search_merchnat() {
    this.http.post<any>(this.apiList.SearchMerchantURL,
      {
        IdDistributor: this.DistributorId,
        SearchText: this.Search
      }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      }).subscribe((response) => {
        this.Unassignmerchantlist = response.lst_UnassignMerchant;
        this.Filter();
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { });
  }

  Filter() {
    this.Unassignmerchantlist = this.Unassignmerchantlist.filter(item1 =>
      !this.Assignmerchantlist.some(item2 => (item2.IdMerchant === item1.IdMerchant)));
  }

  Distribute_sel(id, name) {
    this.DistributorId = id;
    this.http.post<any>(this.apiList.MerchantListingURL,
      { IdDistributor: this.DistributorId },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      }).subscribe((response) => {
        this.Unassignmerchantlist = response.lst_UnassignMerchant;
        this.Filter();
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  protected setInitialValue() {
    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: Bank, b: Bank) => a && b && a.IdMerchant === b.IdMerchant;
      });
  }

  protected filterBanks() {
    if (!this.banks) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.banks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.banks.filter(bank => bank.Merchant.toLowerCase().indexOf(search) > -1)
    );
  }


  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}


