import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from '../../../../environments/environment';
import { NgForm } from "@angular/forms";
import { GlobalService } from '../../../global.service'
import { ApilistService } from '../../../../service/Api/apilist.service';
declare const $;

@Component({
  selector: 'app-pickreport',
  templateUrl: './pickreport.component.html',
  styleUrls: ['./pickreport.component.css']
})
export class PickreportComponent implements OnInit {
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");
  CSVFileUrl = environment.CSVFileUrl;
  BaseFileUrl = environment.BaseFileUrl;
  ImageUrl = this.BaseFileUrl + "/ClientDocument/ThumbnailImages/";


  @ViewChild('reportinglocation', { static: false }) public reportinglocationForm: NgForm
  list: any;
  selectedroute: any;
  array = [];
  Route_id
  routelist: any;
  machinelist: any;
  RouteName: any;
  AlertText: string;
  coupon_code
  coupon_amount
  coupon_used
  coupon_startdate
  CurrentPage: number = 1;


  constructor(private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public apiList: ApilistService) { }

  ngOnInit() {
    this.List_Route();
    this.HidePopups();
  }

  HidePopups(){
    $('#Norecord').hide();
    $('#dots_spinner').hide();
    $("#alert_div").hide();
  }

  pageChange(event) {
    this.CurrentPage = event
  }

  List_Route() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.GetRouteListUrl, {  CreatedBy: this.IdMerchant }, this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      this.list = response;
      if (this.list.Response == 1) {
        this.list = this.list.lst_Routes;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  selectAll() {
    $('#checkall').addClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
    this.array = [];
    for (let i = 0; i < this.list.length; i++) {
      this.array.push(this.list[i].RouteId);
    }
    this.selectedroute = this.array;
    this.Route_id = this.array;
    // this.Route_id = this.selectedroute.toString();
  }

  deselectAll(id) {
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').addClass("btn_active");
    this.selectedroute = '';
  }

  onChangeroute(id) {
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
    this.Route_id = id
  }

  listofpickupreport() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.GetPickupReportUrl, {  RouteId: this.Route_id }, this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      // console.log(response)
      var Response = response
      if (Response.Response == 1) {
        this.RouteName = Response.RouteName
        this.routelist = Response.objSectionOne.lst_Products
        this.machinelist = Response.objsectionTwo.lst_LocationsMachines
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  export() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ExportPickuptUrl, {  RouteId: this.Route_id }, this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      //  console.log(response);
      let result = response;
      if (result.Response == 1) {
        let FileName = result.FileName
        window.location.href = this.CSVFileUrl + '/' + FileName;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  Print() {
    this.http.post<any>(this.apiList.PrintUrl, {  RouteId: this.Route_id }, this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      //   console.log(response)
      var data = response;
      if (data.Response == 1) {
        data = data.htmltext
        var popupWinindow = window.open('', '_blank', 'width=1366, height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        popupWinindow.document.write('<html><head></head><body onload="window.print()" onafterprint="window.close()">' + data + '</html>');
        popupWinindow.document.close();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  salesbtn() { this.router.navigate([this.companyName + '/dashboard/reporting']); }
  pickreport() { this.router.navigate([this.companyName + '/dashboard/reporting/pickup']); }
  productbtn() { this.router.navigate([this.companyName + '/dashboard/reporting/productsale']); }
  exception() { this.router.navigate([this.companyName + '/dashboard/reporting/exception']); }
}
