import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgForm } from "@angular/forms";
import { Observable } from 'rxjs/Rx';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';
import { Howfaraisle } from '../../../howfaraisle.enum';
import { Sideofaisle } from '../../../sideofaisle.enum';
declare const $;

@Component({
  selector: 'app-addproductlocation',
  templateUrl: './addproductlocation.component.html',
  styleUrls: ['./addproductlocation.component.css']
})
export class AddproductlocationComponent implements OnInit, OnDestroy {

  @ViewChild('personForm', { static: false }) public UserForm: NgForm
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");
  UserRole = sessionStorage.getItem("referUserRole");
  Name: string;
  Password: any;
  DateSetup: string;
  DateCancelled: string;
  Premissions: string;
  EmailId: any;
  CompanyName: string;
  CompanyAddress: string;
  City: string;
  State: string;
  Zip: string;
  CreatedBy = 1;
  posts: any;
  IsActive: boolean;
  ConfirmPassword: any;;
  AlertText
  isAdding = false;
  values = '';
  compare = true
  nextLibAvailable = false;
  show_message
  iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass = false;
  type = 'password';
  iconname2 = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass2 = false;
  type2 = 'password';
  countrylists: any;
  IdCountry
  IdState
  states: any;
  citys: any;
  Lastname
  bit: any = false;
  rownum = 1;
  floornum = 1;
  hfardown;
  sideofaisleval;
  aisle;
  chainval="None";
  Locationval;
  ProductVal;
  chainList: any[] = [];
  locationList: any[] = [];


  howFarAisleArr: { Id: number; Name: string }[] = [];
  sideOfAisleArr: { Id: number; Name: string }[] = [];




  constructor(private router: Router,
    private http: HttpClient,
    public dialog: MatDialog,
    private globalservice: GlobalService, public apiList: ApilistService) {

    for (var n in Howfaraisle) {
      if (typeof Howfaraisle[n] === 'number') {
        this.howFarAisleArr.push({ Id: <any>Howfaraisle[n], Name: n });
      }
    }


    for (var n in Sideofaisle) {
      if (typeof Sideofaisle[n] === 'number') {
        this.sideOfAisleArr.push({ Id: <any>Sideofaisle[n], Name: n });
      }
    }
  }

  ngOnInit() {
    this.globalservice.setDiscard_check();
    this.refreshChainlist();
    this.onChangechain(this.chainval);
    this.refreshlistproduct();

    this.HidePopups()
  }

  HidePopups() {
    $('#txtPassword').attr("autocomplete", "off");
    setTimeout('$("#txtPassword").val("");', 50);
    $("#alert_div").hide();
    $('#dots_spinner').hide();
    $('#psw').hide();
  }


  onChangechain(id: string) {
    this.chainval = id;
  if(id=="None"){
id=null
  }
      $('#dots_spinner').show();
     
      this.http.post<any>(this.apiList.LocationsByChain, { IdChain: id }, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (result.Response !== 1) throw result;
          return result;
        }).subscribe((response) => {
          $('#dots_spinner').hide();
          if (response.Response == 1) {
            this.Locationval=null;
            //console.log("locations are" + JSON.stringify(response));
            this.locationList = response.lst_Location;
            //console.log("this.location list" + JSON.stringify(this.locationList));
            // this.names = response;
            // // console.log(this.names.length);
            // this.names = this.names.lst_mc
            // this.subcat_length = this.names.length;
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
        });
    // }
    // else {
    //   this.chainval = null;
    //   this.Locationval = null;
    // }
  }

  onChangeloc(id1: string) {
    if (id1) {
      this.Locationval = id1;
    } else {
      this.Locationval = null;
    }
  }


  canDeactivate(): any {
    if (!this.isAdding && this.UserForm.dirty) {
      if (this.bit == false && this.globalservice.GetDiscard_Check()) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });
        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response == "ok")) {
            this.dialog.closeAll();
            this.bit = true;
            this.router.navigate([this.companyName + '/dashboard/productlocations']);
          }
        });
      }
    } else if (this.UserForm.dirty) {
      if (this.bit == false) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });
        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response == "ok")) {
            this.dialog.closeAll();
            this.bit = true;
            this.router.navigate([this.companyName + '/dashboard/productlocations']);
          }
        });
      }
    }
    return true;
  }

  refreshChainlist() {
    $('#dots_spinner').show();
    // console.log(this.globalservice.Headers())

    this.http.post<any>(this.apiList.MerchantChains, {}, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
        if (response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response) {
          console.log("merchant chain list" + JSON.stringify(response));
          this.chainList = response.Chains;
          $('#dots_spinner').hide();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
            $('#dots_spinner').hide();
          }
          this.globalservice.Logout('No');
          $('#dots_spinner').hide();
          return;
        } else {
          $('#dots_spinner').hide();
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();

      });
  }

  refreshlistproduct() {
    $('#dots_spinner').show();
    let url1 = `${this.apiList.ProductLstUrl}`;
    this.http.post<any>(url1, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        this.posts = response;
        this.posts = this.posts.lst_Product;
        //this.TotalCount = this.posts.TotalCount;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 4) {
          this.posts = err;
          this.posts = err.lst_Product === null ? [] : null;
          //  this.TotalCount = err.TotalCount;
          return;
        }
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }

      }).add(() => {
        $('#dots_spinner').hide();

      });
  }




  submit_click() {

    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.AddLocationProduct, { IdProduct: this.ProductVal, IdLocation: this.Locationval, RowNumber: this.rownum, FloorNumber: this.floornum, Aisle: this.aisle, HowFarDownAisle: this.hfardown, SideOfAisle: this.sideofaisleval }, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) throw result;
        return result;
      })
      .subscribe((response) => {
        this.posts = response;
        if (this.posts.Response == 1) {
          this.UserForm.reset();
          this.router.navigate([this.companyName + '/dashboard/productlocations']);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });

  }


  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }



  goback() {
    this.router.navigate([this.companyName + '/dashboard/productlocations']);
  }

  // Flasherror() {
  //   $("#alert_div").show();
  //   $("#alert_div").animate({
  //     width: "100%"
  //   }, "slow");
  //   $('#user_detail').hide();
  //   setTimeout(() => {
  //     $("#alert_div").hide();
  //   }, 3000);
  // }

  ngOnDestroy() {
    this.dialog.ngOnDestroy();
  }

}
