import { Component, OnInit, ViewChild, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { NgForm, FormBuilder, Validators, FormControl, FormGroup } from "@angular/forms";
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UserIdleService } from 'angular-user-idle';
import { DropdownSearchService } from '../../../dropdown-search.service';


declare const tinymce;



declare const $;

@Component({
    selector: 'app-addproduct',
    templateUrl: './addproduct.component.html',
    styleUrls: ['./addproduct.component.css']
})
export class AddproductComponent implements OnInit, OnDestroy {
    @ViewChild('myInput', { static: false }) public myInputVariable: ElementRef;
    @ViewChild('myInputlogo', { static: false }) public myInputVariablelogo: ElementRef;
    @ViewChild('myIn11', { static: false }) public myIn11: ElementRef;
    @ViewChild('image3', { static: false }) public image3: ElementRef;
    @ViewChild('ima4', { static: false }) public ima4: ElementRef;
    @ViewChild('image5', { static: false }) public image5: ElementRef;
    @ViewChild('productinformation', { static: false }) public productinformationForm: NgForm
    // @ViewChild('saletax', { static: false }) public saletaxForm: NgForm;
    public searchCountryControl: FormControl = new FormControl();
    public countryCtrl: FormControl = new FormControl();
    public searchStateSearchCtrl: FormControl = new FormControl();
    public stateCtrl: FormControl = new FormControl();
    public searchCityControl: FormControl = new FormControl();
    public cityCtrl: FormControl = new FormControl();
    companyName = sessionStorage.getItem("companyName");
    IdMerchant = +parseInt(sessionStorage.getItem("referMerchantId"));
    IdMasterMerchant = + sessionStorage.getItem("referMasterMerchantId");

    pictures: any;
    Response: any;
    imageresponse: any;
    names: any;
    posts: any;
    lstproduct: any;
    Name = "";
    Price = "";
    SKU = "";
    currency = "";
    Supplier = "";
    IdMainCategory: any;
    IdSubCategory: any;
    Cost = "";
    IsSalesTax;
    SalesTax: any;
    CreatedBy = "";
    SubCategoryName = "";
    MainCategoryName = "";
    MainImage = "";
    NutritionalFacts = "";
    AlternateImage1 = "";
    AlternateImage2 = "";
    AlternateImage3 = "";
    ProductDescription = "";
    allergen = "";
    ingredients = "";
    currencyType: any;
    countrylists: any;
    selctedcountry;
    states: any;
    selectedstate: any;
    IdCountry: any;
    IdState: any;
    CountryName: any;
    StateName: String = "";
    lst_AddSalesTax = [];
    lst_SalesTax: any[] = [];
    ItemsCount: any = 0;
    SalesTaxAmount: any
    lists
    IdProductSupplier
    upload_image
    AlertText
    addsale_modal
    subcat_length
    productsuplr
    isAdding = false;
    idM
    idS; selectedcity
    citys: any;
    IdCity: any;
    CityName: any;
    MainfileList: any;
    NutritionalFileList: any;
    Alt1fileList: any;
    Mainfile: any;
    Alt2fileList: any;
    Alt3fileList: any;
    section = "";
    name: string;
    userdata;
    bit: any = false;
    productForm: FormGroup;
    blob: Blob;
    MainImageName: any;
    NutritionalFactsName: any;
    Certificatelogoo = "";
    CertificatelogooName: any;
    AlternateImage1Name: any;
    AlternateImage2Name: any;
    AlternateImage3Name: any;
    filterData: any;
    limitChecked;
    IsSample = false
    Brandlist: any;
    IdBrand: any;
    idB;
    IsBrandVisible: boolean = false; //$$ BUG had to add since front end looks for this, but no one ever updates this. Please fix.

    LookupFeature: any;
    IsSamplingActive: boolean = false;
    IsBrandActive: boolean = false;
    ShortDescription;


    constructor(
        private router: Router,
        private http: HttpClient,
        public dialog: MatDialog,
        private globalservice: GlobalService,
        public apiList: ApilistService,
        public zone: NgZone,
        public userIdle: UserIdleService,
        private formBuilder: FormBuilder,
        public dropdownService: DropdownSearchService,
    ) { }

    ngOnInit(): any {
        var that = this
        this.userIdle.startWatching();

        tinymce.init({
            selector: '#mytextarea1',
            resize: true,
            width: '100%',
            min_height: 300,
            plugins: 'lists advlist code',
            toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | code',
            setup: (ed) => {
                ed.on('keyup', function (e) {
                    that.userIdle.resetTimer();
                });
            }
        });

        tinymce.init({
            selector: '#mytextareaallergen',
            resize: true,
            width: '100%',
            min_height: 300,
            plugins: 'lists advlist code',
            toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | code',
            setup: (ed) => {
                ed.on('keyup', function (e) {
                    that.userIdle.resetTimer();
                });
            }
        });

        tinymce.init({
            selector: '#mytextareaIngredients',
            resize: true,
            width: '100%',
            min_height: 300,
            plugins: 'lists advlist code',
            toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | code',
            setup: (ed) => {
                ed.on('keyup', function (e) {
                    that.userIdle.resetTimer();
                });
            }
        });

        this.HidePopups();
        this.Featuredetail();
        this.categorylist();
        this.globalservice.setDiscard_check();
        this.supplierlist();
        this.brand();

        this.productForm = this.formBuilder.group({
            Country: [this.IdCountry, [Validators.required]],
            StateName: ['', [Validators.required]],
            SalesTaxAmount: ['', [Validators.required]],
            CityName: ['', [Validators.required]],
            IdState: ['', [Validators.required]],
            IdCity: ['', [Validators.required]],
            limitChecked: ['']
        })
        this.HideImage()
    }

    Featuredetail() {

        this.http.post<any>(this.apiList.GetMerchantFeatures, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
            .timeout(30000)
            .retry(2)
            .take(1)
            .map(response => {
                if (!response) {
                    throw { Response: 0, ErrorMessage: "Empty or null response" };
                }
                if (response.Response !== 1) {
                    throw response;
                }
                return response;
            })
            .subscribe(response => {

                this.LookupFeature = response.Feature.reduce((acc, cv) => {
                    acc[cv.FeatureType] = cv.FeatureValue;
                    return acc;
                }, {});
                if (this.LookupFeature) {
                    this.IsBrandActive = this.LookupFeature.IsBrandActive;
                    this.IsSamplingActive = this.LookupFeature.IsSamplingActive;
                }

            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                }
            }).add(() => {
            });
    }
    HidePopups() {
        $('#myModal').modal('hide')
        $('#delete').modal('hide')
        $("#alert_div").hide();
        $('#uploadSuccess').modal('hide')
        $('#dots_spinner').show();
    }

    HideImage() {
        $('#myImg').hide();
        $('#main_img_close').hide();
        $('#myImglogo').hide();
        $('#main_img_closelogo').hide();

        $('#myImg1').hide();
        $('#main_img_close1').hide();

        $('#myImg2').hide();
        $('#main_img_close2').hide();

        $('#myImg3').hide();
        $('#main_img_close3').hide();

        $('#myImg4').hide();
        $('#main_img_close4').hide();
    }

    categorylist() {
        this.http.post<any>(this.apiList.CategoryListUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            })
            .subscribe((response) => {
                if (response.Response == 1) {
                    this.posts = response;
                    this.posts = this.posts.lst_mc;
                }
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
            });
    }
    //...................CLOSE.............................//
    remove_mainimage() {
        this.MainImage = '';
        this.myInputVariable.nativeElement.value = "";
        this.upload_image = '';
        $('#myImg').hide();
        $('#main_img_close').hide();
        $('#upload-btn-wrapper').show();
    }

    remove_logo() {
        this.Certificatelogoo = '';
        this.myInputVariablelogo.nativeElement.value = "";

        $('#myImglogo').hide();
        $('#main_img_closelogo').hide();
        $('#upload-btn-wrapperlogo').show();
    }

    remove_Nutritional() {
        this.NutritionalFacts = ''
        this.myIn11.nativeElement.value = "";
        $('#myImg1').hide();
        $('#main_img_close1').hide();
        $('#upload-btn-wrapper1').show();
    }
    remove_alternate() {
        this.AlternateImage1 = '';
        this.image3.nativeElement.value = "";
        $('#myImg2').hide();
        $('#main_img_close2').hide();
        $('#upload-btn-wrapper2').show();
    }
    remove_alt_second() {
        this.AlternateImage2 = '';
        this.ima4.nativeElement.value = "";
        $('#myImg3').hide();
        $('#main_img_close3').hide();
        $('#upload-btn-wrapper3').show();
    }
    remove_alt_third() {
        this.AlternateImage3 = '';
        this.image5.nativeElement.value = "";
        $('#myImg4').hide();
        $('#main_img_close4').hide();
        $('#upload-btn-wrapper4').show();
    }
    //...................CLOSE.............................//
    canDeactivate(): any {
        if (!this.isAdding && this.productinformationForm.dirty) {
            if (this.bit == false && this.globalservice.GetDiscard_Check()) {
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });

                return dialogRef.afterClosed().subscribe(result => {
                    // console.log(result)
                    // console.log( result.response)
                    if (result && (result.response == "ok")) {
                        this.dialog.closeAll();
                        this.bit = true;
                        this.router.navigate([this.companyName + '/dashboard/product']);
                    }
                });
            }
        } else if (this.productinformationForm.dirty) {
            if (this.bit == false) {
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });

                return dialogRef.afterClosed().subscribe(result => {
                    if (result && (result.response == "ok")) {
                        this.dialog.closeAll();
                        this.bit = true;
                        this.router.navigate([this.companyName + '/dashboard/product']);
                    }
                });
            }
        }
        return true;
    }

    FlashError(message: string) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(() => {
            $('#alert_div').hide();
        }, 3000);
    }



    onChangemain(id: string) {
        if (id != 'None') {
            $('#dots_spinner').show();
            this.IdMainCategory = id;
            this.http.post<any>(this.apiList.SubCategoryListUrl, { IdMainCategory: this.IdMainCategory, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
                .take(1)
                .timeout(25000)
                .map(result => {
                    if (result.Response !== 1) throw result;
                    return result;
                }).subscribe((response) => {
                    $('#dots_spinner').hide();
                    if (response.Response == 1) {
                        this.names = response;
                        // console.log(this.names.length);
                        this.names = this.names.lst_mc
                        this.subcat_length = this.names.length;
                    }
                }, (err: any) => {
                    const Error = this.globalservice.ProcessError(err);
                    if (Error.Response === 35) {
                        if (Error.ErrorMessage) {
                            this.FlashError(Error.ErrorMessage);
                            this.globalservice.Logout('Yes');
                        }
                        this.globalservice.Logout('No');
                        return;
                    } else {
                        this.FlashError(Error.ErrorMessage);
                        return;
                    }
                }).add(() => {
                    $('#dots_spinner').hide();
                });
        }
        else {
            this.subcat_length = 0;
            this.IdMainCategory = null;
            this.IdSubCategory = null
            this.idS = '';
        }
    }

    onChangesub(id1: string) {
        if (id1 != 'None') {
            this.IdSubCategory = id1;
        } else {
            this.IdSubCategory = null;
        }
    }

    supplierlist() {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.SupplierListUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            })
            .subscribe((response) => {
                $('#dots_spinner').hide();
                if (response.Response == 1) {
                    this.lists = response;
                    this.lists = this.lists.lst_ProductSupplier
                }
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
                $('#dots_spinner').hide();
            });
    }

    saleTax() {
        this.productForm.patchValue({
            limitChecked: 'true'
        });
        this.limitChecked = 'true';
        this.productForm.patchValue({
            Country: [''],
            StateName: [''],
            SalesTaxAmount: [''],
            CityName: [''],
            IdState: [''],
            IdCity: [''],
        })
        $('#saleTax').modal('show');
        this.AccordingToLocation();
    }

    AccordingToLocation() {
        let data = {
            IdMerchant: this.IdMerchant
        }
        this.http.post<any>(this.apiList.CountryStateCityByMerchantId, data, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            })
            .subscribe((response: any) => {
                console.log(response);
                if (response.Response == 1) {
                    this.filterData = response.Country
                    console.log(response.Country)
                    this.countrylists = response.Country;
                    this.dropdownService.countryObject.next(this.countrylists);
                    this.dropdownService.CountryArrayObj.next(this.countrylists.slice());
                    this.searchCountryControl = this.dropdownService.searchCountryControl;
                    this.searchCountryControl.valueChanges
                        .subscribe(() => {
                            this.dropdownService.countryFilter();
                        });
                }
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
            });
    }

    admindetails() {
        $('#dots_spinner').show();
        var data = {
            IdMerchant: this.IdMasterMerchant == 0 ? this.IdMerchant : this.IdMasterMerchant
        }
        this.http.post<any>(this.apiList.MerchantDetailUrl, data, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            })
            .subscribe((response) => {
                this.userdata = response;
                if (this.userdata.Response === 1) {
                    console.log(this.userdata.obj_MerchantRecord);
                    this.IdCountry = '' + this.userdata.obj_MerchantRecord.IdCountry;
                    this.IdState = '' + this.userdata.obj_MerchantRecord.IdState;
                    this.IdCity = '' + this.userdata.obj_MerchantRecord.IdCity;
                    this.StateName = this.userdata.obj_MerchantRecord.State;
                    this.CityName = this.userdata.obj_MerchantRecord.City;
                    this.getcountry();
                    this.getstates();
                    this.getcity();
                    this.productForm.patchValue({
                        Country: this.IdCountry,
                        IdState: this.IdState,
                        IdCity: this.IdCity,
                        StateName: this.userdata.obj_MerchantRecord.State,
                        CityName: this.userdata.obj_MerchantRecord.City
                    });
                    console.log(this.productForm.value)
                }
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
                $('#dots_spinner').hide();
            });
    }

    onLimitChanged(event) {
        this.limitChecked = event.checked;
        if (event.checked == false) {
            this.admindetails()
        } else {
            this.productForm.patchValue({
                Country: [''],
                StateName: [''],
                SalesTaxAmount: [''],
                CityName: [''],
                IdState: [''],
                IdCity: [''],
            })
            this.AccordingToLocation()
        }
    }

    onChangecurrency(value) {
        this.currencyType = value;
    }

    onChangesupplier(id) {
        this.IdProductSupplier = ((id == 'None') ? '' : this.IdProductSupplier);
    }

    stateName(b) {
        this.productForm.patchValue({
            StateName: b
        })
    }

    onChangeCountry(a) {
        this.IdCountry = a;
        if (this.productForm.value.limitChecked == false) {
            this.getstates();
        } else {
            this.filterData.forEach(element => {
                if (element.IdCountry == this.IdCountry)
                    this.states = element.States
                this.dropdownService.stateObject.next(this.states);
                this.dropdownService.StateArrayObj.next(this.states);
                this.searchStateSearchCtrl = this.dropdownService.searchStateControl;
                this.searchStateSearchCtrl.valueChanges
                    .subscribe(() => {
                        this.dropdownService.stateFilter();
                    });
            });
        }
    }

    getcountry() {
        $('#dots_spinner').show();
        this.globalservice.getCountry().then((res: any) => {
            $('#dots_spinner').hide();
            this.countrylists = res;
            this.dropdownService.countryObject.next(this.countrylists);
            this.dropdownService.CountryArrayObj.next(this.countrylists.slice());
            this.searchCountryControl = this.dropdownService.searchCountryControl;
            this.searchCountryControl.valueChanges
                .subscribe(() => {
                    this.dropdownService.countryFilter();
                });
        });
    }

    getstates() {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.StateUrl, { IdCountry: this.IdCountry }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            })
            .subscribe((response) => {
                $('#dots_spinner').hide();
                this.states = response.lst_State;
                this.dropdownService.stateObject.next(this.states);
                this.dropdownService.StateArrayObj.next(this.states.slice());
                this.searchStateSearchCtrl = this.dropdownService.searchStateControl;
                this.searchStateSearchCtrl.valueChanges
                    .subscribe(() => {
                        this.dropdownService.stateFilter();
                    });
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
                $('#dots_spinner').hide();
            });
    }

    onChangeState(b) {
        this.IdState = b;
        if (this.productForm.value.limitChecked == false) {
            this.getcity();
        } else {
            this.states.forEach(element => {
                if (element.IdState == this.IdState)
                    this.citys = element.cities;
                console.log(this.citys)
                console.log(this.citys)
                this.dropdownService.cityObject.next(this.citys);
                this.dropdownService.CityArrayObj.next(this.citys);
                this.searchCityControl = this.dropdownService.searchCityControl;
                this.searchCityControl.valueChanges
                    .subscribe(() => {
                        this.dropdownService.cityFilter();
                    });
            });
        }
    }

    getcity() {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.CityUrl, { IdState: this.IdState }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            }).subscribe((response) => {
                $('#dots_spinner').hide();
                this.citys = response.lst_City;
                this.dropdownService.cityObject.next(this.citys);
                this.dropdownService.CityArrayObj.next(this.citys.slice());
                console.log(this.dropdownService.CityArrayObj)
                this.searchCityControl = this.dropdownService.searchCityControl;
                this.searchCityControl.valueChanges
                    .subscribe(() => {
                        this.dropdownService.cityFilter();
                    });
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                }
                else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
                $('#dots_spinner').hide();
            });
    }

    onChangeCity(d) {
        if (d != 'None') {
            this.IdCity = d;

        } else {
            this.IdCity = '';
            this.productForm.patchValue({
                CityName: ''
            })
        }
    }


    getCityName(c) {
        if (c == '') {
            this.productForm.patchValue({
                CityName: ''
            })
        } else {
            this.productForm.patchValue({
                CityName: c
            })
        }

    }

    goback() {
        this.router.navigate([this.companyName + '/dashboard/product']);
    }

    Addsaletax() {
        if (this.lst_SalesTax != null) {
            let a = this.productForm.value.StateName;
            let b = this.productForm.value.CityName;
            var IsExist = this.lst_SalesTax.some(function (o) { return o["StateName"] === a && o["CityName"] === b; });
            if (IsExist == true) {
                this.FlashError("Already Exist");
            }
            else {
                this.addsale_modal = 1;
                this.lst_SalesTax.push({
                    IdState: this.IdState,
                    StateName: this.productForm.value.StateName,
                    SalesTaxAmount: this.productForm.value.SalesTaxAmount,
                    IdCity: this.IdCity,
                    CityName: this.productForm.value.CityName
                });
                this.ItemsCount = this.lst_SalesTax.length;
                this.SalesTaxAmount = '';
                this.selectedcity = '';
                this.IdCity = '';
                this.IdState = ''

            }
        }
        else {
            this.addsale_modal = 1;
            this.lst_SalesTax.push({
                IdState: this.IdState,
                StateName: this.productForm.value.StateName,
                SalesTaxAmount: this.productForm.value.SalesTaxAmount,
                IdCity: this.IdCity,
                CityName: this.productForm.value.CityName
            });
            this.ItemsCount = this.lst_SalesTax.length;
            this.SalesTaxAmount = '';
            this.IdCity = '';
            this.IdState = ''
            this.selectedcity = '';
        }
    }

    delete(name: string) {
        this.name = name;
        $('#delete').modal('show')
    }

    remove() {
        this.ItemsCount = this.lst_SalesTax.length;
        for (var i = 0; i < this.ItemsCount; i++) {
            if (this.lst_SalesTax[i]["CityName"] == this.name) {
                this.lst_SalesTax.splice(i, 1);
                // this.ItemsCount = this.ItemsCount - 1;
                this.ItemsCount--
            }
        }
    }


    submit_click() {
        this.isAdding = true;
        if(this.IdBrand=="None"){
            this.IdBrand=null;
        }
        console.log("salesTax is" + this.IsSalesTax);
        let sampleval = this.IsSample == false ? 0 : 1
        if (this.IsSalesTax == false) {
            this.addsale_modal = '';
        }
        if (this.IsSalesTax == true) {
            console.log("inside sale tax");
            console.log("ProductDescription" + this.ProductDescription);
            this.ingredients = tinymce.get('mytextareaIngredients').getContent({ format: 'raw' });
            this.ProductDescription = tinymce.get('mytextarea1').getContent({ format: 'raw' });
            this.allergen = tinymce.get('mytextareaallergen').getContent({ format: 'raw' });
            if (tinymce.get('mytextarea1').getContent({ format: 'text' }).trim()) {
                // if (tinymce.get('mytextareaallergen').getContent({ format: 'text' }).trim()) {

                    // if (tinymce.get('mytextareaIngredients').getContent({ format: 'text' }).trim()) {



                        if (this.addsale_modal == 1) {
                            if (this.upload_image == 1) {

                                $('#dots_spinner').show();
                                this.http.post<any>(this.apiList.AddProductUrl, { Name: this.Name, Price: this.Price, SKU: this.SKU, Supplier: this.productsuplr, ProductDescription: this.ProductDescription, IdMainCategory: this.IdMainCategory, IdSubCategory: this.IdSubCategory, CurrencyType: this.currencyType, IsSalesTax: this.IsSalesTax, CreatedBy: this.IdMerchant, MainImage: this.MainImageName, NutritionalFacts: this.NutritionalFactsName, AlternateImage1: this.AlternateImage1Name, AlternateImage2: this.AlternateImage2Name, AlternateImage3: this.AlternateImage3Name, lst_AddSalesTax: this.lst_SalesTax, IsSample: sampleval, IdBrand: this.IdBrand, Ingredients: this.ingredients, AllergenInformation: this.allergen, CertificationLogo: this.CertificatelogooName,ShortDescription:this.ShortDescription }, this.globalservice.Headers())
                                    .take(1)
                                    .timeout(25000)
                                    .map(result => {
                                        if (result.Response !== 1) throw result;
                                        return result;
                                    }).subscribe((response) => {
                                        $('#dots_spinner').hide();
                                        this.lstproduct = response;
                                        if (this.lstproduct.Response == 1) {
                                            this.productinformationForm.reset();
                                            this.router.navigate([this.companyName + '/dashboard/product']);
                                        }
                                    }, (err: any) => {
                                        const Error = this.globalservice.ProcessError(err);
                                        if (Error.Response === 35) {
                                            if (Error.ErrorMessage) {
                                                this.FlashError(Error.ErrorMessage);
                                                this.globalservice.Logout('Yes');
                                            }
                                            this.globalservice.Logout('No');
                                            return;
                                        }
                                        else {
                                            $('#dots_spinner').hide();
                                            this.FlashError(Error.ErrorMessage);
                                            return;
                                        }
                                    }).add(() => {
                                        $('#dots_spinner').hide();
                                    });
                            } else {
                                this.FlashError("Firstly upload your Main image");
                            }
                        } else {
                            this.FlashError("Fill Sale tax form")
                        }
                    // }
                    // else {
                    //     this.FlashError("Ingredients is Mandatory !");
                    // }
                // }
                // else {
                //     this.FlashError("Allergen is Mandatory !");
                // }
            }
            else {
                this.FlashError("Product Description is Mandatory !");
            }
        } else {
            console.log("else tinymce.get('mytextarea1').getContent({format: 'text'})" + tinymce.get('mytextarea1').getContent({ format: 'text' }));
            this.ingredients = tinymce.get('mytextareaIngredients').getContent({ format: 'raw' });
            this.ProductDescription = tinymce.get('mytextarea1').getContent({ format: 'raw' });
            this.allergen = tinymce.get('mytextareaallergen').getContent({ format: 'raw' });
            if (tinymce.get('mytextarea1').getContent({ format: 'text' }).trim()) {
                // if (tinymce.get('mytextareaallergen').getContent({ format: 'text' }).trim()) {

                    // if (tinymce.get('mytextareaIngredients').getContent({ format: 'text' }).trim()) {
                        if (this.upload_image == 1) {
                            $('#dots_spinner').show();
                            //console.log("Name:"+ this.Name, "Price:"+ this.Price, "SKU:"+ this.SKU, "SupplierIds:"+ "1,", "Supplier:"+ this.IdProductSupplier, "ProductDescription:"+ this.ProductDescription, "IdMainCategory:"+ this.IdMainCategory, "IdSubCategory:"+ this.IdSubCategory, "CurrencyType:"+ this.currencyType, "IsSalesTax:"+ this.IsSalesTax, "CreatedBy:"+ this.IdMerchant, "MainImage:"+ this.MainImage, "NutritionalFacts:"+ this.NutritionalFacts, "AlternateImage1:"+ this.AlternateImage1, "AlternateImage2:"+ this.AlternateImage2, "AlternateImage3:"+ this.AlternateImage3, "lst_AddSalesTax:"+ this.lst_SalesTax, IdBrand:this.IdBrand)
                            this.http.post<any>(this.apiList.AddProductUrl, { Name: this.Name, Price: this.Price, SKU: this.SKU, Supplier: this.productsuplr,  ProductDescription: this.ProductDescription, IdMainCategory: this.IdMainCategory, IdSubCategory: this.IdSubCategory, CurrencyType: this.currencyType, IsSalesTax: this.IsSalesTax, CreatedBy: this.IdMerchant, MainImage: this.MainImageName, NutritionalFacts: this.NutritionalFactsName, AlternateImage1: this.AlternateImage1Name, AlternateImage2: this.AlternateImage2Name, AlternateImage3: this.AlternateImage3Name, lst_AddSalesTax: this.lst_SalesTax, IsSample: sampleval, IdBrand: this.IdBrand, Ingredients: this.ingredients, AllergenInformation: this.allergen, CertificationLogo: this.CertificatelogooName,ShortDescription:this.ShortDescription }, this.globalservice.Headers())
                                .take(1)
                                .timeout(25000)
                                .map(result => {
                                    if (result.Response !== 1) throw result;
                                    return result;
                                }).subscribe((response) => {
                                    $('#dots_spinner').hide();
                                    this.lstproduct = response;
                                    if (this.lstproduct.Response == 1) {
                                        this.productinformationForm.reset();
                                        this.router.navigate([this.companyName + '/dashboard/product']);
                                    }
                                }, (err: any) => {
                                    const Error = this.globalservice.ProcessError(err);
                                    if (Error.Response === 35) {
                                        if (Error.ErrorMessage) {
                                            this.FlashError(Error.ErrorMessage);
                                            this.globalservice.Logout('Yes');
                                        }
                                        this.globalservice.Logout('No');
                                        return;
                                    }
                                    else {
                                        $('#dots_spinner').hide();
                                        this.FlashError(Error.ErrorMessage);
                                        return;
                                    }
                                }
                                ).add(() => {
                                    $('#dots_spinner').hide();
                                });
                        } else {
                            this.FlashError("Firstly upload your Main image");
                        }
                    // }
                    // else {
                    //     this.FlashError("Ingredients is Mandatory !");
                    // }
                // }
                // else {
                //     this.FlashError("Allergen is Mandatory !");
                // }
            } else {
                this.FlashError("Product Description is Mandatory !")
            }
        }
    }

    close_saletax() {
        $('#saleTax').modal('hide');
        if (this.ItemsCount == 0) {
            this.IsSalesTax = false;
        }
    }

    ngOnDestroy() {
        tinymce.remove('#mytextarea1');
        tinymce.remove('#mytextareaallergen');
        tinymce.remove('#mytextareaIngredients');
        this.dialog.ngOnDestroy();
    }

    MainImageUpload(file: File, categoryType) {
        if (categoryType == 'MainImage') {
            this.upload_image = 1
        }
        if (this.upload_image == 1) {
            $('#dots_spinner').show();
            this.section = categoryType;
            this.blob = new Blob([file[0]], { type: file[0].type });
            console.log(file)
            this.globalservice.CheckTransparency(file).then((res: any) => {
                $('#dots_spinner').hide();
                console.log(res)
                if (res.Response == 1) {
                    this.uploadFile(categoryType)
                }
            }, error => {
                $('#dots_spinner').hide();
                console.log(error)
                const Error = this.globalservice.ProcessError(error);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else if (Error.Response == 4) {
                    $('#myModal').modal('show');
                }
                else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            })
        } else {
            this.FlashError("Firstly upload your Main image");
        }
    }

    uploadFile(categoryType) {
        $('#dots_spinner').show();
        console.log(categoryType)
        this.globalservice.uploadFiles(this.blob).then((res: any) => {
            if (res.Response == 1) {
                $('#dots_spinner').hide();
                switch (categoryType) {
                    case 'MainImage':
                        this.MainImage = res.PreviewUrl;
                        this.MainImageName = res.FileName
                        this.upload_image = 1;
                        $('#myImg').show(2000, function () {
                            $('#main_img_close').show();
                            $('#uploadSuccess').modal('show');
                        });
                        $('#upload-btn-wrapper').hide();
                        console.log(this.MainImage)
                        break;
                    case 'CertificateLogo':
                        this.Certificatelogoo = res.PreviewUrl;
                        this.CertificatelogooName = res.FileName


                        $('#myImglogo').show(2000, function () {
                            $('#main_img_closelogo').show();
                            $('#uploadSuccess').modal('show');
                        });
                        $('#upload-btn-wrapperlogo').hide();
                        console.log("test logo" + this.Certificatelogoo)
                        break;

                    case 'NutritionalFacts':
                        this.NutritionalFacts = res.PreviewUrl;
                        this.NutritionalFactsName = res.FileName
                        $('#myImg1').show(2000, function () {
                            $('#main_img_close1').show();
                            $('#uploadSuccess').modal('show');
                        });
                        $('#upload-btn-wrapper1').hide();
                        break;
                    case 'AlternateImage1':
                        this.AlternateImage1 = res.PreviewUrl;
                        this.AlternateImage1Name = res.FileName
                        $('#myImg2').show(2000, function () {
                            $('#main_img_close2').show();
                            $('#uploadSuccess').modal('show');;
                        });
                        $('#upload-btn-wrapper2').hide();
                        break;
                    case 'AlternateImage2':
                        this.AlternateImage2 = res.PreviewUrl;
                        this.AlternateImage2Name = res.FileName
                        $('#myImg3').show(2000, function () {
                            $('#main_img_close3').show();
                            $('#uploadSuccess').modal('show');
                        });
                        $('#upload-btn-wrapper3').hide();
                        break;
                    case 'AlternateImage3':
                        this.AlternateImage3 = res.PreviewUrl;
                        this.AlternateImage3Name = res.FileName
                        $('#myImg4').show(2000, function () { $('#main_img_close4').show(); });
                        $('#upload-btn-wrapper4').hide();
                        $('#uploadSuccess').modal('show');
                        break;
                }
            }
        }, error => {
            const Error = this.globalservice.ProcessError(error);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    this.FlashError(Error.ErrorMessage);
                    this.globalservice.Logout('Yes');
                }
                this.globalservice.Logout('No');
                return;
            }
            else {
                console.log(Error.ErrorMessage)
                this.FlashError(Error.ErrorMessage);
                return;
            }
        })
    }

    Yes() {
        switch (this.section) {
            case 'MainImage':
                this.uploadFile(this.section)
                break;
            case 'CertificateLogo':
                this.uploadFile(this.section)
                break;
            case 'NutritionalFacts':
                this.uploadFile(this.section)
                break;
            case 'AlternateImage1':
                this.uploadFile(this.section)
                break;
            case 'AlternateImage2':
                this.uploadFile(this.section)
                break;
            case 'AlternateImage3':
                this.uploadFile(this.section)
                break;
        }

    }

    onSampleChanges(value) {
        this.IsSample = value.checked;
        this.productinformationForm.control.markAsDirty();
    }

    brand() {
        this.http.post<any>(this.apiList.MerchantBrandResult, '', this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(response => {
                if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
                if (response.Response !== 1) { throw response; }
                return response;
            })
            .subscribe((response) => {
                if (response) {
                    console.log(response)
                    this.Brandlist = response.MerchantBrandsList;
                }
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {

            });
    }

    onChangebrand(value) {
        this.IdBrand = value
        //console.log(this.IdBrand)
    }
}
