import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
declare const $

@Component({
  selector: 'app-updatespecialpricing',
  templateUrl: './updatespecialpricing.component.html',
  styleUrls: ['./updatespecialpricing.component.css']
})
export class UpdatespecialpricingComponent implements OnInit {

  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  type
  SpecialPricingType
  StartDate
  AlertText
  selectedProduct1
  selectedProduct2
  selectedMachine
  DiscountAmount
  DiscountType
  StartTime
  EndTime
  postdata
  EndDate
  mlists = [];
  Mlists
  Glists = [];
  IdMachines: any = 0;
  Mlistsss = [];
  productlenght
  array
  IdProducts: any = 0;
  selectedMachineproduct
  SpecialPricingRule;
  Date; time;
  discount_show = 1;
  Dis_amount = false;
  Start_Date: any;
  End_Date: any;

  cacheProducts = [];
  discount_minValueError = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private http: HttpClient, private globalservice: GlobalService, public apiList: ApilistService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.type = params.id;
      //  this.clickSave()


    })
    this.Date = new Date();
    $("#alert_div").hide();
    //this.refreshlistproductGlobal();

    $('body').on('change', '#timepicker5', function () {
      $('#timepicker5').trigger('click');
    });

    $('body').on('change', '#timepicker6', function () {
      $('#timepicker6').trigger('click');
    });
    $('#timepicker5').timepicker();
    $('#timepicker6').timepicker();
    this.listofmachine();
    // this.Getdetail();


  }

  // clickSave() {
  //   if (this.type == 'Global') {
  //     $("#applyGlobel").show();
  //     $("#applyMachine").hide();
  //     $("#machineproduct").hide();
  //   }
  //   else if (this.type == 'machine') {
  //     $("#applyGlobel").hide();
  //     $("#applyMachine").show();
  //     $("#machineproduct").hide();
  //   }
  //   else if (this.type == 'machineproduct') {
  //     $("#applyGlobel").hide();
  //     $("#applyMachine").hide();
  //     $("#machineproduct").show();
  //   }
  // }

  startTime(time: string) {
    this.StartTime = time;
    //alert(time);
  }
  endTime(tim: string) {
    this.EndTime = tim;
    //alert(tim);
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDate = $('#GSDate').val();
    // alert(this.StartDate);
  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    this.EndDate = $('#GEDate').val();
    // alert(this.EndDate);
  }
  addEvent3(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDate = $('#MSDate').val();
    // alert(this.StartDate);
  }
  addEvent4(type: string, event: MatDatepickerInputEvent<Date>) {
    this.EndDate = $('#MEDate').val();
    // alert(this.EndDate);
  }
  addEvent5(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDate = $('#MSDate').val();
    //alert(this.StartDate);
  }
  addEvent6(type: string, event: MatDatepickerInputEvent<Date>) {
    this.EndDate = $('#MEDate').val();
    //alert(this.EndDate);
  }
  addEvent7(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDate = $('#MSPDate').val();
    // alert(this.EndDate);
  }
  addEvent8(type: string, event: MatDatepickerInputEvent<Date>) {
    this.StartDate = $('#MSPDate').val();
    // alert(this.EndDate);
  }
  addEvent9(type: string, event: MatDatepickerInputEvent<Date>) {
    this.EndDate = $('#MEPDate').val();
    //alert(this.EndDate);
  }
  addEvent10(type: string, event: MatDatepickerInputEvent<Date>) {
    this.EndDate = $('#MEPDate').val();
    //alert(this.EndDate);
  }

  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  timeconversion(Time) {
    var time = Time;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes + ":" + "00"
  }

  Getdetail() {
    let url2 = `${this.apiList.GetdetailUrl}`;
    this.http.post<any>(url2, {  SpecialPricingRule: this.type }, this.globalservice.Headers())
    .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      //console.log(response);
      this.array = [];
      var array2 = [];
      if (response.Response == 1) {
        $('#checkall').addClass("btn_active");
        $('#uncheckall').removeClass("btn_active");
        this.DiscountAmount = response.obj_SpecialPricing.DiscountAmount;
        this.SpecialPricingRule = response.obj_SpecialPricing.SpecialPricingRule;
        this.DiscountType = response.obj_SpecialPricing.DiscountType;
        // console.log( this.DiscountType);
        if (this.DiscountType == '%') {
          this.discount_show = 1
        } else {
          this.discount_show = 2
        }
        this.Start_Date = response.obj_SpecialPricing.StartDate.split('T');
        // console.log(this.Start_Date)
        var d = new Date(this.Start_Date);
        var curr_date = d.getDate();
        var curr_month = d.getMonth();
        curr_month++;
        var curr_year = d.getFullYear();
        this.StartDate = curr_month + "/" + curr_date + "/" + curr_year;
        // console.log(this.StartDate);
        //this.StartDate = this.StartDate[0];
        this.End_Date = response.obj_SpecialPricing.EndDate.split('T');
        var d = new Date(this.End_Date);
        var curr_date = d.getDate();
        var curr_month = d.getMonth();
        curr_month++;
        var curr_year = d.getFullYear();
        this.EndDate = curr_month + "/" + curr_date + "/" + curr_year;
        // console.log(this.StartDate)
        // this.EndDate = this.EndDate[0];
        $('#MSPDate').val(this.StartDate);
        $('#MEPDate').val(this.EndDate);
        this.StartTime = response.obj_SpecialPricing.StartTime;
        this.EndTime = response.obj_SpecialPricing.EndTime;
        $('#timepicker5').timepicker('setTime', response.obj_SpecialPricing.StartTime);
        $('#timepicker6').timepicker('setTime', response.obj_SpecialPricing.EndTime);
        // $('#timepicker5').val(this.StartTime);
        //   $('#timepicker6').val(this.EndTime );
        for (let i = 0; i < response.lst_Machines.length; i++) {
          this.array.push(response.lst_Machines[i].IdMachine);
        }
        this.selectedMachineproduct = this.array;
        this.IdMachines = this.selectedMachineproduct.toString();
        for (let i = 0; i < response.lst_Products.length; i++) {
          array2.push(response.lst_Products[i].IdProduct);
        }
        this.selectedProduct2 = array2;
        this.IdProducts = this.selectedProduct2.toString();
        this.refreshlistproductMachine()
      }    
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
     });
  }

  listofmachine() {
    // $('#dots_spinner').show();
    let url1 = `${this.apiList.GetPlaceMachinesForSpecialPrice}`;
    this.http.post<any>(url1, { IdMerchant: this.IdMerchant }, this.globalservice.Headers()).pipe(
      switchMap((response) => {      
      if (response.Response == 1) {
        this.mlists = response.lst_sp_GetPlacedMachinesWithLocation;
        let array = [];
        for (let i = 0; i < this.mlists.length; i++) {
          array.push(this.mlists[i].IdMachine);
        }
        this.IdMachines = array.toString();
        let url2 = `${this.apiList.ProductLstMachineUrl}`;
        return this.http.post<any>(url2, { IdMachines: this.IdMachines }, this.globalservice.Headers());
      }
    })).take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      // $('#dots_spinner').hide();
        if (response.Response == 1) {
          this.cacheProducts = response.lst_Product;
          this.Getdetail();
        }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       });
  }

  refreshlistproductGlobal() {
    // $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ProductLstGlobalUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      //  $('#dots_spinner').hide();
      if (response.Response == 1) {
        this.Glists = response;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        });
  }

  selectAll(id) {
      $('#checkall').addClass("btn_active");
      $('#uncheckall').removeClass("btn_active");
    if (id == 'machine') {
      this.array = [];
      for (let i = 0; i < this.mlists.length; i++) {
        this.array.push(this.mlists[i].IdMachine);
      }
      this.selectedMachineproduct = this.array;
      this.IdMachines = this.selectedMachineproduct.toString();
      //console.log(this.IdMachines);
      this.refreshlistproductMachine();
    } else if (id == 'multipleproduct') {
      this.array = [];
      for (let i = 0; i < this.Mlistsss.length; i++) {
        this.array.push(this.Mlistsss[i].IdProduct);
      }
      this.selectedProduct2 = this.array;
      this.IdProducts = this.selectedProduct2.toString();
    }
  }

  deselectAll(id) {
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').addClass("btn_active");
    if (id == 'machine') {
      this.selectedMachineproduct = "";
      this.array = [];
      this.IdMachines = "";
      this.refreshlistproductMachine();
    } else if (id == 'multipleproduct') {
      this.selectedProduct2 = "";
      this.array = [];
      this.IdProducts = "";
    }
  }

  onChangesingleMachine(id) {

    

    this.IdMachines = id.toString();
    // console.log(this.IdMachines);
    // console.log(this.IdMachines);
    this.refreshlistproductMachine();
  }



  refreshlistproductMachine() {
    if (this.IdMachines) {
      let machines = this.IdMachines.split(',');
      let products = [];
      machines.forEach(el => {
        for(let i=0; i<this.cacheProducts.length; i++) {
          if(el == this.cacheProducts[i].IdMachine) {
            products.push(this.cacheProducts[i]);
          }
        }
      });
      //remove duplicates from products array
      let newArr = [];
      products.forEach((item, index) => {
          if (newArr.findIndex(i => i.IdProduct == item.IdProduct) === -1) {
            newArr.push(item)
          }
      });
      this.Mlistsss = newArr;
      this.Mlists = newArr;
      this.productlenght = this.Mlistsss.length;
    } else {
      this.Mlists = [];
      this.selectedProduct2 = '';
    }
  }

  onChangeMachine(a) {
    $('#uncheckall').removeClass("btn_active");
    $('#checkall').removeClass("btn_active");
    a = a.map(function (e) { return JSON.stringify(e); });
    a = a.join(",");
    this.IdMachines = a;

  }

  Apply() {
    if(this.StartTime &&  this.EndTime){    // both are required
    this.time = this.Date.getHours() + ":" + this.Date.getMinutes() + ":00";
    var date = (this.Date.getMonth() + 1) + '/' + this.Date.getDate() + '/' + this.Date.getFullYear();
    this.SpecialPricingType = 'MachineProducts';
    if(parseFloat(this.DiscountAmount) <= 0) {
      this.discount_minValueError = true;
      return this.FlashError("Discount Amount should be more than 0");
    }
    else {
      this.discount_minValueError = false;
    }
    $('#dots_spinner').show();
    if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {  // start date is less the end date
      if (this.timeconversion(this.StartTime) == this.timeconversion("12:00 AM") && this.timeconversion(this.EndTime) == this.timeconversion("12:00 AM")) {  //start time == 12 and time == 12
        //console.log(this.CouponCode, this.DiscountType, this.CouponType, this.DiscountAmount, this.StartDate + " " + this.StartTime, this.EndDate + " " + this.EndTime, this.IdMachines, this.IdProducts);
       this.updateSpecialPrices();
      }
      else if (this.toTimestamp(this.StartDate) >= this.toTimestamp(date) && this.toTimestamp(this.EndDate) != this.toTimestamp(date)) {   // start date is greater than current date and end date not equal to current date
        if (this.timeconversion(this.StartTime) < this.timeconversion(this.EndTime)) {   // start time is less the end time
          this.updateSpecialPrices();
        }
        else if ((this.timeconversion(this.StartTime) >= this.timeconversion(this.EndTime))) {
          $('#dots_spinner').hide();
          this.FlashError("Start Time Should be less than End Time");
        } 
        // else {
        //   $('#dots_spinner').hide();
        //   this.FlashError("Something went wrong");
        // }
      }
      else if (this.toTimestamp(this.StartDate) == this.toTimestamp(date) && this.toTimestamp(this.EndDate) == this.toTimestamp(date)) {  // start date is equal to current date and end date is equal to current date
        if (this.timeconversion(this.StartTime) >= this.time  ) {   // start time is greater then current time
          if (this.timeconversion(this.StartTime) < this.timeconversion(this.EndTime)) {   // start  time is smaller than end time
            // console.log( this.CouponCode, this.DiscountType, this.CouponType, this.DiscountAmount, this.StartDate + " " + this.StartTime, this.EndDate + " " + this.EndTime, this.IdMachines, this.IdProducts);
           this.updateSpecialPrices();
          } else if ((this.timeconversion(this.StartTime) >= this.timeconversion(this.EndTime))) {
            $('#dots_spinner').hide();
            this.FlashError("Start Time Should be less than End Time");
          }
        } else {
          this.updateSpecialPrices();
          // $('#dots_spinner').hide();
          // this.FlashError("Start Time Should be greater than Current Time");
        }
      }
       else {
        this.updateSpecialPrices();
        // $('#dots_spinner').hide();
        // this.FlashError("Start date and End date is invalid. Start date and End date is smaller than Current date");

      }
    }
    else if (this.toTimestamp(this.StartDate) >= this.toTimestamp(this.EndDate)) {
      $('#dots_spinner').hide();
      this.FlashError("Start Date should be less than End Date");
    } else {
      $('#dots_spinner').hide();
      this.FlashError("Something went wrong");

    }
  }else{
      this.FlashError("Please complete time Information");
  }
  }

  updateSpecialPrices(){
    $('#dots_spinner').show();
    this.postdata = {
      "DiscountType": this.DiscountType,
      "SpecialPricingType": this.SpecialPricingType,
      "DiscountAmount": this.DiscountAmount,
      "CreatedBy": this.IdMerchant,
      "StartDate": this.StartDate,
      "EndDate": this.EndDate,
      "StartTime": this.StartTime,
      "EndTime": this.EndTime,
      "IdProducts": this.IdProducts,
      "IdMachines": this.IdMachines,
      "SpecialPricingRule": this.SpecialPricingRule,
      "CreatedDate": "9/28/2018",
    }
    let url2 = `${this.apiList.UpdateSPUrl}`;
    // var headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    this.http.post<any>(url2, this.postdata, this.globalservice.Headers()) .take(1)
.timeout(25000)
.map(result => {
  if (result.Response !== 1) throw result;
  return result;
})
.subscribe((response) => {
          if (response.Response == 1) {
            this.router.navigate([this.companyName + '/dashboard/Specialpricing'])
          }
        }, (err: any) => {
    const Error = this.globalservice.ProcessError(err);
    if (Error.Response === 35) {
      if (Error.ErrorMessage) {
        this.FlashError(Error.ErrorMessage);
        this.globalservice.Logout('Yes');
      }
      this.globalservice.Logout('No');
      return;
    } else if (Error.Response == 4) {
            var error_rule = Error.ErrorMessage.substr(1);
              this.FlashError("This special price name conflicting with " + error_rule + ". Please select another time duration");
          } else {
      this.FlashError(Error.ErrorMessage);
      return;
    }
  }).add(() => {
   $('#dots_spinner').hide(); });
  
  }


  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }


  onChangeProduct1(id1) {
    $('#uncheckall').removeClass("btn_active");
    $('#checkall').removeClass("btn_active");
    id1 = id1.map(function (e) { return JSON.stringify(e); });
    id1 = id1.join(",");
    this.IdProducts = id1
  }

  onChangeProduct2(id2) {
    $('#uncheckall').removeClass("btn_active");
    $('#checkall').removeClass("btn_active");
    id2 = id2.map(function (e) { return JSON.stringify(e); });
    id2 = id2.join(",");
    this.IdProducts = id2

  }

  discount_reset() {
    this.discount_minValueError = false;
  }

  type_discount(value) {
    //console.log(value);
    this.discount_show = value;
    this.Dis_amount = true;
    this.DiscountAmount = "";
    // console.log(value)
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/Specialpricing']);
  }
}


