import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApilistService {
  baseUrl = environment.baseUrl;

  /*Machine Section*/
  GetMachineList = this.baseUrl + "/Machine/GetMachineListByMerchantId_New";//done Admin
  ResetUrl = this.baseUrl + "/Common/MachineFileSyncOnPIByMerchant";///done Admin
  MachinesearchUrl = this.baseUrl + "/Machine/GetMachineListByMerchantIdBySearch";//done Admin
  UpdateMachineSettings = this.baseUrl + "/Machine/UpdateMachineSettings";//done Admin
  ForceFullyZeroURL = this.baseUrl + "/PlaceMachine/RemoveInventoryForcefully";//done


  getMachineSerial=this.baseUrl + "/Machine/GetMachineSlavesDetailById";


  /* MACHINE SETTING*/
  TimeUrl = this.baseUrl + "/Common/UpdateInactivityPopUpeOfMachine"///done Admin

  /*   SMTP  */
  saveSMTPDetailApi = this.baseUrl + '/SMTPEmail/SaveOrUpdateSMTPDetails';//done admin
  sendToMAilApi = this.baseUrl + '/SMTPEmail/SendTestEmail';//done admin
  getSMTPDetails = this.baseUrl + '/SMTPEmail/GetSMTPDetailByMerchantId'//done admin
  uploadSMTPApi = this.baseUrl + '/DocumentUpload/CheckValidZipFile' //done admin
  saveUploadSMTPApi = this.baseUrl + '/DocumentUpload/SubmitUplaodedFiles'//done admin
  getEmailTempalteApi = this.baseUrl + '/DocumentUpload/GetCurrentTemplate';//done admin

  /*   Login */
  SignInUrl = this.baseUrl + "/login/Login"; //common
  SignupUrl = this.baseUrl + "/login/RequestCredential" //common
  CheckUserNameUrl = this.baseUrl + "/Login/CheckUserNameExist";  //common
  ForgotPswUrl = this.baseUrl + "/Login/ForgotPasswordFun"; //common
  CheckEmailExistUrl = this.baseUrl + "/Login/CheckEmailIdExist"; //common
  ContactUsUrl = this.baseUrl + "/Common/ContactUs" //common
  CheckZipCodeExist=this.baseUrl+'/Login/CheckZipCodeExist'; //common

  /* Common */
  CountryListUrl = this.baseUrl + "/Common/GetCountriesList"; //Common
  StateUrl = this.baseUrl + "/Common/GetStatesListByCountryId"; //Common
  CityUrl = this.baseUrl + "/Common/GetCityListByStateId"; //Common
  GetCodeUrl = this.baseUrl + "/Common/GetCodeByCountryId";//not used
  MerchantDetailUrl = this.baseUrl + "/Merchant/GetMerchantDetailsById";//done Common
  LocationListUrl = this.baseUrl + "/Location/GetLocationListByMerchantId";//done Admin
  GetLocationsWithXactUrl = this.baseUrl + "/Location/GetLocationsWithXact";//done Admin
  Impersonate = this.baseUrl + "/Merchant/Impersonate"; //common
  NoOperation = this.baseUrl + "/Login/NoOperation"; //common
  BackToSuperAdmin=this.baseUrl + "/Merchant/BackToSuperAdmin"; //admin
  UploadImage=this.baseUrl + '/DocumentUpload/UploadImage'; //admin
  GetTenantSkin=this.baseUrl +"/Common/GetTenantSkin";//common css before login
  GetMerchantSkin=this.baseUrl+"/Common/GetMerchantSkin";//common css after login
  GetAllSkin=this.baseUrl+"/Common/GetAllSkin";//common css to get all skins
  
  /* Client */
  AddClientApi = this.baseUrl + "/Client/AddClient"//done Admin
  ClientDetailsUrl = this.baseUrl + "/Client/ClientDetailById";//done Admin
  UpdateClientUrl = this.baseUrl + "/Client/UpdateClient"//done Admin
  ClientListApi = this.baseUrl + "/Client/ClientListByMerchantId";//done Admin
  DeleteClientApi = this.baseUrl + "/Client/DeleteClient";//done Admin

  /*  Location */
  AddLocationUrl = this.baseUrl + "/Location/AddLocation";//done Admin
  UpdateLocationUrl = this.baseUrl + "/Location/UpdateLocation";//done Admin
  DeleteLocationUrl = this.baseUrl + "/Location/DeleteLocation";//done Admin
  AddZipCodeUrl = this.baseUrl + "/Location/AddZipCode"
  CountryStateCityByMerchantId = this.baseUrl + "/Merchant/CountryStateCityByMerchantId"


  /* Alerts */
  
  GetAlertsTypes=this.baseUrl+"/Users/GetAlertsTypes";
  AddAlerts=this.baseUrl+"/Users/AddAlerts";
  EditAlerts=this.baseUrl+"/Users/EditAlerts";
  DeleteAlerts=this.baseUrl+"/Users/DeleteAlerts";
  GetuUserSubscriptions=this.baseUrl+"/Users/GetuUserSubscriptions";
  GetuUserSubscriptionsById=this.baseUrl+"/Users/GetuUserSubscriptionsById";
  SubscribeAlerts=this.baseUrl+"/Users/SubscribeAlerts";
  GetEvents=this.baseUrl+"/Users/GetEvents";
  GetChildMerchants=this.baseUrl+"/Users/GetChildMerchants";
  GetSubscribedAlertList=this.baseUrl+"/Users/GetSubscribedAlertList";
  GetSingleSubscribedAlertList=this.baseUrl+"/Users/GetSingleSubscribedAlertList";
  

  /* Smptp */

  FetchSMTPTypes=this.baseUrl+"/SMTPEmail/FetchSMTPTypes";
 
  /* Route */
  RouteDetailUrl = this.baseUrl + "/Machine/GetRouteDetailById";//done Admin
  UpdateRouteUrl = this.baseUrl + "/Machine/UpdateRoute";//done Admin
  LocationSearchUrl = this.baseUrl + '/Location/GetLocationSearchListByMerchantId';//done Admin

  /* Report */
  /* ---sale Report --- */
  MachinelstUrl = this.baseUrl + "/Report/MachineListByLocation";//done Admin
  XactMachineList = this.baseUrl + "/Report/MachineListForXactLocations";//done Admin
  TimeZoneListFUrl = this.baseUrl + "/Report/TimeZoneList";//done Admin
  ExportReportUrl = this.baseUrl + '/Report/ExportTransactionsByDateAndIdMachineLocation'//done Admin
  SaleReportUrl = this.baseUrl + "/Report/TransactionsByDateAndIdMachineLocation"//done Admin
  PrintsalesUrl = this.baseUrl + "/Report/PrintTransactionsByDateAndIdMachineLocation"//done Admin
  sendEmailreceipt = this.baseUrl + "/Report/ResendTransactionMail"//done Admin
  GetTermsAndConditionByRevNumber=this.baseUrl+'/Report/GetTermsAndConditionByRevNumber'; //Admin
  PrintGetTermsAndConditionByRevNumber=this.baseUrl+'/Report/PrintGetTermsAndConditionByRevNumber'; //Admin

  /* ---Exception Report --- */
  ExceptionListUrl = this.baseUrl + "/Report/GetExceptionList";//done Admin
  getEventTypeList = this.baseUrl + '/SMTPEmail/GetEventTypeList'; // Admin
  getEventListBySearch = this.baseUrl + '/Report/GetEventListBySearch';//done Admin
  PrintEventList = this.baseUrl + '/Report/PrintEventList';//done Admin
  ExportEventList = this.baseUrl + '/Report/ExportEventList';//done Admin

  /* Product */
  MediaUpload = this.baseUrl + "/DocumentUpload/MediaUpload"; //Admin
  AddProductUrl = this.baseUrl + "/Product/AddProduct";//done Admin 
  SupplierListUrl = this.baseUrl + "/ProductSupplier/GetListOfSupplierByMerchantId";//done Admin
  CheckTransparency = this.baseUrl + "/DocumentUpload/CheckTransparency"; //Admin
  UpdateProductUrl = this.baseUrl + "/Product/UpdateProduct";//done Admin
  ProductDetailUrl = this.baseUrl + "/Product/GetProductDetailById";//done Admin
  DeleteProductUrl = this.baseUrl + "/Product/DeleteProduct";//done Admin
  ProductLstUrl = this.baseUrl + "/Product/GetProductListByMerchantId";//done Admin
  ProductDetailsUrl = this.baseUrl + "/Product/GetProductDetailById";//done Admin
  ProductSalesTaxDetailsUrl = this.baseUrl + "/Product/GetSalesTaxListByProduct";//done Admin

  /* Theme */

  ThemeListUrl = this.baseUrl + "/Common/ListOfThemeByMerchantId";//done Admin
  MachineListUrl = this.baseUrl + '/Machine/GetMachineListByMerchantId';//done Admin
  UpdateThemeUrl = this.baseUrl + '/Common/UpdateThemeOfMachine';//done Admin
  ThemeDetailUrl = this.baseUrl + '/Common/GetThemeDetailById';//done Admin
  DeleteThemeUrl = this.baseUrl + "/Common/DeleteThemeByMerchant";//done Admin
  UploadThemeMerchantUrl = this.baseUrl + "/Common/UploadThemeByMerchant";//done Admin
  CheckThemeConfiguration=this.baseUrl +"/DocumentUpload/CheckThemeConfiguration";
  uploadApi = this.baseUrl + "/DocumentUpload/UploadTheme";//done Admin

  /* User */
  UpdateUserUrl = this.baseUrl + "/Users/UpdateMerchantById";//done Admin
  AddUserUrl = this.baseUrl + '/Users/AddUserRole';//done Admin 
  DeleteUserUrl = this.baseUrl + '/Users/DeleteUser';//done Admin
  UserListUrl = this.baseUrl + '/Users/GetUsersRoleListByMerchantId';//done Admin

  /* Add product location */

  AddLocationProduct=this.baseUrl+'/Product/AddLocationProduct';
  UpdateLocationProduct=this.baseUrl+'/Product/UpdateLocationProduct';
  DeleteLocationProduct=this.baseUrl+'/Product/DeleteLocationProduct';
  MerchantLocationProducts=this.baseUrl+'/Product/MerchantLocationProducts';
  LocationProductInfoById=this.baseUrl+'/Product/LocationProductInfoById';
  LocationsByChain=this.baseUrl+'/Location/LocationsByChain';


  /* Common api .............. */
  UpdateMerchantUrl = this.baseUrl + "/Merchant/UpdateMerchantById";//done Superadmin
  PermissionDetailUrl = this.baseUrl + "/SuperAdmin/PermissionDetailByMerchantId";//done common

  AllPermissionsWithMerchant=this.baseUrl+"/Superadmin/AllPermissionsWithMerchant";
  GetPermissionByMerchantId=this.baseUrl+"/Superadmin/GetPermissionByMerchantId";


  placemachinesearchUrl = this.baseUrl + "/PlaceMachine/GetListOfPlaceMachineByMerchantIdBySearch_New";//done admin
  SuperAdminssearchUrl = this.baseUrl + "/SuperAdmin/GetListOfPlaceMachineForSuperAdminBySearch";//done Superadmin
  LocationDetailUrl = this.baseUrl + "/Location/GetLocationDetailById";//done admin
  RebootRequest = this.baseUrl + "/Machine/RebootRequest"//done Superadmin

  /* Active,Deleted  && Deactive Custommer............. */
  ActiveCustomerListUrl = this.baseUrl + "/SuperAdmin/GetActiveDeactiveCustomerList";//done Superadmin
  ActiveDeactiveListUrl = this.baseUrl + "/SuperAdmin/ActiveDeactiveCustomer";//done Superadmin

  SetPermissionUrl = this.baseUrl + "/SuperAdmin/SetPermissionsForPanel";//done Superadmin
  SearchRadioURL = this.baseUrl + "/Superadmin/GetActiveDeactiveCustomerListBySearch";//done Superadmin
  DeleteCustomerUrl = this.baseUrl + "/SuperAdmin/DeleteRestoreCustomer"//done Superadmin
  DeletedCustomerList = this.baseUrl + "/SuperAdmin/GetDeletedCustomerList"//done Superadmin

  /* Distributorlist............ */
  DistributorlistURL = this.baseUrl + "/Merchant/GetDistributorsList";//done Superadmin
  AssignMerchantURL = this.baseUrl + "/Merchant/UpdateMerchantDistributorRelation";//done Superadmin
  MerchantListingURL = this.baseUrl + "/Merchant/GetMerchantList";//done Superadmin
  SearchMerchantURL = this.baseUrl + "/Merchant/GetMerchantListBySerach";//done Superadmin

  /* New Signup Superadmin.............*/

  NewCustomerUrl = this.baseUrl + "/SuperAdmin/GetNewCustomerList";//done Superadmin


  /*Inventory....................*/
  ManufacturerListUrl: string = this.baseUrl + "/Machine/GetManufacturerList";//done Superadmin
  SuperadminTransferInventoryListUrl: string = this.baseUrl + "/SuperAdmin/GetSuperadminTransferInventoryList";//done Superadmin
  UsertypeURL = this.baseUrl + "/Superadmin/GetActiveDeactiveCustomerListBySearch";//done Superadmin
  SaveTransferInventoryURL = this.baseUrl + "/SuperAdmin/SaveTransferInventory";//done  Superadmin
  DeleteMachine: string = this.baseUrl + "/Machine/DeleteMachine";//done Superadmin

  /*Add machine....................*/
  ModelByManufacturerUrl: string = this.baseUrl + '/Machine/GetMachineModelByManufacturer';//not used
  MachineNumberCheckUrl = this.baseUrl + "/Machine/IsExistMachineNumber";//not in use
  MachineSNCheckUrl = this.baseUrl + "/Machine/IsExistMachineSerialNumber";//done Superadmin
  AddMachineUrl = this.baseUrl + '/Machine/AddMachine';//done Superadmin
  MachineByIdUrl = this.baseUrl + "/Cube/GetMachineWithSlavesById";//done Superadmin
  UpdateMachineUrl = this.baseUrl + "/Machine/UpdateMachine";   //done Superadmin
  GetMachineDetailUrl = this.baseUrl + "/Machine/GetMachineDetailById1";// not in use

  /* Request Inventory...........*/
  GetRequestedTransferListURL = this.baseUrl + '/SuperAdmin/GetRequestedTransferList';//done Superadmin
  RejecttransferURL = this.baseUrl + '/SuperAdmin/RejectRequest';//done Superadmin
  AccepttransferURL = this.baseUrl + '/SuperAdmin/AcceptRequest';//done Superadmin
  InventorySearchMerchantURL = this.baseUrl + "/Superadmin/GetActiveDeactiveCustomerListBySearch";//done Superadmin



  // Machinelist Superdmin..................
  SuperAdminUrl = this.baseUrl + "/SuperAdmin/GetListOfPlaceMachineForSuperAdmin";//done Superadmin
  UnassignLinkCodeUrl = this.baseUrl + "/Machine/UnassignLinkCode"//done Superadmin

  // Superadmin............
  SuperadminlistUrl = this.baseUrl + "/SuperAdmin/GetSuperAdminList";//done Superadmin
  DeleteSuperAdmin = this.baseUrl + "/SuperAdmin/DeleteSuperAdmin";//done Superadmin
  createUrl = this.baseUrl + "/superadmin/CreateSuperAdmin";//done Superadmin
  UpdatesuperadminUrl = this.baseUrl + "/Merchant/UpdateMerchantById";//done Superadmin


  /* for both admin and superadmin Dashboard.................... */
  UploadImageUrl = this.baseUrl + "/Merchant/SaveProfileImage"//done common
 // UploadImageUrl = this.baseUrl + "/DocumentUpload/UploadProfilePicBase64Image"//done common
  ChangePswUrl = this.baseUrl + "/Merchant/ChangePasswordById";//done common
  LogoutUrl = this.baseUrl + "/Machine/Logout"; //common

  /*View Map in superadmin*/
  MachinelstwithStatus: string = this.baseUrl + "/Machine/GetMachineListWithStatus";//done Superadmin
  MachineDetail = this.baseUrl + "/Machine/GetMachineDetail"; //common

  /* Map */
  MachinelstStatusUrl = this.baseUrl + "/Machine/GetMachineListWithStatusNew";//done admin

  /*placeamachine....................*/
  ProductPlaceInMasterUrl = this.baseUrl + `/PlaceMachine/CheckProductPlacedInMaster`;//done admin
  PlaceMachinelst = this.baseUrl + "/PlaceMachine/GetListOfPlaceMachineByMerchantId_New";//done admin
  DeleteUrl = this.baseUrl + "/PlaceMachine/DeletePlaceMachine";//done admin
  MachinelstTMUrl = this.baseUrl + "/PlaceMachine/MachineListAccordingtoTrayAndMotor";//done admin
  UpdateLocationDesUrl = this.baseUrl + "/PlaceMachine/UpdateLocationDescription";//done admin
  CopyPlanogramUrl = this.baseUrl + "/PlaceMachine/CopyPlanogram";//done admin

  /* AddPlacement...................... */
  MachineDetailUrl: string = this.baseUrl + "/Machine/GetMultipleMachineDetailById";//done admin
  UnplaceMachineListUrl: string = this.baseUrl + "/PlaceMachine/UnPlaceMachinesWithSlaveCountByMerchantId";//done admin
  PlaceMachineListUrl: string = this.baseUrl + "/PlaceMachine/GetListOfPlaceMachineByMerchantId";//done admin
  PlaceMachineUrl: string = this.baseUrl + "/PlaceMachine/PlaceMachine";//done admin

  /*Edit Placement*/
  GetPlaceMachineDetailUrl = this.baseUrl + "/PlaceMachine/GetPlaceMachineDetailById";//done Admin

  UpdatePlaceMachineUrl = this.baseUrl + "/PlaceMachine/EditPlaceMent";//done admin
  /*Planogram................*/
  TrayMotorlstUrl = this.baseUrl + "/PlaceMachine/GetTrayAndMotorOfMachine";//done admin
  ProductlstPlanoUrl = this.baseUrl + "/PlaceMachine/GetProductsForPlanogram";//done admin
  AddProdToMachineUrl = this.baseUrl + "/PlaceMachine/AddProductToMachine";//done admin
  RemoveProductUrl = this.baseUrl + "/PlaceMachine/RemoveProductFromMachine";//done admin
  GetMachineLocationDetailUrl = this.baseUrl + "/Machine/GetMachineLocationDetail";//done admin
  TrayMotorConfUrl = this.baseUrl + "/Placemachine/GetTrayConfiguration";//done admin
  RemoveTrayOrMotorUrl = this.baseUrl + "/Placemachine/RemoveTrayOrMotor";//done admin
  AddTrayOrMotorUrl = this.baseUrl + "/Placemachine/AddTrayOrMotor";//done admin


  /* category */
  CategoryListUrl: string = this.baseUrl + "/Category/MainCatListByMerchantId";//done admin
  SubCategoryListUrl: string = this.baseUrl + "/Category/SubCatListByMainCatId";//done admin
  DeleteCategoryUrl: string = this.baseUrl + "/Category/DeleteMainCategory";//done admin
  //SubcategorylistUrl: string = this.baseUrl + "/Category/SubCatListByMainCatId"; 
  DeleteSubCatUrl: string = this.baseUrl + "/Category/DeleteSubCategory";//done admin
  AddSubCategoryUrl = this.baseUrl + "/Category/AddSubCategory";//done admin
  updateSubCatUrl: string = this.baseUrl + "/Category/UpdateSubCategory";//done admin
  updateMainCatUrl = this.baseUrl + '/Category/UpdateMainCategory';//done admin
  getMainCategoryDetailUrl: string = this.baseUrl + "/Category/GetMainCategoryDetailById";//done admin

  /* Supplier */
  AddSupplierUrl = this.baseUrl + '/ProductSupplier/AddProductSupplier';//done admin
  SupplierDetailsUrl = this.baseUrl + '/ProductSupplier/ProductSupplierDetailById';//done admin
  UpdateSupplierUrl = this.baseUrl + '/ProductSupplier/UpdateProductSupplierById';//done admin
  SupplierlstUrl = this.baseUrl + `/ProductSupplier/GetListOfSupplierByMerchantId`;//done admin
  DeleteSupplierUrl = this.baseUrl + `/ProductSupplier/DeleteProductSupplierById`;//done admin

  // SMTP
  deleteSMTPSettings = this.baseUrl + '/SMTPEmail/DeleteSMTPSettings';//done admin

  // / special message /
  CreateSpecialMessage:string = this.baseUrl + '/SMTPEmail/CreateSpecialMessage'; //done admin
  EditSpecialMessage:string = this.baseUrl + '/SMTPEmail/EditSpecialMessage'; //done admin
  DeleteSpecialMessage:string = this.baseUrl + '/SMTPEmail/DeleteSpecialMessage'; //done admin
  GetSpecialMessageList:string = this.baseUrl + '/SMTPEmail/GetSpecialMessageList'; //done admin
  GetSpecialMessageDetailById:string = this.baseUrl + '/SMTPEmail/GetSpecialMessageDetailById'; //done admin

/* Terms and conditions */
UpdateTermsAndCondition=this.baseUrl + '/Machine/UpdateTermsAndCondition'; //done admin
GetTermsandConditions=this.baseUrl+'/Machine/GetTermsandConditions'; //done admin


  /* Coupons */
  CouponlistUrl = this.baseUrl + "/Coupon/GetCouponListByMerchantId";//done admin
  CouponDeleteUrl = this.baseUrl + "/Coupon/DeleteCoupon";//done admin
  AddCouponUrl = this.baseUrl + "/Coupon/CreateCouponForMultipleMachineProducts ";//done admin
  PlaceMachineLstUrl = this.baseUrl + '/Coupon/GetPlaceMachines';//done admin
  PlaceMachineForXactLstUrl = this.baseUrl + '/Coupon/GetPlaceMachinesForXact';//done admin
  SpecialPricingPlaceMachineLstUrl = this.baseUrl + "/SpecialPricing/GetPlaceMachinesForSpecialPrice";
  ProductLstMachineUrl = this.baseUrl + "/Report/AllProductBySelectedMachines";//done admin
  IsExistCouponCode = this.baseUrl + "/Coupon/IsExistCouponCode";//done admin
  UpdateCouponUrl = this.baseUrl + "/Coupon/UpdateCoupon";//done admin
  GetCouponDetailUrl = this.baseUrl + "/Coupon/GetCouponDetailById";//done admin
  GetPlacedMachineTimeZoneList = this.baseUrl + "/Coupon/GetPlacedMachineTimeZoneList";
    
  /* Digital Signage */
  SaveApi = this.baseUrl + "/DigitalSignage/AddScreen"//done admin
  DeleteApi = this.baseUrl + "/DigitalSignage/DeleteScreen";//done admin
  UpdateApi = this.baseUrl + "/DigitalSignage/UpdateScreen";//done admin
  ListApi = this.baseUrl + "/DigitalSignage/ScreenListByMerchantId";//done admin
  ContentApi = this.baseUrl + "/DigitalSignage/ContentListByMerchantId";//done admin
  CampaignDetailApi = this.baseUrl + "/DigitalSignage/CampaignDetailById";//done admin
  CreateCampaignApi = this.baseUrl + "/DigitalSignage/CreateCampaign";//done admin
  CampaignlistApi = this.baseUrl + "/DigitalSignage/GetListOfCampaigns";//done admin
  deleteCampaignApi = this.baseUrl + "/DigitalSignage/DeleteCampaign";//done admin

  /* Content */
  SaveContentApi = this.baseUrl + "/DigitalSignage/AddContent"; // Already Done 
  deleteApi = this.baseUrl + "/DigitalSignage/DeleteContent";//done  admin
  EditApi = this.baseUrl + "/DigitalSignage/UpdateContent";//done admin
  listApi = this.baseUrl + "/DigitalSignage/ContentListByMerchantId"; // Already Done  Admin
  Uploadurl = this.baseUrl + "/UploadContent/UploadFile"; //Already Done  Admin
  clientlistApi = this.baseUrl + "/Client/ClientListByMerchantId"; //done Admin
  clientdetailsApi = this.baseUrl + "/Client/ClientDetailById"; //done Admin
  ContentDetailApi = this.baseUrl + "/DigitalSignage/ContentDetailById";  //done Admin
  UpdateCampaignApi = this.baseUrl + "/DigitalSignage/UpdateCampaign";//done Admin

  /* Campaign */
  CampaignDetailsApi = this.baseUrl + "/DigitalSignage/CampaignDetailForCalendarById"; // Adready done Admin
  MachinelistApi = this.baseUrl + '/Coupon/GetPlaceMachinesForDigital';  // Adready Done Admin
  PlaceCampaignApi = this.baseUrl + "/DigitalSignage/PlaceCampaign";//done Admin
  ListOfPlaceCampaignsApi = this.baseUrl + "/DigitalSignage/ListOfPlaceCampaignsByMerchantId"; //Adready Done Admin
  deletePlaceCampaignApi = this.baseUrl + "/DigitalSignage/DeletePlaceCampaign";//done  Admin
  UpdatePlaceCampaignApi = this.baseUrl + "/DigitalSignage/UpdatePlaceCampaign";//done Admin
  PlaceCampaignDetailApi = this.baseUrl + "/DigitalSignage/PlaceCampaignDetailById"; // done Admin

  /* Screen */
  EditScreenApi = this.baseUrl + "/DigitalSignage/GetScreenDetailById" //done Admin

  /* Inventory */
  DistributorInventoryListUrl = this.baseUrl + "/Merchant/GetDistributorInventoryList";//done Admin
  UsertypeMerchantURL = this.baseUrl + "/Merchant/GetAssignMerchantList";//done Admin

  /* Merchant Inventory */
  MerchantInventoryListUrl = this.baseUrl + "/Merchant/GetMerchantInventoryList";//done  Admin
  SaveMachineTransferInventoryURL = this.baseUrl + "/Machine/SaveRequestTransfer";//done  Admin

  /* Pickup Report */
  GetRouteListUrl = this.baseUrl + "/Machine/GetRouteList";//done Admin
  GetPickupReportUrl = this.baseUrl + "/Report/PickUpreportByRouteProducts"; // Adready done Admin
  ExportPickuptUrl = this.baseUrl + "/Report/ExportPickUpreportByRouteProducts";// Adready done Admin
  PrintUrl = this.baseUrl + "/Report/PrintPickUpReportByRoute";// Adready done Admin

  /* Product Sales Report */
  PlacedProductlstUrl = this.baseUrl + "/Report/AllPlacedProductByMerchant";// Done Admin
  ProductLstMachinesUrl = this.baseUrl + "/Report/AllProductBySelectedMachines";// Done Admin
  ReportsLstURl = this.baseUrl + "/Report/ReportsByProductSelection";// Done Admin
  ExportproductreporttUrl = this.baseUrl + "/Report/ExportReportsByProductSelection";// Done Admin
  PrintProductsUrl = this.baseUrl + "/report/PrintReportsByProductSelection";// Done Admin

  /* Stock Pick Report */
  MachineLstUrl = this.baseUrl + "/Report/MachineListByLocation";//done  Admin
  PlacedProductLstUrl = this.baseUrl + "/Report/AllPlacedProductByMerchant";//done Admin
  StockReportByMachineUrl = this.baseUrl + "/Report/GetStockReportByMachines ";//done Admin
  StockRepostByProductUrl = this.baseUrl + "/Report/GetStockReportByProducts ";//done Admin

  /* Route List */
  RouteDeleteUrl = this.baseUrl + "/Machine/DeleteRoute";//done Admin
  PrintrouteUrl = this.baseUrl + "/Machine/GenerateRoutePDF";//done admin
  AddRouteUrl = this.baseUrl + '/Machine/AddRoute';//done admin

  /* Special Pricing */
  ProductLstGlobalUrl = this.baseUrl + "/Product/GetProductListSpecialPricing";//done  Admin
  ApplySPUrl = this.baseUrl + "/SpecialPricing/SetSpecialPricingOnMachineProducts"; // Done Admin
  SpecilPricingUrl = this.baseUrl + "/SpecialPricing/IsExistRuleName";//done Admin
  SPLstByTypeUrl = this.baseUrl + "/SpecialPricing/SpecialPricingListByRule";//done Admin
  DeleteSPUrl = this.baseUrl + '/SpecialPricing/DeleteSpecialPricingByRule';//done Admin
  UpdateSPUrl = this.baseUrl + "/SpecialPricing/UpdateSpecialPricingOnMachineProducts";// done Admin
  GetdetailUrl = this.baseUrl + "/SpecialPricing/DetailOfSpecialPricingByRule";//done Admin
  GetPlaceMachinesForSpecialPrice=this.baseUrl+"/SpecialPricing/GetPlaceMachinesForSpecialPrice"
  /* Logo */
  logoApi = this.baseUrl + "/DocumentUpload/UploadLogoBase64Image";//done admin
  DeletelogoUrl = this.baseUrl + "/Common/RemoveLogoByMerchant";//done admin

  SaveCompanyLogo = this.baseUrl + "/Merchant/SaveCompanyLogo";
  SaveProfileImage = this.baseUrl + "/Merchant/SaveProfileImage";
  GetCompanyInfo = this.baseUrl + "/Merchant/GetCompanyInfo";


  /* brandManagement */
  CreateBrand = this.baseUrl + "/Brand/CreateBrand"
  UpdateBrandResult = this.baseUrl + "/Brand/UpdateBrandResult"
  DeleteBrandResult = this.baseUrl + "/Brand/DeleteBrandResult"
  BrandByIdResult = this.baseUrl + "/Brand/BrandByIdResult"
  MerchantBrandResult = this.baseUrl + "/Brand/MerchantBrandResult"


  /* features */
  GetMerchantFeatures =this.baseUrl+"/Superadmin/GetMerchantFeatures";
  UpdateMerchantFeatures = this.baseUrl + "/SuperAdmin/UpdateMerchantFeatures";

  /*  Chains */

  AddChain=this.baseUrl+"/Location/AddChain";
  UpdateChain=this.baseUrl+"/Location/UpdateChain";
  DeleteChain=this.baseUrl+"/Location/DeleteChain";
  MerchantChains=this.baseUrl+"/Location/MerchantChains";

  // sampling reports

  GetProductListByBrandId=this.baseUrl+"/Product/GetProductListByBrandId";
  GetLocationListByChain=this.baseUrl+"/Location/GetLocationListByChain";
  BrandMainReport=this.baseUrl+"/Brand/BrandMainReport";
  BrandSubReport=this.baseUrl+"/Brand/BrandSubReport";
  PrintBrandMainReport=this.baseUrl+"/Brand/PrintBrandMainReport";
  ExportBrandMainReport=this.baseUrl+"/Brand/ExportBrandMainReport";

  PrintBrandSubReport=this.baseUrl+"/Brand/PrintBrandSubReport";
  ExportBrandSubReport=this.baseUrl+"/Brand/ExportBrandSubReport";


  //Shopper reports

  AllShopperResult=this.baseUrl+"/Shopper/AllShopperResult";
  MerchantZipCodeList=this.baseUrl+"/Brand/MerchantZipCodeList";

  UserSpecificReport=this.baseUrl+"/Shopper/UserSpecificReport";

  PrintUserReport=this.baseUrl+"/Shopper/PrintUserReport";
  ExportUserReport=this.baseUrl+"/Shopper/ExportUserReport";
  PrintZipCodeSpecificReport=this.baseUrl+"/Shopper/PrintZipCodeSpecificReport";
  ExportZipCodeReport=this.baseUrl+"/Shopper/ExportZipCodeReport";
  SearchShopper=this.baseUrl+"/Shopper/SearchShopper";
  StoreReport=this.baseUrl+"/Brand/StoreReport";

  MerchantStateList=this.baseUrl+"/Brand/MerchantStateList";
  MerchantChainbyState=this.baseUrl+"/Brand/MerchantChainbyState";
  LocationsByChainandState=this.baseUrl+"/Brand/LocationsByChainandState";
  BrandListbyLocation=this.baseUrl+"/Brand/BrandListbyLocation"
  MachineListByProduct=this.baseUrl+"/Brand/MachineListByProduct";
  PrintStoreReport=this.baseUrl+"/Brand/PrintStoreReport";

  ExportStoreReport=this.baseUrl+"/Brand/ExportStoreReport";

  /* firebasesetting */
  SaveOrUpdateFirebaseDetails=this.baseUrl+"/SMTPEmail/SaveOrUpdateFirebaseDetails";
  GetMerchantFirebaseDetail=this.baseUrl+"/SMTPEmail/GetMerchantFirebaseDetail";

  constructor() { }
}
