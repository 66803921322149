import { Component, OnInit, HostListener } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Sort } from '@angular/material';

import { Subject } from 'rxjs';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
declare const $;

@Component({
  selector: 'app-event-subscription',
  templateUrl: './event-subscription.component.html',
  styleUrls: ['./event-subscription.component.css']
})
export class EventSubscriptionComponent implements OnInit {

  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
  p = 1;
  posts: any;
  IdLocation: string;
  Search: any;
  AlertText = '';
  post_info: any;
  pageno = 1;
  before: number;
  //TotalCount: any = 1;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  IdSubsc;
  public RowHeight = 43; // initial estimate


  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public apiList: ApilistService
  ) {
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
  }

  ngOnInit() {
    this.hidepops();
    this.refreshsubscriptionlist();
  }

  hidepops() {
    $('#LocationDetails').modal('hide');
    $('#alert_div').hide();
    $('#dots_spinner').hide();
  }

  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      this.ItemsPerPage = 9;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
  }

  addAlert() {
    this.router.navigate([this.companyName + '/dashboard/eventsubscription/addsubscription']);
  }

  getID(id: string) {
    this.IdSubsc = id;
  }

  
  refreshsubscriptionlist() {
    $('#dots_spinner').show();
    let data = {
      PageNumber: this.pageno,
      NumberOfRows: this.before
    };
    this.http.post<any>(this.apiList.GetSubscribedAlertList, data, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(resp => {
        if (resp == null || resp.Response == null) { throw { Response: 0, ErrorMessage: 'Null Response' }; }
        resp.Response = +resp.Response;
        if (resp.Response !== 1) { throw resp; }
        return resp;
      })
      .subscribe((response) => {
        this.posts = response.merchantSubscribes;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide();
      if (this.Resizing) {
        this.SettleAndAutosize();
      }
    });
  }

  delete() {

    //console.log("this.IdSubsc"+this.IdSubsc);
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteAlerts, { IdMerchant : this.IdSubsc }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe(success => {
        const res = success;
        if (res.Response === 1) {
          this.FlashError('Successfully deleted');
          this.refreshsubscriptionlist();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  edit(id: string) {
    this.IdSubsc = id;
    this.router.navigate([this.companyName + '/dashboard/eventsubscription/editsubscription', {
      id: this.IdSubsc
    }]);
  }

  // -----------sorting----------//
  sortData(sort: Sort) {
    const data = this.posts.slice();
    if (!sort.active || sort.direction === '') {
      this.posts = data;
      return;
    }
    this.posts = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'merchantId': return this.compare(a.IdMerchant.toLowerCase(), b.IdMerchant.toLowerCase(), isAsc);
        case 'merchantName': return this.compare(a.Name.toLowerCase(), b.Name.toLowerCase(), isAsc);
        case 'merchantRole': return this.compare(a.UserRole.toLowerCase(), b.UserRole.toLowerCase(), isAsc);
        case 'merchantEmail': return this.compare(a.EmailId.toLowerCase(), b.EmailId.toLowerCase(), isAsc);
  
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  clearSearch() {
    this.Search = '';
    this.refreshsubscriptionlist();
  }
  /* function for hit the api when click on pagination*/
  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }

}
