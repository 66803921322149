import { Component, OnInit, HostListener } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
import { Subject } from 'rxjs';
declare const $;

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.css']
})

export class CouponsComponent implements OnInit {
  baseUrl = environment.baseUrl;

  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));

  lists: any;
  IdCoupon: any;
  Search: any;
  key: any;
  reverse: any;
  p: any;
  ll: any;
  before: number;
  screenHeight: number;
  pageno: any;
  AlertText: string;
  keylock: number;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate
  checkedCoupon:boolean=false;
  pos: { lat: number; lng: number; };

  checkedBit:number=0;
  timezone: any;
  selectedtimezone='';
  timezone_address: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public apiList: ApilistService,
    private route: ActivatedRoute
  ) {
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }

  ngOnInit() {
    this.checkedCoupon=false;
    this.checkedBit=0;
    this.route.params.subscribe((get) => {
      this.Search = get.value;
      })
      this.getTimeZone()
    this.HidePopups()
  }

  HidePopups(){
     $("#alert_div").hide();
    $('#dots_spinner').hide();
  }

  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
//console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  getID(id: string) {
    this.IdCoupon = id;
  }

  refreshlistcoupons() {
    //IsUsed
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CouponlistUrl, { IdMerchant: this.IdMerchant, IsUsed : this.checkedBit, TimeZoneId: this.selectedtimezone }, this.globalservice.Headers())
    .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.ll = response;
      this.lists = this.ll.lst_Coupons;
       $("#paginate").show();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide();
      if (this.Resizing) {
        // need to give time to render behind the scenes before size/resize rows
        this.SettleAndAutosize();
      }
     });

  }

  getTimeZone(){
    this.http.get<any>(this.apiList.GetPlacedMachineTimeZoneList, this.globalservice.Headers())
    .timeout(25000)
    .take(1)
    .map(resp => {
      if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
      if (+resp.Response !== 1) { throw resp; }
      return resp;
    }).subscribe((response) => {
      console.log(response)
      if(response.Response == 1){
        this.timezone = response.lst_TimeZone;

        this.selectedtimezone = this.timezone.length > 0 ? response.lst_TimeZone[0].TimeZone : Intl.DateTimeFormat().resolvedOptions().timeZone
        this.refreshlistcoupons();
      }
    }, (err: any) => {
      const Error = this.globalservice.ProcessError(err);
      if (Error.Response === 35) {
        if (Error.ErrorMessage) {
          this.FlashError(Error.ErrorMessage);
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
        return;
      } else {
        this.FlashError(Error.ErrorMessage);
        return;
      }
    }).add(() => { });
  }

 
  Addcoupons() {
    this.router.navigate([this.companyName + '/dashboard/coupons/addcoupons']);
  }

  delete() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CouponDeleteUrl, { IdCoupon: this.IdCoupon, CreatedBy: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.FlashError(response.ErrorMessage);
        this.refreshlistcoupons();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  Edit(id) {
    this.router.navigate([this.companyName + '/dashboard/coupons/Updatecoupons',
    { id: id }]);
  }
  onChangeCheckbox(value){
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
    this.checkedBit =  value.checked == true? 1 : 0
    this.refreshlistcoupons();
  }

  onChangeTimeZone(){
    this.refreshlistcoupons();
  }

  //-----------sorting----------//
  sortData(sort: Sort) {
    const data = this.lists.slice();
    if (!sort.active || sort.direction === '') {
      this.lists = data;
      return;
    }
    this.lists = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'amount': return this.compare(a.DiscountAmount, b.DiscountAmount, isAsc);
        case 'statedate': return this.compare(a.StartDate, b.StartDate, isAsc);
        case 'enddate': return this.compare(a.EndDate, b.EndDate, isAsc);
        case 'starttime': return this.compare(a.StartTime, b.StartTime, isAsc);
        case 'endtime': return this.compare(a.EndTime, b.EndTime, isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  //----------------------//

  clearSearch() {
    this.Search = '';
    this.refreshlistcoupons();
  }

  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }

   FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  filteCoupon(){
    this.refreshlistcoupons()
  }
}
