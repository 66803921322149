import { Component, OnInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../global.service';
import { NgForm } from '@angular/forms';
import { MatInput } from '@angular/material';
import { Sort } from '@angular/material/sort';
import { ApilistService } from '../../../service/Api/apilist.service';
declare const $;


@Component({
  selector: 'app-merchantinventory',
  templateUrl: './merchantinventory.component.html',
  styleUrls: ['./merchantinventory.component.css']
})
export class MerchantinventoryComponent implements OnInit {
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  UserRole = sessionStorage.getItem("referUserRole");

  @ViewChild('deactivate',{static: false}) public deactivateForm: NgForm
  @ViewChild('t2', {
    read: MatInput,
    static: false
  }) t2: MatInput;
  Inventory: any;
  AlertText: string;
  Selectedmachine: any;
  IdManufacturer: any;
  posts: any;
  IdMachine: any;
  selectedser
  Effectivedate: any;
  before: number;
  screenHeight: number;
  pageno: any;
  destination: any;
  Search
  Assignby: boolean;  // true when 'superadminlogin otherwise false'


  constructor(private router: Router, 
    private http: HttpClient, 
    private globalservice: GlobalService,
    public apiList: ApilistService) {
    this.screenHeight = window.innerHeight;
    var rowno = (window.innerHeight - 300) / 54;
    if (rowno >= 1) {
      this.before = parseInt(rowno.toFixed(2).toString().split(".")[0], 10);
    } else {
      this.before = 1;
    }
   }

  ngOnInit() {
  this.HidePopups()
    this.Inventorydata();
    this.Assignby = this.UserRole == 'SuperAdmin'?true:false
  }

  HidePopups(){
    $("#alert_div").hide();
    $('#transferform').modal('hide');
  }

  Inventorydata() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MerchantInventoryListUrl, { IdDistributor: this.IdMerchant }, this.globalservice.Headers())
    .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
    this.Inventory = response.lst_Inventory;
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      $('#transferform').modal('hide');
      $('#dots_spinner').hide(); });
  }

  getID(id: string) { this.IdMachine = + id; }

  Transfer(value, id) {
    this.Selectedmachine = value
    this.IdManufacturer = id
    $('#transferform').modal('show');
  }


  addEvent1(value) {
    this.Effectivedate = value
  }

  Assign() {
    let data = {
       IdMachine: this.IdMachine,
       EffectiveDate: this.Effectivedate,
       SourceIdMerchant: this.IdMerchant,
      Status:"pending",
      Destination:this.destination,
      IsSuperAdmin:this.Assignby,
    }
    this.http.post<any>(this.apiList.SaveTransferInventoryURL, data, this.globalservice.Headers())
      .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
        this.deactivateForm.reset();
        this.t2.value = '';
        this.Inventorydata();
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      $('#transferform').modal('hide'); });
  }
  
  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerHeight;
    this.screenHeight = window.innerHeight;
    var rowno = (window.innerHeight - 300) / 54;
    this.before = parseInt(rowno.toFixed(2).toString().split(".")[0], 10);//before
    if (this.before >= 0) {
      if (this.before == 0) {
        this.before = 1
      }
      //this.Inventorydata();
    }
  }

  clearSearch(){
    this.Search=''
    this.Inventorydata();
  }

  sortData(sort: Sort) {
    const data = this.Inventory.slice();
    if (!sort.active || sort.direction === '') {
      this.posts = data;
      return;
    }
    this.Inventory = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Purchase_Date': return this.compare(a.PurchaseDate, b.PurchaseDate, isAsc);
        case 'Machine_Type': return this.compare(a.MachineType.toLowerCase(), b.MachineType.toLowerCase(), isAsc);
        case 'Machine_Serial': return this.compare(a.MachineSerial.toLowerCase(), b.MachineSerial.toLowerCase(), isAsc);
        case 'LinkCode': return this.compare(a.LinkCode.toLowerCase(), b.LinkCode.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
