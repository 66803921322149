import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { RouterModule, Routes, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
import { filter } from 'rxjs/operators';
declare var google;
declare const $;

@Component({
  selector: 'app-viewmap',
  templateUrl: './viewmap.component.html',
  styleUrls: ['./viewmap.component.css']
})
export class ViewmapComponent implements OnInit, OnDestroy {


  IdMerchant = sessionStorage.getItem('referSuperMerchantId');
  Session_id = sessionStorage.getItem('Sessionid');
  public VendEvents: EventSource;
  @ViewChild('map', { static: false }) public mapElement: ElementRef;
  map: any;
  lat_m;
  pos: { lat: number; lng: number; };
  Machinelist = [];
  activeInfoWindow: any;
  Gotomachine: string;
  CompanyName: any;
  Gotoplanogram: string;
  result: any;
  EventUrl: any;
  DashBoardContext = '';
  Marker: any;
  Merchantdetail: any;
  bounds: any;
  show_result: any;
  boxText: any;
  activeInfoWindow2: any;
  UpTime: any;
  MachineStatus: any;
  Gotodashboard: string;
  detail_id: any;
  picker_lat: any;
  picker_long: any;
  AllMarkers = [];
  AlertText: string;
  linkcode: any;
  constructor(
    public apiList: ApilistService,
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    private elementRef: ElementRef) {
  }

  ngOnInit() {
    $(document).unbind('click');
    this.hidepops();
    this.Listofmachine();
  }
  hidepops() {
    $('#alert_div').hide();
    $('#dots_spinner').hide();
  }
  Listofmachine() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachinelstwithStatus,
      {
        IdMerchant: '0', Idmachines: null,
        SuperAdminId: this.IdMerchant,
        SessionId: this.Session_id
      },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.result = response;
        if (this.result.Response === 1) {
          this.Merchantdetail = this.result.obj_MerchantLocation;
          this.Machinelist = this.result.lst_Machines;
          this.EventUrl = this.result.EventUrl;
          this.VendEvents = new EventSource(this.EventUrl);
          this.VendEvents.onmessage = (e) => {
            const Response = JSON.parse(e.data);
            for (let i = 0; i < Response.length; i++) {
              for (let j = 0; j < this.Machinelist.length; j++) {
                for (let l = 0; l < this.Machinelist[j].lst_Companies.length; l++) {
                  for (let n = 0; n < this.Machinelist[j].lst_Companies[l].lst_DashBoardMachines.length; n++) {
                    if (Response[i].objChange.IdMachine === this.Machinelist[j].lst_Companies[l].lst_DashBoardMachines[n].IdMachine) {
                      if (Response[i].objChange.RemoveStatus === true) {  // RemoveStatus false means Remove the machine
                        this.Machinelist[j].lst_Companies[l].lst_DashBoardMachines.splice(n, 1);
                        for (let m = 0; m < this.AllMarkers.length; m++) {
                          if (this.AllMarkers[m].lat_m === this.Machinelist[j].Latitude && this.AllMarkers[m].long_m === this.Machinelist[j].Longitude) {
                            this.Marker = this.AllMarkers[m];
                            this.Marker.setMap(null);
                          }
                        }
                        break;
                      } else {   // Update the Uptime and connect disconnect status
                        if (Response[i].objChange.Status) {
                          this.Machinelist[j].lst_Companies[l].lst_DashBoardMachines[n].Status = Response[i].objChange.Status;
                          this.Machinelist[j].lst_Companies[l].lst_DashBoardMachines[n].UpTime = Response[i].objChange.UpTime;
                          if (this.detail_id === Response[i].objChange.IdMachine) {
                            $('#secondmarker h5 #up').text(Response[i].objChange.UpTime);
                          }
                        }
                        if (Response[i].objChange.MachineStatus) {
                          this.Machinelist[j].lst_Companies[l].lst_DashBoardMachines[n].MachineStatus = Response[i].objChange.MachineStatus;
                          if (this.detail_id === Response[i].objChange.IdMachine) {
                            $('#secondmarker h5 #st').attr('class', Response[i].objChange.MachineStatus);
                          }
                        }
                      }
                    }
                  }
                  if (Response[i].objChange.RemoveStatus === false) {  // RemoveStatus true means any new machine add or update existing machine
                    if (Response[i].objChange.Latitude === this.Machinelist[j].Latitude && Response[i].objChange.Longitude === this.Machinelist[j].Longitude) {
                      const IsCompanyExist = this.Machinelist[j].lst_Companies.some(function (o) { return o['CompanyName'] === Response[i].objChange.CompanyName; });
                      if (IsCompanyExist) {   // Add new machine on existing lat long and company
                        const index = this.Machinelist[j].lst_Companies.findIndex(record => record.CompanyName === Response[i].objChange.CompanyName);
                        const IsMachineExist = this.Machinelist[j].lst_Companies[index].lst_DashBoardMachines.some(function (o) { return o['IdMachine'] === Response[i].objChange.IdMachine; });
                        if (!IsMachineExist) {
                          this.Machinelist[j].lst_Companies[index].lst_DashBoardMachines.push(Response[i].objChange);
                          break;
                        }
                      }
                      if (!IsCompanyExist) {  // Add new machine on existing lat long and add new company
                        const dashboardMac = [];
                        dashboardMac.push(Response[i].objChange);
                        let data = {};
                        data = {
                          CompanyName: Response[i].objChange.CompanyName,
                          IdMerchant: Response[i].objChange.IdMerchant,
                          UserRole: 'Admin',
                          lst_DashBoardMachines: dashboardMac
                        };
                        this.Machinelist[j].lst_Companies.push(data);
                        break;
                      }
                    } else { // Add new lat long, new company and new machine
                      const IslatitudeExist = this.Machinelist.some(function (o) { return o['Latitude'] === Response[i].objChange.Latitude && o['Longitude'] === Response[i].objChange.Longitude; });
                      if (!IslatitudeExist) {
                        const lstcompany = [];
                        let data = {};
                        const dashboardMac = [];
                        dashboardMac.push(Response[i].objChange);
                        lstcompany.push({
                          CompanyName: Response[i].objChange.CompanyName,
                          IdMerchant: Response[i].objChange.IdMerchant,
                          UserRole: 'Admin',
                          lst_DashBoardMachines: dashboardMac
                        });
                        data = {
                          Latitude: Response[i].objChange.Latitude,
                          Longitude: Response[i].objChange.Longitude,
                          lst_Companies: lstcompany,
                        };
                        this.Machinelist.push(data);
                      }
                    }
                  }
                }
              }
              this.UpdateMarker();
            }

          };
          this.VendEvents.addEventListener('my_event', function (e) {
          });
          this.getCurrent_location();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }
  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
  getCurrent_location() {
    const that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        that.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        that.Pin_Point();
      },
        function (error) {
          // console.log(error);
        });
    }
  }

  Pin_Point() {
    if (this.Machinelist.length > 0) {
      this.map = new google.maps.Map(document.getElementById('map'), {
        // center: new google.maps.LatLng(55, 99),
        center: new google.maps.LatLng(this.Merchantdetail.Latitude, this.Merchantdetail.Longitude),
        zoom: 2,
      });
      //   this.bounds = new google.maps.LatLngBounds();
    } else {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: new google.maps.LatLng(this.pos.lat, this.pos.lng),
        zoom: 5,
      });
    }
    this.setMarkers();
  }

  setMarkers() {  // Create Marker
    const that = this;
    if (this.Machinelist.length > 0) {
      for (let i = 0; i < this.Machinelist.length; i++) {
        let IsDown = false;
        let IsIntermittent = false;
        let IsUp = false;
        for (let j = 0; j < this.Machinelist[i].lst_Companies.length; j++) {   // -- Down > Intermittent > Up
          for (let k = 0; k < this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines.length; k++) {
            if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines.length > 0) {
              var Lat = '';
              var Long = '';
              var Icon = '';
              if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].Status === 'Down') {
                IsDown = true;
              }
              if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].Status === 'Intermittent') {
                IsIntermittent = true;
              }
              if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].Status === 'Up') {
                IsUp = true;
              }
              Lat = this.Machinelist[i].Latitude;
              Long = this.Machinelist[i].Longitude;
              const machine = this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].IdMachine;
            }
          }
          if (IsDown === true) {
            Icon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
          } else if (IsIntermittent === true) {
            Icon = 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
          } else if (IsUp === true) {
            Icon = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
          }
        }
        this.Marker = new google.maps.Marker({
          map: that.map,
          icon: Icon,
          position: new google.maps.LatLng(Lat, Long),
          lat_m: Lat,
          long_m: Long,
        });
        this.AllMarkers.push(this.Marker);
        this.FirstInfoWindow(this.Marker, this.Machinelist);
      }
    } else {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: new google.maps.LatLng(this.Merchantdetail.Latitude, this.Merchantdetail.Longitude),
        zoom: 5,
      });
    }
  }

  UpdateMarker() { // Update the colour of marker
    const that = this;
    if (this.Machinelist.length > 0) {
      for (let i = 0; i < this.Machinelist.length; i++) {
        let IsDown = false;
        let IsIntermittent = false;
        let IsUp = false;
        for (let j = 0; j < this.Machinelist[i].lst_Companies.length; j++) {
          for (let k = 0; k < this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines.length; k++) {
            var Lat = '';
            var Long = '';
            var Icon = '';
            if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines.length > 0) {   // -- Down > Intermittent > Up

              if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].Status === 'Down') {
                IsDown = true;
              }
              if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].Status === 'Intermittent') {
                IsIntermittent = true;
              }
              if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].Status === 'Up') {
                IsUp = true;
              }
              Lat = this.Machinelist[i].Latitude;
              Long = this.Machinelist[i].Longitude;
            }
          }
          if (IsDown === true) {
            Icon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
          } else if (IsIntermittent === true) {
            Icon = 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
          } else if (IsUp === true) {
            Icon = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
          }
        }

        this.Marker = new google.maps.Marker({
          map: that.map,
          icon: Icon,
          position: new google.maps.LatLng(Lat, Long),
          lat_m: Lat,
          long_m: Long
        });
        this.AllMarkers.push(this.Marker);
        this.FirstInfoWindow(this.Marker, this.Machinelist);
      }
    } else {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: new google.maps.LatLng(this.Merchantdetail.Latitude, this.Merchantdetail.Longitude),
        zoom: 5,
      });
    }
  }

  FirstInfoWindow(marker, machine) {
    const that = this;
    marker.addListener('click', function (event) {
      if (that.activeInfoWindow || that.activeInfoWindow2) {
        if (that.activeInfoWindow && that.activeInfoWindow2) {
          that.activeInfoWindow.close();
          that.activeInfoWindow2.close();
        } else if (that.activeInfoWindow) {
          that.activeInfoWindow.close();
        } else {
          that.activeInfoWindow2.close();
        }
      }
      that.show_result = [];
      for (let i = 0; i < machine.length; i++) {
        if (this.lat_m === machine[i].Latitude && this.long_m === machine[i].Longitude) {
          that.show_result.push(machine[i]);
        }
      }
      for (let j = 0; j < that.show_result.length; j++) {
        that.picker_lat = that.show_result[0].Latitude;
        that.picker_long = that.show_result[0].Longitude;
        for (let k = 0; k < that.show_result[0].lst_Companies.length; k++) {
          if (that.show_result[j].lst_Companies.length > 1) {   // multiple company on one Location
            this.boxText = document.createElement('div');
            this.boxText.setAttribute('id', 'info-header');
            for (let j = 0; j < that.show_result.length; j++) {
              for (let k = 0; k < that.show_result[j].lst_Companies.length; k++) {
                if (that.show_result[j].lst_Companies[k].lst_DashBoardMachines.length > 0) {
                  this.boxText.innerHTML = this.boxText.innerHTML + '<span>' + that.show_result[j].lst_Companies[k].CompanyName + '</span>';
                }
                for (let l = 0; l < that.show_result[j].lst_Companies[k].lst_DashBoardMachines.length; l++) {
                  this.boxText.innerHTML = this.boxText.innerHTML + '<div id=' + that.show_result[j].lst_Companies[k].lst_DashBoardMachines[l].IdMachine + ' class="pickerMap ' + that.show_result[j].lst_Companies[k].lst_DashBoardMachines[l].Status + '"><h5 id=MS' + that.show_result[j].lst_Companies[k].lst_DashBoardMachines[l].IdMachine + ' class="info_machineserial" title=' + that.show_result[j].lst_Companies[k].CompanyName.replace(/[ ]/g, '\u00a0') + '\u00a0-\u00a0' + that.show_result[j].lst_Companies[k].lst_DashBoardMachines[l].LocationName.replace(/[ ]/g, '\u00a0') + '>' + that.show_result[j].lst_Companies[k].lst_DashBoardMachines[l].MachineSerial + '</h5></div>';
                }
              }
            }
            const infowindow = new google.maps.InfoWindow({
              content: this.boxText,
              maxWidth: 400
            });
            infowindow.open(that.map, marker);
            that.activeInfoWindow = infowindow;
            google.maps.event.addListener(that.map, 'click', function () {
              infowindow.close();
              that.picker_lat = '';
              that.picker_long = '';
            });
            google.maps.event.addListener(infowindow, 'domready', function () {
              $('<div/>', { class: 'info-window-header', text: that.show_result[0].lst_Companies[0].lst_DashBoardMachines[0].LocationName }).appendTo('#info-header');
              $('<div/>', { class: 'arrow_down' }).appendTo('#info-header');
              for (let k = 0; k < that.show_result[0].lst_Companies.length; k++) {
                for (let l = 0; l < that.show_result[0].lst_Companies[k].lst_DashBoardMachines.length; l++) {
                  that.elementRef.nativeElement.querySelector('#MS' + that.show_result[0].lst_Companies[k].lst_DashBoardMachines[l].IdMachine).addEventListener('click', function (event) {
                    $('.selected').removeClass('selected');
                    $('#MS' + that.show_result[0].lst_Companies[k].lst_DashBoardMachines[l].IdMachine).addClass('selected');
                    if (that.activeInfoWindow2) {
                      that.activeInfoWindow2.close();
                    }
                    const id = event.target.id.substring(2);
                    that.Second_infowindow(id, marker, 130, -50);
                  });
                }
              }
            });
          } else {   // multiple machine in one company on one Location
            if (that.show_result[0].lst_Companies[k].lst_DashBoardMachines.length > 1) {
              this.boxText = document.createElement('div');
              this.boxText.setAttribute('id', 'info-header');
              for (let j = 0; j < that.show_result.length; j++) {
                for (let k = 0; k < that.show_result[j].lst_Companies.length; k++) {
                  if (that.show_result[j].lst_Companies[k].lst_DashBoardMachines.length > 0) {
                    this.boxText.innerHTML = this.boxText.innerHTML + '<span>' + that.show_result[j].lst_Companies[k].CompanyName + '</span>';
                  }
                  for (let l = 0; l < that.show_result[j].lst_Companies[k].lst_DashBoardMachines.length; l++) {
                    this.boxText.innerHTML = this.boxText.innerHTML + '<div id=' + that.show_result[j].lst_Companies[k].lst_DashBoardMachines[l].IdMachine + ' class="pickerMap ' + that.show_result[j].lst_Companies[k].lst_DashBoardMachines[l].Status + '"><h5 id=MS' + that.show_result[j].lst_Companies[k].lst_DashBoardMachines[l].IdMachine + ' class="info_machineserial" title=' + that.show_result[j].lst_Companies[k].CompanyName.replace(/[ ]/g, '\u00a0') + '\u00a0-\u00a0' + that.show_result[j].lst_Companies[k].lst_DashBoardMachines[l].LocationName.replace(/[ ]/g, '\u00a0') + '>' + that.show_result[j].lst_Companies[k].lst_DashBoardMachines[l].MachineSerial + '</h5></div>';
                  }
                }
              }
              const infowindow = new google.maps.InfoWindow({
                content: this.boxText,
                maxWidth: 400
              });
              infowindow.open(that.map, marker);
              that.activeInfoWindow = infowindow;
              google.maps.event.addListener(that.map, 'click', function () {
                infowindow.close();
                that.picker_lat = '';
                that.picker_long = '';
              });
              google.maps.event.addListener(infowindow, 'domready', function () {
                $('<div/>', { class: 'info-window-header', text: that.show_result[0].lst_Companies[0].lst_DashBoardMachines[0].LocationName }).appendTo('#info-header');
                $('<div/>', { class: 'arrow_down' }).appendTo('#info-header');
                for (let k = 0; k < that.show_result[0].lst_Companies.length; k++) {
                  for (let l = 0; l < that.show_result[0].lst_Companies[k].lst_DashBoardMachines.length; l++) {
                    that.elementRef.nativeElement.querySelector('#MS' + that.show_result[0].lst_Companies[k].lst_DashBoardMachines[l].IdMachine).addEventListener('click', function (event) {
                      $('.selected').removeClass('selected');
                      $('#MS' + that.show_result[0].lst_Companies[k].lst_DashBoardMachines[l].IdMachine).addClass('selected');
                      if (that.activeInfoWindow2) {
                        that.activeInfoWindow2.close();
                      }
                      const id = event.target.id.substring(2);
                      that.Second_infowindow(id, marker, 130, -50);
                    });
                  }
                }
              });
            } else {
              if (that.activeInfoWindow || that.activeInfoWindow2) {
                if (that.activeInfoWindow && that.activeInfoWindow2) {
                  that.activeInfoWindow.close();
                  that.activeInfoWindow2.close();
                } else if (that.activeInfoWindow) {
                  that.activeInfoWindow.close();
                } else {
                  that.activeInfoWindow2.close();
                }
              }
              that.Second_infowindow(that.show_result[0].lst_Companies[0].lst_DashBoardMachines[0].IdMachine, marker, 0, 0);
            }
          }
        }
      }
    });
  }

  Second_infowindow(id, marker, sizex, sizey) {  // Machine detail popup
    const that = this;
    this.detail_id = id;
    that.http.post<any>(that.apiList.MachineDetail, { IdMachine: id }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        const Details = response;
        if (Details.Response === 1) {
          this.MachineStatus = Details.obj_MachineDetail.MachineStatus;
          this.IdMerchant = Details.obj_MachineDetail.IdMerchant;
          sessionStorage.setItem('rediect', Details.obj_MachineDetail.IdMerchant);
          sessionStorage.setItem('Inventoryuserole', Details.obj_MachineDetail.UserRole);   // for machine inventory
          sessionStorage.setItem('referMerchantId', Details.obj_MachineDetail.IdMerchant);
          sessionStorage.setItem('logocompanyName', Details.obj_MachineDetail.CompanyName);
          that.CompanyName = Details.obj_MachineDetail.CompanyName.replace(/\s/g, '');
          sessionStorage.setItem('companyName', that.CompanyName);
          sessionStorage.setItem('refrencemachineid', Details.obj_MachineDetail.IdMachine);
          sessionStorage.setItem('referMachinetype', 'Master');
          sessionStorage.setItem('referProductType', null);
          this.globalservice.getMerchantDetail(Details.obj_MachineDetail.IdMerchant);
        }

        $(document).on('click', '#idmachine', (e) => {
          this.globalservice.gpsImpersonate(this.IdMerchant, true).subscribe(res => {
            sessionStorage.setItem('Login', this.IdMerchant);
            this.router.navigate(['/' + that.CompanyName + '/dashboard/machine', {
              code: Details.obj_MachineDetail.LinkCode
            }]);
          });
        });

        $(document).on('click', '#idp', () => {
          this.globalservice.gpsImpersonate(this.IdMerchant, true).subscribe(res => {
            sessionStorage.setItem('Login', this.IdMerchant);
            this.router.navigate(['/' + that.CompanyName + '/dashboard/placeaMachine/Planogramtraymotor', { l_c: Details.obj_MachineDetail.LinkCode, c_m: Details.obj_MachineDetail.Machine, I_l: Details.obj_MachineDetail.IdLocation ,id:id,idplace:Details.obj_MachineDetail.IdPlaceMachine}]);
          });
        });


        $(document).on('click', '#idmap', () => {
          this.globalservice.gpsImpersonate(this.IdMerchant, true).subscribe(res => {
            sessionStorage.setItem('Login', this.IdMerchant);
            this.router.navigate(['/' + that.CompanyName + '/dashboard/Map']);
          });
        });


        if (sizex === 0 && sizey === 0) {   // -- When open only machine detail popup
          var contentString1 =
            '<div class="info-window" id="secondmarker" closeLocation>' +
            '<div class="info-window-header">' + Details.obj_MachineDetail.LocationName + ' </div>' +
            '<p> <strong> ' + Details.obj_MachineDetail.CompanyName + '</strong></p>' +
            '<h5 class="single_machine"><span  id="st" class="' + this.MachineStatus + '" ></span>' + Details.obj_MachineDetail.MachineSerial + '<span id="up" class="badge badge-primary">' + Details.obj_MachineDetail.UpTime + '</span></h5>' +
            '<a id="idmachine" class="btn btn-xs" href="javascript:void(0)">Go to Machine</a>' +
            '<a id="idp" class="btn btn-xs" href="javascript:void(0)">Go to Planogram</a>' +
            '<a id="idmap" class="btn btn-xs" href="javascript:void(0)">Go to Dashboard</a>' +
            '<div class="arrow_down"></div>' +
            '</div>';
        } else if (sizex === 130 && sizey === -50) {  // -- When we click on machine serial
          var contentString1 =
            '<div class="info-window" id="secondmarker" closeLocation>' +
            '<div class="info-window-header">' + Details.obj_MachineDetail.LocationName + ' </div>' +
            '<p> <strong> ' + Details.obj_MachineDetail.CompanyName + '</strong></p>' +
            '<h5 class="single_machine"><span id="st" class="' + this.MachineStatus + '" ></span>' + Details.obj_MachineDetail.MachineSerial + '<span id="up" class="badge badge-primary">' + Details.obj_MachineDetail.UpTime + '</span></h5>' +
            '<a id="idmachine" class="btn btn-xs" href="javascript:void(0)">Go to Machine</a>' +
            '<a id="idp" class="btn btn-xs" href="javascript:void(0)">Go to Planogram</a>' +
            '<a id="idmap" class="btn btn-xs" href="javascript:void(0)">Go to Dashboard</a>' +
            '</div>';
        }
        const myoptions = {
          content: contentString1,
          maxWidth: 400
          , pixelOffset: new google.maps.Size(sizex, sizey)
          , zIndex: 99999
        };
        const infowindow2 = new google.maps.InfoWindow(myoptions);
        infowindow2.open(that.map, marker);
        that.activeInfoWindow2 = infowindow2;
        google.maps.event.addListener(that.map, 'click', function () {
          infowindow2.close();
        });
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }
  ngOnDestroy() {
    if (this.VendEvents) {
      this.VendEvents.close();
    }
  }
}



