import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { NgForm } from "@angular/forms";
import { MatInput } from '@angular/material';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
import { Subject } from 'rxjs';
declare const $;

@Component({
  selector: 'app-specialpricing',
  templateUrl: './specialpricing.component.html',
  styleUrls: ['./specialpricing.component.css']
})
export class SpecialpricingComponent implements OnInit {
  @ViewChild('machineproduct',{static: false}) public SpecialPriceForm: NgForm;
  @ViewChild('input1', { read: MatInput, static: false }) input1: MatInput;
  @ViewChild('input2', { read: MatInput, static: false }) input2: MatInput;

  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));

  posts: any;
  IdSpecialPricing: any;
  p: number = 1;
  AlertText;
  screenHeight: any;
  before: number;
  pageno: number = 1;
  Search: any;
  SpecialPricingRule: any;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate


  constructor(private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private title: Title,
    private globalservice: GlobalService,
    public apiList: ApilistService) {

  }

  ngOnInit() {
    this.HidePopups();
    this.title.setTitle(this.title.getTitle() + this.router.url);
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
    this.specialpricelist();
    this.route.params.subscribe((get) => {
      this.SpecialPricingRule = get.rule;
      this.Search = this.SpecialPricingRule;
    });
  }

  HidePopups(){
    $('#alert_div').hide();
    $('#dots_spinner').hide();
  }

  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
//console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  Addspecialpricing() {
    this.router.navigate([this.companyName + '/dashboard/Specialpricing/Addspecialpricing']);
  }


  specialpricelist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.SPLstByTypeUrl, { IdMerchant: this.IdMerchant, PageNumber:this.pageno, NumberOfRows :this.before },this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.posts = response;
      this.posts=this.posts.lst_SpecialPricing
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide();
       if (this.Resizing) {
        // need to give time to render behind the scenes before size/resize rows
        this.SettleAndAutosize();
      } });
  }

  delete(id) {
    $('#dots_spinner').show();
    this.IdSpecialPricing = id
    this.http.post<any>(this.apiList.DeleteSPUrl, { SpecialPricingRule: this.IdSpecialPricing },this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.FlashError("Successfully deleted");
        this.specialpricelist();
        this.FlashError('Deleted successfully');
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  edit(rule) {
  //  console.log(rule)
    this.router.navigate([this.companyName + '/dashboard/Specialpricing/Updatespecialpricing', {
      id: rule,
    }],
      // { skipLocationChange: true }
    );
  }


  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  sortData(sort: Sort) {
    const data = this.posts.slice();
    if (!sort.active || sort.direction === '') {
      this.posts = data;
      return;
    }
    this.posts = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        // case 'productname': return this.compare(a.ProductName, b.ProductName, isAsc);
        case 'discount': return this.compare(a.DiscountAmount, b.DiscountAmount, isAsc);
        case 'startdate': return this.compare(a.StartDate, b.StartDate, isAsc);
        case 'enddate': return this.compare(a.EndDate, b.EndDate, isAsc);
        // case 'machineserial': return this.compare(a.MachineSerial, b.MachineSerial, isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }

  clearSearch() {
    this.Search = '';
    this.specialpricelist();
  }
}
