import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
declare const $;

import { Observable } from 'rxjs/Observable';
import { startWith } from 'rxjs/operators/startWith';
import { map } from 'rxjs/operators/map';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';

@Component({
  selector: 'app-machinesetting',
  templateUrl: './machinesetting.component.html',
  styleUrls: ['./machinesetting.component.css']
})

export class MachinesettingComponent implements OnInit, OnDestroy {
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");


  ShoppingGridRows: number;
  AlertText: string;
  IdMachine: any;
  MaxCartSize: any;
  MachineSerial: any;
  ShoppingGridColumns: any;
  IsOfferReceiptEmail: boolean;
  IsSMTPConfigured: boolean;
  TimeInSecond: any;
  Tposts: any;
  reqParam: any;
  InactivityPopUp: any;
  IsAgeCheckRequire: Boolean;
  IsAllowManualDOB: Boolean;
  IsPerformFaceMatch: Boolean;
  IsRequireFaceMatch: Boolean;
  ProductDeliveryArrowAngle: number;
  IsClickThroughRequired: Boolean;
  ScanIdForBirthDate = "Never"
  EligibilityAge: number;
  isAdding = false;
  bit: any = false;
  ShowTouchlessQRCode:boolean;

  stateCtrl: FormControl;
  filteredStates: Observable<any[]>;
  question = 'Would you like to add age "';
  states: string[] = [
    '18',
    '21',
  ];
  public FacialVisible: any = false;
  public AgeVerificationVisible: any = true;

  checkoutType=1;

  @ViewChild('machinesetting', { static: false }) public machinesetting: NgForm
  details: any;

  constructor(public dialog: MatDialog, public apiList: ApilistService, private route: ActivatedRoute, private router: Router, private http: HttpClient, private globalservice: GlobalService,) {
    this.route.params.subscribe(params => {
      this.reqParam = JSON.parse(params['data']);
       console.log("reqParam"+JSON.stringify(this.reqParam));
      this.InactivityPopUp = this.reqParam.InactivityPopUp;
      this.TimeInSecond = this.InactivityPopUp.toString();
      this.IdMachine = this.reqParam.IdMachine;
      this.MachineSerial = this.reqParam.MachineSerial;
      this.IsOfferReceiptEmail = JSON.parse(this.reqParam.IsOfferReceiptEmail);
      this.IsSMTPConfigured = JSON.parse(this.reqParam.IsSMTPConfigured);
      this.MaxCartSize = this.reqParam.MaxCartSize;
      this.ShoppingGridRows = this.reqParam.ShoppingGridRows;
      this.ShoppingGridColumns = this.reqParam.ShoppingGridColumns;
      this.IsAgeCheckRequire = this.reqParam.IsRequireAgeCheck;
      this.IsAllowManualDOB = this.reqParam.IsAllowManualDOB;
      this.IsPerformFaceMatch = this.reqParam.IsPerformFaceMatch;
      this.IsRequireFaceMatch = this.reqParam.IsRequireFaceMatch;
      this.ProductDeliveryArrowAngle = this.reqParam.ProductDeliveryArrowAngle;
      this.IsClickThroughRequired = this.reqParam.IsClickThroughRequired;
      this.ScanIdForBirthDate = this.reqParam.ScanIdForBirthDate;

      this.checkoutType=this.reqParam.CheckoutType;
      // console.log(this.ScanIdForBirthDate)
      this.EligibilityAge = this.reqParam.EligibilityAge;
      this.ShowTouchlessQRCode=this.reqParam.ShowTouchlessQRCode;
      console.log("this.showtouchlessqrcode"+this.ShowTouchlessQRCode);
      this.stateCtrl = new FormControl();
      this.setAgeInputFieldStatus();

      this.filteredStates = this.stateCtrl.valueChanges
        .pipe(
          startWith(''),
          map(state => state ? this.filterStates(state) : this.states.slice())
        );


      // set visibility
      this.SetOptionVisibility();
    });


  }

  filterStates(name: string) {
    let results = this.states.filter(state =>
      state.indexOf(name) === 0);

    if (results.length < 1) {
      results = [this.question + name + '"?'];
    }

    return results;
  }
  optionSelected(option) {
    // console.log('optionSelected:', option.value);
    if (option.value.indexOf(this.question) === 0) {
      let newState = option.value.substring(this.question.length).split('"?')[0];
      this.states.push(newState);
      this.stateCtrl.setValue(newState);
    }
  }

  enter() {
    const value = this.stateCtrl.value;
    if (!this.states.some(entry => entry === value)) {
      this.states.push(value);
    }
    setTimeout(() => this.stateCtrl.setValue(value));
  }

  ngOnInit() {
    this.globalservice.setDiscard_check();
    this.HidePopups()
  }

  HidePopups() {
    $("#dots_spinner").hide();
    $("#alert_div").hide();
  }

  setAgeInputFieldStatus() {
    if (this.IsAgeCheckRequire) {
      this.stateCtrl.enable();
    }
    else {
      this.stateCtrl.setValue(null);
      this.stateCtrl.disable();
    }
  }

  onValChange(value) {
    if (this.IsSMTPConfigured) {
      this.IsOfferReceiptEmail = value.checked
    }
    this.machinesetting.control.markAsDirty();
  }

  onValChangeAge(value) {
    this.IsAgeCheckRequire = value.checked;
    this.machinesetting.control.markAsDirty();
     this.setAgeInputFieldStatus();
  }


  onValChangeQr(value) {
    this.ShowTouchlessQRCode = value.checked;
    this.machinesetting.control.markAsDirty();
    console.log("this.ShowTouchlessQRCode"+this.ShowTouchlessQRCode);
    //this.setAgeInputFieldStatus();
  }

  

  onValChangeDOB(value) {
    this.IsAllowManualDOB = value.checked;
    this.machinesetting.control.markAsDirty();
  }

  scanIdChange() {
    this.machinesetting.control.markAsDirty();
  }

  //checkouttypeChange
  checkouttypeChange() {

    this.machinesetting.control.markAsDirty();

  }

  changeEligibilityAge() {
    this.machinesetting.control.markAsDirty();
  }

  onValChangePerformFace(value) {
    this.IsPerformFaceMatch = value.checked;
    this.machinesetting.control.markAsDirty();
  }

  onValChangeFace(value) {
    this.IsRequireFaceMatch = value.checked;
    this.machinesetting.control.markAsDirty();
  }
  onValChangeTC(value) {
    this.IsClickThroughRequired = value.checked
    this.machinesetting.control.markAsDirty();
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/machine', {
    }]);
  }

  submit() {
    this.isAdding = true;
    if (this.ShoppingGridRows > 6 || this.ShoppingGridColumns > 6) {
      this.FlashError('Grid rows And coloumns should be less than 6')
    } else if (this.ProductDeliveryArrowAngle > 360 || this.ProductDeliveryArrowAngle < 0) {
      this.FlashError('Delivery Arrow angle must be between 0 and 360 degrees')
    } else if (this.IsAgeCheckRequire && ((this.EligibilityAge === null) || (this.EligibilityAge > 200 || this.EligibilityAge < 0))) {
      this.FlashError('Eligibility Age must be between 0 and 200')
    } else {
      this.machinesetting.control.markAsPristine();
      this.machinesetting.control.markAsUntouched();
      $('#dots_spinner').show();
      this.http.post<any>(this.apiList.UpdateMachineSettings, {
        IsAgeCheckRequire: this.IsAgeCheckRequire,
        IsAllowManualDOB: this.IsAllowManualDOB,
        IsPerformFaceMatch: this.IsPerformFaceMatch,
        IsRequireFaceMatch: this.IsRequireFaceMatch,
        IsOfferReceiptEmail: this.IsOfferReceiptEmail,
        IdMachine: this.IdMachine,
        CheckoutType:this.checkoutType,
        MaxCartSize: this.MaxCartSize,
        ShoppingGridRows: this.ShoppingGridRows,
        ShoppingGridCols: this.ShoppingGridColumns,
        ProductDeliveryArrowAngle: this.ProductDeliveryArrowAngle,
        IsClickThroughRequired: this.IsClickThroughRequired,
        ShowTouchlessQRCode:this.ShowTouchlessQRCode,
        EligibilityAge: this.IsAgeCheckRequire ? this.EligibilityAge : null, ScanIdForBirthDate: this.ScanIdForBirthDate
      }, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (result.Response !== 1) throw result;
          return result;
        })
        .subscribe((response) => {
          if (response.Response === 1) {
            this.FlashError('Kiosk Settings Saved');
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.MaxCartSize = '';
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
        });
      this.http.post<any>(this.apiList.TimeUrl,
        {
          IdMachine: this.IdMachine,
          TimeInSecond: this.TimeInSecond,
          IdMerchant: this.IdMerchant
        }
        , this.globalservice.Headers())
        .subscribe((response) => {
          this.Tposts = response;
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.MaxCartSize = '';
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }, () => {
          $('#dots_spinner').hide();
        });
    }
  }

  SetOptionVisibility() {
    this.http.post<any>(this.apiList.AllPermissionsWithMerchant, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
    .map(response => {
      if (!response) {
        throw { Response: 0, ErrorMessage: "Empty or null response" };
      }
      if (response.Response !== 1) {
        throw response;
      }
      return response;
    })
    .timeout(30000)
    .retry(2)
    .take(1)
    .subscribe(response => {

      this.details = response;

      // merge permissions into variables for display
      let perms = this.details.Permissions;

      for (let i = 0; i < perms.length; i++) {

        // if (perms[i].name == "Machine Section") {
        //   this.MachineVisible = perms[i].State;
        // }
        // if (perms[i].name == "Product Category Section") {
        //   this.CategoryVisible = perms[i].State;
        // }

        // if (perms[i].name == "Product Section") {
        //   this.ProductVisible = perms[i].State;
        // }

        // if (perms[i].name == "Location Section") {
        //   this.LocationVisible = perms[i].State;
        // }

        // if (perms[i].name == "Place Machine Section") {
        //   this.PlaceMachineVisible = perms[i].State;
        // }

        // if (perms[i].name == "Users Section") {
        //   this.UsersVisible = perms[i].State;
        // }

        // if (perms[i].name == "Coupon Section") {
        //   this.CouponVisible = perms[i].State;
        // }

        // if (perms[i].name == "Special Pricing Section") {
        //   this.SpecialPricingVisible = perms[i].State;
        // }

        // if (perms[i].name == "Digital Signage Section") {
        //   this.DigitalSinageVisible = perms[i].State;
        // }
        // if (perms[i].name == "Report Section") {
        //   this.ReportVisible = perms[i].State;
        // }

        // if (perms[i].name == "UI Section") {
        //   this.UIVisible = perms[i].State;
        // }

        if (perms[i].name == "Facial Recognition") {
          this.FacialVisible = !!perms[i].State;
        }

        if (perms[i].name == "Age Verification") {
          this.AgeVerificationVisible = !!perms[i].State;
        }

        // if (perms[i].name == "Background Theme") {
        //   this.ThemeVisible = perms[i].State;
        // }

        // if (perms[i].name == "Company Logo") {
        //   this.LogoVisible = perms[i].State;
        // }
        // if (perms[i].name == "Inventory Management") {
        //   this.InventoryManagementVisible = perms[i].State;
        // }

        // if (perms[i].name == "Dashboard") {
        //   this.dashboardVisible = perms[i].State;
        // }
        // if (perms[i].name == "Global Settings") {
        //   this.GlobalSettingVisible = perms[i].State;
        // }

        // if (perms[i].name == "Brand Management") {
        //   this.BrandUserVisible = perms[i].State;
        // }

        // if (perms[i].name == "Alerts") {
        //   this.Alerts = perms[i].State;
        // }

        // if (perms[i].name == "Sampling Report") {
        //   this.Samplingvisible = perms[i].State;
        // }

      }
    }, (err: any) => {
      const Error = this.globalservice.ProcessError(err);
      if (Error.Response === 35) {
        if (Error.ErrorMessage) {
          this.FlashError(Error.ErrorMessage);
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
        return;
      } else {
        this.FlashError(Error.ErrorMessage);
        return;
      }
    }).add(() => {
    });

  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  onChange(value) {
    this.TimeInSecond = value;
    this.machinesetting.control.markAsDirty();
  }

  canDeactivate(): any {
    if (!this.isAdding && this.machinesetting.dirty) {
      if (this.bit == false && this.globalservice.GetDiscard_Check()) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });

        return dialogRef.afterClosed().subscribe(result => {
          // console.log(result)
          // console.log( result.response)
          if (result && (result.response == "ok")) {
            this.dialog.closeAll();
            this.bit = true;
            this.router.navigate([this.companyName + '/dashboard/machine'])
          }
        });
      }
    } else if (this.machinesetting.dirty) {
      if (this.bit == false) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });

        return dialogRef.afterClosed().subscribe(result => {
          // console.log(result)
          // console.log( result.response)
          if (result && (result.response == "ok")) {
            this.dialog.closeAll();
            this.bit = true;
            this.router.navigate([this.companyName + '/dashboard/machine'])
          }
        });
      }
    }
    return true;
  }
  ngOnDestroy() {
    this.dialog.ngOnDestroy();
  }

  onChangeCheckout(checkoutSelected) {
    
    this.checkoutType=checkoutSelected;
  }
}

