import { Component, OnInit, HostListener, ElementRef, Inject } from '@angular/core';
import { UserIdleService } from "angular-user-idle";
import { NavigationEnd, Router } from '../../node_modules/@angular/router';
import { GlobalService } from './global.service';
import { ApilistService } from '../service/Api/apilist.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { UpdateVersionService } from './update-version.service';
import { FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
declare const $;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  AlertText: string;
  AlertTextt: string;
  timeout;
  viewIs;
  fullUrl: string;
  darkTheme = new FormControl(false);
  constructor(public apiList: ApilistService,
    private http: HttpClient,
    public userIdle: UserIdleService,
    private eRef: ElementRef,
    private router: Router,
    private globalservice: GlobalService,
    //private updates: SwUpdate,
    public UpService: UpdateVersionService) {

//this.functionskin();
    
      router.events.filter(event => event instanceof 
      NavigationEnd).subscribe((val) => {
      this.fullUrl = window.location.href;
      this.viewIs = window.location.href;
      var lastSlash = window.location.href.lastIndexOf('/');
      this.viewIs = this.viewIs.substr(lastSlash + 1);
      if (this.viewIs == 'login') {
        this.globalservice.setThanksBtnStatus(false);
        //get tenant skin on login screen
        this.globalservice.getTenantSkin();
      }
      else {
        this.globalservice.setThanksBtnStatus(true);
      }

    });


    //this.UpService.checkForUpdates();
  } 

  ngOnInit() {
    //console.log("2020-09-08 1:17pm");
    $('#dots_spinner').hide();
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((response) => {
      //  console.log(response);
    });
    // Start watch when time is up.
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      // console.log('Time is up!')
      this.globalservice.stopNopInterval();
      this.AlertText = ""
      this.AlertText = "Your session is inactive. Remain logged in"
      $("#Idle_popup").modal('show');
      this.timeout = setTimeout(() => {
        $('#Idle_popup').modal('hide');
        this.userIdle.stopTimer();
        this.userIdle.stopWatching();
        $('#myModal').modal('hide');
        this.globalservice.setTimeoutFlag();
        this.globalservice.Logout('No');
        this.router.navigate(['/login']);
      }, 1000 * 15);
    });
  }

  @HostListener('document:mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    this.userIdle.resetTimer();
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.userIdle.resetTimer();
  }

  @HostListener('document:mouseover') onHover() {
    this.userIdle.resetTimer();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 67) {
      //console.log('CTRL + C');
      this.userIdle.resetTimer();
    }
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 65) {
      //console.log('CTRL');
      this.userIdle.resetTimer();
    }
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 86) {
      // console.log('CTRL +  V');
      this.userIdle.resetTimer();
    }


  }
  @HostListener("document:keyup", ["$event"])
  handleKeyup(event: KeyboardEvent) {
    if (event.keyCode === 8) {
      this.userIdle.resetTimer();
    }
  }

  // @HostListener('document:touch', ['$event'])
  // clickout(event) {
  //   if (this.eRef.nativeElement.contains(event.target)) {
  //     //console.log("clicked inside");
  //   } else {
  //     //console.log("clicked outside") ;
  //   }
  //   this.userIdle.resetTimer();
  // }

  Yes() {
    $("#Idle_popup").modal('hide');
    this.globalservice.NoOperation();
    this.userIdle.resetTimer();
    clearTimeout(this.timeout);
    this.globalservice.startNopInterval();
  }
  Cancel() {
    clearTimeout(this.timeout);
    $("#Idle_popup").modal('hide');
    this.globalservice.setTimeoutFlag();
    this.globalservice.Logout('No')
    this.router.navigate(['/login']);
  }
  hidepops() {
    $('#alert_div').hide();
    $('#dots_spinner').hide();
  }

}


