import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Observable } from 'rxjs/Rx';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
declare const $;
declare const tinymce;
import * as moment from 'moment';
import { UserIdleService } from 'angular-user-idle';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';

@Component({
  selector: 'app-special-message',
  templateUrl: './special-message.component.html',
  styleUrls: ['./special-message.component.css']
})
export class SpecialMessageComponent implements OnInit {
  @ViewChild('couponForm', { static: false }) public couponForm: NgForm

  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");
  Date: any;
  mlists: any;
  AlertText: any
  specialMessage = {
    SpecialMessage: '',
    StartDate: '',
    EndDate: '',
    Content: ''
  }
  MachineIds
  Id: any;
  array: any[];
  machineID = [];
  api
  tagShow
  editor:any
  isAdding = false;
  bit: any = false;

  constructor(private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public apiList: ApilistService,
    public route: ActivatedRoute,
    public userIdle: UserIdleService,
    public dialog: MatDialog ) {
      
    this.route.params.subscribe((get) => {
      let page = get.add
      if (page == 1) {
        this.Id = get.id;
        this.getDetail()
      }
      this.api = page == 1 ? this.apiList.EditSpecialMessage : this.apiList.CreateSpecialMessage;
      this.tagShow = page == 1 ? 'Edit' : 'Add'
    })
    
  }

     canDeactivate():any { 
        if (!this.isAdding && this.couponForm.dirty) {
            if(this.bit == false && this.globalservice.GetDiscard_Check()){
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });
                
              return dialogRef.afterClosed().subscribe(result => {
                // console.log(result)
                // console.log( result.response)
                    if (result && (result.response == "ok")) {
                        this.dialog.closeAll();
                        this.bit = true;
                        this.router.navigate([this.companyName + '/dashboard/setting/specialMessage']);
                    }
                });
            }
        } else if (!this.isAdding && this.couponForm.dirty) {
            if(this.bit == false && this.globalservice.GetDiscard_Check()){
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });
                
              return dialogRef.afterClosed().subscribe(result => {
                // console.log(result)
                // console.log( result.response)
                    if (result && (result.response == "ok")) {
                        this.dialog.closeAll();
                        this.bit = true;
                        this.router.navigate([this.companyName + '/dashboard/setting/specialMessage']);
                    }
                });
            }
        }
        return true; 
    }


  ngOnInit() {
    var that = this
    this.userIdle.startWatching();
    this.globalservice.setDiscard_check();

    tinymce.init({
      selector: '#mytextareaSPL',
      resize: true,
      width: '100%',
      min_height: 300,
      plugins: 'lists advlist code',
      toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | code',
      setup:(ed) => {
        ed.on('keyup', function(e) {
        that.couponForm.control.markAsTouched();
        that.couponForm.control.markAsDirty();
        that.userIdle.resetTimer();
        });
      }
    });

    this.Date = new Date();
    $("#alert_div").hide();
    $('#dots_spinner').hide();
    this.listofmachine()
    //   this.getDetail();
  }


  listofmachine() {
    if (this.IdMerchant) {
     // $('#dots_spinner').show();
      this.http.post<any>(this.apiList.PlaceMachineLstUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(resp => {
      if (resp == null || resp.Response == null) resp = { Response: 0, ErrorMessage: "Empty response" };
      resp.Response = +resp.Response;
      if (resp.Response != 1) throw resp;
      return resp;
    })
    .subscribe((response: any) => {
      //  $('#dots_spinner').hide();
        if (response.Response == 1) {
          this.mlists = response.lst_sp_GetPlacedMachinesWithLocation;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
    }
  }


  getDetail() {

    let data = {
      
      SpecialMessageId: this.Id
    }
    this.http.post<any>(this.apiList.GetSpecialMessageDetailById, data, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(resp => {
      if (resp == null || resp.Response == null) resp = { Response: 0, ErrorMessage: "Empty response" };
      resp.Response = +resp.Response;
      if (resp.Response != 1) throw resp;
      return resp;
    })
    .subscribe((response: any) => {
     if (response.Response == 1) {
        this.specialMessage.SpecialMessage = response.obj_SpecialMessage.SpecialMessage;
        this.specialMessage.EndDate = moment(response.obj_SpecialMessage.EndDate).format('MM/DD/YYYY')
        this.specialMessage.StartDate = moment(response.obj_SpecialMessage.StartDate).format('MM/DD/YYYY');
        this.specialMessage.Content = response.obj_SpecialMessage.Content;
      tinymce.get('mytextareaSPL').setContent(response.obj_SpecialMessage.Content); 
        $('#SDate').val(this.specialMessage.StartDate );
        $('#EDate').val(this.specialMessage.EndDate);
        var array1 = []
        for (let i = 0; i < response.obj_SpecialMessage.lst_Machines.length; i++) {
          array1.push(response.obj_SpecialMessage.lst_Machines[i].MachineId)
        }
        this.machineID = array1
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }


  editSpecialMessage() {
    this.isAdding = true
    if (!this.specialMessage.StartDate || !this.specialMessage.EndDate) {
      this.FlashError("Please complete Date Information");
    }
    else {
      if (this.toTimestamp(this.specialMessage.StartDate) <= this.toTimestamp(this.specialMessage.EndDate)) {
        this.specialMessage.Content = tinymce.activeEditor.getContent({format : 'raw'});
        if(!this.specialMessage.Content){
      this.FlashError("Content is required");
        }else{
        let data = {
          SpecialMessage: this.specialMessage.SpecialMessage,
          StartDate: this.specialMessage.StartDate,
          EndDate: this.specialMessage.EndDate,
          UpdatedBy: this.IdMerchant,
          MachineIds: this.machineID,
          
          SpecialMessageId: this.Id,
          Content: this.specialMessage.Content
        }
        $('#dots_spinner').show();
        this.http.post<any>(this.api, data, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(resp => {
      if (resp == null || resp.Response == null) resp = { Response: 0, ErrorMessage: "Empty response" };
      resp.Response = +resp.Response;
      if (resp.Response != 1) throw resp;
      return resp;
    })
    .subscribe((res: any) => {
          if (res.Response == 1) {
            this.FlashError(res.ErrorMessage);
            this.router.navigate([this.companyName + '/dashboard/setting/specialMessage'])
          }
        }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
      }
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    }
  }
  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.specialMessage.StartDate = $('#SDate').val();
    //  console.log(this.StartDate);
  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    this.specialMessage.EndDate = $('#EDate').val();
    // console.log(this.EndDate);
  }
  onChangeMachine(id) {
    $('#uncheckall').removeClass("btn_active");
    $('#checkall').removeClass("btn_active");
    this.machineID = id

  }

  selectAll() {
    $('#checkall').addClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
    this.array = [];
    for (let i = 0; i < this.mlists.length; i++) {
      this.array.push(this.mlists[i].IdMachine);
    }
    this.machineID = this.array;
    // console.log(this.machineID)
    // this.MachineIds = this.machineID.toString();
    // console.log( this.MachineIds)
    //console.log(this.IdMachines);

  }

  deselectAll() {
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').addClass("btn_active");
    this.machineID = []
    // this.MachineIds = "";
    this.array = [];
  }
  goback() {
    this.router.navigate([this.companyName + '/dashboard/setting/specialMessage'])
  }

  detectChange(event){
    // console.log(event);
  }

  ngOnDestroy() {
    tinymce.remove('#mytextareaSPL');
    this.dialog.ngOnDestroy();
  }
}
