import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';


declare const $;
@Component({
  selector: 'app-createsuperadmin',
  templateUrl: './createsuperadmin.component.html',
  styleUrls: ['./createsuperadmin.component.css']
})
export class CreatesuperadminComponent implements OnInit {
  @ViewChild('Createsuperadmin', { static: false }) public CreatesuperadminForm: NgForm;


  constructor(public apiList: ApilistService, private router: Router, private http: HttpClient, private globalservice: GlobalService) { }
  //lists;
  Name;
  RequestedUserName;
  RequestedPassword;
  Search;
  AlertText;
  confirmPassword;
  iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass = false;
  type = 'password';
  iconname2 = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass2 = false;
  type2 = 'password';
  compare = true;
  nextLibAvailable = false;
  show_message;
  countrylists;
  selectedState;
  citys;
  states;
  IdCountry;
  IdState;
  selectedcountry;
  selectedcity;
  Zip;

  ngOnInit() {
    this.globalservice.getCountry().then((res: any) => {
      this.countrylists = res;
    });
    $('#alert_div').hide();
  }

  create() {
    if (this.confirmPassword === this.RequestedPassword) {
      this.http.post<any>(this.apiList.createUrl,
        {
          Name: this.Name,
          EmailId: this.RequestedUserName,
          Password: this.RequestedPassword,
          CompanyName: '',
          CompanyAddress: '',
          IdCountry: this.IdCountry,
          IdCity: this.selectedcity,
          IdState: this.IdState,
          Zip: this.Zip,
          CompanyPhoneNumber: '',
          ContactPerson: '',
          ContactPersonNumber: '',
          ContactPersonEmailId: '',
          RequestedUserName: '',
          RequestedPassword: this.RequestedPassword,
          ImagePath: '',
          UserRole: 'SuperAdmin'
        }, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(resp => {
          if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
          if (+resp.Response !== 1) { throw resp; }
          return resp;
        }).subscribe((response) => {
         // this.lists = response;
         // if (this.lists.Response === 1) {
            this.CreatesuperadminForm.reset();
            this.router.navigate(['./Superadmindashboard/Superadminlist']);
         // }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
        });
    } else {
      this.FlashError('Password and Confirm Password does not match');
    }
  }
  goback() {
    this.router.navigate(['./Superadmindashboard/Superadminlist']);
  }

  showPassword() {
    this.showPass = !this.showPass;
    if (this.showPass) {
      this.type = 'text';
      this.iconname = 'fa fa-fw fa-eye field-icon toggle-password';
    } else {
      this.type = 'password';
      this.iconname = 'fa fa-fw fa-eye-slash  field-icon toggle-password';
    }
  }

  confirm_pass() {
    this.showPass2 = !this.showPass2;
    if (this.showPass2) {
      this.type2 = 'text';
      this.iconname2 = 'fa fa-fw fa-eye field-icon toggle-password';
    } else {
      this.type2 = 'password';
      this.iconname2 = 'fa fa-fw fa-eye-slash  field-icon toggle-password';
    }
  }

  onKey(event: any) { // without type info
    this.compare = this.RequestedPassword === this.confirmPassword?true:false;
    this.show_message = this.RequestedPassword !== this.confirmPassword?'Password and Confirm Password does not match':'';
  }

  onKey1(event) {

    this.show_message = ''
    this.nextLibAvailable = this.RequestedPassword?true:false;
    this.compare = !this.RequestedPassword?false:true ;
    // if (this.RequestedPassword) {
    //   this.show_message = '';
    //   this.nextLibAvailable = true;
    // } else {
    //   this.show_message = '';
    //   this.nextLibAvailable = false;
    //   this.compare = false;
    // }
  }

  onChangeCountry(a) {
    this.IdCountry = a;
    if (typeof this.IdCountry === 'undefined') {
      alert('value undefined');
    } else {
      this.getStates();
    }
  }

  getStates() {
    $('#dots_spinner').show();

    this.http.post<any>(this.apiList.StateUrl, { IdCountry: this.IdCountry }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.states = response.lst_State;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  onChangeState(b) {
    this.IdState = b;
    if (typeof this.IdState === 'undefined') {
      alert('value undefined');
    } else {
      this.getcity();
    }

  }
  getcity() {
    $('#dots_spinner').show();

    this.http.post<any>(this.apiList.CityUrl, { IdState: this.IdState }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.citys = response.lst_City;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}
