import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DropdownSearchService {
  public CountryArrayObj: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public StateArrayObj: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public CityArrayObj: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public countryObject: Subject<any[]> = new Subject<any[]>();
  public stateObject: Subject<any[]> = new Subject<any[]>();
  public cityObject: Subject<any[]> = new Subject<any[]>();
  public searchCountryControl: FormControl = new FormControl();
  public searchStateControl: FormControl = new FormControl();
  public searchCityControl: FormControl = new FormControl();
  public countryControl: FormControl = new FormControl();
  public stateControl: FormControl = new FormControl();
  public cityControl: FormControl = new FormControl();
  countryObj: any;
  stateObj: any;
  cityObj: any;

  constructor() {
    this.countryObject.subscribe((response: any) => {
      this.countryObj = response;
    });
    this.stateObject.subscribe((response: any) => {
      this.stateObj = response;
    });
    this.cityObject.subscribe((response: any) => {
      this.cityObj = response;
    });
  }

  public countryFilter() {
    if (!this.countryObj) {
      return;
    }
    let search = this.searchCountryControl.value;
    if (!search) {
      this.CountryArrayObj.next(this.countryObj.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.CountryArrayObj.next(
      this.countryObj.filter(item => item.CountryName.toLowerCase().indexOf(search) > -1)
    );
  }
  public stateFilter() {
    if (!this.stateObj) {
      return;
    }
    let search = this.searchStateControl.value;
    if (!search) {
      this.StateArrayObj.next(this.stateObj.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.StateArrayObj.next(
      this.stateObj.filter(item => item.StateName.toLowerCase().indexOf(search) > -1)
    );
  }
  public cityFilter() {
    if (!this.cityObj) {
      return;
    }
    let search = this.searchCityControl.value;
    if (!search) {
      this.CityArrayObj.next(this.cityObj.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.CityArrayObj.next(
      this.cityObj.filter(item => item.CityName.toLowerCase().indexOf(search) > -1)
    );
  }
}
