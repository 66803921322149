import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';

import { ApilistService } from '../../../service/Api/apilist.service';
import { GlobalService } from '../../global.service';
import { Subject } from 'rxjs';
declare const $;
@Component({
  selector: 'app-clientlist',
  templateUrl: './clientlist.component.html',
  styleUrls: ['./clientlist.component.css']
})
export class ClientlistComponent implements OnInit {
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
  lists;
  Search: any;
  

  AlertText: string;


  pageno = 1;
  before: number;
  //TotalCount: any = 1;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; 

  constructor(
    private router: Router,
    private http: HttpClient,
    public apiList: ApilistService,
    private globalservice: GlobalService) {

      this.resize$.debounceTime(100).subscribe(innerWidth => {
        if (this.Resizing) { return; }
        this.Repaginate();
        this.SettleAndAutosize(80);
      });
      this.Repaginate();


    // const rowno = (window.innerHeight - 388) / 54;
    // if (rowno >= 1) {
    //   this.before = parseInt(rowno.toFixed(2).toString().split('.')[0], 10);
    // } else {
    //   this.before = 1;
    // }
  }

  Repaginate() {
    this.Resizing = true;
    this.pageno = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
//console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  ngOnInit() {
    this.hidepops();
    this.clientlist();
  }

  hidepops() {
    $('#Norecord').hide();
    $('#dots_spinner').hide();
    $('#alert_div').hide();
  }

  clientlist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ClientListApi, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
         // this.lists = response;
          this.lists = response.lst_Client;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide();
      if (this.Resizing) {
        // need to give time to render behind the scenes before size/resize rows
        this.SettleAndAutosize();
      }
    
    });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  addclient() {
    this.router.navigate([this.companyName + '/dashboard/Clientlist/Clients']);
  }

  delete(id) {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteClientApi, { IdClient: id, IdMerchant: this.IdMerchant },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response) {
          this.FlashError('Successfully deleted');
          this.clientlist();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  edit(id) {
    sessionStorage.setItem('referclientid', id);
    this.router.navigate([this.companyName + '/dashboard/Clientlist/UpdateClient']);
  }

  pageChange(event) {
    this.pageno = event;
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   event.target.innerHeight;
  //   const rowno = (window.innerHeight - 388) / 54;
  //   this.before = parseInt(rowno.toFixed(2).toString().split('.')[0], 10); // before
  //   if (this.before >= 0) {
  //     if (this.before === 0) {
  //       this.before = 1;
  //     }
  //   }
  // }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }
}
