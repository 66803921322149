import { Component, OnInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../global.service';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material';
import { NgForm, FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs/Rx';
import { ApilistService } from '../../../service/Api/apilist.service';
declare const $;
export interface posts {
  IdMerchant: number;
  CompanyName: string;
}

@Component({
  selector: 'app-requestinventory',
  templateUrl: './requestinventory.component.html',
  styleUrls: ['./requestinventory.component.css']
})
export class RequestinventoryComponent implements OnInit {

  Request: any;
  Request_id: any;
  AlertText: any;
  screenHeight: number;
  before: number;
  pageno: any;
  search: any;
  Search;

  protected posts: posts[];
  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<posts[]> = new ReplaySubject<posts[]>(1);
  myForm: FormGroup;
  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  Merchant_id: any;
  source_id: number;
  machine_data: any;
  machine: any;
  EffectiveDate:Date;

  machine_id:number;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate

  constructor(
    public apiList: ApilistService,
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    private fb: FormBuilder) {
      this.resize$.debounceTime(100).subscribe(innerWidth => {
        if (this.Resizing) { return; }
        this.Repaginate();
        this.SettleAndAutosize(80);
      });
      this.Repaginate();
      this.Requestlist();

  }

  ngOnInit() {
    this.hidePopup();
   
  }

  hidePopup() {
    $('#alert_div').hide();
    $('#dots_spinner').hide();
    $('#transferform').modal('hide');
    $('#myModalreq').modal('hide');
  }
  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
//console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  Requestlist() {
    this.http.post<any>(this.apiList.GetRequestedTransferListURL, {}, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      }).subscribe((response) => {
        this.Request = response.lst_Requested;
        console.log("requested list is "+JSON.stringify(this.Request));
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        if (this.Resizing) {
          // need to give time to render behind the scenes before size/resize rows
          this.SettleAndAutosize();
        }
       });
  }

  Accept(des) {
    this.Request_id = des.RequestedTransferId;
    this.source_id = des.IdSourceMerchant;
    this.search = des.DestinationMerchant;
    this.machine = des.MachineSerial;
    //adding machine id and effective date
    this.machine_id=des.IdMachine;
    this.EffectiveDate=new Date(des.EffectiveDate);

    
    if (des.LinkCode) {
      this.machine = des.MachineSerial + ' (' + des.LinkCode + ')';
    }
    $('#transferform').modal('show');
    this.search_merchnat();
  }

  Accepted_transfer() {
    if (this.Merchant_id) {
// var objecttoSend={IdMachine:this.machine_id,EffectiveDate:this.EffectiveDate,SourceIdMerchant:this.source_id, RequestTransferId: this.Request_id, ToMerchantId: this.Merchant_id };

//console.log("sent data is"+JSON.stringify(objecttoSend));
//adding machineid,effective date,sourceidmerchant
      this.http.post<any>(this.apiList.AccepttransferURL, {IdMachine:this.machine_id,EffectiveDate:this.EffectiveDate,SourceIdMerchant:this.source_id, RequestTransferId: this.Request_id, ToMerchantId: this.Merchant_id }, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(response => {
          if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
          if (+response.Response !== 1) { throw response; }
          return response;
        }).subscribe((response) => {
          this.FlashError('Transfer Requests accept successfully');
          this.Requestlist();
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#transferform').modal('hide'); });
    } else {
      this.FlashError('Select Merchant is Mandatory !');
    }
  }

  filterMyOptions(id) {
    this.Merchant_id = id;
  }

  search_merchnat() {
    this.http.post<any>(this.apiList.InventorySearchMerchantURL, { ActiveDeactive: 1, SearchText: 'Merchant' }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      }).subscribe((response) => {
        this.posts = response.lst_Customer;
        const index = this.posts.findIndex(p => p.IdMerchant === this.source_id);
        this.posts.splice(index, 1);
        this.bankCtrl.setValue(this.posts);
        this.filteredBanks.next(this.posts.slice());

        this.bankFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterBanks();
          });
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { });
  }

  protected setInitialValue() {
    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: posts, b: posts) => a && b && a.CompanyName === b.CompanyName;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
  }

  protected filterBanks() {
    if (!this.posts) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.posts.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.posts.filter(bank => bank.CompanyName.toLowerCase().indexOf(search) > -1)
    );
  }

  delete() {
    this.search_merchnat();
  }

  Reject(value) {
    this.Request_id = value;
    $('#myModalreq').modal('show');
  }

  Yes() {
    this.http.post<any>(this.apiList.RejecttransferURL, { RequestTransferId: this.Request_id }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      }).subscribe((response) => {
        this.FlashError('Transfer Requests reject successfully');
        this.Requestlist();
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#myModalreq').modal('hide'); });
  }

  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  clearSearch() {
    this.Search = '';
    this.Requestlist();
  }
  sortData(sort: Sort) {
    const data = this.Request.slice();
    if (!sort.active || sort.direction === '') {
      this.posts = data;
      return;
    }
    this.Request = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Machine_Type': return this.compare(a.MachineType.toLowerCase(), b.MachineType.toLowerCase(), isAsc);
        case 'Machine_Serial': return this.compare(a.MachineSerial.toLowerCase(), b.MachineSerial.toLowerCase(), isAsc);
        case 'LinkCode': return this.compare(a.LinkCode.toLowerCase(), b.LinkCode.toLowerCase(), isAsc);
        case 'From_Merchant': return this.compare(a.SourceMerchant.toLowerCase(), b.SourceMerchant.toLowerCase(), isAsc);
        case 'Destination_Merchant': return this.compare(a.DestinationMerchant.toLowerCase(), b.DestinationMerchant.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
