import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { environment } from '../../environments/environment';
import { GlobalService } from '../global.service';
import { UserIdleService } from 'angular-user-idle';
declare const $;

@Component({
  selector: 'app-regeneratepassword',
  templateUrl: './regeneratepassword.component.html',
  styleUrls: ['./regeneratepassword.component.css']
})
export class RegeneratepasswordComponent implements OnInit {

  baseUrl = environment.baseUrl;
  ResetpasswordUrl = this.baseUrl + '/Login/RegeneratePassword';


  AlertTextExpiry:string='';


  CheckReferencLinkExpiryUrl = this.baseUrl + '/Login/CheckReferenceExpiry';

  newpassword;
  confirmpassword;
  reference;
  AlertText;
  compare;
  show_message;
  ReferenceNo;
  showMain:boolean=false;

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private http: HttpClient,
    private globalservice: GlobalService,
    private route: ActivatedRoute, public userIdle: UserIdleService) {
    this.route.queryParams.subscribe(params => {
      this.reference = params.ref;
    });
  }

  ngOnInit() {
this.showMain=false;
    this._route.queryParams.subscribe(params => {
      this.ReferenceNo = params['ref'];
      
    });
   
if(this.ReferenceNo){
  this.checkExpire(this.ReferenceNo);
}

    this.userIdle.stopTimer();
    this.userIdle.stopWatching();
    this.globalservice.stopNopInterval();
    this.hidepops();
  }

  checkExpire(referalCode){
    var referalObject ={ReferenceNo:referalCode};
    this.http.post<any>(this.CheckReferencLinkExpiryUrl,referalObject,this.globalservice.headerWithoutauth())
    .take(1)
    .timeout(25000)
    .subscribe((response) => {
      if (response) {
        if (response.Response === 36) {
         
          this.AlertTextExpiry=response.ErrorMessage;
          
            $('#forget_invalid_popup').modal('show');
             
         

        } else {
            
        }
      }
    }, (err) => {
      const Error = this.globalservice.ProcessError(err);
      if (Error.Response === 35) {
        if (Error.ErrorMessage) {
         
           this.FlashError2(Error.ErrorMessage);

         
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
        return;
      } else {
        this.FlashError2(Error.ErrorMessage);
       
        return;
      }
    });
    
  }


  checkExpireBeforeSubmit(){
    var referalObject ={ReferenceNo:this.ReferenceNo};
    this.http.post<any>(this.CheckReferencLinkExpiryUrl,referalObject,this.globalservice.headerWithoutauth())
    .take(1)
    .timeout(25000)
    .subscribe((response) => {
      if (response) {
        if (response.Response === 36) {
         
          this.AlertTextExpiry=response.ErrorMessage;
          
            $('#forget_invalid_popup').modal('show');
             
         

        } else {
          this.resetpasswordsubmit();
        }
      }
    }, (err) => {
      const Error = this.globalservice.ProcessError(err);
      if (Error.Response === 35) {
        if (Error.ErrorMessage) {
         
           this.FlashError(Error.ErrorMessage);

         
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
        return;
      } else {
        this.FlashError(Error.ErrorMessage);
       
        return;
      }
    });
    
  }

  hidepops() {
    $('#Idle_popup').modal('hide');
    $('#myModal').modal('hide');
    $('#alert_div').hide();
    $('#uploadSuccess').modal('hide');
    $('#forget_invalid_popup').modal('hide');
  }
  onKey() {
    if (this.newpassword) {
      if (this.newpassword === this.confirmpassword) {
        this.compare = true;
      } else {
        this.show_message = 'Password and Confirm Password does not match';
        this.compare = false;
      }
    } else {
      this.show_message = 'Please enter the password';
      this.compare = false;
    }
  }

  resetpasswordsubmit() {
    if (this.newpassword === this.confirmpassword) {
      const password_info = {
        ReferenceNo: this.ReferenceNo,
        Password: this.newpassword
      };
      this.http.post<any>(this.ResetpasswordUrl, password_info, this.globalservice.Headers())
        .take(1)
        .timeout(25000)
        .map(result => {
          if (+result.Response !== 1) { throw result; }
          return result;
        }).subscribe((response) => {
          if (response) {
            if (response.Response === 1) {
              $('#uploadSuccess').modal('show');
            } else {
              this.FlashError(response.ErrorMessage);
            }
          }
        }, (err) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        });
    } else {
      this.FlashError('Password and Confirm Password does not match');
    }
  }

  login() {
    this.router.navigate(['/login']);
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  FlashError2(message: string) {
    console.log("inside 2");
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
       this.router.navigate(['/login']);
    }, 4000);
  }

  Yes() {
    $('#forget_invalid_popup').modal('hide');
    this.router.navigate(['/login']);
  }

}
