import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../../global.service';
import { environment } from '../../../../environments/environment';
declare const $;
import * as moment from 'moment';

@Component({
  selector: 'app-exception-report',
  templateUrl: './exception-report.component.html',
  styleUrls: ['./exception-report.component.css']
})
export class ExceptionReportComponent implements OnInit {
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");
  CSVFileUrl = environment.CSVFileUrl;
  exceptiondata: any;
  eventslist: any;
  eventArray: any[];
  selectedevent: any;
  IdEvent: any;
  listMachine: any;
  selectedMachine1: any;
  IdMachine: any;
  severitylists: any;
  selectedSeverity: any;
  SeverityLevelId: any;
  SeverityArray: any[];
  EventTypeId: any;
  StartDate: any;
  EndDate: any;
  Searchdata: any;
  AlertText: any;
  E2Date
  S1Date
  ExceptionDetail: any;

  Date: Date;
  constructor(private globalservice: GlobalService, private router: Router, public apiList: ApilistService, private http: HttpClient) { }

  ngOnInit() {
    this.Date = new Date();
    this.StartDate =  moment(this.Date).format('MM/DD/YYYY')
    this.EndDate = moment(this.Date).format('MM/DD/YYYY')
    $('#S1Date').val(this.StartDate);
    $('#E2Date').val(this.EndDate)

    this.exceptionList();
    this.eventList();
    this.HidePopups()
  }

  HidePopups(){
    $('#dots_spinner').hide();
    $('#alert_div').hide();
  }

  exceptionList() {
    var data = {
      IdMerchant: this.IdMerchant,
      
    }
    this.http.post<any>(this.apiList.ExceptionListUrl, data, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      //console.log(response)
      if (response.Response == 1) {
        this.exceptiondata = response.lst_Exceptions;
        this.listMachine = response.lst_Machines;
        this.severitylists = response.lst_Levels;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { });
  }

  salesbtn() { this.router.navigate([this.companyName + '/dashboard/reporting']); }
  pickreport() { this.router.navigate([this.companyName + '/dashboard/reporting/pickup']); }
  productbtn() { this.router.navigate([this.companyName + '/dashboard/reporting/productsale']); }
  exception() { this.router.navigate([this.companyName + '/dashboard/reporting/exception']); }

  eventList() {
    this.http.post<any>(this.apiList.getEventTypeList, {  }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.eventslist = response.lst_EventTypes;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  selectAll(id) {
    if (id == 'event') {
      $('#checkall').addClass("btn_active");
      $('#uncheckall').removeClass("btn_active");
      this.eventArray = [];
      for (let i = 0; i < this.eventslist.length; i++) {
        this.eventArray.push(this.eventslist[i].EventTypeId);
      }
      this.selectedevent = this.eventArray;
      this.EventTypeId = this.selectedevent.toString();
    } else if (id == 'machine') {
      $('#checkall').addClass("btn_active");
      $('#uncheckall').removeClass("btn_active");
      this.eventArray = [];
      for (let i = 0; i < this.listMachine.length; i++) {
        this.eventArray.push(this.listMachine[i].IdMachine);
      }
      this.selectedMachine1 = this.eventArray;
      this.IdMachine = this.selectedMachine1.toString();
      //console.log(this.selectedSeverity);
      //console.log(this.selectedMachine1);
    } else if (id == 'severity') {
      $('#checkall').addClass("btn_active");
      $('#uncheckall').removeClass("btn_active");
      this.eventArray = [];
      for (let i = 0; i < this.severitylists.length; i++) {
        this.eventArray.push(this.severitylists[i].SeverityLevelId);
      }
      this.selectedSeverity = this.eventArray;
      //console.log(this.selectedSeverity);
      //console.log(this.selectedMachine1);
      this.SeverityLevelId = this.selectedSeverity.toString();
    }
  }

  deselectAll(id) {
    if (id == 'event') {
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').addClass("btn_active");
      this.selectedevent = '';
      this.EventTypeId = '';
    }
    else if (id == 'machine') {
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').addClass("btn_active");
      this.selectedMachine1 = '';
      this.IdMachine = '';
    }
    else if (id == 'severity') {
      $('#checkall').removeClass("btn_active");
      $('#uncheckall').addClass("btn_active");
      this.selectedSeverity = '';
      this.SeverityLevelId = '';
    }

  }
  onChangeMachine(id) {
    this.IdMachine = id
  }
  onChangeevent(id) {
    this.EventTypeId = id;
  }
  onChangeSeverity(id) {
    this.SeverityLevelId = id;
    //console.log(this.SeverityLevelId);
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) { this.StartDate = $('#S1Date').val(); }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDate = $('#E2Date').val(); }
  addEvent3(type: string, event: MatDatepickerInputEvent<Date>) { this.StartDate = $('#S3Date').val(); }
  addEvent4(type: string, event: MatDatepickerInputEvent<Date>) { this.EndDate = $('#E4Date').val(); }


  EventReportSearch() {
    if (!this.StartDate || !this.EndDate) {
      this.FlashError("Please complete Date Information");
    } else {
      if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {
        $('#dots_spinner').show();
        //console.log(this.StartDate,this.EndDate,this.EventTypeId,this.SeverityLevelId,this.IdMachine)
        this.http.post<any>(this.apiList.getEventListBySearch, {  IdMerchant: this.IdMerchant, IdMachine: this.IdMachine, StartDate: this.StartDate, EndDate: this.EndDate, EventTypeId: this.EventTypeId, SeverityLevelId: this.SeverityLevelId }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
          //console.log(response);
          let res = response;
          this.Searchdata = res.lst_Exceptions
        }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
      } else {
        this.FlashError("Start Date should be less than End Date");
      }
    }
  }
  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  viewDetail(data) {
    $('#ExceptionDetails').modal('show');
    //console.log(data);
    let newData;
    if(data.charAt(0) != "{") {
      newData = JSON.stringify({
        "ExceptionMessage": data
      });
    }
    else {
      newData = data;
    }
    this.ExceptionDetail = JSON.parse(newData);
  }

  Print() {
    this.http.post<any>(this.apiList.PrintEventList, {  IdMerchant: this.IdMerchant, IdMachine: this.IdMachine, StartDate: this.StartDate, EndDate: this.EndDate, EventTypeId: this.EventTypeId, SeverityLevelId: this.SeverityLevelId }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      //console.log(response)
      var data = response;
      if (data.Response == 1) {
        data = data.htmltext
        var popupWinindow = window.open('', '_blank', 'width=1366, height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        popupWinindow.document.write('<html><head></head><body onload="window.print()" onafterprint="window.close()">' + data + '</html>');
        popupWinindow.document.close();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }


  export() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ExportEventList, {  IdMerchant: this.IdMerchant, IdMachine: this.IdMachine, StartDate: this.StartDate, EndDate: this.EndDate, EventTypeId: this.EventTypeId, SeverityLevelId: this.SeverityLevelId }, this.globalservice.Headers())
    .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      //console.log(response);
      let result = response;
      if (result.Response == 1) {
        let FileName = result.FileName
        window.location.href = this.CSVFileUrl + '/' + FileName;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}
