import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { NgForm } from "@angular/forms";
import { ApilistService } from '../../../../service/Api/apilist.service'
declare const $;
@Component({
  selector: 'app-settheme',
  templateUrl: './settheme.component.html',
  styleUrls: ['./settheme.component.css']
})
export class SetthemeComponent implements OnInit {
  @ViewChild('setthemeForm', { static: false }) public setthemeFormForm: NgForm
  BaseFileUrl = environment.BaseFileUrl;
  Imagelink = this.BaseFileUrl + "/Themes/";


  companyName = sessionStorage.getItem("companyName");
  IdMerchant = + parseInt(sessionStorage.getItem("referMerchantId"));
  IdMasterMerchant = + sessionStorage.getItem("referMasterMerchantId");
  posts
  IdMachine
  lsts
  ThemeImage
  selectedTheme
  selectedMachine
  //result
  posts_machine = [];
  Id_Machine
  array = [];
  ResolutionType
  AlertText: string;
  theme_id: number;
  themedetail: any;
  check_machine = [];
  unset_machineid;
  data: any;
  themetype: any
  myOptions = [
      { id: 0, name: 'All', ResolutionType: 'all' },
      { id: 1, name: 'Vertical Theme', ResolutionType: true },
      { id: 2, name: 'Horizontal Theme', ResolutionType: false },
    ];
  //path: boolean=false;   //if navigate to machine section then true otherwise false;
  userdata: any;
  machinetype: any;
  theme_color: any;
  IsColorTheme: any;
  ThemeId: any;
  FileMD5Sum: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    private route: ActivatedRoute,
    public apiList: ApilistService
  ) {
    this.route.params.subscribe((get) => {
      this.theme_id = + get.id;
      this.ResolutionType = get.type;
      if (this.theme_id) {
        if (this.ResolutionType == "true") {
          this.themetype = true;
        } else if (this.ResolutionType == "false") {
          this.themetype = false;
        }
        this.theme_detail();
      }
      else {
        this.admindetails();

      }
    })
  }

  ngOnInit() {
    //this.ResolutionType='all';
    this.Hidepops()
  }

  Hidepops(){
      $('#uploadSuccess').modal('hide');
    $('#uploadSuccess1').modal('hide');
    $("#alert_div").hide();
    $('#dots_spinner').hide();
    $("#theme").hide();
  }

  checktype(ResolutionType) {
    $("#theme").hide();
    this.IsColorTheme = null;
    this.selectedTheme = '';
    this.selectedMachine = '';
    this.lsts = [];
    this.posts_machine = [];
    // this.ThemeImage = '';
    this.ResolutionType = ResolutionType;
    this.listoftheme();

  }

  listoftheme() {
    this.http.post<any>(this.apiList.ThemeListUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())
 .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        if (this.ResolutionType == 'all') {
          this.lsts = response;
          this.lsts = this.lsts.lst_Themes;
        } else {
          var array = []
          this.lsts = response;
          this.data = this.lsts.lst_Themes;
          for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].ResolutionType == this.ResolutionType) {
              array.push(this.data[i])
            }
            this.lsts = array;
            //console.log( this.lsts)
          }
        }
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  theme_detail() {
    this.http.post<any>(this.apiList.ThemeDetailUrl, {  IdTheme: this.theme_id, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
        this.themedetail = response;
        //console.log(this.themedetail);
        if (this.themedetail.Response == 1) {
          this.ThemeImage = this.themedetail.obj_ThemeDetail.ThemeImage;
          this.ThemeId = this.themedetail.obj_ThemeDetail.ThemeId;
          this.IsColorTheme = this.themedetail.obj_ThemeDetail.IsColorTheme;
          this.ResolutionType = this.themedetail.obj_ThemeDetail.ResolutionType;
          this.selectedTheme = this.themedetail.obj_ThemeDetail.ThemeImage;
          this.FileMD5Sum = this.themedetail.obj_ThemeDetail.FileMD5Sum;
          for (let i = 0; i < this.themedetail.Machine_Lst.length; i++) {
            this.array.push(this.themedetail.Machine_Lst[i].MachineId);
          }
          this.selectedMachine = this.array;
          this.check_machine = this.selectedMachine
          this.Id_Machine = this.selectedMachine.toString();
          $("#theme").show();
          this.listoftheme();
          this.listofmachine();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }


  getdetails(i) {
    $("#theme").hide();
    //console.log(i);
    this.posts_machine = [];
    this.array = [];
    this.selectedMachine = ""
    this.theme_id = + i;
    //console.log(this.theme_id);
    this.theme_detail();
    $('#uncheckall').removeClass("btn_active");
    $('#checkall').removeClass("btn_active");
  }

  listofmachine() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.MachineListUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())
 .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.posts = response;
        //console.log(this.posts);
        this.posts = this.posts.Obj_MachineMaster;
        //console.log(this.posts);
        $('#dots_spinner').hide();
        for (var j = 0; j < this.posts.length; j++) {
          if (this.ResolutionType == true) {
            if (this.posts[j].ResolutionType == true) {
              this.posts_machine.push(this.posts[j])
            }
          }
          else if (this.ResolutionType == false) {
            if (this.posts[j].ResolutionType == false) {
              this.posts_machine.push(this.posts[j])
            }
          }
        }
      }
      // }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  onChangeMachine(id: any) {
    $('#uncheckall').removeClass("btn_active");
    $('#checkall').removeClass("btn_active");
    this.IdMachine = id;
    if (this.check_machine) {
      var missing = this.check_machine.filter(item => this.IdMachine.indexOf(item) < 0);
      this.unset_machineid = missing.toString();
    }
    this.Id_Machine = this.IdMachine.toString();
  }

  checktheme() {
    if (!this.theme_id) {
       this.FlashError("Firstly select the theme");
    }
  }

  selectAll() {
    $('#checkall').addClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
    this.array = [];
    for (let i = 0; i < this.posts_machine.length; i++) {
      this.array.push(this.posts_machine[i].IdMachine);
    }
    this.selectedMachine = this.array;
    this.Id_Machine = this.selectedMachine.toString();
    // console.log(this.Id_Machine);
  }
  deselectAll() {
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').addClass("btn_active");
    this.selectedMachine = "";
    this.array = [];
    this.Id_Machine = "";

  }

  settheme() {
    if (this.Id_Machine || this.unset_machineid) {
      //console.log(this.IdMerchant,this.ThemeImage,this.selectedMachine,this.selectedTheme,this.ResolutionType)
      $('#dots_spinner').show();
      this.http.post<any>(this.apiList.UpdateThemeUrl, {  IdMerchant: this.IdMerchant, IdTheme: this.ThemeId, IdMachines: this.Id_Machine, UnSelectedIdMachines: this.unset_machineid }, this.globalservice.Headers())
   .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
        if (response.Response == 1) {
          this.IsColorTheme = null;
          this.posts_machine = [];
          this.lsts = [];
          //this.ThemeImage = "";
          $("#theme").hide();
          this.unset_machineid = '';
          // this.path=false;
          //  this.setthemeFormForm.reset()
          $('#uploadSuccess').modal('show');
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
    } else {
      this.FlashError("Machine selection is invalid");
    }
  }


  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  back() {
    this.router.navigate([this.companyName + '/dashboard/Theme']);
  }

  admindetails() {
    if (this.IdMasterMerchant == 0) {
      var data = {
        IdMerchant: this.IdMerchant,
        
      }
    } else {
      var data = {
        IdMerchant: this.IdMasterMerchant,
        
      }
    }
    this.http.post<any>(this.apiList.MerchantDetailUrl, data, this.globalservice.Headers()).subscribe((response) => {
      this.userdata = response;
      if (this.userdata.Response == 1) {
        this.machinetype = this.userdata.MachineType;
        if (this.machinetype == "All" || this.machinetype == "") {
          this.themetype = 'all';
          this.ResolutionType = 'all'
        } else if (this.machinetype == "V") {
          this.themetype = true;
          this.ResolutionType = true;
          $("#theme").show();
        } else if (this.machinetype == "H") {
          this.themetype = false;
          this.ResolutionType = false;
          $("#theme").show();
        }
        this.listoftheme();
      }
    });
  }
}
