import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service'
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgForm, FormControl } from '../../../../../node_modules/@angular/forms';
import { DialogComponent } from '../../../dialog/dialog.component';
import { MatDialog } from '../../../../../node_modules/@angular/material';
declare const $;
import { UserIdleService } from 'angular-user-idle';
import { DropdownSearchService } from '../../../dropdown-search.service';
declare const tinymce;
@Component({
    selector: 'app-updateproduct',
    templateUrl: './updateproduct.component.html',
    styleUrls: ['./updateproduct.component.css']
})
export class UpdateproductComponent implements OnInit {
    @ViewChild('editproduct', { static: false }) public editproduct: NgForm
    @ViewChild('myInput', { static: false }) public myInputVariable: ElementRef;
    @ViewChild('myInputlogo', { static: false }) public myInputVariablelogo: ElementRef;
    @ViewChild('myIn11', { static: false }) public myIn11: ElementRef;
    @ViewChild('image3', { static: false }) public image3: ElementRef;
    @ViewChild('ima4', { static: false }) public ima4: ElementRef;
    @ViewChild('image5', { static: false }) public image5: ElementRef;
    BaseFileUrl = environment.BaseFileUrl;
    ImageUrl = this.BaseFileUrl + "/ClientDocument/";
    companyName = sessionStorage.getItem("companyName");
    IdMerchant = +parseInt(sessionStorage.getItem("referMerchantId"));
    IdMasterMerchant = + sessionStorage.getItem("referMasterMerchantId");
    //id = sessionStorage.getItem("refrenceid");

    posts: any;
    Name: any;
    Price: any;
    SKU: any;
    Supplier: any;
    SubCategoryName: any;
    MainCategoryName: any;
    ProductDescription: any;
    allergen: any;
    ingredients: any;
    IdMainCategory: any;
    IdSubCategory: any;
    IdProduct: any;
    MainImage: any;
    NutritionalFacts: any;
    CertificateLogoImg: any;
    AlternateImage1: any;
    AlternateImage2: any;
    AlternateImage3: any;
    updates: any
    names: any;
    lstproduct: any;
    idM: any;
    idS: any;
    imageresponse: any;
    SalesTax
    CurrencyType: any;
    lst_SalesTax = [];
    StateName
    IdState
    ItemsCount
    CountryName
    countrylists
    IdCountry
    states
    SalesTaxAmount
    IsSalesTax
    selctedcountry; selectedstate; selectedcity
    upload_image
    AlertText
    subcat_length
    addsale_modal
    Cityname: any;
    CityName; image
    IdCity: any;
    citys: any;
    MainfileList: any;
    NutritionalFileList: any;
    Alt1fileList: any;
    Mainfile: any;
    Alt2fileList: any;
    Alt3fileList: any;
    section = "";
    name: string;
    userdata
    my: any;
    Nutritional_file: any;
    NutrifileList: any;
    NutriFile: any;
    Alt2file: any;
    Alt1file: any;
    Alt3file: any;
    lists
    MainImageFileMD5Sum: any;
    NutritionalFileMD5Sum: any;
    CertificateLogoImgMD5Sum: any;
    Alternate1FileMD5Sum: any;
    Alternate2FileMD5Sum: any;
    Alternate3FileMD5Sum: any;
    Suppliername: any;
    Id_M: any;
    IsSamplingActive: boolean = false;
    IsBrandActive: boolean = false;



    // public Editor = ClassicEditor;
    isAdding = false;
    bit: any = false;
    editor: any;
    public searchCountryControl: FormControl = new FormControl();
    public countryCtrl: FormControl = new FormControl();
    public searchStateSearchCtrl: FormControl = new FormControl();
    public stateCtrl: FormControl = new FormControl();
    public searchCityControl: FormControl = new FormControl();
    public cityCtrl: FormControl = new FormControl();
    blob: Blob;
    MainImageName: any;
    AlternateImage1Name: any;
    AlternateImage2Name: any;
    AlternateImage3Name: any;
    NutritionalFactsName: any;
    CertificationLogoName: any;
    limitChecked
    filterData: any;
    IsSample = false
    IdBrand: any;
    Brandlist: any;
    idB: any;
    IsBrandVisible: boolean = false; //$$ BUG had to add since front end looks for this, but no one ever updates this. Please fix.

    LookupFeature: any;
    ShortDescription;


    constructor(
        public userIdle: UserIdleService,
        public apiList: ApilistService,
        public dialog: MatDialog,
        private router: Router,
        private http: HttpClient,
        private globalservice: GlobalService,
        public route: ActivatedRoute,
        public dropdownService: DropdownSearchService) {

        this.route.params.subscribe((get) => {
            this.Id_M = get.id;
            //  console.log( this.Id_M );
        })
    }

    ngOnInit() {

        var that = this
        this.userIdle.startWatching();

        tinymce.init({
            selector: '#mytextarea2',
            resize: true,
            width: '100%',
            min_height: 300,
            plugins: 'lists advlist code',
            toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | code',
            setup: (ed) => {
                ed.on('keyup', function (e) {
                    that.editproduct.control.markAsTouched();
                    that.editproduct.control.markAsDirty();
                    that.userIdle.resetTimer();
                });
            }
        });

        tinymce.init({
            selector: '#mytextareaallergen',
            resize: true,
            width: '100%',
            min_height: 300,
            plugins: 'lists advlist code',
            toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | code',
            setup: (ed) => {
                ed.on('keyup', function (e) {
                    that.editproduct.control.markAsTouched();
                    that.editproduct.control.markAsDirty();
                    that.userIdle.resetTimer();
                });
            }
        });

        tinymce.init({
            selector: '#mytextareaIngredients',
            resize: true,
            width: '100%',
            min_height: 300,
            plugins: 'lists advlist code',
            toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | code',
            setup: (ed) => {
                ed.on('keyup', function (e) {
                    that.editproduct.control.markAsTouched();
                    that.editproduct.control.markAsDirty();
                    that.userIdle.resetTimer();
                });
            }
        });

        this.globalservice.setDiscard_check();
        $('#myModal').modal('hide');
        this.upload_image = 1;
        $("#alert_div").hide();
        $('#dots_spinner').hide();
        $('#add').trigger('click');
        $(function () {
            $('#upload-btn-wrapper').hide();
            $('#myImg').show();
            $('#main_img_close').show();

            $('#upload-btn-wrapperlogo').hide();
            $('#myImglogo').show();
            $('#main_img_closelogo').show();

            $('#upload-btn-wrapper1').hide();
            $('#myImg1').show();
            $('#main_img_close1').show();

            $('#upload-btn-wrapper2').hide();
            $('#myImg2').show();
            $('#main_img_close2').show();

            $('#upload-btn-wrapper3').hide();
            $('#myImg3').show();
            $('#main_img_close3').show();

            $('#upload-btn-wrapper4').hide();
            $('#myImg4').show();
            $('#main_img_close4').show();

            $('#upload-btn-wrapper5').hide();
            $('#myImg5').show();
            $('#main_img_close5').show();

        });

        $('#myImg1').show();
        $('#main_img_close1').show();
        $('#upload-btn-wrapper1').hide();
        this.Featuredetail();
        this.supplierlist()
        this.brand();
        this.productdetailstoupdate();
        this.categorylist()


    }


    categorylist() {
        this.http.post<any>(this.apiList.CategoryListUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            })
            .subscribe((response) => {
                if (response.Response == 1) {
                    this.posts = response;
                    this.posts = this.posts.lst_mc;
                }
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
            });
    }

    remove_mainimage() {
        this.MainImage = '';
        //  this.myInputVariable.nativeElement.value = "";
        this.upload_image = '';
        this.MainImageFileMD5Sum = '';
        this.MainImageName = '';
        $('#myImg').hide();
        $('#main_img_close').hide();
        $('#upload-btn-wrapper').show();
        this.editproduct.control.markAsTouched();
        this.editproduct.control.markAsDirty();
    }
    remove_Nutritional() {
        this.NutritionalFacts = '';
        // this.myIn11.nativeElement.value = "";
        this.NutritionalFileMD5Sum = '';
        this.NutritionalFactsName = '';
        $('#myImg1').hide();
        $('#main_img_close1').hide();
        $('#upload-btn-wrapper1').show();
        this.editproduct.control.markAsTouched();
        this.editproduct.control.markAsDirty();
    }
    remove_logo() {
        this.CertificateLogoImg = '';
        //  this.myInputVariablelogo.nativeElement.value = "";
        this.CertificateLogoImgMD5Sum = '';
        this.CertificationLogoName = '';
        $('#myImglogo').hide();
        $('#main_img_closelogo').hide();
        $('#upload-btn-wrapperlogo').show();
    }
    remove_alternate() {
        this.AlternateImage1 = '';
        this.Alternate1FileMD5Sum = '';
        this.AlternateImage1Name = '';
        // this.image3.nativeElement.value = "";
        $('#myImg2').hide();
        $('#main_img_close2').hide();
        $('#upload-btn-wrapper2').show();
        this.editproduct.control.markAsTouched();
        this.editproduct.control.markAsDirty();
    }
    remove_alt_second() {
        this.AlternateImage2 = '';
        this.Alternate2FileMD5Sum = '';
        this.AlternateImage2Name = '';
        //  this.ima4.nativeElement.value = "";
        $('#myImg3').hide();
        $('#main_img_close3').hide();
        $('#upload-btn-wrapper3').show();
        this.editproduct.control.markAsTouched();
        this.editproduct.control.markAsDirty();
    }
    remove_alt_third() {
        this.AlternateImage3 = '';
        this.Alternate3FileMD5Sum = '';
        this.AlternateImage3Name = '';
        // this.image5.nativeElement.value = "";
        $('#myImg4').hide();
        $('#main_img_close4').hide();
        $('#upload-btn-wrapper4').show();
        this.editproduct.control.markAsTouched();
        this.editproduct.control.markAsDirty();
    }

    canDeactivate(): any {
        if (!this.isAdding && this.editproduct.dirty) {
            if (this.bit == false && this.globalservice.GetDiscard_Check()) {
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });

                return dialogRef.afterClosed().subscribe(result => {
                    if (result && (result.response == "ok")) {
                        this.dialog.closeAll();
                        this.bit = true;
                        this.router.navigate([this.companyName + '/dashboard/product']);
                    }
                });
            }
        } else if (!this.isAdding && this.editproduct.dirty) {
            if (this.bit == false && this.globalservice.GetDiscard_Check()) {
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });

                return dialogRef.afterClosed().subscribe(result => {
                    // console.log(result)
                    // console.log( result.response)
                    if (result && (result.response == "ok")) {
                        this.dialog.closeAll();
                        this.bit = true;
                        this.router.navigate([this.companyName + '/dashboard/product']);
                    }
                });
            }
        }
        return true;
    }

    productdetailstoupdate() {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.ProductDetailUrl, { IdProduct: this.Id_M }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            }).subscribe((response) => {
                $('#dots_spinner').hide();
                if (response.Response == 1) {

                    this.updates = response;

                    // console.log(this.updates);
                    console.log("updates" + JSON.stringify(this.updates.lst_ProductRecord[0]));
                    this.Name = this.updates.lst_ProductRecord[0].Name;
                    this.Price = this.updates.lst_ProductRecord[0].Price;
                    this.SKU = this.updates.lst_ProductRecord[0].SKU;
                    this.Suppliername = this.updates.lst_ProductRecord[0].Supplier;
                    this.IsSample = this.updates.lst_ProductRecord[0].IsSample == 1 ? true : false;
                    this.ShortDescription=this.updates.lst_ProductRecord[0].ShortDescription;
                    // if (this.Suppliername == '') {
                    //     this.Supplier = 'None'
                    // } else {
                    //     this.Supplier = this.updates.lst_ProductRecord[0].Supplier;
                    // }
                    this.Supplier = this.Suppliername == '' ? 'None' : (this.Suppliername == null ? 'None' : this.updates.lst_ProductRecord[0].Supplier);
                    //  console.log(this.Supplier);
                    this.SubCategoryName = this.updates.lst_ProductRecord[0].SubCategoryName;
                    this.IdSubCategory = this.updates.lst_ProductRecord[0].IdSubCategory;
                    // if (this.IdSubCategory == null) {
                    //     this.idS = 'None'
                    // } else {
                    //     this.idS = this.updates.lst_ProductRecord[0].IdSubCategory;
                    // }


                    this.MainCategoryName = this.updates.lst_ProductRecord[0].MainCategoryName;
                    this.IdMainCategory = this.updates.lst_ProductRecord[0].IdMainCategory;
                    this.IdBrand = this.updates.lst_ProductRecord[0].IdBrand
                    this.idB = this.updates.lst_ProductRecord[0].IdBrand
                    console.log(this.idB)
                    // 
                    // if (this.IdMainCategory == null) {
                    //     this.idM = 'None'
                    // } else {
                    //     this.idM = this.updates.lst_ProductRecord[0].IdMainCategory;
                    //     this.subcat();
                    // }
                    this.idM = this.IdMainCategory == null ? 'None' : this.updates.lst_ProductRecord[0].IdMainCategory;
                    this.onChangemain(this.idM);

                    this.idS = this.IdSubCategory == null ? 'None' : this.updates.lst_ProductRecord[0].IdSubCategory;
                    this.ProductDescription = this.updates.lst_ProductRecord[0].ProductDescription;
                    tinymce.get('mytextarea2').setContent(this.ProductDescription);
                    this.allergen = this.updates.lst_ProductRecord[0].AllergenInformation ? this.updates.lst_ProductRecord[0].AllergenInformation : '';
                    tinymce.get('mytextareaallergen').setContent(this.allergen);
                    this.ingredients = this.updates.lst_ProductRecord[0].Ingredients ? this.updates.lst_ProductRecord[0].Ingredients : '';
                    tinymce.get('mytextareaIngredients').setContent(this.ingredients);
                    this.MainImage = this.updates.lst_ProductRecord[0].MainImageURL;
                    this.MainImageFileMD5Sum = this.updates.lst_ProductRecord[0].FileMD5Sum;
                    this.NutritionalFacts = this.updates.lst_ProductRecord[0].NutritionalFactsURL;
                    this.NutritionalFileMD5Sum = this.updates.lst_ProductRecord[0].NutritionalFileMD5Sum;
                    this.CertificateLogoImg = this.updates.lst_ProductRecord[0].CertificationLogoURL;
                    this.CertificateLogoImgMD5Sum = this.updates.lst_ProductRecord[0].CertificationLogoFileMD5Sum;

                
                    this.AlternateImage1 = this.updates.lst_ProductRecord[0].AlternateImage1URL;
                    this.Alternate1FileMD5Sum = this.updates.lst_ProductRecord[0].Alternate1FileMD5Sum;
                    this.AlternateImage2 = this.updates.lst_ProductRecord[0].AlternateImage2URL;
                    this.Alternate2FileMD5Sum = this.updates.lst_ProductRecord[0].Alternate2FileMD5Sum;
                    this.AlternateImage3 = this.updates.lst_ProductRecord[0].AlternateImage3URL;
                    // console.log( this.AlternateImage3);
                    this.Alternate3FileMD5Sum = this.updates.lst_ProductRecord[0].Alternate3FileMD5Sum;


                    //new 

                    this.MainImageName = this.updates.lst_ProductRecord[0].MainImage ? this.updates.lst_ProductRecord[0].MainImage : '';

                    this.NutritionalFactsName = this.updates.lst_ProductRecord[0].NutritionalFacts ? this.updates.lst_ProductRecord[0].NutritionalFacts : '';

                    this.CertificationLogoName = this.updates.lst_ProductRecord[0].CertificationLogo ? this.updates.lst_ProductRecord[0].CertificationLogo : '';

                    this.AlternateImage1Name = this.updates.lst_ProductRecord[0].AlternateImage1 ? this.updates.lst_ProductRecord[0].AlternateImage1 : '';

                    this.AlternateImage2Name = this.updates.lst_ProductRecord[0].AlternateImage2 ? this.updates.lst_ProductRecord[0].AlternateImage2 : '';

                    this.AlternateImage3Name = this.updates.lst_ProductRecord[0].AlternateImage3 ? this.updates.lst_ProductRecord[0].AlternateImage3 : '';


                    // console.log( this.AlternateImage3);
                    this.SalesTax = this.updates.lst_ProductRecord[0].SalesTax;
                    this.CurrencyType = this.updates.lst_ProductRecord[0].CurrencyType;
                    for (var j = 0; j < this.updates.lst_StateSalesTax.length; j++) {
                        this.addsale_modal = 1;
                        this.StateName = this.updates.lst_StateSalesTax[j].StateName;
                        this.IdState = this.updates.lst_StateSalesTax[j].IdState;
                        this.CityName = this.updates.lst_StateSalesTax[j].CityName;
                        this.IdCity = this.updates.lst_StateSalesTax[j].IdCity;
                        this.lst_SalesTax.push({
                            IdState: this.IdState,
                            StateName: this.StateName,
                            IdCity: this.IdCity,
                            CityName: this.CityName,
                            SalesTaxAmount: this.updates.lst_StateSalesTax[j].SalesTaxAmount
                        });
                        this.ItemsCount = this.lst_SalesTax.length;
                    }
                    // this.admindetails();
                }
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
            });
    }

    supplierlist() {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.SupplierListUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            }).subscribe((response) => {
                $('#dots_spinner').hide();
                if (response.Response == 1) {
                    this.lists = response;
                    this.lists = this.lists.lst_ProductSupplier
                }
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
                $('#dots_spinner').hide();
            });
    }

    saleTax() {
        $('#saleTax').modal('show')
        this.limitChecked = 'true'
        this.IdCity = '';
        this.IdCountry = ''
        this.IdState = ''
        this.selctedcountry = ''
        this.selectedstate = ''
        this.selectedcity = ''

        this.AccordingToLocation()
    }

    close_saletax() {
        // console.log(this.ItemsCount)
        $('#saleTax').modal('hide');
        if (this.ItemsCount == 0) {
            this.SalesTax = false;
            this.lst_SalesTax = []
        }
    }

    AccordingToLocation() {
        let data = {
            IdMerchant: this.IdMerchant
        }
        this.http.post<any>(this.apiList.CountryStateCityByMerchantId, data, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            })
            .subscribe((response: any) => {
                console.log(response);
                if (response.Response == 1) {
                    this.filterData = response.Country
                    console.log(response.Country)
                    this.countrylists = response.Country;
                    this.dropdownService.countryObject.next(this.countrylists);
                    this.dropdownService.CountryArrayObj.next(this.countrylists.slice());
                    this.searchCountryControl = this.dropdownService.searchCountryControl;
                    this.searchCountryControl.valueChanges
                        .subscribe(() => {
                            this.dropdownService.countryFilter();
                        });
                }
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
            });
    }





    Featuredetail() {

        this.http.post<any>(this.apiList.GetMerchantFeatures, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
            .timeout(30000)
            .retry(2)
            .take(1)
            .map(response => {
                if (!response) {
                    throw { Response: 0, ErrorMessage: "Empty or null response" };
                }
                if (response.Response !== 1) {
                    throw response;
                }
                return response;
            })
            .subscribe(response => {

                this.LookupFeature = response.Feature.reduce((acc, cv) => {
                    acc[cv.FeatureType] = cv.FeatureValue;
                    return acc;
                }, {});
                if (this.LookupFeature) {
                    this.IsBrandActive = this.LookupFeature.IsBrandActive;
                    this.IsSamplingActive = this.LookupFeature.IsSamplingActive;
                   }

            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                }
            }).add(() => {
            });
    }


    admindetails() {
        // if (this.IdMasterMerchant == 0) {
        //     var data = {
        //         IdMerchant: this.IdMerchant,

        //     }
        // } else {
        //     var data = {
        //         IdMerchant: this.IdMasterMerchant,

        //     }
        // }
        var data = {
            IdMerchant: this.IdMasterMerchant == 0 ? this.IdMerchant : this.IdMasterMerchant
        }
        this.http.post<any>(this.apiList.MerchantDetailUrl, data, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            }).subscribe((response) => {
                this.userdata = response;
                //  console.log(this.userdata)
                if (this.userdata.Response == 1) {
                    this.IdCountry = '' + this.userdata.obj_MerchantRecord.IdCountry;
                    this.selctedcountry = '' + this.userdata.obj_MerchantRecord.IdCountry;
                    this.IdState = '' + this.userdata.obj_MerchantRecord.IdState;
                    this.selectedstate = '' + this.userdata.obj_MerchantRecord.IdState;
                    this.selectedcity = '' + this.userdata.obj_MerchantRecord.IdCity;
                    this.IdCity = '' + this.userdata.obj_MerchantRecord.IdCity;
                    this.StateName = this.userdata.obj_MerchantRecord.State;
                    this.CityName = this.userdata.obj_MerchantRecord.City;
                    this.globalservice.getCountry().then((res: any) => {
                    this.countrylists = res;
                        // console.log(res);
                    this.dropdownService.countryObject.next(this.countrylists);
                    this.dropdownService.CountryArrayObj.next(this.countrylists.slice());
                    this.searchCountryControl = this.dropdownService.searchCountryControl;
                    this.searchCountryControl.valueChanges
                            .subscribe(() => {
                                this.dropdownService.countryFilter();
                            });
                    });
                    this.getcity();
                    this.getstates();
                }
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
            });
    }

    onLimitChanged(event) {
        console.log(event.checked)
        this.limitChecked = event.checked
        if (event.checked == false) {
            this.admindetails()
        } else {
            this.selctedcountry = ''
            this.selectedstate = ''
            this.SalesTaxAmount = ''
            this.selectedcity = ''
            this.IdState = ''
            this.IdCity = ''
            this.AccordingToLocation()
        }
    }

    Addsaletax() {
        if (this.lst_SalesTax != null) {
            let a = this.StateName;
            let b = this.CityName;
            var IsExist = this.lst_SalesTax.some(function (o) { return o["StateName"] === a && o["CityName"] === b; });
            if (IsExist == true) {
                this.FlashError("Already Exist");
            }
            else {
                this.ItemsCount++
                this.addsale_modal = 1;
                this.lst_SalesTax.push({
                    IdState: this.IdState,
                    StateName: this.StateName,
                    SalesTaxAmount: this.SalesTaxAmount,
                    IdCity: this.IdCity,
                    CityName: this.CityName
                });
                this.SalesTaxAmount = '';
                this.selectedcity = '';
                this.CityName = '';
            }
        }
        else {
            this.addsale_modal = 1;
            this.lst_SalesTax.push({
                IdState: this.IdState,
                StateName: this.StateName,
                SalesTaxAmount: this.SalesTaxAmount,
                IdCity: this.IdCity,
                CityName: this.CityName
            });
            this.SalesTaxAmount = '';
            this.selectedcity = '';
            this.CityName = '';
        }
    }


    delete(name: string) {
        this.name = name;
        $('#delete').modal('show')
    }

    remove() {
        this.ItemsCount = this.lst_SalesTax.length;
        for (var i = 0; i < this.ItemsCount; i++) {
            if (this.lst_SalesTax[i]["CityName"] == this.name) {
                this.lst_SalesTax.splice(i, 1);
                this.ItemsCount--
            }
        }
        // console.log(this.ItemsCount)
    }

    onChangecurrency(value) {
        this.CurrencyType = value;
    }

    onChangeCountry(a) {
        this.IdCountry = a;
        if (this.limitChecked == false) {
            this.getstates();
        } else {
            this.filterData.forEach(element => {
                if (element.IdCountry == this.IdCountry)
                    this.states = element.States
                this.dropdownService.stateObject.next(this.states);
                this.dropdownService.StateArrayObj.next(this.states);
                this.searchStateSearchCtrl = this.dropdownService.searchStateControl;
                this.searchStateSearchCtrl.valueChanges
                    .subscribe(() => {
                        this.dropdownService.stateFilter();
                    });
            });
        }
    }

    country(b) {
        this.CountryName = b;
    }
    stateName(b) {
        this.StateName = b;
    }
    getstates() {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.StateUrl, { IdCountry: this.IdCountry })
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            }).subscribe((response) => {
                $('#dots_spinner').hide();
                this.states = response.lst_State;
                this.dropdownService.stateObject.next(this.states);
                this.dropdownService.StateArrayObj.next(this.states.slice());
                this.searchStateSearchCtrl = this.dropdownService.searchStateControl;
                this.searchStateSearchCtrl.valueChanges
                    .subscribe(() => {
                        this.dropdownService.stateFilter();
                    });
            });
    }

    onChangeState(b) {
        this.IdState = b;
        if (this.limitChecked == false) {
            this.getcity();
        } else {
            this.states.forEach(element => {
                if (element.IdState == this.IdState)
                    this.citys = element.cities;
                console.log(this.citys)
                console.log(this.citys)
                this.dropdownService.cityObject.next(this.citys);
                this.dropdownService.CityArrayObj.next(this.citys);
                this.searchCityControl = this.dropdownService.searchCityControl;
                this.searchCityControl.valueChanges
                    .subscribe(() => {
                        this.dropdownService.cityFilter();
                    });
            });
        }
    }

    getcity() {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.CityUrl, { IdState: this.IdState }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            }).subscribe((response) => {
                $('#dots_spinner').hide();
                this.citys = response.lst_City;
                this.dropdownService.cityObject.next(this.citys);
                this.dropdownService.CityArrayObj.next(this.citys.slice());
                this.searchCityControl = this.dropdownService.searchCityControl;
                this.searchCityControl.valueChanges
                    .subscribe(() => {
                        this.dropdownService.cityFilter();
                    });
            });
    }

    onChangeCity(d) {
        if (d != 'None') {
            this.IdCity = d;
        } else {
            this.IdCity = '';
            this.CityName = ''
        }
    }
    getCityName(c) {
        this.CityName = c;

    }

    onChangesupplier(id) {
        this.Supplier = id;
        if (id == 'None') {
            this.Suppliername = '';
        } else {
            this.Suppliername = id;
        }

    }

    subcat() {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.SubCategoryListUrl, { IdMainCategory: this.idM, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (result.Response !== 1) throw result;
                return result;
            }).subscribe((response) => {
                $('#dots_spinner').hide();

                if (response.Response == 1) {
                    this.names = response;
                    this.names = this.names.lst_mc;
                }
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                }
                else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
                $('#dots_spinner').hide();
            });
    }

    onChangemain(id: string) {
        this.IdMainCategory = id;
        if (this.IdMainCategory != 'None') {
            $('#dots_spinner').show();
            this.http.post<any>(this.apiList.SubCategoryListUrl, { IdMainCategory: this.IdMainCategory, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
                .take(1)
                .timeout(25000)
                .map(result => {
                    if (result.Response !== 1) throw result;
                    return result;
                }).subscribe((response) => {
                    $('#dots_spinner').hide();

                    if (response.Response == 1) {
                        this.names = response;
                        this.names = this.names.lst_mc;
                        this.subcat_length = this.names.length;
                    }
                }, (err: any) => {
                    const Error = this.globalservice.ProcessError(err);
                    if (Error.Response === 35) {
                        if (Error.ErrorMessage) {
                            this.FlashError(Error.ErrorMessage);
                            this.globalservice.Logout('Yes');
                        }
                        this.globalservice.Logout('No');
                        return;
                    }
                    else {
                        this.FlashError(Error.ErrorMessage);
                        return;
                    }
                }).add(() => {
                    $('#dots_spinner').hide();
                });
        } else {
            this.subcat_length = 0;
            this.idS = 'None';
            this.IdMainCategory = null;
            this.IdSubCategory = null
        }
    }
    onChangesub(id1: string) {
        if (id1 != 'None') {
            this.IdSubCategory = id1;
        } else {
            this.IdSubCategory = null;
        }
    }



    goback() {
        this.router.navigate([this.companyName + '/dashboard/product']);
    }

    FlashError(message: string) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(() => {
            $('#alert_div').hide();
        }, 3000);
    }

    submit_click() {
        this.isAdding = true;
        if(this.IdBrand=="None"){
            this.IdBrand=null;
        }
        let sampleval = this.IsSample == false ? 0 : 1
        if (this.SalesTax == false) {
            this.addsale_modal = '';
        }
        
        if (this.SalesTax == true) {
            // this.ProductDescription = tinymce.activeEditor.getContent({ format: 'raw' });
            this.ingredients = tinymce.get('mytextareaIngredients').getContent({ format: 'raw' });
            this.ProductDescription = tinymce.get('mytextarea2').getContent({ format: 'raw' });
            this.allergen = tinymce.get('mytextareaallergen').getContent({ format: 'raw' });
            if (tinymce.get('mytextarea2').getContent({ format: 'text' }).trim()) {
                // if (tinymce.get('mytextareaallergen').getContent({ format: 'text' }).trim()) {

                //     if (tinymce.get('mytextareaIngredients').getContent({ format: 'text' }).trim()) {
                        if (this.addsale_modal == 1) {
                            if (this.upload_image == 1) {
                                $('#dots_spinner').show();
                                this.http.post<any>(this.apiList.UpdateProductUrl, { IsSalesTax: this.SalesTax, CurrencyType: this.CurrencyType, IdProduct: this.Id_M, Name: this.Name, Price: this.Price, SKU: this.SKU, Supplier: this.Suppliername, ProductDescription: this.ProductDescription, IdMainCategory: this.IdMainCategory, IdSubCategory: this.IdSubCategory, CreatedBy: this.IdMerchant, MainImage: this.MainImageName, NutritionalFacts: this.NutritionalFactsName, AlternateImage1: this.AlternateImage1Name, AlternateImage2: this.AlternateImage2Name, AlternateImage3: this.AlternateImage3Name, lst_AddSalesTax: this.lst_SalesTax, IsSample: sampleval, IdBrand: this.IdBrand, Ingredients: this.ingredients, AllergenInformation: this.allergen, CertificationLogo: this.CertificationLogoName,ShortDescription:this.ShortDescription }, this.globalservice.Headers())
                                    .take(1)
                                    .timeout(25000)
                                    .map(result => {
                                        if (result.Response !== 1) throw result;
                                        return result;
                                    }).subscribe((response) => {
                                        $('#dots_spinner').hide();
                                        if (response.Response == 1) {
                                            this.lstproduct = response;
                                            // console.log(response);
                                            this.router.navigate([this.companyName + '/dashboard/product']);
                                        }
                                    }, (err: any) => {
                                        const Error = this.globalservice.ProcessError(err);
                                        if (Error.Response === 35) {
                                            if (Error.ErrorMessage) {
                                                this.FlashError(Error.ErrorMessage);
                                                this.globalservice.Logout('Yes');
                                            }
                                            this.globalservice.Logout('No');
                                            return;
                                        }
                                        else {
                                            this.FlashError(Error.ErrorMessage);
                                            return;
                                        }
                                    }).add(() => {
                                        $('#dots_spinner').hide();
                                    });
                            }
                            else {
                                this.FlashError("Firstly upload your Main image")
                            }
                        } else {
                            this.FlashError("Fill Sale tax form")

                        }

                    // }
                    // else {
                    //     this.FlashError("Ingredients is Mandatory !");
                    // }
                // }
                // else {
                //     this.FlashError("Allergen is Mandatory !");
                // }
            } else {
                this.FlashError("Product Description is Mandatory !")
            }
        } else {
            // this.ProductDescription = tinymce.activeEditor.getContent({ format: 'raw' });
            this.ingredients = tinymce.get('mytextareaIngredients').getContent({ format: 'raw' });
            this.ProductDescription = tinymce.get('mytextarea2').getContent({ format: 'raw' });
            this.allergen = tinymce.get('mytextareaallergen').getContent({ format: 'raw' });
            if (tinymce.get('mytextarea2').getContent({ format: 'text' }).trim()) {
                // if (tinymce.get('mytextareaallergen').getContent({ format: 'text' }).trim()) {

                //     if (tinymce.get('mytextareaIngredients').getContent({ format: 'text' }).trim()) {
                        if (this.upload_image == 1) {
                            $('#dots_spinner').show();
                            this.http.post<any>(this.apiList.UpdateProductUrl, { IsSalesTax: this.SalesTax, CurrencyType: this.CurrencyType, IdProduct: this.Id_M, Name: this.Name, Price: this.Price, SKU: this.SKU, Supplier: this.Suppliername, ProductDescription: this.ProductDescription, IdMainCategory: this.IdMainCategory, IdSubCategory: this.IdSubCategory, CreatedBy: this.IdMerchant, MainImage: this.MainImageName, NutritionalFacts: this.NutritionalFactsName, AlternateImage1: this.AlternateImage1Name, AlternateImage2: this.AlternateImage2Name, AlternateImage3: this.AlternateImage3Name, lst_AddSalesTax: this.lst_SalesTax, IsSample: sampleval, IdBrand: this.IdBrand, Ingredients: this.ingredients, AllergenInformation: this.allergen, CertificationLogo: this.CertificationLogoName,ShortDescription:this.ShortDescription }, this.globalservice.Headers())
                                .take(1)
                                .timeout(25000)
                                .map(result => {
                                    if (result.Response !== 1) throw result;
                                    return result;
                                }).subscribe((response) => {
                                    $('#dots_spinner').hide();
                                    if (response.Response == 1) {
                                        this.lstproduct = response;
                                        //console.log(response);
                                        this.router.navigate([this.companyName + '/dashboard/product']);
                                    }
                                }, (err: any) => {
                                    //handling error
                                    const Error = this.globalservice.ProcessError(err);
                                    if (Error.Response === 35) {
                                        if (Error.ErrorMessage) {
                                            this.FlashError(Error.ErrorMessage);
                                            this.globalservice.Logout('Yes');
                                        }
                                        this.globalservice.Logout('No');
                                        return;
                                    }
                                    else {
                                        this.FlashError(Error.ErrorMessage);
                                        return;
                                    }
                                }).add(() => {

                                    $('#dots_spinner').hide();
                                });
                        }
                        else {
                            this.FlashError("Firstly upload your Main image")
                        }
                //     }
                //     else {
                //         this.FlashError("Ingredients is Mandatory !");
                //     }
                // }
                // else {
                //     this.FlashError("Allergen is Mandatory !");
                // }
            } else {
                this.FlashError("Product Description is Mandatory !")
            }
        }
    }


    ngOnDestroy() {
        tinymce.remove('#mytextarea2');
        tinymce.remove('#mytextareaallergen');
        tinymce.remove('#mytextareaIngredients');
        this.dialog.ngOnDestroy();
    }

    MainImageUpload(file: File, categoryType) {
        if (categoryType == 'MainImage') {
            this.upload_image = 1
        }
        if (this.upload_image == 1) {
            $('#dots_spinner').show();
            this.section = categoryType;
            this.blob = new Blob([file[0]], { type: file[0].type });
            console.log(file)
            this.globalservice.CheckTransparency(file).then((res: any) => {
                $('#dots_spinner').hide();
                console.log(res)
                if (res.Response == 1) {
                    this.uploadFile(categoryType)
                }
            }, error => {
                $('#dots_spinner').hide();
                console.log(error)
                const Error = this.globalservice.ProcessError(error);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else if (Error.Response == 4) {
                    $('#myModal').modal('show');
                }
                else {
                    console.log(Error.ErrorMessage)
                    // event.target.value = null;
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            })
        } else {
            this.FlashError("Firstly upload your Main image");
        }
    }
    uploadFile(categoryType) {
        $('#dots_spinner').show();
        console.log(categoryType)
        this.globalservice.uploadFiles(this.blob).then((res: any) => {
            if (res.Response == 1) {
                $('#dots_spinner').hide();
                switch (categoryType) {
                    case 'MainImage':
                        this.MainImage = res.PreviewUrl;
                        this.MainImageName = res.FileName
                        this.upload_image = 1;
                        $('#myImg').show(2000, function () {
                            $('#main_img_close').show();
                            $('#uploadSuccess').modal('show');
                        });
                        $('#upload-btn-wrapper').hide();
                        console.log(this.MainImage)
                        break;
                    case 'NutritionalFacts':
                        this.NutritionalFacts = res.PreviewUrl;
                        this.NutritionalFactsName = res.FileName
                        $('#myImg1').show(2000, function () {
                            $('#main_img_close1').show();
                            $('#uploadSuccess').modal('show');
                        });
                        $('#upload-btn-wrapper1').hide();
                        break;

                    case 'CertificateLogo':
                        this.CertificateLogoImg = res.PreviewUrl;
                        this.CertificationLogoName = res.FileName
                        $('#myImglogo').show(2000, function () {
                            $('#main_img_closelogo').show();
                            $('#uploadSuccess').modal('show');
                        });
                        $('#upload-btn-wrapperlogo').hide();
                        break;
                    case 'AlternateImage1':
                        this.AlternateImage1 = res.PreviewUrl;
                        this.AlternateImage1Name = res.FileName
                        $('#myImg2').show(2000, function () {
                            $('#main_img_close2').show();
                            $('#uploadSuccess').modal('show');;
                        });
                        $('#upload-btn-wrapper2').hide();
                        break;
                    case 'AlternateImage2':
                        this.AlternateImage2 = res.PreviewUrl;
                        this.AlternateImage2Name = res.FileName
                        $('#myImg3').show(2000, function () {
                            $('#main_img_close3').show();
                            $('#uploadSuccess').modal('show');
                        });
                        $('#upload-btn-wrapper3').hide();
                        break;
                    case 'AlternateImage3':
                        this.AlternateImage3 = res.PreviewUrl;
                        this.AlternateImage3Name = res.FileName
                        $('#myImg4').show(2000, function () { $('#main_img_close4').show(); });
                        $('#upload-btn-wrapper4').hide();
                        $('#uploadSuccess').modal('show');
                        break;
                }
            }
        }, error => {
            const Error = this.globalservice.ProcessError(error);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    this.FlashError(Error.ErrorMessage);
                    this.globalservice.Logout('Yes');
                }
                this.globalservice.Logout('No');
                return;
            }
            // else if (Error.Response == 4) {
            //     $('#myModal').modal('show');
            // }
            else {
                console.log(Error.ErrorMessage)
                // event.target.value = null;
                this.FlashError(Error.ErrorMessage);
                return;
            }
        })
    }

    Yes() {
        // console.log(this.section);
        switch (this.section) {
            case 'MainImage':
                this.uploadFile(this.section)
                break;
            case 'CertificateLogo':
                this.uploadFile(this.section)
                break;
            case 'NutritionalFacts':
                this.uploadFile(this.section)
                break;
            case 'AlternateImage1':
                this.uploadFile(this.section)
                break;
            case 'AlternateImage2':
                this.uploadFile(this.section)
                break;
            case 'AlternateImage3':
                this.uploadFile(this.section)
                break;
        }
    }

    brand() {
        this.http.post<any>(this.apiList.MerchantBrandResult, '', this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(response => {
                if (!response) { throw { Response: 0, ErrorMessage: 'Empty Response' }; }
                if (response.Response !== 1) { throw response; }
                return response;
            })
            .subscribe((response) => {
                if (response) {
                    console.log(response)
                    this.Brandlist = response.MerchantBrandsList;
                }
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {

            });
    }

    onChangebrand(value) {
        this.IdBrand = value
        console.log(this.IdBrand)
    }

    onSampleChanges(value) {
        this.IsSample = value.checked;
        this.editproduct.control.markAsDirty();
    }

}
