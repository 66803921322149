import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiCacheStorageService {

  private Cache = new Map();

  constructor() {
  }

  public Lookup(hash: string) : any {
    if (this.Cache.has(hash)) {
      return this.Cache.get(hash);
    }
    return null;
  }

  public Set(hash: string, o: any) : void {
    this.Cache.set(hash, o);
  }

  public RemoveIf(hash: string) {
    this.Cache.delete(hash);
  }

  public Clear() {
    this.Cache.clear();
  }

  
  public InvalidateById(api: string, id: number) : void {
    var hash = `${api}_${id}`
    console.log(`Remove entry from cache: ${hash}`);
    this.RemoveIf(hash);
  }

}
