import { Pipe, PipeTransform } from '@angular/core';
declare const $;
@Pipe({
  name: 'timepipe'
})
export class TimepipePipe implements PipeTransform {
 
  transform(value: number): string {
   var s=Math.floor(value);
   var fm = [
      Math.floor(Math.floor(s / 60) / 60) % 60, //HOURS
      Math.floor(s / 60) % 60, //MINUTES
      s % 60                   //SECONDS
   ];
      var t= $.map(fm, function (v, i) { return ((v < 10) ? '0' : '') + v; }).join(':');

    return t;
 }
  

}
