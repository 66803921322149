import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { GlobalService } from '../global.service';
import { ApilistService } from '../../service/Api/apilist.service';
import { TabHeadingDirective } from 'ngx-bootstrap/tabs';
declare const $;

@Component({
    selector: 'app-uploadcompanylogo',
    templateUrl: './uploadcompanylogo.component.html',
    styleUrls: ['./uploadcompanylogo.component.css']
})
export class UploadcompanylogoComponent implements OnInit {
    BaseFileUrl = environment.BaseFileUrl;
    ImageUrl = this.BaseFileUrl + '/CompaniesLogo/';
    IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
    @ViewChild('myInput', { static: false }) public myInputVariable: ElementRef;
    base64Profilepic;
    ProfileImg;
    img;
    posts;
    CompanyLogo;
    show = 1;
    MainImage: any;
    showyyy;
    files;
    AlertText: string;
    lst;
    nextLibAvailable = true;
    Submit_Btn = false;
    FileMD5Sum: any;
    uploadImage: any;
    FileName: any;
    uploadimage = false

    constructor(
        private http: HttpClient,
        private globalservice: GlobalService,
        public apiList: ApilistService) { }

    ngOnInit() {
        $('#alert_div').hide();
        $('#dots_spinner').hide();
        $('#uploadSuccess').modal('hide');
     this.getInfo()
    }

    getInfo(){
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.GetCompanyInfo, { IdMerchant: this.IdMerchant },
            this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (+result.Response !== 1) { throw result; }
                return result;
            }).subscribe((response) => {
                this.posts = response;
                if (this.posts.Response === 1) {
                    this.CompanyLogo = this.posts.ImageURL;
                    this.FileMD5Sum = this.posts.FileMD5Sum;
                    this.FileName = this.posts.FileName;
                    if (!this.CompanyLogo) {
                        $('#pro-img').hide();
                        $('#upload-btn-wrapper').show();
                        this.nextLibAvailable = false;
                        this.Submit_Btn = false;
                    } else {
                        $('#main_img_close').show();
                        $('#upload-btn-wrapper').hide();
                        this.Submit_Btn = false;
                      //  this.nextLibAvailable = ;
                    }
                }
            }, (err) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                }
                else if (Error.Response === 4){
                    this.nextLibAvailable = false;
                }
                else {
                    this.nextLibAvailable = false;
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
                $('#dots_spinner').hide();
            });
    }


    handleFileInput(files: FileList) {
        console.log("files[0].type "+files[0].type );

        
    if (files[0].type.search(/.jpg|.jpeg|.png|.tiff|.bmp /g) == -1) {

        this.FlashError("Please upload valid image file");
   //     alert('Please add an Image file ( jpg,jpeg,png,tiff,bmp )');
      }

      else{
        const blobFile = new Blob([files[0]], { type: files[0].type });
        $('#dots_spinner').show();
        this.globalservice.uploadFiles(blobFile).then((res: any) => {
            if (res.Response === 1) {
                $('#dots_spinner').hide();
               this.CompanyLogo = res.PreviewUrl;
                this.FileName=res.FileName;
                this.Submit_Btn = true;
                this.uploadimage = true
            }
        }, error => {
            this.FileMD5Sum = null;
            const Error = this.globalservice.ProcessError(error);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    this.FlashError(Error.ErrorMessage);
                    this.globalservice.Logout('Yes');
                }
                this.globalservice.Logout('No');
                return;
            } else {
                this.FlashError(Error.ErrorMessage);
                return;
            }
        });
      }
        
    }

    saveCompanyLogo()
        {
            let data = {
                FileName:this.FileName,
                IdMerchant:this.IdMerchant
            }
            this.http.post<any>(this.apiList.SaveCompanyLogo, data,  this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (+result.Response !== 1) { throw result; }
                return result;
            })
            .subscribe((res:any)=>{
                if (res.Response === 1) {
                    $('#dots_spinner').hide();
                    this.nextLibAvailable = true;
                    this.Submit_Btn = false;
                    this.uploadimage = false
                    $('#uploadSuccess').modal('show');
                }
            }, error => {
                this.FileMD5Sum = null;
                const Error = this.globalservice.ProcessError(error);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            })
        }

    Delete_logo() {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.DeletelogoUrl, { IdMerchant: this.IdMerchant },
            this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
                if (+result.Response !== 1) { throw result; }
                return result;
            }).subscribe((response) => {
                this.lst = response;
                if (this.lst.Response === 1) {
                    this.CompanyLogo=''
                    this.Submit_Btn = false;
                    this.nextLibAvailable = false;
                    $('#upload-btn-wrapper').show();
                    this.FlashError("Successfully deleted");
                }
            }, (err) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => {
                $('#dots_spinner').hide();
                $('#myImg').hide();
                $('#main_img_close').hide();
                $('#pro-img').hide();
            });
    }

    showww() {
        this.CompanyLogo = '';
        this.Submit_Btn = false;
        this.nextLibAvailable = false;
        $('#pro-img').hide();
        $('#myImg').hide();
        $('#main_img_close').hide();
        $('#upload-btn-wrapper').show();
    }

    FlashError(message: string) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(() => {
            $('#alert_div').hide();
        }, 3000);
    }
}
