import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { RouterService } from "./router.service";

@Injectable()
export class RouterGuard implements CanActivate {
  companyName = sessionStorage.getItem("companyName");
  MachineVisible

  CategoryVisible; DigitalSinageVisible; ReportVisible; UIVisible; AgeverfiactionVisible; FacialVisible
  ProductVisible; LocationVisible; PlaceMachineVisible; UsersVisible; CouponVisible; SpecialPricingVisible
  permissionlst=[];
  ThemeVisible;
  LogoVisible;
  InventoryManagementVisible;
  dashboardVisible: any;
  GlobalSettingVisible: any;
  constructor(public detailservices: RouterService, private router: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      return true
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) :boolean{
    this.detailservices.Permissiondetailrouter().then(function (res) {
      this.details=res;
      for (let j = 0; j < this.details.Permissions.length; j++) {
          this.permissionlst.push({ id: this.details.Permissions[j].IdPermission, State: this.details.Permissions[j].State });
        }
        for (let i = 0; i < this.permissionlst.length; i++) {
          if (this.permissionlst[i].id == 1 ){
            this.MachineVisible = this.permissionlst[i].State
          }
          if (this.permissionlst[i].id == 2) {
            this.CategoryVisible = this.permissionlst[i].State
          }
          if(this.permissionlst[i].id == 3) {
            this.ProductVisible = this.permissionlst[i].State   
          }     
          if (this.permissionlst[i].id == 4) {
              this.LocationVisible = this.permissionlst[i].State
            }
            if (this.permissionlst[i].id == 5) {
              this.PlaceMachineVisible = this.permissionlst[i].State
            }
            if (this.permissionlst[i].id == 6) {
              this.UsersVisible = this.permissionlst[i].State
            }
            if (this.permissionlst[i].id == 7) {
              this.CouponVisible = this.permissionlst[i].State
            }
            if (this.permissionlst[i].id == 8) {
              this.SpecialPricingVisible = this.permissionlst[i].State
            }
            if (this.permissionlst[i].id == 9) {
              this.DigitalSinageVisible = this.permissionlst[i].State
            }
            if (this.permissionlst[i].id == 10) {
              this.ReportVisible = this.permissionlst[i].State
            }
            if (this.permissionlst[i].id == 11) {
              this.UIVisible = this.permissionlst[i].State
            }    
            if (this.permissionlst[i].id == 12) {
              this.FacialVisible = this.permissionlst[i].State
            } 
            if (this.permissionlst[i].id == 13) {
              this.AgeverfiactionVisible = this.permissionlst[i].State
            }    
            if (this.permissionlst[i].id == 14) {
              this.ThemeVisible = this.permissionlst[i].State
            } 
            if (this.permissionlst[i].id == 15) {
              this.LogoVisible = this.permissionlst[i].State
            }   
            if (this.permissionlst[i].id == 16) {
              this.InventoryManagementVisible = this.permissionlst[i].State
            }  
            if (this.permissionlst[i].id == 17) {
              this.dashboardVisible = this.permissionlst[i].State
            }
            if (this.permissionlst[i].id == 18) {
              this.GlobalSettingVisible = this.permissionlst[i].State
            }
      }      
    }.bind(this)); 
    if(!this.MachineVisible && (state.url=='/'+this.companyName+'/dashboard/machine')){
      return this.MachineVisible;
    }
    if(!this.MachineVisible && (state.url=='/'+this.companyName+'/dashboard/machine')){
      return this.MachineVisible;
    }
    if((!this.CategoryVisible) && (state.url=='/'+this.companyName+'/dashboard/categories')){
      return this.CategoryVisible;
    }
    if(!this.ProductVisible && (state.url=='/'+this.companyName+'/dashboard/product')){
      return this.ProductVisible;
    }
    if(!this.LocationVisible && (state.url=='/'+this.companyName+'/dashboard/locations')){
      return this.LocationVisible;
    }
    if(!this.PlaceMachineVisible && (state.url=='/'+this.companyName+'/dashboard/placeaMachine')){
      return this.PlaceMachineVisible;
    }
    if(!this.UsersVisible && (state.url=='/'+this.companyName+'/dashboard/users')){
      return this.UsersVisible;
    }
    if(!this.CouponVisible && (state.url=='/'+this.companyName+'/dashboard/coupons')){
      return this.CouponVisible;
    }
    if(!this.SpecialPricingVisible && (state.url=='/'+this.companyName+'/dashboard/Specialpricing')){
      return this.SpecialPricingVisible;
    }
    if(!this.DigitalSinageVisible && (state.url=='/'+this.companyName+'/dashboard/digitalsignage')){
      return this.DigitalSinageVisible;
    }
    if(!this.ReportVisible && (state.url=='/'+this.companyName+'/dashboard/reporting')){
      return this.ReportVisible;
    }
    if(!this.UIVisible && (state.url=='/'+this.companyName+'/dashboard/Userinterfacescreen')){
      return this.UIVisible;
    }
    if(!this.FacialVisible && (state.url=='/'+this.companyName+'/dashboard/FacialRecog')){
      return this.FacialVisible;
    }
    if(!this.AgeverfiactionVisible && (state.url=='/'+this.companyName+'/dashboard/AgeVerify')){
      return this.AgeverfiactionVisible;
    }
    if(!this.ThemeVisible && (state.url=='/'+this.companyName+'/dashboard/Theme')){
      return this.ThemeVisible;
    }
    if(!this.LogoVisible && (state.url=='/'+this.companyName+'/dashboard/Uploadcompanylogo')){
      return this.LogoVisible;
    }
    if(!this.dashboardVisible && (state.url=='/'+this.companyName+'/dashboard/Map')){
      return this.dashboardVisible;
    }
    if(!this.GlobalSettingVisible && (state.url=='/'+this.companyName+'/dashboard/setting')){
      return this.GlobalSettingVisible;
    }


  else{
    return true
  }
  }
}
