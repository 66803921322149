import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ApilistService } from '../../../service/Api/apilist.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../global.service';
import { NgForm } from "@angular/forms";
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { MatSelect } from '@angular/material';
import { SMTPTypes } from '../../smtptypes.enum';
declare const $;
 

@Component({
  selector: 'app-globalsetting',
  templateUrl: './globalsetting.component.html',
  styleUrls: ['./globalsetting.component.css']
})
export class GlobalsettingComponent implements OnInit {

  //  @ViewChild('tabset', { static: false }) tabset: TabsetComponent;
  @ViewChild('server', { static: false }) public serverForm: NgForm
  Server = {
    IdSMTPServer: '0',
    SMTPServerHost: '',
    PortNumber: '',
    FromUserName: '',
    SMTPEmail: '',
    SMTPPassword: '',
    IsSSL_enabled: false
  }
  sendemail: any = { emailId: '' };
  upload_file: any;
//  TC: any
  AlertText: string;
  fileUrl;
  Template;
  IdEmailTemplateMaster = 0
  image
  status
  iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass = false;
  type = 'password';
  public files: NgxFileDropEntry[] = [];
  fileName: string = "No file choosen";
  Url: string = "";
  //merchant id
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
  companyName = sessionStorage.getItem("companyName");
  screenHeight: number;
  frameHeight: number;
  SMTPTypes:{SMTPTypeId: number; SMTPTypeName: string}[]=[];
  
  smtpavailable:SMTPTypes;

  smtpSelected=parseInt("3");
  ePropertyType =SMTPTypes;
  
  
  //@ViewChild(MatSelect, { static: false }) t4: MatSelect;
  constructor(private router: Router, public apiList: ApilistService, private http: HttpClient, private globalservice: GlobalService, private sanitizer: DomSanitizer) {
    this.smtpSelected=parseInt("3");

    for(var n in SMTPTypes) {
      if (typeof SMTPTypes[n] === 'number') {
          this.SMTPTypes.push({SMTPTypeId: <any>SMTPTypes[n], SMTPTypeName: n});
      }
  }
  
  }



  ngOnInit() {
   
    this.HidePopups();
   // this.changeSmtp(this.smtpSelected);
    this.getSmptpTypes();

    this.getServerDetails();
    
  }

  getSmptpTypes(){
  
         this.SMTPTypes=this.SMTPTypes.filter((key)=>key.SMTPTypeId==3);
         this.smtpSelected=this.SMTPTypes[0].SMTPTypeId;
  }

  HidePopups(){
    $("#alert_div").hide();
    $('#dots_spinner').hide();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerHeight;
    this.screenHeight = window.innerHeight;
    this.frameHeight = this.screenHeight - 360;
  }

  getServerDetails() {
    $('#dots_spinner').show();
    var data = {
      IdMerchant: this.IdMerchant,
      SMTPTypeId:parseInt(this.smtpSelected.toString())
    }
    this.http.post<any>(this.apiList.getSMTPDetails, data, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(resp => {
      if (resp == null || resp.Response == null) resp = { Response: 0, ErrorMessage: "Empty response" };
      resp.Response = +resp.Response;
      if (resp.Response != 1) throw resp;
      return resp;
    })
    .subscribe((response: any) => {
      //console.log(response)
      if (response.Response == 1) {
        this.Server = response.objDetails;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.Server = {
            IdSMTPServer: '0',
            SMTPServerHost: '',
            PortNumber: '',
            FromUserName: '',
            SMTPEmail: '',
            SMTPPassword: '',
            IsSSL_enabled: false
          }
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {$('#dots_spinner').hide(); });
  }

  
  checkSlider(value) {
    this.Server.IsSSL_enabled = value.checked
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  showModal() {
    $('#myModal').modal('show');
  }

  

  showPassword() {
    this.showPass = !this.showPass;
    if (this.showPass) {
      this.type = 'text';
      this.iconname = 'fa fa-fw fa-eye field-icon toggle-password';
    } else {
      this.type = 'password';
      this.iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
    }
  }
  changeSmtp(val) {
    this.smtpSelected = parseInt(val.toString());
    this.getServerDetails();
  }

  save(value) {
    var data;
    if (value == 'saved') {
      $('#dots_spinner').show();
      var url = this.apiList.saveSMTPDetailApi
      data = {
        SMTPTypeId:this.smtpSelected,
        IdSMTPServer: this.Server.IdSMTPServer,
        SMTPServerHost: this.Server.SMTPServerHost,
        PortNumber: this.Server.PortNumber,
        IdMerchant: this.IdMerchant,
        FromUserName: this.Server.FromUserName,// this.Server.FromUserName,
        SMTPEmail: this.Server.SMTPEmail,//this.Server.SMTPEmail,
        SMTPPassword: this.Server.SMTPPassword,
        IsSSL_enabled: this.Server.IsSSL_enabled,
        ToEmailId: '',
        
      }
    } else if (value == 'send') {
      url = this.apiList.sendToMAilApi
      return this.showModal();
    }
    this.http.post<any>(url, data, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(resp => {
      if (resp == null || resp.Response == null) resp = { Response: 0, ErrorMessage: "Empty response" };
      resp.Response = +resp.Response;
      if (resp.Response != 1) throw resp;
      return resp;
    })
    .subscribe((response: any) => {
      if (response.Response == 1) {
        this.FlashError("SMTP settings " + value);
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {  $('#dots_spinner').hide(); });
  }

  sendtestEmail() {
    // console.log(this.sendemail.emailId);
    $('#dots_spinner').show();
    var data = {
      IdSMTPServer: this.Server.IdSMTPServer,
      SMTPServerHost: this.Server.SMTPServerHost,
      PortNumber: this.Server.PortNumber,
      IdMerchant: this.IdMerchant,
      FromUserName: this.Server.FromUserName,// this.Server.FromUserName,
      SMTPEmail: this.Server.SMTPEmail,//this.Server.SMTPEmail,
      SMTPPassword: this.Server.SMTPPassword,
      IsSSL_enabled: this.Server.IsSSL_enabled,
      ToEmailId: this.sendemail.emailId,
    }
    this.http.post<any>(this.apiList.sendToMAilApi, data, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(resp => {
      if (resp == null || resp.Response == null) resp = { Response: 0, ErrorMessage: "Empty response" };
      resp.Response = +resp.Response;
      if (resp.Response != 1) throw resp;
      return resp;
    })
    .subscribe((response: any) => {
      this.sendemail.emailId = '';
      if (response.Response == 1) {
        this.FlashError(response.ErrorMessage);
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {  $('#dots_spinner').hide(); });
  }
  
  deleteSMTPSettings() {
    this.http.post<any>(this.apiList.deleteSMTPSettings, {  IdMerchant: this.IdMerchant ,SMTPTypeId:this.smtpSelected}, this.globalservice.Headers())
  .take(1)
    .timeout(25000)
    .map(resp => {
      if (resp == null || resp.Response == null) resp = { Response: 0, ErrorMessage: "Empty response" };
      resp.Response = +resp.Response;
      if (resp.Response != 1) throw resp;
      return resp;
    })
    .subscribe((response: any) => {
      if (response.Response == 1) {
        this.FlashError('Successfully deleted');
        this.Server.IsSSL_enabled = false;
        this.serverForm.reset();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { });
  }


}
