import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { RouterModule, Routes, Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { GlobalService } from '../../../../global.service';
import { ApilistService } from '../../../../../service/Api/apilist.service';
declare const $

@Component({
  selector: 'app-updateadloop',
  templateUrl: './updateadloop.component.html',
  styleUrls: ['./updateadloop.component.css']
})
export class UpdateadloopComponent implements OnInit {
  companyName = sessionStorage.getItem("companyName");
  // IdCampaign = sessionStorage.getItem('referIdCampaign');
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));

  details
  CampaignName
  lists
  selectedContent: any[] = [];
  ContentLists
  SelectedAdTime
  buttonDisabled
  selectedScreen
  selectedCampaign
  LocationOnScreen
  LoopDuration
  Statedate
  Enddate
  lst_AddContent = [];
  ContentName
  ContentType
  FilePath
  Duration
  ClientName
  AlertText
  ItemsCount
  SDate
  EDate
  posts
  SelectedScreenLocation
  disableinput = true;
  ScreenOrientation
  idScreen
  selectedScreenshow
  IdCampaign
  SelectedScreenLocationshow
  Aspectmode: any;
  p
  OriginalFileName: any;
  // @ViewChild('add', { static: false }) public addcontentForm: NgForm;
  location_on_screen = [  //Digitalsingage position
    { id: '0', name: 'Top Third', value: 'Top Third' },
    { id: '1', name: 'Middle', value: 'Middle' },
    { id: '2', name: 'Bottom Third', value: 'Bottom Third' },
    { id: '3', name: 'Full Screen Vertical', value: 'Full Screen Vertical' },
    // {id:'4', name:'Full Screen Horizontal', value:'Full Screen Horizontal'}
  ]
  array: any[];
  Timeslot =[];

  constructor(
    private router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private globalservice: GlobalService,
    public apiList: ApilistService
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.IdCampaign = params.campaignid;
    })
  }

  ngOnInit() {
    this.Timeslot = [
      { id: 'midnight to 6am', name: 'midnight to 6am' },
      { id: '6am to 11am', name: '6am to 11am' },
      { id: '11am to 2pm', name: '11am to 2pm' },
      { id: '2pm to 6pm', name: '2pm to 6pm' },
      { id: '6pm to 10pm', name: '6pm to 10pm' },
      { id: '10pm to midnight', name: '10pm to midnight' },
    ];
    $("#alert_div").hide();
    $('#dots_spinner').hide();
    this.selectedCampaign = this.IdCampaign;
    this.Campaigndetails();
    this.ScreenList();
    this.ContentList()
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {

  }

  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
  }

  ContentList() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ContentApi, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
 .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
      if (response.Response == 1) {
        this.ContentLists = response;
        this.ContentLists = this.ContentLists.lst_Content;
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  ScreenList() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ListApi, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
    .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
      if (response.Response == 1) {
        this.lists = response;
        this.lists = this.lists.lsy_Content;
        // console.log(  this.lists)
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  Campaigndetails() {
    this.http.post<any>(this.apiList.CampaignDetailApi, { IdCampaign: this.selectedCampaign }, this.globalservice.Headers())
   .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
      if (response.Response == 1) {
        this.details = response;
       //  console.log("campaign details"+JSON.stringify(this.details));

         
        this.CampaignName = this.details.Obj_CampaignClass.CampaignName;
        this.selectedScreenshow = this.details.Obj_CampaignClass.SpecificationName
        this.selectedScreen = this.details.Obj_CampaignClass.IdScreen
        this.SelectedScreenLocation = this.details.Obj_CampaignClass.LocationOnScreen;
        this.SelectedScreenLocationshow = this.details.Obj_CampaignClass.LocationOnScreen;
        //console.log( this.SelectedScreenLocationshow)
        this.Aspectmode = this.details.Obj_CampaignClass.AspectMode;
        if (this.SelectedScreenLocationshow == "Full Screen Horizontal") {
          this.disableinput = false
        } else {
          this.disableinput = true
        }
        this.SDate = this.details.Obj_CampaignClass.StringCampaignStartDate
        this.EDate = this.details.Obj_CampaignClass.StringCampaignEndDate
        this.lst_AddContent = this.details.lst_Content;
        this.lst_AddContent.forEach(element => {
           let SplitTime =  element.SelectedAdTime.split(',');
          var playTime= []
           SplitTime.forEach(element1 => {
            playTime.push({"PlayTime":element1})
           })
        element.DailyPlayTime = playTime
        })
        console.log(this.lst_AddContent)
        this.math();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  setDailyPlayTime(timeArray: any[]) {
    if (timeArray.length > 0) {
      var finaltext = '';
      timeArray.forEach(item => {
        finaltext += item.PlayTime + ', ';
      });
      return finaltext;
    }
  }

  screentype(type) {
    this.ScreenOrientation = type;
    if (this.ScreenOrientation == 'Horizontal') {
      this.SelectedScreenLocationshow = "Full Screen Horizontal";
      this.SelectedScreenLocation = "Full Screen Horizontal"
      this.disableinput = false
    } else {
      this.SelectedScreenLocationshow = "Select location on screen"
      this.SelectedScreenLocation = "";
      this.disableinput = true
    }
  }

  math() {
    this.LoopDuration = 0;
    for (var j = 0; j < this.lst_AddContent.length; j++) {
      this.LoopDuration += parseFloat(this.lst_AddContent[j]["Duration"]);
      // console.log(this.LoopDuration);
    }
  }

  getContentDetails(id) {
    this.selectedContent = id
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ContentDetailApi, { IdContent: this.selectedContent }, this.globalservice.Headers())
 .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
      this.details = response;
      this.buttonDisabled = true;
      //console.log(this.details);
      this.details = this.details.Obj_ContentClass;
      this.ContentName = this.details.ContentName;
      this.ContentType = this.details.ContentType;
      this.FilePath = this.details.FilePath;
      this.Duration = this.details.Duration;
      this.ClientName = this.details.ClientName;
      this.OriginalFileName = this.details.OriginalFileName;
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  // AddContent() {
  //   this.buttonDisabled = false;
  //   if (this.lst_AddContent != null) {
  //     let a = this.selectedContent;
  //     for (let i = 0; i < this.SelectedAdTime.length; i++) {
  //       this.DailyPlayTime.push({"PlayTime":this.SelectedAdTime[i]})
  //     }
  //     var IsExist = this.lst_AddContent.some(function (o) { return o["IdContent"] === a; });
  //     if (IsExist == true) {
  //       this.FlashError("Already Exist")
  //     }
  //     else if (IsExist == false) {
  //       this.lst_AddContent.push({
  //         IdContent: this.selectedContent,
  //         ContentName: this.ContentName,
  //         ClientName: this.ClientName,
  //         ContentType: this.ContentType,
  //         FilePath: this.FilePath,
  //         DailyPlayTime: this.SelectedAdTime,
  //         SelectedAdTime: this.SelectedAdTime,
  //         Duration: this.Duration
  //       });
  //     }
  //   }
  //   this.math();
  //   this.selectedContent = [];
  //   this.SelectedAdTime = [];
  // }

  AddContent() {
   // this.selectAllEl.nativeElement.classList.remove('btn_active');
   // this.unSelectAllEl.nativeElement.classList.remove('btn_active');
    this.buttonDisabled = false;
    var playTime= []
    for (let i = 0; i < this.SelectedAdTime.length; i++) {
      playTime.push({"PlayTime":this.SelectedAdTime[i]})
    }
    if (this.lst_AddContent != null) {
      const a = this.selectedContent;
      const IsExist = this.lst_AddContent.some(function (o) { return o['IdContent'] === a; });
      if (IsExist === true) {
        this.FlashError('Already Exist');
      } else if (IsExist === false) {
        this.lst_AddContent.push({
          IdContent: this.selectedContent,
          ContentName: this.ContentName,
          ClientName: this.ClientName,
          ContentType: this.ContentType,
          FilePath: this.FilePath,
          OriginalFileName: this.OriginalFileName,
          DailyPlayTime: playTime,
          SelectedAdTime: this.SelectedAdTime,
          Duration: this.Duration
        });
        this.math();
      }
    }

    console.log(this.lst_AddContent)
   // this.addcontentForm.reset();
  }

  remove(id) {
    this.ItemsCount = this.lst_AddContent.length;
    for (var i = 0; i < this.ItemsCount; i++) {
      if (this.lst_AddContent[i]["IdContent"] == id) {
        this.LoopDuration = this.LoopDuration - parseFloat(this.lst_AddContent[i]["Duration"]);
        //console.log( this.LoopDuration);
        this.lst_AddContent.splice(i, 1);
        this.ItemsCount = this.ItemsCount - 1;
      }
    }
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  Save() {
    this.SDate = this.convert(this.SDate);
    this.EDate = this.convert(this.EDate);
    // console.log(JSON.stringify({ "IdCampaign" : this.IdCampaign, "IdScreen": this.selectedScreen, "LocationOnScreen": this.SelectedScreenLocation, "CampaignName": this.CampaignName, "CampaignStartDate": this.SDate, "CampaignEndDate": this.EDate, "lst_CampaignContent": this.lst_AddContent, "IdMerchant": this.IdMerchant }));
    $('#dots_spinner').show();
    if (this.toTimestamp(this.SDate) <= this.toTimestamp(this.EDate)) {
      if (this.lst_AddContent.length == 0) {
        $('#dots_spinner').hide();
        this.FlashError("Add content and play time")
      } else {
        $('#dots_spinner').show();

        this.http.post<any>(this.apiList.UpdateCampaignApi,
           { IdCampaign: this.IdCampaign,
             IdScreen: this.selectedScreen,
              LocationOnScreen: this.SelectedScreenLocation, CampaignName: this.CampaignName, CampaignStartDate: this.SDate, CampaignEndDate: this.EDate, lst_CampaignContent: this.lst_AddContent, IdMerchant: this.IdMerchant, CampaignDuration: this.LoopDuration, AspectMode: this.Aspectmode }, this.globalservice.Headers())
 .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
          this.posts = response;
          // console.log(this.posts)
          if (this.posts.Response == 1) {
            this.router.navigate([this.companyName + '/dashboard/digitalsignage/Adloop']);
          }
        }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
      }
    }
    else if (this.SDate == undefined && this.EDate == undefined) {
      $('#dots_spinner').hide();
      this.FlashError("Select Start Date and End Date")
    }
    else {
      $('#dots_spinner').hide();
      this.FlashError("Start Date should be less than End Date");
    }
  }

  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  timeconversion(Time) {
    var time = Time;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes + ":" + "00"
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/digitalsignage/Adloop']);
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  selectAll() {
    $('#checkall').addClass("btn_active");
    $('#uncheckall').removeClass("btn_active");
    this.array = [];
    for (let i = 0; i < this.Timeslot.length; i++) {
      this.array.push(this.Timeslot[i].id);
    }
    this.SelectedAdTime = this.array;
    console.log(this.SelectedAdTime)
  }

  deselectAll() {
    $('#checkall').removeClass("btn_active");
    $('#uncheckall').addClass("btn_active");
    this.SelectedAdTime = [];
  }

  AddScreenbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage']); }
  AddContentbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Addcontent']); }
  Adloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Adloop']); }
  placeloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/placeloop']); }
  preview() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Previewcampaign']); }
  Calendar() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaigncalendar']); }
  report() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaignreports']); }
}
