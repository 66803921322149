import { Component, OnInit, ElementRef } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DragDropDirectiveModule } from 'angular4-drag-drop';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DOCUMENT } from '@angular/common';
import { debug } from 'util';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
declare const $;

@Component({
  selector: 'app-gridlayoutactivemachine',
  templateUrl: './gridlayoutactivemachine.component.html',
  styleUrls: ['./gridlayoutactivemachine.component.css']
})
export class GridlayoutactivemachineComponent implements OnInit {

  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'));
  // IdMachine = sessionStorage.getItem("refrenceid");
  companyName = sessionStorage.getItem('companyName');
  IdMachine;
  count = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
  events: string[] = [];
  IdGrid: any;
  Slavenumber = 1;
  MachineType: string;
  item;
  ActivationDate;
  Activated;
  MasterNoOfMotorsPerTray;
  MasterNoOfTrays;
  MasterMachineModel;
  MasterMachineModel1;
  MasterMachineModel2;
  MasterMachineSerial;
  MasterMachine = '0';
  Machine;
  LinkCode = '';
  Snumbers;
  Mnumbers;
  added;
  abc;
  SlaveNoOfTrays;
  SlaveNoOfMotorsPerTray;
  SlaveMachineModel;
  SlaveMachineModel1;
  SlaveMachineModel2;
  SlaveMachine;
  SlaveMachineSerial;
  IdSlot;
  IdMaster;
  IdSlave;
  buttonDisabled = false;
  lists;
  MasterPositionInGrid;
  SlavesPositionInGrid = [];
  MachineName = '';
  MasterglobalFlag = true;
  SlaveglobalFlag = true;
  posts;
  filled = false;
  Tdetails;
  Type = '';
  MType;
  AlertText;
  Machine_Id = 0;
  Cannisters;
  Tea;
  WholeBean;
  Bean; Cannister;
  maxTray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
    30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
  MaxTrayAllowed: any;
  MaxMotorsAllowed: any;
  MinTrayAllowed: any;
  MinMotorsAllowed: any;
  ModalName: string;
  CanBeMaster: boolean;
  CanBeslave: boolean;
  IdManufacturer: any;
  IdMachineModel: any;
  ManufacturerName: any;
  MachineModelName;
  Date: any;
  BuildStartDate: any;
  BuildCompleteDate: any;
  Assign: any;
  constructor(
    public apiList: ApilistService,
    private router: Router,
    private elRef: ElementRef,
    private route: ActivatedRoute,
    private http: HttpClient,
    private globalservice: GlobalService) {
    this.route.params.subscribe((get) => {
      this.IdMachine = get.refrenceid;
      this.Type = get.MachineType;
      this.CanBeMaster = JSON.parse(get.CanBeMaster);
      this.CanBeslave = JSON.parse(get.CanBeslave);
      this.IdManufacturer = get.IdManufacturer;
      this.ManufacturerName = get.ManufacturerName;
      this.IdMachineModel = get.IdMachineModel;
      this.getlayout();
    });
  }

  ngOnInit() {
    this.Date = new Date();
    this.hidepops();
  }
  hidepops() {
    $('#alert_div').hide();
  }
  getlayout() {
    this.http.post<any>(this.apiList.MachineByIdUrl, { IdMachine: this.IdMachine }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.lists = response;
          this.MasterPositionInGrid = this.lists.Obj_Machines.Obj_Machine.PositionInGrid;
          if ((this.CanBeMaster && this.CanBeslave) || (this.CanBeMaster && (!this.CanBeslave))) {
            this.startDrag('Master');
          } else if ((!this.CanBeMaster) && (!this.CanBeslave)) {
            this.startDrag('Coffee');
          }
          this.addDropItem(this.MasterPositionInGrid, this.lists.Obj_Machines.Obj_Machine.Machine);
          $('#M_' + this.MasterPositionInGrid).addClass('fill_done');
          for (let i = 0; i < this.lists.Obj_Machines.lst_Slaves.length; i++) {
            this.startDrag('Slave');
            this.SlavesPositionInGrid.push({
              position: this.lists.Obj_Machines.lst_Slaves[i].PositionInGrid,
              Machine: this.lists.Obj_Machines.lst_Slaves[i].Machine
            });
            this.addDropItem(this.lists.Obj_Machines.lst_Slaves[i].PositionInGrid, this.lists.Obj_Machines.lst_Slaves[i].Machine);
            const position = this.lists.Obj_Machines.lst_Slaves[i].PositionInGrid;
            $('#S_' + position).addClass('fill_done');
          }
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
  }

  async OpenModal(id) {
    await this.http.post<any>(this.apiList.MachineByIdUrl, { IdMachine: this.IdMachine },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.lists = response;
          this.MaxTrayAllowed = this.lists.Obj_Machines.Obj_Machine.NoOfTrays;
          this.MinTrayAllowed = this.lists.Obj_Machines.Obj_Machine.MinTrays;
          this.MaxMotorsAllowed = this.lists.Obj_Machines.Obj_Machine.NoOfMotorsPerTray;
          this.MinMotorsAllowed = this.lists.Obj_Machines.Obj_Machine.MinMotors;
          this.IdMaster = this.lists.Obj_Machines.Obj_Machine.IdMachine;
          this.filled = false;
          this.IdSlot = id;
          this.resetform();
          if ($('#M_' + id).hasClass('fill_done')) {
            this.filled = true;
            this.Assign = this.lists.Obj_Machines.Obj_Machine.AssignTo;
            this.IdMaster = this.lists.Obj_Machines.Obj_Machine.IdMachine;
            this.Machine_Id = this.lists.Obj_Machines.Obj_Machine.IdMachine;
            this.MasterMachine = this.lists.Obj_Machines.Obj_Machine.Machine;
            this.Activated = this.lists.Obj_Machines.Obj_Machine.Activated;
            this.LinkCode = this.lists.Obj_Machines.Obj_Machine.LinkCode;
            this.MasterMachineSerial = this.lists.Obj_Machines.Obj_Machine.MachineSerial;
            this.MachineType = this.lists.Obj_Machines.Obj_Machine.MachineType;
            this.MasterMachineModel = this.lists.Obj_Machines.Obj_Machine.MachineModel;
            this.MasterMachineModel = this.MasterMachineModel.split(' ');
            this.MasterMachineModel1 = this.MasterMachineModel[0];
            this.MasterMachineModel2 = this.lists.Obj_Machines.Obj_Machine.MachineModelNumber;
            this.MasterNoOfTrays = this.lists.Obj_Machines.Obj_Machine.NoOfTrays;
            this.MasterNoOfMotorsPerTray = this.lists.Obj_Machines.Obj_Machine.NoOfMotorsPerTray;
            this.ActivationDate = this.lists.Obj_Machines.Obj_Machine.ActivationDate.split('T');
            this.ActivationDate = this.ActivationDate[0];
            this.BuildStartDate = this.lists.Obj_Machines.Obj_Machine.BuildStartDate.split('T');
            this.BuildStartDate = this.BuildStartDate[0];
            $('#sdate').val(this.BuildStartDate);
            this.BuildCompleteDate = this.lists.Obj_Machines.Obj_Machine.BuildCompleteDate.split('T');
            this.BuildCompleteDate = this.BuildCompleteDate[0];
            $('#cdate').val(this.BuildCompleteDate);
            if ((!this.CanBeMaster) && (!this.CanBeslave)) {
              this.Cannisters = this.lists.Obj_Machines.Obj_Machine.Cannisters;
              this.Tea = this.lists.Obj_Machines.Obj_Machine.Tea;
              this.WholeBean = this.lists.Obj_Machines.Obj_Machine.WholeBean;
            }
            $('#date1').val(this.ActivationDate);
            $('#date3').val(this.ActivationDate);
          } else if ($('#S_' + id).hasClass('fill_done')) {
            this.filled = true;
            for (let i = 0; i < this.lists.Obj_Machines.lst_Slaves.length; i++) {
              if (this.IdSlot == this.lists.Obj_Machines.lst_Slaves[i].PositionInGrid) {
                this.MachineType = 'Slave-Cube';
                this.IdSlave = this.lists.Obj_Machines.lst_Slaves[i].IdSlave;
                this.Machine_Id = this.lists.Obj_Machines.lst_Slaves[i].IdSlave;
                this.SlaveMachine = this.lists.Obj_Machines.lst_Slaves[i].Machine;
                this.SlaveMachineSerial = this.lists.Obj_Machines.lst_Slaves[i].MachineSerial;
                this.SlaveNoOfTrays = this.lists.Obj_Machines.lst_Slaves[i].NoOfTrays;
                this.SlaveNoOfMotorsPerTray = this.lists.Obj_Machines.lst_Slaves[i].NoOfMotorsPerTray;
                this.SlaveMachineModel = this.lists.Obj_Machines.lst_Slaves[i].MachineModel.replace(/  +/g, ' ');
                this.SlaveMachineModel = this.SlaveMachineModel.split(' ');

                this.SlaveMachineModel1 = this.SlaveMachineModel[0];
                this.SlaveMachineModel2 = this.SlaveMachineModel[1];
                this.Activated = this.lists.Obj_Machines.lst_Slaves[i].Activated;
                this.ActivationDate = this.lists.Obj_Machines.lst_Slaves[i].ActivationDate.split('T');
                this.ActivationDate = this.ActivationDate[0];
                $('#date2').val(this.ActivationDate);
              }
            }
          } else {
            this.Machine_Id = 0;
          }
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
  }

  dragEnter(event) {////// console.log("dragEnter");
  }
  dragLeave(event) {////// console.log("dragLeave");
  }
  dragoverMouse(event) {////// console.log("dragoverMouse");
  }
  releaseDrop(event) { ////// console.log("releaseDrop");
  }
  startDrag(a) {
    this.MachineType = a;
  }

  addDropItem(id: number, name) {
    this.SlaveMachine = '';
    this.IdGrid = id;
    if (name !== undefined) {
      this.SlaveMachine = name;
    }
    if (name === undefined) {
      this.SlaveMachine = '';
    }
    if (this.MachineType === 'Master') {
      if (this.CanBeMaster && !this.CanBeslave) {
        $('#Master_modal').modal('show');
        this.OpenModal(20);
      }
      const plus8 = (parseInt(this.IdGrid, 0) + 8);
      const minus8 = (parseInt(this.IdGrid, 0) - 8);
      const plus1 = (parseInt(this.IdGrid, 0) + 1);
      const minus1 = (parseInt(this.IdGrid, 0) - 1);
      if ($('.Machine_dragDiv').find('.Master_Div').length === 1) {
        $('#' + this.IdGrid).empty();
        $('#' + this.IdGrid).append('<div class="Machine_dragDiv" data-toggle="modal" data-target="#Master_modal"><div class="Master_Div" id=M_' + this.IdGrid + '>' + this.MasterMachine + '</div></div></div>');
        $('#' + this.IdGrid).addClass('placed');

        this.MasterglobalFlag = false;
        this.SlaveglobalFlag = false;
        if ((this.CanBeMaster && this.CanBeslave) || (!this.CanBeMaster) && (!this.CanBeslave)) {
          if (!$('#' + plus8).hasClass('placed')) {
            $('#' + plus8).addClass('dashedDiv');
          }
          if (!$('#' + minus8).hasClass('placed')) {
            $('#' + minus8).addClass('dashedDiv');
          }
          if (!$('#' + plus1).hasClass('placed')) {
            if ((this.IdGrid !== 8) && (this.IdGrid !== 16) && (this.IdGrid !== 24) && (this.IdGrid !== 32) && (this.IdGrid !== 40)) {
              $('#' + plus1).addClass('dashedDiv');
            }
          }
          if (!$('#' + minus1).hasClass('placed')) {
            if ((this.IdGrid !== 9) && (this.IdGrid !== 17) && (this.IdGrid !== 25) && (this.IdGrid !== 33)) {
              $('#' + minus1).addClass('dashedDiv');
            }
          }
        }

      } else {
        this.FlashError('Not allowed');
      }
    }
    if (this.MachineType === 'Slave') {
      const plus8 = (parseInt(this.IdGrid, 0) + 8);
      const minus8 = (parseInt(this.IdGrid, 0) - 8);
      const plus1 = (parseInt(this.IdGrid, 0) + 1);
      const minus1 = (parseInt(this.IdGrid, 0) - 1);
      if ($('#' + this.IdGrid).hasClass('dashedDiv')) {
        $('#' + this.IdGrid).empty();
        $('#' + this.IdGrid).append('<div class="Machine_dragDiv" data-toggle="modal" data-target="#Slave_modal"><div class="Slave_Div" style="margin:0px" id=S_' + this.IdGrid + '>' + this.SlaveMachine + '</div></div>');
        $('#' + this.IdGrid).addClass('placed');
        this.MasterglobalFlag = false;
        this.SlaveglobalFlag = false;
        if (!$('#' + plus8).hasClass('placed')) {
          $('#' + plus8).addClass('dashedDiv');
        }
        if (!$('#' + minus8).hasClass('placed')) {
          $('#' + minus8).addClass('dashedDiv');
        }
        if (!$('#' + plus1).hasClass('placed')) {
          if ((this.IdGrid !== 8) && (this.IdGrid !== 16) && (this.IdGrid !== 24) && (this.IdGrid !== 32) && (this.IdGrid !== 40)) {
            $('#' + plus1).addClass('dashedDiv');
          }
        }
        if (!$('#' + minus1).hasClass('placed')) {
          if ((this.IdGrid !== 9) && (this.IdGrid !== 17) && (this.IdGrid !== 25) && (this.IdGrid !== 33)) {
            $('#' + minus1).addClass('dashedDiv');
          }
        }
      } else if ($('#' + this.IdGrid).hasClass('dashedDiv') && $('#' + this.IdGrid).addClass('placed')) {
        $('#' + this.IdGrid).removeClass('dashedDiv');
      } else if (!$('#' + this.IdGrid).hasClass('dashedDiv')) {
        this.FlashError('Not allowed');
      }
    }
    if (this.MachineType === 'Coffee') {
      const plus8 = (parseInt(this.IdGrid, 0) + 8);
      const minus8 = (parseInt(this.IdGrid, 0) - 8);
      const plus1 = (parseInt(this.IdGrid, 0) + 1);
      const minus1 = (parseInt(this.IdGrid, 0) - 1);
      if ($('.Machine_dragDiv').find('.Master_Div').length === 1) {
        $('#' + this.IdGrid).empty();
        $('#' + this.IdGrid).append('<div class="Machine_dragDiv" data-toggle="modal" data-target="#Coffee_modal"><div class="Master_Div" id=M_' + this.IdGrid + '>' + this.MasterMachine + '</div></div></div>');
        $('#' + this.IdGrid).addClass('placed');
        this.MasterglobalFlag = false;
        this.SlaveglobalFlag = false;
        if ((!this.CanBeMaster) && (!this.CanBeslave)) {
          $('#Master_modal').modal('show');
          this.OpenModal(20);
          if (!$('#' + plus8).hasClass('placed')) {
            $('#' + plus8).addClass('dashedDiv');
          }
          if (!$('#' + minus8).hasClass('placed')) {
            $('#' + minus8).addClass('dashedDiv');
          }
          if (!$('#' + plus1).hasClass('placed')) {
            if ((this.IdGrid !== 8) && (this.IdGrid !== 16) && (this.IdGrid !== 24) && (this.IdGrid !== 32) && (this.IdGrid !== 40)) {
              $('#' + plus1).addClass('dashedDiv');
            }
          }
          if (!$('#' + minus1).hasClass('placed')) {
            if ((this.IdGrid !== 9) && (this.IdGrid !== 17) && (this.IdGrid !== 25) && (this.IdGrid !== 33)) {
              $('#' + minus1).addClass('dashedDiv');
            }
          }
        }
        $('#Master_modal').modal('show');
        this.OpenModal(20);
      } else {
        this.FlashError('Not allowed');
      }
    }
  }

  dropEventMouse(event) {
    ////// console.log("dropEventMouse");
  }
  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    // alert(this.ActivationDate);
  }
  addEvent2(type: string, event: MatDatepickerInputEvent<Date>) {
    // alert(this.ActivationDate);
  }
  addEvent3(type: string, event: MatDatepickerInputEvent<Date>) {
    // alert(this.ActivationDate);
  }
  Completedate(value) {
    this.BuildCompleteDate = value;
  }
  startdate(value) {
    this.BuildStartDate = value;
  }
  randomNumber() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (let i = 0; i < 8; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    this.LinkCode = text;
    return text;
  }

  updateMasterCubes() {
    this.ActivationDate = $('#date1').val();
    this.http.post<any>(this.apiList.MachineSNCheckUrl,
      {
        MachineSerial: this.MasterMachineSerial,
        IdMachine: this.Machine_Id
      },
      this.globalservice.Headers()).subscribe((response) => {
        this.Snumbers = response;
        if (this.Snumbers.Response === 0) {
          this.FlashError('Machine Serial number is already in Use');
        } else if (this.Snumbers.Response === 1) {
          this.MasterMachineModel = this.MasterMachineModel1 + ' ' + this.MasterMachineModel2;
          this.http.post<any>(this.apiList.UpdateMachineUrl,
            {
              IdManufacturer: this.IdManufacturer,
              IdMachineModel: this.IdMachineModel,
              MachineModelNumber: this.MasterMachineModel2,
              PositionInGrid: this.IdSlot,
              IdMachine: this.IdMaster,
              LinkCode: this.LinkCode,
              Machine: this.MasterMachine,
              MachineSerial: this.MasterMachineSerial,
              MachineModel: this.MasterMachineModel,
              NoOfTrays: this.MasterNoOfTrays,
              NoOfMotorsPerTray: this.MasterNoOfMotorsPerTray,
              ActivationDate: this.ActivationDate,
              Activated: this.Activated,
              CreatedBy: this.IdMerchant,
              AssignTo: this.Assign,
              BuildStartDate: this.BuildStartDate,
              BuildCompleteDate: this.BuildCompleteDate
            }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(resp => {
              if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
              if (+resp.Response !== 1) { throw resp; }
              return resp;
            }).subscribe((response) => {
              this.Tdetails = response;
              if (this.Tdetails.Response === 2) {
                $('#Master_modal').modal('hide');
                this.FlashError('Please Remove Products From Machines');
              } else if (this.Tdetails.Response === 1) {
                this.MasterglobalFlag = true;
                this.SlaveglobalFlag === true;
                $('#Master_modal').modal('hide');
                this.FlashError('Updated Successfully');
                this.http.post<any>(this.apiList.MachineByIdUrl,
                  { IdMachine: this.IdMachine }, this.globalservice.Headers())
                  .timeout(25000)
                  .take(1)
                  .map(resp => {
                    if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                    if (+resp.Response !== 1) { throw resp; }
                    return resp;
                  }).subscribe((response) => {
                    if (response.Response === 1) {
                      this.lists = response;
                    }
                  }, (err: any) => {
                    const Error = this.globalservice.ProcessError(err);
                    if (Error.Response === 35) {
                      if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                      }
                      this.globalservice.Logout('No');
                      return;
                    } else {
                      this.FlashError(Error.ErrorMessage);
                      return;
                    }
                  });
                if (this.CanBeMaster && (!this.CanBeslave) || !this.CanBeMaster && (!this.CanBeslave)) {
                  setTimeout(() => {
                    this.router.navigate(['./Superadmindashboard/Machineslist']);
                  }, 1000);

                }
              } else if (this.Tdetails.Response === 35) {
                $('#Master_modal').modal('hide');
                this.FlashError('Existing Machine Number');
              }
            }, (err: any) => {
              const Error = this.globalservice.ProcessError(err);
              if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                  this.FlashError(Error.ErrorMessage);
                  this.globalservice.Logout('Yes');
                }
                this.globalservice.Logout('No');
                return;
              } else {
                this.FlashError(Error.ErrorMessage);
                return;
              }
            });
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });

  }

  UpdateSlaveCubes() {
    this.ActivationDate = $('#date2').val();
    if (this.SlaveMachine === 0) {
      $('#dots_spinner').hide();
      this.FlashError('Slave machine number is Invalid');
    } else {
      this.http.post<any>(this.apiList.MachineSNCheckUrl,
        {
          MachineSerial: this.SlaveMachineSerial,
          IdMachine: this.Machine_Id
        }, this.globalservice.Headers()).subscribe((response) => {
          this.Snumbers = response;
          if (this.Snumbers.Response === 0) {
            $('#dots_spinner').hide();
            this.FlashError('Machine Serial number is already in Use');
          } else if (this.Snumbers.Response === 1) {
            this.SlaveMachineModel = this.SlaveMachineModel1 + ' ' + this.SlaveMachineModel2;
            this.http.post<any>(this.apiList.UpdateMachineUrl,
              {
                IdManufacturer: this.IdManufacturer,
                IdMachineModel: this.IdMachineModel,
                MachineModelNumber: this.SlaveMachineModel2,
                PositionInGrid: this.IdSlot,
                IdMachine: this.IdSlave,
                LinkCode: this.LinkCode,
                Machine: this.SlaveMachine,
                MachineSerial: this.SlaveMachineSerial,
                MachineType: this.MachineType,
                MachineModel: this.SlaveMachineModel,
                NoOfTrays: this.SlaveNoOfTrays,
                NoOfMotorsPerTray: this.SlaveNoOfMotorsPerTray,
                ActivationDate: this.ActivationDate,
                Activated: this.Activated,
                CreatedBy: this.IdMerchant,
                AssignTo: this.Assign
              },
              this.globalservice.Headers()).subscribe((response) => {
                this.Tdetails = response;
                if (this.Tdetails.Response === 1) {
                  this.router.navigate(['./Superadmindashboard/Machineslist']);
                  this.FlashError('Updated Successfully');
                  $('#Slave_modal').modal('hide');
                  this.http.post<any>(this.apiList.MachineByIdUrl, { IdMachine: this.IdMachine },
                    this.globalservice.Headers())
                    .timeout(25000)
                    .take(1)
                    .map(resp => {
                      if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                      if (+resp.Response !== 1) { throw resp; }
                      return resp;
                    }).subscribe((response) => {
                      if (response.Response === 1) {
                        this.lists = response;
                      }
                    }, (err: any) => {
                      const Error = this.globalservice.ProcessError(err);
                      if (Error.Response === 35) {
                        if (Error.ErrorMessage) {
                          this.FlashError(Error.ErrorMessage);
                          this.globalservice.Logout('Yes');
                        }
                        this.globalservice.Logout('No');
                        return;
                      } else {
                        this.FlashError(Error.ErrorMessage);
                        return;
                      }
                    });
                  $('#' + this.IdSlot).empty();
                  $('#' + this.IdSlot).append('<div class="Machine_dragDiv" data-toggle="modal" data-target="#Slave_modal"><div class="Slave_Div" style="margin:0px" id=S_' + this.IdSlot + '>' + this.SlaveMachine + '</div></div>');
                  $('#' + this.IdSlot).addClass('placed');
                  $('#S_' + this.IdSlot).addClass('fill_done');
                } else if (this.Tdetails.Response === 35) {
                  this.FlashError('Existing Machine Number');
                } else if (this.Tdetails.Response === 2) {
                  this.FlashError('Please Remove Products From Machines');
                }
              }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                  if (Error.ErrorMessage) {
                    this.FlashError(Error.ErrorMessage);
                    this.globalservice.Logout('Yes');
                  }
                  this.globalservice.Logout('No');
                  return;
                } else {
                  this.FlashError(Error.ErrorMessage);
                  return;
                }
              });
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        });
    }
  }

  updateCoffee() {
    this.ActivationDate = $('#date3').val();
    this.http.post<any>(this.apiList.MachineSNCheckUrl,
      { MachineSerial: this.MasterMachineSerial, IdMachine: this.Machine_Id },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.Snumbers = response;
        if (this.Snumbers.Response === 0) {
          this.FlashError('Machine Serial number is already in Use');
        } else if (this.Snumbers.Response === 1) {
          this.MasterMachineModel = this.MasterMachineModel1 + ' ' + this.MasterMachineModel2;
          this.http.post<any>(this.apiList.UpdateMachineUrl,
            {
              IdManufacturer: this.IdManufacturer,
              IdMachineModel: this.IdMachineModel,
              Cannisters: this.Cannisters,
              Tea: this.Tea,
              WholeBean: this.WholeBean,
              Activated: this.Activated,
              MachineModelNumber: this.MasterMachineModel2,
              PositionInGrid: this.IdSlot,
              IdMachine: this.IdMaster,
              LinkCode: this.LinkCode,
              Machine: this.MasterMachine,
              MachineSerial: this.MasterMachineSerial,
              MachineModel: this.MasterMachineModel,
              ActivationDate: this.ActivationDate,
              CreatedBy: this.IdMerchant,
              AssignTo: this.Assign
            },
            this.globalservice.Headers()).subscribe((response) => {
              this.Tdetails = response;
              if (this.Tdetails.Response === 2) {
                $('#Master_modal').modal('hide');
                this.FlashError('Please Remove Products From Machines');
              } else if (this.Tdetails.Response === 1) {
                this.MasterglobalFlag = true;
                this.SlaveglobalFlag === true;
                $('#Master_modal').modal('hide');
                this.FlashError('Updated Successfully');

                this.http.post<any>(this.apiList.MachineByIdUrl,
                  { IdMachine: this.IdMachine }, this.globalservice.Headers())
                  .timeout(25000)
                  .take(1)
                  .map(resp => {
                    if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                    if (+resp.Response !== 1) { throw resp; }
                    return resp;
                  }).subscribe((response) => {
                    if (response.Response === 1) {
                      this.lists = response;
                    }
                  }, (err: any) => {
                    const Error = this.globalservice.ProcessError(err);
                    if (Error.Response === 35) {
                      if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                      }
                      this.globalservice.Logout('No');
                      return;
                    } else {
                      this.FlashError(Error.ErrorMessage);
                      return;
                    }
                  });
                setTimeout(() => {
                  this.router.navigate(['./Superadmindashboard/Machineslist']);
                }, 500);


              } else if (this.Tdetails.Response === 35) {
                $('#Master_modal').modal('hide');
                this.FlashError('Existing Machine Number');
              }
            }, (err: any) => {
              const Error = this.globalservice.ProcessError(err);
              if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                  this.FlashError(Error.ErrorMessage);
                  this.globalservice.Logout('Yes');
                }
                this.globalservice.Logout('No');
                return;
              } else {
                this.FlashError(Error.ErrorMessage);
                return;
              }
            });
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
  }
  submitMasterCubes() {
    this.MasterMachineModel = this.MasterMachineModel1 + ' ' + this.MasterMachineModel2;
    this.http.post<any>(this.apiList.AddMachineUrl, {
      IdManufacturer: this.IdManufacturer,
      IdMachineModel: this.IdMachineModel,
      MachineModelNumber: this.MasterMachineModel2,
      PositionInGrid: this.IdSlot,
      MachineName: this.MachineName,
      IdMasterMachine: 0, IsMaster: true
      , LinkCode: this.LinkCode,
      Machine: this.MasterMachine,
      MachineSerial: this.MasterMachineSerial,
      MachineType: 'Master-Cube',
      IdMachineType: 2,
      MachineModel: this.MasterMachineModel,
      NoOfTrays: this.MasterNoOfTrays,
      NoOfMotorsPerTray: this.MasterNoOfMotorsPerTray,
      ActivationDate: this.ActivationDate,
      Activated: this.Activated,
      CreatedBy: this.IdMerchant,
      AssignTo: this.Assign, MaxCartSize: 4
    }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.added = response;
        if (this.added.Response === 1) {
          $('#M_' + this.IdSlot).addClass('fill_done');
          this.MasterglobalFlag = true;
          this.SlaveglobalFlag = true;
          this.IdMachine = this.added.IdMachine;
        }
        if (this.added.Response === 2) {
          this.FlashError('Existing Machine Serial Number');
        } else if (this.added.Response === 35) {
          if (this.added.ErrorMessage) {
            this.FlashError(this.added.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
        } else {
          this.FlashError(this.added.ErrorMessage);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
    $('#date1').val('');

    this.MasterMachine = '';
    this.MasterMachineSerial = '';
    this.MachineType = '';
    this.MasterMachineModel = '';
    this.MasterNoOfTrays = '';
    this.MasterNoOfMotorsPerTray = '';
    this.Activated = '';
  }

  submitSlaveCubes() {
    if (this.SlaveMachine === 0) {
      $('#dots_spinner').hide();
      this.FlashError('Slave machine number is Invalid');
    } else {
      if (!this.ActivationDate) {
        $('#dots_spinner').hide();
        this.FlashError('Select Activation date');
      } else {
        this.http.post<any>(this.apiList.MachineSNCheckUrl,
          {
            MachineSerial: this.SlaveMachineSerial,
            IdMachine: this.Machine_Id
          }
          , this.globalservice.Headers())
          .timeout(25000)
          .take(1)
          .map(resp => {
            if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
            if (+resp.Response !== 1) { throw resp; }
            return resp;
          }).subscribe((response) => {
            this.Snumbers = response;
            if (this.Snumbers.Response === 0) {
              $('#dots_spinner').hide();
              this.FlashError('Machine Serial number is already in Use');
            } else if (this.Snumbers.Response === 1) {
              this.SlaveMachineModel = this.SlaveMachineModel1 + ' ' + this.SlaveMachineModel2;
              this.http.post<any>(this.apiList.AddMachineUrl, {
                IdManufacturer: this.IdManufacturer,
                IdMachineModel: this.IdMachineModel,
                MachineModelNumber: this.SlaveMachineModel2,
                PositionInGrid: this.IdSlot,
                MachineName: this.MachineName,
                IdMasterMachine: this.IdMaster,
                IsMaster: false,
                LinkCode: '', Machine: this.SlaveMachine,
                MachineSerial: this.SlaveMachineSerial,
                MachineType: 'Slave-Cube',
                IdMachineType: 2,
                MachineModel: this.SlaveMachineModel,
                NoOfTrays: this.SlaveNoOfTrays,
                NoOfMotorsPerTray: this.SlaveNoOfMotorsPerTray,
                ActivationDate: this.ActivationDate,
                Activated: this.Activated,
                CreatedBy: this.IdMerchant,
                AssignTo: this.Assign
              }, this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(resp => {
                  if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                  if (+resp.Response !== 1) { throw resp; }
                  return resp;
                }).subscribe((response) => {
                  this.added = response;
                  if (this.added.Response === 1) {
                    $('#Slave_modal').modal('hide');
                    $('#S_' + this.IdSlot).addClass('fill_done');
                    this.SlaveglobalFlag = true;
                    this.MasterglobalFlag = true;
                    $('#S_' + this.IdSlot).text(this.SlaveMachine);
                  } else if (this.added.Response === 35) {
                    if (this.AlertText) {
                      this.FlashError(this.added.ErrorMessage);
                      this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                  } else {
                    this.FlashError(this.added.ErrorMessage);
                  }
                }, (err: any) => {
                  const Error = this.globalservice.ProcessError(err);
                  if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                      this.FlashError(Error.ErrorMessage);
                      this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                  } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                  }
                });
            } else if (this.Snumbers.Response === 35) {
              if (this.Snumbers.ErrorMessage) {
                this.FlashError(this.Snumbers.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
            } else {
              this.FlashError(this.Snumbers.ErrorMessage);
            }
          }, (err: any) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          });
      }
    }
  }

  back() {
    this.router.navigate(['./Superadmindashboard/Machineslist']);
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  resetform() {
    this.MachineType = '';
    this.MasterMachine = '';
    this.MasterMachineSerial = '';
    this.MasterMachineModel = '';
    this.MasterNoOfTrays = '';
    this.MasterNoOfMotorsPerTray = '';
    this.SlaveMachine = '';
    this.SlaveMachineSerial = '';
    this.SlaveMachineModel1 = '';
    this.SlaveMachineModel2 = '';
    this.SlaveNoOfTrays = '';
    this.SlaveNoOfMotorsPerTray = '';
    this.ActivationDate = '';
    this.Cannisters = null;
    this.Tea = null;
    this.WholeBean = null;
  }
}

