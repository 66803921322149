import { Component, OnInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { GlobalService } from '../../global.service';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material';
import { NgForm, FormControl, FormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs/Rx';
import { ApilistService } from '../../../service/Api/apilist.service';
declare const $;
export interface posts {
  IdMerchant: string;
  CompanyName: string;
}
@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})

export class InventoryComponent implements OnInit {


  IdMerchant = sessionStorage.getItem('referSuperMerchantId');
  companyName = sessionStorage.getItem('companyName');
  // posts: any;
  AlertText: string;
  IdManufacturer: any;
  Inventory;
  before: number;
  screenHeight: number;
  pageno: any;
  selectedmanufacturer;
  Effectivedate: any;
  Selectedmachine: any;
  type: any = 'Merchant';
  IsSalesTax = true;
  IdMachine: number;
  length_machine: number;
  Search: string;
  ToId: number;
  protected posts: posts[];
  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<posts[]> = new ReplaySubject<posts[]>(1);
  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  postss: any;
  array = [];
  manufactureid: any;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate

  constructor(
    public apiList: ApilistService,
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService) { 
      this.resize$.debounceTime(100).subscribe(innerWidth => {
        if (this.Resizing) { return; }
        this.Repaginate();
        this.SettleAndAutosize(80);
      });
      this.Repaginate();
      this.GetManufacturerlist();
    }

  ngOnInit() {
    $('#checkall').addClass('btn_active');
    this.hidepops();
  }

  hidepops() {
    $('#alert_div').hide();
    $('#dots_spinner').hide();
    $('#transferform').modal('hide');
  }

  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
//console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }


  addEvent1(value) {
    this.Effectivedate = value;
  }

  GetManufacturerlist() {
   // $('#dots_spinner').show();

    this.http.post<any>(this.apiList.ManufacturerListUrl, { IdMerchant: parseInt(this.IdMerchant, 0) },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        const Response = response;
        if (Response.Response === 1) {
          this.postss = Response.lst_Manufacturer;
          for (let i = 0; i < this.postss.length; i++) {
            this.array.push(this.postss[i].IdManufacturer);
          }
          this.selectedmanufacturer = this.array;
          this.Inventorydata();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      //  $('#dots_spinner').hide();
      });
  }

  Inventorydata() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.SuperadminTransferInventoryListUrl,
      { IdManufacturers: this.selectedmanufacturer }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        const Response = response;
        if (Response.Response === 1) {
          this.Inventory = Response.lst_Inventory;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide();
        if (this.Resizing) {
          // need to give time to render behind the scenes before size/resize rows
          this.SettleAndAutosize();
        }
      });
  }
  Inventorydata1() {
    $('#checkall').removeClass('btn_active');
    $('#uncheckall').removeClass('btn_active');
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.SuperadminTransferInventoryListUrl,
      { IdManufacturers: this.selectedmanufacturer }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        const Response = response;
        if (Response.Response === 1) {
          this.Inventory = Response.lst_Inventory;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  selectAll() {
    this.array = [];
    $('#checkall').addClass('btn_active');
    $('#uncheckall').removeClass('btn_active');
    for (let i = 0; i < this.postss.length; i++) {
      this.array.push(this.postss[i].IdManufacturer);
    }
    this.selectedmanufacturer = this.array;
    this.Inventorydata();
  }

  deselectAll() {
    $('#checkall').removeClass('btn_active');
    $('#uncheckall').addClass('btn_active');
    this.selectedmanufacturer = [];
    this.Inventorydata();
  }

  Transfer(value, id) {
    this.Selectedmachine = value;
    this.manufactureid = id;
    $('#transferform').modal('show');
    this.listusertype(this.type);
  }
  addmachine() {
    this.router.navigate(['./Superadmindashboard/Inventory/addmachine',
    ]);
  }

  setup(id, MachineType, CanBeMaster, CanBeslave, IdManufacturer, ManufacturerName, IdMachineModel) {
    this.router.navigate(['./Superadmindashboard/Inventory/GridLayout', {
      refrenceid: id,
      IdManufacturer: IdManufacturer,
      ManufacturerName: ManufacturerName,
      IdMachineModel: IdMachineModel,
      MachineType: MachineType,
      CanBeMaster: CanBeMaster,
      CanBeslave: CanBeslave
    }], { skipLocationChange: true });
  }

  clearSearch() {
    this.Search = '';
    this.Inventorydata();
  }
  getID(id: string) { this.IdMachine = + id; }

  delete() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteMachine,
      { IdMachine: this.IdMachine, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe(response => {
        const result = response;
        if (result.Response === 1) {
          this.FlashError('Successfully deleted');
          if (this.length_machine === 1) {
            this.pageno = this.pageno - 1;

            this.Inventorydata();
          } else {

            this.Inventorydata();
          }
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }
  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }

  listusertype(value) {
    this.type = value;
    this.http.post<any>(this.apiList.UsertypeURL,
      { ActiveDeactive: 1, Searchtext: this.type },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        const Response = response;
        if (Response.Response === 1) {
          this.posts = Response.lst_Customer;
          this.bankCtrl.setValue(this.posts);
          this.filteredBanks.next(this.posts.slice());
          this.bankFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterBanks();
            });
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();
      });
  }

  protected setInitialValue() {
    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: posts, b: posts) => a && b && a.IdMerchant === b.IdMerchant;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
  }

  protected filterBanks() {
    if (!this.posts) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.posts.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.posts.filter(bank => bank.CompanyName.toLowerCase().indexOf(search) > -1)
    );
  }

  selecteduser(id) {
    this.ToId = id;
  }

  Assign() {
    const data = {
      IdMachine: this.IdMachine,
      LinkCode: this.Selectedmachine,
      EffectiveDate: this.Effectivedate,
      FromId: this.IdMerchant,
      ToId: this.ToId,
      IdManufacturer: this.manufactureid
    };
    this.http.post<any>(this.apiList.SaveTransferInventoryURL,
      data, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        const Response = response;
        if (Response.Response === 1) {
          $('#transferform').modal('hide');
          this.Inventorydata();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#transferform').modal('hide');
      });
  }
  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  sortData(sort: Sort) {
    const data = this.Inventory.slice();
    if (!sort.active || sort.direction === '') {
      this.posts = data;
      return;
    }
    this.Inventory = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Start_Date': return this.compare(a.BuildStartDate, b.BuildStartDate, isAsc);
        case 'Complete_Date': return this.compare(a.BuildCompleteDate, b.BuildCompleteDate, isAsc);
        case 'Machine_Type': return this.compare(a.MachineType.toLowerCase(), b.MachineType.toLowerCase(), isAsc);
        case 'Serial_Number': return this.compare(a.MachineSerial.toLowerCase(), b.MachineSerial.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
