import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { NgForm } from '../../../../../node_modules/@angular/forms';
import { MatDialog } from '../../../../../node_modules/@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';
declare const $;
@Component({
    selector: 'app-updatecategory',
    templateUrl: './updatecategory.component.html',
    styleUrls: ['./updatecategory.component.css']
})
export class UpdatecategoryComponent implements OnInit, OnDestroy {
    @ViewChild('myInput', { static: false }) public myInputVariable: ElementRef;
    @ViewChild('update', { static: false }) public update: NgForm;
    baseUrl = environment.baseUrl;
    BaseFileUrl = environment.BaseFileUrl;
    ImageUrl = this.BaseFileUrl + '/ClientDocument/';
    IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
    companyName = sessionStorage.getItem('companyName');
    upload_image;
    AlertText;
    LinkCode = '';
    image;
    Mainfile: any;
    fileList: any;
    IdMainCategory;
    ImageName;
    MainCategoryName;
    Description;
    FileMD5Sum: any;
    isAdding = false;
    bit: any = false;
    blob: Blob;
    MainImageName: any;
    constructor(
        public apiList: ApilistService,
        private router: Router,
        private http: HttpClient,
        private globalservice: GlobalService,
        public dialog: MatDialog,
        private route: ActivatedRoute) {
        this.route.params.subscribe((get) => {
            this.IdMainCategory = get.id;
        });
    }

    ngOnInit() {
        this.globalservice.setDiscard_check();
        this.upload_image = 1;
        this.hidepops();
        this.get_detail();
        $('#add').trigger('click');
        $(function () {
            $('#myImg').show();
            $('#main_img_close').show();
            $('#upload-btn-wrapper').hide();
            $('#file').change(function () {
                if (this.files && this.files[0]) {
                    const reader = new FileReader();
                    reader.onload = imageIsLoaded;
                    reader.readAsDataURL(this.files[0]);
                    this.MainImage = this.files[0].name;
                }
            });
        });
        function imageIsLoaded(e) {
            $('#myImg').attr('src', e.target.result);
        }
    }
    hidepops() {
        $('#alert_div').hide();
        $('#dots_spinner').hide();
        $('#upload-btn-wrapper').hide();
    }
    canDeactivate(): any {
        if (!this.isAdding && this.update.dirty) {
            if (this.bit === false && this.globalservice.GetDiscard_Check()) {
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });

                return dialogRef.afterClosed().subscribe(result => {
                    if (result && (result.response === 'ok')) {
                        this.dialog.closeAll();
                        this.bit = true;
                        this.router.navigate([this.companyName + '/dashboard/categories']);
                    }
                });
            }
        } else if (!this.isAdding && this.update.dirty) {
            if (this.bit === false) {
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });

                return dialogRef.afterClosed().subscribe(result => {
                    if (result && (result.response === 'ok')) {
                        this.dialog.closeAll();
                        this.bit = true;
                        this.router.navigate([this.companyName + '/dashboard/categories']);
                    }
                });
            }
        }
        return true;
    }

    get_detail() {
        $('#dots_spinner').show();
        this.http.post<any>(this.apiList.getMainCategoryDetailUrl,
            { IdMainCategory: this.IdMainCategory, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(resp => {
                if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                if (+resp.Response !== 1) { throw resp; }
                return resp;
            })
            .subscribe((response) => {
                    response = response.MainCategoryDetail;
                    this.MainCategoryName = response.MainCategoryName;
                    this.ImageName = response.ImageURL;
                    this.Description = response.Description;
                    this.FileMD5Sum = response.FileMD5Sum;
                    this.MainImageName = response.ImageName;
            }, (err: any) => {
                const Error = this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else {
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(() => { $('#dots_spinner').hide(); });
    }

    // fileChange(event) {
    //     this.fileList = event.target.files;
    //     if (this.fileList.length > 0) {
    //         $('#dots_spinner').show();
    //         this.Mainfile = this.fileList[0];
    //         const formData: FormData = new FormData();
    //         formData.append('ClientDocs', this.Mainfile, this.Mainfile.name.replace(/\s/g, ''));
    //         this.http.post<any>(this.apiList.CheckTransparency, formData, this.globalservice.MediaHeaders())
    //             .timeout(25000)
    //             .take(1)
    //             .map(resp => {
    //                 if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
    //                 if (+resp.Response !== 1) { throw resp; }
    //                 return resp;
    //             })
    //             .subscribe((response) => {
    //                 const check1 = response;
    //                 if (check1.Response === 1) {
    //                     this.ImageUpload(formData, this.Mainfile);
    //                 } else if (check1.Response === 4) {
    //                     $('#myModal').modal('show');
    //                 }
    //             }, (err: any) => {
    //                 const Error = this.globalservice.ProcessError(err);
    //                 if (Error.Response === 35) {
    //                     if (Error.ErrorMessage) {
    //                         this.FlashError(Error.ErrorMessage);
    //                         this.globalservice.Logout('Yes');
    //                     }
    //                     this.globalservice.Logout('No');
    //                     return;
    //                 } else {
    //                     this.FlashError(Error.ErrorMessage);
    //                     return;
    //                 }
    //             }).add(() => { $('#dots_spinner').hide(); });
    //     }
    // }

    // ImageUpload(formData, Mainfile) {
    //     $('#dots_spinner').show();
    //     this.http.post<any>(this.apiList.MediaUpload, formData, this.globalservice.MediaHeaders())
    //         .timeout(25000)
    //         .take(1)
    //         .map(resp => {
    //             if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
    //             if (+resp.Response !== 1) { throw resp; }
    //             return resp;
    //         })
    //         .subscribe((response) => {
    //             if (response) {
    //                 if (response.Response === 1) {
    //                     this.upload_image = 1;
    //                     this.ImageName = response.FileName;
    //                     this.FileMD5Sum = 1;
    //                     $('#pro-img').show(2000, function () {
    //                         $('#main_img_close').show();
    //                         $('#uploadSuccess').modal('show');
    //                     });
    //                     $('#upload-btn-wrapper').hide();
    //                 }else if (response.Response === 0) {
    //                     this.FlashError('Not Uploaded');
    //                 }
    //             }
    //         }, (err: any) => {
    //             const Error = this.globalservice.ProcessError(err);
    //             if (Error.Response === 35) {
    //                 if (Error.ErrorMessage) {
    //                     this.FlashError(Error.ErrorMessage);
    //                     this.globalservice.Logout('Yes');
    //                 }
    //                 this.globalservice.Logout('No');
    //                 return;
    //             } else {
    //                 this.FlashError(Error.ErrorMessage);
    //                 return;
    //             }
    //         }).add(() => { $('#dots_spinner').hide(); });
    // }

    // Yes() {
    //     if (this.fileList.length > 0) {
    //         const file = this.fileList[0];
    //         const formData: FormData = new FormData();
    //         formData.append('ClientDocs', file, file.name.replace(/\s/g, ''));
    //         this.ImageUpload(formData, file);
    //     }
    // }

    category_submit() {
        this.isAdding = true;
        if (this.upload_image === 1) {
            $('#dots_spinner').show();
            this.http.post<any>(this.apiList.updateMainCatUrl,
                {
                    IdMainCategory: this.IdMainCategory,
                    MainCategoryName: this.MainCategoryName,
                    Description: this.Description,
                    IdMerchant: this.IdMerchant,
                    ImageName: this.MainImageName
                }, this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(resp => {
                    if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                    if (+resp.Response !== 1) { throw resp; }
                    return resp;
                })
                .subscribe((res) => {
                    if (res.Response === 1) {
                        this.router.navigate([this.companyName + '/dashboard/categories']);
                    }
                }, (err: any) => {
                    const Error = this.globalservice.ProcessError(err);
                    if (Error.Response === 35) {
                        if (Error.ErrorMessage) {
                            this.FlashError(Error.ErrorMessage);
                            this.globalservice.Logout('Yes');
                        }
                        this.globalservice.Logout('No');
                        return;
                    } else {
                        this.FlashError(Error.ErrorMessage);
                        return;
                    }
                }).add(() => { $('#dots_spinner').hide(); });
        } else {
            this.FlashError('Please upload the Category Image');
        }
    }

    goback() {
        this.router.navigate([this.companyName + '/dashboard/categories']);

    }

    remove() {
        this.ImageName = '';
        this.FileMD5Sum = '';
        this.upload_image = '';
        $('#myImg').hide();
        $('#main_img_close').hide();
        $('#upload-btn-wrapper').show();
    }

    FlashError(message: string) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(() => {
            $('#alert_div').hide();
        }, 3000);
    }

    ngOnDestroy() {
        this.dialog.ngOnDestroy();
    }

    MainImageUpload(file: File) {
        // if (this.upload_image == 1) {
         $('#dots_spinner').show();
       
         this.blob = new Blob([file[0]], { type: file[0].type });
         console.log(file)
         this.globalservice.CheckTransparency(file).then((res:any) => {
             $('#dots_spinner').hide();
             console.log(res)
             if(res.Response == 1){
                 this.uploadFile()
             }
         },error=>{
             $('#dots_spinner').hide();
             console.log(error)
             const Error = this.globalservice.ProcessError(error);
                 if (Error.Response === 35) {
                     if (Error.ErrorMessage) {
                         this.FlashError(Error.ErrorMessage);
                         this.globalservice.Logout('Yes');
                     }
                     this.globalservice.Logout('No');
                     return;
                 } else if (Error.Response == 4) {
                     $('#myModal').modal('show');
                 }
                 else {
                     console.log(Error.ErrorMessage)
                    // event.target.value = null;
                     this.FlashError(Error.ErrorMessage);
                     return;
                 }
         })
     }
     // else{
     //         this.FlashError("Firstly upload your Category image");
     //     }
     // }
     uploadFile(){
         $('#dots_spinner').show();
         this.globalservice.uploadFiles(this.blob).then((res:any)=>{
             if(res.Response==1){
                 $('#dots_spinner').hide();
                 this.upload_image = 1;
                 this.ImageName = res.PreviewUrl;
                 this.MainImageName = res.FileName
                 this.FileMD5Sum = 1
                 $('#myImg').show(2000, function () {
                     $('#main_img_close').show();
                     $('#uploadSuccess').modal('show');
                 });
                 $('#upload-btn-wrapper').hide();
             }
         },error=>{
             const Error = this.globalservice.ProcessError(error);
             if (Error.Response === 35) {
                 if (Error.ErrorMessage) {
                     this.FlashError(Error.ErrorMessage);
                     this.globalservice.Logout('Yes');
                 }
                 this.globalservice.Logout('No');
                 return;
             } 
             // else if (Error.Response == 4) {
             //     $('#myModal').modal('show');
             // }
             else {
                 console.log(Error.ErrorMessage)
                // event.target.value = null;
                 this.FlashError(Error.ErrorMessage);
                 return;
             }
         })
     }
 
       Yes() {
         // console.log(this.section);
     this.uploadFile()
        
     }
}

