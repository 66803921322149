import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ApilistService } from '../service/Api/apilist.service';
import { UserService } from './user.service';
import { UserIdleService } from '../../node_modules/angular-user-idle';
import { Router } from '../../node_modules/@angular/router';
import { tap } from 'rxjs/operators';
import { SELECT_ITEM_HEIGHT_EM } from '@angular/material';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';



import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
// import { Subject } from 'rxjs';

declare const $;
@Injectable()
export class GlobalService {
  baseUrl = environment.baseUrl;
  baseUrlSkin=environment.baseUrlSkin;
  GetZipCodeList = this.baseUrl + '/common/GetZipCodeList';
  GetZipCodeDetails = this.baseUrl + '/common/GetDetailByZipCode ';
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'));
  IdActiveMerchantId;
  // AuthToken: string = '';
  public _authToken;
  public Headers2: HttpHeaders;
  public MediaHeaders2: HttpHeaders;
  public MediaHeaders3: HttpHeaders;
  public HeaderNoAuth: HttpHeaders;
  UserRole = '';
  SuperAdminToken = '';
  Sessionid: string;
  posts: any;
  timeout: any;
  SessionExpires: any;
  Discard_Check = true;
  nopInterval: number;
  nopTimeout: any;
  timeoutFlag = false;
  countryList = {};
  Permissions = {};
  ActiveMerchantId: any;
  public ImpersonationMode = false;
  oldAuthToken: any;
  blob: Blob;


  showNoThanksBtn:boolean=true;
  // private fooSubject = new Subject<any>();
  constructor(private router: Router, private http: HttpClient, public apiList: ApilistService, public userIdle: UserIdleService, private user: UserService, @Inject(DOCUMENT) private document: Document) {

  }

  getThanksBtnStatus(){
    return this.showNoThanksBtn;
  }

  setThanksBtnStatus(status){
    this.showNoThanksBtn=status;
  }

   //dynamic css file loading

   loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName.trim();
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.type = 'text/css';
      style.href = styleName.trim();
      head.appendChild(style);
    }
  }

  GetToken(): string {
    return this._authToken;
  }

  Headers(): { headers: HttpHeaders } {
    return { headers: this.Headers2 };
  }

  GetPermissions() {
    if (this.Permissions.hasOwnProperty(this.ActiveMerchantId)) { return this.Permissions[this.ActiveMerchantId]; }
  }

  getOldHeader(): { headers: HttpHeaders } {
    return { headers: this.oldAuthToken };
  }
  SaveIdentity(response) {

    this._authToken = response.AuthToken;
    this.IdMerchant = response.ActiveMasterMerchantID;
    this.ActiveMerchantId = response.ActiveMerchantID;
    this.Permissions[response.ActiveMerchantId] = response.Permissions;

    sessionStorage.setItem('referMerchantId', `${response.ActiveMerchantID}`);
    sessionStorage.setItem('referMasterMerchantId', `${response.ActiveMasterMerchantID}`);
    sessionStorage.setItem('referUserRole', response.ObjUserRecordClass.UserRole);
    sessionStorage.setItem('logocompanyName', response.ObjUserRecordClass.CompanyName);
    sessionStorage.setItem('companyName', response.ObjUserRecordClass.CompanyName.replace(/\s/g, ''));
    sessionStorage.setItem('Login', response.LoginId);

    this.ImpersonationMode = response.ActiveMerchantID !== response.LoginId;

    const ur = response.ObjUserRecordClass;

    if (response.UserRole === 'SuperAdmin') {
      sessionStorage.setItem('referSuperMerchantId', response.ActiveMasterMerchantID);
      sessionStorage.setItem('Sessionid', response.SessionId);
    } else {
      sessionStorage.removeItem('referSuperMerchantId');
    }

    if (['Admin', 'Merchant'].includes(response.UserRole)) {
      sessionStorage.setItem('Sessionid_Admin', response.SessionId);
    }

    if (['Admin', 'Merchant', 'Distributor'].includes(response.UserRole)) {
      sessionStorage.setItem('Inventoryuserole', this.UserRole);
    }

    this.Headers2 = new HttpHeaders({
      'Content-Type': 'text/plain',
      'AuthToken': this._authToken
    });
    this.MediaHeaders2 = new HttpHeaders({
      'AuthToken': this._authToken
    });
    this.MediaHeaders3 = new HttpHeaders({
      'AuthToken': this._authToken,
      'ngsw-bypass': ''
    });
    this.HeaderNoAuth = new HttpHeaders({
      'Content-Type': 'text/plain'
    });
  }

  ProcessError(error){
console.log(error)
    // errors9[']]\'] other than our normal API Response/ErrorMessage format
    if (typeof error === 'object' && error.constructor.name === 'Response') {
      error = {
        ErrorMessage: 'Network error',
        Response: 30
      };
    } else if (typeof error === 'string' || error == null) {
      error = {
        ErrorMessage: 'Unexpected error',
        Response: 0
      };
    } else if (error.hasOwnProperty('name') && error.name === 'TimeoutError') {
      error = {
        ErrorMessage: 'Request timed out',
        Response: 3
      };
    }

    return error;
  }


  MediaHeaders(): { headers: HttpHeaders } {
    return {
      headers: this.MediaHeaders2
    };
  }

  VideoUploadHeaders(): {headers: HttpHeaders, observe: 'events', reportProgress: true } {
    return {
      headers: this.MediaHeaders3,
      observe: 'events',
      reportProgress: true
    };
  }

  setsession(a) {
    this.SessionExpires = a;
  }

  getsession() {
    return this.SessionExpires;
  }

  headerWithoutauth(): { headers: HttpHeaders } {
    return {
      headers: this.HeaderNoAuth
    };
  }

  LoginApi(loginName: string, password: string) {
    return new Promise((resolve) => {
      this.http.post<any>(this.apiList.SignInUrl, { LoginName: loginName, Password: password,IsWebRequest:true }, this.headerWithoutauth())
        .map(resp => {
          if (resp.Response == null || +resp.Response !== 1) {
            throw (resp);
          }
          return resp;
        })
        .subscribe(response => {
          this.SaveIdentity(response);
          this.UserRole = response.ObjUserRecordClass.UserRole;
          this.timeout = (response.LoginTimeOut) * 0.95;
          this.nopInterval = (response.LoginTimeOut) * 0.8;
          // console.log(this.timeout);
          this.userIdle.setConfigValues(({ idle: 0.1, timeout: this.timeout, ping: 10 }));
          this.startNopInterval();
console.log('api')
          resolve(response);

        }, err => {
console.log('error')
          resolve(err);
        }
        );
    });

  }

  async GetZipCode() {
    this.http.get<any>(this.GetZipCodeList, this.Headers()).subscribe((response) => {
      return response;
    }, err => ({ Response: 0, ErrorMessage: `Request error${err}` }));
  }

  async ImpersonateApi(id, Isfrom) {
    this.oldAuthToken = this.Headers2;
    return new Promise((resolve) => {
      this.http.post<any>(this.apiList.Impersonate, { IdMerchant: id, IsFromSuperAdmin: Isfrom }, this.Headers())
        .take(1)
        .map(resp => {
          if (resp.Response == null || +resp.Response !== 1) {
            throw (resp);
          }
          return resp;
        })
        .subscribe(res => {
         if(res.ObjUserRecordClass.SkinCssFileUrl){
            this.loadStyle(this.baseUrlSkin+res.ObjUserRecordClass.SkinCssFileUrl);
          }
          //adding else if no skin is there scenario

          else{
            var defaultSkin = this.baseUrlSkin+'assets/css/skins/defaultSkin.css';
           this.loadStyle(defaultSkin);
          
          }
          
          this.SaveIdentity(res);
          resolve(res);
        }, err => {
          resolve({ Response: 0, ErrorMessage: `Request failed ${err}` });
        }
        );
    });
  }
  Signout(sessionId: string) {
    this.http.post<any>(this.apiList.LogoutUrl, { SessionId: sessionId }, this.getOldHeader())
      .subscribe((response) => {
        if (response) {
          return;
        }
      });
  }
  async BackToSuperAdmin(idSuperMerchant) {
    this.oldAuthToken = this.Headers2;
    return new Promise((resolve) => {
      this.http.post<any>(this.apiList.BackToSuperAdmin, { SuperAdminId: idSuperMerchant }, this.getOldHeader())
        .take(1)
        .map((resp) => {
          if (resp.Response == null || +resp.Response !== 1) {
            throw (resp);
          }
          return resp;
        }).subscribe(res => {
          if(res.ObjUserRecordClass.SkinCssFileUrl){
            this.loadStyle(this.baseUrlSkin+res.ObjUserRecordClass.SkinCssFileUrl);
          }
          else{
            var defaultSkin=this.baseUrlSkin+'assets/css/skins/defaultSkin.css';
           this.loadStyle(defaultSkin);
          
          }
          this.SaveIdentity(res);
          resolve(res);
        }, err => {
          resolve({ Response: 0, ErrorMessage: `Request failed ${err}` });
        }
        );
    });
  }
  async clearStorage() {
    sessionStorage.clear();
    this.setsession('No');
  }
  gpsImpersonate(id, Isfrom) {
    return this.http.post<any>(this.apiList.Impersonate, { IdMerchant: id, IsFromSuperAdmin: Isfrom }, this.Headers()).pipe(
      tap(response => {
        if (response.Response === 1) {

          if(response.ObjUserRecordClass.SkinCssFileUrl){
            this.loadStyle(this.baseUrlSkin+response.ObjUserRecordClass.SkinCssFileUrl);
          }

         //adding else if no skin is there scenario

          else{
            var defaultSkin = this.baseUrlSkin+'assets/css/skins/defaultSkin.css';
           this.loadStyle(defaultSkin);
            
          }
          
          this.SaveIdentity(response);
        }
      })
    );
  }

  getDetails() {
    this.IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'));
    this.Discard_Check = false;
  }

  setDiscard_check() {
    this.Discard_Check = true;
  }

  GetDiscard_Check() {
    return this.Discard_Check;
  }

  Logout(a) {
    this.getDetails();
    if (this.UserRole === 'SuperAdmin') {
      this.Sessionid = sessionStorage.getItem('Sessionid');
    } else {
      this.Sessionid = sessionStorage.getItem('Sessionid_Admin');
    }
    this.http.post<any>(this.apiList.LogoutUrl, { SessionId: this.Sessionid }, this.Headers()).subscribe((response) => {
    });
    
    sessionStorage.clear();
    this.user.setUserLoggedOut();
    this._authToken = '';
    this.setsession(a);
    this.stopNopInterval();
    this.router.navigate(['/login']);
    
    
   
  }

  getTenantSkin(){        
    this.http.get<any>(
      this.apiList.GetTenantSkin)
      .subscribe((response) => {
        if (response) {
         if(response.SkinCssFileUrl){
           this.loadStyle(this.baseUrlSkin+response.SkinCssFileUrl);
         }
         else{
           var defaultSkin=this.baseUrlSkin+'assets/css/skins/defaultSkin.css';
           this.loadStyle(defaultSkin);
         }
        }
      }, (err) => {
       console.log("err"+err);
      });
  }

  async NoOperation() {
    this.getDetails();
    return new Promise((resolve, rject) => {
      this.http.post<any>(this.apiList.NoOperation, {}, this.Headers()).subscribe((response) => {
        resolve(response);
      }, err => {
        resolve({ Response: 0, ErrorMessage: `Request error${err}` });
      }
      );
    });
  }

  GetZIPDetails(Zip) {
    return new Promise((resolve, reject) => {
      this.http.post<any>(this.GetZipCodeDetails, { ZipCode: Zip }, this.Headers()).subscribe((response) => {
        resolve(response);
      }, err => {
        resolve({ Response: 0, ErrorMessage: `Request error${err}` });
      }
      );
    });
  }


  getMerchantDetail(id) {
    this.http.post<any>(this.apiList.MerchantDetailUrl, { IdMerchant: id, IsFromSuperAdmin: true }, this.Headers()).subscribe(
      response => {
        if (response.Response === 1) {
          sessionStorage.setItem('Sessionid_Admin', response.SessionId);
          sessionStorage.setItem('Inventoryuserole', response.obj_MerchantRecord.UserRole);
        }
      }, err => {
        return { Response: 0, ErrorMessage: `Request error${err}` };
      }
    );
  }

  getUserRole() {
    return this.UserRole;
  }

  // publishSomeData(data: any) {
  //   this.fooSubject.next(data);
  // }

  // getObservable(): Subject<any> {
  //   return this.fooSubject;
  // }

  startNopInterval() {
    this.nopTimeout = setInterval(() => {
      // console.log('NOP');
      this.NoOperation();
    }, this.nopInterval * 1000);
  }

  stopNopInterval() {
    // console.log('Stop NOP');
    if (this.nopTimeout) {
      clearInterval(this.nopTimeout);
    }
  }

  setTimeoutFlag() {
    this.timeoutFlag = true;
  }

  resetTimeoutFlag() {
    this.timeoutFlag = false;
  }

  getTimeoutFlag() {
    return this.timeoutFlag;
  }


  PagingHandler(
    pageNumber: number,
    records: Array<any>,
    itemsPerPage: number,
    quantum: number,
    pullRecords: (originPage: number, finalPage: number, reload: boolean) => void,
    changePage: (pageNumber: number) => void) {

    console.log(`Start change to ${pageNumber}`);

    const rowNumber = (pageNumber - 1) * itemsPerPage;
    let limit = Math.min(rowNumber + itemsPerPage, records.length);
    let originPage: number = pageNumber;

    // confirm that we have all records defined for the requested page. If we don't, call API to pull records
    for (let offset = 0; offset < itemsPerPage; offset++) {

      const i: number = offset + rowNumber;
      if (i >= limit) { break; }

      // need to make sure we request records if ANY records in the requested page are undefined
      if (records[i] === undefined) {

        // shift the limit back as far as necessary to capture the furthest "undefined record"
        // while still capturing the demanded page.
        const requestedPageLimit = i + (itemsPerPage - offset);
        for (limit = Math.min(rowNumber + quantum, records.length); limit > requestedPageLimit; limit--) {
          if (records[limit - 1] === undefined) { break; }
        }

        // found the "ideal limit". Now our ideal range starts #quantum records back from here.
        originPage = Math.ceil(Math.max(limit - quantum, 0) / itemsPerPage) + 1;

        pullRecords(originPage, pageNumber, false);
        return;
      }
    }

    changePage(pageNumber);
  }


  MergeRecords({ cache, newRecords, totalCount, pageNumber, itemsPerPage, reload }:
    {
      cache: Array<any>;
      newRecords: Array<any>;
      totalCount: number;
      pageNumber: number;
      itemsPerPage: number;
      reload: boolean;
    }) {

    if (reload) {
      // delete all records... starting from scratch
      // happens at construction and during search changes
      cache.length = 0;
    }

    // size record cache to accomodate the full set of records.
    // this along with recordsPerPage allows ngx-pagination to put the right number of pages.
    cache.length = totalCount;

    // merge in the downloaded set of records
    for (let i = 0, rowNumber = (pageNumber - 1) * itemsPerPage; i < newRecords.length; i++, rowNumber++) {
      cache[rowNumber] = newRecords[i];
    }

  }


  /* get country list */

  getCountry() {
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.apiList.CountryListUrl)
        .subscribe(response => {
          this.countryList = response;
          // console.log(response)
          resolve(response);
        }, err => {
          reject(new Error(err));
          return { Response: 0, ErrorMessage: `Request error${err}` };
        });
    });
  } 

   CheckTransparency(file: File): Promise<void> {
    return new Promise((resolve, reject) => {
    this.blob = new Blob([file[0]], { type: file[0].type });
    try {
        this.http.post<any>(this.apiList.CheckTransparency, this.blob,
            {
                headers: {
                    'Content-Type': this.blob.type,
                    'AuthToken': this._authToken,
                    // 'FileCategory': 'Product'
                }
            }
        )
        .take(1)
        .timeout(25000)
        .map(result => {
            if (result.Response !== 1) throw result;
            return result;
        }).subscribe((response: any) => {
            console.log(response);
            resolve(response);
        }, (error) => {
            console.log(error);
            reject (error);
        });
    } catch (error) {
        console.warn('File upload failed');
        console.error(error);

    }
  })
}

ThemeConfiguration(file: File): Promise<void> {
  return new Promise((resolve, reject) => {
  this.blob = new Blob([file[0]], { type: file[0].type });
  try {
      this.http.post<any>(this.apiList.CheckThemeConfiguration, this.blob,
          {
              headers: {
                  'Content-Type': this.blob.type,
                  'AuthToken': this._authToken,
              }
          }
      )
      .take(1)
      .timeout(25000)
      .map(result => {
          if (result.Response !== 1) throw result;
          return result;
      }).subscribe((response: any) => {
          console.log(response);
          resolve(response);
      }, (error) => {
          console.log(error);
          reject (error);
      });
  } catch (error) {
      console.warn('File upload failed');
      console.error(error);

  }
})
}

 uploadFiles(blob): Promise<void> {
 return new Promise((resolve, reject) => {
  //console.log(blob);
  try {
      this.http.post<any>(this.apiList.UploadImage, blob,
          {
              headers: {
                  'Content-Type': blob.type,
                  'AuthToken': this._authToken
              }
          }
      )
      .take(1)
      .timeout(25000)
      .map(result => {
          if (result.Response !== 1) throw result;
          return result;
      }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        reject (error);
      });
  } catch (error) {
      console.warn('File upload failed');
      console.error(error);
  }
})
}



//searchfilterSHopper

searchShopper(terms: Observable<string>) {
  return terms.debounceTime(400)
    .distinctUntilChanged()
    .switchMap(term => this.searchEntries(term));
}

searchEntries(term) {
  return this.http
      .post<any>(this.apiList.SearchShopper,{Name:term}, this.Headers())
      .map((res) => res);
}

}
