import { Component, OnInit, HostListener } from '@angular/core';
import { ApilistService } from '../../../../service/Api/apilist.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../../global.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Sort } from '@angular/material';
import { Subject } from 'rxjs';
declare const $;

@Component({
  selector: 'app-list-special-message',
  templateUrl: './list-special-message.component.html',
  styleUrls: ['./list-special-message.component.css']
})
export class ListSpecialMessageComponent implements OnInit {
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  companyName = sessionStorage.getItem("companyName");

  specialMessageList: any;
  before: number;
  pageno: any;
  screenHeight: number;
  frameHeight: number;
  AlertText: string;
  deleteSpecialMessage: any;
  Search

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate
  
  constructor(private router: Router, public apiList: ApilistService, private http: HttpClient, private globalservice: GlobalService) { 
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
    this.list_specialMessage()
  }

  ngOnInit() {
    $("#alert_div").hide();
    $('#dots_spinner').hide();
   
  }

  smtp(){this.router.navigate([this.companyName + '/dashboard/setting'])}
  email(){this.router.navigate([this.companyName + '/dashboard/setting/EmailTemplate'])}
  terms(){this.router.navigate([this.companyName + '/dashboard/setting/term'])}
  listSpecial(){this.router.navigate([this.companyName + '/dashboard/setting/specialMessage'])}
  firebaseRoute(){this.router.navigate([this.companyName + '/dashboard/setting/FirebaseDetails'])}

  
  clearSearch(){
    this.Search='';
    this.list_specialMessage() 
  }
  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
//console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  list_specialMessage() {
    let data = {
      IdMerchant: this.IdMerchant
    }
    $("#dots_spinner").show();

    this.http.post<any>(this.apiList.GetSpecialMessageList, data, this.globalservice.Headers())
    .take(1)
    .timeout(25000)
    .map(resp => {
      if (resp == null || resp.Response == null) resp = { Response: 0, ErrorMessage: "Empty response" };
      resp.Response = +resp.Response;
      if (resp.Response != 1) throw resp;
      return resp;
    })
    .subscribe((res: any) => {
      this.specialMessageList = res.lst_SpecialMessage
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide();
       if (this.Resizing) {
        // need to give time to render behind the scenes before size/resize rows
        this.SettleAndAutosize();
      }
     });
  }

  add(){
    this.router.navigate([this.companyName + '/dashboard/setting/specialmessage/add', {
      add:'0',
    }])
  }

  editMessage(id) {
    this.router.navigate([this.companyName + '/dashboard/setting/specialmessage/add', {
      add:'1',
      id:id
    }])
  }


  delete(id) {
    this.deleteSpecialMessage = id
  }

  delete_specialMessage() {
    let data = {
      SpecialMessageId: this.deleteSpecialMessage,
      
    }
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteSpecialMessage, data, this.globalservice.Headers())
 .take(1)
    .timeout(25000)
    .map(resp => {
      if (resp == null || resp.Response == null) resp = { Response: 0, ErrorMessage: "Empty response" };
      resp.Response = +resp.Response;
      if (resp.Response != 1) throw resp;
      return resp;
    })
    .subscribe((res: any) => {
      if (res.Response == 1) {
        this.FlashError('Successfully deleted');
        this.list_specialMessage()
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
       $('#dots_spinner').hide(); });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  sortData(sort: Sort) {
    const data = this.specialMessageList.slice();
    if (!sort.active || sort.direction === '') {
      this.specialMessageList = data;
      return;
    }
    this.specialMessageList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.SpecialMessage.toLowerCase(), b.SpecialMessage.toLowerCase(), isAsc);
        case 'startDate': return this.compare(a.StartDate, b.StartDate, isAsc);
        case 'endDate': return this.compare(a.EndDate, b.EndDate, isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }

}
