import { Injectable } from '@angular/core';
import { DialogVersionUpdateComponent } from './dialog-version-update/dialog-version-update.component';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material';
import { interval, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateVersionService {

  updateSub: Subscription;

  constructor(public updates: SwUpdate,
    public dialog: MatDialog) {
    if (updates.isEnabled) {
      this.updateSub = interval(10000).subscribe(() => updates.checkForUpdate());
//        .then(() => console.log('checking for new updates')))
      this.updates.available.subscribe(event => this.promptUser());
      console.log("SUBSCRIBED TO UPDATES");

    }
  }

  // public checkForUpdates(): void {
  //   this.updates.available.subscribe(event => this.promptUser());
  // }

  private promptUser(): void {
    console.log("**** promptUser attempt ***");
    const dialogRef = this.dialog.open(DialogVersionUpdateComponent, {
      width: '350px',
      data: { message: 'There is a new version available for this application!' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updates.activateUpdate().then(() => document.location.reload());
      } else {
        interval(24 * 60 * 1000)
          .subscribe((val) => {
            this.promptUser();
          });
      }
    });
  }
}