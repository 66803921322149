import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Rx';
import { GlobalService } from '../../../global.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { error } from 'util';
declare const $;
@Component({
    selector: 'app-categories-information',
    templateUrl: './categories-information.component.html',
    styleUrls: ['./categories-information.component.css']
})
export class CategoriesInformationComponent implements OnInit, OnDestroy {
    @ViewChild('myInput', { static: false }) public myInputVariable: ElementRef;
    @ViewChild('categories', { static: false }) public categoriesForm: NgForm;

    baseUrl = environment.baseUrl;
    IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'));
    AddMainCategoryurl = this.baseUrl + '/Category/AddMainCategory?';
    companyName = sessionStorage.getItem('companyName');
    MainCategoryName = '';
    Description = '';
    CreatedBy: number;
    ImageName = '';
    upload_image;
    AlertText;
    isAdding = false;
    category;
    Mainfile: any;
    fileList: any;
    image;
    bit: any = false;
    blob: Blob;
    MainImageName: any;
    constructor(
        public apiList: ApilistService,
        private router: Router,
        private http: HttpClient,
        private globalservice: GlobalService,
        public dialog: MatDialog) { }

    ngOnInit() {
        this.globalservice.setDiscard_check();
        this.hidepops();
        $(function () {
            $('#file').change(function () {
                if (this.files && this.files[0]) {
                    const reader = new FileReader();
                    reader.onload = imageIsLoaded;
                    reader.readAsDataURL(this.files[0]);
                    this.MainImage = this.files[0].name;
                }
            });
        });
        function imageIsLoaded(e) {
            $('#myImg').attr('src', e.target.result);
        }
    }
    hidepops() {
        $('myModal').modal('hide');
        $('#alert_div').hide();
        $('#uploadSuccess').modal('hide');
        $('#dots_spinner').hide();
        $('#myImg').hide();
        $('#main_img_close').hide();
    }
    canDeactivate(): any {
        if (!this.isAdding && this.categoriesForm.dirty) {
            if (this.bit === false && this.globalservice.GetDiscard_Check()) {
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });
                return dialogRef.afterClosed().subscribe(result => {
                    if (result && (result.response === 'ok')) {
                        this.dialog.closeAll();
                        this.bit = true;
                        this.router.navigate([this.companyName + '/dashboard/categories']);
                    }
                });
            }
        } else if (this.categoriesForm.dirty) {
            if (this.bit === false) {
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });
                return dialogRef.afterClosed().subscribe(result => {
                    if (result && (result.response === 'ok')) {
                        this.dialog.closeAll();
                        this.bit = true;
                        this.router.navigate([this.companyName + '/dashboard/categories']);
                    }
                });
            }
        }
        return true;
    }

    FlashError(message: string) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(() => {
            $('#alert_div').hide();
        }, 3000);
    }

    category_submit() {
        if (this.upload_image === 1) {
            this.isAdding = true;
            $('#dots_spinner').show();
            this.http.post<any>(this.AddMainCategoryurl, {
                MainCategoryName: this.MainCategoryName,
                Description: this.Description,
                CreatedBy: this.IdMerchant,
                ImageName: this.MainImageName
            }, this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(resp => {
                    if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
                    if (+resp.Response !== 1) { throw resp; }
                    return resp;
                })
                .subscribe((response) => {
                    if (response.Response === 1) {
                        this.categoriesForm.reset();
                        this.router.navigate([this.companyName + '/dashboard/categories']);
                    }
                }, (err: any) => {
                    const Error = this.globalservice.ProcessError(err);
                    if (Error.Response === 35) {
                        if (Error.ErrorMessage) {
                            this.FlashError(Error.ErrorMessage);
                            this.globalservice.Logout('Yes');
                        }
                        this.globalservice.Logout('No');
                        return;
                    } else {
                        this.FlashError(Error.ErrorMessage);
                        return;
                    }
                }).add(() => { $('#dots_spinner').hide(); });
        } else {
            this.FlashError('Firstly upload category Image');
        }
    }

    goback() {
        this.router.navigate([this.companyName + '/dashboard/categories']);
    }

    remove() {
        this.myInputVariable.nativeElement.value = '';
        this.upload_image = '';
        $('#myImg').hide();
        $('#main_img_close').hide();
        $('#upload-btn-wrapper').show();
    }

    ngOnDestroy() {
        this.dialog.ngOnDestroy();
    }

    MainImageUpload(file: File) {
       // if (this.upload_image == 1) {
        $('#dots_spinner').show();
      
        this.blob = new Blob([file[0]], { type: file[0].type });
        console.log(file)
        this.globalservice.CheckTransparency(file).then((res:any) => {
            $('#dots_spinner').hide();
            console.log(res)
            if(res.Response == 1){
                this.uploadFile()
            }
        },error=>{
            $('#dots_spinner').hide();
            console.log(error)
            const Error = this.globalservice.ProcessError(error);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        this.FlashError(Error.ErrorMessage);
                        this.globalservice.Logout('Yes');
                    }
                    this.globalservice.Logout('No');
                    return;
                } else if (Error.Response == 4) {
                    $('#myModal').modal('show');
                }
                else {
                    console.log(Error.ErrorMessage)
                   // event.target.value = null;
                    this.FlashError(Error.ErrorMessage);
                    return;
                }
        })
    }
    // else{
    //         this.FlashError("Firstly upload your Category image");
    //     }
    // }
    uploadFile(){
        $('#dots_spinner').show();
        this.globalservice.uploadFiles(this.blob).then((res:any)=>{
            if(res.Response==1){
                $('#dots_spinner').hide();
                this.upload_image = 1;
                this.ImageName = res.PreviewUrl;
                this.MainImageName = res.FileName
                $('#myImg').show(2000, function () {
                    $('#main_img_close').show();
                    $('#uploadSuccess').modal('show');
                });
                $('#upload-btn-wrapper').hide();
            }
        },error=>{
            const Error = this.globalservice.ProcessError(error);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    this.FlashError(Error.ErrorMessage);
                    this.globalservice.Logout('Yes');
                }
                this.globalservice.Logout('No');
                return;
            } 
            // else if (Error.Response == 4) {
            //     $('#myModal').modal('show');
            // }
            else {
                console.log(Error.ErrorMessage)
               // event.target.value = null;
                this.FlashError(Error.ErrorMessage);
                return;
            }
        })
    }

      Yes() {
        // console.log(this.section);
    this.uploadFile()
       
    }
}
