import { Component, OnInit, HostListener, NgZone, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material/sort';
import { GlobalService } from '../../global.service';
import { Observable, Subject } from 'rxjs';
import { ApilistService } from '../../../service/Api/apilist.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PageEvent } from '@angular/material';

const FixedRecordCount = 100;

declare const $;
@Component({
  selector: 'app-machinelist',
  templateUrl: './machinelist.component.html',
  styleUrls: ['./machinelist.component.css']
})
export class MachinelistComponent implements OnInit, OnDestroy {
  IdMerchant = sessionStorage.getItem('referSuperMerchantId');
  Search: string;
  OldSearch: string;
  post_info;
  linkcode: any;
  AlertText: any;
  public Resizing = false;
  public Records = [];
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  RecordCount: number = FixedRecordCount;
  screenHeight: number;
  resize$ = new Subject<void>(); // for debounce
  public RowHeight = 43; // initial estimate
  CompanyName: any;
  IsReboot = true;
  Status = -1;
  source: any;
  corvar:string="Core Version : ";
  forvar:string="Front Version : ";

  constructor(
    private _ngZone: NgZone,
    public apiList: ApilistService,
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService) {
  }

  ngOnInit() {
    this.HidePopups();
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
    console.log('(Re)loading data');
    this.DoQuery(1, 1, true);
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  ngOnDestroy(): void {
    this.HidePopups();
    this.resize$.complete();
  }

  Repaginate() {
    this.Resizing = true;
    this.ItemsPerPageBeforeResize = this.ItemsPerPage;
    this.CurrentPageBeforeResize = this.CurrentPage;
    this.CurrentPage = 1;
    this.ItemsPerPage = this.RecordCount;
  }

  private HidePopups() {
    $('#dots_spinner').hide();
    $('#alert_div').hide();
    $('#MachineDetails').modal('hide');
    $('#myModal').modal('hide');
    $('#LocationDetails').modal('hide');
  }

  reboot(index, id, IsReboot, RebootTimeOut) {
    const check = $('#reboot_img' + index).hasClass('reboot_disabled');
    if (IsReboot === true && !check) {
      this.Status = index;
      $('#dots_spinner').show();
      this.http.post<any>(this.apiList.RebootRequest,
        { Idmachine: id, }, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(resp => {
          if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
          if (+resp.Response !== 1) { throw resp; }
          return resp;
        }).subscribe(response => {
          $('#dots_spinner').hide();
          if (response.Response === 1) {
            $('#reboot_img' + index).addClass('reboot_disabled');
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => {
          $('#dots_spinner').hide();
        });

      setInterval(() => {
        $('#reboot_img' + index).removeClass('reboot_disabled');
      }, RebootTimeOut * 1000);
    }

  }

  /* function for hit the api when click on pagination*/
  /* also called during resizing to correct for fly-away */
  public pageChange(pageNumber: number) {

    this.globalservice.PagingHandler(
      pageNumber,
      this.Records,
      this.ItemsPerPage,
      FixedRecordCount,
      (originPage, finalPage, reload) => this.DoQuery(originPage, finalPage, reload),
      (pageNumber) => this.CurrentPage = pageNumber);
  }

  Fetch(url: string, req: any, finalPage: number, reload: boolean = false) {
    $('#dots_spinner').show();
    this.http.post<any>(url, req, this.globalservice.Headers())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (+result.Response !== 1) { throw result; }
        return result;
      })
      .subscribe((response) => {
        // merge retrieved records into cache
        this.globalservice.MergeRecords({
          cache: this.Records,
          newRecords: response.lst_Machines,
          totalCount: response.TotalCount,
          pageNumber: req.PageNumber,
          itemsPerPage: req.RecordsPerPage,
          reload
        });

        // transition to the requested page
        this.CurrentPage = finalPage;

      }, (err) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }, () => {
      }).add(() => {
        $('#dots_spinner').hide();
        if (this.Resizing) {
          // need to give time to render behind the scenes before size/resize rows
          this.SettleAndAutosize();
        }
      });
  }

  async GoToAdmin(name, cname, link_code, merchantId) {
    this.globalservice.getMerchantDetail(merchantId);
    const result: any = await this.globalservice.ImpersonateApi(merchantId, true);
    if (result.Response === 0) {
      this.FlashError(result.ErrorMessage);
      return;
    }
    this.router.navigate([name.replace(/\s/g, '') + '/dashboard/machine', {
      code: link_code
    }]);
    const sessionId = sessionStorage.getItem('Sessionid');
    this.globalservice.Signout(sessionId);
  }

  searchDebounce($event: KeyboardEvent) {
    if (this.Search == null) { return; }
    // on ENTER, commit the search, no waiting
    if ($event.keyCode === 13 || !this.Search.length) {
      if (this.source) {
        clearTimeout(this.source);
        this.source = null;
      }
      this.OldSearch = this.Search;
      this.DoQuery(1, 1, true);
      return;
    }
    // ... rest is to wait until user stops typing as indication of waiting for search

    // ignore keyup events if string doesn't actually change (like, ENTER)
    if (this.OldSearch === this.Search) {
      return;
    }
    this.OldSearch = this.Search;
    // looks like still typing. Cancel any delay and start waiting all over again
    const oldSource = this.source;
    if (oldSource) {
      clearTimeout(oldSource);
    }
    // delay API call to see if user is still typing.
    this.source = setTimeout(() => {
      this.source = null;
      this.DoQuery(1, 1, true);
    }, 800);
  }

  DoQuery(originPage: number, finalPage: number, reload: boolean) {
    const req: any = {
      PageNumber: originPage,
      RecordsPerPage: this.ItemsPerPage,
      RecordCount: this.RecordCount
    };
    const searchText = this.Search;
    let doSearch = false;
    if (searchText != null && searchText.length !== 0) {
      req.SearchText = searchText;
      doSearch = true;
    }
    this.Fetch(doSearch ? this.apiList.SuperAdminssearchUrl : this.apiList.SuperAdminUrl, req, finalPage, reload);
  }

  sortData(sort: Sort) {
    const data = this.Records.slice();
    if (!sort.active || sort.direction === '') {
      this.Records = data;
      return;
    }
    this.Records = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Company_name': return this.compare(a.CompanyName.toLowerCase(), b.CompanyName.toLowerCase(), isAsc);
        case 'machine_serial': return this.compare(a.MachineSerial.toLowerCase(), b.MachineSerial.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  machine_info(data) {
    $('#MachineDetails').modal('show');
    this.post_info = data;
  }

  location_info(data) {
    $('#LocationDetails').modal('show');
    this.post_info = data;
  }

  clearSearch() {
    clearInterval(this.source);
    this.Search = '';
    this.DoQuery(1, 1, true);
  }

  Unassign(value) {
    this.linkcode = value;
    $('#myModal').modal('show');
  }

  Yes_unassign() {
    this.http.post<any>(this.apiList.UnassignLinkCodeUrl,
      { LinkCode: this.linkcode, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe(response => {
        const unassign = response;
        if (unassign.Response === 1) {

          this.DoQuery(this.CurrentPage, this.CurrentPage, true);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  private AutosizeTable() {
    console.log('Autosize table');
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
      console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;

      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
      // if ItemsPerPage changes, we could suddenly be on a range of rows that
      // have not been pulled from the server.
      // Also, unless user is on the first page they will be at a whole different set of records ("fly-away")
      // we don't know exactly which item the user was looking at, but we can get them
      // pretty close! Using the middle record
      if (this.ItemsPerPageBeforeResize !== this.ItemsPerPage || this.CurrentPageBeforeResize !== this.CurrentPage) {
        const rowToShow = Math.min((this.CurrentPageBeforeResize - 1) * this.ItemsPerPageBeforeResize +
          Math.trunc((this.ItemsPerPageBeforeResize + 1) / 2), Math.max(this.Records.length - 1, 0));
        setTimeout(() => {
          this.pageChange(Math.trunc(rowToShow / this.ItemsPerPage) + 1);
        }, 0);
      }
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  setup(id, MachineType, CanBeMaster, CanBeslave, IdManufacturer, ManufacturerName, IdMachineModel) {
    this.router.navigate(['./Superadmindashboard/Machineslist/Edit', {
      refrenceid: id,
      IdManufacturer: IdManufacturer,
      ManufacturerName: ManufacturerName,
      IdMachineModel: IdMachineModel,
      MachineType: MachineType,
      CanBeMaster: CanBeMaster,
      CanBeslave: CanBeslave
    }]);
  }
}
//





