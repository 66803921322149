import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Sort } from '@angular/material';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';
import { debug } from 'util';
import { Observable } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';
declare const $;

@Component({
  selector: 'app-addcontent',
  templateUrl: './addcontent.component.html',
  styleUrls: ['./addcontent.component.css']
})
export class AddcontentComponent implements OnInit, OnDestroy {
  @ViewChild('addcontent', { static: false }) public addcontentForm: NgForm;
  BaseFileUrl = environment.BaseFileUrl;

  ImageUrl = this.BaseFileUrl + '/ContentDocument/';
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
  showaddress = 'None';
  message: string;
  progress: number;
  FileName: any;
  FilePathURL: any;
  FileMD5Sum: number;
  filename: any;
  file: any;
  total: string;
  sizeInMB: string;
  fileFormat: any;
  submitted:boolean=false;

  constructor(
    private router: Router,
    private http: HttpClient,
    public dialog: MatDialog,
    private globalservice: GlobalService,
    public apiList: ApilistService,
    private userIdle: UserIdleService) { }

  public mask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  lists: any;
  posts: any;
  IdContent: any;
  ContentName: string;
  Clients: any;
  FilePath: string;
  ContentType: string;
  Duration: string;
  ZipCode;
  ClientPhoneNo: string;
  ContactName: string;
  ContactPhoneNo: string;
  ContactEmailId: string;
  CostPerPlay: number;
  CurrencyType: string;
  ClientName: string;
  ClientAddress: string;
  CountryName;
  StateName;
  CityName;
  selectedclient;
  TotalCost;
  myVideos = [];
  Remove: any;
  p = 1;
  checktype;
  color;
  Clientlists;
  Clientdetails;
  IdClient;
  AlertText; image;
  isAdding = false;
  bit: any = false;
  OriginalFileName
  ngOnInit() {
    this.submitted=false;
    this.globalservice.setDiscard_check();
    this.hidepops();
    this.listContent();
    this.clientlist();
  }
  hidepops() {
    $('#Norecord').hide();
    $('#alert_div').hide();
    $('#dots_spinner').hide();
    $('#dots_spinner2').hide();
    $('#dots_spinner3').hide();
  }

  canDeactivate(): any {
    if (!this.isAdding && this.addcontentForm.dirty) {
      if (this.bit === false && this.globalservice.GetDiscard_Check()) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });
        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response === 'ok')) {
            this.dialog.closeAll();
            this.bit = true;
          }
        });
      }
    } else if (this.addcontentForm.dirty) {
      if (this.bit === false) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });
        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response === 'ok')) {
            this.dialog.closeAll();
            this.bit = true;
          }
        });
      }
    }
    return true;
  }


  fileChange(event) {
   // console.log(event)
    const fileList = event.target.files;
    if (fileList.length > 0) {
      this.file = fileList[0];

     // console.log(this.file);
      this.filename = this.file.name;
      var formData: any = new FormData();
      formData.append('ClientDocs', this.file, this.file.name);
      const req = new HttpRequest('POST', this.apiList.Uploadurl, formData, this.globalservice.VideoUploadHeaders());      
      return this.http.request(req).pipe(
        // catchError(this.errorMgmt)
      );
    }
  }

  UploadVedio(event1) {
    $('#dots_spinner').show();
    this.message = 'Please wait';
    this.fileChange(
      event1
    ).subscribe((event: HttpEvent<any>) => {
      //console.log(event);
      switch (event.type) {
        case HttpEventType.Sent:
        //  console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          //console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          //console.log(event.loaded);
          $('#dots_spinner').hide();
          this.sizeInMB = (event.loaded / (1024 * 1024)).toFixed(2);
          //console.log(this.sizeInMB + 'MB');
          this.total = (event.total / (1024 * 1024)).toFixed(2);
          //console.log(this.total + 'MB');
          this.progress = Math.round(event.loaded / event.total * 100);
          this.userIdle.resetTimer();
          //console.log(`Uploaded! ${this.progress}%`);
          break;
        case HttpEventType.Response:
          $('#dots_spinner').hide();
          //console.log('User successfully created!', event.body);
          $('#FileType').val(this.file.type);
          this.fileFormat = this.file.type;
          $('#Filepath').val(this.file.name);
          this.FileName = event.body.FileName;
          this.OriginalFileName = this.file.name;
          this.FilePathURL = event.body.FileURL;
          this.FileName = event.body.FileName;
          this.FileMD5Sum = 1;
          this.ContentType = this.file.type;
          //console.log(this.ContentType);
          this.checktype = this.ContentType.split('/');
          this.checktype = this.checktype[0];

          this.calculateTime();
          break;
        default: console.log("default");
        // setTimeout(() => {
        //   this.progress = 0;
        // }, 1500);

      }
    }).add(() => { $('#dots_spinner').hide(); });

  }

  onLoadStart(){
    $('#dots_spinner').show();
    this.message="Please wait...";
    console.log("inside starttt");
  }

  onLoadedData(){
    $('#dots_spinner').hide();
    this.message="Please wait...";
    console.log("inside loaded");
  }

  calculateTime() {
    var that = this;
    const vid = document.createElement('video');
    const fileURL = URL.createObjectURL(this.file);
    vid.src = fileURL;
    // wait for duration to change from NaN to the actual duration
    vid.ondurationchange = function () {
      $('#getval').val(vid.duration);
      const s = Math.floor(vid.duration);
      const fm = [
        Math.floor(Math.floor(s / 60) / 60) % 60, // HOURS
        Math.floor(s / 60) % 60, // MINUTES
        s % 60                   // SECONDS
      ];
      const t = $.map(fm, function (v, i) { return ((v < 10) ? '0' : '') + v; }).join(':');
      //$('#Dtime').val(t);
      that.Duration = t;
    };
  }

  clientlist() {
    this.http.post<any>(this.apiList.clientlistApi, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.Clientlists = response.lst_Client;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { });
  }

  onchangeclient(id) {
    if (id === 'None') {
      this.showaddress = id;
      this.IdClient = 0;
    } else {
      this.showaddress = id;
      this.IdClient = id;
      $('#dots_spinner').show();
      this.message = 'Please wait';
      this.http.post<any>(this.apiList.clientdetailsApi, { IdClient: this.IdClient, IdMerchant: this.IdMerchant },
        this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(resp => {
          if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
          if (+resp.Response !== 1) { throw resp; }
          return resp;
        }).subscribe((response) => {
          this.Clientdetails = response;
          this.ClientAddress = this.Clientdetails.Obj_Client.ClientAddress;
          this.ClientPhoneNo = this.Clientdetails.Obj_Client.ClientPhoneNo;
          this.CountryName = this.Clientdetails.Obj_Client.CountryName;
          this.StateName = this.Clientdetails.Obj_Client.StateName;
          this.CityName = this.Clientdetails.Obj_Client.CityName;
          this.ContactName = this.Clientdetails.Obj_Client.ContactName;
          this.ContactPhoneNo = this.Clientdetails.Obj_Client.ContactPhoneNo;
          this.ContactEmailId = this.Clientdetails.Obj_Client.ContactEmailId;
          this.ZipCode = this.Clientdetails.Obj_Client.ZipCode;
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#dots_spinner').hide(); });
    }
  }
  cost() {
    this.TotalCost = (this.CostPerPlay * $('#getval').val()) / 60;
  }

  listContent() {
    this.http.post<any>(this.apiList.listApi, { IdMerchant: this.IdMerchant },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.lists = response.lst_Content;
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  delete(id) {
    $('#dots_spinner').show();
    this.message = 'Please wait';
    this.http.post<any>(this.apiList.deleteApi, { IdContent: id }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.FlashError('Successfully deleted');
          this.listContent();
        } else {
          this.FlashError(response.ErrorMessage);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  save() {
    this.isAdding = true;
    this.submitted=true;
    this.Duration = $('#getval').val();
    this.ContentType = $('#FileType').val();
    this.FilePath = $('#Filepath').val();
    $('#dots_spinner').show();
    this.message = 'Please wait';
    if (this.FilePath === 'undefined' || this.FilePath === '') {
      $('#dots_spinner').hide();
      
      this.AlertText = 'All fields are Mandatory. Firstly uplaod Ads content';
      $('#alert_div').show();
      $('#alert_div').animate({
        width: '100%'
      }, 'slow');
      setTimeout(() => {
        $('#alert_div').hide();
      }, 3000);
    }
    
    else if(this.progress!==100){
      $('#dots_spinner').hide();
      this.AlertText = 'Before saving, First complete file uploading';
      $('#alert_div').show();
      $('#alert_div').animate({
        width: '100%'
      }, 'slow');
      setTimeout(() => {
        $('#alert_div').hide();
      }, 3000);
    }
    else {

      this.http.post<any>(this.apiList.SaveContentApi,
        {
          IdClient: this.IdClient,
          ContentName: this.ContentName,
          FilePath: this.FileName,
          ContentType: this.ContentType,
          Duration: this.Duration,
          IdMerchant: this.IdMerchant,
          CostPerPlay: this.CostPerPlay,
          CurrencyType: this.CurrencyType,
          OriginalFileName: this.OriginalFileName
        }, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(resp => {
          if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
          if (+resp.Response !== 1) { throw resp; }
          return resp;
        }).subscribe((response) => {
          this.posts = response;
          this.addcontentForm.resetForm();
          this.addcontentForm.reset();
          
    //form.reset();

    // Object.keys(this.addcontentForm.controls).forEach(key => {
    //   this.addcontentForm.(key).setErrors(null) ;
    // });
          $('#CName').val('');
          $('#FileType').val('');
          $('#Dtime').val('');
          $('#Filepath').val('');
          $('#input').val('');
          this.FilePath = ' ';
          this.checktype = '';
          this.progress=0;
          this.image='';
          this.OriginalFileName='';
          this.showaddress = 'None';
          this.TotalCost=0;
          this.listContent();
          this.submitted=false;
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#dots_spinner').hide(); });
    }
  }

  edit(IdContentVar) {
    this.IdContent = IdContentVar;
    sessionStorage.setItem('referid', this.IdContent);
    this.router.navigate([this.companyName + '/dashboard/digitalsignage/Updatecontent']);
  }
  // -----------sorting----------//
  sortData(sort: Sort) {
    const data = this.lists.slice();
    if (!sort.active || sort.direction === '') {
      this.lists = data;
      return;
    }
    this.lists = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'contentname': return this.compare(a.ContentName.toLowerCase(), b.ContentName.toLowerCase(), isAsc);
        case 'contenttype': return this.compare(a.ContentType.toLowerCase(), b.ContentType.toLowerCase(), isAsc);
        case 'clientname': return this.compare(a.ClientName.toLowerCase(), b.ClientName.toLowerCase(), isAsc);
        case 'duration': return this.compare(a.Duration, b.Duration, isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  // ----------------------//

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  AddScreenbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage']); }
  AddContentbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Addcontent']); }
  Adloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Adloop']); }
  placeloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/placeloop']); }
  preview() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Previewcampaign']); }
  Calendar() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaigncalendar']); }
  report() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaignreports']); }

  ngOnDestroy() {
    this.dialog.ngOnDestroy();
  }
}
