import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';;
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service'
import { ApiCacheStorageService } from '../../../api-cache-storage.service';
declare const $
@Component({
  selector: 'app-updateuser',
  templateUrl: './updateuser.component.html',
  styleUrls: ['./updateuser.component.css']
})
export class UpdateuserComponent implements OnInit {
  companyName = sessionStorage.getItem("companyName");
  UserRole = sessionStorage.getItem("referUserRole");
  SuperMerchantId = + sessionStorage.getItem("referSuperMerchantId")
  LoginId = + sessionStorage.getItem("LoginId");
  posts: any;
  AlertText;
  Id_M: number
  Name: any;
  Lastname: any;
  EmailId: any;
  Role: any;
  IsActive: Boolean;
  password_field = false;
  Password: any;
  ConfirmPassword: any;
  iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass = false;
  type = 'password';
  iconname2 = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass2 = false;
  type2 = 'password';
  compare = true
  nextLibAvailable = false;
  show_message
  disable_check: Boolean;
  IdMasterAdmin: any;
  IsMasterAdmin:boolean=false;
  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    private route: ActivatedRoute,
    public apiList: ApilistService,private apiCacheStorage: ApiCacheStorageService) {
    this.route.params.subscribe((get) => {
      this.Id_M = get.id;
    })
  }

  ngOnInit() {
    this.apiCacheStorage.InvalidateById('GetMerchantDetailsById', this.Id_M);
    this.Getuserinfo();
    $("#alert_div").hide();
    $('#dots_spinner').hide();
    //this.updateuser();
  }
  onValChange(value) {
    this.IsActive = value.checked;
  }

  async Getuserinfo() {
    this.http.post<any>(this.apiList.MerchantDetailUrl, { IdMerchant: this.Id_M }, this.globalservice.Headers()).subscribe((response) => {
      this.posts = response;
      // console.log( this.posts );
      if (this.posts.Response == 1) {
        this.Name = this.posts.obj_MerchantRecord.Name;
        this.Lastname = this.posts.obj_MerchantRecord.LastName;
        this.EmailId = this.posts.obj_MerchantRecord.EmailId;
        this.Role = this.posts.obj_MerchantRecord.UserRole;
        this.IsActive = this.posts.obj_MerchantRecord.IsActive;
        this.IdMasterAdmin = this.posts.obj_MerchantRecord.IdMasterAdmin;
        this.IsMasterAdmin=this.posts.obj_MerchantRecord.IsMasterAdmin;
        // console.log(this.IsMasterAdmin+"this.IsMasterAdmin");
      }
    })
  }


  submit_click() {
    if (this.Password == this.ConfirmPassword) {
      $('#dots_spinner').show();
      var data = {
        Name: this.Name,
        LastName: this.Lastname,
        EmailId: this.EmailId,
        IsActive: this.IsActive,
        Password: this.Password,
        IdMerchant: this.Id_M,
        UserRole:this.Role
      }
      this.http.post<any>(this.apiList.UpdateUserUrl, data, this.globalservice.Headers())
         .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if(response.Response==1){
           this.FlashError("Update successfully")
          this.router.navigate([this.companyName + '/dashboard/users']);
      }
    },(err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {  $('#dots_spinner').hide();
       });;
    } else {
      this.FlashError("Password and Confirm Password does not match");
    }
  }


  showPassword() {
    console.log("showpass"+this.showPass);
    this.showPass = !this.showPass;
    if (this.showPass) {
      this.type = 'text';
      this.iconname = 'fa fa-fw fa-eye field-icon toggle-password';
    } else {
      this.type = 'password';
      this.iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
    }
  }

  confirm_pass() {
    // console.log("dgvfdg")
    this.showPass2 = !this.showPass2;
    if (this.showPass2) {
      this.type2 = 'text';
      this.iconname2 = 'fa fa-fw fa-eye field-icon toggle-password';
    } else {
      this.type2 = 'password';
      this.iconname2 = 'fa fa-fw fa-eye-slash field-icon toggle-password';
    }
  }

  onKey(event: any) { // without type info
    if (this.Password == this.ConfirmPassword) {
      this.compare = true
    } else {
      this.show_message = "Password and Confirm Password does not match"
      this.compare = false;
    }
  }
  onKey1() {
    if (this.Password) {
      this.show_message = "";
      this.nextLibAvailable = true;
    } else {
      this.show_message = "";
      // this.show_message = " First fill User Password"
      this.nextLibAvailable = false;
      this.compare = false;
    }
  }




  goback() {
    this.router.navigate([this.companyName + '/dashboard/users']);
  }

  show_password() {
    this.password_field = true;
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}
