import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emailverification',
  templateUrl: './emailverification.component.html',
  styleUrls: ['./emailverification.component.css']
})
export class EmailverificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
