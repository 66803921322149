import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Rx';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../dialog/dialog.component';
declare const $;
@Component({
  selector: 'app-digitalsignage',
  templateUrl: './digitalsignage.component.html',

  styleUrls: ['./digitalsignage.component.css']
})
export class DigitalsignageComponent implements OnInit, OnDestroy {
  @ViewChild('addscreen', { static: false }) public addscreenForm: NgForm;
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
  screenHeight: number;
  before: number;
  pageno: any;
  Search;
  ll: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public dialog: MatDialog,
    public apiList: ApilistService) {
    this.screenHeight = window.innerHeight;
    const rowno = (window.innerHeight - 388) / 70;
    if (rowno >= 1) {
      this.before = parseInt(rowno.toFixed(2).toString().split('.')[0], 10);
    } else {
      this.before = 1;
    }
  }

  ScreenOrientation = 'Horizontal';
  ScreenSize: string;
  ScreenResolution = '1920 x 1080';
  posts: any;
  lists: any;
  Remove: any;
  IdScreen: any;
  SpecificationName: any;
  p = 1;
  AlertText;
  isAdding = false;
  bit: any = false;
  ngOnInit() {
    this.globalservice.setDiscard_check();
    this.hidepops();
    this.ScreenList();
  }
  hidepops() {
    $('#dots_spinner').hide();
    $('#alert_div').hide();
  }

  orientation(value) {
    if (value === 'Horizontal') {
      this.ScreenResolution = '1920 x 1080';
    }
    if (value === 'Vertical') {
      this.ScreenResolution = '1080 x 1920';
    }
  }

  canDeactivate(): any {
    if (!this.isAdding && this.addscreenForm.dirty) {
      if (this.bit === false && this.globalservice.GetDiscard_Check()) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });
        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response === 'ok')) {
            this.dialog.closeAll();
            this.bit = true;
          }
        });
      }
    } else if (this.addscreenForm.dirty) {
      if (this.bit === false) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });
        return dialogRef.afterClosed().subscribe(result => {
          if (result && (result.response === 'ok')) {
            this.dialog.closeAll();
            this.bit = true;
          }
        });
      }
    }
    return true;
  }

  SaveAs() {
    this.isAdding = true;
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.SaveApi,
      {
        ScreenResolution: this.ScreenResolution,
        ScreenSize: this.ScreenSize,
        ScreenOrientation: this.ScreenOrientation,
        SpecificationName: this.ScreenSize + '_' + this.ScreenOrientation + '_' + this.ScreenResolution,
        CreatedBy: this.IdMerchant,
        ScreenName: 'Removed'
      }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.posts = response;
        if (this.posts.Response === 1) {
          this.addscreenForm.reset();
          this.ScreenResolution = '';
          this.SpecificationName = '';
          this.FlashError('Successfully Submitted');
          this.ScreenList();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else if (Error.Response === 2) {
          this.FlashError('This Screen specifications Already Exist');
          this.ScreenList();
        }else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  ScreenList() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ListApi, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.ll = response;
          this.lists = this.ll.lsy_Content;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  delete(id) {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteApi, { IdScreen: id }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.FlashError('Successfully deleted');
          this.Remove = response;
          this.ScreenList();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
           this.ScreenList();
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  edit(Id) {
    this.router.navigate([this.companyName + '/dashboard/digitalsignage/Updatescreen', {
      id: Id
    }]);
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  // -----------sorting----------//
  sortData(sort: Sort) {
    const data = this.lists.slice();
    if (!sort.active || sort.direction === '') {
      this.lists = data;
      return;
    }
    this.lists = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'screensize': return this.compare(a.ScreenSize, b.ScreenSize, isAsc);
        case 'specificationname': return this.compare(a.SpecificationName.toLowerCase(), b.SpecificationName.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  pageChange(event) {
    // console.log(event);
    this.pageno = event;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerHeight;
    this.screenHeight = window.innerHeight;
    const rowno = (window.innerHeight - 388) / 54;
    this.before = parseInt(rowno.toFixed(2).toString().split('.')[0], 10); // before
    if (this.before >= 0) {
      if (this.before === 0) {
        this.before = 1;
      }
    }
  }

  AddScreenbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage']); }
  AddContentbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Addcontent']); }
  Adloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Adloop']); }
  placeloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/placeloop']); }
  preview() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Previewcampaign']); }
  Calendar() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaigncalendar']); }
  report() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaignreports']); }

  ngOnDestroy() {
    this.dialog.ngOnDestroy();
  }
}

