import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { UserService } from '../user.service';
import { GlobalService } from '../global.service';
import { ApilistService } from '../../service/Api/apilist.service';
import { UserIdleService } from 'angular-user-idle';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { InternationalPhoneNumber2Module } from 'ngx-international-phone-number2';
declare const $;
declare var grecaptcha: any;

import { Observable, ReplaySubject, Subject } from 'rxjs/Rx';
import { take, takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';

export interface Bank {
  IdCountry: string;
  CountryName: string;
}

export interface States {
  IdState: string;
  StateName: string;
}
export interface city {
  IdCity: string;
  CityName: string;
}

export interface FormModel {
  captcha?: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('personForm', { static: false }) public personForm: NgForm;
  @ViewChild('contactUsForm', { static: false }) public contactUsForm: NgForm;
  public mask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  posts: any;
  LoginName = '';
  Password = '';
  baseUrlSkin=environment.baseUrlSkin;
  selectedcountry;
  CompanyName;
  ContactPerson;
  ContactPersonNumber;
  ContactPersonEmailId;
  RequestedUserName = '';
  RequestedPassword;
  CompanyPhoneNumber;
  CompanyAddress;
  City;
  State;
  Zip;
  lists;
  AlertText;
  IdMerchant;
  logoCompanyName;
  countrylists;
  IdState;
  selectedstate: any;
  selectedcity: any;
  IdCountry: any;
  states;
  citys;
  UserRole;
  Emailid;
  Rposts;
  signupThankYou;
  checkusername;
  checkEmailId;
  LastName;
  FirstName;
  IdMasterMerchant;
  verified = false;
  Name: string;
  Email: string;
  Phone: string;
  Message: string;
  Msg_Sent = false;
  User_role: any;
  ipAddress: Response;
  error = false;
  LoginId: any;
  Latitude = '';
  Longitude = '';
  Session_popup: string;
  Show_popup: any;
  validate: string;
  public signupForm: FormGroup;
  Bank = [];
  protected banks: Bank[];
  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  protected _onDestroy = new Subject<void>();
  States = [];
  protected statesselect: States[] = [];
  public stateCtrl: FormControl = new FormControl();
  public stateFilterCtrl: FormControl = new FormControl();
  public filteredstate: ReplaySubject<States[]> = new ReplaySubject<States[]>(1);
  city = [];
  protected cityselect: city[];
  public cityCtrl: FormControl = new FormControl();
  public cityFilterCtrl: FormControl = new FormControl();
  public filteredcity: ReplaySubject<city[]> = new ReplaySubject<city[]>(1);
  public formModel: FormModel = {};
  geometry_address: any;
  timezone_address: any;
  timezoneid: string;
  dstOffset: any;
  rawOffset: any;
  completeaddress: string;
  zipValidationAddress:string;
  constructor(
    public formBuilder: FormBuilder,
    public apiList: ApilistService,
    private router: Router,
    private userIdle: UserIdleService,
    private http: HttpClient,
    private user: UserService,
    private globalservice: GlobalService, @Inject(DOCUMENT) private document: Document) {
    $('#Login_Idle_popup').modal('hide');
  }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      selectedcountry: new FormControl(),
      selectedcity: new FormControl(),
      selectedstate: new FormControl(),
    });

    this.userIdle.stopTimer();
    this.userIdle.stopWatching();
    this.globalservice.getTenantSkin();
    sessionStorage.setItem('referMerchantId', '');
    this.Show_popup = this.globalservice.getsession();
    if (this.Show_popup === 'Yes') {
      this.AlertText = '';
      this.AlertText = 'Your session is inactive.';
      $('#Login_Idle_popup').modal('show');
      setTimeout(() => {
        $('#Login_Idle_popup').modal('hide');
      }, 3000);
    } else {
      $('#Login_Idle_popup').modal('hide');
    }
    $('.signUpMsg').hide();
    $('#dots_spinner').hide();
    $('#alert_div').hide();
    $('.toggle-password').click(function () {
      $(this).toggleClass('fa-eye fa-eye-slash');
      const input = $($(this).attr('toggle'));
      if (input.attr('type') === 'password') {
        input.attr('type', 'text');
      } else {
        input.attr('type', 'password');
      }
    });
    if (this.globalservice.getTimeoutFlag()) {
      $('#Login_timeout_popup').modal('show');
    }
  }

  YesTimeout() {
    this.globalservice.resetTimeoutFlag();
    $('#Login_timeout_popup').modal('hide');
  }

  ContactUS() {
    this.Msg_Sent = false;
    $('#myModal2').modal('hide');
    $('#contactModal').modal('show');
  }
  Yes() {
    $('#Login_Idle_popup').modal('hide');
  }
  Close_Signup() {
    $('#myModal2').modal('hide');
    this.personForm.reset();
  }

  close_contactModal() {
    $('#contactModal').modal('hide');
    this.contactUsForm.reset();
    grecaptcha.reset();
  }

  check() {
    if (this.Phone.length < 12) {
      delete this.validate;
    }
  }

  ContactUsAPi() {
    delete this.validate;
    if (this.Phone) {
      if (this.Phone.length < 12) {
        return this.validate = 'InValid Number';
      } else {

        if (grecaptcha.getResponse() === '') {
          this.verified = true;
          this.FlashError('You can\'t proceed! First fill the captcha');
        } else {
          $('#dots_spinner').show();
          const data = {
            Name: this.Name,
            Email: this.Email,
            Phone: this.Phone,
            Message: this.Message
          };
          this.http.post<any>(
            this.apiList.ContactUsUrl, data, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(result => {
              if (result.Response !== 1) { throw result; }
              return result;
            }).subscribe((response) => {
              if (response) {
                this.verified = false;
                grecaptcha.reset();
                this.contactUsForm.reset();
                $('#contactModal').modal('hide');
                this.Msg_Sent = true;
                setTimeout(() => {
                  this.Msg_Sent = false;
                }, 1000 * 10);
              }
            }, (err) => {
              const Error = this.globalservice.ProcessError(err);
              if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                  this.FlashError(Error.ErrorMessage);
                  this.globalservice.Logout('Yes');
                }
                this.globalservice.Logout('No');
                return;
              } else {
                this.FlashError(Error.ErrorMessage);
                return;
              }
            }).add(() => { $('#dots_spinner').hide(); });
        }
      }
    }
  }

  Signupclick() {
    $('#myModal2').show();
    this.signupThankYou = '';
    this.getcountry();
  }
  forgotPassword() {
    this.Emailid = '';
  }
  getcountry() {
    $('#dots_spinner').show();
    this.globalservice.getCountry().then((res: any) => {
      $('#dots_spinner').hide();
      this.banks = res;
      this.bankCtrl.setValue(this.banks);
      this.filteredBanks.next(this.banks.slice());
      this.bankFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterBanks();
        });
      this.bankCtrl.setValue(231);
      this.selectedcountry = 231;
      this.getstates();
    }).catch(err => {
      this.FlashError(err.message);
      $('#dots_spinner').hide();
    });
  }

  protected filterBanks() {
    if (!this.banks) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.banks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.banks.filter(bank => bank.CountryName.toLowerCase().indexOf(search) > -1)
    );
  }

  onChangeCountry(a) {
    this.selectedcountry = a;
    this.getstates();
  }

  getstates() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.StateUrl, { IdCountry: this.selectedcountry }, this.globalservice.Headers())
      .subscribe((response) => {
        this.statesselect = [];
        $('#dots_spinner').hide();
        if (response.lst_State.length > 0) {
          response.lst_State.forEach((item: States) => {
            if (item.StateName !== '') {
              this.statesselect.push(item);
            }
          });
        }
        this.stateCtrl.setValue(this.statesselect);
        this.filteredstate.next(this.statesselect.slice());
        this.stateFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            if (!this.statesselect) {
              return;
            }
            // get the search keyword
            let search = this.stateFilterCtrl.value;
            if (!search) {
              this.filteredstate.next(this.statesselect.slice());
              return;
            } else {
              search = search.toLowerCase();
            }
            // filter the banks
            this.filteredstate.next(
              this.statesselect.filter(states => states.StateName.toLowerCase().indexOf(search) > -1)
            );
          }, (err) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => { $('#dots_spinner').hide(); });
      }, (err) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  onChangeState(b) {
    this.IdState = b;
    this.getcity();
  }

  getcity() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CityUrl, { IdState: this.IdState }, this.globalservice.Headers())
      .subscribe((response) => {
        $('#dots_spinner').hide();
        this.citys = response.lst_City;
        this.cityselect = response.lst_City;
        this.cityCtrl.setValue(this.cityselect);
        this.filteredcity.next(this.cityselect.slice());
        this.cityFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            if (!this.cityselect) {
              return;
            }
            // get the search keyword
            let search = this.cityFilterCtrl.value;
            if (!search) {
              this.filteredcity.next(this.cityselect.slice());
              return;
            } else {
              search = search.toLowerCase();
            }
            // filter the banks
            this.filteredcity.next(
              this.cityselect.filter(citys => citys.CityName.toLowerCase().indexOf(search) > -1)
            );
          }, (err) => {
            const Error = this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
              if (Error.ErrorMessage) {
                this.FlashError(Error.ErrorMessage);
                this.globalservice.Logout('Yes');
              }
              this.globalservice.Logout('No');
              return;
            } else {
              this.FlashError(Error.ErrorMessage);
              return;
            }
          }).add(() => { $('#dots_spinner').hide(); });
      }, (err) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  async Signbtn() {
    $('#dots_spinner').show();
    this.posts = await this.globalservice.LoginApi(this.LoginName, this.Password);
    //console.log("this.posts"+JSON.stringify(this.posts));
    $('#dots_spinner').hide();
    if (this.posts.Response !== 1) {
      this.FlashError(this.posts.ErrorMessage);
      return;
    }
    this.LoginName = '';
    this.Password = '';
    this.user.setUserLoggedIn();
    const scrubbedCompanyName = this.posts.ObjUserRecordClass.CompanyName.replace(/\s/g, '');
    this.globalservice.SaveIdentity(this.posts);
    if(this.posts.ObjUserRecordClass.SkinCssFileUrl){
       this.globalservice.loadStyle(this.baseUrlSkin+this.posts.ObjUserRecordClass.SkinCssFileUrl);
    }

    //else case if no tenant dns is set

    
    else{
      var defaultSkin=this.baseUrlSkin+'assets/css/skins/defaultSkin.css';
      this.globalservice.loadStyle(defaultSkin);
    }
  // console.log("this.posts.UserRole"+this.posts.UserRole);
    switch (this.posts.UserRole) {
      case 'Admin':
      case 'Merchant':
        this.router.navigate([scrubbedCompanyName + '/dashboard/placeaMachine']);
        break;
      case 'SuperAdmin':
        this.router.navigate(['Superadmindashboard/Machineslist']);
        break;

        case 'BrandUser':
          this.router.navigate([scrubbedCompanyName + '/dashboard/brandusers']);
        break;
      case 'Distributor':
        this.router.navigate([scrubbedCompanyName + '/dashboard/InventoryManagement']);
        break;
    }



    

    
  }
  
  focusOutFunction2() {
    this.http.post<any>(this.apiList.CheckUserNameUrl,
      { RequestedUserName: this.RequestedUserName }, this.globalservice.Headers())
      .subscribe((response) => {
        this.checkusername = response;
        if (this.checkusername.Response === 2) {
          $('#errorlable2').remove();
          $('#requsername').append('<small id=\'errorlable2\' style=\'color:red;padding:5px 0 0 12px\'>* This UserName is already in Use, please try another one</small>');
          this.RequestedUserName = '';
        } else if (this.checkusername.Response === 1) {
          $('#errorlable2').remove();
        }
      }, (err) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  focusOutFunction1() {
    this.http.post<any>(this.apiList.CheckEmailExistUrl,
      { EmailId: this.ContactPersonEmailId }, this.globalservice.Headers())
      .subscribe((response) => {
        this.checkEmailId = response;
        if (this.checkEmailId.Response === 2) {
          this.error = true;
        } else {
          this.error = false;
        }
      }, (err) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  ZipcodeExist() {
    if (this.selectedcity) {
      this.http.post<any>(this.apiList.CheckZipCodeExist,
        { ZipCode: this.Zip, IdCity: this.selectedcity }, this.globalservice.headerWithoutauth())
        .subscribe((response) => {
          if (response) {
            if (response.Response === 1) {
              this.Latitude = response.Latitude;
              this.Longitude = response.Longitude;
            } else if (response.Response === 4) {
              this.AlertText = "The zip code "+  this.Zip  +" is not recognized, are you sure?";
              $('#myModal').modal('show');
            } else {
              this.FlashError("There was an internal error processing this zip code");
            }
          }
        }, (err) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        });
    } else {
      this.Latitude = '';
        this.Longitude = '';
      this.Zip = '';
      this.timezoneid='';
      this.dstOffset='';
      this.rawOffset='';
      this.FlashError('Please select city first');
    }
  }

  checkZipGoogleApi(){
    this.zipValidationAddress = this.selectedcity + " " + this.Zip ;
    // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
    // //console.log(this.completeaddress);
     this.zipValidationAddress = this.zipValidationAddress.replace(/  +/g, ' ');
    // this.completeaddress = this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selectedcountry;
    // // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
    // //console.log(this.completeaddress);
    // this.completeaddress = this.completeaddress.replace(/  +/g, ' ');
    // this.completeaddress = this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selectedcountry;
    // // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
    // //console.log(this.completeaddress);
    // this.completeaddress = this.completeaddress.replace(/  +/g, ' ');
    this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.zipValidationAddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.geometry_address = response;
      if (this.geometry_address.status == "OK") {
        var lat = this.geometry_address.results[0].geometry.location.lat;
        var long = this.geometry_address.results[0].geometry.location.lng;
        this.GetTimezoneID(lat, long);
      } else {
        this.Latitude = '';
        this.Longitude = '';
        this.Zip='';
        this.timezoneid='';
        this.dstOffset='';
        this.rawOffset='';
        this.FlashError("Invalid Zip Code");
      }
    })
  }

  
  GetTimezoneID(lat, long) {
    var time = new Date().getTime();
    var date_timestamp = Math.round(time / 1000);
    this.http.get<any>("https://maps.googleapis.com/maps/api/timezone/json?location=" + lat + "," + long + "&timestamp=" + date_timestamp + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.timezone_address = response;
      // console.log(this.timezone_address)
      if (this.timezone_address.status == "OK") {
        this.timezoneid = this.timezone_address.timeZoneId;
        this.dstOffset = this.timezone_address.dstOffset;
        this.rawOffset = this.timezone_address.rawOffset;
        this.Latitude=lat;
        this.Longitude=long;
        // console.log("this.Latitude"+this.Latitude);
        // console.log("this.Longitude"+this.Longitude);
      } else {
        this.Latitude = '';
        this.Longitude = '';
        this.Zip='';
        this.timezoneid='';
        this.dstOffset='';
        this.rawOffset='';
        this.FlashError("Please fill valid Zip Code");
      }
    })
  }

  No() {
    this.Zip = '';
  }

  Signup() {
    this.http.post<any>(this.apiList.SignupUrl,
      {
        Latitude: this.Latitude,
        Longitude: this.Longitude,
        Name: this.FirstName,
        LastName: this.LastName,
        EmailId: this.ContactPersonEmailId,
        Password: this.RequestedPassword,
        CompanyName: this.CompanyName,
        CompanyAddress: this.CompanyAddress,
        IdCountry: this.selectedcountry,
        IdCity: this.selectedcity,
        IdState: this.selectedstate,
        Zip: this.Zip,
        CompanyPhoneNumber: this.CompanyPhoneNumber,
        ContactPerson: this.FirstName + ' ' + this.LastName,
        ContactPersonNumber: this.ContactPersonNumber,
        ContactPersonEmailId: this.ContactPersonEmailId,
        RequestedUserName: this.RequestedUserName,
        RequestedPassword: this.RequestedPassword,
        ImagePath: '', UserRole: this.User_role,
        TimeZoneId:this.timezoneid
      }, this.globalservice.headerWithoutauth())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (+result.Response !== 1) { throw result; }
        return result;
      })
      .subscribe((response) => {
        if (response) {
          if (response.Response === 1) {
            this.personForm.reset();
            this.signupThankYou = 'Done';
            setTimeout(() => {
              this.signupThankYou = '';
              $('#myModal2').hide();
            }, 5000);
          } else if (response.Response === 2) {
            this.FlashError('Username/EmailId already exists');
            this.RequestedUserName = '';
            this.ContactPersonEmailId = '';
          } else if (response.Response === 0) {
            this.FlashError('Some error Found');
          }
        }
      }, (err) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      });
  }

  forgot() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.ForgotPswUrl, { LoginName: this.Emailid }, this.globalservice.headerWithoutauth())
      .take(1)
      .timeout(25000)
      .map(result => {
        if (result.Response !== 1) { throw result; }
        return result;
      })
      .subscribe((response) => {
        if (response) {
          this.FlashError('Check your email to reset the password');
        }
      }, (err) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
        $('#dots_spinner').hide();

        $('#ForgotModal').modal('hide');
      });
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  ngOnDestroy() {
    this.userIdle.startWatching();
  }
}
