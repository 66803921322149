import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
import { environment } from '../../../../../environments/environment';
import { GlobalService } from '../../../../global.service';
import { ApilistService } from '../../../../../service/Api/apilist.service';
import { UserIdleService } from 'angular-user-idle';
declare const $;
@Component({
  selector: 'app-updatecontent',
  templateUrl: './updatecontent.component.html',
  styleUrls: ['./updatecontent.component.css']
})
export class UpdatecontentComponent implements OnInit {
  BaseFileUrl = environment.BaseFileUrl;
  ImageUrl = this.BaseFileUrl + '/ContentDocument/';
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
  FileMD5Sum: any;
  AlertText: any;
  showaddress: string;
  FilePathURL: any;
  OriginalFileName: any;
  FileName: any;
  file: any;
  progress: number;
  fileFormat: any;
  message: string;
  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public apiList: ApilistService,
    private userIdle: UserIdleService) { }

  public mask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  IdContent: string;
  ContentName: string;
  ContentType: string;
  Duration: string;
  ClientName: string;
  ClientAddress: string;
  ZipCode;
  ClientPhoneNo: string;
  ContactName: string;
  ContactPhoneNo: string;
  ContactEmailId: string;
  CostPerPlay: number;
  CurrencyType: string;
  Clientlists;
  color;
  checktype;
  IdClient;
  Clientdetails;
  selectedclient;
  CountryName;
  StateName;
  CityName;
  posts;
  details; image;
  TotalCost: number;
  total: string;
  sizeInMB: string;

  ngOnInit() {
    this.color = 'warn';
    this.hidepops();
    this.IdContent = sessionStorage.getItem('referid');
    this.getContentDetails();
    this.clientlist();
  }
  hidepops() {
    $('#dots_spinner').hide();
    $('#alert_div').hide();
    $('#dots_spinner3').hide();
  }

  onLoadStart(){
    $('#dots_spinner').show();
    this.message="Please wait...";
    console.log("inside starttt");
  }

  onLoadedData(){
    $('#dots_spinner').hide();
    this.message="Please wait...";
    console.log("inside loaded");
  }

  getContentDetails() {
    this.http.post<any>(this.apiList.ContentDetailApi, { IdContent: this.IdContent }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.details = response;
          this.details = this.details.Obj_ContentClass;
          this.FilePathURL = this.details.FilePathURL;
          this.FileName= this.details.FilePath
          this.OriginalFileName= this.details.OriginalFileName;
          this.ContentName = this.details.ContentName;
          this.ContentType = this.details.ContentType;
          this.checktype = this.ContentType.split('/');
          this.checktype = this.checktype[0];
          this.Duration = this.details.Duration;
          this.IdClient = this.details.IdClient;
          if (this.IdClient !== 0) {
            this.selectedclient = this.IdClient;
            this.showaddress = this.IdClient;
            this.client();
          }
          if (this.IdClient === 0) {
            this.selectedclient = 'None';
            this.showaddress = 'None';

          }
          this.FileMD5Sum = this.details.FileMD5Sum;
          this.CostPerPlay = this.details.CostPerPlay;
          this.CurrencyType = this.details.CurrencyType;
          this.TotalCost = ((this.CostPerPlay) * parseFloat(this.Duration)) / 60;
        } else {
          this.flashError(response.ErrorMessage);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.flashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.flashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }


  Costchange() {
    this.TotalCost = ((this.CostPerPlay) * parseFloat(this.Duration)) / 60;
  }
  client() {
    this.http.post<any>(this.apiList.clientdetailsApi, { IdClient: this.IdClient, IdMerchant: this.IdMerchant },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response) {
          this.ClientAddress = response.Obj_Client.ClientAddress;
          this.ClientPhoneNo = response.Obj_Client.ClientPhoneNo;
          this.CountryName = response.Obj_Client.CountryName;
          this.StateName = response.Obj_Client.StateName;
          this.CityName = response.Obj_Client.CityName;
          this.ContactName = response.Obj_Client.ContactName;
          this.ContactPhoneNo = response.Obj_Client.ContactPhoneNo;
          this.ContactEmailId = response.Obj_Client.ContactEmailId;
          this.ZipCode = response.Obj_Client.ZipCode;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.flashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.flashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  fileChange(event){
    //console.log(event)
    const fileList = event.target.files;
  if (fileList.length > 0) {
     this.file = fileList[0];
    this.FileName = this.file.name
    var formData: any = new FormData();
   formData.append('ClientDocs', this.file, this.file.name);

   const req = new HttpRequest('POST', this.apiList.Uploadurl, formData, this.globalservice.VideoUploadHeaders());      
   return this.http.request(req).pipe(
     // catchError(this.errorMgmt)
   );

    // return this.http.post(this.apiList.Uploadurl, formData, this.globalservice.VideoUploadHeaders()).pipe(
    //  // catchError(this.errorMgmt)
    // )
  }
  }

  UploadVedio(event1) {
    $('#dots_spinner').show();
    this.message = 'Please wait';
    this.fileChange(
      event1
    ).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          //console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          //console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          $('#dots_spinner').hide();
          this.userIdle.resetTimer();
          this.sizeInMB = (event.loaded / (1024*1024)).toFixed(2);
          //console.log(this.sizeInMB + 'MB');
          this.total = (event.total / (1024*1024)).toFixed(2);
          this.progress = Math.round(event.loaded / event.total * 100);
          //console.log(`Uploaded! ${this.progress}%`);
          break;
        case HttpEventType.Response:
          $('#dots_spinner').hide();
         // console.log('User successfully created!', event.body);
           $('#FileType').val(this.file.type);
           this.fileFormat = this.file.type
                    $('#Filepath').val(this.file.name);
                    this.FileName=event.body.FileName;
                    this.OriginalFileName=this.file.name;
                    this.FilePathURL=event.body.FileURL
                    this.FileName=event.body.FileName;
                    this.FileMD5Sum = 1;
                    this.ContentType = $('#FileType').val();
                    this.checktype = this.ContentType.split('/');
                    this.checktype = this.checktype[0];
                       
   this.calculateTime();
   break;
          // setTimeout(() => {
          //   this.progress = 0;
          // }, 1500);

      }
    }).add(() => { $('#dots_spinner').hide(); });
  }

  calculateTime(){
    var that = this
    const vid = document.createElement('video');
    const fileURL = URL.createObjectURL(this.file);
    vid.src = fileURL;
    // wait for duration to change from NaN to the actual duration
    vid.ondurationchange = function () {
      $('#getval').val(vid.duration);
      const s = Math.floor(vid.duration);
      const fm = [
        Math.floor(Math.floor(s / 60) / 60) % 60, // HOURS
        Math.floor(s / 60) % 60, // MINUTES
        s % 60                   // SECONDS
      ];
      const t = $.map(fm, function (v, i) { return ((v < 10) ? '0' : '') + v; }).join(':');
      that.Duration = t
      console.log( that.Duration);
      $('#Dtime').val(t);
      console.log($('#Dtime').val(t))
    };
  }

  clientlist() {
    this.http.post<any>(this.apiList.clientlistApi, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response) {
          this.Clientlists = response.lst_Client;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.flashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.flashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  onchangeclient(id) {
    if (id === 'None') {
      this.showaddress = id;
      this.IdClient = 0;
    } else {
      this.IdClient = id;
      this.http.post<any>(this.apiList.clientdetailsApi, { IdClient: this.IdClient }, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(resp => {
          if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
          if (+resp.Response !== 1) { throw resp; }
          return resp;
        }).subscribe((response) => {
          this.Clientdetails = response;
          this.ClientAddress = this.Clientdetails.Obj_Client.ClientAddress;
          this.ClientPhoneNo = this.Clientdetails.Obj_Client.ClientPhoneNo;
          this.CountryName = this.Clientdetails.Obj_Client.CountryName;
          this.StateName = this.Clientdetails.Obj_Client.StateName;
          this.CityName = this.Clientdetails.Obj_Client.CityName;
          this.ContactName = this.Clientdetails.Obj_Client.ContactName;
          this.ContactPhoneNo = this.Clientdetails.Obj_Client.ContactPhoneNo;
          this.ContactEmailId = this.Clientdetails.Obj_Client.ContactEmailId;
          this.ZipCode = this.Clientdetails.Obj_Client.ZipCode;
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.flashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.flashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#dots_spinner').hide(); });
    }
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/digitalsignage/Addcontent']);
  }

  save() {
    if ($('#getval').val() !== '') {
      this.Duration = $('#getval').val();
    }
    this.http.post<any>(this.apiList.EditApi,
      {
        IdContent: this.IdContent,
        IdClient: this.IdClient,
        ContentName: this.ContentName,
        FilePath:  this.FileName,
        ContentType: this.ContentType,
        Duration: this.Duration,
        IdMerchant: this.IdMerchant,
        CostPerPlay: this.CostPerPlay,
        CurrencyType: this.CurrencyType,
        OriginalFileName: this.OriginalFileName
      },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.posts = response;
        if (this.posts.Response === 1) {
          this.router.navigate([this.companyName + '/dashboard/digitalsignage/Addcontent']);
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.flashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.flashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });


  }

  flashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }
}
