import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service'
declare const $;

@Component({
  selector: 'app-editplacemachine',
  templateUrl: './editplacemachine.component.html',
  styleUrls: ['./editplacemachine.component.css']
})
export class EditplacemachineComponent implements OnInit {

  companyName = sessionStorage.getItem("companyName");
  IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
  posts: any;
  AlertText: string;
  IdPlaceMachine: any;
  selectedValue: any;
  IdLocation: any;
  ldetail: any;
  detail: any;
  selectedMachine: any;
  IdMachine: any;
  Mdetails: any;
  //names: any;
 // array: any[];
  machine_id: any;
 // updatemachine: any;

  constructor(public apiList: ApilistService, private router: Router, private http: HttpClient, private globalservice: GlobalService, private route: ActivatedRoute) {
    this.route.params.subscribe((get) => {
      this.IdPlaceMachine = get.id;
      //  console.log(this.IdPlaceMachine)
    })
  }

  ngOnInit() {
    this.GetPlaceMachineDetail();
    this.LocationLstUrl();
    this.HidePopups()
    // this.UnplaceMachineLst();
  }

HidePopups(){
    $('#dots_spinner').hide();
    $("#location").hide();
    $("#vertical_columns").hide();
    $("#alert_div").hide();
}

  GetPlaceMachineDetail() {
    this.http.post<any>(this.apiList.GetPlaceMachineDetailUrl, {  IdPlaceMachine: this.IdPlaceMachine }, this.globalservice.Headers())
    .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      this.detail = response;
      // console.log(this.detail)
      if (this.detail.Response == 1) {
        this.selectedValue = this.detail.obj_PlaceMachine.Idlocation;
        // this.array = []; 
        this.machine_id = this.detail.obj_PlaceMachine.IdMachine
        this.selectedMachine = this.detail.obj_PlaceMachine.MachineSerial;
        // console.log(this.selectedMachine)
        this.onChangelocation()
        this.onChangeMachine()
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {});
  }

  LocationLstUrl() {
    this.http.post<any>(this.apiList.LocationListUrl, {  IdMerchant: this.IdMerchant }, this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if( response.Response==1){
        this.posts = response;
      this.posts = this.posts.lst_Location;
      if (this.posts.length == 0) {
        this.FlashError("Please Add a Location")
      }
     }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { });
  }

  onChangelocation() {
    $('#dots_spinner').show();
    this.IdLocation = this.selectedValue;
    this.http.post<any>(this.apiList.LocationDetailUrl, {  IdLocation: this.IdLocation }, this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.ldetail = response.Obj_MachineList[0];
        // console.log(this.ldetail);
        $("#location").show();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      $('#dots_spinner').hide(); });
  }

  onChangeMachine() {
    $('#dots_spinner').show();
    $("#tray_moter").empty();
    // id = id.map(function (e) {
    //   return JSON.stringify(e);
    // });
    // id = id.join(",");
    // this.IdMachine = id;
    let url = `${this.apiList.MachineDetailUrl}`;
    this.http.post<any>(url, {  IdMachines: [this.machine_id] }, this.globalservice.Headers())
     .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.Mdetails = response;
        this.Mdetails = this.Mdetails.lst_MachineMaster;
        $("#vertical_columns").show();
        $("#tray_moter").show();
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {
      $('#dots_spinner').hide(); });
  }

  UpDatePlace() {
    this.http.post<any>(this.apiList.UpdatePlaceMachineUrl, {  IdPlaceMachine: this.IdPlaceMachine, IdMachine: this.machine_id, IdLocation: this.IdLocation, CreatedBy: this.IdMerchant }, this.globalservice.Headers())
    .take(1)
    .timeout(25000)
    .map(result => {
      if (result.Response !== 1) throw result;
      return result;
    })
    .subscribe((response) => {
      if (response.Response == 1) {
        this.FlashError("Successfully update the location of " + this.selectedMachine + " machine");
        this.router.navigate([this.companyName + '/dashboard/placeaMachine']);
      }
    }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => {});
  }

   FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/placeaMachine']);
  }

}
