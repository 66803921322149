import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-dialog-version-update',
  templateUrl: './dialog-version-update.component.html',
  styleUrls: ['./dialog-version-update.component.css']
})
export class DialogVersionUpdateComponent implements OnInit{
  viewIs;
  showNothanks:boolean=true;
  
  constructor(
    private globalservice: GlobalService,
    public dialogRef: MatDialogRef<DialogVersionUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private router: Router) {

    
    }
    ngOnInit() {
     this.showNothanks=this.globalservice.getThanksBtnStatus();
     
    }
   

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
