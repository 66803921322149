import { Component, OnInit, HostListener } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { Subject } from 'rxjs';
declare const $;
@Component({
  selector: 'app-sub-category-list-page',
  templateUrl: './sub-category-list-page.component.html',
  styleUrls: ['./sub-category-list-page.component.css']
})
export class SubCategoryListPageComponent implements OnInit {
  BaseFileUrl = environment.BaseFileUrl;
  ImageUrl = this.BaseFileUrl + '/ClientDocument/';
  getMainCatName = sessionStorage.getItem('referidmancategoryName');
  companyName = sessionStorage.getItem('companyName');
  getMainCatId = sessionStorage.getItem('referidmancategory');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);
  posts: any;
//  list: any;
  Search: any;
  IdMainCategory: number;
  IdSubCategory: any;
  AlertText;
  pageno: number = 1;
  before: number;
  screenHeight: number;

  resize$ = new Subject<void>(); // for debounce
  public Resizing = false;
  public CurrentPage = 1;
  CurrentPageBeforeResize;
  ItemsPerPageBeforeResize;
  ItemsPerPage = 1;
  public RowHeight = 43; // initial estimate
  
  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    private route: ActivatedRoute,
    public apiList: ApilistService
  ) {
    this.resize$.debounceTime(100).subscribe(innerWidth => {
      if (this.Resizing) { return; }
      this.Repaginate();
      this.SettleAndAutosize(80);
    });
    this.Repaginate();
    this.GetSubcategorylist();
  }

  ngOnInit() {
    this.hidepops();
  }

  Repaginate() {
    this.Resizing = true;
    this.CurrentPage = 1;
  }

  private SettleAndAutosize(settleDelay: number = 80) {
    setTimeout(() => {
      this.AutosizeTable();
    }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
  }

  private AutosizeTable() {
    this.UpdateRowHeight();
    let tableOffset = $('#tableBody').offset();
    let pageControlOffset = $('#paginate').offset();
    if (tableOffset == null || pageControlOffset == null) {
      console.log('Can\'t find');
//console.log(`Total records: ${this.Records.length}`);
      this.ItemsPerPage = 6;
    } else {
      tableOffset = tableOffset.top;
      pageControlOffset = pageControlOffset.top - 10;
      this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
    }
    this.Resizing = false;
    console.log('Resize complete>');
  }

  private UpdateRowHeight() {
    const rows = (<HTMLTableElement>document.getElementById('recordset')).rows;
    if (rows.length > 1) { this.RowHeight = 0; }
    for (let i = 0; i < Math.min(rows.length, 30); i++) {
      this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
    }
    console.log(`RowHeight: ${this.RowHeight}`);
  }

  hidepops() {
    $('#alert_div').hide();
    $('#dots_spinner').hide();
  }
  GetSubcategorylist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.SubCategoryListUrl,
      {
        IdMainCategory: this.getMainCatId,
        PageNumber: this.pageno,
        NumberOfRows: this.before
      },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      })
      .subscribe((response) => {
        this.posts = response;
        if (this.posts.Response === 1) {
          this.posts = response.lst_mc;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide();
      if (this.Resizing) {
        // need to give time to render behind the scenes before size/resize rows
        this.SettleAndAutosize();
      } });
  }

  goback() {
    this.router.navigate([this.companyName + '/dashboard/categories']);
  }

  getID(id: string) {
    this.IdSubCategory = id;
  }

  edit(id: number, name: string, disc: string, image: any) {
    this.router.navigate([this.companyName + '/dashboard/categories/SubCategoryListPage/Updatesubcategory', {
      id: id
    }]);
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  delete() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.DeleteSubCatUrl, {
      IdSubCategory: this.IdSubCategory, IdMerchant: this.IdMerchant
    }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
       // this.list = response;
        if (response.Response === 1) {
          this.FlashError('Successfully deleted');
          this.GetSubcategorylist();
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }
  /* function for hit the api when click on pagination*/
  pageChange(event) {
    this.CurrentPage = event
  }

  @HostListener('window:resize', ['$event'])
  onResize(target) {
    this.resize$.next(target.innerHeight);
  }
}
