import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { UserService } from "./user.service";
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private user:UserService , private router:Router) {
    //console.log(this.user.getUserLoggedIn());
   }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  boolean {

      if( this.user.getUserLoggedIn()){
        return this.user.getUserLoggedIn();
      }
      this.router.navigate(['login']);
    return this.user.getUserLoggedIn();
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
