import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
declare const $;

@Component({
  selector: 'app-previewcampaign',
  templateUrl: './previewcampaign.component.html',
  styleUrls: ['./previewcampaign.component.css']
})

export class PreviewcampaignComponent implements OnInit {
  BaseFileUrl = environment.BaseFileUrl;

  ImageUrl = this.BaseFileUrl + '/ContentDocument/';

  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'), 0);

  lists: any;
  details;
  LocationOnScreen;
  selectedCampaign;
  FilePath;
  previewdetails: any;
  mediaContent = [];
  previewdisplay = false;
  SpecificationName;
  FileMD5Sum: any;
  Response: any;
  AlertText: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private globalservice: GlobalService,
    public apiList: ApilistService) { }

  ngOnInit() {
    this.Campaignlist();
    this.hidepops();
    $('#preview').removeClass('previewbgHz');
    $('#preview').removeClass('previewbgVC ');
  }

  hidepops() {
    $('#alert_div').hide();
    $('#dots_spinner').hide();
    $('#middle').hide();
    $('#previewheading').hide();
    $('#fullscreen_vc').hide();
    $('#fullscreen_hz').hide();
    $('#top_third').hide();
    $('#bottom_third').hide();
  }
  Campaignlist() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.CampaignlistApi, { IdMerchant: this.IdMerchant },
      this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        if (response.Response === 1) {
          this.lists = response;
          this.lists = this.lists.lst_GetListOfCampaign;
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }


  getCampaignDetails(id) {
    $('#middle').show();
    $('#previewheading').show();
    $('#dots_spinner').show();
    this.FilePath = '';
    this.mediaContent = [];
    this.http.post<any>(this.apiList.CampaignDetailApi, { IdCampaign: id }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      }).subscribe((response) => {
        this.details = response;
        this.Response = this.details.Response;
        if (this.Response === 1) {
          this.LocationOnScreen = this.details.Obj_CampaignClass.LocationOnScreen;
          this.SpecificationName = this.details.Obj_CampaignClass.SpecificationName;
          if (this.details.lst_Content[0].FileMD5Sum) {
            this.FilePath = this.ImageUrl + '' + this.details.lst_Content[0].FilePath +
              '?nocache=' + this.details.lst_Content[0].FileMD5Sum;
          } else {
            this.FilePath = 'assets/images/logo-vend4You.png';
          }
          this.previewdetails = this.details.lst_Content;
          this.Set_location();
          for (let i = 0; i < this.details.lst_Content.length; i++) {
            this.mediaContent.push(this.ImageUrl + this.details.lst_Content[i].FilePath);
          }
          if (this.mediaContent) {
            let myvid: HTMLVideoElement;
            myvid = document.getElementById('myvideo') as HTMLVideoElement;
            const myvids = this.mediaContent;
            let activeVideo = 0;
            myvid.addEventListener('ended', function (e) {
              activeVideo = (++activeVideo) % myvids.length;
              myvid.src = myvids[activeVideo];
              myvid.onplaying = function () {
                //  this.onplaying = true;
                //   this.onpause = false;
              };
              // On video pause toggle values
              myvid.onpause = function () {
                // this.onplaying = false;
                // this.onpause = true;
              };
              // Play video function
              function playVid() {
                if (myvid.paused && !onplaying) {
                  myvid.play();
                }
              }
              // Pause video function
              function pauseVid() {
                if (!myvid.paused && !onpause) {
                  myvid.pause();
                }
              }
            });
          }
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });

  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }

  setDailyPlayTime(timeArray: any[]) {
    if (timeArray.length > 0) {
      var finaltext = '';
      timeArray.forEach(item => {
        finaltext += item.PlayTime + ', ';
      });
      return finaltext;
    }
  }

  Set_location() {
    switch (this.LocationOnScreen) {
      case 'Full Screen Vertical':
        $('#preview').show();
        $('.secoundPart').show();
        $('.firstPart').hide();
        $('.thirdPart').hide();
        $('.secoundPart').css('margin', '0px');
        $('.mainVend').css('padding', '0px');
        $('.mainVend').css('background', '');
        $('.secoundPart video').css('height', '426px');
        $('#middle').removeClass('mainVendhz');
        $('#middle').addClass('mainVend ');
        $('#preview').removeClass('previewbgHz');
        $('#preview').addClass('previewbgVC ');
        break;
      case 'Full Screen Horizontal':
        $('#preview').show();
        $('.secoundPart').show();
        $('.firstPart').hide();
        $('.thirdPart').hide();
        $('.secoundPart').css('margin', '0px');
        $('.mainVend').css('padding', '0px');
        $('.mainVend').css('background', '');
        $('.secoundPart video').css('height', 'auto');
        $('#middle').removeClass('mainVend');
        $('#middle').addClass('mainVendhz ');
        $('#preview').removeClass('previewbgVC');
        $('#preview').addClass('previewbgHz ');
        break;
      case 'Top Third':
        $('#preview').show();
        $('.mainVend').css('padding', '0px');
        $('.secoundPart').after($('.firstPart'));
        $('.firstPart').show();
        $('.thirdPart').show();
        $('#middle').show();
        $('.mainVend').css('background', '');
        $('.secoundPart video').css('height', '');
        $('#middle').removeClass('mainVendhz');
        $('#middle').addClass('mainVend ');
        $('#preview').removeClass('previewbgHz');
        $('#preview').addClass('previewbgVC ');
        break;
      case 'Middle':
        $('#preview').show();
        $('.firstPart').after($('.secoundPart'));
        $('#middle').show();
        $('.firstPart').show();
        $('.thirdPart').show();
        $('.mainVend').css('background', '');
        $('.secoundPart video').css('height', '');
        $('#middle').removeClass('mainVendhz');
        $('#middle').addClass('mainVend ');
        $('#preview').removeClass('previewbgHz');
        $('#preview').addClass('previewbgVC ');
        break;
      case 'Bottom Third':
        $('#preview').show();
        $('.thirdPart').after($('.secoundPart'));
        $('#middle').show();
        $('.firstPart').show();
        $('.thirdPart').show();
        $('#top_third').hide();
        $('.secoundPart').css('margin', '0px');
        $('.mainVend').css('background', '');
        $('.secoundPart video').css('height', '');
        $('#middle').removeClass('mainVendhz');
        $('#middle').addClass('mainVend ');
        $('#preview').removeClass('previewbgHz');
        $('#preview').addClass('previewbgVC ');
        break;
      default:
        console.error('Unknown signage position: ' + this.LocationOnScreen);
    }



    // console.log(this.LocationOnScreen)
    // if (this.LocationOnScreen == 'Full Screen Vertical') {
    //   $('#preview').show();
    //   $(".secoundPart").show();
    //   $(".firstPart").hide();
    //   $(".thirdPart").hide();
    //   $(".secoundPart").css("margin", "0px");
    //   $(".mainVend").css("padding", "0px");
    //   $(".mainVend").css("background", "");
    //   $(".secoundPart video").css("height", "426px");
    //   $('#middle').removeClass("mainVendhz");
    //   $('#middle').addClass("mainVend ");
    //   $('#preview').removeClass("previewbgHz");
    //   $('#preview').addClass("previewbgVC ");

    // }
    // if (this.LocationOnScreen == 'Full Screen Horizontal') {
    //   $('#preview').show();
    //   $(".secoundPart").show();
    //   $(".firstPart").hide();
    //   $(".thirdPart").hide();
    //   $(".secoundPart").css("margin", "0px");
    //   $(".mainVend").css("padding", "0px");
    //   $(".mainVend").css("background", "");
    //   $(".secoundPart video").css("height", "auto");
    //   $('#middle').removeClass("mainVend");
    //   $('#middle').addClass("mainVendhz ");
    //   $('#preview').removeClass("previewbgVC");
    //   $('#preview').addClass("previewbgHz ");

    // }
    // if (this.LocationOnScreen == 'Top third') {
    //   $('#preview').show();
    //   $(".mainVend").css("padding", "0px");
    //   $(".secoundPart").after($(".firstPart"));
    //   $(".firstPart").show();
    //   $(".thirdPart").show();
    //   $("#middle").show();
    //   $(".mainVend").css("background", "");
    //   $(".secoundPart video").css("height", "");
    //   $('#middle').removeClass("mainVendhz");
    //   $('#middle').addClass("mainVend ");
    //   $('#preview').removeClass("previewbgHz");
    //   $('#preview').addClass("previewbgVC ");
    // }
    // if (this.LocationOnScreen == 'Middle') {
    //   $('#preview').show();
    //   $(".firstPart").after($(".secoundPart"));
    //   $("#middle").show();
    //   $(".firstPart").show();
    //   $(".thirdPart").show();
    //   $(".mainVend").css("background", "");
    //   $(".secoundPart video").css("height", "");
    //   $('#middle').removeClass("mainVendhz");
    //   $('#middle').addClass("mainVend ");
    //   $('#preview').removeClass("previewbgHz");
    //   $('#preview').addClass("previewbgVC ");
    // }
    // if (this.LocationOnScreen == 'Bottom third') {
    //   // $(".secoundPart video").css("height", "200px");
    //   $('#preview').show();
    //   $(".thirdPart").after($(".secoundPart"));
    //   $("#middle").show();
    //   $(".firstPart").show();
    //   $(".thirdPart").show();
    //   $("#top_third").hide();
    //   $(".secoundPart").css("margin", "0px");
    //   $(".mainVend").css("background", "");
    //   $(".secoundPart video").css("height", "");
    //   $('#middle').removeClass("mainVendhz");
    //   $('#middle').addClass("mainVend ");
    //   $('#preview').removeClass("previewbgHz");
    //   $('#preview').addClass("previewbgVC ");
    // }
  }

  AddScreenbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage']); }
  AddContentbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Addcontent']); }
  Adloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Adloop']); }
  placeloopbtn() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/placeloop']); }
  preview() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Previewcampaign']); }
  Calendar() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaigncalendar']); }
  report() { this.router.navigate([this.companyName + '/dashboard/digitalsignage/Campaignreports']); }
}
