import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';
import { DropdownSearchService } from '../../../dropdown-search.service';
declare const $;

@Component({
  selector: 'app-editbrands',
  templateUrl: './editbrands.component.html',
  styleUrls: ['./editbrands.component.css']
})
export class EditbrandsComponent implements OnInit {
  public mask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  @ViewChild('myInput', { static: false }) public myInputVariable: ElementRef;
  @ViewChild('BrandForm', { static: false }) public BrandForm: NgForm;
  companyName = sessionStorage.getItem('companyName');
  IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'));
  BrandName='';
  BrandEmailId;
  BrandPassword;
  BrandCompanyName;
  BrandCompanyAddress;
  BrandIdCountry;
  BrandIdState;
  BrandIdCity;
  Zip;
  BrandCompanyPhoneNumber;
  blob: Blob;
  AlertText: string;
  BrandNamee: any;
  upload_image: any;
  isAdding = false;
  Name: any;
  Id: any;
  api: string;
  tagShow: string;
  bit: any = false;
  ComBrandPassword
  compare = true
  nextLibAvailable = false;
  show_message
  password_field = false;
  FileMD5Sum: any;

  iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass = false;
  type = 'password';
  iconname2 = 'fa fa-fw fa-eye-slash field-icon toggle-password';
  showPass2 = false;
  type2 = 'password';
  
  countrylists;
  selectedState;
  citys;
  States;
  result;
  Country;
  selectedcountry;
  selectedcity;
  StateName;
  CountryName;
  CityName;
  IdCity;
  IdCountry;
  IdState;
  responseUpdate: string = "";
  responseZip: string = "";
  Latitude = '';
  Longitude = '';

  geometry_address: any;
  timezone_address: any;
  timezoneid: string;
  dstOffset: any;
  rawOffset: any;
  zipValidationAddress: string;

  public searchCountryControl: FormControl = new FormControl();
  public countryCtrl: FormControl = new FormControl();
  public searchStateSearchCtrl: FormControl = new FormControl();
  public stateCtrl: FormControl = new FormControl();
  public searchCityControl: FormControl = new FormControl();
  public cityCtrl: FormControl = new FormControl();
  constructor(public route: ActivatedRoute, private globalservice: GlobalService, public apiList: ApilistService, private router: Router,
    private http: HttpClient, public dialog: MatDialog,public dropdownService: DropdownSearchService) {
    this.route.params.subscribe((get) => {
    

        this.Id = get.id;
      
      
      // this.api = page == 1 ? this.apiList.UpdateBrandResult : this.apiList.CreateBrand;
      // this.tagShow = page == 1 ? 'Edit' : 'Add'
    })
  }

  ngOnInit() {
    this.HidePopups();
    this.brandDetail();
     }

  // getcountry(){
  //   this.globalservice.getCountry().then((res: any) => {
  //     this.countrylists = res;
  //     this.dropdownService.countryObject.next(this.countrylists);
  //     this.dropdownService.CountryArrayObj.next(this.countrylists.slice());
  //     this.searchCountryControl = this.dropdownService.searchCountryControl;
  //     this.searchCountryControl.valueChanges
  //         .subscribe(() => {
  //             this.dropdownService.countryFilter();
  //         });
  //   });
  // }

  HidePopups() {
    $("#alert_div").hide();
    $('#dots_spinner').hide();
    $('#myImg').hide();
    $('#main_img_close').hide();
    $('#uploadSuccess').modal('hide');
    $('#colorTheme').modal('hide');
    $('#errorZip').modal('hide');
  }
  MainImageUpload(file: File) {
    this.blob = new Blob([file[0]], { type: file[0].type });
    console.log(file);
    this.uploadFile()
  }


  canDeactivate(): any {
    if (!this.isAdding && this.BrandForm.dirty) {
      if (this.bit == false && this.globalservice.GetDiscard_Check()) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });

        return dialogRef.afterClosed().subscribe(result => {
          // console.log(result)
          // console.log( result.response)
          if (result && (result.response == "ok")) {
            this.dialog.closeAll();
            this.bit = true;
            this.router.navigate([this.companyName + '/dashboard/brandusers'])
          }
        });
      }
    } else if (!this.isAdding && this.BrandForm.dirty) {
      if (this.bit == false && this.globalservice.GetDiscard_Check()) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
        });

        return dialogRef.afterClosed().subscribe(result => {
          // console.log(result)
          // console.log( result.response)
          if (result && (result.response == "ok")) {
            this.dialog.closeAll();
            this.bit = true;
            this.router.navigate([this.companyName + '/dashboard/brandusers'])
          }
        });
      }
    }
    return true;
  }


  uploadFile() {
    $('#dots_spinner').show();
    this.globalservice.uploadFiles(this.blob).then((res: any) => {
      if (res.Response == 1) {
        $('#dots_spinner').hide();
        this.upload_image = 1;
        this.BrandNamee = res.PreviewUrl;
        this.Name = res.FileName
        this.FileMD5Sum = 1;
        $('#myImg').show(2000, function () {
          $('#main_img_close').show();
          $('#uploadSuccess').modal('show');
        });
        $('#upload-btn-wrapper').hide();
      }
    }, error => {
      const Error = this.globalservice.ProcessError(error);
      if (Error.Response === 35) {
        if (Error.ErrorMessage) {
          this.FlashError(Error.ErrorMessage);
          this.globalservice.Logout('Yes');
        }
        this.globalservice.Logout('No');
        return;
      } else {
        console.log(Error.ErrorMessage)
        // event.target.value = null;
        this.FlashError(Error.ErrorMessage);
        return;
      }
    })
  }

  

  goback() {
    this.router.navigate([this.companyName + '/dashboard/brandusers'])
  }

  remove() {
    this.BrandNamee = ''
    this.upload_image = '';
    //this.myInputVariable.nativeElement.value = '';
    $('#myImg').hide();
    $('#main_img_close').hide();
    $('#upload-btn-wrapper').show();
  }

  FlashError(message: string) {
    this.AlertText = '';
    this.AlertText = message;
    $('#alert_div').show();
    $('#alert_div').animate({
      width: '100%'
    }, 'slow');
    setTimeout(() => {
      $('#alert_div').hide();
    }, 3000);
  }


  brandDetail() {
    $('#dots_spinner').show();
    this.http.post<any>(this.apiList.BrandByIdResult, {
      IdBrand: this.Id
    }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(resp => {
        if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
        if (+resp.Response !== 1) { throw resp; }
        return resp;
      })
      .subscribe((response) => {
        if (response.Response === 1) {
        //  console.log("response brand details"+JSON.stringify(response));
         // this.brand = response.MerchantBrandsList[0];
         this.BrandName= response.MerchantBrandsList[0].BrandName;
         this.BrandEmailId= response.MerchantBrandsList[0].BrandEmailId;
         this.BrandCompanyName=response.MerchantBrandsList[0].BrandCompanyName;

         this.BrandCompanyAddress=response.MerchantBrandsList[0].BrandCompanyAddress;
         this.selectedcountry=response.MerchantBrandsList[0].BrandIdCountry;
         this.IdCountry = this.selectedcountry;
         this.selectedState=response.MerchantBrandsList[0].BrandIdState;
         this.IdState = this.selectedState;
         this.selectedcity=response.MerchantBrandsList[0].BrandIdCity;
         this.IdCity=this.selectedcity;
         this.Zip=response.MerchantBrandsList[0].BrandZip;
         this.BrandCompanyPhoneNumber=response.MerchantBrandsList[0].BrandCompanyPhoneNumber;

          this.BrandNamee = response.MerchantBrandsList[0].BrandImageURL;
          this.Name = response.MerchantBrandsList[0].BrandImagePath;
          this.FileMD5Sum= response.MerchantBrandsList[0].FileMD5Sum;
          this.upload_image = 1;

          this.globalservice.getCountry().then((res: any) => {
            this.countrylists = res;
            // console.log(res);
            this.dropdownService.countryObject.next(this.countrylists);
            this.dropdownService.CountryArrayObj.next(this.countrylists.slice());
            this.searchCountryControl = this.dropdownService.searchCountryControl;
            this.searchCountryControl.valueChanges
                .subscribe(() => {
                    this.dropdownService.countryFilter();
                });
        });
        this.getStates();
        this.getcity();

          // if (typeof this.IdCountry === 'undefined') {
          //   alert('value undefined');
          // } else {
          //   this.getStates();
          //   this.selectedcity=response.MerchantBrandsList[0].BrandIdCity;
          //   this.IdCity=this.selectedcity;
          // }
          // if (typeof this.IdState === 'undefined') {
          //   alert('value undefined');
          // } else {
          //   this.getcity();
          // }
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  onKey1() {
    this.show_message=''
    if (this.BrandPassword) {
      this.nextLibAvailable = true;
    } else {
      this.ComBrandPassword = ''
      this.nextLibAvailable = false;
      this.compare = false;
    }
  }

  onKey(event: any) { // without type info
    this.compare = this.BrandPassword == this.ComBrandPassword?true:false;
    this.show_message = this.BrandPassword != this.ComBrandPassword?'Password and Confirm Password does not match':''

  }

  show_password() {
    this.password_field = true;
  }  confirm_pass() {
    // console.log("dgvfdg")
     this.showPass2 = !this.showPass2;
     if (this.showPass2) {
       this.type2 = 'text';
       this.iconname2 = 'fa fa-fw fa-eye field-icon toggle-password';
     } else {
       this.type2 = 'password';
       this.iconname2 = 'fa fa-fw fa-eye-slash field-icon toggle-password';
     }
   }

   showPassword() {
    this.showPass = this.showPass ? false : true;
    if (this.showPass) {
      this.type = 'text';
      this.iconname = 'fa fa-fw fa-eye field-icon toggle-password';
    } else {
      this.type = 'password';
      this.iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
    }
  }

  onChangeCountry(a) {
    this.IdCountry = a;
   
      this.getStates();
    
  }

  getStates() {
    this.http.post<any>(this.apiList.StateUrl,
      { IdCountry: this.IdCountry }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response.Response === 1) {
          this.States = response.lst_State;
          this.dropdownService.stateObject.next(this.States);
            this.dropdownService.StateArrayObj.next(this.States.slice());
            this.searchStateSearchCtrl = this.dropdownService.searchStateControl;
            this.searchStateSearchCtrl.valueChanges
                .subscribe(() => {
                    this.dropdownService.stateFilter();
                });
        }
      }, (err) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  onChangeState(b) {
    this.IdState = b;
   
      this.getcity();
    
  }

  getcity() {
    //  console.log("inside city call");
    this.http.post<any>(this.apiList.CityUrl,
      { IdState: this.IdState }, this.globalservice.Headers())
      .timeout(25000)
      .take(1)
      .map(response => {
        if (response == null) { response = { Response: 0, ErrorMessage: 'No response' }; }
        if (+response.Response !== 1) { throw response; }
        return response;
      })
      .subscribe((response) => {
        if (response.Response === 1) {
          this.citys = response.lst_City;
          this.dropdownService.cityObject.next(this.citys);
          this.dropdownService.CityArrayObj.next(this.citys.slice());
          this.searchCityControl = this.dropdownService.searchCityControl;
          this.searchCityControl.valueChanges
              .subscribe(() => {
                  this.dropdownService.cityFilter();
              });
        }
      }, (err: any) => {
        const Error = this.globalservice.ProcessError(err);
        if (Error.Response === 35) {
          if (Error.ErrorMessage) {
            this.FlashError(Error.ErrorMessage);
            this.globalservice.Logout('Yes');
          }
          this.globalservice.Logout('No');
          return;
        } else {
          this.FlashError(Error.ErrorMessage);
          return;
        }
      }).add(() => { $('#dots_spinner').hide(); });
  }

  ZipcodeExist() {
    this.responseZip = "";
    this.responseUpdate = "";
    if (this.selectedcity) {
      this.http.post<any>(this.apiList.CheckZipCodeExist,
        { ZipCode: this.Zip, IdCity: this.selectedcity }, this.globalservice.headerWithoutauth())
        .subscribe((response) => {
          if (response) {
            if (response.Response === 1) {
              this.Latitude = response.Latitude;
              this.Longitude = response.Longitude;
            } else if (response.Response === 4) {
              // this.FlashError("We don\'t recognize this zip code");
              this.responseZip = "The zip code " + this.Zip + " is not recognized, are you sure?";
              $('#errorZip').modal('show');

              // this.AlertText = 'We don\'t recognize this zip code, are you sure?';
              // $('#myModal').modal('show');
            } else {
              $('#errorZip').modal('hide');
              this.responseUpdate = "There was an internal error processing this zip code";
              $('#colorTheme').modal('show');
              //this.FlashError("There was an internal error processing this zip code");
            }
          }
        }, (err) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        });
    } else {
      this.Latitude = '';
      this.Longitude = '';
      this.Zip = '';
      this.timezoneid = '';
      this.dstOffset = '';
      this.rawOffset = '';
      $('#errorZip').modal('hide');
      this.responseUpdate = "Please select city first";
      $('#colorTheme').modal('show');
      //this.FlashError('Please select city first');
    }
  }

  checkZipGoogleApi() {
    this.responseUpdate = '';
    this.zipValidationAddress = this.selectedcity + " " + this.Zip;
    // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
    // //console.log(this.completeaddress);
    this.zipValidationAddress = this.zipValidationAddress.replace(/  +/g, ' ');
    this.http.get<any>("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.zipValidationAddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.geometry_address = response;
      if (this.geometry_address.status == "OK") {
        var lat = this.geometry_address.results[0].geometry.location.lat;
        var long = this.geometry_address.results[0].geometry.location.lng;
        this.GetTimezoneID(lat, long);
      } else {
        this.Latitude = '';
        this.Longitude = '';
        this.Zip = '';
        this.timezoneid = '';
        this.dstOffset = '';
        this.rawOffset = '';
        $('#errorZip').modal('hide');
        this.responseUpdate = "Invalid Zip Code";
        $('#colorTheme').modal('show');
      }
    })
  }


  GetTimezoneID(lat, long) {
    var time = new Date().getTime();
    var date_timestamp = Math.round(time / 1000);
    this.http.get<any>("https://maps.googleapis.com/maps/api/timezone/json?location=" + lat + "," + long + "&timestamp=" + date_timestamp + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe((response) => {
      this.timezone_address = response;
      // console.log(this.timezone_address)
      if (this.timezone_address.status == "OK") {
        this.timezoneid = this.timezone_address.timeZoneId;
        this.dstOffset = this.timezone_address.dstOffset;
        this.rawOffset = this.timezone_address.rawOffset;
        this.Latitude = lat;
        this.Longitude = long;
        // console.log("this.Latitude"+this.Latitude);
        // console.log("this.Longitude"+this.Longitude);
      } else {
        this.Latitude = '';
        this.Longitude = '';
        this.Zip = '';
        this.timezoneid = '';
        this.dstOffset = '';
        this.rawOffset = '';
        $('#errorZip').modal('hide');
        this.responseUpdate = "Please Fill a valid Zip Code";
        $('#colorTheme').modal('show');
        // this.FlashError("Please fill valid Zip Code");
      }
    })
  }

  No() {
    this.Zip = '';
  }
  
  submit() {
    if (this.BrandPassword == this.ComBrandPassword) {
    if (this.upload_image === 1) {
      this.isAdding = true;
      $('#dots_spinner').show();
      this.http.post<any>(this.apiList.UpdateBrandResult, {
        IdBrand: this.Id,
        BrandName: this.BrandName,
        BrandEmailId: this.BrandEmailId,
        BrandPassword: this.BrandPassword,
        IdMerchant : this.IdMerchant,
        BrandImagePath: this.Name,
        BrandCompanyName:this.BrandCompanyName,
        BrandCompanyAddress:this.BrandCompanyAddress,
        BrandIdCountry:this.selectedcountry,
        BrandIdState:this.selectedState,
        BrandIdCity:this.selectedcity,
        BrandZip:this.Zip,
        BrandCompanyPhoneNumber:this.BrandCompanyPhoneNumber

      }, this.globalservice.Headers())
        .timeout(25000)
        .take(1)
        .map(resp => {
          if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
          if (+resp.Response !== 1) { throw resp; }
          return resp;
        })
        .subscribe((response) => {
          if (response.Response === 1) {
            this.BrandForm.reset();
            this.router.navigate([this.companyName + '/dashboard/brandusers']);
          }
        }, (err: any) => {
          const Error = this.globalservice.ProcessError(err);
          if (Error.Response === 35) {
            if (Error.ErrorMessage) {
              this.FlashError(Error.ErrorMessage);
              this.globalservice.Logout('Yes');
            }
            this.globalservice.Logout('No');
            return;
          } else {
            this.FlashError(Error.ErrorMessage);
            return;
          }
        }).add(() => { $('#dots_spinner').hide(); });
    } else {
      this.FlashError('Firstly upload brand Image');
    }
  }else{
    this.FlashError("Password and Confirm Password does not match");
  }
  }


}
